import { findIndex } from 'lodash';
import initialState from '../constants/initialState';
import { coupons as couponActionTypes } from '../actions';
import { updateObjectInArray, removeItem } from '../utills/custom';

function coupons(state = initialState.coupons, action) {
  switch (action.type) {
    case couponActionTypes.COUPON_ALL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case couponActionTypes.COUPON_ALL_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allList: action.data
        };
      }
      return state;
    case couponActionTypes.COUPON_ALL_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case couponActionTypes.COUPON_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case couponActionTypes.COUPON_CREATE_SUCCESS:
    case couponActionTypes.COUPON_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case couponActionTypes.COUPON_SUCCESS:
      if (action.data) {
        return {
          ...state,
          allList: { data: [action.data] }
        };
      }
      return state;
    case couponActionTypes.COUPON_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case couponActionTypes.COUPON_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        if (index > -1) {
          const newList = updateObjectInArray(state.allList.data, {
            index,
            item: action.data
          });
          const allList = { ...state.allList, data: newList };
          return {
            ...state,
            allList,
            updateProgress: false
          };
        }
      }
      return {
        ...state,
        updateProgress: false
      };
    case couponActionTypes.COUPON_STATUS_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case couponActionTypes.COUPON_STATUS_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        if (index > -1) {
          state.allList.data[index] = {
            ...state.allList.data[index],
            status: action.data.status
          };
          const allList = { ...state.allList };
          return {
            ...state,
            allList
          };
        }
      }
      return state;
    case couponActionTypes.COUPON_STATUS_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case couponActionTypes.COUPON_SHOW_ALERT:
      return {
        ...state,
        alert: action.data
      };
    case couponActionTypes.COUPON_HIDE_ALERT:
      return {
        ...state,
        alert: false
      };
    case couponActionTypes.COUPON_DELETE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case couponActionTypes.COUPON_DELETE_SUCCESS:
      if (action.id) {
        const index = findIndex(state.allList.data, { id: action.id });
        if (index > -1) {
          const newList = removeItem(state.allList.data, {
            index
          });
          return {
            ...state,
            allList: { ...state.allList, data: newList },
            updateProgress: false
          };
        }
      }
      return {
        ...state,
        updateProgress: false
      };
    case couponActionTypes.COUPON_DELETE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case couponActionTypes.COUPON_ALL_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case couponActionTypes.COUPON_ALL_ORDER_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allCouponOrder: action.data
        };
      }
      return state;
    case couponActionTypes.COUPON_ALL_ORDER_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case couponActionTypes.COUPON_ORDER_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allOrder: action.data
        };
      }
      return state;
    case couponActionTypes.COUPON_ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case couponActionTypes.COUPON_ORDER_ERROR:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

export default coupons;
