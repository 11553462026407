import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { findIndex, find } from 'lodash';
import { change, arrayPush, arrayRemove, getFormValues } from 'redux-form';
import Select from 'react-select';
import { productCountry } from '../../services/apiService';
import CountryContext from './countryContext';

function CountryDropdown({ dispatch, formValues }) {
  const { countryList, setCountryList, setStateList } = useContext(
    CountryContext
  );
  const {
    countries: selectedCountries,
    priceCountry,
    category_id: selectedCategory,
    regular_price: regularPrice,
    sale_price: salePrice,
    member_price: memberPrice,
    member_price_vat: memberPriceVat,
    customer_price_vat: customerPriceVat,
    bpoint,
    cpoint,
    taxable,
    tax_id: taxId,
    uncovered_countries: uncoveredCountries
  } = formValues;

  const noVal = '';
  const handleCountrySelect = val => {
    const index = findIndex(selectedCountries, { country: val.value });
    if (index < 0) {
      dispatch(
        arrayPush('productForm', 'countries', {
          country: val.value,
          regular_price: regularPrice,
          sale_price: salePrice,
          member_price: memberPrice,
          member_price_vat: memberPriceVat,
          customer_price_vat: customerPriceVat,
          bpoint,
          cpoint,
          taxable,
          tax_id: taxId,
          states: []
        })
      );

      // Delete from uncoveredCountries
      const uIndx = uncoveredCountries.indexOf(val.value);
      if (uIndx > -1) {
        dispatch(arrayRemove('productForm', 'uncovered_countries', uIndx));
      }
    }
  };

  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        if (selectedCategory && selectedCategory.length) {
          const res = await productCountry(selectedCategory);
          const newCountryList = [];
          const newStateList = {};
          res.countries.forEach(item => {
            // Create country list
            newCountryList.push({
              label: item.name,
              value: item.iso2
            });

            // Create state list
            newStateList[item.iso2] = {
              states: item.states,
              country: item.name
            };
          });
          setStateList(newStateList);
          setCountryList(newCountryList);

          // Auto select all country
          const selectednewList = [];
          newCountryList.forEach(item => {
            if (!uncoveredCountries.includes(item.value)) {
              // Country price is different
              const priceExist = find(priceCountry, {
                country: item.value
              });
              if (priceExist) {
                selectednewList.push({
                  country: item.value,
                  regular_price: priceExist.regular_price,
                  sale_price: priceExist.sale_price,
                  member_price: priceExist.member_price,
                  member_price_vat: priceExist.member_price_vat,
                  customer_price_vat: priceExist.customer_price_vat,
                  bpoint: priceExist.bpoint,
                  cpoint: priceExist.cpoint,
                  taxable: priceExist.taxable,
                  tax_id: priceExist.tax_id,
                  states: [],
                  inherit: false,
                  check_currency_value: priceExist.check_currency_value
                });
              } else {
                const index = findIndex(selectedCountries, {
                  country: item.value
                });
                selectednewList.push({
                  country: item.value,
                  regular_price: regularPrice,
                  sale_price: salePrice,
                  member_price: memberPrice,
                  member_price_vat: memberPriceVat,
                  customer_price_vat: customerPriceVat,
                  bpoint,
                  cpoint,
                  taxable,
                  tax_id: taxId,
                  states: index > -1 ? selectedCountries[index].states : [],
                  inherit: true
                });
              }
            }
          });

          dispatch(change('productForm', 'countries', selectednewList));
        } else {
          // De-select country
          setStateList({});
          setCountryList([]);
          dispatch(change('productForm', 'countries', []));
        }
      } catch (error) {
        console.error(error);
      }
    };

    // Get category wise country/state list
    fetchCountryList(selectedCategory);
  }, [selectedCategory]);

  return (
    <Select
      name="countries"
      placeholder="Select ..."
      label="Select Country"
      onBlur={event => event.preventDefault()}
      onChange={handleCountrySelect}
      value={noVal}
      options={countryList}
      className="react-select"
      classNamePrefix="react-select"
    />
  );
}

// const selector = formValueSelector('productForm');
const mapStateToProps = state => ({
  // selectedCountries: selector(state, 'countries'),
  // selectedCategory: selector(state, 'category_id')
  formValues: getFormValues('productForm')(state)
});

export default connect(
  mapStateToProps,
  null
)(CountryDropdown);
