import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import TemplateForm from './TemplateForm';
import { template as templateActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';

class TemplateAdd extends React.Component {
  handleSubmit = values => {
    const { createTemplate } = this.props;
    createTemplate(values);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.templateAdd.breadcrumb} />
              <h4 className="page-title">{routePath.templateAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <TemplateForm
              submitForm={this.handleSubmit}
              inProgress={createProgress}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.template.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createTemplate: templateActions.createTemplate
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateAdd);
