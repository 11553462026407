import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  Field,
  FieldArray,
  reduxForm,
  change,
  getFormValues
} from 'redux-form';
import { Tab, Nav, Form } from 'react-bootstrap';
import axios from 'axios';
import { required, number, minValue } from '../../utills/validator';
import {
  inputField,
  dateField,
  editorField,
  inputFieldControlled,
  checkboxField,
  selectField,
  radioField
} from '../../components/Fields';
import routePath from '../../constants/routePath';
import {
  app as appActionTypes,
  product as productActions
} from '../../actions';
import { getSlug } from '../../utills/custom';
import btnLoader from '../../assets/images/btn-loader.gif';
import ProductImageUpload from './ProductImageUpload';
import CountryField from './CountryField';
import CountryDropdown from './CountryDropdown';
import { CountryContextProvider } from './countryContext';
import { calculateProductPrice, allCurrency } from '../../services/apiService';

const minValueValidate = minValue(0);

class ProductForm extends React.Component {
  noVal = '';

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      currencyList: []
    };
    const {
      getPrefetchCategory,
      getPrefetchShipping,
      getProductTaxList
    } = this.props;
    this.getPriceCalculation = debounce(
      this.getPriceCalculation.bind(this),
      750
    );
    getPrefetchCategory();
    getPrefetchShipping();

    // Get taxes list
    getProductTaxList({
      taxable: 1,
      regular_price: 0,
      sale_price: 0,
      bpoint: 0,
      cpoint: 0
    });
  }

  async componentDidMount() {
    try {
      let cres = await allCurrency();
      if (cres && cres.currencies) {
        cres = cres.currencies.map(item => ({
          value: item.id,
          label: `${item.currency_abbreviation} (${item.currency_symbol})`
        }));
        this.setState({ currencyList: cres });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getPriceCalculation(data) {
    const { dispatch } = this.props;
    try {
      const res = await calculateProductPrice(data);
      dispatch(change('productForm', `member_price`, res.member_price));
      dispatch(change('productForm', `member_price_vat`, res.member_price_vat));
      dispatch(
        change('productForm', `customer_price_vat`, res.customer_price_vat)
      );
    } catch (error) {
      console.error(error);
    }
  }

  handleChangePrice = (field, value) => {
    const { formValues } = this.props;
    const data = {};
    const fields = [
      'taxable',
      'regular_price',
      'sale_price',
      'bpoint',
      'cpoint'
    ];
    fields.forEach(item => {
      if (item === field) {
        data[item] = value;
      } else {
        data[item] = formValues[item] || 0;
      }
    });

    if (formValues.taxable) {
      if (field === 'tax_id') {
        data.tax_rate = value;
      } else {
        data.tax_rate = formValues.tax_id;
      }
    }

    data.country = '';

    this.getPriceCalculation(data);
  };

  handleSlug = val => {
    const { formValues, dispatch } = this.props;
    if (formValues && formValues.title) {
      dispatch(change('productForm', 'slug', getSlug(val)));
    }
  };

  openCreateCat = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleUpload = async (file, callback) => {
    try {
      const fd = new FormData();
      fd.append('image', file);
      fd.append('type', 'product');
      const responseJson = await axios({
        url: `${process.env.REACT_APP_API_URL}/file`,
        method: 'POST',
        data: fd,
        successMessage: 'File uploaded'
      });
      if (responseJson && responseJson.file_id) {
        callback(responseJson.file_id);
      }
    } catch (error) {
      console.error('Upload failed');
    }
  };

  handleGalleryImageDelete = async index => {
    const { id, formValues, dispatch } = this.props;
    const fileId = formValues.file_id[index];
    try {
      if (fileId) {
        if (id) {
          await axios({
            url: `${process.env.REACT_APP_API_URL}/product/${id}/image/${fileId}`,
            method: 'PUT',
            successMessage: 'File deleted'
          });
        }
        const newfileIds = [...formValues.file_id];
        newfileIds.splice(index, 1);
        dispatch(change('productForm', 'file_id', newfileIds));
      }
    } catch (error) {
      console.error('Delete failed');
    }
  };

  handleDefaultImageDelete = async index => {
    const { id, formValues, dispatch } = this.props;
    try {
      const fileId = formValues.default_file_id;
      if (fileId && id) {
        await axios({
          url: `${process.env.REACT_APP_API_URL}/product/${id}/image/${fileId}`,
          method: 'PUT',
          successMessage: 'File was deleted successfully'
        });
        dispatch(change('productForm', 'default_file_id', ''));
      }
    } catch (error) {
      console.error('Delete failed');
    }
  };

  taxOptions = taxes => {
    const options = [];
    taxes.map(tax =>
      options.push({
        label: `${tax.tax_rate_name} - ${tax.tax_rate}%`,
        value: tax.id
      })
    );
    return options;
  };

  handleDefaultImageUpload = file => {
    const { dispatch } = this.props;
    this.handleUpload(file, fileId => {
      dispatch(change('productForm', 'default_file_id', fileId));
    });
  };

  handleGalleryImageUpload = file => {
    const { dispatch, formValues } = this.props;
    this.handleUpload(file, fileId => {
      dispatch(
        change('productForm', 'file_id', [...formValues.file_id, fileId])
      );
    });
  };

  handleSelectCat = e => {
    const { dispatch, formValues } = this.props;
    let newCat = [];
    if (e.target.checked) {
      newCat = [...formValues.category_id, parseInt(e.target.value, 10)];
    } else {
      const index = formValues.category_id.indexOf(
        parseInt(e.target.value, 10)
      );
      if (index > -1) {
        formValues.category_id.splice(index, 1);
      }
      newCat = [...formValues.category_id];
    }

    dispatch(change('productForm', 'category_id', newCat));
  };

  handleSelectStatus = e => {
    const { dispatch } = this.props;
    dispatch(change('productForm', 'status', e.target.value === 'true'));
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      categoryList,
      shippingList,
      inProgress,
      formValues,
      defaultImage,
      imageGallery,
      taxes,
      countryList
    } = this.props;
    const { open, currencyList } = this.state;
    const shippingOptions = shippingList.map(item => ({
      value: item.id,
      label: item.title
    }));
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xs-12 col-md-4">
                    <Field
                      name="sku"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="SKU"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <Field
                      name="ean"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="EAN 13 Number"
                    />
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <Field
                      name="publish_date"
                      component={dateField}
                      type="text"
                      className="form-control"
                      label="Publish Date"
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <Field
                      name="title"
                      component={inputFieldControlled}
                      type="text"
                      className="form-control"
                      label="Title"
                      feedback={this.handleSlug}
                      validate={[required]}
                    />
                    <Field
                      name="slug"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Slug"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <Field
                      name="description"
                      component={editorField}
                      className="form-control"
                      label="Description"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-xs-12 col-md-12">
                    <Field
                      name="excerpt"
                      component={editorField}
                      className="form-control"
                      label="Short Description"
                      validate={[required]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              {/* <h4 className="card-header">General</h4> */}
              <div className="card-body">
                <div className="form-horizontal">
                  <h4 className="header-title mb-3 text-primary">
                    <i className="mdi mdi-file-cabinet" /> General
                  </h4>
                  <Field
                    name="currency_id"
                    component={selectField}
                    label="Currency"
                    notSearchable
                    tooltip="Currency"
                    inline={['col-4', 'col-8']}
                    options={currencyList}
                    validate={[required]}
                  />
                  <Field
                    name="regular_price"
                    component={inputField}
                    type="number"
                    label="Regular Price"
                    tooltip="Regular Price"
                    inline={['col-4', 'col-8']}
                    className="form-control form-control-sm"
                    validate={[required, number, minValueValidate]}
                    feedback={value =>
                      this.handleChangePrice('regular_price', value)
                    }
                  />
                  <Field
                    name="sale_price"
                    component={inputField}
                    type="number"
                    className="form-control form-control-sm"
                    label="Sale Price"
                    tooltip="Sale Price"
                    inline={['col-4', 'col-8']}
                    validate={[number, minValueValidate]}
                    feedback={value =>
                      this.handleChangePrice('sale_price', value)
                    }
                  />
                  <Field
                    name="bpoint"
                    component={inputFieldControlled}
                    type="number"
                    className="form-control form-control-sm"
                    label="B-Points"
                    tooltip="B-Points"
                    inline={['col-4', 'col-8']}
                    validate={[required, number, minValueValidate]}
                    feedback={value => this.handleChangePrice('bpoint', value)}
                  />
                  <Field
                    name="cpoint"
                    component={inputFieldControlled}
                    type="number"
                    className="form-control form-control-sm"
                    label="C-Points"
                    tooltip="Customer Points"
                    inline={['col-4', 'col-8']}
                    validate={[required, number, minValueValidate]}
                    feedback={value => this.handleChangePrice('cpoint', value)}
                  />
                  <hr />
                  <Field
                    name="taxable"
                    component={selectField}
                    label="Tax Status"
                    notSearchable
                    tooltip="Tax Status"
                    inline={['col-4', 'col-8']}
                    options={[
                      { value: true, label: 'Taxable' },
                      { value: false, label: 'Not taxable' }
                    ]}
                    feedback={value => this.handleChangePrice('taxable', value)}
                  />
                  {formValues.taxable && (
                    <Field
                      name="tax_id"
                      component={selectField}
                      label="Tax Rate"
                      notSearchable
                      tooltip="Tax Rate"
                      inline={['col-4', 'col-8']}
                      options={this.taxOptions(taxes)}
                      feedback={value =>
                        this.handleChangePrice('tax_id', value)
                      }
                    />
                  )}
                  <hr />
                  <Field
                    name="member_price"
                    component={inputField}
                    readOnly
                    type="number"
                    className="form-control form-control-sm"
                    label="Member Price"
                    tooltip="Member Price"
                    inline={['col-4', 'col-8']}
                  />
                  <Field
                    name="member_price_vat"
                    component={inputField}
                    readOnly
                    className="form-control form-control-sm"
                    label="Member Price (VAT)"
                    tooltip="Member price (VAT)"
                    inline={['col-4', 'col-8']}
                  />
                  <Field
                    name="customer_price_vat"
                    component={inputField}
                    readOnly
                    className="form-control form-control-sm"
                    label="Customer Price (VAT)"
                    tooltip="Customer Price (VAT)"
                    inline={['col-4', 'col-8']}
                  />
                  <Field
                    name="sold_out"
                    component={checkboxField}
                    id="sold_out"
                    label="Sold Out"
                    tooltip="Sold Out"
                    className="form-control form-control-sm"
                    inline={['col-4', 'col-8 pt-2']}
                    options={[{ value: 'yes', label: 'Mark as sold' }]}
                  />
                </div>
              </div>
            </div>
            <div className="card">
              {/* <h4 className="card-header">General</h4> */}
              <div className="card-body">
                <div className="form-horizontal">
                  <h4 className="header-title mb-3 text-primary">
                    <i className="mdi mdi-file-cabinet" /> Country
                  </h4>
                  <CountryContextProvider>
                    <label htmlFor="countries">Choose Country</label>
                    <CountryDropdown />
                    <div className="col-xs-12 col-md-12 d-flex flex-wrap mt-2 mb-2">
                      <FieldArray name="countries" component={CountryField} />
                    </div>
                  </CountryContextProvider>

                  {/* {formValues.countries && (
                    <div className="col-xs-12 col-md-12 d-flex flex-wrap mt-2 mb-2">
                      {formValues.countries.map(item => (
                        <SelectedCountryItem
                          key={`sel_c_${item}`}
                          data={item}
                        />
                      ))}
                    </div>
                  )} */}
                </div>
              </div>
            </div>
            <div className="card">
              {/* <h4 className="card-header">General</h4> */}
              <div className="card-body">
                <div className="form-horizontal">
                  <h4 className="header-title mb-3 text-primary">
                    <i className="mdi mdi-cube-outline" /> Inventory
                  </h4>
                  <Field
                    name="manage_stock"
                    component={checkboxField}
                    id="manage_stock"
                    label="Manage Stocks?"
                    tooltip="Manage Stocks?"
                    inline={['col-4', 'col-8 pt-2']}
                    options={[
                      {
                        value: 'yes',
                        label: 'Enable stock management at product level'
                      }
                    ]}
                  />
                  <Field
                    name="stock_qty"
                    component={inputField}
                    type="number"
                    className="form-control form-control-sm"
                    label="Stock Quantity"
                    tooltip="Stock Quantity"
                    inline={['col-4', 'col-8']}
                    validate={[number]}
                  />
                  <Field
                    name="allow_backorders"
                    component={selectField}
                    label="Allow backorders?"
                    notSearchable
                    tooltip="This will allow backorders"
                    inline={['col-4', 'col-8']}
                    options={[
                      { value: 'ALLOW', label: 'Allow' },
                      { value: 'DO NOT ALLOW', label: 'Do not allow' },
                      {
                        value: 'ALLOW BUT NOTIFY USERS',
                        label: 'Allow but notify users'
                      }
                    ]}
                    // validate={[required]}
                  />
                  <Field
                    name="low_stock_alert"
                    component={inputField}
                    type="number"
                    className="form-control form-control-sm"
                    label="Low stock threshold"
                    tooltip="Low stock threshold"
                    inline={['col-4', 'col-8']}
                    validate={[number]}
                  />
                  <hr />
                  <Field
                    name="sold_single"
                    component={checkboxField}
                    id="sold_individual"
                    label="Sold individually"
                    inline={['col-4', 'col-8 pt-2']}
                    options={[
                      {
                        value: 'yes',
                        label:
                          'Enable this to only allow one of this item to be bought in a single order'
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="card">
              {/* <h4 className="card-header">General</h4> */}
              <div className="card-body">
                <div className="form-horizontal">
                  <h4 className="header-title mb-3 text-primary">
                    <i className="mdi mdi-truck-delivery" /> Shipping
                  </h4>
                  <Field
                    name="weight"
                    component={inputField}
                    type="number"
                    className="form-control form-control-sm"
                    label="Weight (kg)"
                    tooltip="Weight (kg)"
                    inline={['col-4', 'col-8']}
                    validate={[number]}
                  />
                  <div className="form-group row">
                    <label className="col-4 col-form-label">
                      Dimensions (cm)
                    </label>
                    <Field
                      name="width"
                      component={inputField}
                      type="number"
                      groupClassName="col"
                      placeholder="Width"
                      className="form-control form-control-sm"
                      validate={[number]}
                    />
                    <Field
                      name="height"
                      component={inputField}
                      type="number"
                      groupClassName="col"
                      placeholder="Height"
                      className="form-control form-control-sm"
                      validate={[number]}
                    />
                    <Field
                      name="length"
                      component={inputField}
                      type="number"
                      groupClassName="col"
                      placeholder="Depth"
                      className="form-control form-control-sm"
                      validate={[number]}
                    />
                  </div>
                  <hr />
                  <Field
                    name="shipping_id"
                    component={selectField}
                    label="Shipping class"
                    notSearchable
                    tooltip="Shipping class"
                    inline={['col-4', 'col-8']}
                    options={shippingOptions}
                  />
                </div>
              </div>
            </div>
            <div className="card d-none">
              {/* <h4 className="card-header">General</h4> */}
              <div className="card-body">
                <div className="form-horizontal">
                  <h4 className="header-title mb-3 text-primary">
                    <i className="mdi mdi-star" /> Offers
                  </h4>
                  <p>Content goes here</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="card">
              <h5 className="card-header">Status</h5>
              <div className="card-body">
                <div className="form-group">
                  <div className="custom-control custom-radio">
                    <input
                      name="status"
                      type="radio"
                      checked={formValues.status === true}
                      className="custom-control-input"
                      id="Publish"
                      value="true"
                      onChange={this.handleSelectStatus}
                    />
                    <label className="custom-control-label" htmlFor="Publish">
                      Publish
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      name="status"
                      type="radio"
                      checked={formValues.status === false}
                      className="custom-control-input"
                      id="Draft"
                      value="false"
                      onChange={this.handleSelectStatus}
                    />
                    <label className="custom-control-label" htmlFor="Draft">
                      Draft
                    </label>
                  </div>
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      disabled
                      className="custom-control-input"
                      id="Pending"
                      value="false"
                    />
                    <label className="custom-control-label" htmlFor="Pending">
                      Pending review
                    </label>
                  </div>
                </div>
                <Field
                  name="sort_order"
                  component={inputField}
                  type="number"
                  className="form-control form-control-sm"
                  label="Sort Order"
                  inline={['col-6', 'col-6']}
                />
                <div className="row">
                  <div className="col">
                    <Link
                      to={routePath.product.path}
                      className="btn btn-light btn-block"
                    >
                      Cancel
                    </Link>
                  </div>
                  <div className="col">
                    <button
                      type="submit"
                      disabled={inProgress}
                      className="btn btn-primary btn-block"
                    >
                      {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">Product Visibility</h5>
              <div className="card-body">
                <Field
                  name="display_product"
                  component={radioField}
                  label=""
                  className="font-weight-semibold"
                  options={[
                    { label: 'Both', value: 0 },
                    { label: 'Only Associate', value: 1 },
                    { label: 'Only Customer', value: 2 }
                  ]}
                />
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">Product Categories</h5>
              <div className="card-body">
                <Tab.Container id="categories-tabs" defaultActiveKey="allCats">
                  <Nav className="nav nav-tabs nav-bordered mb-2 no-rounded">
                    <Nav.Item>
                      <Nav.Link eventKey="allCats">All Categories</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="mostCats">Most Used</Nav.Link>
                    </Nav.Item> */}
                  </Nav>
                  <Tab.Content className="px-2">
                    <Tab.Pane eventKey="allCats">
                      {categoryList.map((cat, index) => {
                        let marginLeftClass = '';
                        if (cat.hierarchyLevel) {
                          marginLeftClass = `ml-${cat.hierarchyLevel + 1}`;
                        }
                        return (
                          <div
                            key={`allcat_${cat.id}`}
                            className={`custom-control custom-checkbox mb-1 ${marginLeftClass}`}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              value={cat.id}
                              checked={formValues.category_id.includes(cat.id)}
                              onChange={this.handleSelectCat}
                              id={`category_all_${index}`}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor={`category_all_${index}`}
                            >
                              {cat.title}
                            </label>
                          </div>
                        );
                      })}
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="mostCats">
                      {categoryList.map((cat, index) => {
                        let marginLeftClass = '';
                        if (cat.hierarchyLevel) {
                          marginLeftClass = `ml-${cat.hierarchyLevel + 1}`;
                        }
                        return (
                          <div key={`reccat_${cat.id}`} className={`custom-control custom-checkbox mb-1 ${marginLeftClass}`}>
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              value={cat.id}
                              checked={formValues.category_id.includes(cat.id)}
                              onChange={this.handleSelectCat}
                              id={`category_${index}`}
                            />
                            <label className="custom-control-label" htmlFor={`category_${index}`}>{cat.title}</label>
                          </div>
                        );
                      })}
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
                {/* <Button
                  variant="link"
                  onClick={() => this.openCreateCat()}
                  className="btn btn-link btn-sm font-weight-bold p-0 mt-1"
                  aria-expanded={open}
                >


                  + Add new category
                </Button>
                <Collapse in={open}>
                  <div className="mt-2">
                    <Field
                      name="new_cat"
                      component={inputField}
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Type here..."
                    />
                    <div className="form-group">
                      <select className="form-control form-control-sm">
                        <option>--Parent category--</option>
                        <option>--</option>
                        <option>--</option>
                        <option>--</option>
                      </select>
                    </div>
                    <Button variant="light" className="btn btn-sm btn-light">Add new category</Button>
                  </div>
                </Collapse> */}
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">Product Image</h5>
              <div className="card-body bg-light-lighten">
                <div className="upload_img text-center align-items-center">
                  <Field name="file_id" component="input" type="hidden" />
                  <ProductImageUpload
                    preview={defaultImage ? [defaultImage] : []}
                    id="cat_img"
                    onChange={this.handleDefaultImageUpload}
                    deleteFile={this.handleDefaultImageDelete}
                  />
                </div>
              </div>
            </div>
            <div className="card">
              <h5 className="card-header">Product Gallery</h5>
              <div className="card-body bg-light-lighten">
                <div className="upload_img text-center align-items-center">
                  <ProductImageUpload
                    preview={imageGallery || []}
                    id="cat_gal"
                    multiple
                    onChange={this.handleGalleryImageUpload}
                    deleteFile={this.handleGalleryImageDelete}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  categoryList: state.app.categoryList,
  shippingList: state.app.shippingList,
  taxes: state.product.taxes,
  isTaxLoading: state.tax.isLoading,
  formValues: getFormValues('productForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrefetchCategory: appActionTypes.getPrefetchCategory,
      getPrefetchShipping: appActionTypes.getPrefetchShipping,
      getProductPrice: productActions.getProductPrice,
      getProductTaxList: productActions.getProductTaxList
    },
    dispatch
  );

const CategoryFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductForm);

export default reduxForm({
  form: 'productForm' // a unique identifier for this form
})(CategoryFormWithRedux);
