import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { payout as payoutActions } from '../../actions';

function PayoutDownloadButton({ id, downloadPayoutInvoice }) {
  function handleInvoiceDownload() {
    downloadPayoutInvoice(id);
  }
  return (
    <button
      type="button"
      className="btn btn-icon btn-sm action-icon"
      onClick={handleInvoiceDownload}
    >
      <i className="action-icon mdi mdi-file-pdf" />
    </button>
  );
}

PayoutDownloadButton.propTypes = {
  id: PropTypes.number.isRequired,
  downloadPayoutInvoice: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      downloadPayoutInvoice: payoutActions.downloadPayoutInvoice
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(PayoutDownloadButton);
