import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';
import StoreForm from './StoreForm';
import { setting as settingActions } from '../../actions';
import InnerLoader from '../../components/InnerLoader';
import StoreAddress from './StoreAddress';
import PaymentGateway from './PaymentGateway';

class Store extends React.Component {
  componentDidMount() {
    const { getSetting } = this.props;
    getSetting('store');
  }

  handleSubmit = values => {
    const { updateSetting } = this.props;
    const formFields = [
      'address1',
      'address2',
      'country',
      'state',
      'city',
      'postcode',
      'vat',
      'phone',
      'selling_location',
      'shipping_location',
      'def_cust_location',
      'enable_tax',
      'enable_coupon',
      'def_currency',
      'currency_position',
      'thousand_sep',
      'decimal_sep',
      'decimal_places',
      'weight_unit',
      'dimension_unit',
      'BP_discount_assoc',
      'payout_date',
      'bp_value',
      'bp_discount',
      'cp_discount',
      'associate_signup_discount',
      'clp',
      'crp',
      'cp_expiry'
    ];
    const fieldValues = [];
    _.forEach(values, (val, field) => {
      if (formFields.indexOf(field) > -1) {
        fieldValues.push({
          key: field,
          value: val,
          options: 'store'
        });
      }
    });
    updateSetting({ settings: fieldValues });
  };

  render() {
    const { settings, isLoading, updateProgress } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.storeSetting.breadcrumb} />
              <h4 className="page-title">{routePath.storeSetting.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="address-wrapper">
                  <div className="row mb-2">
                    <div className="col">
                      <h4 className="header-title mb-3 text-primary">
                        Store Address
                      </h4>
                    </div>
                    <div className="col">
                      <Link
                        className="btn btn-primary float-right"
                        to="/setting/address/add"
                      >
                        <i className="mdi mdi-plus-circle mr-2" />
                        Add New
                      </Link>
                    </div>
                  </div>
                  <StoreAddress />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="address-wrapper">
                  <div className="row mb-2">
                    <div className="col">
                      <h4 className="header-title mb-3 text-primary">
                        Payment gateway Details
                      </h4>
                    </div>
                    <div className="col">
                      <Link
                        className="btn btn-primary float-right"
                        to="/setting/payment/add"
                      >
                        <i className="mdi mdi-plus-circle mr-2" />
                        Add New
                      </Link>
                    </div>
                  </div>
                  <PaymentGateway />
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <InnerLoader type="table" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <StoreForm
            submitForm={this.handleSubmit}
            inProgress={updateProgress}
            initialValues={settings}
            enableReinitialize="true"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.setting.isLoading,
  updateProgress: state.setting.updateProgress,
  settings: state.setting.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSetting: settingActions.updateSetting,
      getSetting: settingActions.getSetting
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Store);
