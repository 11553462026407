import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { change, Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  required,
  requiredIfOtherCity,
  requiredIfShipping,
  requiredIfShippingOtherCity
} from '../../utills/validator';
import { app as appActions, auth as authActions } from '../../actions';
import {
  checkboxField,
  inputField,
  phoneField,
  selectField
} from '../../components/Fields';

function RegisterAddressForm({
  initialValues,
  stateList,
  shippingStateList,
  getStateByCountry,
  getShippingStateByCountry,
  getPrefetchSetting,
  getPrefetchStoreAddress,
  handleSubmit,
  submitForm,
  countryList,
  cityList,
  shippingCityList,
  formValues,
  settings,
  storeAddress,
  dispatch,
  getShippingCityByState,
  getCityByState
}) {
  useEffect(() => {
    const { country, shipping_country: shippingCountry } = initialValues;
    // Get general settings
    getPrefetchSetting({
      options: 'general'
      // key: ['site_date_format', 'site_title', 'site_def_lang']
    });

    // Get state list by country unless state list is empty
    if (country && !stateList.length) {
      getStateByCountry(country);
    }
    if (shippingCountry && !shippingStateList.length) {
      getShippingStateByCountry(shippingCountry);
    }
  }, []);

  useEffect(() => {
    getPrefetchStoreAddress(initialValues.country);
  }, [initialValues.country]);

  const countryChange = val => {
    dispatch(change('registerAddressForm', 'city', ''));
    dispatch(change('registerAddressForm', 'state', ''));
    if (val) {
      getStateByCountry(val);
    }
  };

  const stateChange = val => {
    dispatch(change('registerAddressForm', 'city', ''));
    if (val) {
      getCityByState(val);
    }
  };

  const shippingCountryChange = val => {
    dispatch(change('registerAddressForm', 'shipping_city', ''));
    dispatch(change('registerAddressForm', 'shipping_state', ''));
    if (val) {
      getShippingStateByCountry(val);
    }
  };

  const shippingStateChange = val => {
    dispatch(change('registerAddressForm', 'shipping_city', ''));
    if (val) {
      getShippingCityByState(val);
    }
  };

  const shippingSection = val => {
    if (val === 1) {
      dispatch(change('registerAddressForm', 'same_as_billing', true));
    }
  };

  return (
    <Form
      className="needs-validation registration-address-form"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <h5 className="section-title pb-2 border-bottom mb-2 text-primary">
        Billing Address
      </h5>
      <div className="row">
        <div className="col-md-6">
          <Field
            name="street_name_number"
            component={inputField}
            type="text"
            className="form-control"
            label="Street name &amp; no."
            placeholder=""
          />
        </div>
        <div className="col-md-6">
          <Field
            name="address_line1"
            component={inputField}
            type="text"
            className="form-control"
            label="Address"
            placeholder="Apartment number, suite, unit, building, floor, etc."
            validate={[required]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Field
            name="country"
            id="country"
            disabled
            component={selectField}
            label="Country"
            feedback={countryChange}
            options={countryList}
            validate={[required]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="state"
            id="state"
            component={selectField}
            label="State"
            feedback={stateChange}
            options={stateList}
            validate={[required]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="city"
            id="city"
            component={selectField}
            label="City"
            options={[{ label: 'Others', value: 'others' }, ...cityList]}
            validate={[required]}
          />
        </div>
        {formValues.city === 'others' && (
          <div className="col-12">
            <Field
              name="otherCity"
              id="otherCity"
              component={inputField}
              type="text"
              className="form-control"
              label="Other City"
              validate={[requiredIfOtherCity]}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-md-6">
          <Field
            name="postcode"
            component={inputField}
            type="text"
            className="form-control"
            label="Postcode"
            validate={[required]}
          />
        </div>
        <div className="col-md-6">
          <Field
            name="mobile_number"
            component={phoneField}
            className="form-control"
            label="Mobile or Phone"
            validate={[required]}
          />
        </div>
      </div>
      {storeAddress && storeAddress.store_pickup ? (
        <>
          <Field
            name="pickup_from_store"
            component={checkboxField}
            id="pickup_from_store"
            labelClass="mb-0 mt-2"
            groupClassName="form-group mb-0"
            feedback={shippingSection}
            options={[{ value: '1', label: 'Pick-up from store' }]}
          />
          {!formValues.pickup_from_store ? (
            <div className="row">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto mt-1">OR</div>
              <div className="col">
                <hr />
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      {!formValues.pickup_from_store && (
        <>
          <Field
            name="same_as_billing"
            component={checkboxField}
            id="same_as_billing"
            disabled={formValues.pickup_from_store}
            options={[
              {
                value: '1',
                label: 'My Shipping address is same as Billing',
                disable: true
              }
            ]}
          />
          {!formValues.same_as_billing && !formValues.pickup_from_store ? (
            <>
              <h5 className="section-title pb-2 border-bottom text-primary mb-3 mt-3">
                Shipping Address
              </h5>
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="shipping_street_name_number"
                    component={inputField}
                    type="text"
                    className="form-control"
                    label="Street name &amp; no."
                    placeholder=""
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="shipping_address_line1"
                    component={inputField}
                    type="text"
                    className="form-control"
                    label="Address"
                    placeholder="Apartment number, suite, unit, building, floor, etc."
                    validate={[requiredIfShipping]}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <Field
                    name="shipping_country"
                    id="shipping_country"
                    component={selectField}
                    disabled
                    label="Country"
                    feedback={shippingCountryChange}
                    options={countryList}
                    validate={[requiredIfShipping]}
                  />
                </div>
                <div className="col-md-4">
                  <Field
                    name="shipping_state"
                    id="shipping_state"
                    component={selectField}
                    label="State"
                    feedback={shippingStateChange}
                    options={shippingStateList}
                    validate={[requiredIfShipping]}
                  />
                </div>
                <div className="col-md-4">
                  <Field
                    name="shipping_city"
                    id="shipping_city"
                    component={selectField}
                    label="City"
                    options={[
                      { label: 'Others', value: 'others' },
                      ...shippingCityList
                    ]}
                    validate={[requiredIfShipping]}
                  />
                </div>
              </div>
              <div className="row">
                {formValues.shipping_city === 'others' && (
                  <div className="col-md-12">
                    <Field
                      name="shipping_otherCity"
                      id="shipping_otherCity"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Other City"
                      validate={[requiredIfShippingOtherCity]}
                    />
                  </div>
                )}
                <div className="col-md-6">
                  <Field
                    name="shipping_postcode"
                    component={inputField}
                    type="text"
                    className="form-control"
                    label="Postcode"
                    validate={[requiredIfShipping]}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="shipping_mobile_number"
                    component={phoneField}
                    className="form-control"
                    label="Mobile or Phone"
                    validate={[required]}
                  />
                </div>
              </div>
            </>
          ) : null}
        </>
      )}
    </Form>
  );
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  shippingStateList: state.auth.shippingStateList,
  shippingCityList: state.auth.shippingCityList,
  settings: state.app.settings,
  storeAddress: state.app.storeAddress,
  formValues: getFormValues('registerAddressForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState,
      getShippingStateByCountry: authActions.getStateByCountry,
      getShippingCityByState: authActions.getCityByState,
      getPrefetchSetting: appActions.getPrefetchSetting,
      getPrefetchStoreAddress: appActions.getPrefetchStoreAddress
    },
    dispatch
  );

const RegisterAddressFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterAddressForm);

export default reduxForm({
  form: 'registerAddressForm'
})(RegisterAddressFormConnected);
