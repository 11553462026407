/* global appConfig */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, getFormValues } from 'redux-form';
import { debounce, get } from 'lodash';
import { Overlay, Tooltip } from 'react-bootstrap';
import {
  required,
  requiredIfIndividual,
  email,
  alpha,
  passwordRules
} from '../../utills/validator';
import { app as appActions } from '../../actions';
import { registrationAsyncValidate } from '../../utills/asyncValidator';
import {
  inputField,
  radioField,
  dateField,
  inputFieldControlled,
  checkboxField,
  selectField
} from '../../components/Fields';
import { ajax } from '../../utills/ajax';
import btnLoader from '../../assets/images/btn-loader.gif';
import avatar from '../../assets/images/avatar.svg';
import { profileImage } from '../../constants/imageSize';
import Image from '../../components/Image';
import ShowHidePasswordField from '../../components/ShowHidePasswordField';

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSponsor: false,
      sponsorData: false,
      passwordStrengthClass: '',
      passwordStrengthPercent: 0,
      tooltipOpen: false,
      tooltipTarget: React.createRef()
    };
    this.strengthPassed = [
      <li className="text-left">
        <span>Use at least one uppercase character</span>
      </li>,
      <li className="text-left">
        <span>Use at least one lowercase character</span>
      </li>,
      <li className="text-left">
        <span>Use at least one digit</span>
      </li>,
      <li className="text-left">
        <span>Use at least one special character ($@$!%*#?&)</span>
      </li>,
      <li className="text-left">
        <span>Must be at least 8 character long</span>
      </li>
    ];
    this.stateChangeDebounce = debounce(this.checkSponsor, 50);
    this.onRefChange = this.onRefChange.bind(this);
  }

  componentDidMount() {
    const { getCountry, initialValues, refUserId } = this.props;
    getCountry();
    this.checkSponsor(initialValues.referrer_id);
  }

  onRefChange(value) {
    this.setState({
      refUUId: value
    });
  }

  toggleTooltip = () => {
    this.setState(a => ({
      tooltipOpen: !a.tooltipOpen
    }));
  };

  checkPasswordStrength = val => {
    const matchedCase = passwordRules;
    const passed = [];
    let ctr = 0;
    matchedCase.forEach((item, key) => {
      if (new RegExp(item.pattern).test(val)) {
        ctr += 1;
        passed.push(
          <li key={key} className="text-left">
            <del>{item.text}</del>
          </li>
        );
      } else {
        passed.push(
          <li key={key} className="text-left">
            <span>{item.text}</span>
          </li>
        );
      }
    });
    this.strengthPassed = passed;
    switch (ctr) {
      case 5:
        this.setState({
          passwordStrengthClass: 'bg-success',
          passwordStrengthPercent: 100,
          tooltipOpen: false
        });
        break;
      case 4:
        this.setState({
          passwordStrengthClass: 'bg-warning',
          passwordStrengthPercent: 80,
          tooltipOpen: true
        });
        break;
      case 3:
        this.setState({
          passwordStrengthClass: 'bg-warning',
          passwordStrengthPercent: 50,
          tooltipOpen: true
        });
        break;
      case 2:
        this.setState({
          passwordStrengthClass: 'bg-info',
          passwordStrengthPercent: 30,
          tooltipOpen: true
        });
        break;
      case 1:
        this.setState({
          passwordStrengthClass: 'bg-info',
          passwordStrengthPercent: 10,
          tooltipOpen: true
        });
        break;
      default:
        this.setState({
          passwordStrengthClass: '',
          passwordStrengthPercent: 0,
          tooltipOpen: true
        });
    }
    if (!val) {
      this.setState({ tooltipOpen: false });
    }
  };

  checkSponsor = async val => {
    if (val.length > 5) {
      try {
        this.setState({ checkingSponsor: true });
        const data = await ajax({
          url: `${process.env.REACT_APP_API_URL}/check-sponsor`,
          method: 'POST',
          data: { referrer_id: val, with_customer: false },
          hideMessage: true
        });
        console.log('sponsor', data);
        this.setState({ sponsorData: data, checkingSponsor: false });
      } catch (error) {
        this.setState({ sponsorData: null, checkingSponsor: false });
      }
    } else {
      this.setState({ sponsorData: false, checkingSponsor: false });
    }
  };

  goBack = () => {
    const { moveBack } = this.props;
    moveBack();
  };

  render() {
    console.log('uuid', this.props);
    const {
      handleSubmit,
      submitForm,
      moveNext,
      step,
      stepProcessing,
      inProgress,
      formValues,
      countryList,
      refUserId
    } = this.props;
    const {
      checkingSponsor,
      sponsorData,
      passwordStrengthClass,
      passwordStrengthPercent,
      tooltipOpen,
      tooltipTarget,
      refUUId
    } = this.state;

    let pImage = get(sponsorData, 'profile_picture_file.file_path', null);
    if (pImage) {
      pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
    } else {
      pImage = avatar;
    }

    return (
      <Form
        className="needs-validation registration-form"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        {step === 1 ? (
          <div>
            <Field
              name="referrer_id"
              component={inputFieldControlled}
              type="text"
              className="form-control referrer_id"
              label="Member ID"
              placeholder="Enter your Member ID"
              validate={[required]}
              feedback={this.stateChangeDebounce}
            />

            {checkingSponsor || sponsorData !== false ? (
              <div className="card card-fluid">
                <div className="card-body">
                  {checkingSponsor ? (
                    <div className="media align-items-center">
                      {checkingSponsor ? <p>Checking..</p> : null}
                    </div>
                  ) : (
                    <div className="media align-items-center">
                      {sponsorData ? (
                        <>
                          <div className="user-avatar user-avatar-xl mr-3 sponsor-avatar">
                            <Image
                              className="rounded-circle user-avatar-xl"
                              src={pImage}
                              alt={`${sponsorData.first_name} ${sponsorData.last_name}`}
                              size={profileImage.profileThumbnail}
                            />
                          </div>
                          <div className="media-body">
                            <h4 className="card-title mb-2 text-truncate">{`${sponsorData.first_name} ${sponsorData.last_name}`}</h4>
                            <h6 className="card-subtitle text-muted">
                              <span
                                className={`flag-icon flag-icon-${sponsorData.associate_info.country.toLowerCase()}`}
                              />
                              <span className="ml-1">
                                {sponsorData.associate_info.country}
                              </span>
                            </h6>
                          </div>
                        </>
                      ) : (
                        <p style={{ color: 'red' }}>Invalid Member ID</p>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : null}
            <div className="text-center">
              <button
                type="button"
                disabled={stepProcessing || checkingSponsor}
                className="btn btn-primary"
                onClick={moveNext}
              >
                {stepProcessing ? <img src={btnLoader} alt="" /> : 'Continue'}
              </button>
            </div>
          </div>
        ) : null}
        {step === 2 ? (
          <div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="user_type"
                  component={radioField}
                  className="custom-control-label"
                  isInline
                  options={[
                    { label: 'Individual', value: 'individual' },
                    { label: 'Company', value: 'company' }
                  ]}
                  label="Are you?"
                  selected="Individual"
                  validate={[required]}
                />
              </div>
              {formValues.user_type === 'company' && (
                <div className="col-md-6">
                  <Field
                    name="company"
                    component={inputField}
                    type="text"
                    className="form-control"
                    label="Company Name"
                    placeholder=""
                    validate={[requiredIfIndividual]}
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="first_name"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="First Name"
                  placeholder="Enter your first name"
                  validate={[required, alpha]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="last_name"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Last Name"
                  placeholder="Enter your last name"
                  validate={[required, alpha]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="email"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Email"
                  placeholder="e.g. hello@example.com"
                  validate={[required, email]}
                />
              </div>
              <div className="col-md-6">
                <ShowHidePasswordField
                  name="password"
                  label="Password"
                  placeholder="Choose a password"
                  feedback={this.checkPasswordStrength}
                  validate={[required]}
                />
                <div className="row">
                  <div className="col-12 col-md-10">
                    <div className="progress" style={{ height: 7 }}>
                      <div
                        className={`bar progress-bar progress-bar-striped ${passwordStrengthClass}`}
                        style={{ width: `${passwordStrengthPercent}%` }}
                      />
                    </div>
                    <div className="d-md-none mt-2 mt-md-0">
                      <span className="font-weight-semibold">
                        Password Guide
                      </span>
                      <ul className="mt-1 pass-guide-info pl-3">
                        {this.strengthPassed}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-2 text-right d-none d-md-inline-block">
                    <button
                      type="button"
                      className="btn btn-icon btn-sm pass-info p-0"
                      onClick={() => this.toggleTooltip()}
                      id="TooltipExample"
                      ref={tooltipTarget}
                    >
                      <i className="mdi mdi-information-outline" />
                    </button>
                    <Overlay
                      target={tooltipTarget.current}
                      show={tooltipOpen}
                      placement="right"
                    >
                      {props => (
                        <Tooltip
                          id="overlay-example"
                          className="d-none d-md-block"
                          {...props}
                        >
                          <div>
                            <strong>Password Guide</strong>
                            <ul className="mt-1 pass-guide-info pl-3">
                              {this.strengthPassed}
                            </ul>
                          </div>
                        </Tooltip>
                      )}
                    </Overlay>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  name="country"
                  id="country"
                  component={selectField}
                  label="Country"
                  feedback={this.countryChange}
                  options={countryList}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="phone_number"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Phone Number"
                  placeholder="e.g. +44 1632960170"
                  validate={[required]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="tax_number"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Tax Number"
                  placeholder={
                    formValues.user_type === 'company'
                      ? 'Registration number'
                      : 'Your Tax number'
                  }
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Field
                  name="gender"
                  component={radioField}
                  className="custom-control-label"
                  isInline
                  options={[
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' }
                  ]}
                  label="Gender"
                  validate={[required]}
                />
              </div>
              <div className="col-md-6">
                <Field
                  name="dob"
                  component={dateField}
                  className="form-control"
                  label="Birthday (dd/mm/yyyy)"
                  max={new Date()}
                  validate={[required]}
                />
              </div>
            </div>
            <Field
              name="terms"
              isInline
              component={checkboxField}
              groupClassName="form-group mb-0"
              options={[
                {
                  value: 'yes',
                  label: (
                    <span>
                      {' '}
                      By creating an account, you agree to the
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={appConfig.companyTermsURL}
                      >
                        {' '}
                        {appConfig.companyTermsText}
                      </a>
                    </span>
                  )
                }
              ]}
              validate={[required]}
            />
            <div className="row mt-4">
              <div className="col-5 col-sm-6">
                <button
                  type="button"
                  disabled={inProgress}
                  onClick={this.goBack}
                  className="btn btn-outline-primary btn-block border-radius-left-o"
                >
                  {' '}
                  Back
                </button>
              </div>
              <div className="col-7 col-sm-6">
                <button
                  type="submit"
                  disabled={inProgress}
                  className="btn btn-primary btn-block border-radius-right-o"
                >
                  {inProgress ? <img src={btnLoader} alt="" /> : 'Continue'}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('registrationForm')(state),
  countryList: state.app.countryList,
  refUserId: state.auth.refUserId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry
    },
    dispatch
  );

const RegistrationFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationForm);

export default reduxForm({
  form: 'registrationForm', // a unique identifier for this form
  asyncValidate: registrationAsyncValidate,
  enableReinitialize: true,
  asyncChangeFields: ['email']
})(RegistrationFormConnected);
