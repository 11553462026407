import React from 'react';
import { Link } from 'react-router-dom';
import routePath from '../../constants/routePath';
import LayoutRegister from '../../components/LayoutRegister';

function Cancel() {
  return (
    <LayoutRegister>
      <div className="card-body ph-5 pt-4 pb-5">
        <div className="text-center">
          <h2 className="mt-0">
            <i className="mdi mdi-close-circle text-danger font-60" />
          </h2>
          <h3 className="mt-0">Canceled!</h3>
          <p className="w-75 mb-3 mx-auto">
            Your payment is either canceled or failed. Don&apos;t worry, you can
            try again
          </p>
          <Link to={routePath.cart.path} className="btn btn-primary btn-lg">
            Back to Payment
          </Link>
        </div>
      </div>
    </LayoutRegister>
  );
}

export default Cancel;
