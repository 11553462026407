/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { storePaymentGateway as storePaymentGatewayTypes } from '../../actions';

import PaymentGatewayDetailsForm from './PaymentGatewayForm';

const PaymentGatewayDetailsEdit = ({
  match: { params },
  getStorePaymentGateway,
  storePaymentGateway,
  updateStorePaymentGateway
}) => {
  useEffect(() => {
    if (params && params.id) getStorePaymentGateway({ iso2: params.id });
  }, [params, getStorePaymentGateway]);

  return (
    <PaymentGatewayDetailsForm
      title="Edit Gateway"
      onSubmit={updateStorePaymentGateway}
      isEdit
      countryProp={
        storePaymentGateway.data && storePaymentGateway.data.country_settings
          ? storePaymentGateway.data.country_settings.country_iso2
          : ''
      }
      activeModeProp={
        storePaymentGateway.data && storePaymentGateway.data.country_settings
          ? storePaymentGateway.data.country_settings.active_mode
          : []
      }
      paymentGatewayList={
        storePaymentGateway.data && storePaymentGateway.data.country_settings
          ? storePaymentGateway.data.country_settings.payment_gateway
          : null
      }
    />
  );
};

const MSTP = state => ({
  storePaymentGateway: state.storePaymentGateway
});

const MDSTP = {
  getStorePaymentGateway: storePaymentGatewayTypes.getStorePaymentGateway,
  updateStorePaymentGateway: storePaymentGatewayTypes.updateStorePaymentGateway
};

export default connect(
  MSTP,
  MDSTP
)(PaymentGatewayDetailsEdit);
