import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import PageLoader from '../../components/PageLoader';
import { order as orderActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import InvoiceViewComponent from './InvoiceViewComponent';

class ConfirmationZottoPay extends React.Component {
  componentDidMount() {
    const { location, getOrderByTnxId } = this.props;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('rtorder_id')) {
      getOrderByTnxId(searchParams.get('rtorder_id'));
    }
  }

  render() {
    const { order } = this.props;
    let data = null;
    if (!isEmpty(order)) {
      data = order;
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.invoiceView.breadcrumb} />
              <h4 className="page-title">Invoice</h4>
            </div>
          </div>
        </div>
        <div className="card">
          {data ? <InvoiceViewComponent data={data} /> : <PageLoader />}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  order: state.order.tnxDetails
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderByTnxId: orderActions.getOrderByTnxId
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationZottoPay);
