import { singleLocation as singleLocationActionTypes } from '../actions';
import initialState from '../constants/initialState';

function singleLocation(state = initialState.singleLocation, action) {
  switch (action.type) {
    case singleLocationActionTypes.SINGLE_LOCATION_REQUEST:
      return {
        ...state,
        data: {},
        isLoading: true
      };
    case singleLocationActionTypes.SINGLE_LOCATION_SUCCESS: {
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          data: action.data
        };
      }
      return state;
    }

    default:
      return state;
  }
}

export default singleLocation;
