const auth = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERROR: 'REGISTER_ERROR',
  REF_USER_ID_REQUEST: 'REF_USER_ID_REQUEST',
  REF_USER_ID_SUCCESS: 'REF_USER_ID_SUCCESS',
  REF_USER_ID_ERROR: 'REF_USER_ID_ERROR',
  USER_INFO_REQUEST: 'USER_INFO_REQUEST',
  USER_INFO_SUCCESS: 'USER_INFO_SUCCESS',
  USER_INFO_ERROR: 'USER_INFO_ERROR',
  PACKAGE_SELECT_REQUEST: 'PACKAGE_SELECT_REQUEST',
  PACKAGE_SELECT_SUCCESS: 'PACKAGE_SELECT_SUCCESS',
  SUBSCRIPTION_CALCULATION_REQUEST: 'SUBSCRIPTION_CALCULATION_REQUEST',
  SUBSCRIPTION_CALCULATION_SUCCESS: 'SUBSCRIPTION_CALCULATION_SUCCESS',
  SUBSCRIPTION_CALCULATION_ERROR: 'SUBSCRIPTION_CALCULATION_ERROR',
  SUBSCRIPTION_REQUEST: 'SUBSCRIPTION_REQUEST',
  SUBSCRIPTION_SUCCESS: 'SUBSCRIPTION_SUCCESS',
  SUBSCRIPTION_ERROR: 'SUBSCRIPTION_ERROR',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
  SUBSCRIPTION_STATUS_REQUEST: 'SUBSCRIPTION_STATUS_REQUEST',
  SUBSCRIPTION_STATUS_SUCCESS: 'SUBSCRIPTION_STATUS_SUCCESS',
  SUBSCRIPTION_STATUS_ERROR: 'SUBSCRIPTION_STATUS_ERROR',
  SHIPPING_STATE_LIST_REQUEST: 'SHIPPING_STATE_LIST_REQUEST',
  SHIPPING_STATE_LIST_SUCCESS: 'SHIPPING_STATE_LIST_SUCCESS',
  SHIPPING_CITY_LIST_REQUEST: 'SHIPPING_CITY_LIST_REQUEST',
  SHIPPING_CITY_LIST_SUCCESS: 'SHIPPING_CITY_LIST_SUCCESS',
  SUBSCRIPTION_SHIPPING_OPTION_REQUEST: 'SUBSCRIPTION_SHIPPING_OPTION_REQUEST',
  SUBSCRIPTION_SHIPPING_OPTION_SUCCESS: 'SUBSCRIPTION_SHIPPING_OPTION_SUCCESS',
  SUBSCRIPTION_SHIPPING_OPTION_ERROR: 'SUBSCRIPTION_SHIPPING_OPTION_ERROR',
  doLogin: data => ({ type: auth.LOGIN_REQUEST, data }),
  doLogout: data => ({ type: auth.LOGOUT_REQUEST, data }),
  doRegister: data => ({ type: auth.REGISTER_REQUEST, data }),
  getUserInfo: data => ({ type: auth.USER_INFO_REQUEST, data }),
  setPackage: id => ({ type: auth.PACKAGE_SELECT_REQUEST, id }),
  getSubscriptionTotalDetails: data => ({
    type: auth.SUBSCRIPTION_CALCULATION_REQUEST,
    data
  }),
  getSubscriptionShippingOption: (params, callback) => ({
    type: auth.SUBSCRIPTION_SHIPPING_OPTION_REQUEST,
    params,
    callback
  }),
  subscribe: data => ({ type: auth.SUBSCRIPTION_REQUEST, data }),
  forgotPassword: data => ({ type: auth.FORGOT_PASSWORD_REQUEST, data }),
  resetPassword: data => ({ type: auth.RESET_PASSWORD_REQUEST, data }),
  changePassword: data => ({ type: auth.CHANGE_PASSWORD_REQUEST, data }),
  getSubscriptionStatus: () => ({ type: auth.SUBSCRIPTION_STATUS_REQUEST }),
  getStateByCountry: data => ({ type: auth.SHIPPING_STATE_LIST_REQUEST, data }),
  getCityByState: data => ({ type: auth.SHIPPING_CITY_LIST_REQUEST, data }),
  getRefUserId: data => ({ type: auth.REF_USER_ID_REQUEST, data })
};

export default auth;
