import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import { coupons as couponActions } from '../../actions';
import routePath from '../../constants/routePath';
import AdvanceTable from '../../components/AdvanceTable';

class CouponHistoryView extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  columns = [
    {
      dataField: 'order_id',
      text: 'Order ID',
      headerClasses: 'text-center',
      headerAttrs: { width: '10%' },
      classes: 'text-center',
      formatter: col => {
        return (
          <>
            <Link to={`/order/${col}/invoice`}>
              <span className="dashed-underline p-0">{col}</span>
            </Link>
          </>
        );
      }
    },
    {
      dataField: 'user.uuid',
      text: 'UUID',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'user',
      text: 'Full Name',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: col => (
        <>
          <span>
            {col.first_name} {col.last_name}
          </span>
        </>
      )
    },
    {
      dataField: 'user.email',
      text: 'Email',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'user.phone_number',
      text: 'Phone',
      headerClasses: 'text-center',
      classes: 'text-center'
    }
  ];

  componentDidMount() {
    const { getOrderCoupon, match } = this.props;
    const data = { code: match.params.id };
    getOrderCoupon(data);
  }

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getOrderCoupon, match } = this.props;
    const data = { page, per_page: perPage };
    if (match.params && match.params.id) {
      data.id = match.params.id;
    }
    getOrderCoupon(data);
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  render() {
    const { consumptionHistory, isLoading, filterValues } = this.props;
    const { page, selected } = this.state;
    const orders = get(consumptionHistory.data[0], 'orders', []);
    const code = get(consumptionHistory.data[0], 'code', '');

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.consumptionHistoryView.breadcrumb} />
              <h4 className="page-title">{code}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <AdvanceTable
                  keyField="order_id"
                  data={orders}
                  columns={this.columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={orders.length}
                  sizePerPage={consumptionHistory.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  consumptionHistory: state.coupons.allOrder,
  appSetting: state.app.settings,
  isLoading: state.coupons.isLoading,
  updateProgress: state.coupons.updateProgress,
  filterValues: state.app.settings.filter_values,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderCoupon: couponActions.getOrderCoupon
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponHistoryView);

CouponHistoryView.defaultProps = {
  getOrderCoupon: () => {},
  match: {},
  consumptionHistory: {},
  isLoading: false,
  filterValues: []
};

CouponHistoryView.propTypes = {
  getOrderCoupon: PropTypes.func,
  match: PropTypes.objectOf(PropTypes.any),
  consumptionHistory: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
  filterValues: PropTypes.arrayOf(PropTypes.any)
};
