import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';

import {
  editorField,
  inputField,
  selectField,
  textareaField
} from '../../components/Fields';
import { timezoneDropdown, languageDropdown } from '../../utills/custom';
import { email, number, required } from '../../utills/validator';
import btnLoader from '../../assets/images/btn-loader.gif';

class GeneralForm extends React.PureComponent {
  render() {
    const {
      inProgress,
      pristine,
      submitting,
      handleSubmit,
      submitForm
    } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <h4 className="header-title mb-3 text-primary">General</h4>
        <div className="row">
          <div className="col-md-7">
            <Field
              name="site_title"
              className="form-control"
              type="text"
              component={inputField}
              placeholder=""
              label="Site title"
            />
          </div>
          <div className="col-md-5">
            <Field
              name="site_email"
              component={inputField}
              type="text"
              className="form-control"
              label="Site Email"
              placeholder="e.g. admin@example.com"
              validate={[email]}
            />
          </div>
          <div className="col-md-12">
            <Field
              name="site_desc"
              className="form-control"
              type="text"
              component={textareaField}
              placeholder="..."
              label="Site Description"
              helpBlock="In a few words, explain what the site is about"
            />
          </div>
          <div className="col-md-3">
            <Field
              name="site_def_lang"
              component={selectField}
              notSearchable
              label="Site default language"
              options={languageDropdown()}
            />
          </div>
          <div className="col-md-3">
            <Field
              name="site_date_format"
              component={selectField}
              notSearchable
              label="Site date format"
              options={[
                { label: 'dd/mm/yyyy', value: 'DD/MM/YYYY' },
                { label: 'mm/dd/yyyy', value: 'MM/DD/YYYY' },
                { label: 'yyyy/mm/dd', value: 'YYYY/MM/DD' }
              ]}
            />
          </div>
          <div className="col-md-6">
            <Field
              name="site_timezone"
              component={selectField}
              notSearchable
              label="Timezone"
              options={timezoneDropdown()}
              helpBlock="Choose timezone as you or a UTC timezone offset."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h4 className="header-title mt-3 mb-3 text-primary">
              Admin Display Records
            </h4>
            <div className="row">
              <div className="col-md-4">
                <Field
                  name="display_admin_cat"
                  component={inputField}
                  type="number"
                  className="form-control w-60"
                  label="Categories"
                  placeholder=""
                  validate={[number]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="display_admin_products"
                  component={inputField}
                  type="number"
                  className="form-control w-60"
                  label="Products"
                  placeholder=""
                  validate={[number]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="display_admin_orders"
                  component={inputField}
                  type="number"
                  className="form-control w-60"
                  label="Orders"
                  placeholder=""
                  validate={[number]}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <h4 className="header-title mt-3 mb-3 text-primary">
              Frontend Display Records
            </h4>
            <div className="row">
              <div className="col-md-4">
                <Field
                  name="show_cat"
                  component={inputField}
                  type="number"
                  className="form-control w-60"
                  label="Categories"
                  placeholder=""
                  validate={[number]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="show_products"
                  component={inputField}
                  type="number"
                  className="form-control w-60"
                  label="Products"
                  placeholder=""
                  validate={[number]}
                />
              </div>
              <div className="col-md-4">
                <Field
                  name="show_orders"
                  component={inputField}
                  type="number"
                  className="form-control w-60"
                  label="Orders"
                  placeholder=""
                  validate={[number]}
                />
              </div>
            </div>
          </div>
        </div>
        <h4 className="header-title mt-3 mb-3 text-primary">Invoice Details</h4>
        <div className="row">
          <div className="col-md-12">
            <Field
              name="invoice_details"
              className="form-control"
              component={editorField}
              placeholder="..."
              label="Invoice Details"
            />
          </div>
        </div>
        <div className="form-group mt-2">
          <Button
            type="submit"
            disabled={pristine || submitting}
            variant="primary"
          >
            {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
          </Button>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const EditGeneralWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralForm);

export default reduxForm({
  form: 'editGeneralForm' // a unique identifier for this form
})(EditGeneralWithRedux);
