import React from 'react';
import { Card } from 'react-bootstrap';
import { OverlayLoader } from './InnerLoader';
import getDuration from '../utills/getDuration';

function StatBox({
  isLoading = false,
  title = '',
  growth = '0%',
  previousValue = '0',
  currentValue = '0',
  icon = '',
  filter,
  start,
  end
}) {
  const { prev, next, start: startDate, end: endDate } = getDuration(
    filter,
    start,
    end
  );
  const growthValue = typeof growth === 'string' ? growth.split('%') : growth;
  const feedback = growthValue >= 0 ? 'text-success' : 'text-danger';
  return (
    <Card className="mb-1 h-100 stat-card">
      {isLoading && <OverlayLoader type="spinner" />}
      <div className="stat-row pl-2 pr-2 pt-2">
        <div>
          <div className="stat-title font-weight-bold text-uppercase">
            {title}
          </div>
          <div className={`statbox-highlight ${feedback}`}>{growthValue}%</div>
        </div>
        {icon && (
          <i className={`mdi ${icon} float-right widget widget-icon-circle`} />
        )}
      </div>
      <div className="stat-row pl-2 pr-2 pb-2">
        <div>
          <div className="stat-value">{startDate}</div>
          <div className="stat-filter font-weight-semibold text-uppercase">
            {prev}
          </div>
          <div className="stat-value">{previousValue}</div>
        </div>
        <div>
          <div className="stat-value">{endDate}</div>
          <div className="stat-filter font-weight-semibold text-uppercase">
            {next}
          </div>
          <div className="stat-value">{currentValue}</div>
        </div>
      </div>
    </Card>
  );
}

export default StatBox;
