import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _, { find } from 'lodash';
import BreadCrumb from '../../components/BreadCrumb';
import { setting as settingActions, tax as taxActions } from '../../actions';
import routePath from '../../constants/routePath';
import TaxSettingForm from './TaxSettingForm';
import AdvanceTable from '../../components/AdvanceTable';
import InnerLoader from '../../components/InnerLoader';
import StatusSwitch from '../../components/StatusSwitch';

class TaxConfig extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  columns = [
    {
      dataField: 'tax_rate_country',
      text: 'Country',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'tax_rate_state',
      text: 'State',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (col, row) => (row.state ? row.state.name : row.tax_rate_state)
    },
    {
      dataField: 'tax_rate_city',
      text: 'City',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (col, row) => (row.city ? row.city.name : row.tax_rate_city)
    },
    {
      dataField: 'tax_rate_postcode',
      text: 'Postcode/ZIP',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'tax_rate',
      text: 'Rate',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'tax_rate_name',
      text: 'Tax Name',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'status',
      text: 'Active?',
      headerClasses: 'text-center',
      classes: 'text-center',
      headerAttrs: { width: '10%' },
      formatter: (col, row) => (
        <StatusSwitch
          checked={parseInt(col, 10)}
          onChange={this.updateTaxStatus}
          id={row.id}
          idPrefix="assos_sts_"
        />
      )
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      headerClasses: 'text-center',
      headerAttrs: { width: '10%' },
      classes: 'text-center',
      formatter: (col, row) => (
        <>
          <Link to={`/tax/${row.id}/edit`}>
            <i className="action-icon mdi mdi-square-edit-outline" />
          </Link>
        </>
      )
    }
  ];

  componentDidMount() {
    const { getSetting, getAllTaxes } = this.props;
    getSetting('tax');
    getAllTaxes();
  }

  updateTaxStatus = (id, val) => {
    const { updateTaxStatus } = this.props;
    updateTaxStatus({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getAllTaxes, match } = this.props;
    const data = { page, per_page: perPage };
    if (match.params && match.params.id) {
      data.id = match.params.id;
    }
    getAllTaxes(data);
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleSubmit = values => {
    const { updateSetting } = this.props;
    const formFields = [
      'price_tax',
      'calc_tax',
      'shipping_tax_class',
      'round_tax',
      'display_price_shop',
      'display_price_cart_checkout',
      'price_display_suffix',
      'display_tax_total'
    ];
    const fieldValues = [];
    _.forEach(values, (val, field) => {
      if (formFields.indexOf(field) > -1) {
        fieldValues.push({
          key: field,
          value: val,
          options: 'tax'
        });
      }
    });
    updateSetting({ settings: fieldValues });
  };

  render() {
    const {
      taxes,
      settings,
      isLoading,
      updateProgress,
      filterValues
    } = this.props;
    const { page, selected } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.tax.breadcrumb} />
              <h4 className="page-title">{routePath.tax.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading && <InnerLoader type="table" />}
                {!isLoading && (
                  <TaxSettingForm
                    submitForm={this.handleSubmit}
                    inProgress={updateProgress}
                    initialValues={settings}
                    enableReinitialize="true"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <Link
                      to={routePath.taxAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      Add New
                    </Link>
                  </div>
                </div>
                <AdvanceTable
                  keyField="id"
                  data={taxes.data}
                  columns={this.columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={taxes.total}
                  sizePerPage={taxes.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  taxes: state.tax.allList,
  isLoading: state.tax.isLoading,
  updateProgress: state.setting.updateProgress,
  settings: state.setting.data,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllTaxes: taxActions.getAllTaxes,
      updateSetting: settingActions.updateSetting,
      getSetting: settingActions.getSetting,
      updateTaxStatus: taxActions.updateTaxStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxConfig);
