import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Nav, Tab } from 'react-bootstrap';

import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import { app as appActions, payout as payoutActions } from '../../actions';
import routePath from '../../constants/routePath';
import PayoutDownloadButton from './PayoutDownloadButton';

const getStatusClass = status => {
  switch (status) {
    case 'PENDING':
      return 'badge badge-warning';
    case 'PAID':
      return 'badge badge-success';
    // case 'DECLINED':
    //   return 'badge badge-info';
    // case 'FAILED':
    //   return 'badge badge-danger';
    default:
      return 'badge badge-danger';
  }
};

class PayoutAssociate extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getAssociatePayout } = this.props;
    getAssociatePayout();
  }

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getAssociatePayout, match } = this.props;
    if (match.params && match.params.id) {
      getAssociatePayout({ page, per_page: perPage });
    }
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(prevState => ({
        selected: [...prevState.selected, ...ids]
      }));
    } else {
      this.setState(prevState => ({
        selected: prevState.selected.filter(x => !ids.includes(x))
      }));
    }
  };

  render() {
    const { payout, isLoading, appSetting, filterValues } = this.props;
    const { page, selected } = this.state;
    const columnsYearly = [
      {
        dataField: 'payout_no',
        text: 'Company Payout no.'
        // headerAttrs: { width: '12%' }
      },
      {
        dataField: 'type',
        text: 'Year'
      },
      {
        dataField: 'created',
        text: 'Date & Time',
        headerAttrs: { width: '12%' },
        formatter: col => (
          <span className="date">
            {moment(col).format(appSetting.site_date_format)}
            <br />
            <small>{moment(col).format('H:mm A')}</small>
          </span>
        )
      },
      {
        dataField: 'amount',
        text: 'Amount',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: col => <span>{`€${parseFloat(col).toFixed(2)}`}</span>
      },
      {
        dataField: 'status',
        text: 'Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '15%' },
        formatter: col => {
          if (col) {
            return <span className="badge badge-success">PAID</span>;
          }
          return null;
        }
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/payout/${row.id}/view`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
          </>
        )
      }
    ];

    const columnsMonthly = [
      {
        dataField: 'payout_no',
        text: 'Company Payout no.',
        formatter: col => <span>{col}</span>
      },
      {
        dataField: 'month',
        text: 'Month'
      },
      {
        dataField: 'date',
        text: 'Date & Time',
        headerAttrs: { width: '12%' },
        formatter: (col, row) => (
          <span className="date">
            {row.date}
            <br />
            <small>{row.time}</small>
          </span>
        )
      },
      {
        dataField: 'amount',
        text: 'Amount',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => (
          <span>
            {`${row.currency ? row.currency.currency_symbol : ''}${
              row.currency_amount
            }`}
          </span>
        )
      },
      {
        dataField: 'status',
        text: 'Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: col => {
          if (col) {
            return <span className="badge badge-success">PAID</span>;
          }
          return <span className="badge badge-warning">PENDING</span>;
        }
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/payout/${row.id}/statement`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
            <PayoutDownloadButton id={row.id} />
          </>
        )
      }
    ];
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.payout.breadcrumb} />
              <h4 className="page-title">{routePath.payout.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Tab.Container id="payouts-tabs" defaultActiveKey="monthly">
              <Nav className="nav nav-tabs nav-bordered">
                <Nav.Item>
                  <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="yearly">Yearly</Nav.Link>
                </Nav.Item> */}
              </Nav>

              <div className="card">
                <div className="card-body">
                  <Tab.Content>
                    <Tab.Pane eventKey="monthly">
                      <AdvanceTable
                        data={payout.data}
                        columns={columnsMonthly}
                        loading={isLoading}
                        remote={{ pagination: true }}
                        sizePerPageList={filterValues}
                        totalSize={payout.total}
                        sizePerPage={payout.per_page}
                        hideSizePerPage={false}
                        page={page}
                        onTableChange={this.handleTableChange}
                        onSelect={this.handleOnSelect}
                        onSelectAll={this.handleOnSelectAll}
                        selected={selected}
                      />
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="yearly">
                      <AdvanceTable
                        data={payout}
                        columns={columnsYearly}
                        loading={isLoading}
                      />
                    </Tab.Pane> */}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  payout: state.payout.assocMonthlist,
  appSetting: state.app.settings,
  isLoading: state.payout.isLoading,
  userInfo: state.auth.user,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAssociatePayout: payoutActions.getAssociatePayout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutAssociate);
