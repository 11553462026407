import React from 'react';

const Icon = ({ title, iconClassName, isAction, cursor }) => {
  const classNames = [
    'mdi',
    iconClassName || '',
    isAction ? 'action-icon' : ''
  ];
  return (
    <i className={classNames.join(' ')} title={title} style={{ cursor }} />
  );
};

export default Icon;
