import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import ProductModalForm from './ProductModalForm';
import ProductModalFormDisabled from './ProductModalFormDisabled';
import { calculateProductPrice } from '../../services/apiService';

function ProductStateModal({
  countryName,
  stateName,
  name,
  fieldValue,
  countryValue,
  taxes,
  currency,
  dispatch
}) {
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };

  const getPriceCalculation = useCallback(
    debounce(async data => {
      try {
        const res = await calculateProductPrice(data);
        dispatch(
          change('productForm', `${name}.member_price`, res.member_price)
        );
        dispatch(
          change(
            'productForm',
            `${name}.member_price_vat`,
            res.member_price_vat
          )
        );
        dispatch(
          change(
            'productForm',
            `${name}.customer_price_vat`,
            res.customer_price_vat
          )
        );
      } catch (error) {
        console.error(error);
      }
    }, 750),
    [name]
  );

  const handleChangePrice = (field, value) => {
    const data = {};
    const fields = [
      'taxable',
      'regular_price',
      'sale_price',
      'bpoint',
      'cpoint'
    ];
    fields.forEach(fname => {
      if (fname === field) {
        data[fname] = value;
      } else {
        data[fname] = fieldValue[fname] || 0;
      }
    });
    if (fieldValue.taxable) data.tax_rate = fieldValue.tax_id;

    getPriceCalculation(data);
  };

  const taxOptions = () => {
    const options = [];
    taxes.map(tax =>
      options.push({
        label: `${tax.tax_rate_name} - ${tax.tax_rate}%`,
        value: tax.id
      })
    );
    return options;
  };

  const handleSameAsCountry = e => {
    dispatch(change('productForm', `${name}.inherit`, e.target.checked));
  };

  return (
    <>
      <button
        type="button"
        className="text-primary ml-2 btn p-0"
        onClick={handleShowModal}
      >
        Edit
        <i className="mdi mdi-square-edit-outline ml-1" />
      </button>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="md"
        dialogClassName="modal-h80"
        aria-labelledby="contained-modal-title-vcenter"
        style={{
          visibility: 'hidden !important',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}
      >
        <Modal.Header closeButton>
          <h4 className="d-inline m-0">{`State: ${countryName} / ${stateName}`}</h4>
        </Modal.Header>
        <Modal.Body>
          <div
            className="border-bottom mb-2 d-flex align-items-center cursor-pointer"
            aria-hidden="true"
          >
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                id="same_as_country"
                checked={fieldValue.inherit}
                onChange={handleSameAsCountry}
                className="custom-control-input"
              />
              <label
                htmlFor="same_as_country"
                className="custom-control-label"
              />
            </div>
            <label
              htmlFor="same_as_country"
              className="h4 h4-state ml-1 cursor-pointer"
            >
              Same as Country Pricing
            </label>
          </div>
          {fieldValue.inherit ? (
            <ProductModalFormDisabled
              data={!countryValue.inherit ? countryValue : null}
              taxOptions={taxOptions()}
              currency={currency}
            />
          ) : (
            <ProductModalForm
              name={name}
              handleChangePrice={handleChangePrice}
              fieldValue={fieldValue}
              taxOptions={taxOptions()}
              currency={currency}
            />
          )}
          <div className="mt-3 text-right">
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              type="submit"
              onClick={handleCloseModal}
              className="btn btn-primary"
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  taxes: state.product.taxes
});

export default connect(
  mapStateToProps,
  null
)(ProductStateModal);
