import React, { useRef, useState } from 'react';
import { Overlay, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * ? Add className prop
 * so I can add className="dashed-underline" if I want an underline
 * I feel country and state names shouldn't be underlined
 */
export default function Tooltip({ position, message, children, href }) {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div>
      <Link
        to={href}
        className="dashed-underline"
        ref={target}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </Link>
      <Overlay target={target.current} show={show} placement={position}>
        {props => <BootstrapTooltip {...props}>{message}</BootstrapTooltip>}
      </Overlay>
    </div>
  );
}
