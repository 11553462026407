import React from 'react';
import moment from 'moment';

const phaseStyle = [
  {
    progress: 100,
    styles: {
      style: 'height',
      container: 'triangle-line1',
      el: 'progressbar1',
      info: 'tri-info1 font-we'
    }
  },
  {
    progress: 100,
    styles: {
      style: 'width',
      container: 'triangle-line2',
      el: 'progressbar2',
      info: 'tri-info2'
    }
  },
  {
    progress: 47,
    styles: {
      style: 'height',
      container: 'triangle-line3',
      el: 'progressbar3',
      info: 'tri-info3'
    }
  }
];

export default function FsbConfigTriangle({ data }) {
  return (
    <div className="triangle-cont">
      {data.phase &&
        data.phase.map((phase, index) => (
          <div className={`${phaseStyle[index].styles.info} text-uppercase`}>
            <span className="font-weight-bold">{phase.title}</span>
            <br />
            {/* <p className="text-muted font-11 d-none">01/06/2019 - 31/12/2019</p> */}
            <p className="badge badge-pill badge-dark">
              {`${phase.bp_target} BP`}
            </p>
          </div>
        ))}
      <div className="tri-out">
        <div className="tri-desc">
          <h5 className="mb-0">DAYS LEFT</h5>
          <h1 className="text-danger font-60 m-0">{data.total_duration}</h1>
          <small className="font-11 text-muted">
            {`CURRENT DATE: ${moment().format('DD/MM/YY')}`}
          </small>
        </div>
        {phaseStyle.map(phase => (
          <div className={phase.styles.container}>
            <div
              className={phase.styles.el}
              style={{ [phase.styles.style]: `${phase.progress}%` }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
