import React from 'react';
import { formValueSelector, change, submit } from 'redux-form';
import { debounce } from 'lodash';
import connect from 'react-redux/es/connect/connect';
import Button from 'react-bootstrap/Button';
import validator from 'card-validator';
import PaymentMode from './PaymentMode';
import btnLoader from '../../assets/images/btn-loader.gif';

class PaymentOption extends React.Component {
  constructor(props) {
    super(props);

    this.handleCheckoutDebounce = debounce(this.handleCheckout, 2000, {
      leading: true,
      trailing: false
    });
  }

  selectSavedCard = e => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', 'card_id', e.target.value));
    dispatch(change('checkoutForm', 'payment_type', 'saved_card'));
  };

  selectPaymentType = e => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', 'payment_type', e.target.value));
  };

  handleCardNumber = e => {
    const { dispatch } = this.props;
    const numberValidation = validator.number(e.target.value);
    if (
      numberValidation &&
      numberValidation.isPotentiallyValid &&
      numberValidation.card
    ) {
      dispatch(change('checkoutForm', 'card_type', numberValidation.card.type));
    }
    dispatch(change('checkoutForm', 'card_number', e.target.value));
  };

  handleCardInputs = e => {
    const { dispatch } = this.props;
    dispatch(change('checkoutForm', e.target.name, e.target.value));
  };

  handleCardExp = e => {
    const { dispatch } = this.props;
    const expDateValidation = validator.expirationDate(e.target.value);
    dispatch(change('checkoutForm', 'exp_date', e.target.value));
    if (expDateValidation.month) {
      dispatch(change('checkoutForm', 'exp_month', expDateValidation.month));
    }
    if (expDateValidation.year) {
      dispatch(change('checkoutForm', 'exp_year', expDateValidation.year));
    }
  };

  handleCheckout = () => {
    const { dispatch } = this.props;
    dispatch(submit('checkoutForm'));
  };

  render() {
    const {
      cardId,
      paymentType,
      cardNumber,
      cardName,
      expDate,
      cvv,
      processing,
      handleTabChange,
      appSetting,
      shopCountry
    } = this.props;
    return (
      <>
        <h4 className="mt-2 mb-3">Choose Payment Option</h4>
        <PaymentMode
          country={shopCountry}
          value={paymentType}
          onChange={this.selectPaymentType}
        />

        {/* <p className="text-muted mb-4">Fill the form below in order to send you the order&apos;s invoice.</p> */}
        {/* <div className="border p-3 mb-3 rounded">
            <div className="row">
              <div className="col-sm-8">
                <div className="custom-control">
                  <label
                    className="font-16 font-weight-bold"
                    htmlFor="BillingOptRadio2"
                  >
                    Saved Cards
                  </label>
                  <div className="saved-card-item custom-control custom-radio">
                    <input
                      type="radio"
                      id="1881"
                      value={1}
                      checked={paymentType === 'saved_card' && cardId === '1'}
                      name="saved_card"
                      onChange={this.selectSavedCard}
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="1881"
                    >
                    ********1881
                    </label>
                  </div>
                  <div className="saved-card-item custom-control custom-radio">
                    <input
                      type="radio"
                      id="1002"
                      value={2}
                      checked={paymentType === 'saved_card' && cardId === '2'}
                      name="saved_card"
                      onChange={this.selectSavedCard}
                      className="custom-control-input"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="1002"
                    >
                    ********1002
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 text-sm-right mt-3 mt-sm-0" />
            </div>
          </div> */}
        {/* <div className="border p-3 mb-3 rounded">
            <div className="row">
              <div className="col-sm-8">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="paypal"
                    name="payment_type"
                    value="paypal"
                    checked={paymentType === 'paypal'}
                    onChange={this.selectPaymentType}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label font-16 font-weight-bold"
                    htmlFor="paypal"
                  >
                  Pay with Paypal
                  </label>
                </div>
                <p className="mb-0 pl-3 pt-1">You will be redirected to PayPal website to complete your purchase securely.</p>
              </div>
              <div className="col-sm-4 text-sm-right mt-3 mt-sm-0">
                <img src="/assets/images/payments/paypal.png" height="25" alt="paypal-img" />
              </div>
            </div>
          </div> */}
        {/* <div className="border p-3 mb-3 rounded">
            <div className="row">
              <div className="col-sm-8">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="payoneer"
                    name="payment_type"
                    value="payoneer"
                    checked={paymentType === 'payoneer'}
                    onChange={this.selectPaymentType}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label font-16 font-weight-bold"
                    htmlFor="payoneer"
                  >
                  Pay with Payoneer
                  </label>
                </div>
                <p className="mb-0 pl-3 pt-1">You will be redirected to Payoneer website to complete your purchase securely.</p>
              </div>
              <div className="col-sm-4 text-sm-right mt-3 mt-sm-0">
                <img src="/assets/images/payments/payoneer.png" height="30" alt="paypal-img" />
              </div>
            </div>
          </div>
          <div className="border p-3 mb-3 rounded">
            <div className="row">
              <div className="col-sm-8">
                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="cod"
                    name="payment_type"
                    value="cod"
                    checked={paymentType === 'cod'}
                    onChange={this.selectPaymentType}
                    className="custom-control-input"
                  />
                  <label
                    className="custom-control-label font-16 font-weight-bold"
                    htmlFor="cod"
                  >
                  Cash on Delivery
                  </label>
                </div>
                <p className="mb-0 pl-3 pt-1">Pay with cash when your order is delivered.</p>
              </div>
              <div className="col-sm-4 text-sm-right mt-3 mt-sm-0">
                <img src="/assets/images/payments/cod.png" height="22" alt="paypal-img" />
              </div>
            </div>
          </div> */}
        {/* <div className="mt-3">
          <label htmlFor="example-textarea">Order Note:</label>
          <textarea
            className="form-control"
            id="example-textarea"
            rows="3"
            placeholder="Write some note.."
          />
        </div> */}
        <div className="row text-center text-xs-left text-sm-left">
          <div className="col-12">
            <hr />
          </div>
          <div className="col-xs-6 col-sm-6">
            <Button
              variant="light"
              className="d-sm-inline-block font-weight-semibold mb-3 mb-sm-0"
              onClick={() => handleTabChange('billing-information')}
            >
              <i className="mdi mdi-arrow-left mr-1" />
              <span>Back</span>
            </Button>
          </div>
          <div className="col-xs-6 col-sm-6">
            <div className="text-xs-right text-sm-right">
              <Button
                variant="danger"
                className="font-weight-semibold"
                disabled={!paymentType}
                onClick={this.handleCheckoutDebounce}
              >
                {processing ? (
                  <img src={btnLoader} alt="" />
                ) : (
                  <>
                    <i className="mdi mdi-check-decagram mr-1" />
                    <span>Place Order</span>
                  </>
                )}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  processing: state.checkout.progress,
  appSetting: state.app.settings,
  cardId: selector(state, 'card_id'),
  paymentType: selector(state, 'payment_type'),
  cardNumber: selector(state, 'card_number'),
  cardName: selector(state, 'card_name'),
  expDate: selector(state, 'exp_date'),
  cvv: selector(state, 'cvv'),
  shopCountry: state.app.shopCountry
});

// const mapDispatchToProps = dispatch => bindActionCreators(
//   {
//     dispatch: action => action
//   },
//   dispatch
// );

export default connect(
  mapStateToProps,
  null
)(PaymentOption);
