import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, find } from 'lodash';
import ShippingForm from './ShippingForm';
import { shipping as shippingActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class ShippingEdit extends React.Component {
  componentDidMount() {
    const { match, getShippingById } = this.props;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getShippingById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateShipping, match } = this.props;
    updateShipping({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  render() {
    const { updateProgress, shipping, match } = this.props;
    const shippingList = get(shipping, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      const dataObj = find(shippingList, { id: parseInt(match.params.id, 10) });
      if (dataObj && dataObj.shipping_modes) {
        data = {
          title: dataObj.title,
          country: dataObj.country,
          state: dataObj.state ? parseInt(dataObj.state.id, 10) : '*',
          postcode: dataObj.postcode,
          city: dataObj.city ? parseInt(dataObj.city.id, 10) : '*',
          rate_type: dataObj.rate_type,
          delivery: dataObj.shipping_modes,
          status: !!dataObj.status
        };
      }
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.shippingEdit.breadcrumb} />
              <h4 className="page-title">{routePath.shippingEdit.title}</h4>
            </div>
          </div>
        </div>
        {data ? (
          <ShippingForm
            submitForm={this.handleSubmit}
            inProgress={updateProgress}
            initialValues={data}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.shipping.updateProgress,
  shipping: state.shipping.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateShipping: shippingActions.updateShipping,
      getShippingById: shippingActions.getShippingById
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingEdit);
