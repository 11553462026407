import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import routePath from '../constants/routePath';
import {
  coupons as couponActionTypes,
  app as appActionTypes
} from '../actions';

function* getAllCoupons(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_ALL_LIST_ERROR, data: error });
  }
}

function* getCoupon(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_ERROR, data: error });
  }
}

function* createCoupon(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Coupon was added successfully'
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.coupons.path
      });
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_CREATE_ERROR, data: error });
  }
}

function* updateCoupon(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Coupon updated successfully'
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.coupons.path
        });
      }
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_UPDATE_ERROR, data: error });
  }
}

function* updateCouponStatus(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons/toggleStatus/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Coupon status updated successfully'
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_STATUS_UPDATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: couponActionTypes.COUPON_STATUS_UPDATE_ERROR,
      data: error
    });
  }
}

function* deleteCoupon(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons/${actions.id}`,
      method: 'DELETE',
      successMessage: 'Coupon deleted successfully'
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_DELETE_SUCCESS,
        id: actions.id
      });
      yield put({
        type: couponActionTypes.COUPON_HIDE_ALERT,
        id: actions.id
      });
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_DELETE_ERROR, data: error });
  }
}

function* getAllOrderCoupons(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/order-coupons`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_ALL_ORDER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_ALL_ORDER_ERROR, data: error });
  }
}

function* getOrderCoupon(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/order-coupons/details`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: couponActionTypes.COUPON_ORDER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: couponActionTypes.COUPON_ORDER_ERROR, data: error });
  }
}

function* watchCouponListAll() {
  yield takeLatest(couponActionTypes.COUPON_ALL_LIST_REQUEST, getAllCoupons);
}

function* watchGetCoupon() {
  yield takeLatest(couponActionTypes.COUPON_REQUEST, getCoupon);
}

function* watchCouponCreate() {
  yield takeLatest(couponActionTypes.COUPON_CREATE_REQUEST, createCoupon);
}

function* watchCouponUpdate() {
  yield takeLatest(couponActionTypes.COUPON_UPDATE_REQUEST, updateCoupon);
}

function* watchCouponStatusUpdate() {
  yield takeLatest(
    couponActionTypes.COUPON_STATUS_UPDATE_REQUEST,
    updateCouponStatus
  );
}

function* watchCouponDelete() {
  yield takeLatest(couponActionTypes.COUPON_DELETE_REQUEST, deleteCoupon);
}

function* watchCouponAllOrder() {
  yield takeLatest(
    couponActionTypes.COUPON_ALL_ORDER_REQUEST,
    getAllOrderCoupons
  );
}

function* watchCouponGetOrder() {
  yield takeLatest(couponActionTypes.COUPON_ORDER_REQUEST, getOrderCoupon);
}

export default [
  fork(watchCouponListAll),
  fork(watchCouponCreate),
  fork(watchGetCoupon),
  fork(watchCouponUpdate),
  fork(watchCouponStatusUpdate),
  fork(watchCouponDelete),
  fork(watchCouponAllOrder),
  fork(watchCouponGetOrder)
];
