const associate = {
  ASSOCIATE_LIST_REQUEST: 'ASSOCIATE_LIST_REQUEST',
  ASSOCIATE_LIST_SUCCESS: 'ASSOCIATE_LIST_SUCCESS',
  ASSOCIATE_CREATE_REQUEST: 'ASSOCIATE_CREATE_REQUEST',
  ASSOCIATE_CREATE_SUCCESS: 'ASSOCIATE_CREATE_SUCCESS',
  ASSOCIATE_CREATE_ERROR: 'ASSOCIATE_CREATE_ERROR',
  getAssociates: data => ({ type: associate.ASSOCIATE_LIST_REQUEST, data }),
  createAssociate: data => ({ type: associate.ASSOCIATE_CREATE_REQUEST, data })
};

export default associate;
