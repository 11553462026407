import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import routePath from '../../constants/routePath';
import { required, number } from '../../utills/validator';
import { inputField, imageField } from '../../components/Fields';
import { setting as settingActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class SettingForm extends React.PureComponent {
  render() {
    const { handleSubmit, submitForm, inProgress, reset } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <Field
              name="key"
              component={inputField}
              className="form-control"
              label="Key"
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <Field
              name="value"
              component={inputField}
              className="form-control"
              label="Value"
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <Field
              name="tag"
              component={inputField}
              className="form-control"
              label="Tag"
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-12 mt-2">
            <Link to={routePath.setting.path} className="btn btn-light mr-2">
              Cancel
            </Link>
            <button
              type="submit"
              disabled={inProgress}
              className="btn btn-primary"
            >
              {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const RankFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingForm);

export default reduxForm({
  form: 'settingForm' // a unique identifier for this form
})(RankFormWithRedux);
