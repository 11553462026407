import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  product as productActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/get`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAllProductByCategory(actions) {
  const page = actions.data && actions.data.page ? actions.data.page : false;
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/get/${actions.data.id}${
        page > 1 ? `?page=${page}` : ''
      }`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_ALL_LIST_CATEGORY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/${actions.id}/get`,
      method: 'GET'
    });
    if (responseJson) {
      if (responseJson.countries) {
        responseJson.countries = responseJson.countries.map(item => ({
          ...item,
          states: []
        }));
      }
      yield put({
        type: productActionTypes.PRODUCT_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProductPrice(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/calculate-price`,
      method: 'POST',
      data: actions.data,
      hideMessage: true
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_PRICE_SUCCESS,
        data: responseJson,
        isTaxList: actions.data.taxable && !actions.data.tax_rate
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getProductConvertedPrice(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/currency-converted-price`,
      method: 'POST',
      data: actions.data,
      hideMessage: true
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_CONVERT_PRICE_SUCCESS,
        data: { country: actions.data.country, ...responseJson }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/add`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Product added successfully'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.product.path
      });
    }
  } catch (error) {
    yield put({ type: productActionTypes.PRODUCT_CREATE_ERROR, data: error });
  }
}

function* cloneProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/clone`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Product(s) cloned successfully'
    });
    if (responseJson) {
      yield put({
        type: productActionTypes.PRODUCT_CLONE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: productActionTypes.PRODUCT_ALL_LIST_REQUEST,
        data: {}
      });
    }
  } catch (error) {
    yield put({ type: productActionTypes.PRODUCT_CLONE_ERROR, data: error });
  }
}

function* updateProduct(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/product/${actions.data.id}/edit`,
      method: 'POST',
      data: actions.data.data,
      successMessage: 'Product updated successfully'
    });
    if (responseJson) {
      if (actions.type === productActionTypes.PRODUCT_UPDATE_REQUEST) {
        yield put({
          type: productActionTypes.PRODUCT_UPDATE_SUCCESS,
          data: responseJson
        });
      } else {
        yield put({
          type: productActionTypes.PRODUCT_STATUS_UPDATE_SUCCESS,
          data: responseJson
        });
      }
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.product.path
        });
      }
    }
  } catch (error) {
    yield put({ type: productActionTypes.PRODUCT_UPDATE_ERROR, data: error });
  }
}

function* watchProductListAll() {
  yield takeLatest(productActionTypes.PRODUCT_ALL_LIST_REQUEST, getAllProduct);
}

function* watchProductListCategoryAll() {
  yield takeLatest(
    productActionTypes.PRODUCT_ALL_LIST_CATEGORY_REQUEST,
    getAllProductByCategory
  );
}

function* watchGetProduct() {
  yield takeLatest(productActionTypes.PRODUCT_REQUEST, getProduct);
}

function* watchGetProductPrice() {
  yield takeLatest(productActionTypes.PRODUCT_PRICE_REQUEST, getProductPrice);
}

function* watchProductConvertedPrice() {
  yield takeLatest(
    productActionTypes.PRODUCT_CONVERT_PRICE_REQUEST,
    getProductConvertedPrice
  );
}

function* watchProductCreate() {
  yield takeLatest(productActionTypes.PRODUCT_CREATE_REQUEST, createProduct);
}

function* watchProductClone() {
  yield takeLatest(productActionTypes.PRODUCT_CLONE_REQUEST, cloneProduct);
}

function* watchProductUpdate() {
  yield takeLatest(productActionTypes.PRODUCT_UPDATE_REQUEST, updateProduct);
}

function* watchProductStatusUpdate() {
  yield takeLatest(
    productActionTypes.PRODUCT_STATUS_UPDATE_REQUEST,
    updateProduct
  );
}

export default [
  fork(watchProductListAll),
  fork(watchProductListCategoryAll),
  fork(watchProductCreate),
  fork(watchProductUpdate),
  fork(watchProductStatusUpdate),
  fork(watchProductClone),
  fork(watchGetProduct),
  fork(watchGetProductPrice),
  fork(watchProductConvertedPrice)
];
