import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { find } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import BreadCrumb from '../components/BreadCrumb';
import {
  auth as authActions,
  dashboard as dashboardActionTypes,
  order as orderActions
} from '../actions';
import AssociateDash from './Dashboard/AssociateDash';
import { dateRanges, dateRangesValues } from '../utills/custom';
import StatBox from '../components/StatBox';
import GrowthBox from '../components/GrowthBox';
import TurnoverBox from '../components/TurnoverBox';

const data = [
  {
    id: 'japan',
    color: 'hsl(338, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 78
      },
      {
        x: 'helicopter',
        y: 164
      },
      {
        x: 'boat',
        y: 80
      },
      {
        x: 'train',
        y: 122
      },
      {
        x: 'subway',
        y: 150
      },
      {
        x: 'bus',
        y: 160
      },
      {
        x: 'car',
        y: 256
      },
      {
        x: 'moto',
        y: 251
      },
      {
        x: 'bicycle',
        y: 79
      },
      {
        x: 'horse',
        y: 77
      },
      {
        x: 'skateboard',
        y: 3
      },
      {
        x: 'others',
        y: 72
      }
    ]
  },
  {
    id: 'france',
    color: 'hsl(36, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 46
      },
      {
        x: 'helicopter',
        y: 184
      },
      {
        x: 'boat',
        y: 294
      },
      {
        x: 'train',
        y: 1
      },
      {
        x: 'subway',
        y: 236
      },
      {
        x: 'bus',
        y: 114
      },
      {
        x: 'car',
        y: 247
      },
      {
        x: 'moto',
        y: 9
      },
      {
        x: 'bicycle',
        y: 192
      },
      {
        x: 'horse',
        y: 272
      },
      {
        x: 'skateboard',
        y: 234
      },
      {
        x: 'others',
        y: 204
      }
    ]
  },
  {
    id: 'us',
    color: 'hsl(3, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 189
      },
      {
        x: 'helicopter',
        y: 272
      },
      {
        x: 'boat',
        y: 163
      },
      {
        x: 'train',
        y: 151
      },
      {
        x: 'subway',
        y: 118
      },
      {
        x: 'bus',
        y: 59
      },
      {
        x: 'car',
        y: 150
      },
      {
        x: 'moto',
        y: 297
      },
      {
        x: 'bicycle',
        y: 243
      },
      {
        x: 'horse',
        y: 180
      },
      {
        x: 'skateboard',
        y: 96
      },
      {
        x: 'others',
        y: 37
      }
    ]
  },
  {
    id: 'germany',
    color: 'hsl(167, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 289
      },
      {
        x: 'helicopter',
        y: 197
      },
      {
        x: 'boat',
        y: 217
      },
      {
        x: 'train',
        y: 5
      },
      {
        x: 'subway',
        y: 147
      },
      {
        x: 'bus',
        y: 216
      },
      {
        x: 'car',
        y: 5
      },
      {
        x: 'moto',
        y: 83
      },
      {
        x: 'bicycle',
        y: 252
      },
      {
        x: 'horse',
        y: 268
      },
      {
        x: 'skateboard',
        y: 215
      },
      {
        x: 'others',
        y: 235
      }
    ]
  },
  {
    id: 'norway',
    color: 'hsl(295, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 215
      },
      {
        x: 'helicopter',
        y: 129
      },
      {
        x: 'boat',
        y: 57
      },
      {
        x: 'train',
        y: 136
      },
      {
        x: 'subway',
        y: 246
      },
      {
        x: 'bus',
        y: 197
      },
      {
        x: 'car',
        y: 11
      },
      {
        x: 'moto',
        y: 119
      },
      {
        x: 'bicycle',
        y: 118
      },
      {
        x: 'horse',
        y: 155
      },
      {
        x: 'skateboard',
        y: 209
      },
      {
        x: 'others',
        y: 174
      }
    ]
  }
];

const dataPie = [
  {
    id: 'sponsored',
    label: 'Sponsored',
    value: 387,
    color: 'hsl(2, 70%, 50%)'
  },
  {
    id: 'email',
    label: 'E-mail',
    value: 492,
    color: 'hsl(47, 70%, 50%)'
  },
  {
    id: 'affilliate',
    label: 'Affilliate',
    value: 521,
    color: 'hsl(164, 70%, 50%)'
  },
  {
    id: 'make',
    label: 'make',
    value: 370,
    color: 'hsl(349, 70%, 50%)'
  },
  {
    id: 'direct',
    label: 'Direct',
    value: 136,
    color: 'hsl(200, 70%, 50%)'
  }
];

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    const start = moment().startOf('year');
    const end = moment().endOf('year');
    const startDate = start.format('DD/MM/YYYY');
    const endDate = end.format('DD/MM/YYYY');
    this.state = {
      orderDateRangeValue: `${startDate} to ${endDate}`,
      orderDateRangeFromValue: startDate,
      orderDateRangeToValue: endDate,
      userDateRangeFromValue: startDate,
      userDateRangeToValue: endDate,
      userDateRangeValue: `${startDate} to ${endDate}`,
      ranges: dateRanges
    };

    const { getUserSummary, getOrderSummary, isAdmin } = this.props;
    if (isAdmin) {
      getOrderSummary({ filter: 'yearly' });
      getUserSummary({ filter: 'yearly' });
    }
  }

  formatDateRange = (start, end) => ({
    startDate: moment(start).format('DD/MM/YYYY'),
    endDate: moment(end).format('DD/MM/YYYY'),
    start: moment(start).format('DDMMYYYY'),
    end: moment(end).format('DDMMYYYY')
  });

  handleOrderDateRangeChange = (event, picker) => {
    const { getOrderSummary } = this.props;
    const { startDate, endDate, start, end } = this.formatDateRange(
      picker.startDate,
      picker.endDate
    );

    const filter = dateRangesValues[picker.chosenLabel] || 'custom';
    this.setState({
      orderDateRangeValue: `${startDate} to ${endDate}`,
      // orderDateRangeFromValue: startDate,
      // orderDateRangeToValue: endDate,
      orderFilter: filter
    });
    if (filter !== 'custom') {
      getOrderSummary({ filter });
    } else {
      getOrderSummary({ filter, start, end });
    }
  };

  handleUserDateRangeChange = (event, picker) => {
    const { getUserSummary } = this.props;
    const { startDate, endDate, start, end } = this.formatDateRange(
      picker.startDate,
      picker.endDate
    );

    const filter = dateRangesValues[picker.chosenLabel] || 'custom';
    this.setState({
      userDateRangeValue: `${startDate} to ${endDate}`,
      // userDateRangeFromValue: startDate,
      // userDateRangeToValue: endDate,
      userFilter: filter
    });
    if (filter !== 'custom') {
      getUserSummary({ filter });
    } else {
      getUserSummary({ filter, start, end });
    }
  };

  render() {
    const {
      isAdmin,
      orderSummaryData,
      isOrderSummaryLoading,
      userSummaryData,
      isUserSummaryLoading
    } = this.props;
    const {
      ranges,
      orderDateRangeValue,
      orderDateRangeFromValue,
      orderDateRangeToValue,
      userDateRangeFromValue,
      userDateRangeToValue,
      userDateRangeValue,
      orderFilter,
      userFilter
    } = this.state;

    // Get Order Summary
    const orderSummary = find(orderSummaryData, { name: 'Orders' });
    const saleSummary = find(orderSummaryData, { name: 'Sales' });
    const turnoverSummary = find(orderSummaryData, { name: 'Turnover' });
    const growthOrderSummary = find(orderSummaryData, { name: 'Growth' });
    const [orderFrom, orderTo] = orderDateRangeValue
      .split('to')
      .map(item => item.trim());
    // Get User Summary
    const associateSummary = find(userSummaryData, {
      name: 'Total Associates'
    });
    const customerSummary = find(userSummaryData, { name: 'Total Customer' });
    const activeUserSummary = find(userSummaryData, { name: 'Active User' });
    const growthSummary = find(userSummaryData, { name: 'Growth' });
    const [userFrom, userTo] = userDateRangeValue
      .split('to')
      .map(item => item.trim());

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={['dashboard']} />
              <h4 className="page-title">Dashboard</h4>
            </div>
          </div>
        </div>
        {isAdmin ? (
          <>
            <div className="row mb-2">
              <div className="col">
                <h5>ORDER</h5>
              </div>
              <div className="col align-content-end">
                <div className="float-right">
                  <DateRangePicker
                    startDate={orderDateRangeFromValue}
                    endDate={orderDateRangeToValue}
                    ranges={ranges}
                    opens="left"
                    onApply={this.handleOrderDateRangeChange}
                  >
                    <div className="form-group mb-0 has-icon">
                      <i className="mdi mdi-calendar" />
                      <input
                        type="text"
                        value={orderDateRangeValue}
                        className="form-control input-medium cursor-pointer bg-white"
                        placeholder="Select date range"
                        readOnly
                      />
                    </div>
                  </DateRangePicker>
                </div>
              </div>
            </div>
            <div className="row row-eq-height">
              <div className="col-md-3 col-sm-6 mb-3">
                <StatBox
                  icon="mdi-cart"
                  title={(orderSummary && orderSummary.name) || 'Orders'}
                  isLoading={isOrderSummaryLoading}
                  growth={
                    (orderSummary && orderSummary.growth_percentage) || '0.00%'
                  }
                  previousValue={
                    (orderSummary && orderSummary.previous_total) || '0'
                  }
                  currentValue={(orderSummary && orderSummary.total) || '0'}
                  filter={orderFilter}
                  start={orderFrom}
                  end={orderTo}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-3">
                <StatBox
                  icon="mdi-cash-multiple"
                  title={(saleSummary && saleSummary.name) || 'Sales'}
                  isLoading={isOrderSummaryLoading}
                  growth={
                    (saleSummary && saleSummary.growth_percentage) || '0.00%'
                  }
                  previousValue={
                    (saleSummary && saleSummary.previous_total) || '0'
                  }
                  currentValue={(saleSummary && saleSummary.total) || '0'}
                  filter={orderFilter}
                  start={orderFrom}
                  end={orderTo}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-3">
                <TurnoverBox
                  title={
                    (turnoverSummary && turnoverSummary.name) || 'Turnover'
                  }
                  isLoading={isOrderSummaryLoading}
                  prevVat={
                    (turnoverSummary &&
                      turnoverSummary.previous_period
                        .previous_total_items_quantities) ||
                    '0.00'
                  }
                  prevRevenue={
                    (turnoverSummary &&
                      turnoverSummary.previous_period.previous_total_revenue) ||
                    '0.00'
                  }
                  vat={
                    (turnoverSummary &&
                      turnoverSummary.current_period.total_items_quantities) ||
                    '0.00'
                  }
                  revenue={
                    (turnoverSummary &&
                      turnoverSummary.current_period.total_revenue) ||
                    '0.00'
                  }
                  filter={orderFilter}
                  start={orderFrom}
                  end={orderTo}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-3">
                <GrowthBox
                  title={
                    (growthOrderSummary && growthOrderSummary.name) || 'Growth'
                  }
                  isLoading={isOrderSummaryLoading}
                  quantity={
                    (growthOrderSummary &&
                      growthOrderSummary.items_quantities_sold_growth_percentage) ||
                    '0.00%'
                  }
                  revenue={
                    (growthOrderSummary &&
                      growthOrderSummary.total_revenue_growth_percentage) ||
                    '0.00%'
                  }
                  filter={orderFilter}
                  start={orderFrom}
                  end={orderTo}
                />
              </div>
            </div>

            <div className="row mb-2">
              <div className="col">
                <h5>USER</h5>
              </div>
              <div className="col align-content-end">
                <div className="float-right">
                  <DateRangePicker
                    startDate={userDateRangeFromValue}
                    endDate={userDateRangeToValue}
                    ranges={ranges}
                    opens="left"
                    onApply={this.handleUserDateRangeChange}
                  >
                    <div className="form-group mb-0 has-icon">
                      <i className="mdi mdi-calendar" />
                      <input
                        type="text"
                        value={userDateRangeValue}
                        className="form-control input-medium cursor-pointer bg-white"
                        placeholder="Select date range"
                        readOnly
                      />
                    </div>
                  </DateRangePicker>
                </div>
              </div>
            </div>
            <div className="row row-eq-height">
              <div className="col-md-3 col-sm-6 mb-3">
                <StatBox
                  icon="mdi-account-star"
                  backgroundColor="bg-primary"
                  textColor="text-white"
                  title={(associateSummary && associateSummary.name) || 'Sales'}
                  isLoading={isUserSummaryLoading}
                  growth={
                    (associateSummary && associateSummary.percentage_total) ||
                    '0%'
                  }
                  previousValue={
                    (associateSummary && associateSummary.previous_total) || '0'
                  }
                  currentValue={
                    (associateSummary && associateSummary.total) || '0'
                  }
                  filter={userFilter}
                  start={userFrom}
                  end={userTo}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-3">
                <StatBox
                  icon="mdi-account-group"
                  backgroundColor="bg-success"
                  textColor="text-white"
                  title={(customerSummary && customerSummary.name) || 'Sales'}
                  isLoading={isUserSummaryLoading}
                  growth={
                    (customerSummary && customerSummary.percentage_total) ||
                    '0%'
                  }
                  previousValue={
                    (customerSummary && customerSummary.previous_total) || '0'
                  }
                  currentValue={
                    (customerSummary && customerSummary.total) || '0'
                  }
                  filter={userFilter}
                  start={userFrom}
                  end={userTo}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-3">
                <StatBox
                  icon="mdi-account-multiple-check"
                  title={
                    (activeUserSummary && activeUserSummary.name) || 'Sales'
                  }
                  isLoading={isUserSummaryLoading}
                  growth={
                    (activeUserSummary && activeUserSummary.percentage_total) ||
                    '0%'
                  }
                  previousValue={
                    (activeUserSummary && activeUserSummary.previous_total) ||
                    '0'
                  }
                  currentValue={
                    (activeUserSummary && activeUserSummary.total) || '0'
                  }
                  filter={userFilter}
                  start={userFrom}
                  end={userTo}
                />
              </div>
              <div className="col-md-3 col-sm-6 mb-3">
                <StatBox
                  icon="mdi-pulse"
                  title={(growthSummary && growthSummary.name) || 'Sales'}
                  isLoading={isUserSummaryLoading}
                  growth={
                    (growthSummary && growthSummary.percentage_total) || '0%'
                  }
                  previousValue={
                    (growthSummary && growthSummary.previous_total) || '0'
                  }
                  currentValue={(growthSummary && growthSummary.total) || '0'}
                  filter={userFilter}
                  start={userFrom}
                  end={userTo}
                />
              </div>
            </div>

            <div className="row d-none">
              <div className="col-xl-8 col-lg-12 col-md-8 col-sm-12 col-12">
                <div className="card">
                  <h5 className="card-header">Revenue</h5>
                  <div className="card-body">
                    <div style={{ height: 322 }}>
                      <ResponsiveLine
                        data={data}
                        margin={{
                          top: 50,
                          right: 110,
                          bottom: 50,
                          left: 60
                        }}
                        xScale={{ type: 'point' }}
                        yScale={{
                          type: 'linear',
                          stacked: true,
                          min: 'auto',
                          max: 'auto'
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                          orient: 'bottom',
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'transportation',
                          legendOffset: 36,
                          legendPosition: 'middle'
                        }}
                        axisLeft={{
                          orient: 'left',
                          tickSize: 5,
                          tickPadding: 5,
                          tickRotation: 0,
                          legend: 'count',
                          legendOffset: -40,
                          legendPosition: 'middle'
                        }}
                        colors={{ scheme: 'nivo' }}
                        pointSize={10}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={2}
                        pointBorderColor={{ from: 'serieColor' }}
                        pointLabel="y"
                        pointLabelYOffset={-12}
                        useMesh
                        legends={[
                          {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemBackground: 'rgba(0, 0, 0, .03)',
                                  itemOpacity: 1
                                }
                              }
                            ]
                          }
                        ]}
                      />
                    </div>
                  </div>
                  <div className="card-body border-top">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 p-3">
                        <h4> Today&apos;s Earning: $2,800.30</h4>
                        <p>Suspendisse potenti. Done csit amet rutrum.</p>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 p-3">
                        <h2 className="font-weight-normal mb-3">
                          <span>$48,325</span>{' '}
                        </h2>
                        <div className="mb-0 mt-3 legend-item">
                          <span className="fa-xs text-primary mr-1 legend-title ">
                            <i className="fa fa-fw fa-square-full" />
                          </span>
                          <span className="legend-text">Current Week</span>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 p-3">
                        <h2 className="font-weight-normal mb-3">
                          <span>$59,567</span>
                        </h2>
                        <div className="text-muted mb-0 mt-3 legend-item">
                          {' '}
                          <span className="fa-xs text-secondary mr-1 legend-title">
                            <i className="fa fa-fw fa-square-full" />
                          </span>
                          <span className="legend-text">Previous Week</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================================== */}
              {/* end reveune  */}
              {/* ============================================================== */}
              {/* ============================================================== */}
              {/* total sale  */}
              {/* ============================================================== */}
              <div className="col-xl-4 col-lg-12 col-md-4 col-sm-12 col-12">
                <div className="card">
                  <h5 className="card-header">Total Sale</h5>
                  <div className="card-body">
                    <div style={{ height: 300 }}>
                      <ResponsivePie
                        data={dataPie}
                        margin={{
                          top: 40,
                          right: 80,
                          bottom: 80,
                          left: 80
                        }}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ scheme: 'nivo' }}
                        borderWidth={1}
                        borderColor={{
                          from: 'color',
                          modifiers: [['darker', 0.2]]
                        }}
                        radialLabelsSkipAngle={10}
                        radialLabelsTextXOffset={6}
                        radialLabelsTextColor="#333333"
                        radialLabelsLinkOffset={0}
                        radialLabelsLinkDiagonalLength={16}
                        radialLabelsLinkHorizontalLength={24}
                        radialLabelsLinkStrokeWidth={1}
                        radialLabelsLinkColor={{ from: 'color' }}
                        slicesLabelsSkipAngle={10}
                        slicesLabelsTextColor="#333333"
                        animate
                        motionStiffness={90}
                        motionDamping={15}
                        defs={[
                          {
                            id: 'dots',
                            type: 'patternDots',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            size: 4,
                            padding: 1,
                            stagger: true
                          },
                          {
                            id: 'lines',
                            type: 'patternLines',
                            background: 'inherit',
                            color: 'rgba(255, 255, 255, 0.3)',
                            rotation: -45,
                            lineWidth: 6,
                            spacing: 10
                          }
                        ]}
                        fill={[
                          {
                            match: {
                              id: 'ruby'
                            },
                            id: 'dots'
                          },
                          {
                            match: {
                              id: 'c'
                            },
                            id: 'dots'
                          },
                          {
                            match: {
                              id: 'go'
                            },
                            id: 'dots'
                          },
                          {
                            match: {
                              id: 'python'
                            },
                            id: 'dots'
                          },
                          {
                            match: {
                              id: 'scala'
                            },
                            id: 'lines'
                          },
                          {
                            match: {
                              id: 'lisp'
                            },
                            id: 'lines'
                          },
                          {
                            match: {
                              id: 'elixir'
                            },
                            id: 'lines'
                          },
                          {
                            match: {
                              id: 'javascript'
                            },
                            id: 'lines'
                          }
                        ]}
                        legends={[
                          {
                            anchor: 'bottom',
                            direction: 'row',
                            translateY: 56,
                            itemWidth: 100,
                            itemHeight: 18,
                            itemTextColor: '#999',
                            symbolSize: 18,
                            symbolShape: 'circle',
                            effects: [
                              {
                                on: 'hover',
                                style: {
                                  itemTextColor: '#000'
                                }
                              }
                            ]
                          }
                        ]}
                      />
                    </div>
                    <div className="chart-widget-list">
                      <p>
                        <span className="fa-xs text-primary mr-1 legend-title">
                          <i className="fa fa-fw fa-square-full" />
                        </span>
                        <span className="legend-text"> Direct</span>
                        <span className="float-right">$300.56</span>
                      </p>
                      <p>
                        <span className="fa-xs text-secondary mr-1 legend-title">
                          <i className="fa fa-fw fa-square-full" />
                        </span>
                        <span className="legend-text">Affilliate</span>
                        <span className="float-right">$135.18</span>
                      </p>
                      <p>
                        <span className="fa-xs text-brand mr-1 legend-title">
                          <i className="fa fa-fw fa-square-full" />
                        </span>{' '}
                        <span className="legend-text">Sponsored</span>
                        <span className="float-right">$48.96</span>
                      </p>
                      <p className="mb-0">
                        <span className="fa-xs text-info mr-1 legend-title">
                          <i className="fa fa-fw fa-square-full" />
                        </span>{' '}
                        <span className="legend-text"> E-mail</span>
                        <span className="float-right">$154.02</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* ============================================================== */}
              {/* end total sale  */}
              {/* ============================================================== */}
            </div>
          </>
        ) : (
          <AssociateDash />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  orderSummaryData: state.order.summary,
  isOrderSummaryLoading: state.order.isSummaryLoading,
  userSummaryData: state.dashboard.userSummary,
  isUserSummaryLoading: state.dashboard.isUserSummaryLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogout: authActions.doLogout,
      getOrderSummary: orderActions.getSummary,
      getUserSummary: dashboardActionTypes.getUserSummary
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
