import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import AdvanceTable from '../../components/AdvanceTable';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import SingleDownloadButton from './SingleDownloadButton';
import { order as orderActions } from '../../actions';
import { getCurrencySymbol, getOrderStatusClass } from '../../utills/custom';

class OwnCustomerOrder extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getAllOrders } = this.props;
    getAllOrders({ orderType: 'customer' });
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAllOrders } = this.props;
    if (type === 'pagination')
      getAllOrders({ orderType: 'customer', page, per_page: perPage });
    this.setState(prevState => ({ page }));
  };

  render() {
    const {
      orders,
      isLoading,
      appSetting,
      userInfo,
      filterValues
    } = this.props;
    const { page, selected } = this.state;
    const userRoles = get(userInfo, 'permission.role', []);
    const columns = [
      {
        dataField: 'invoice_number',
        text: 'Inv. no.',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link
                to={`/order/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            ) : (
              <Link
                to={`/subscription/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            )}
          </>
        )
      },
      {
        dataField: 'order_local_date_tz',
        text: 'Date',
        // headerClasses: 'text-center',
        // classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: (col, row) => {
          let dateObj = [];
          if (userRoles.includes('associate')) {
            dateObj = row.order_local_date_tz.split(' ');
          } else {
            dateObj = col.split(' ');
          }
          return (
            <span className="date">
              {dateObj[0]}
              <br />
              <small>{dateObj[1]}</small>
            </span>
          );
        }
      },
      {
        dataField: 'original_total',
        text: 'Total Price',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: col => (
          <span className="font-weight-semibold text-primary">
            {`${getCurrencySymbol(appSetting.def_currency)}${col}`}
          </span>
        )
      },
      {
        dataField: 'id',
        text: 'Order ID'
      },
      {
        dataField: 'uuid',
        text: 'Cust ID',
        formatter: (col, row) => row.user_info && row.user_info.uuid
      },
      {
        dataField: 'user_info',
        text: 'Full Name',
        formatter: col =>
          col && col.first_name && `${col.first_name} ${col.last_name}`
      },
      {
        dataField: 'method',
        // headerAttrs: { width: '15%' },
        text: 'Transaction ID',
        formatter: (col, row) => (
          <span>
            {row.order_transaction && row.order_transaction[0]
              ? row.order_transaction[0].transaction_id
              : '---'}
          </span>
        )
      },
      {
        dataField: 'payment_gateway_status',
        text: 'Payment Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '15%' },
        formatter: col => (
          <span className={getOrderStatusClass(col)}>{col}</span>
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '8%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link to={`/order/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" />
              </Link>
            ) : (
              <Link to={`/subscription/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" />
              </Link>
            )}
            <SingleDownloadButton
              id={row.id}
              invoice={row.invoice_number}
              title="Download PDF"
              type={row.cart_id ? 'order-invoice' : 'subscription-invoice'}
            />
            {/* <Link to={`/order/${row.order_number}/delete`}><i className="action-icon mdi mdi-trash-can" /></Link> */}
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.ownCustomerOrder.breadcrumb} />
              <h4 className="page-title">{routePath.ownCustomerOrder.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2 d-none">
                  <div className="col-sm-12 col-lg-7">
                    <div className="form-row">
                      <div className="form-group col-sm-8">
                        <div
                          className="app-search input-group input-group-sm search-bar"
                          id="custom-search"
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search By SKU/ITEM Name"
                          />
                          <span className="mdi mdi-magnify" />
                          <div className="input-group-append d-none d-lg-block">
                            <button className="btn btn-light" type="submit">
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-group form-group-sm col-sm-4">
                        <select
                          className="form-control form-control-sm"
                          name="form-field-name"
                        >
                          {statusOptions.map(status => (
                            <option value={status.value} key={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-5 text-lg-right">
                    <div className="img_checkbox">
                      <label className="custom-control custom-checkbox">
                        <input
                          className="custom-control-input"
                          type="checkbox"
                        />
                        <span className="custom-control-label">
                          Show Images
                        </span>
                      </label>
                    </div>
                    <button
                      className="btn btn-sm btn-success btn-refresh ml-2"
                      type="submit"
                    >
                      <i className="fas fa-sync" />
                    </button>
                    <div className="btn-group btn-group-sm ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          <Dropdown.Item href={routePath.productAdd.path}>
                            <i className="mdi mdi-plus-circle-outline" />
                            <span>Add new</span>
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            <i className="mdi mdi-trash-can" />
                            <span>Delete</span>
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-export" />
                            <span>Export</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <AdvanceTable
                  keyField="id"
                  data={orders.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={orders.total}
                  sizePerPageList={filterValues}
                  sizePerPage={orders.per_page}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.order.allList,
  appSetting: state.app.settings,
  isLoading: state.order.isLoading,
  userInfo: state.auth.user,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllOrders: orderActions.getAllOrders
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnCustomerOrder);
