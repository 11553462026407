import React from 'react';
import PropTypes from 'prop-types';

class Tab extends React.Component {
  constructor(props) {
    super(props);
    const { defaultSelected } = props;
    this.state = {
      selectedtab: defaultSelected
    };
  }

  handleChangeTab = index => e => {
    e.preventDefault();
    this.setState({ selectedtab: index });
  };

  render() {
    const { tabs, children } = this.props;
    const { selectedtab } = this.state;
    return (
      <div className="simple-card">
        <ul className="nav nav-tabs" role="tablist">
          {tabs.map((item, index) => (
            <li key={`tab_${item}`} className="nav-item">
              <a
                className={
                  index === selectedtab
                    ? 'nav-link border-left-0 active show'
                    : 'nav-link border-left-0'
                }
                href="#tab"
                role="tab"
                aria-selected={index === selectedtab}
                onClick={this.handleChangeTab(index)}
              >
                {item}
              </a>
            </li>
          ))}
        </ul>
        <div className="tab-content">
          {children.map((item, index) => (
            <div
              key={`tabContent_${index}`}
              className={
                index === selectedtab
                  ? 'tab-pane fade show active'
                  : 'tab-pane fade'
              }
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

Tab.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string),
  defaultSelected: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
};

Tab.defaultProps = {
  tabs: [],
  defaultSelected: 0
};

export default Tab;
