import axios from 'axios';
import { downloadBlob } from '../utills/ajax';

export async function stateListAPI(countryId) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/states/${countryId}`,
    method: 'GET'
  });

  return res;
}

export async function fetchStatesByCountryId(countryId) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/country/states/${countryId}`,
    method: 'GET'
  });

  return res;
}

export async function productCountry(categoryIds) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/prefetch/categoryCountryState`,
    params: {
      category_ids: categoryIds
    },
    method: 'GET'
  });

  return res;
}

export async function calculateProductConvertPrice(data) {
  try {
    const responseJson = await axios({
      url: `${process.env.REACT_APP_API_URL}/product/currency-converted-price`,
      method: 'POST',
      data,
      hideMessage: true
    });
    return { country: data.country, ...responseJson };
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function calculateProductPrice(data) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/product/calculate-price`,
    method: 'POST',
    data,
    hideMessage: true
  });

  return res;
}

export async function exportPayoutInvite() {
  const response = await axios({
    url: `${process.env.REACT_APP_API_URL}/payout/invite`,
    method: 'GET',
    headers: { Accept: 'text/plain' },
    responseType: 'blob'
  });
  downloadBlob(response, 'INV-Maxx4-Payout.pdf');
}

export async function orderStatusChange(data) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/orders/manageOrderStatus`,
    method: 'POST',
    data
  });

  return res;
}

export async function allCurrency() {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/prefetch/currencies`,
    method: 'GET'
  });

  return res;
}

export async function orderFulfillmentChange(data) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/orders/manageOrderFulfillmentStatus`,
    method: 'POST',
    data
  });

  return res;
}

export async function paymentGatewayList() {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/country/getPaymentGatewayList`,
    method: 'GET'
  });

  return res;
}

export async function getAllCountryCurrencies() {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/prefetch/all-country-currencies`,
    method: 'GET'
  });

  return res;
}

export async function setDefaultAddress(id) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/address/toggleStatus/${id}`,
    method: 'PUT'
  });

  return res;
}

export async function activePaymentModeByCountry(country) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/cart/active-payment-mode/${country}`,
    method: 'GET'
  });

  return res;
}

export async function validateCoupon(data) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/cartitem/calculation`,
    method: 'POST',
    data,
    hideMessage: true
  });

  return res;
}

export async function getCartShippingOption(params) {
  const res = await axios({
    url: `${process.env.REACT_APP_API_URL}/cart/get-shipping`,
    method: 'GET',
    params
  });

  return res;
}
