import React, { useEffect, useState } from 'react';
import { getAllCountryCurrencies } from '../../services/apiService';

const CountryContext = React.createContext({});

function CountryContextProvider({ children }) {
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [countryCurrencies, setCountryCurrencies] = useState({});

  useEffect(() => {
    const fetcCurrencies = async () => {
      try {
        const res = await getAllCountryCurrencies();
        if (res) {
          setCountryCurrencies(res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetcCurrencies();
  }, []);

  return (
    <CountryContext.Provider
      value={{
        countryList,
        stateList,
        setCountryList,
        setStateList,
        countryCurrencies
      }}
    >
      {children}
    </CountryContext.Provider>
  );
}

export { CountryContext as default, CountryContextProvider };
