import validator from 'card-validator';

export const required = value =>
  value || typeof value === 'number' ? undefined : 'Required';
export const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;
export const maxLength15 = maxLength(15);
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;
export const minLength2 = minLength(2);
export const number = value =>
  value && Number.isNaN(Number(value)) ? 'Must be a number' : undefined;
export const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined;
export const minValue13 = minValue(13);
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;
export const tooYoung = value =>
  value && value < 13
    ? 'You do not meet the minimum age requirement!'
    : undefined;
export const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined;
export const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined;
export const alpha = value =>
  value && /[^a-zA-Z ]/i.test(value) ? 'Only alphabet characters' : undefined;
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? 'Invalid phone number, must be 10 digits'
    : undefined;
export const date = value =>
  value && !/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/i.test(value)
    ? `${value}Date in YYYY-MM-DD format`
    : undefined;
export const equal = otherField => (value, allValues) =>
  allValues[otherField] === value ? "Passwords don't match" : undefined;
export const passwordsMatch = (value, allValues) =>
  value !== allValues.newPassword ? "Passwords don't match" : undefined;
export const requiredIf = (field, fieldValue) => (value, allValues) =>
  allValues[field] === fieldValue && (!value && typeof value !== 'number')
    ? 'Required'
    : undefined;
export const requiredIfSavedCard = requiredIf('payment_type', 'saved_card');
export const requiredIfCard = requiredIf('payment_type', 'quickpay');
export const requiredIfIndividual = requiredIf('user_type', 'company');
export const requiredIfShipping = requiredIf('same_as_billing', false);
export const requiredIfOtherCity = requiredIf('city', 'others');
export const requiredIfShippingOtherCity = requiredIf(
  'shipping_city',
  'others'
);
export const requiredIfNotPickupFromStore = requiredIf(
  'pickup_from_store',
  '1'
);
export const ccNumber = value => {
  const numberValidation = validator.number(value);
  if (numberValidation.isValid) {
    return undefined;
  }
  return 'Invalid Card Number';
};
export const ccExpMonth = value => {
  const expMonthValidation = validator.expirationMonth(value);
  if (expMonthValidation.isValid) {
    return undefined;
  }
  return 'Invalid Exp Month';
};
export const ccExpYear = value => {
  const expYearValidation = validator.expirationYear(value);
  if (expYearValidation.isValid) {
    return undefined;
  }
  return 'Invalid Exp Year';
};
export const ccv = value => {
  const cvvValidation = validator.cvv(value);
  if (cvvValidation.isValid) {
    return undefined;
  }
  return 'Invalid CVV';
};

export const passwordRules = [
  {
    pattern: '[A-Z]', // Uppercase Alpabates
    text: 'Use at least one uppercase character'
  },
  {
    pattern: '[a-z]', // Lowercase Alphabates
    text: 'Use at least one lowercase character'
  },
  {
    pattern: '[0-9]', // Numbers
    text: 'Use at least one digit'
  },
  {
    pattern: '[$@$!%*#?&]', // Special Charector
    text: 'Use at least one special character ($@$!%*#?&)'
  },
  {
    pattern: '^(?=.{8,})', // Length check
    text: 'Must be at least 8 character long'
  }
];
