import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { required } from '../../utills/validator';
import { inputGroupField } from '../../components/Fields';

const CouponCode = ({ fields, prefix }) => {
  return (
    <>
      {fields &&
        fields.map((item, index) => (
          <Fragment key={index}>
            <div className="d-flex coupon-code">
              <Field
                name={`${item}.code`}
                component={inputGroupField}
                type="text"
                className="form-control"
                placeholder={`Code ${index + 1}`}
              >
                <div className="input-group-prepend">
                  <span className="input-group-text">{prefix}</span>
                </div>
              </Field>
              <div>
                {index === fields.length - 1 && (
                  <button
                    type="button"
                    onClick={() => fields.push({})}
                    className="btn btn-md btn-success ml-1"
                  >
                    <i className="mdi mdi-plus" />
                  </button>
                )}

                {index !== 0 && (
                  <button
                    type="button"
                    onClick={() => fields.remove(index)}
                    className="btn btn-md btn-danger ml-1 mr-1"
                  >
                    <i className="mdi mdi-trash-can-outline" />
                  </button>
                )}
              </div>
            </div>
          </Fragment>
        ))}
    </>
  );
};

export default CouponCode;
