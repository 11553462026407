import React, { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import { required } from '../../utills/validator';
import { inputField, selectField } from '../../components/Fields';
import { orderStatusChange } from '../../services/apiService';
import { getOrderStatusClass } from '../../utills/custom';
import btnLoader from '../../assets/images/btn-loader.gif';

const options = [
  { label: 'SUCCESS', value: 'SUCCESS' },
  { label: 'FAILED', value: 'FAILED' },
  { label: 'PROCESSING', value: 'PROCESSING' },
  { label: 'CANCELLED', value: 'CANCELLED' },
  { label: 'PENDING', value: 'PENDING' },
  { label: 'DECLINED', value: 'DECLINED' },
  { label: 'REFUND', value: 'REFUND' }
];

function orderStatusFormComp({
  hideModal,
  handleSubmit,
  pristine,
  submitting,
  onSubmit,
  inProgress
}) {
  const [displayTxnFields, setDisplayTxnFields] = useState(false);

  const afterStausChange = value => {
    if (value === 'SUCCESS') {
      setDisplayTxnFields(true);
    } else {
      setDisplayTxnFields(false);
    }
  };

  const handleClose = () => {
    hideModal();
  };

  const submitForm = values => {
    onSubmit(values);
  };

  return (
    <Form
      className="needs-validation"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <Modal.Header>
        <Modal.Title>Order Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="new_status"
              id="new_status"
              component={selectField}
              label="Payment Status"
              options={options}
              feedback={afterStausChange}
              validate={[required]}
            />
          </div>
        </div>
        {displayTxnFields ? (
          <>
            <hr />
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="payment_gateway"
                  id="payment_gateway"
                  component={selectField}
                  label="Payment Gateway"
                  options={[
                    { label: 'QuickPay', value: 'quick_pay' },
                    { label: 'ZottoPay', value: 'zotto_pay' }
                  ]}
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="trans_id"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Transaction Id"
                  validate={[required]}
                />
              </div>
            </div>
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={inProgress}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={inProgress || pristine || submitting}
          variant="primary"
        >
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}

const OrderStatusForm = reduxForm({
  form: 'orderStatusForm' // a unique identifier for this form
})(orderStatusFormComp);

function OrderStatusDropdown({ value, orderId, reload, clickable }) {
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  // const clickable = false;

  const handleChange = () => {
    if (clickable) {
      setShowModal(true);
    }
  };
  const handleConfirm = async (formValue = {}) => {
    try {
      setInProgress(true);
      await orderStatusChange({
        order_id: orderId,
        ...formValue
      });
      reload();
      setShowModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setInProgress(false);
    }
  };
  const handleHideModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <span
        className={getOrderStatusClass(
          value,
          clickable ? 'cursor-pointer' : ''
        )}
        onClick={handleChange}
        role="presentation"
      >
        {value}
      </span>
      <Modal show={showModal} onHide={handleHideModal} backdrop="static">
        <OrderStatusForm
          hideModal={handleHideModal}
          onSubmit={handleConfirm}
          inProgress={inProgress}
          initialValues={{ new_status: value }}
        />
      </Modal>
    </>
  );
}

export default OrderStatusDropdown;
