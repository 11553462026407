import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import masterCardImg from '../../assets/images/master.png';
import visaCardImg from '../../assets/images/visa.png';
import { paymentOption as paymentOptionActions } from '../../actions';

class PaymentOptionItem extends React.Component {
  handleEdit = paymentOption => {
    const { showModal } = this.props;
    if (paymentOption) {
      let city = '';
      let otherCity = '';
      if (paymentOption.city_details) {
        city = paymentOption.city_details.id;
      } else if (paymentOption.city) {
        city = 'others';
        otherCity = paymentOption.city;
      }
      showModal({
        ...paymentOption,
        country: paymentOption.country ? paymentOption.country.iso2 : '',
        state: paymentOption.state ? paymentOption.state.id : '',
        city,
        otherCity,
        optionType: 'bank'
      });
    }
  };

  render() {
    const { paymentOption, optionType, handleDelete, showAlert } = this.props;

    if (optionType === 'card') {
      return (
        <div className="col-sm-6 col-md-3 mb-3">
          <div className="card border border-primary h-100 mb-0">
            <div className="card-body">
              <h5 className="card-title">{paymentOption.card_holder_name}</h5>
              <p className="card-text mb-1 font-13">
                <img
                  alt="card"
                  src={
                    paymentOption.card_type === 'visa'
                      ? visaCardImg
                      : masterCardImg
                  }
                  className="mb-2"
                  height="24"
                />
                <br />
                {`${paymentOption.card_type} ${paymentOption.card_number}`}
                <br />
                <span className={paymentOption.exp_month ? 'text-danger1' : ''}>
                  {paymentOption.exp_month ? 'Expires' : ''}{' '}
                  {`${
                    paymentOption.exp_month
                  }/${`${paymentOption.exp_year}`.substr(2, 2)}`}
                </span>
              </p>
              <button
                type="button"
                className="btn btn-link p-0 card-link text-custom font-12"
                onClick={() => showAlert({ ...paymentOption, optionType })}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      );
    }
    if (optionType === 'bank') {
      return (
        <div className="col-sm-6 col-md-3 mb-3">
          <div className="card border border-primary h-100 mb-0">
            <div className="card-body">
              <h5 className="card-title">{paymentOption.account_name}</h5>
              <p className="card-text font-13 mb-1">
                {paymentOption.iban}
                <br />
                {paymentOption.bic_code}
                <br />
                {paymentOption.city || paymentOption.city_details ? (
                  <>
                    {paymentOption.city_details
                      ? paymentOption.city_details.name
                      : paymentOption.city}
                    <br />
                  </>
                ) : null}
                {paymentOption.state ? (
                  <>
                    {paymentOption.state.name}
                    <br />
                  </>
                ) : null}
                {paymentOption.country ? (
                  <>
                    {paymentOption.country.name}
                    <br />
                  </>
                ) : null}
              </p>
              {/* <div className=" font-12 text-uppercase mb-2">
                <span className={`badge mr-1 badge-${paymentOption.type === 'Current' ? 'info' : 'warning'}`}>{paymentOption.type}</span>
              </div> */}
              <button
                type="button"
                className="btn btn-link p-0 card-link text-custom font-12"
                onClick={() => this.handleEdit(paymentOption)}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-link p-0 card-link text-custom font-12"
                onClick={() => showAlert({ ...paymentOption, optionType })}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  // something
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal: paymentOptionActions.showModal,
      showAlert: paymentOptionActions.showAlert
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentOptionItem);
