import { fsb as fsbActionTypes } from '../actions';
import initialState from '../constants/initialState';

function fsb(state = initialState.fsb, action) {
  switch (action.type) {
    case fsbActionTypes.FSB_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case fsbActionTypes.FSB_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          plan: action.data
        };
      }
      return state;
    case fsbActionTypes.FSB_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case fsbActionTypes.FSB_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case fsbActionTypes.FSB_UPDATE_SUCCESS:
      if (action.data) {
        return {
          ...state,
          plan: action.data,
          updateProgress: false
        };
      }
      return {
        ...state,
        updateProgress: false
      };
    case fsbActionTypes.FSB_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case fsbActionTypes.FSB_PERFORMANCE_LIST_REQUEST:
      return {
        ...state,
        isLoadingPerformance: true
      };
    case fsbActionTypes.FSB_PERFORMANCE_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoadingPerformance: false,
          performance: action.data
        };
      }
      return state;
    case fsbActionTypes.FSB_PERFORMANCE_LIST_ERROR:
      return {
        ...state,
        isLoadingPerformance: false
      };
    case fsbActionTypes.FSB_PERFORMANCE_DETAILS_REQUEST:
      return {
        ...state,
        isLoadingPerformanceDetails: true
      };
    case fsbActionTypes.FSB_PERFORMANCE_DETAILS_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoadingPerformanceDetails: false,
          performanceDetails: {
            ...state.performanceDetails,
            [[action.data.user_id]]: action.data
          }
        };
      }
      return state;
    case fsbActionTypes.FSB_PERFORMANCE_DETAILS_ERROR:
      return {
        ...state,
        isLoadingPerformanceDetails: false
      };
    default:
      return state;
  }
}

export default fsb;
