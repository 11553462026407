import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { tree as treeActionTypes, order as orderActionTypes } from '../actions';

function* getAssociates(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/associate/genealogy-tree/${actions.data.id}`,
      method: 'GET',
      params: actions.data.params
    });
    if (responseJson) {
      yield put({
        type: treeActionTypes.ASSOCIATE_TREE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getDownlineAssociates(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/associate/downline-tree`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: treeActionTypes.DOWNLINE_ASSOCIATE_SUCCESS,
        data: responseJson
      });
      if (actions.data && actions.data.dispatchDownlineOrder) {
        yield put({
          type: orderActionTypes.ORDER_ALL_LIST_REQUEST,
          data: { orderType: 'associate', ids: [], page: 1 }
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchAssociateTree() {
  yield takeLatest(treeActionTypes.ASSOCIATE_TREE_REQUEST, getAssociates);
}

function* watchDownlineAssociate() {
  yield takeLatest(
    treeActionTypes.DOWNLINE_ASSOCIATE_REQUEST,
    getDownlineAssociates
  );
}

export default [fork(watchAssociateTree), fork(watchDownlineAssociate)];
