const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

/**
 * Returns the date
 * @param {number} dd the date
 * @param {number} mm the months, 0 to 11
 * @param {number} yy e.g. 2021
 */
const generateDate = (dd, mm, yy) => {
  const tempDate = new Date();
  tempDate.setFullYear(yy, mm, dd);
  return tempDate;
};

const getDuration = (durationType, start, end) => {
  const [d1, m1, y1] = start.split('/');
  const [d2, m2, y2] = end.split('/');
  const startDate = generateDate(d1, m1 - 1, y1 - 1);
  const endDate = generateDate(d2, m2 - 1, y2);

  if (durationType === 'daily')
    return {
      prev: 'Yesterday',
      next: 'Today',
      start: days[startDate.getDay()],
      end: days[endDate.getDay()]
    };
  if (durationType === 'monthly')
    return {
      prev: 'Last month',
      next: 'This month',
      start: months[startDate.getMonth() - 1],
      end: months[endDate.getMonth()]
    };
  if (durationType === 'last_month')
    return {
      prev: 'Penultimate',
      next: 'Last month',
      start: months[startDate.getMonth() - 1],
      end: months[endDate.getMonth()]
    };
  if (durationType === 'last_year')
    return {
      prev: 'Penultimate',
      next: 'Last year',
      start: startDate.getFullYear(),
      end: endDate.getFullYear()
    };
  if (durationType === 'last_seven_days')
    return {
      prev: '7 days ago',
      next: 'Today',
      start,
      end
    };
  if (durationType === 'last_thirty_days')
    return {
      prev: '30 days ago',
      next: 'Today',
      start,
      end
    };
  if (durationType === 'custom')
    return {
      prev: 'Previously',
      next: 'Now',
      start,
      end
    };
  return {
    prev: 'Last year',
    next: 'This year',
    start: startDate.getFullYear(),
    end: endDate.getFullYear()
  };
};

export default getDuration;
