import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import CouponForm from './CouponForm';
import DisplayAppliedCoupon from './DisplayAppliedCoupon';
import { validateCoupon } from '../../../services/apiService';
import storageService from '../../../services/storageService';

function ApplyCoupon({ cartId, couponDetails, dispatch }) {
  const [state, setState] = useState({
    couponApplied: false,
    coupon: ''
  });

  const saveCoupon = value => {
    // Save in state
    setState({
      couponApplied: true,
      coupon: value
    });

    // Set to checkout form
    dispatch(change('checkoutForm', 'coupon_code', value));

    // Set in storage
    storageService.setAppliedCoupon(value);
  };

  const handleApplyCoupon = async value => {
    try {
      // Validate coupon
      const couponRes = await validateCoupon({
        cart_id: cartId,
        coupon_code: value
      });

      // Check if success
      if (
        couponRes &&
        couponRes.coupon_details &&
        couponRes.coupon_details.error_message
      ) {
        return Promise.reject(couponRes.coupon_details.error_message);
      }
      saveCoupon(value);
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const handleRemoveCoupon = () => {
    setState({
      couponApplied: false,
      coupon: ''
    });

    // Remove from checkout form
    dispatch(change('checkoutForm', 'coupon_code', ''));

    // Remove from storage
    storageService.setAppliedCoupon('');
  };

  useEffect(() => {
    // Check if any coupon code was applied
    const appliedCoupon = storageService.getAppliedCoupon();
    if (appliedCoupon) {
      // Validate coupon
      saveCoupon(appliedCoupon);
    }
  }, []);

  if (state.couponApplied) {
    return (
      <DisplayAppliedCoupon
        coupon={state.coupon}
        couponDetails={couponDetails}
        onDelete={handleRemoveCoupon}
      />
    );
  }

  return (
    <CouponForm onApply={handleApplyCoupon} errorMsg={state.errorMessage} />
  );
}

export default connect(
  null,
  null
)(ApplyCoupon);
