const shop = {
  SHOP_PRODUCT_REQUEST: 'SHOP_PRODUCT_REQUEST',
  SHOP_PRODUCT_SUCCESS: 'SHOP_PRODUCT_SUCCESS',
  SHOP_PRODUCT_ERROR: 'SHOP_PRODUCT_ERROR',
  SEARCH_REQUEST: 'SEARCH_REQUEST',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_ERROR: 'SEARCH_ERROR',
  GET_SHOP_CATEGORY_REQUEST: 'GET_SHOP_CATEGORY_REQUEST',
  GET_SHOP_CATEGORY_SUCCESS: 'GET_SHOP_CATEGORY_SUCCESS',
  GET_SHOP_CATEGORY_ERROR: 'GET_SHOP_CATEGORY_ERROR',
  searchItem: params => ({
    type: shop.SEARCH_REQUEST,
    params
  }),
  getProductDetails: params => ({ type: shop.SHOP_PRODUCT_REQUEST, params }),
  getShopCategory: param => ({ type: shop.GET_SHOP_CATEGORY_REQUEST, param })
};

export default shop;
