import { associateFsb as associateFsbActionTypes } from '../actions';
import initialState from '../constants/initialState';

function associateFsb(state = initialState.associateFsb, action) {
  switch (action.type) {
    case associateFsbActionTypes.ASSOC_FSB_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case associateFsbActionTypes.ASSOC_FSB_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          plan: action.data
        };
      }
      return state;
    case associateFsbActionTypes.ASSOC_FSB_ERROR:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}

export default associateFsb;
