import React, { useState, useEffect } from 'react';
import { Field, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { find } from 'lodash';
import {
  selectField,
  inputField,
  inputGroupField
} from '../../components/Fields';
import { required, number } from '../../utills/validator';
import { getAllCountryCurrencies } from '../../services/apiService';

function CountryNameComp({ value, countryList }) {
  const [countryName, setCountryName] = useState(value);
  useEffect(() => {
    const details = find(countryList, o => o.value === value);
    if (details) {
      setCountryName(details.label);
    }
  }, [value, countryList]);

  return countryName;
}

const mapStateToProps2 = state => ({
  countryList: state.app.countryList
});

const CountryName = connect(
  mapStateToProps2,
  null
)(CountryNameComp);

function TaxItemField({ fields, taxList, shippingList, formValues }) {
  const [taxOptions, setTaxOptions] = useState([]);
  const [countryCurrencies, setCountryCurrencies] = useState({});

  useEffect(() => {
    const taxes = [];
    taxList.forEach(item => {
      if (!item.tax_rate_shipping) {
        taxes.push({
          value: item.id,
          label: `${item.tax_rate_name} - ${item.tax_rate_country} (${item.tax_rate}%)`
        });
      }
    });

    setTaxOptions(taxes);
  }, [taxList]);

  const [shippingOptions, setShippingOptions] = useState([]);

  useEffect(() => {
    const taxes = shippingList.map(item => ({
      value: item.id,
      label: `${item.title} - ${item.country} (${
        item.rate_type === 1 ? 'Flat Rate' : 'Rate by Weight'
      })`
    }));
    setShippingOptions(taxes);
  }, [shippingList]);

  const [vatOptions, setVatOptions] = useState([]);

  useEffect(() => {
    const taxes = [];
    taxList.forEach(item => {
      if (item.tax_rate_shipping) {
        taxes.push({
          value: item.id,
          label: `${item.tax_rate_name} - ${item.tax_rate_country} (${item.tax_rate}%)`
        });
      }
    });
    setVatOptions(taxes);
  }, [taxList]);

  useEffect(() => {
    const fetcCurrencies = async () => {
      try {
        const res = await getAllCountryCurrencies();
        if (res) {
          setCountryCurrencies(res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetcCurrencies();
  }, []);

  return (
    <>
      {fields.map((name, index) => {
        const currency = countryCurrencies[formValues.countries[index].country]
          ? countryCurrencies[formValues.countries[index].country]
              .currency_symbol
          : '';
        return (
          <div className="card" key={index}>
            <div className="card-header col-12">
              <div className="card-widgets">
                <button
                  type="button"
                  className="btn btn-icon btn-sm"
                  onClick={() => fields.remove(index)}
                >
                  <i className="mdi mdi-close-circle-outline" />
                </button>
              </div>
              <CountryName value={formValues.countries[index].country} />
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.weight`}
                    component={inputField}
                    type="number"
                    className="form-control"
                    label="Product Weight (kg)"
                    placeholder="0.00"
                    validate={[required, number]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.bpoint`}
                    component={inputField}
                    type="number"
                    className="form-control"
                    label="B-Points"
                    placeholder="0.00"
                    validate={[required, number]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.price`}
                    component={inputGroupField}
                    type="number"
                    className="form-control"
                    label="Price"
                    placeholder="0.00"
                    validate={[required, number]}
                  >
                    <div className="input-group-text">{currency}</div>
                  </Field>
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.package_bonus_amount`}
                    component={inputField}
                    type="number"
                    className="form-control"
                    label="Package Bonus"
                    placeholder="0.00"
                    validate={[number]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.tax`}
                    component={selectField}
                    label="VAT"
                    notSearchable
                    options={[{ label: 'None', value: null }, ...taxOptions]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.shipping`}
                    component={selectField}
                    label="Shipping "
                    notSearchable
                    options={[
                      { label: 'None', value: null },
                      ...shippingOptions
                    ]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.shipping_vat`}
                    component={selectField}
                    label="Shipping VAT"
                    notSearchable
                    options={[{ label: 'None', value: null }, ...vatOptions]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.bp_restriction`}
                    component={inputField}
                    type="number"
                    className="form-control"
                    label="30-BP Check (Month)"
                    validate={[required, number]}
                  />
                </div>
                <div className="col-lg-3 col-sm-6 col-md-4 font-12">
                  <Field
                    name={`${name}.shop_restriction`}
                    component={inputField}
                    type="number"
                    className="form-control"
                    label="Shop Restriction (Days)"
                    validate={[required, number]}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

const mapStateToProps = state => ({
  formValues: getFormValues('packageForm')(state),
  taxList: state.app.taxList,
  shippingList: state.app.shippingList
});

export default connect(
  mapStateToProps,
  null
)(TaxItemField);
