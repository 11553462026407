import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CategoryForm from './CategoryForm';
import BreadCrumb from '../../components/BreadCrumb';
import { category as categoryActions } from '../../actions';
import routePath from '../../constants/routePath';

class CategoryEdit extends React.Component {
  componentDidMount() {
    const { match, getCategoryById } = this.props;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getCategoryById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateCategory, match, imageFile } = this.props;
    updateCategory({
      id: parseInt(match.params.id, 10),
      data: { ...values, image: imageFile },
      redirect: true
    });
  };

  render() {
    const { updateProgress, categoryDetails, match } = this.props;
    let data = null;
    let dataObj = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      dataObj = categoryDetails[match.params.id];
      if (dataObj) {
        data = {
          title: dataObj.title,
          parent_id: dataObj.parent_id,
          short_description: dataObj.short_description,
          status: dataObj.status,
          slug: dataObj.slug,
          country_iso2: dataObj.country_iso2,
          states: dataObj.states
        };
      }
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.categoryEdit.breadcrumb} />
              <h4 className="page-title">{routePath.categoryEdit.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card category_add">
              <div className="card-body">
                {data ? (
                  <CategoryForm
                    submitForm={this.handleSubmit}
                    initialValues={data}
                    defaultImage={
                      dataObj.file
                        ? `${process.env.REACT_APP_IMAGE_URL}/${dataObj.file.file_path}`
                        : ''
                    }
                    inProgress={updateProgress}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.category.updateProgress,
  categoryDetails: state.category.categoryDetails,
  imageFile: state.category.imageFile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCategory: categoryActions.updateCategory,
      getCategoryById: categoryActions.getCategoryById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CategoryEdit)
);
