import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  permission as permissionActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getRoles() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/role`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: permissionActionTypes.ROLE_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: permissionActionTypes.ROLE_LIST_ERROR, data: error });
  }
}

function* getPermissions(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/permission`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: permissionActionTypes.PERMISSION_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: permissionActionTypes.PERMISSION_LIST_ERROR,
      data: error
    });
  }
}

function* getRolePermission(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/role/${actions.data.id}/permission`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: permissionActionTypes.ROLE_PERMISSION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: permissionActionTypes.ROLE_PERMISSION_ERROR,
      data: error
    });
  }
}

function* updateRolePermission(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/role/${actions.data.id}/permission`,
      method: 'PUT',
      data: actions.data.data
    });
    if (responseJson) {
      yield put({
        type: permissionActionTypes.UPDATE_ROLE_PERMISSION_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.role.path
      });
    }
  } catch (error) {
    yield put({
      type: permissionActionTypes.UPDATE_ROLE_PERMISSION_ERROR,
      data: error
    });
  }
}

function* getUsersByRole(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/role/${actions.data.id}/users`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: permissionActionTypes.USER_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: permissionActionTypes.USER_LIST_ERROR, data: error });
  }
}

function* watchRoleList() {
  yield takeLatest(permissionActionTypes.ROLE_LIST_REQUEST, getRoles);
}

function* watchPermissionList() {
  yield takeLatest(
    permissionActionTypes.PERMISSION_LIST_REQUEST,
    getPermissions
  );
}

function* watchRolePermission() {
  yield takeLatest(
    permissionActionTypes.ROLE_PERMISSION_REQUEST,
    getRolePermission
  );
}

function* watchUpdateRolePermission() {
  yield takeLatest(
    permissionActionTypes.UPDATE_ROLE_PERMISSION_REQUEST,
    updateRolePermission
  );
}

function* watchUserList() {
  yield takeLatest(permissionActionTypes.USER_LIST_REQUEST, getUsersByRole);
}

export default [
  fork(watchRoleList),
  fork(watchPermissionList),
  fork(watchRolePermission),
  fork(watchUpdateRolePermission),
  fork(watchUserList)
];
