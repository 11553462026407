import React from 'react';

class MyDownline extends React.Component {
  state = {
    expand: false
  };

  toggleDownline = () => {
    this.setState(prevState => ({ expand: !prevState.expand }));
  };

  render() {
    const { expand } = this.state;
    const { item, itemObj, margin } = this.props;
    return (
      <div className="dl-card" style={{ marginLeft: margin }}>
        <div>
          <p>
            <strong>{`ID: ${item.id} / UUID: ${item.uuid} - ${item.first_name} ${item.last_name}`}</strong>
          </p>
          {item.b_points ? (
            <>
              <p>
                <label>Associate Signup: </label>
                <strong>{item.b_points.associate_signup}</strong>
              </p>
              <p>
                <label>Own Purchase: </label>
                <strong>{item.b_points.own_purchase}</strong>
              </p>
              <p>
                <label>Customer Purchase: </label>
                <strong>{item.b_points.customer_purchase}</strong>
              </p>
              <p>
                <label>Downline Associate Signup: </label>
                <strong>{item.b_points.downline_associate_signup}</strong>
              </p>
              <p>
                <label>Downline Associate Own Purchase: </label>
                <strong>{item.b_points.downline_associate_own_purchase}</strong>
              </p>
              <p>
                <label>Downline Customer Purchase: </label>
                <strong>{item.b_points.downline_customer_purchase}</strong>
              </p>
              <p>
                <label>Total</label>
                <span />
              </p>
            </>
          ) : null}
          <button type="button" onClick={this.toggleDownline}>
            {`Downline (${item.referrals ? item.referrals.length : 0})`}
          </button>
        </div>
        {expand && item.referrals ? (
          <div>
            {item.referrals.map(refItem => (
              <MyDownline
                item={itemObj[refItem.user_id]}
                itemObj={itemObj}
                margin={margin + 1}
              />
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

export default MyDownline;
