import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get, find } from 'lodash';
import {
  // Button,
  Nav,
  ProgressBar,
  Tab
  // Alert
} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import InnerLoader from '../../components/InnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import { product as productActions } from '../../actions';
import routePath from '../../constants/routePath';
import { productImage } from '../../constants/imageSize';
import { getImageUrl } from '../../utills/custom';
import 'react-image-gallery/styles/css/image-gallery.css';
import NoResult from '../../components/NoResult';

// Stock Table
const Stocks = props => (
  <React.Fragment>
    <div className="table-responsive mt-4">
      <table className="table table-bordered table-centered mb-0">
        <thead className="thead-light">
          <tr>
            <th>Outlets</th>
            <th>Price</th>
            <th>Stock</th>
            <th>Revenue</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ASOS Ridley Outlet - NYC</td>
            <td>$139.58</td>
            <td>
              <div className="progress-w-percent mb-0">
                <span className="progress-value">478 </span>
                <ProgressBar now={56} className="progress-sm" />
              </div>
            </td>
            <td>$1,89,547</td>
          </tr>
          <tr>
            <td>Marco Outlet - SRT</td>
            <td>$149.99</td>
            <td>
              <div className="progress-w-percent mb-0">
                <span className="progress-value">73 </span>
                <ProgressBar now={16} className="progress-sm" color="danger" />
              </div>
            </td>
            <td>$87,245</td>
          </tr>
          <tr>
            <td>Chairtest Outlet - HY</td>
            <td>$135.87</td>
            <td>
              <div className="progress-w-percent mb-0">
                <span className="progress-value">781 </span>
                <ProgressBar now={72} className="progress-sm" color="success" />
              </div>
            </td>
            <td>$5,87,478</td>
          </tr>
          <tr>
            <td>Nworld Group - India</td>
            <td>$159.89</td>
            <td>
              <div className="progress-w-percent mb-0">
                <span className="progress-value">815 </span>
                <ProgressBar now={89} className="progress-sm" color="success" />
              </div>
            </td>
            <td>$55,781</td>
          </tr>
        </tbody>
      </table>
    </div>
  </React.Fragment>
);

class ProductView extends React.Component {
  componentDidMount() {
    const { match, getProductById } = this.props;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getProductById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateProduct, match } = this.props;
    updateProduct({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  processImages = (images, title) => {
    const { imageStyle } = this.props;
    return images.map(image => ({
      original: `${process.env.REACT_APP_IMAGE_URL}/${getImageUrl(
        image.file.file_path,
        imageStyle[productImage.productGridMedium]
      )}`,
      thumbnail: `${process.env.REACT_APP_IMAGE_URL}/${getImageUrl(
        image.file.file_path,
        imageStyle[productImage.productGridThumb]
      )}`,
      originalAlt: title,
      thumbnailAlt: title
    }));
  };

  render() {
    const { product, match, loading } = this.props;
    const productList = get(product, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      const tmpData = find(productList, { id: parseInt(match.params.id, 10) });
      if (tmpData && tmpData.product_category) {
        data = tmpData;
      }
    }

    return (
      <React.Fragment>
        {loading ? (
          <div className="mt-5">
            <InnerLoader type="table" />
          </div>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <BreadCrumb list={routePath.productView.breadcrumb} />
                  <h4 className="page-title">{routePath.productView.title}</h4>
                </div>
              </div>
            </div>
            {/* <Alert variant="success">
              <Link to={routePath.cart.path} className="btn btn-dark btn-sm float-right">View cart</Link>
              <p className="mt-1 mb-0">
                <i className="dripicons-checkmark mr-2" />
                {' '}
                <strong>Sample Product</strong>
                {' '}
                added in your cart.
              </p>
              <div className="clearfix" />
            </Alert> */}
            {data ? (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-5 mb-3">
                          {data.product_images.length ? (
                            <ImageGallery
                              items={this.processImages(
                                data.product_images,
                                data.title
                              )}
                              showPlayButton={false}
                              useBrowserFullscreen={false}
                              showThumbnails={
                                data.product_images &&
                                data.product_images.length > 1
                              }
                            />
                          ) : (
                            <NoResult
                              icon="mdi mdi-image-broken-variant"
                              title="No images"
                              body="This product currently has no images."
                            />
                          )}
                        </div>
                        <div className="col-lg-7">
                          <form className="pl-lg-4">
                            <h3 className="mt-0">
                              {data.title}
                              <Link
                                to={`/product/${data.id}/edit`}
                                className="btn btn-sm btn-rounded btn-primary font-weight-normal font-12 ml-2"
                              >
                                <i className="mdi mdi-square-edit-outline" />{' '}
                                Edit
                              </Link>
                            </h3>
                            <h6 className="font-14">{`SKU: ${data.sku}`}</h6>
                            <p className="font-16 d-none">
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                            </p>
                            <h4>
                              <span className="badge badge-success-lighten">
                                {`In stock (${parseInt(data.stock_qty, 10)})`}
                              </span>
                            </h4>
                            <div className="mt-3">
                              <h3 className="text-primary">
                                {`€${
                                  data.taxable
                                    ? data.member_price_vat
                                    : data.member_price
                                } `}
                                {data.taxable && (
                                  <span className="badge badge-light text-muted font-10">
                                    incl. VAT
                                  </span>
                                )}
                              </h3>
                              {/* {data.sale_price ? (
                                <>
                                  <h5 className="text-strikeout font-weight-normal">{`${data.taxable ? data.member_price_vat : data.member_price} `}</h5>
                                  <h3 className="text-primary">
                                    {`${data.sale_price} `}
                                    <span className="badge badge-light text-muted font-10">incl. VAT</span>
                                  </h3>
                                </>
                              ) : (
                                <h3 className="text-primary">
                                  {`${data.taxable ? data.member_price_vat : data.member_price} `}
                                  <span className="badge badge-light text-muted font-10">incl. VAT</span>
                                </h3>
                              )} */}
                            </div>
                            <div className="mt-3">
                              <h6 className="font-14">Quantity</h6>
                              <div className="row">
                                <div className="col-12 mb-3 mb-sm-0 col-sm-3 col-md-3">
                                  <div className="input-group input-qty">
                                    <div className="input-group-append">
                                      <button
                                        disabled
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        -
                                      </button>
                                    </div>
                                    <input
                                      readOnly
                                      type="number"
                                      min="1"
                                      value="1"
                                      className="form-control no-spinner p-0 text-center"
                                    />
                                    <div className="input-group-append">
                                      <button
                                        disabled
                                        type="button"
                                        className="btn btn-light"
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-5">
                                  <button
                                    disabled
                                    type="button"
                                    className="btn btn-danger"
                                  >
                                    <i className="mdi mdi-cart" />
                                    <span> Add to cart</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              {/* <h6 className="font-14">Description:</h6> */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.excerpt
                                }}
                              />
                            </div>
                            {/* <div className="mt-4">
                              <div className="row">
                                <div className="col-md-4">
                                  <h6 className="font-14">Available Stock:</h6>
                                  <p className="text-sm lh-150">{parseInt(data.stock_qty, 10)}</p>
                                </div>
                                <div className="col-md-4">
                                  <h6 className="font-14">Number of Orders:</h6>
                                  <p className="text-sm lh-150">5,458</p>
                                </div>
                                <div className="col-md-4">
                                  <h6 className="font-14">Revenue:</h6>
                                  <p className="text-sm lh-150">$8,57,014</p>
                                </div>
                              </div>
                            </div> */}
                          </form>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Tab.Container
                          id="details-tabs"
                          defaultActiveKey="description"
                        >
                          <Nav className="nav nav-tabs nav-bordered mb-2 no-rounded">
                            <Nav.Item>
                              <Nav.Link eventKey="description">
                                Description
                              </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                              <Nav.Link eventKey="specifications">Specifications</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                            </Nav.Item> */}
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="description" className="p-2">
                              <h6 className="font-14">Description:</h6>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.description
                                }}
                              />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="specifications" className="p-2" />
                            <Tab.Pane eventKey="reviews" className="p-2" /> */}
                          </Tab.Content>
                        </Tab.Container>
                      </div>

                      {/* <div className="table-responsive">
                        <Stocks />
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>404 Page</p>
            )}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.product.isLoadingSingle,
  product: state.product.allList,
  imageStyle: state.app.imageStyle
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProductById: productActions.getProductById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductView)
);
