import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { associateFsb as associateFsbActionTypes } from '../actions';

function* getAssociateFsb() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb-track`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: associateFsbActionTypes.ASSOC_FSB_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetAssociateFsb() {
  yield takeLatest(associateFsbActionTypes.ASSOC_FSB_REQUEST, getAssociateFsb);
}

export default [fork(watchGetAssociateFsb)];
