import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form, reduxForm, change, getFormValues } from 'redux-form';
import Select from 'react-select';
import { required } from '../../utills/validator';
import {
  inputField,
  inputFieldControlled,
  editorField,
  switchField
} from '../../components/Fields';
import {
  app as appActionTypes,
  category as categoryActions
} from '../../actions';
import routePath from '../../constants/routePath';
import { getSlug, hierarchyDash } from '../../utills/custom';
import btnLoader from '../../assets/images/btn-loader.gif';
import CategoryImage from './CategoryImage';
import SelectedCountryItem from './SelectedCountryItem';

class CategoryForm extends React.Component {
  noVal = '';

  componentDidMount() {
    const { getPrefetchCategory, getCountry } = this.props;
    getPrefetchCategory();
    getCountry();
  }

  componentWillUnmount() {
    const { deleteCategoryImageFile } = this.props;
    deleteCategoryImageFile();
  }

  handleSlug = val => {
    const { dispatch } = this.props;
    dispatch(change('categoryForm', 'slug', getSlug(val)));
  };

  handleCountrySelect = val => {
    const { dispatch, formValues } = this.props;
    const isPresent = formValues.country_iso2.includes(val.value);
    if (!isPresent) {
      dispatch(
        change('categoryForm', 'country_iso2', [
          ...formValues.country_iso2,
          val.value
        ])
      );
    }
  };

  render() {
    const {
      defaultImage,
      handleSubmit,
      submitForm,
      categoryList,
      inProgress,
      countryList,
      formValues
    } = this.props;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="title"
              component={inputFieldControlled}
              type="text"
              className="form-control"
              label="Title"
              feedback={this.handleSlug}
              validate={[required]}
            />
            <Field
              name="short_description"
              component={editorField}
              className="form-control"
              label="Short Description"
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row justify-content-md-center mt-2">
              <div className="col-xs-12 col-md-5">
                <CategoryImage defaultImage={defaultImage} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="slug"
              component={inputField}
              type="text"
              className="form-control"
              label="Slug"
            />
            <div>
              <label htmlFor="country_iso2">Choose Country</label>
              <Select
                name="country_iso2"
                placeholder="Select ..."
                label="Choose Country"
                onBlur={event => event.preventDefault()}
                onChange={this.handleCountrySelect}
                value={this.noVal}
                options={countryList}
                className="react-select"
                classNamePrefix="react-select"
              />
            </div>
          </div>
          {formValues.country_iso2 && (
            <div className="col-xs-12 col-md-12 d-flex flex-wrap mt-2 mb-2">
              {formValues.country_iso2.map(item => (
                <SelectedCountryItem key={`sel_c_${item}`} data={item} />
              ))}
            </div>
          )}
          <div className="col-xs-12 col-md-6">
            <div className="form-group input-large">
              <label htmlFor="country">Parent</label>
              <Field
                className="form-control"
                name="parent_id"
                component="select"
              >
                <option value="">None</option>
                {categoryList.map(item => (
                  <option key={item.id} value={item.id}>
                    {`${hierarchyDash(item.hierarchyLevel)} ${item.title}`}
                  </option>
                ))}
              </Field>
            </div>
            <Field
              name="status"
              component={switchField}
              label="Status"
              id="categoryStatus"
              type="success"
            />
            <Link className="btn btn-light mr-2" to={routePath.category.path}>
              Cancel
            </Link>
            <button
              type="submit"
              disabled={inProgress}
              className="btn btn-primary"
            >
              {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  categoryList: state.app.categoryList,
  formValues: getFormValues('categoryForm')(state),
  countryList: state.app.countryList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrefetchCategory: appActionTypes.getPrefetchCategory,
      getCountry: appActionTypes.getCountry,
      deleteCategoryImageFile: categoryActions.deleteCategoryImageFile
    },
    dispatch
  );

CategoryForm.defaultProps = {
  initialValues: {}
};

const CategoryFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryForm);

export default reduxForm({
  form: 'categoryForm' // a unique identifier for this form
})(CategoryFormWithRedux);
