import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { address as addressActions } from '../../actions';
import AddressForm from './AddressForm';

class AddressModal extends React.Component {
  handleClose = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const { modal, address, updateProgress } = this.props;

    return (
      <Modal
        show={modal}
        onHide={this.handleClose}
        size="lg"
        dialogClassName="address-modal-dialog"
      >
        {modal && (
          <AddressForm
            initialValues={address}
            inProgress={updateProgress}
            enableReinitialize="true"
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.address.modal,
  address: state.address.editAddress,
  updateProgress: state.address.updateProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: addressActions.hideModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressModal);
