import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { inputGroupField, selectField } from '../../components/Fields';

const ProductModalForm = ({
  name,
  handleChangePrice,
  fieldValue,
  taxOptions,
  isCheckValue,
  currency
}) => {
  return (
    <div>
      <div className="border-bottom mt-2">
        <Field
          name={`${name}.regular_price`}
          component={inputGroupField}
          type="number"
          label="Regular Price"
          tooltip="Regular Price"
          inline={['col-5', 'col-7']}
          className="form-control"
          inputGroupClassName="input-group input-group-sm mb-0"
          groupClassName="form-group"
          feedback={value => handleChangePrice('regular_price', value)}
          readOnly={isCheckValue} // should be disabled if the value should be converted by back
        >
          <div className="input-group-text">{currency}</div>
        </Field>

        <Field
          name={`${name}.sale_price`}
          component={inputGroupField}
          type="number"
          className="form-control"
          inputGroupClassName="input-group input-group-sm"
          label="Sale Price"
          tooltip="Sale Price"
          inline={['col-5', 'col-7']}
          feedback={value => handleChangePrice('sale_price', value)}
          readOnly={isCheckValue} // should be disabled if the value should be converted by back
        >
          <div className="input-group-text">{currency}</div>
        </Field>
        <Field
          name={`${name}.bpoint`}
          component={inputGroupField}
          type="number"
          className="form-control"
          inputGroupClassName="input-group input-group-sm"
          label="B-Points"
          tooltip="B-Points"
          inline={['col-5', 'col-7']}
          feedback={value => handleChangePrice('bpoint', value)}
        />
        <Field
          name={`${name}.cpoint`}
          component={inputGroupField}
          type="number"
          className="form-control"
          inputGroupClassName="input-group input-group-sm"
          label="C-Points"
          tooltip="Customer Points"
          inline={['col-5', 'col-7']}
          feedback={value => handleChangePrice('cpoint', value)}
        />
      </div>
      <div className="border-bottom pt-2">
        <Field
          name={`${name}.taxable`}
          component={selectField}
          label="Tax Status"
          notSearchable
          tooltip="Tax Status"
          inline={['col-5', 'col-7']}
          options={[
            { value: true, label: 'Taxable' },
            { value: false, label: 'Not taxable' }
          ]}
        />
        {fieldValue.taxable && (
          <Field
            name={`${name}.tax_id`}
            component={selectField}
            label="Tax Rate"
            notSearchable
            tooltip="Tax Rate"
            inline={['col-5', 'col-7']}
            options={taxOptions}
            feedback={value => handleChangePrice('tax_id', value)}
          />
        )}
      </div>
      <div className="pt-2">
        <Field
          name={`${name}.member_price`}
          component={inputGroupField}
          readOnly
          type="number"
          className="form-control"
          inputGroupClassName="input-group input-group-sm"
          label="Member Price"
          tooltip="Member Price"
          inline={['col-5', 'col-7']}
        >
          <div className="input-group-text">{currency}</div>
        </Field>
        <Field
          name={`${name}.member_price_vat`}
          component={inputGroupField}
          readOnly
          className="form-control"
          inputGroupClassName="input-group input-group-sm"
          label="Member Price (VAT)"
          tooltip="Member price (VAT)"
          inline={['col-5', 'col-7']}
        >
          <div className="input-group-text">{currency}</div>
        </Field>
        <Field
          name={`${name}.customer_price_vat`}
          component={inputGroupField}
          readOnly
          className="form-control"
          inputGroupClassName="input-group input-group-sm"
          label="Customer Price (VAT)"
          tooltip="Customer Price (VAT)"
          inline={['col-5', 'col-7']}
        >
          <div className="input-group-text">{currency}</div>
        </Field>
      </div>
    </div>
  );
};

export default ProductModalForm;
