import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { change, Field, getFormValues, reduxForm } from 'redux-form';
import {
  checkboxField,
  inputField,
  selectField
} from '../../components/Fields';
import { currencyDropdown } from '../../utills/custom';
import btnLoader from '../../assets/images/btn-loader.gif';
import { app as appActions } from '../../actions';

class StoreForm extends React.Component {
  componentDidMount() {
    const {
      getCountry,
      getCityByState,
      getStateByCountry,
      initialValues
    } = this.props;
    // Fetch state and city list
    getCountry();

    if (initialValues.country) {
      getStateByCountry(initialValues.country);
    }
    if (initialValues.state) {
      getCityByState(initialValues.state);
    }
  }

  countryChange = val => {
    const { dispatch, getStateByCountry } = this.props;
    dispatch(change('editStoreForm', 'city', ''));
    dispatch(change('editStoreForm', 'state', ''));
    if (val) {
      getStateByCountry(val);
    }
  };

  stateChange = val => {
    const { dispatch, getCityByState } = this.props;
    dispatch(change('editStoreForm', 'city', ''));
    if (val) {
      getCityByState(val);
    }
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      pristine,
      submitting,
      countryList
    } = this.props;

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(submitForm)}
              >
                <h4 className="header-title mt-3 mb-3 text-primary">
                  General Options
                </h4>
                <div className="row">
                  {/* <div className="col-md-4">
            <Field
              name="selling_location"
              component={selectField}
              multiple
              label="Selling locations(s)"
              options={countryList}
            />
          </div> */}
                  <div className="col-md-6">
                    <Field
                      name="shipping_location"
                      component={selectField}
                      multiple
                      label="Shipping Location(s)"
                      options={countryList}
                    />
                  </div>
                  {/* <div className="col-md-4">
            <Field
              name="def_cust_location"
              component={selectField}
              multiple
              label="Default Customer Location"
              options={countryList}
            />
          </div> */}
                  <div className="col-md-2 mt-2">
                    <Field
                      name="enable_tax"
                      component={checkboxField}
                      id="enable_tax"
                      label=" "
                      options={[{ value: 'yes', label: 'Enable Tax' }]}
                    />
                  </div>
                  <div className="col-md-2 mt-2">
                    <Field
                      name="enable_coupon"
                      component={checkboxField}
                      id="enable_coupon"
                      label=" "
                      options={[{ value: 'yes', label: 'Enable coupon' }]}
                    />
                  </div>
                </div>
                <h4 className="header-title mt-3 mb-3 text-primary">
                  Currency Options
                </h4>
                <div className="row">
                  <div className="col-md-6">
                    <Field
                      name="def_currency"
                      component={selectField}
                      label="Currency"
                      options={currencyDropdown()}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name="currency_position"
                      component={selectField}
                      notSearchable
                      label="Currency Position"
                      options={[
                        { value: 'left', label: 'Left' },
                        { value: 'right', label: 'Right' },
                        { value: 'left_space', label: 'Left with space' },
                        { value: 'right_space', label: 'Right with space' }
                      ]}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      name="thousand_sep"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Thousand separator"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      name="decimal_sep"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Decimal Separator"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      name="decimal_places"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Number of Decimals"
                      placeholder=""
                    />
                  </div>
                </div>
                <h4 className="header-title mt-3 mb-3 text-primary">
                  Measurements
                </h4>
                <div className="row">
                  <div className="col-md-2">
                    <Field
                      name="weight_unit"
                      component={selectField}
                      notSearchable
                      label="Weight unit"
                      options={[
                        { value: 'kg', label: 'kg' },
                        { value: 'g', label: 'g' },
                        { value: 'lbs', label: 'lbs' },
                        { value: 'oz', label: 'oz' }
                      ]}
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      name="dimension_unit"
                      component={selectField}
                      notSearchable
                      label="Dimensions unit"
                      options={[
                        { value: 'm', label: 'm' },
                        { value: 'cm', label: 'cm' },
                        { value: 'mm', label: 'mm' },
                        { value: 'in', label: 'in' },
                        { value: 'yd', label: 'yd' }
                      ]}
                    />
                  </div>
                </div>
                <h4 className="header-title mt-3 mb-3 text-primary">Members</h4>
                <div className="row">
                  {/* <div className="col-md-4">
            <Field
              name="BP_discount_assoc"
              component={inputField}
              type="text"
              className="form-control"
              label="B-POINTS Discount %"
              placeholder=""
            />
          </div> */}
                  <div className="col-md-2">
                    <Field
                      name="bp_value"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="BP Value"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      name="bp_discount"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="BP Discount (%)"
                      placeholder=""
                    />
                  </div>

                  <div className="col-md-2">
                    <Field
                      name="associate_signup_discount"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Associate Discount (%)"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      name="clp"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Loyalty Points - CLP (%)"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      name="crp"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Referral Points - CRP (%)"
                      placeholder=""
                    />
                  </div>
                  <div className="col-md-2">
                    <Field
                      name="cp_expiry"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="CP Expiry"
                      placeholder=""
                    />
                  </div>
                  {/* <div className="col-md-4">
            <Field
              name="payout_date"
              component={selectField}
              notSearchable
              label="Payout Date"
              id="payoutDate"
              options={payoutDropdown()}
            />
          </div> */}
                </div>
                <h4 className="header-title mt-3 mb-3 text-primary">Reviews</h4>
                <div className="row">
                  <div className="col-md-12">
                    <Field
                      name="enable_reviews"
                      component={checkboxField}
                      id="enable_reviews"
                      groupClassName="form-group mb-0"
                      label="Enable reviews"
                      options={[
                        { value: 'product', label: 'Enable product reviews' }
                      ]}
                    />
                    <Field
                      name="show_verified"
                      component={checkboxField}
                      id="show_verified_label"
                      groupClassName="form-group mb-0"
                      options={[
                        {
                          value: 'ver',
                          label:
                            'Show "verified owner" label on customer reviews'
                        }
                      ]}
                    />
                    <Field
                      name="show_verified_owner"
                      component={checkboxField}
                      id="show_verified_owner"
                      options={[
                        {
                          value: 'ver1',
                          label: 'Reviews can only be left by "verified owners"'
                        }
                      ]}
                    />
                  </div>
                  <div className="col-md-12">
                    <Field
                      name="product_rating"
                      component={checkboxField}
                      id="product_rating"
                      label="Product ratings"
                      groupClassName="form-group mb-0"
                      options={[
                        {
                          value: 'star',
                          label: 'Enable star ratings on reviews'
                        }
                      ]}
                    />
                    <Field
                      name="star_req"
                      component={checkboxField}
                      id="star_req"
                      options={[
                        {
                          value: 'star_req',
                          label: 'Star rating should be required, no optional'
                        }
                      ]}
                    />
                  </div>
                </div>

                <div className="form-group mt-2">
                  <Button
                    type="submit"
                    disabled={pristine || submitting}
                    variant="primary"
                  >
                    {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  formValues: getFormValues('editStoreForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState
    },
    dispatch
  );

const EditStoreWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreForm);

export default reduxForm({
  form: 'editStoreForm' // a unique identifier for this form
})(EditStoreWithRedux);
