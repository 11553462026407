import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { tax as taxActionTypes, app as appActionTypes } from '../actions';
import routePath from '../constants/routePath';

function* getAllTax(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/taxrate`,
      params: actions.data,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: taxActionTypes.TAX_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getTax(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/taxrate/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: taxActionTypes.TAX_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createTax(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/taxrate`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Tax was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: taxActionTypes.TAX_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.tax.path
      });
    }
  } catch (error) {
    yield put({ type: taxActionTypes.TAX_CREATE_ERROR, data: error });
  }
}

function* updateTax(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/taxrate/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Tax updated successfully'
    });
    if (responseJson) {
      yield put({
        type: taxActionTypes.TAX_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.tax.path
        });
      }
    }
  } catch (error) {
    yield put({ type: taxActionTypes.TAX_UPDATE_ERROR, data: error });
  }
}

function* updateTaxStatus(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/taxStatus/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Tax updated successfully'
    });
    if (responseJson) {
      yield put({
        type: taxActionTypes.TAX_STATUS_UPDATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: taxActionTypes.TAX_STATUS_UPDATE_ERROR, data: error });
  }
}

function* watchTaxListAll() {
  yield takeLatest(taxActionTypes.TAX_ALL_LIST_REQUEST, getAllTax);
}

function* watchGetTax() {
  yield takeLatest(taxActionTypes.TAX_REQUEST, getTax);
}

function* watchTaxCreate() {
  yield takeLatest(taxActionTypes.TAX_CREATE_REQUEST, createTax);
}

function* watchTaxUpdate() {
  yield takeLatest(taxActionTypes.TAX_UPDATE_REQUEST, updateTax);
}

function* watchTaxStatusUpdate() {
  yield takeLatest(taxActionTypes.TAX_STATUS_UPDATE_REQUEST, updateTaxStatus);
}

export default [
  fork(watchTaxListAll),
  fork(watchTaxCreate),
  fork(watchTaxUpdate),
  fork(watchTaxStatusUpdate),
  fork(watchGetTax)
];
