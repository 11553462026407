import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import routePath from '../../constants/routePath';
import { required, number } from '../../utills/validator';
import {
  inputField,
  inputFieldControlled,
  selectField
} from '../../components/Fields';
import { generation as generationActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class RankForm extends React.Component {
  componentDidMount() {
    const { getAllGenerations } = this.props;
    getAllGenerations();
  }

  render() {
    const { handleSubmit, submitForm, inProgress, generations } = this.props;
    const generationOption = [];
    if (generations && generations.data) {
      generations.data.forEach(item => {
        generationOption.push({ value: item.id, label: item.title });
      });
    }
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="title"
              component={inputFieldControlled}
              type="text"
              className="form-control"
              label="Title"
              feedback={this.handleSlug}
              validate={[required]}
            />
            <Field
              name="bp_personal"
              component={inputField}
              type="text"
              className="form-control"
              label="B-Point Personal"
              validate={[required, number]}
            />
            <Field
              name="bp_group"
              component={inputField}
              type="text"
              className="form-control"
              label="B-Point Group"
              validate={[required, number]}
            />
            <Field
              name="downline_commision"
              id="downline_commision"
              component={selectField}
              label="Downline Commission"
              options={generationOption}
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-6" />
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="btn btn-primary mr-2"
        >
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </button>
        <Button as={Link} to={routePath.rank.path} className="btn btn-light">
          Cancel
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  generations: state.generation.allList,
  isLoading: state.generation.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllGenerations: generationActions.getAllGenerations
    },
    dispatch
  );

const RankFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(RankForm);

export default reduxForm({
  form: 'rankForm' // a unique identifier for this form
})(RankFormWithRedux);
