const app = {
  NAVIGATE_REQUEST: 'NAVIGATE_REQUEST',
  COUNTRY_LIST_REQUEST: 'COUNTRY_LIST_REQUEST',
  COUNTRY_LIST_SUCCESS: 'COUNTRY_LIST_SUCCESS',
  STATE_LIST_REQUEST: 'STATE_LIST_REQUEST',
  STATE_LIST_SUCCESS: 'STATE_LIST_SUCCESS',
  CITY_LIST_REQUEST: 'CITY_LIST_REQUEST',
  CITY_LIST_SUCCESS: 'CITY_LIST_SUCCESS',
  PREFETCH_CATEGORY_LIST_REQUEST: 'PREFETCH_CATEGORY_LIST_REQUEST',
  PREFETCH_CATEGORY_LIST_SUCCESS: 'PREFETCH_CATEGORY_LIST_SUCCESS',
  PREFETCH_SHIPPING_LIST_REQUEST: 'PREFETCH_SHIPPING_LIST_REQUEST',
  PREFETCH_SHIPPING_LIST_SUCCESS: 'PREFETCH_SHIPPING_LIST_SUCCESS',
  PREFETCH_TAX_LIST_REQUEST: 'PREFETCH_TAX_LIST_REQUEST',
  PREFETCH_TAX_LIST_SUCCESS: 'PREFETCH_TAX_LIST_SUCCESS',
  PREFETCH_IMAGE_STYLE_REQUEST: 'PREFETCH_IMAGE_STYLE_REQUEST',
  PREFETCH_IMAGE_STYLE_SUCCESS: 'PREFETCH_IMAGE_STYLE_SUCCESS',
  PREFETCH_SETTING_REQUEST: 'PREFETCH_SETTING_REQUEST',
  PREFETCH_SETTING_SUCCESS: 'PREFETCH_SETTING_SUCCESS',
  PREFETCH_SETTING_ERROR: 'PREFETCH_SETTING_ERROR',
  PREFETCH_STORE_ADDRESS_REQUEST: 'PREFETCH_STORE_ADDRESS_REQUEST',
  PREFETCH_STORE_ADDRESS_SUCCESS: 'PREFETCH_STORE_ADDRESS_SUCCESS',
  PREFETCH_STORE_COUNTRIES_REQUEST: 'PREFETCH_STORE_COUNTRIES_REQUEST',
  PREFETCH_STORE_COUNTRIES_SUCCESS: 'PREFETCH_STORE_COUNTRIES_SUCCESS',
  PREFETCH_COMPANY_ADDRESS_REQUEST: 'PREFETCH_COMPANY_ADDRESS_REQUEST',
  PREFETCH_COMPANY_ADDRESS_SUCCESS: 'PREFETCH_COMPANY_ADDRESS_SUCCESS',
  PREFETCH_COUNTRY_WISE_CURRENCY_REQUEST:
    'PREFETCH_COUNTRY_WISE_CURRENCY_REQUEST',
  PREFETCH_COUNTRY_WISE_CURRENCY_SUCCESS:
    'PREFETCH_COUNTRY_WISE_CURRENCY_SUCCESS',
  SET_SHOP_COUNTRY: 'SET_SHOP_COUNTRY',
  SET_SHOP_STATE: 'SET_SHOP_STATE',
  navigate: path => ({ type: app.NAVIGATE_REQUEST, path }),
  getCountry: data => ({ type: app.COUNTRY_LIST_REQUEST, data }),
  getStateByCountry: data => ({ type: app.STATE_LIST_REQUEST, data }),
  getCityByState: data => ({ type: app.CITY_LIST_REQUEST, data }),
  getPrefetchCategory: () => ({ type: app.PREFETCH_CATEGORY_LIST_REQUEST }),
  getPrefetchShipping: () => ({ type: app.PREFETCH_SHIPPING_LIST_REQUEST }),
  getPrefetchTax: () => ({ type: app.PREFETCH_TAX_LIST_REQUEST }),
  getPrefetchSetting: data => ({ type: app.PREFETCH_SETTING_REQUEST, data }),
  getPrefetchImageStyle: () => ({ type: app.PREFETCH_IMAGE_STYLE_REQUEST }),
  getPrefetchStoreCountries: data => ({
    type: app.PREFETCH_STORE_COUNTRIES_REQUEST,
    data
  }),
  getPrefetchStoreAddress: country => ({
    type: app.PREFETCH_STORE_ADDRESS_REQUEST,
    country
  }),
  getPrefetchCompanyAddress: () => ({
    type: app.PREFETCH_COMPANY_ADDRESS_REQUEST
  }),
  setShopCountry: data => ({ type: app.SET_SHOP_COUNTRY, data }),
  setShopState: data => ({ type: app.SET_SHOP_STATE, data }),
  getCountryWiseCurrency: () => ({
    type: app.PREFETCH_COUNTRY_WISE_CURRENCY_REQUEST
  })
};

export default app;
