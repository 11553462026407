const cart = {
  CART_REQUEST: 'CART_REQUEST',
  CART_SUCCESS: 'CART_SUCCESS',
  CART_ERROR: 'CART_ERROR',
  CART_DETAILS_REQUEST: 'CART_DETAILS_REQUEST',
  CART_DETAILS_SUCCESS: 'CART_DETAILS_SUCCESS',
  CART_DETAILS_ERROR: 'CART_DETAILS_ERROR',
  CART_CALCULATION_REQUEST: 'CART_CALCULATION_REQUEST',
  CART_CALCULATION_SUCCESS: 'CART_CALCULATION_SUCCESS',
  CART_CALCULATION_ERROR: 'CART_CALCULATION_ERROR',
  ADD_TO_CART_REQUEST: 'ADD_TO_CART_REQUEST',
  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  ADD_TO_CART_ERROR: 'ADD_TO_CART_ERROR',
  UPDATE_CART_REQUEST: 'UPDATE_CART_REQUEST',
  UPDATE_CART_SUCCESS: 'UPDATE_CART_SUCCESS',
  UPDATE_CART_ERROR: 'UPDATE_CART_ERROR',
  DELETE_CART_REQUEST: 'DELETE_CART_REQUEST',
  DELETE_CART_SUCCESS: 'DELETE_CART_SUCCESS',
  DELETE_CART_ERROR: 'DELETE_CART_ERROR',
  REVISE_CART_REQUEST: 'REVISE_CART_REQUEST',
  REVISE_CART_SUCCESS: 'REVISE_CART_SUCCESS',
  REVISE_CART_ERROR: 'REVISE_CART_ERROR',
  getCart: data => ({ type: cart.CART_REQUEST, data }),
  getCartDetails: data => ({ type: cart.CART_DETAILS_REQUEST, data }),
  getCartTotalDetails: data => ({ type: cart.CART_CALCULATION_REQUEST, data }),
  addToCart: data => ({ type: cart.ADD_TO_CART_REQUEST, data }),
  updateCart: data => ({ type: cart.UPDATE_CART_REQUEST, data }),
  deleteCart: id => ({ type: cart.DELETE_CART_REQUEST, id }),
  reviseCart: (data, callback) => ({
    type: cart.REVISE_CART_REQUEST,
    data,
    callback
  })
};

export default cart;
