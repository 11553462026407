/* global appConfig */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, find } from 'lodash';
import PageLoader from '../../components/PageLoader';
import { order as orderActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import InvoiceViewComponent from './InvoiceViewComponent';

function ShippingSteps({ data }) {
  const steps = ['Order Placed'];
  let active = 1;
  let shippingDetails = {};
  let count = steps.length;

  const checkSteps = () => {
    // Check Payment
    switch (data.payment_gateway_status) {
      case 'SUCCESS':
        steps.push('Payment Success');
        break;
      case 'SUCCESS-CANCELLED':
      case 'SUCCESS-REFUND':
      case 'SUCCESS-PARTIAL-CANCELLED':
      case 'SUCCESS-PARTIAL-REFUND':
        steps.push('Payment Success');
        break;
      case 'PROCESSING':
        steps.push('Payment Processing');
        break;
      case 'FAILED':
        steps.push('Payment Failed');
        break;
      case 'CANCELLED':
        steps.push('Payment Cancelled');
        break;
      default:
        steps.push('Payment Pending');
        active = 0;
    }

    // Check Cancel/Refund
    if (data.payment_gateway_status === 'SUCCESS-CANCELLED') {
      steps.push('Cancelled');
      active = 2;
    } else if (data.payment_gateway_status === 'SUCCESS-REFUND') {
      if (shippingDetails && shippingDetails.shipping) {
        steps.push('Shipped');
        active += 1;
      }
      if (shippingDetails && shippingDetails.delivered) {
        steps.push('Delivered');
        active += 1;
      }
      steps.push('Refunded');
      active += 1;
    } else if (data.fulfillment_status === 'SHIPPED') {
      steps.push('Shipped');
      steps.push('Delivered');
      active = 2;
    } else if (data.fulfillment_status === 'DELIVERED') {
      steps.push('Shipped');
      steps.push('Delivered');
      active = 3;
    } else {
      steps.push('Shipped');
      steps.push('Delivered');
    }
  };

  if (data && data.user_info) {
    shippingDetails = JSON.parse(data.shipping_delivery_details);
    checkSteps();
    count = steps.length;
  }

  return (
    <div className="row justify-content-center invoice-shipping-status">
      <div className="col-lg-8 col-md-7 col-sm-12 pl-5 pr-5">
        <div className="horizontal-steps mt-4 mb-4 pb-5">
          <div className="horizontal-steps-content">
            {steps.map((item, index) => (
              <div
                key={item}
                className={active === index ? 'step-item current' : 'step-item'}
              >
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div
            className="process-line"
            style={{
              width: `${Math.ceil((100 / (count - 1 || count)) * active)}%`
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-5 col-sm-12 d-flex flex-column justify-content-center align-items-lg-end align-items-md-end align-items-sm-start">
        {shippingDetails && shippingDetails.shipping ? (
          <div>
            <a
              href={shippingDetails.shipping.tracking_link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mb-3"
            >
              Track Order
            </a>
            <p className="text-muted font-11 pr-4">
              <strong>Shipping Date: </strong>{' '}
              {shippingDetails.shipping.shipped_at}
              <br />
              <strong>Shipping Carrier:</strong>{' '}
              {shippingDetails.shipping.shipping_carrier}
              <br />
              <strong>Carrier Ref:</strong>{' '}
              {shippingDetails.shipping.shipping_carrier_ref}
            </p>
          </div>
        ) : null}
        {shippingDetails && shippingDetails.delivered ? (
          <div>
            <p className="text-muted font-11 pr-4">
              <strong>Delivery Date: </strong>{' '}
              {shippingDetails.delivered.delivery_date}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

class InvoiceView extends React.Component {
  componentDidMount() {
    const { match, getOrderById } = this.props;
    if (match.params && match.params.id) {
      getOrderById(match.params.id);
    }
  }

  render() {
    const { order, match, isAdmin } = this.props;
    const orderList = get(order, 'data', []);
    let data = null;
    if (match.params && match.params.id) {
      const dataObj = find(orderList, { id: parseInt(match.params.id, 10) });
      if (dataObj && dataObj.user_info) {
        data = dataObj;
      }
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.invoiceView.breadcrumb} />
              <h4 className="page-title">Invoice</h4>
            </div>
          </div>
        </div>
        <ShippingSteps data={data} />
        <div className="card">
          {data ? (
            <InvoiceViewComponent data={data} isAdmin={isAdmin} />
          ) : (
            <PageLoader />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  order: state.order.allList,
  isAdmin: state.auth.isAdmin
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderById: orderActions.getOrderById
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceView);
