import { customerTree as treeActionTypes } from '../actions';
import initialState from '../constants/initialState';

function customerTree(state = initialState.customerTree, action) {
  switch (action.type) {
    case treeActionTypes.CUSTOMER_TREE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case treeActionTypes.CUSTOMER_TREE_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          list: action.data
        };
      }
      return state;
    default:
      return state;
  }
}

export default customerTree;
