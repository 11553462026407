const category = {
  CATEGORY_ALL_LIST_REQUEST: 'CATEGORY_ALL_LIST_REQUEST',
  CATEGORY_ALL_LIST_SUCCESS: 'CATEGORY_ALL_LIST_SUCCESS',
  CATEGORY_ALL_LIST_ERROR: 'CATEGORY_ALL_LIST_ERROR',
  CATEGORY_CREATE_REQUEST: 'CATEGORY_CREATE_REQUEST',
  CATEGORY_CREATE_SUCCESS: 'CATEGORY_CREATE_SUCCESS',
  CATEGORY_CREATE_ERROR: 'CATEGORY_CREATE_ERROR',
  CATEGORY_UPDATE_REQUEST: 'CATEGORY_UPDATE_REQUEST',
  CATEGORY_UPDATE_SUCCESS: 'CATEGORY_UPDATE_SUCCESS',
  CATEGORY_UPDATE_ERROR: 'CATEGORY_UPDATE_ERROR',
  CATEGORY_REQUEST: 'CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
  CATEGORY_THIRTY: 'CATEGORY_THIRTY',
  ADD_CATEGORY_IMAGE_FILE: 'ADD_CATEGORY_IMAGE_FILE',
  DELETE_CATEGORY_IMAGE_FILE: 'DELETE_CATEGORY_IMAGE_FILE',
  getAllCategories: data => ({
    type: category.CATEGORY_ALL_LIST_REQUEST,
    data
  }),
  createCategory: data => ({ type: category.CATEGORY_CREATE_REQUEST, data }),
  updateCategory: data => ({ type: category.CATEGORY_UPDATE_REQUEST, data }),
  getCategoryById: id => ({ type: category.CATEGORY_REQUEST, id }),
  categoryImageFile: data => ({ type: category.ADD_CATEGORY_IMAGE_FILE, data }),
  deleteCategoryImageFile: () => ({ type: category.DELETE_CATEGORY_IMAGE_FILE })
};

export default category;
