import React, { useState, useEffect, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { arrayRemove, arrayPush, change } from 'redux-form';
import { connect } from 'react-redux';
import { findIndex, find } from 'lodash';
import ProductModalForm from './ProductModalForm';
import CountryContext from './countryContext';
import ProductModalFormDisabled from './ProductModalFormDisabled';
import ProductStateModal from './ProductStateModal';
import useCountryPrice from './hooks/useCountryPrice';
import btnLoader from '../../assets/images/btn-loader.gif';

const ProductCountryModal = ({
  countryName,
  showModal,
  statesInModal,
  handleCloseModal,
  inProgress,
  name,
  fieldValue,
  uncoveredStates,
  taxes,
  countyIso2,
  dispatch
}) => {
  const [allStates, setAllStates] = useState(true);
  const [searchMode, setSearchMode] = useState(statesInModal);
  const { countryCurrencies } = useContext(CountryContext);
  const { handleChangePrice } = useCountryPrice(
    countyIso2,
    name,
    fieldValue,
    dispatch
  );

  // uncheck ALL STATES if any country is select
  useEffect(() => {
    if (
      fieldValue.states &&
      fieldValue.states.length !== statesInModal.length
    ) {
      setAllStates(false);
    } else {
      setAllStates(true);
    }
  }, [fieldValue.states]);

  // checked single State
  const handleOnSelect = e => {
    const index = findIndex(fieldValue.states, {
      state: parseInt(e.target.value, 10)
    });
    if (index > -1) {
      dispatch(arrayRemove('productForm', `${name}.states`, index));
      dispatch(
        arrayPush(
          'productForm',
          'uncovered_states',
          parseInt(e.target.value, 10)
        )
      );
    } else {
      dispatch(
        arrayPush('productForm', `${name}.states`, {
          state: parseInt(e.target.value, 10),
          regular_price: fieldValue.regular_price,
          sale_price: fieldValue.sale_price,
          member_price: fieldValue.member_price,
          member_price_vat: fieldValue.member_price_vat,
          customer_price_vat: fieldValue.customer_price_vat,
          bpoint: fieldValue.bpoint,
          cpoint: fieldValue.cpoint,
          taxable: fieldValue.taxable,
          tax_id: fieldValue.tax_id
        })
      );
      const dIndex = uncoveredStates.indexOf(parseInt(e.target.value, 10));
      if (dIndex > -1) {
        dispatch(arrayRemove('productForm', 'uncovered_states', dIndex));
      }
    }
  };

  // checked/unchecked  all states
  const handleOnSelectAll = e => {
    setAllStates(prev => !prev);
    if (e.target.checked) {
      const newUncoveredStates = Array.from(uncoveredStates);
      const newStateList = statesInModal.map(item => {
        // Remove from uncovered states
        const UIndex = uncoveredStates.indexOf(item.id);
        if (UIndex > -1) {
          newUncoveredStates.splice(UIndex, 1);
        }

        const oldState = find(fieldValue.state, { state: item.id });
        if (oldState) {
          return oldState;
        }
        return {
          state: item.id,
          regular_price: fieldValue.regular_price,
          sale_price: fieldValue.sale_price,
          member_price: fieldValue.member_price,
          member_price_vat: fieldValue.member_price_vat,
          customer_price_vat: fieldValue.customer_price_vat,
          bpoint: fieldValue.bpoint,
          cpoint: fieldValue.cpoint,
          taxable: fieldValue.taxable,
          tax_id: fieldValue.tax_id
        };
      });

      dispatch(change('productForm', `${name}.states`, newStateList));
      dispatch(change('productForm', `uncovered_states`, newUncoveredStates));
    } else {
      // Add to uncovered states
      const newUncoveredStates = Array.from(uncoveredStates);
      statesInModal.forEach(item => {
        if (!uncoveredStates.includes(item.id)) {
          newUncoveredStates.push(item.id);
        }
      });

      dispatch(change('productForm', `${name}.states`, []));
      dispatch(change('productForm', `uncovered_states`, newUncoveredStates));
    }
  };

  const handleSubmit = () => {
    handleCloseModal();
  };

  const handleSameAsProduct = e => {
    dispatch(change('productForm', `${name}.inherit`, e.target.checked));
    // uncheck the currency value checkbox
    dispatch(change('productForm', `${name}.check_currency_value`, false));
  };

  const handleCheckCurrenyValue = e => {
    dispatch(
      change('productForm', `${name}.check_currency_value`, e.target.checked)
    );
  };

  const taxOptions = () => {
    const options = [];
    taxes.map(tax =>
      options.push({
        label: `${tax.tax_rate_name} - ${tax.tax_rate}%`,
        value: tax.id
      })
    );
    return options;
  };

  const currency = countryCurrencies[fieldValue.country]
    ? countryCurrencies[fieldValue.country].currency_symbol
    : '';

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="modal-70 vh-90 my-0 mx-auto p-0"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="d-inline m-0">{`Country : ${countryName}`}</h4>
        </Modal.Header>
        <Modal.Body className="my-auto">
          <div className="row">
            <div
              className="col-md-7 col-xs-12"
              style={{ paddingRight: '50px' }}
            >
              <div
                className="border-bottom d-flex align-items-center cursor-pointer"
                aria-hidden="true"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="same_as_prod"
                    checked={fieldValue.inherit}
                    onChange={handleSameAsProduct}
                    className="custom-control-input"
                  />
                  <label
                    htmlFor="same_as_prod"
                    className="custom-control-label"
                  />
                </div>
                <label
                  htmlFor="same_as_prod"
                  className="h4 h4-state ml-1 cursor-pointer"
                >
                  Same as General Pricing
                </label>
              </div>
              <div
                className="border-bottom d-flex align-items-center cursor-pointer"
                aria-hidden="true"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="check_currency_value"
                    checked={fieldValue.check_currency_value}
                    onChange={handleCheckCurrenyValue}
                    className="custom-control-input"
                    disabled={fieldValue.inherit}
                  />
                  <label
                    htmlFor="check_currency_value"
                    className="custom-control-label"
                  />
                </div>
                <label
                  htmlFor="check_currency_value"
                  className="h4 h4-state ml-1 cursor-pointer"
                >
                  Calculate Pricing with Currency Value
                </label>
              </div>
              {fieldValue.inherit ? (
                <ProductModalFormDisabled
                  taxOptions={taxOptions()}
                  currency={currency}
                />
              ) : (
                <ProductModalForm
                  name={name}
                  handleChangePrice={handleChangePrice}
                  fieldValue={fieldValue}
                  taxOptions={taxOptions()}
                  currency={currency}
                  isCheckValue={fieldValue.check_currency_value}
                />
              )}
            </div>
            <div className="col-md-5 col-xs-12">
              <div
                className="pl-2 d-flex align-items-center cursor-pointer border-bottom"
                aria-hidden="true"
              >
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    id="all_state"
                    checked={allStates}
                    onChange={handleOnSelectAll}
                    className="custom-control-input"
                  />
                  <label htmlFor="all_state" className="custom-control-label" />
                </div>
                <label
                  htmlFor="all_state"
                  className="h4 h4-state ml-1 cursor-pointer"
                >
                  All States
                </label>
              </div>
              <div className="overflow-auto category-modal-states px-2">
                <div style={{ height: '150px' }}>
                  {searchMode.map(state => {
                    const isPresent = findIndex(fieldValue.states, {
                      state: state.id
                    });
                    return (
                      <div
                        key={`state_item_${state.id}`}
                        className="d-flex align-items-center mt-1"
                      >
                        <div
                          className="custom-control custom-checkbox cursor-pointer"
                          style={{ pointerEvents: 'auto' }}
                        >
                          <input
                            type="checkbox"
                            checked={isPresent > -1}
                            id={`state_item_${state.id}`}
                            onChange={handleOnSelect}
                            value={state.id}
                            className="custom-control-input cursor-pointer"
                          />
                          <label
                            htmlFor={`state_item_${state.id}`}
                            className="custom-control-label"
                          />
                        </div>
                        <label
                          htmlFor={`state_item_${state.id}`}
                          className="h5 h5-state ml-1 cursor-pointer"
                        >
                          {state.name}
                        </label>
                        {isPresent > -1 ? (
                          <ProductStateModal
                            fieldValue={fieldValue.states[isPresent]}
                            countryValue={fieldValue}
                            name={`${name}.states[${isPresent}]`}
                            stateName={state.name}
                            countryName={countryName}
                            currency={currency}
                          />
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="text-right">
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              type="submit"
              disabled={inProgress}
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({
  taxes: state.product.taxes
});

export default connect(
  mapStateToProps,
  null
)(ProductCountryModal);
