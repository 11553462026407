import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import { fsb as fsbActionTypes } from '../../actions';
import routePath from '../../constants/routePath';
import SearchInput from './SearchInput';

class FsbList extends React.Component {
  searchInput = '';

  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getFsbPerformanceList } = this.props;
    getFsbPerformanceList();
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (
    type,
    { page, sizePerPage: perPage, sortField, sortOrder }
  ) => {
    const { getFsbPerformanceList } = this.props;
    if (type === 'pagination' || type === 'sort') {
      const obj = { page, per_page: perPage };
      if (this.searchInput) {
        obj.user = this.searchInput;
      }
      if (sortField) {
        obj.sort_by = sortField;
        obj.sort_order = sortOrder;
      }
      getFsbPerformanceList(obj);
    }
    this.setState({ page });
  };

  handleSearch = input => {
    const { getFsbPerformanceList } = this.props;
    this.searchInput = input;
    this.setState({ page: 1 });
    if (input) {
      getFsbPerformanceList({ user: input });
    } else {
      getFsbPerformanceList();
    }
  };

  render() {
    const { performance, isLoading, filterValues } = this.props;
    const { page, selected } = this.state;

    const columns = [
      {
        dataField: 'uuid',
        text: 'ID',
        headerAttrs: { width: '15%' },
        formatter: (col, row) => (
          <>
            {row.country && (
              <span
                className={`flag-icon flag-icon-${row.country.toLowerCase()} mr-1`}
              />
            )}
            <span>{col}</span>
          </>
        )
      },
      {
        dataField: 'name',
        text: 'Name',
        headerAttrs: { width: '25%' },
        sort: true,
        formatter: (col, row) => `${row.first_name} ${row.last_name}`
      },
      {
        dataField: 'phone_number',
        text: 'Phone'
      },
      // {
      //   dataField: 'duration',
      //   text: 'Duration',
      //   headerClasses: 'text-center',
      //   classes: 'text-center'
      // },
      {
        dataField: 'start_date',
        text: 'Start Date',
        sort: true,
        headerAttrs: { width: '10%' }
        // formatter: col => moment(col).format('DD/MM/YYYY')
      },
      {
        dataField: 'end_date',
        text: 'End Date',
        sort: true,
        headerAttrs: { width: '10%' }
        // formatter: col => moment(col).format('DD/MM/YYYY')
      },
      {
        dataField: 'active_status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '5%' },
        formatter: (col, row) => {
          if (col) {
            return <span className="badge badge-pill badge-success">Yes</span>;
          }
          return <span className="badge badge-pill badge-danger">No</span>;
        }
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <Link to={`/fsb-performance/${row.user_id}/view`}>
            <i className="action-icon mdi mdi-eye" />
          </Link>
        )
      }
    ];

    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.fsbPerformanceList.breadcrumb} />
              <h4 className="page-title">
                {routePath.fsbPerformanceList.title}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-sm-12 col-lg-7">
                    <div className="form-row">
                      <div className="form-group col-sm-8">
                        <SearchInput onSubmit={this.handleSearch} />
                      </div>
                    </div>
                  </div>
                </div>
                <AdvanceTable
                  data={performance.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={performance.total}
                  sizePerPage={performance.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  performance: state.fsb.performance,
  isLoading: state.fsb.isLoadingPerformance,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getFsbPerformanceList: fsbActionTypes.getFsbPerformanceList },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FsbList);
