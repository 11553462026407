import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import CartItem from './CartItem';
import { cart as cartActions } from '../../actions';
import InnerLoader, { OverlayLoader } from '../../components/InnerLoader';
import ShippingOptions from './ShippingOptions';
import ApplyCoupon from './ApplyCoupon';

function OrderSummary({
  cartDetails,
  getCartTotalDetails,
  shippingAddress,
  cartCalculation,
  countryCurrencies,
  calculationProgress,
  displayOnly,
  shippingMode,
  storePickup,
  couponCode
}) {
  useEffect(() => {
    if (!displayOnly) {
      getCartTotalDetails({
        cart_id: cartDetails.id,
        address_id: shippingAddress,
        pickup_from_store: storePickup,
        shipping_mode: shippingMode,
        coupon_code: couponCode
      });
    }
  }, [shippingMode, shippingAddress, storePickup, couponCode]);

  const currency = countryCurrencies[cartDetails.country]
    ? countryCurrencies[cartDetails.country].currency_symbol
    : '';

  return (
    <>
      <h4 className="header-title mb-3">Order Summary</h4>
      {cartDetails.items.map(product => (
        <CartItem
          key={`cart_item_${product.id}`}
          data={product}
          currency={currency}
        />
      ))}
      {calculationProgress && isEmpty(cartCalculation) ? (
        <InnerLoader type="table" />
      ) : (
        <>
          <div className="table-responsive position-relative">
            {calculationProgress ? <OverlayLoader type="spinner" /> : null}
            <table className="table table-summary mb-0">
              <tbody>
                {!isEmpty(cartCalculation) ? (
                  <>
                    <tr>
                      <td>Sub Total:</td>
                      <td className="text-right">
                        {`${currency}${parseFloat(
                          cartCalculation.subtotal
                        ).toFixed(2)}`}
                      </td>
                    </tr>

                    <ApplyCoupon
                      cartId={cartDetails.id}
                      couponDetails={cartCalculation.coupon_details}
                    />

                    <ShippingOptions
                      cartId={cartDetails.id}
                      currency={currency}
                    />
                    {cartCalculation.tax_details &&
                      cartCalculation.tax_details.map((taxItem, taxItemKey) => (
                        <tr key={`cal)tax_${taxItemKey}`}>
                          <td>{`${taxItem.name} (${taxItem.tax_rate}%):`}</td>
                          <td className="text-right">
                            {`${currency}${parseFloat(taxItem.amount).toFixed(
                              2
                            )}`}
                          </td>
                        </tr>
                      ))}
                    <tr>
                      <th className="pl-0">Gross Total: </th>
                      <th className="text-right pr-0">
                        {`${currency}${cartCalculation.total_amount}`}
                      </th>
                    </tr>
                    {cartCalculation.coupon_details &&
                    cartCalculation.coupon_details.coupon_id ? (
                      <tr className="font-11 font-weight-bold">
                        <td className="text-danger">Discount</td>
                        <td className="text-right text-danger">
                          {`${currency}${cartCalculation.coupon_details.discount_amount}`}
                        </td>
                      </tr>
                    ) : null}
                    {Number(cartCalculation.total_amount) >
                    Number(cartCalculation.total_payable_amount) ? (
                      <tr>
                        <th className="pl-0">Net Payable Amount: </th>
                        <th className="text-right pr-0">
                          {`${currency}${cartCalculation.total_payable_amount}`}
                        </th>
                      </tr>
                    ) : null}
                  </>
                ) : null}
                <tr className="font-13">
                  <td>Total B-Point:</td>
                  <td className="text-right">{cartCalculation.total_bp}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  cartCalculation: state.cart.cartCalculation,
  calculationProgress: state.cart.calculationProgress,
  countryCurrencies: state.app.countryCurrencies,
  shippingAddress: selector(state, 'shipping_address_id'),
  storePickup: selector(state, 'pickup_from_store'),
  couponCode: selector(state, 'coupon_code'),
  shippingMode: selector(state, 'shipping_mode')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartDetails: cartActions.getCartDetails,
      getCartTotalDetails: cartActions.getCartTotalDetails
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderSummary);
