import React, { Fragment } from 'react';
import { Modal } from 'react-bootstrap';
import { fsb } from '../../actions';

function NodeViewModal(props) {
  const { handleModal, details } = props;
  return (
    <Modal show onHide={handleModal} size="sm">
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          {details.uuid}
          {' | '}
          {details.first_name} {details.last_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-1">
        {details && (
          <div className="font-12">
            <table className="table table-xs mb-2">
              <tbody>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Joining Date
                  </td>
                  <td>{details.joiningDate}</td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Email
                  </td>
                  <td className="text-break">{details.email}</td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Phone
                  </td>
                  <td>{details.phone_number}</td>
                </tr>
                <tr>
                  <td colSpan="2" />
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Month &amp; Year
                  </td>
                  <td>{`${details.month}, ${details.year}`}</td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Present Rank
                  </td>
                  <td>{details.present_rank}</td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten border-bottom">
                    Next Rank
                  </td>
                  <td className="border-bottom">{details.next_rank}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-xs mb-0">
              <tbody>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Total BP – Rank Qualification
                  </td>
                  <td>
                    {details.earned_points ? details.earned_points : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Own Purchase BP
                  </td>
                  <td>
                    {details.own_purchase ? details.own_purchase : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Personal Associate Sign-Up BP
                  </td>
                  <td>
                    {details.associate_signup
                      ? details.associate_signup
                      : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Customer Purchase BP
                  </td>
                  <td>
                    {details.customer_purchase
                      ? details.customer_purchase
                      : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Referred Customer BP
                  </td>
                  <td>
                    {details.ref_customer_purchase
                      ? details.ref_customer_purchase
                      : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" />
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Total BP – Downline
                  </td>
                  <td>
                    {parseFloat(
                      (details.downline_associate_own_purchase || 0) +
                        (details.downline_associate_signup || 0) +
                        (details.downline_customer_purchase || 0)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Downline Associate Own Purchase BP
                  </td>
                  <td>
                    {details.downline_associate_own_purchase
                      ? details.downline_associate_own_purchase
                      : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Downline Associate Sign-Up BP
                  </td>
                  <td>
                    {details.downline_associate_signup
                      ? details.downline_associate_signup
                      : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td className="font-weight-semibold bg-light-lighten">
                    Downline Customer Purchase BP
                  </td>
                  <td>
                    {details.downline_customer_purchase
                      ? details.downline_customer_purchase
                      : '0.00'}
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" />
                </tr>
                <tr>
                  <td className="font-weight-bold bg-light-lighten" colSpan="2">
                    FSB Plan
                  </td>
                </tr>
                {Array.isArray(details.fsb) ? (
                  details.fsb.map(element => (
                    <Fragment>
                      <tr>
                        <td className="font-weight-semibold bg-light-lighten">
                          Start Date
                        </td>
                        <td>{element.start_date}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-semibold bg-light-lighten">
                          End Date
                        </td>
                        <td>{element.end_date}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-semibold bg-light-lighten">
                          Achieved
                        </td>
                        <td>{element.achieve_status === 0 ? 'No' : 'Yes'}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-semibold bg-light-lighten">
                          Active
                        </td>
                        <td>{element.active_status === 0 ? 'No' : 'Yes'}</td>
                      </tr>
                      {element.fsb_plan_logs &&
                        element.fsb_plan_logs.map((phases, index) => (
                          <tr>
                            <td className="font-weight-semibold bg-light-lighten">
                              Phase {index + 1}
                            </td>
                            <td>{phases.achieved_bp}</td>
                          </tr>
                        ))}
                    </Fragment>
                  ))
                ) : (
                  <tr>
                    <td
                      className="font-weight-semibold text-danger"
                      colSpan="2"
                    >
                      {details.fsb}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default NodeViewModal;
