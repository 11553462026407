import React from 'react';
// eslint-disable-next-line import/no-duplicates
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import moment from 'moment';
import RegistrationForm from './RegistrationForm';
import { auth as authActions } from '../../actions';
import routePath from '../../constants/routePath';
import { ajax } from '../../utills/ajax';
import LayoutRegister from '../../components/LayoutRegister';

class Register extends React.Component {
  state = {
    step: 1,
    stepProcessing: false,
    refUUId: ''
  };

  componentWillMount() {
    const { getRefUserId, refUserId, refUserChecked } = this.props;
    const refUUId = window.localStorage.getItem('refUUId') || '';
    // console.log('val ', val);
    if ((!refUserId || refUserId === '') && refUUId) {
      getRefUserId({ refUserId: refUUId });
    }
    if (refUserChecked && refUserId && refUserId !== '') {
      this.setState({ refUUId: refUserId });
      console.log('refId ', refUserId);
    }
    // return val;
  }

  // shouldComponentUpdate(nextProps) {
  //   const { refUserId } = this.props;
  //   console.log('newProps ', nextProps, refUserId);
  //   const diff = refUserId !== nextProps.refUserId;
  //   console.log(diff);
  //   return diff;
  // }

  handleSubmit = value => {
    const { doRegister } = this.props;
    const fieldValue = { ...value };
    if (fieldValue.mobile_number) {
      fieldValue.mobile_number = value.mobile_number.replace(/\D/g, '');
    }
    if (fieldValue.dob) {
      fieldValue.dob = moment(fieldValue.dob, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
    }
    if (
      fieldValue.city &&
      fieldValue.city === 'others' &&
      fieldValue.otherCity
    ) {
      fieldValue.city = fieldValue.otherCity;
    }
    doRegister(fieldValue);
  };

  moveBack = () => {
    this.setState(prevState => ({ step: prevState.step - 1 }));
  };

  moveNext = async () => {
    const { formValues } = this.props;
    const { step } = this.state;
    if (step === 1 && formValues && formValues.referrer_id) {
      // Check if the sponsor ID is valid
      try {
        this.setState({ stepProcessing: true });
        await ajax({
          url: `${process.env.REACT_APP_API_URL}/check-sponsor`,
          method: 'POST',
          data: { referrer_id: formValues.referrer_id, with_customer: false },
          hideMessage: true
        });
        this.setState(prevState => ({
          step: prevState.step + 1,
          stepProcessing: false
        }));
      } catch (error) {
        this.setState({ stepProcessing: false });
      }
    }
  };

  render() {
    console.log('abcd', this.props);
    const { step, stepProcessing, refUUId } = this.state;
    const { registrationProgress, refUserId } = this.props;
    // console.log('ref ', refUserId);
    const val = refUserId && refUserId !== '' ? refUserId : refUUId;
    return (
      <LayoutRegister step={step}>
        <div className="card-body">
          <RegistrationForm
            submitForm={this.handleSubmit}
            inProgress={registrationProgress}
            step={step}
            moveNext={this.moveNext}
            moveBack={this.moveBack}
            stepProcessing={stepProcessing}
            initialValues={{
              user_type: 'individual',
              same_as_billing: true,
              referrer_id: val
            }}
          />
        </div>
        <div className="card-footer">
          <p className="text-muted text-center mt-1">
            Already have an account?
            <Link to={routePath.login.path} className="ml-1">
              <b>Sign In</b>
            </Link>
          </p>
        </div>
      </LayoutRegister>
    );
  }
}

const mapStateToProps = state => ({
  registrationProgress: state.auth.registrationProgress,
  refUserId: state.auth.refUserId,
  refUserChecked: state.auth.refUserChecked,
  formValues: getFormValues('registrationForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doRegister: authActions.doRegister,
      getRefUserId: authActions.getRefUserId
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Register));
