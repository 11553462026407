import React from 'react';
import { Modal } from 'react-bootstrap';
import { PropTypes } from 'prop-types';

const Row = ({ title, children }) => (
  <tr>
    <td className="font-weight-semibold bg-light-lighten">{title}</td>
    <td>{children}</td>
  </tr>
);

Row.defaultProps = {
  title: '',
  children: null
};

Row.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

function NodeViewModal(props) {
  const { handleModal, details } = props;
  return (
    <Modal show onHide={handleModal} size="sm">
      <Modal.Header closeButton>
        <Modal.Title className="h5">
          {details.uuid}
          {' | '}
          {details.first_name} {details.last_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-1">
        {details && (
          <div className="font-12">
            <table className="table table-xs mb-2">
              <tbody>
                <Row title="Joining Date">{details.joining_date}</Row>
                <Row title="Email">{details.email}</Row>
                <Row title="Phone">{details.phone_number}</Row>
                <tr>
                  <td colSpan="2" />
                </tr>
                <Row title="Month &amp; Year">
                  {details.month
                    ? `${details.month}, ${details.year}`
                    : 'Lifetime'}
                </Row>
                <Row title="Connect Loyalty Points CLP">
                  {details.cust_own_purchase}
                </Row>
                <Row title="Connect Referral Points CRP">
                  {details.customer_joining}
                </Row>
                <Row title="Downline CLP">{details.downline_cust_purchase}</Row>
                <tr>
                  <td colSpan="2" />
                </tr>
                <Row title="Total CPOINT">{details.total_cpoints}</Row>
                <Row title="Used CPOINT">{details.total_used_cp}</Row>
                <Row title="Remaining CPOINT">{details.remaining_cp}</Row>
                <Row title="Expiry Date">
                  {details.expiry_date_tz ? `${details.month}` : 'N/A'}
                </Row>
              </tbody>
            </table>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}

export default NodeViewModal;
