import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';

import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import {
  app as appActions,
  permission as permissionActions
} from '../../actions';
import routePath from '../../constants/routePath';
import { readableTransform } from '../../utills/custom';

class Role extends React.Component {
  componentDidMount() {
    const { getRoles, getCountry } = this.props;
    getRoles();
    getCountry();
  }

  render() {
    const { countryList, roles, isLoading, filterValues } = this.props;
    const columns = [
      {
        dataField: 'name',
        text: 'Title',
        formatter: (col, row) => readableTransform(col)
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '15%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/role/${row.id}/permissions`}>
              <i className="action-icon dripicons-toggles mr-2" />
            </Link>
            <Link to={`/role/${row.name}/users`}>
              <i className="action-icon dripicons-user-group" />
            </Link>
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.role.breadcrumb} />
              <h4 className="page-title">{routePath.role.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-3">
                    <div className="form-group form-group-sm">
                      <Select
                        name="country"
                        placeholder="Choose Country..."
                        id="country"
                        options={countryList}
                      />
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Link
                      to={routePath.associate.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      Add New
                    </Link>
                  </div>
                </div>

                <AdvanceTable
                  data={roles.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={roles.total}
                  sizePerPage={roles.per_page}
                  sizePerPageList={filterValues}
                  hideSizePerPage={false}
                  page={1}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  roles: state.permission.roles,
  isLoading: state.permission.isLoadingRoles,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getRoles: permissionActions.getRoles
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Role);
