/* eslint-disable camelcase */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  app as appActions,
  storeAddress as storeAddressActions
} from '../../actions';
import { fetchStatesByCountryId } from '../../services/apiService';
import btnLoader from '../../assets/images/btn-loader.gif';
import LinkBack from '../Location/LinkBack';
import StatusSwitch from '../../components/StatusSwitch';

class AddressAdd extends React.Component {
  state = {
    stateList: [],
    address_line1: '',
    address_line2: '',
    city: '',
    company_email: '',
    company_name: '',
    company_phone: '',
    company_vat: '',
    country: '',
    postcode: '',
    state: '',
    store_pickup: 0,
    street_name_number: '',
    lastSet: 'country'
  };

  componentDidMount() {
    const { getCountry } = this.props;
    getCountry();
  }

  storeCountryChange = val => {
    this.setState({
      store_country: val.value
    });
  };

  countryChange = val => {
    if (val.id) {
      fetchStatesByCountryId(val.id).then(data => {
        this.setState({
          stateList: data.data.state,
          country: val.value,
          lastSet: 'country',
          state: '',
          city: ''
        });
      });
    }
  };

  stateChange = val => {
    const { getCityByState } = this.props;

    if (val) {
      getCityByState(val.value);
    }
    this.setState({
      state: val.value,
      lastSet: 'state',
      city: ''
    });
  };

  cityChange = val => {
    this.setState({
      city: val.value
    });
  };

  submitForm = () => {
    const { createStoreAddress } = this.props;
    const {
      store_pickup,
      address_line1,
      address_line2,
      street_name_number,
      country,
      state,
      city,
      postcode,
      company_phone,
      company_name,
      company_vat,
      company_email,
      is_default,
      otherCity,
      store_country
    } = this.state;

    createStoreAddress({
      store_country,
      store_pickup,
      address_line1,
      address_line2,
      street_name_number,
      country,
      state,
      city: city === 0 ? otherCity : city,
      postcode,
      company_phone,
      company_name,
      company_vat,
      company_email,
      is_default
    });
  };

  render() {
    const { countryList, cityList, onHide, createProgress } = this.props;
    const {
      stateList,
      lastSet,
      address_line1,
      address_line2,
      city,
      company_email,
      company_name,
      company_phone,
      company_vat,
      country,
      postcode,
      state,
      store_pickup,
      street_name_number,
      otherCity,
      store_country
    } = this.state;

    const filteredStateList = stateList.map(item => {
      return {
        ...item,
        label: item.state_name,
        value: item.state_id
      };
    });

    const cityFilteredList = lastSet === 'country' ? [] : cityList;

    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <LinkBack
                linkItems={[
                  { link: '/setting/store', title: 'Settings' },
                  {
                    title: 'Store Address'
                  }
                ]}
              />
              <h4 className="page-title">Add Store Address</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-xs-12 col-md-4">
                    <Form.Label>Store Country</Form.Label>
                    <Select
                      placeholder="Select..."
                      name="Store Country"
                      label="Store Country"
                      options={[
                        { label: '*', value: '*', id: '*' },
                        ...countryList
                      ]}
                      isSearchable
                      onChange={this.storeCountryChange}
                      value={[
                        { label: '*', value: '*', id: '*' },
                        ...countryList
                      ].filter(option => option.value === store_country)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-4">
                    <Form.Label>Country Address</Form.Label>
                    <Select
                      placeholder="Select Country..."
                      name="Country"
                      label="Country"
                      options={countryList}
                      isSearchable
                      onChange={this.countryChange}
                      value={countryList.filter(
                        option => option.value === country
                      )}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <Form.Label>State</Form.Label>
                    <Select
                      label="Region"
                      placeholder="Select Region..."
                      name="Region"
                      options={filteredStateList}
                      isSearchable
                      onChange={this.stateChange}
                      value={filteredStateList.filter(
                        option => option.value === Number(state)
                      )}
                    />
                  </div>
                  <div className="col-xs-12 col-md-4">
                    <Form.Label>City</Form.Label>
                    <Select
                      label="City"
                      placeholder="Select City..."
                      name="City"
                      options={cityFilteredList}
                      isSearchable
                      onChange={this.cityChange}
                      value={cityFilteredList.filter(
                        option => option.value === city
                      )}
                    />
                  </div>
                </div>
                <br />
                {city === 0 && (
                  <div className="row">
                    <div className="col-xs-12 col-md-4">
                      <Form.Group>
                        <Form.Label>City name</Form.Label>
                        <Form.Control
                          placeholder="City name"
                          value={otherCity}
                          onChange={e =>
                            this.setState({
                              otherCity: e.target.value
                            })
                          }
                        />
                      </Form.Group>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        placeholder="ABC Store"
                        value={company_name}
                        onChange={e =>
                          this.setState({
                            company_name: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Company VAT</Form.Label>
                      <Form.Control
                        placeholder="123456789"
                        value={company_vat}
                        onChange={e =>
                          this.setState({
                            company_vat: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Company Phone/Mobile</Form.Label>
                      <Form.Control
                        placeholder="Company Phone/Mobile"
                        value={company_phone}
                        onChange={e =>
                          this.setState({
                            company_phone: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Company Email</Form.Label>
                      <Form.Control
                        placeholder="Company Email"
                        value={company_email}
                        onChange={e =>
                          this.setState({
                            company_email: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Street Name Number</Form.Label>
                      <Form.Control
                        placeholder="Street Name Number"
                        value={street_name_number}
                        onChange={e =>
                          this.setState({
                            street_name_number: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Postcode ZIP</Form.Label>
                      <Form.Control
                        placeholder="123456"
                        value={postcode}
                        onChange={e =>
                          this.setState({
                            postcode: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Address Line 1</Form.Label>
                      <Form.Control
                        placeholder="Address Line 1"
                        value={address_line1}
                        onChange={e =>
                          this.setState({
                            address_line1: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Form.Group>
                      <Form.Label>Address Line 2</Form.Label>
                      <Form.Control
                        placeholder="Address Line 2"
                        value={address_line2}
                        onChange={e =>
                          this.setState({
                            address_line2: e.target.value
                          })
                        }
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    Store Pickup
                    <StatusSwitch
                      checked={Boolean(store_pickup)}
                      onChange={() =>
                        this.setState({
                          store_pickup: Number(!store_pickup)
                        })
                      }
                    />
                  </div>
                </div>

                <br />
                <div className="row">
                  <div className="col">
                    <Button
                      type="submit"
                      disabled={createProgress}
                      className="btn btn-light mr-2"
                      onClick={onHide}
                      as={Link}
                      to="/setting/store"
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={createProgress}
                      className="btn btn-primary mr-2"
                      onClick={this.submitForm}
                    >
                      {createProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  cityList: state.app.cityList,
  createProgress: state.storeAddress.createProgress,
  updateProgress: state.storeAddress.updateProgress,
  storeAddress: state.storeAddress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getCityByState: appActions.getCityByState,
      createStoreAddress: storeAddressActions.createStoreAddress
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddressAdd);
