import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { required, number } from '../../utills/validator';
import { inputField, inputFieldControlled } from '../../components/Fields';
import routePath from '../../constants/routePath';
import btnLoader from '../../assets/images/btn-loader.gif';

class GenerationForm extends React.Component {
  render() {
    const { handleSubmit, submitForm, inProgress } = this.props;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="title"
              component={inputFieldControlled}
              type="text"
              className="form-control"
              label="Title"
              feedback={this.handleSlug}
              validate={[required]}
            />
            <Field
              name="bp_percent"
              component={inputField}
              type="text"
              className="form-control"
              label="B-Point Percentage"
              validate={[required, number]}
            />
          </div>
          <div className="col-xs-12 col-md-6" />
        </div>
        <Link to={routePath.generation.path} className="btn btn-light mr-2">
          Cancel
        </Link>
        <button type="submit" disabled={inProgress} className="btn btn-primary">
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </button>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'generationForm' // a unique identifier for this form
})(GenerationForm);
