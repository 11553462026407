/* global appConfig */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PageLoader from '../../components/PageLoader';
import { app as appActions, payout as payoutActions } from '../../actions';
import routePath from '../../constants/routePath';

const generationArray = [
  { label: 'Generation 1 - 20%', commission: '(BP x 20%) x 1.43' },
  { label: 'Generation 2 - 10%', commission: '(BP x 10%) x 1.43' },
  { label: 'Generation 3 - 9%', commission: '(BP x 9%) x 1.43' },
  { label: 'Generation 4 - 9%', commission: '(BP x 9%) x 1.43' },
  { label: 'Generation 5 - 7%', commission: '(BP x 7%) x 1.43' },
  { label: 'Generation 6 - 7%', commission: '(BP x 7%) x 1.43' },
  { label: 'Generation 7 - 5%', commission: '(BP x 5%) x 1.43' },
  { label: 'Generation 8 - 4%', commission: '(BP x 4%) x 1.43' },
  { label: 'BP outside 8 Generations', commission: 'N/A' }
];

class PayoutStatement extends React.Component {
  componentDidMount() {
    const { match, getPayoutById, getPrefetchCompanyAddress } = this.props;
    getPrefetchCompanyAddress();
    if (match.params && match.params.id) {
      getPayoutById(match.params.id);
    }
  }

  handleInvoiceDownload = async () => {
    const { match, downloadPayoutInvoice } = this.props;
    if (match.params && match.params.id) {
      downloadPayoutInvoice(match.params.id);
    }
  };

  render() {
    const { payout, match, appSetting, companyAddress } = this.props;
    let data = null;
    if (match.params && match.params.id && payout[match.params.id]) {
      data = payout[match.params.id];
    }
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              {/* <BreadCrumb list={routePath.payoutStatement.breadcrumb} /> */}
              <h4 className="page-title">{routePath.payoutStatement.title}</h4>
            </div>
          </div>
        </div>
        <div className="card">
          {data ? (
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap table-sm mb-2">
                  <thead>
                    <tr>
                      <td width="50%" className="border-right-0">
                        <img
                          src={appConfig.logo}
                          alt="{appConfig.logoALT}"
                          height="48"
                        />
                      </td>
                      <td className="border-right-0 border-left-0 align-text-top" />
                      <td className="border-left-0 align-text-top" width="20%">
                        {!isEmpty(companyAddress.company_2) ? (
                          <>
                            <h6>{companyAddress.company_2.name}</h6>
                            <address className="font-13 mb-1">
                              <p className="font-weight-normal m-0">
                                {companyAddress.company_2.street_name_number}
                                <br />
                                {companyAddress.company_2.address_line1}
                                <br />
                                {companyAddress.company_2.address_line2 ? (
                                  <>
                                    {companyAddress.company_2.address_line2}
                                    <br />
                                  </>
                                ) : null}
                                {companyAddress.company_2.postcode}
                                {', '}
                                {companyAddress.company_2.city}
                                <br />
                                {companyAddress.company_2.state}
                                {', '}
                                {companyAddress.company_2.country}
                                <br />
                                {`Phone: ${companyAddress.company_2.mobile_number}`}
                              </p>
                            </address>
                          </>
                        ) : null}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <h5 className="m-0">
                          <span className="text-uppercase text-primary">
                            Payout Statement: {data.payout_no}
                          </span>
                          <span className="ml-2 font-13 font-weight-normal float-right">
                            {`${data.month_name} - ${data.period}`}
                          </span>
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td className="align-text-top">
                        <h6 className="text-uppercase text-primary">
                          Personal details
                        </h6>
                        <p className="font-12">
                          <strong>Associate ID:</strong>{' '}
                          {data.user && data.user.uuid}
                          <br />
                          <strong>Full Name:</strong>{' '}
                          {data.user &&
                            `${data.user.first_name} ${data.user.last_name}`}
                          <br />
                          <strong>Email:</strong> {data.user && data.user.email}
                        </p>
                      </td>
                      <td className="align-text-top" colSpan="2">
                        <h6 className="text-uppercase text-primary">Address</h6>
                        <p className="font-12">
                          {data.address ? (
                            <>
                              {data.address.street_name_number}
                              <br />
                              {data.address.address_line1}
                              <br />
                              {data.address.address_line2 ? (
                                <>
                                  {data.address.address_line2}
                                  <br />
                                </>
                              ) : null}
                              {data.address.postcode}
                              <br />
                              {data.address.city_details
                                ? data.address.city_details.name
                                : data.address.city && data.address.city}
                              <br />
                              {data.address.state
                                ? data.address.state.name
                                : ''}
                              <br />
                              {typeof data.address.country === 'object'
                                ? data.address.country.name
                                : data.address.country}
                            </>
                          ) : (
                            '---'
                          )}
                        </p>
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-bordered table-nowrap table-sm">
                      <thead>
                        <tr className="bg-light-lighten text-primary">
                          <th width="50%">Earnings</th>
                          <th className="text-center">B-Points</th>
                          <th colSpan="2" className="text-right">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Associate Signup Purchase</td>
                          <td className="text-center">
                            {parseFloat(data.associate_signup).toFixed(2)}
                          </td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${parseFloat(
                              data.currency_associate_signup_amount
                            ).toFixed(2)}`}
                          </td>
                        </tr>
                        <tr>
                          <td>Own Purchase</td>
                          <td className="text-center">
                            {parseFloat(data.own_purchase).toFixed(2)}
                          </td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${parseFloat(
                              data.own_purchase_amount
                            ).toFixed(2)}`}
                          </td>
                        </tr>
                        <tr>
                          <td>Customer Purchase</td>
                          <td className="text-center">
                            {parseFloat(data.customer_purchase).toFixed(2)}
                          </td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${parseFloat(
                              data.currency_customer_purchase_amount
                            ).toFixed(2)}`}
                          </td>
                        </tr>
                        {generationArray.map((item, index) => (
                          <tr key={`generation_bp_${index}`}>
                            <td>{item.label}</td>
                            <td className="text-center">
                              {data.generation_bp[index + 1]
                                ? data.generation_bp[index + 1].bpoint
                                : '0.00'}
                            </td>
                            <td colSpan="2" className="text-right">
                              {`${data.currency}${
                                data.generation_bp[index + 1]
                                  ? data.generation_bp[index + 1]
                                      .currency_amount
                                  : '0.00'
                              }`}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <th className="bg-light-lighten">Total Ranking BP</th>
                          <th className="text-center">
                            {parseFloat(data.total_ranking_bp).toFixed(2)}
                          </th>
                          <th rowSpan="2">Total BP Earnings</th>
                          <th rowSpan="2" className="text-right">
                            {`${data.currency}${data.currency_total_bp_earn}`}
                          </th>
                        </tr>
                        <tr>
                          <th className="bg-light-lighten">
                            Total Qualified BP
                          </th>
                          <th className="text-center">
                            {parseFloat(data.total_qualified_bp).toFixed(2)}
                          </th>
                        </tr>
                        <tr>
                          <td colSpan="4" className="border-0" />
                        </tr>
                      </tbody>
                      <thead>
                        <tr className="bg-light-lighten text-primary">
                          <th colSpan="2" width="50%">
                            Cash Earnings
                          </th>
                          <th colSpan="2" className="text-right">
                            Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="2">Associate Joining Package Sale</td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${data.currency_associate_joining_package_sale}`}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">Fast Start Bonus</td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${data.currency_fsb_payout}`}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">Car Bonus</td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${parseFloat(
                              data.car_payout
                            ).toFixed(2)}`}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2">Cash Bonus</td>
                          <td colSpan="2" className="text-right">
                            {`${data.currency}${parseFloat(
                              data.cash_payout
                            ).toFixed(2)}`}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="4" className="border-0" />
                        </tr>
                      </tbody>
                      <tbody>
                        <tr className="text-primary">
                          <td colSpan="2">
                            <h4 className="text-uppercase m-0">Total Payout</h4>
                          </td>
                          <td colSpan="2" rowSpan="1" className="text-right">
                            {data.euro_value === 1 ? (
                              <h3>
                                {`€${parseFloat(data.total_payout).toFixed(2)}`}
                              </h3>
                            ) : (
                              <>
                                <h5>
                                  {`€${parseFloat(data.total_payout).toFixed(
                                    2
                                  )}`}
                                </h5>
                                <h3>
                                  {`${data.currency}${parseFloat(
                                    data.currency_total_payout
                                  ).toFixed(2)}`}
                                </h3>
                              </>
                            )}
                          </td>
                        </tr>

                        <tr>
                          <td colSpan="4" className="border-0" />
                        </tr>
                        <tr>
                          <td colSpan="4" className="font-13">
                            Authorised Signature
                            <div>
                              <img src={appConfig.companySign} alt="sign" />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="font-11">
                            Date &amp; Time: &nbsp;
                            {moment(data.created_at).format(
                              appSetting.site_date_format
                            )}{' '}
                            {moment(data.created_at).format('H:mm A')}
                          </td>
                          <td colSpan="2" className="font-11">
                            This is a computer generated statement.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="d-print-none mt-2">
                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => window.print()}
                    className="btn btn-primary mr-2"
                  >
                    <i className="mdi mdi-printer" /> Print
                  </button>
                  <button
                    type="button"
                    onClick={this.handleInvoiceDownload}
                    className="btn btn-success"
                  >
                    <i className="mdi mdi-download" /> Download
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  payout: state.payout.details,
  appSetting: state.app.settings,
  companyAddress: state.app.companyAddress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPayoutById: payoutActions.getPayoutById,
      downloadPayoutInvoice: payoutActions.downloadPayoutInvoice,
      getPrefetchCompanyAddress: appActions.getPrefetchCompanyAddress
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutStatement);
