import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import ProfileImg from '../../components/ProfileImg';
import { tree as treeActions } from '../../actions';
import { getMonthOption } from '../../utills/custom';

function MenuItem({ user }) {
  return (
    <>
      <span
        className={`flag-icon flag-icon-${user.country.toLowerCase()} mr-1`}
      />
      <span>{`${user.uuid} - ${user.first_name} ${user.last_name}`}</span>
    </>
  );
}

class TreeFilterForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.monthOption = getMonthOption();
    const dateObj = moment();
    this.state = {
      selectedMonth: {
        label: `${dateObj.format('MMMM')} - ${dateObj.format('YYYY')}`,
        value: `${dateObj.month() + 1},${dateObj.year()}`
      }
    };
  }

  formatAssociateUsers = async (input, callback) => {
    if (input) {
      try {
        const result = await axios({
          url: `${process.env.REACT_APP_API_URL}/associate/search`,
          method: 'GET',
          params: { user: input }
        });
        if (result && result.users) {
          callback(
            result.users.map(user => ({
              label: <MenuItem user={user} />,
              value: user.id
            }))
          );
        } else {
          callback([]);
        }
      } catch (error) {
        callback([]);
      }
    }
  };

  handleMonthChange = val => {
    const { submitForm } = this.props;
    this.setState({ selectedMonth: val });
    submitForm(undefined, val);
  };

  handleSelectSearch = input => {
    const { submitForm } = this.props;
    const { selectedMonth } = this.state;
    submitForm(input, selectedMonth);
  };

  render() {
    const { loadingAssociate, hideSearch } = this.props;
    const { selectedMonth } = this.state;

    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select Month"
            isClearable={false}
            isSearchable
            name="month_picker"
            value={selectedMonth}
            options={this.monthOption}
            onChange={this.handleMonthChange}
          />
        </div>
        {hideSearch ? null : (
          <div className="col-12 col-md-8">
            <AsyncSelect
              cacheOptions
              loadOptions={this.formatAssociateUsers}
              defaultOptions
              isSearchable
              isClearable
              isLoading={loadingAssociate}
              onChange={this.handleSelectSearch}
              autofocus
              placeholder="Search by Associate Name or ID"
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('treeFilterForm')(state),
  associateUsers: state.tree.associateUsers,
  loadingAssociate: state.tree.loadingAssociate
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAssociateTree: treeActions.getAssociateTree
    },
    dispatch
  );

const TreeFilterFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeFilterForm);

export default reduxForm({
  form: 'treeFilterForm' // a unique identifier for this form
})(TreeFilterFormConnected);
