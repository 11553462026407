import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { paymentOption as paymentOptionActions } from '../../actions';
import PaymentCardForm from './PaymentCardForm';
import PaymentBankForm from './PaymentBankForm';

class PaymentOptionModal extends React.Component {
  handleClose = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  render() {
    const { modal, paymentOption } = this.props;

    return (
      <Modal show={!!modal} onHide={this.handleClose}>
        {modal === 'card' && (
          <PaymentCardForm
            initialValues={paymentOption}
            enableReinitialize="true"
          />
        )}
        {modal === 'bank' && (
          <PaymentBankForm
            initialValues={paymentOption}
            enableReinitialize="true"
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modal: state.paymentOption.modal,
  paymentOption: state.paymentOption.editPaymentOption
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showModal: paymentOptionActions.showModal,
      hideModal: paymentOptionActions.hideModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentOptionModal);
