import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  template as templateActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllTemplate() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/template`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: templateActionTypes.TEMPLATE_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getTemplate(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/template/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: templateActionTypes.TEMPLATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createTemplate(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/template`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Template was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: templateActionTypes.TEMPLATE_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.template.path
      });
    }
  } catch (error) {
    yield put({ type: templateActionTypes.TEMPLATE_CREATE_ERROR, data: error });
  }
}

function* updateTemplate(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/template/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Template updated successfully'
    });
    if (responseJson) {
      yield put({
        type: templateActionTypes.TEMPLATE_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.template.path
        });
      }
    }
  } catch (error) {
    yield put({ type: templateActionTypes.TEMPLATE_UPDATE_ERROR, data: error });
  }
}

function* watchPackageListAll() {
  yield takeLatest(
    templateActionTypes.TEMPLATE_ALL_LIST_REQUEST,
    getAllTemplate
  );
}

function* watchGetTemplate() {
  yield takeLatest(templateActionTypes.TEMPLATE_REQUEST, getTemplate);
}

function* watchTemplateCreate() {
  yield takeLatest(templateActionTypes.TEMPLATE_CREATE_REQUEST, createTemplate);
}

function* watchTemplateUpdate() {
  yield takeLatest(templateActionTypes.TEMPLATE_UPDATE_REQUEST, updateTemplate);
}

export default [
  fork(watchPackageListAll),
  fork(watchTemplateCreate),
  fork(watchTemplateUpdate),
  fork(watchGetTemplate)
];
