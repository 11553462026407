import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import BreadCrumb from '../../components/BreadCrumb';
import InnerLoader from '../../components/InnerLoader';
import routePath from '../../constants/routePath';
import { fsb as fsbActions } from '../../actions';
import StatusSwitch from '../../components/StatusSwitch';
import AdvanceTable from '../../components/AdvanceTable';

class FsbConfig extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getFsb } = this.props;
    const { page } = this.state;
    getFsb({ page });
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAllGenerations } = this.props;
    if (type === 'pagination') getAllGenerations({ page, per_page: perPage });
    this.setState({ page });
  };

  updateFsbStatus = (id, val) => {
    const { updateFsb } = this.props;
    updateFsb({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  render() {
    const { fsb, isLoading, filterValues } = this.props;
    const { page, selected } = this.state;
    const columns = [
      {
        dataField: 'id',
        text: '#',
        headerClasses: 'text-center',
        headerAttrs: { width: '5%' },
        classes: 'text-center'
      },
      {
        dataField: 'title',
        text: 'Plan',
        formatter: this.titleFormatter
      },
      {
        dataField: 'total_duration',
        text: 'Duration',
        headerClasses: 'text-center',
        headerAttrs: { width: '13%' },
        classes: 'text-center'
      },
      // {
      //   dataField: 'bp_target',
      //   text: 'Total BP',
      //   headerClasses: 'text-center',
      //   classes: 'text-center',
      //   headerAttrs: { width: '13%' }
      // },
      {
        dataField: 'date',
        text: 'Date',
        headerClasses: 'text-center',
        headerAttrs: { width: '15%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <span className="date">{moment(col).format('DD/MM/YYYY')}</span>
        )
      },
      // {
      //   dataField: 'status',
      //   text: 'Active?',
      //   headerClasses: 'text-center',
      //   classes: 'text-center',
      //   headerAttrs: { width: '9%' },
      //   formatter: (col, row) => (
      //     <StatusSwitch
      //       checked={parseInt(col, 10)}
      //       onChange={this.updateFsbStatus}
      //       id={row.id}
      //       idPrefix="fsb_sts_"
      //     />
      //   )
      // },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '13%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/fsb/${row.id}/view`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
            <Link to={`/fsb/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.fsb.breadcrumb} />
              <h4 className="page-title">{routePath.fsb.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <AdvanceTable
                  data={fsb.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={fsb.total}
                  sizePerPage={parseInt(fsb.per_page, 10)}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.fsb.isLoading,
  updateProgress: state.fsb.updateProgress,
  fsb: state.fsb.plan,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFsb: fsbActions.updateFsb,
      getFsb: fsbActions.getFsb
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FsbConfig);
