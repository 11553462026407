/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Dropdown from 'react-bootstrap/Dropdown';
import { get, debounce } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import AdvanceTable from '../../components/AdvanceTable';
import StatusSwitch from '../../components/StatusSwitch';
import BreadCrumb from '../../components/BreadCrumb';
import { product as productActions, app as appActions } from '../../actions';
import routePath from '../../constants/routePath';
import img from '../../assets/images/img-placeholder.jpg';
import { productImage } from '../../constants/imageSize';
import Image from '../../components/Image';
import { SpinnerLoader } from '../../components/InnerLoader';
import { WarningAlert } from '../../components/ConfirmAlert';

class Product extends React.Component {
  state = {
    page: 1,
    alert: false,
    selected: [],
    selectedCategory: '',
    searchValue: '',
    searchType: 'Title'
  };

  componentDidMount() {
    const { getCountry, getPrefetchCategory } = this.props;
    this.getProducts();
    getCountry();
    getPrefetchCategory();
  }

  componentDidUpdate(_, prevState) {
    const {
      selectedCountry: prevCountry,
      selectedCategory: prevCategory,
      searchValue: prevValue,
      page: prevPage,
      perPage: prevPerPage
    } = prevState;

    const {
      selectedCountry,
      selectedCategory,
      searchValue,
      page,
      perPage
    } = this.state;

    if (
      prevCountry !== selectedCountry ||
      prevCategory !== selectedCategory ||
      page !== prevPage ||
      perPage !== prevPerPage
    ) {
      this.getProducts();
    }
    if (searchValue !== prevValue) {
      this.debouncedGetProducts();
    }
  }

  getProducts = () => {
    const { getAllProducts } = this.props;

    const {
      searchType,
      selectedCountry,
      searchValue,
      selectedCategory,
      page,
      perPage
    } = this.state;

    const requestData = {};

    if (selectedCountry) requestData.country = selectedCountry;
    if (selectedCategory) requestData.c_id = selectedCategory;
    if (searchValue) {
      if (searchType === 'Title') requestData.title = searchValue;
      else requestData.sku = searchValue;
    }
    if (page) requestData.page = page;
    if (perPage) requestData.per_page = perPage;

    getAllProducts(requestData);
  };

  // eslint-disable-next-line react/sort-comp
  debouncedGetProducts = debounce(this.getProducts, 500);

  handleTableChange = (_, options) => {
    const { sizePerPage: perPage, page } = options;
    this.setState({ page, perPage });
  };

  handleFilterChange = (type, data) => {
    this.setState({ [type]: data ? data.value : '', page: 1 });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleAlert = () => {
    const { alert } = this.state;
    this.setState({ alert: !alert });
  };

  updateProductStatus = (id, val) => {
    const { updateProductStatus } = this.props;
    updateProductStatus({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  updateProductSoldout = (id, val) => {
    const { updateProduct } = this.props;
    updateProduct({
      id,
      data: { sold_out: val ? 1 : 0 }
    });
  };

  confirmCloneProduct = productId => {
    this.handleAlert();
    if (productId) this.setState({ selected: [productId] });
  };

  cloneProduct = () => {
    const { selected } = this.state;
    const { cloneProduct } = this.props;
    cloneProduct({
      products_id: selected
    });
    this.setState({ selected: [], alert: false });
  };

  getCategoryLinks = product => {
    if (product.category && product.category.length) {
      return (
        <>
          {product.category.map(item => (
            <Link to="/" className="dashed-underline">
              {get(item, 'category[0].title', '')}
            </Link>
          ))}
        </>
      );
    }
    return null;
  };

  render() {
    const {
      products,
      isLoading,
      createProgress,
      countryList,
      categoryList = [],
      filterValues
    } = this.props;

    const { page, alert, selected, searchValue, searchType } = this.state;

    const columns = [
      {
        dataField: 'sku',
        text: 'SKU',
        headerAttrs: { width: '10%' }
      },
      {
        dataField: 'product_image_default',
        text: 'Image',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => {
          let pImage = get(
            row,
            'product_image_default[0].file.file_path',
            null
          );
          if (pImage) {
            pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
          } else {
            pImage = img;
          }
          return (
            <span className="img_holder">
              <Image src={pImage} alt="" size={productImage.productAdmin} />
            </span>
          );
        }
      },
      {
        dataField: 'title',
        text: 'Title',
        headerAttrs: { width: '15%' },
        formatter: (col, row) => (
          <Link to={`/product/${row.id}/view`} className="dashed-underline">
            {col}
          </Link>
        )
      },
      {
        dataField: 'category',
        text: 'Categories',
        formatter: (col, row) => {
          if (col && col.length) {
            return (
              <div className="d-flex flex-wrap">
                {col.map(item => (
                  <span key={`${item.id}-cat`} className="d-flex mr-1">
                    <Link to="/" className="dashed-underline">
                      {get(item, 'category[0].title', '')}
                    </Link>
                  </span>
                ))}
              </div>
            );
          }
          return '-';
        }
      },
      {
        dataField: 'regular_price',
        text: 'Price',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => {
          if (col) {
            if (parseInt(row.sale_price, 10) > 0) {
              return (
                <div className="font-weight-semibold">
                  <small className="text-strikeout text-danger">{col}</small>
                  <p className="text-primary">{`€${row.sale_price}`}</p>
                </div>
              );
            }
            return (
              <span className="font-weight-semibold text-primary">{`€${col}`}</span>
            );
          }
          return '-';
        }
      },
      /* {
    dataField: 'stock_qty',
    text: 'Stock',
    headerClasses: 'text-center',
    classes: 'text-center',
    headerAttrs: { width: '10%' },
    formatter: (col, row) => {
      const intCol = parseInt(col, 10);
      return <span className={`badge badge-${intCol < 10 ? 'danger' : 'success'}`}>{intCol}</span>;
    }
  }, */ {
        dataField: 'display_product',
        text: 'Visibility',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '15%' },
        formatter: col => {
          if (col === 1) {
            return <span className="badge badge-primary">Associate</span>;
          }
          if (col === 2) {
            return <span className="badge badge-success">Customer</span>;
          }
          return <span className="badge badge-dark">Both</span>;
        }
      },
      {
        dataField: 'created_at',
        text: 'Published',
        formatter: (col, row) => (
          <span className="date">{moment(col).format('DD/MM/YYYY')}</span>
        )
      },
      {
        dataField: 'status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={parseInt(col, 10)}
            onChange={this.updateProductStatus}
            id={row.id}
            idPrefix="assos_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '13%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <button
              type="button"
              className="btn btn-sm btn-icon"
              onClick={() => this.confirmCloneProduct(row.id)}
              title="Copy"
            >
              <i className="action-icon mdi mdi-content-duplicate" />
            </button>
            <Link to={`/product/${row.id}/view`} title="View">
              <i className="action-icon mdi mdi-eye" />
            </Link>
            <Link to={`/product/${row.id}/edit`} title="Edit">
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.product.breadcrumb} />
              <h4 className="page-title">{routePath.product.title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2 justify-content-between">
                  <div className="col-sm-12 col-md-9 col-lg-10 ">
                    <div className="form-row product-form">
                      <div className="col-md-4">
                        <div id="custom-search">
                          <Form.Group className="search-bar input-group">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="light"
                                drop="right"
                                id="filterOptionDropdown"
                              >
                                {searchType}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    this.handleFilterChange('searchType', {
                                      value: 'Title'
                                    })
                                  }
                                >
                                  Title
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    this.handleFilterChange('searchType', {
                                      value: 'SKU'
                                    })
                                  }
                                >
                                  SKU
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control
                              type="text"
                              className="form-control col-md-10"
                              placeholder="Search By Title or SKU"
                              value={searchValue}
                              onChange={e =>
                                this.handleFilterChange('searchValue', e.target)
                              }
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className="col-md-4 product-dropdown mb-2">
                        <Select
                          options={categoryList.map(item => {
                            const { id, title } = item;
                            return { value: id, label: title };
                          })}
                          isSearchable
                          onChange={e =>
                            this.handleFilterChange('selectedCategory', e)
                          }
                          placeholder="Select Category"
                          isClearable
                        />
                      </div>
                      <div className="col-md-4 product-dropdown mb-2">
                        <Select
                          options={countryList.map(item => item)}
                          onChange={e =>
                            this.handleFilterChange('selectedCountry', e)
                          }
                          placeholder="Select Country"
                          isClearable
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-3 col-lg-2 px-lg-0 text-md-right text-lg-center">
                    <button
                      type="button"
                      onClick={() => this.getProducts()}
                      className="btn btn-sm btn-success btn-refresh mr-2"
                    >
                      {isLoading ? (
                        <SpinnerLoader className="spinner-border-sm" />
                      ) : (
                        <i className="fas fa-sync" />
                      )}
                    </button>
                    <div className="btn-group btn-group-sm">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          <Dropdown.Item
                            as={Link}
                            to={routePath.productAdd.path}
                          >
                            <i className="mdi mdi-plus-circle-outline" /> Add
                            new
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => this.confirmCloneProduct()}
                          >
                            <i className="mdi mdi-content-duplicate" /> Clone
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            <i className="mdi mdi-trash-can" /> Delete
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-export" /> Export
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <AdvanceTable
                  data={products.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={products.total}
                  sizePerPage={+products.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
        <WarningAlert
          show={!!alert}
          inProgress={createProgress}
          onConfirm={this.cloneProduct}
          onCancel={this.handleAlert}
        >
          This will clone the selected product. Please make sure before
          confirming.
        </WarningAlert>
      </>
    );
  }
}

const mapStateToProps = state => ({
  products: state.product.allList,
  isLoading: state.product.isLoading,
  createProgress: state.product.createProgress,
  countryList: state.app.countryList,
  categoryList: state.app.categoryList,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllProducts: productActions.getAllProducts,
      getProductsByCategory: productActions.getProductsByCategory,
      updateProduct: productActions.updateProduct,
      updateProductStatus: productActions.updateProductStatus,
      cloneProduct: productActions.cloneProduct,
      getCountry: appActions.getCountry,
      getPrefetchCategory: appActions.getPrefetchCategory
    },
    dispatch
  );
// product-form
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Product)
);
