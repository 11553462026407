import { auth as authActionTypes } from '../actions';
import StorageService from '../services/storageService';
import initialState from '../constants/initialState';

function auth(state = initialState.auth, action) {
  switch (action.type) {
    case authActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginProgress: true
      };
    case authActionTypes.LOGIN_SUCCESS:
      if (action.data) {
        StorageService.setToken(action.data.access_token);
        StorageService.setRefreshToken(action.data.refresh_token);
      }
      return {
        ...state,
        loginProgress: false
      };
    case authActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loginProgress: false
      };
    case authActionTypes.REGISTER_REQUEST:
      return {
        ...state,
        registrationProgress: true
      };
    case authActionTypes.REGISTER_SUCCESS:
      if (action.data) {
        StorageService.setToken(action.data.access_token);
        StorageService.setRefreshToken(action.data.refresh_token);
      }
      return {
        ...state,
        registrationProgress: false
      };
    case authActionTypes.REGISTER_ERROR:
      return {
        ...state,
        registrationProgress: false
      };
    case authActionTypes.REF_USER_ID_SUCCESS:
      // window.sessionStorage.setItem('refUUId', action.data.uuid);
      return {
        ...state,
        refUserId: action.data.uuid,
        refUserChecked: true
      };
    case authActionTypes.REF_USER_ID_ERROR:
      return {
        ...state,
        refUserId: '',
        refUserChecked: true
      };
    case authActionTypes.LOGOUT_SUCCESS:
      if (action.data) {
        StorageService.clearStorage();
      }
      return initialState.auth;
    case authActionTypes.USER_INFO_REQUEST:
      return {
        ...state,
        userInfoProgress: true
      };
    case authActionTypes.USER_INFO_SUCCESS:
      if (action.data) {
        if (action.data.permission) {
          const userRoles = action.data.permission.role;
          return {
            ...state,
            user: action.data,
            isAdmin: userRoles.indexOf('super-admin') > -1,
            userInfoProgress: false
          };
        }
        return {
          ...state,
          user: action.data,
          userInfoProgress: false
        };
      }
      return {
        ...state,
        userInfoProgress: false
      };
    case authActionTypes.USER_INFO_ERROR:
      return {
        ...state,
        userInfoProgress: false
      };
    case authActionTypes.PACKAGE_SELECT_SUCCESS:
      return {
        ...state,
        package: action.data
      };
    case authActionTypes.SUBSCRIPTION_REQUEST:
      return {
        ...state,
        registrationProgress: true
      };
    case authActionTypes.SUBSCRIPTION_ERROR:
      return {
        ...state,
        registrationProgress: false
      };
    case authActionTypes.SUBSCRIPTION_SUCCESS:
      if (action.data) {
        return {
          ...state,
          user: { ...state.user, subscription: action.data },
          registrationProgress: false
        };
      }
      return {
        ...state,
        registrationProgress: false
      };
    case authActionTypes.SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        subscriptionStatusProgress: true
      };
    case authActionTypes.SUBSCRIPTION_STATUS_ERROR:
      return {
        ...state,
        subscriptionStatusProgress: false
      };
    case authActionTypes.SUBSCRIPTION_STATUS_SUCCESS:
      if (action.data) {
        return {
          ...state,
          subscriptionStatus: action.data,
          subscriptionStatusProgress: false
        };
      }
      return {
        ...state,
        subscriptionStatusProgress: false
      };
    case authActionTypes.SUBSCRIPTION_CALCULATION_REQUEST:
      return {
        ...state,
        calculationProgress: true
      };
    case authActionTypes.SUBSCRIPTION_CALCULATION_SUCCESS:
      if (action.data) {
        return {
          ...state,
          subscriptionCalculation: action.data,
          calculationProgress: false
        };
      }
      return state;
    case authActionTypes.SUBSCRIPTION_SHIPPING_OPTION_REQUEST:
      return {
        ...state,
        calculationProgress: true
      };
    case authActionTypes.SUBSCRIPTION_SHIPPING_OPTION_SUCCESS:
      if (action.data) {
        return {
          ...state,
          calculationProgress: false,
          subscriptionShippingOptions: action.data
        };
      }
      return state;
    case authActionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordProgress: true
      };
    case authActionTypes.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordSuccess: false,
        forgotPasswordProgress: false
      };
    case authActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPasswordSuccess: true,
        forgotPasswordProgress: false
      };

    case authActionTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordProgress: true
      };
    case authActionTypes.RESET_PASSWORD_SUCCESS:
    case authActionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetPasswordProgress: false
      };

    case authActionTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordProgress: true
      };
    case authActionTypes.CHANGE_PASSWORD_SUCCESS:
      if (action.data) {
        StorageService.setToken(action.data);
      }
      return {
        ...state,
        changePasswordProgress: false
      };
    case authActionTypes.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordProgress: false
      };
    default:
      return state;
    case authActionTypes.SHIPPING_STATE_LIST_REQUEST:
      return {
        ...state,
        shippingStateList: [],
        shippingCityList: []
      };
    case authActionTypes.SHIPPING_STATE_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          shippingStateList: action.data
        };
      }
      return state;
    case authActionTypes.SHIPPING_CITY_LIST_REQUEST:
      return {
        ...state,
        shippingCityList: []
      };
    case authActionTypes.SHIPPING_CITY_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          shippingCityList: action.data
        };
      }
      return state;
  }
}

export default auth;
