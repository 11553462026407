import React from 'react';
import { Field, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { inputField } from '../../components/Fields';
import { required, number } from '../../utills/validator';

function FsbItemField({ fields, dispatch, formValues }) {
  const handleDelete = index => () => {
    if (formValues.phase[index].id) {
      dispatch(
        change('fsbConfigForm', 'is_deleted', [
          ...formValues.is_deleted,
          formValues.phase[index].id
        ])
      );
    }
    fields.remove(index);
  };

  return (
    <table className="table table-bordered table-centered table-sm no-width">
      <thead className="table-light">
        <tr>
          <th width="30%">Phases</th>
          <th width="15%">BP Target</th>
          <th width="15%">Payout</th>
          <th width="1%">
            {fields.length < 3 && (
              <button
                type="button"
                className="btn action-icon btn-rounded btn-sm"
                onClick={() => fields.push({ id: 0 })}
              >
                <i className="mdi mdi-plus-circle" />
              </button>
            )}
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((member, index) => (
          <tr key={index} className="row-fields">
            <td>
              <Field
                name={`${member}.title`}
                component={inputField}
                type="text"
                className="form-control"
                validate={[required]}
              />
            </td>
            <td>
              <Field
                name={`${member}.bp_target`}
                component={inputField}
                type="text"
                className="form-control"
                validate={[required, number]}
              />
            </td>
            <td>
              <Field
                name={`${member}.payout`}
                component={inputField}
                type="text"
                className="form-control"
                validate={[required, number]}
              />
            </td>
            <td>
              <button
                type="button"
                className="btn btn-icon btn-sm action-icon"
                onClick={handleDelete(index)}
              >
                <i className="mdi mdi-delete" />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

const mapStateToProps = state => ({
  formValues: getFormValues('fsbConfigForm')(state)
});

export default connect(
  mapStateToProps,
  null
)(FsbItemField);
