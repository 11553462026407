import React from 'react';
import { Card } from 'react-bootstrap';
import { OverlayLoader } from './InnerLoader';
import getDuration from '../utills/getDuration';

function GrowthBox({
  isLoading,
  title,
  quantity,
  revenue,
  filter,
  start,
  end
}) {
  const { start: startDate, end: endDate } = getDuration(filter, start, end);
  const quantityFeedback =
    Number(quantity.split('%')[0]) >= 0 ? 'text-success' : 'text-danger';
  const revenueFeedback =
    Number(revenue.split('%')[0]) >= 0 ? 'text-success' : 'text-danger';
  return (
    <Card className="mb-1 h-100 stat-card">
      {isLoading && <OverlayLoader type="spinner" />}
      <div className="stat-row pl-2 pr-2 pt-2">
        <div>
          <div className="stat-title font-weight-bold text-uppercase">
            {title}
          </div>
          <div className="stat-title text-uppercase">
            {startDate} TO {endDate}
          </div>
        </div>
        <i className="mdi mdi-pulse float-right widget widget-icon-circle" />
      </div>
      <div className="stat-row pl-2 pr-2 pb-2">
        <div>
          <div className="stat-filter font-weight-semibold text-uppercase">
            Revenue
          </div>
          <div className={`${revenueFeedback} stat-figure`}>{revenue}</div>
        </div>
        <div>
          <div className="stat-filter font-weight-semibold text-uppercase">
            Items sold
          </div>
          <div className={`${quantityFeedback} stat-figure`}>{quantity}</div>
        </div>
      </div>
    </Card>
  );
}

export default GrowthBox;
