import { shop as shopActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { insertItem } from '../utills/custom';

function rank(state = initialState.shop, action) {
  switch (action.type) {
    case shopActionTypes.SHOP_PRODUCT_REQUEST:
      return {
        ...state,
        productDetails: {},
        isLoadingDetails: true
      };
    case shopActionTypes.SHOP_PRODUCT_SUCCESS:
      if (action.data && action.data.data) {
        return {
          ...state,
          productDetails: { [action.data.data.slug]: action.data.data },
          isLoadingDetails: false
        };
      }
      return {
        ...state,
        isLoadingDetails: false
      };
    case shopActionTypes.SHOP_PRODUCT_ERROR:
      return {
        ...state,
        isLoadingDetails: false
      };
    case shopActionTypes.SEARCH_REQUEST:
      return {
        ...state,
        isSearching: true
      };

    case shopActionTypes.SEARCH_SUCCESS:
      return {
        ...state,
        isSearching: false,
        list: action.data
      };

    case shopActionTypes.SEARCH_ERROR:
      return {
        ...state,
        isSearching: false,
        list: { ...state.list, data: [] }
      };
    case shopActionTypes.GET_SHOP_CATEGORY_REQUEST:
      return {
        ...state,
        categoryLoading: true
      };
    case shopActionTypes.GET_SHOP_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryLoading: false,
        categoryList: action.data
      };
    case shopActionTypes.GET_SHOP_CATEGORY_ERROR:
      return {
        ...state,
        categoryLoading: false
      };
    default:
      return state;
  }
}

export default rank;
