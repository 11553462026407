import React, { useState } from 'react';
import { Field, reduxForm, Form } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import moment from 'moment';
import { required } from '../../utills/validator';
import { inputField, dateField, selectField } from '../../components/Fields';
import { orderFulfillmentChange } from '../../services/apiService';
import { getFulfillmentClass } from '../../utills/custom';
import btnLoader from '../../assets/images/btn-loader.gif';

const options = [
  { label: 'SHIPPED', value: 'SHIPPED' },
  { label: 'DELIVERED', value: 'DELIVERED' }
];

function orderFulfillmentShippedFormComp({
  hideModal,
  handleSubmit,
  pristine,
  submitting,
  onSubmit,
  inProgress,
  staticData,
  initialValues
}) {
  const [state, setState] = useState({
    displayForm: '',
    displayStaticData: true
  });

  const afterStausChange = value => {
    setState(prev => ({
      ...prev,
      displayForm: value,
      displayStaticData: false
    }));
  };

  const handleClose = () => {
    hideModal();
  };

  const submitForm = values => {
    const newValues = { ...values };
    if (newValues.shipped_at) {
      newValues.shipped_at = moment(newValues.shipped_at, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      );
    }
    if (newValues.delivery_date) {
      newValues.delivery_date = moment(
        newValues.delivery_date,
        'DD/MM/YYYY'
      ).format('YYYY-MM-DD');
    }
    onSubmit(newValues);
  };

  return (
    <Form
      className="needs-validation"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <Modal.Header>
        <Modal.Title>Fulfillment Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="new_status"
              id="new_status"
              component={selectField}
              label="Fulfillment Status"
              options={options}
              feedback={afterStausChange}
              validate={[required]}
            />
          </div>
        </div>
        {state.displayForm === 'SHIPPED' ? (
          <>
            <hr />
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="shipped_at"
                  component={dateField}
                  type="text"
                  className="form-control"
                  label="Shipping Date"
                  dateFormat="DD/MM/YYYY"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="shipping_carrier"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Shipping Carrier"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="shipping_carrier_ref"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Shipping Carrier Ref"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="tracking_link"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Tracking Link"
                  validate={[required]}
                />
              </div>
            </div>
          </>
        ) : null}
        {state.displayForm === 'DELIVERED' ? (
          <>
            <hr />
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Field
                  name="delivery_date"
                  component={dateField}
                  type="text"
                  className="form-control"
                  label="Deliver Date"
                  dateFormat="DD/MM/YYYY"
                />
              </div>
            </div>
          </>
        ) : null}
        {state.displayStaticData &&
        (initialValues.new_status === 'SHIPPED' ||
          initialValues.new_status === 'DELIVERED') ? (
          <>
            <hr />
            {staticData && staticData.shipping ? (
              <div>
                <p>
                  <strong>Shipped Date: </strong>
                  {staticData.shipping.shipped_at}
                </p>
                <p>
                  <strong>Shipping Carrier: </strong>
                  {staticData.shipping.shipping_carrier}
                </p>
                <p>
                  <strong>Shipping Carrier Ref: </strong>
                  {staticData.shipping.shipping_carrier_ref}
                </p>
                <p>
                  <strong>Tracking Link: </strong>
                  <a
                    href={staticData.shipping.tracking_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Click here to track your order
                  </a>
                </p>
              </div>
            ) : (
              <p>No shipping information available</p>
            )}
          </>
        ) : null}
        {state.displayStaticData && initialValues.new_status === 'DELIVERED' ? (
          <>
            <hr />
            {staticData && staticData.delivered ? (
              <div>
                <p>
                  <strong>Delivery Date: </strong>
                  {staticData.delivered.delivery_date}
                </p>
              </div>
            ) : (
              <p>No delivery information available</p>
            )}
          </>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} disabled={inProgress}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={inProgress || pristine || submitting}
          variant="primary"
        >
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </Button>
      </Modal.Footer>
    </Form>
  );
}

const OrderFulfillmentShippedForm = reduxForm({
  form: 'orderFullfillmentShippedForm' // a unique identifier for this form
})(orderFulfillmentShippedFormComp);

function OrderFulfillmentDropdown({
  value,
  additionalData,
  orderId,
  reload,
  clickable
}) {
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  // const clickable = false;

  const handleChange = () => {
    if (clickable) {
      setShowModal(true);
    }
  };
  const handleConfirm = async (formValue = {}) => {
    try {
      setInProgress(true);
      await orderFulfillmentChange({
        order_id: orderId,
        ...formValue
      });
      reload();
      setShowModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setInProgress(false);
    }
  };

  const handleHideModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <span
        className={getFulfillmentClass(
          value,
          clickable ? 'cursor-pointer' : ''
        )}
        onClick={handleChange}
        role="presentation"
      >
        {value || 'None'}
      </span>
      <Modal show={showModal} onHide={handleHideModal} backdrop="static">
        <OrderFulfillmentShippedForm
          hideModal={handleHideModal}
          onSubmit={handleConfirm}
          inProgress={inProgress}
          initialValues={{ new_status: value }}
          staticData={JSON.parse(additionalData)}
        />
      </Modal>
    </>
  );
}

export default OrderFulfillmentDropdown;
