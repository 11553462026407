import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import MediaForm from './MediaForm';
import { media as mediaActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';

class MediaAdd extends React.Component {
  handleSubmit = values => {
    const { createMedia } = this.props;
    createMedia(values);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.mediaAdd.breadcrumb} />
              <h4 className="page-title">{routePath.mediaAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <MediaForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.media.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createMedia: mediaActions.createMedia }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaAdd);
