const rank = {
  RANK_ALL_LIST_REQUEST: 'RANK_ALL_LIST_REQUEST',
  RANK_ALL_LIST_SUCCESS: 'RANK_ALL_LIST_SUCCESS',
  RANK_ALL_LIST_ERROR: 'RANK_ALL_LIST_ERROR',
  RANK_CREATE_REQUEST: 'RANK_CREATE_REQUEST',
  RANK_CREATE_SUCCESS: 'RANK_CREATE_SUCCESS',
  RANK_CREATE_ERROR: 'RANK_CREATE_ERROR',
  RANK_UPDATE_REQUEST: 'RANK_UPDATE_REQUEST',
  RANK_UPDATE_SUCCESS: 'RANK_UPDATE_SUCCESS',
  RANK_UPDATE_ERROR: 'RANK_UPDATE_ERROR',
  RANK_REQUEST: 'RANK_REQUEST',
  RANK_SUCCESS: 'RANK_SUCCESS',
  getAllRanks: data => ({
    type: rank.RANK_ALL_LIST_REQUEST,
    data
  }),
  createRank: data => ({ type: rank.RANK_CREATE_REQUEST, data }),
  updateRank: data => ({ type: rank.RANK_UPDATE_REQUEST, data }),
  getRankById: id => ({ type: rank.RANK_REQUEST, id })
};

export default rank;
