import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { cart as cartActions } from '../../actions';

function CartPrice({
  country,
  countryCurrencies,
  getCartTotalDetails,
  cartId,
  cartCalculation
}) {
  useEffect(() => {
    if (cartId) {
      getCartTotalDetails({
        cart_id: cartId
      });
    }
  }, [cartId]);

  const currency = countryCurrencies[country]
    ? countryCurrencies[country].currency_symbol
    : '';

  return (
    <>
      <div className="border p-3 mt-4 mt-lg-0 rounded">
        <h4 className="header-title mb-2">Order Summary</h4>
        <div className="table-responsive">
          <table className="table table-summary mb-0">
            {!isEmpty(cartCalculation) ? (
              <tbody>
                <tr>
                  <td>Sub Total:</td>
                  <td className="text-right">
                    {`${currency}${parseFloat(cartCalculation.subtotal).toFixed(
                      2
                    )}`}
                  </td>
                </tr>
                {cartCalculation.tax_details &&
                  cartCalculation.tax_details.map(taxItem => (
                    <tr>
                      <td>{`${taxItem.name} (${taxItem.tax_rate}%):`}</td>
                      <td className="text-right">
                        {`${currency}${parseFloat(taxItem.amount).toFixed(2)}`}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <th className="pl-0">Gross Total: </th>
                  <th className="text-right pr-0">
                    {`${currency}${parseFloat(
                      cartCalculation.total_amount
                    ).toFixed(2)}`}
                  </th>
                </tr>
                <tr className="font-13">
                  <td>Total B-Point:</td>
                  <td className="text-right">{cartCalculation.total_bp}</td>
                </tr>
              </tbody>
            ) : null}
          </table>
        </div>
      </div>
      {/* <div className="alert alert-warning mt-3" role="alert">
        <span>Use coupon code</span>
        <strong>HYPBM</strong>
        <span>and get 10% discount !</span>
      </div>
      <div className="input-group mt-3">
        <input
          type="text"
          className="form-control form-control-light"
          placeholder="Coupon code"
          aria-label="Recipient's username"
        />
        <div className="input-group-append">
          <button className="btn btn-light" type="button">Apply</button>
        </div>
      </div> */}
    </>
  );
}

const mapStateToProps = state => ({
  cartId: state.cart.cartId,
  cartCalculation: state.cart.cartCalculation,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartTotalDetails: cartActions.getCartTotalDetails
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartPrice);
