/* global appConfig */
import React, { Fragment } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, find, filter } from 'lodash';
import moment from 'moment';
// import { numberToWords } from '../../utills/custom';
import PageLoader from '../../components/PageLoader';
import { app as appActions, order as orderActions } from '../../actions';
import { downloadBlob } from '../../utills/ajax';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import StoreAddress from '../../components/StoreAddress';
import { getOrderStatusClass } from '../../utills/custom';
import PackingSlip from './PackingSlipView';

function ShippingSteps({ data }) {
  const steps = ['Order Placed'];
  let active = 1;
  let shippingDetails = {};
  let count = steps.length;

  const checkSteps = () => {
    // Check Payment
    switch (data.payment_gateway_status) {
      case 'SUCCESS':
        steps.push('Payment Success');
        break;
      case 'SUCCESS-CANCELLED':
      case 'SUCCESS-REFUND':
      case 'SUCCESS-PARTIAL-CANCELLED':
      case 'SUCCESS-PARTIAL-REFUND':
        steps.push('Payment Success');
        break;
      case 'PROCESSING':
        steps.push('Payment Processing');
        break;
      case 'FAILED':
        steps.push('Payment Failed');
        break;
      case 'CANCELLED':
        steps.push('Payment Cancelled');
        break;
      default:
        steps.push('Payment Pending');
        active = 0;
    }

    // Check Cancel/Refund
    if (data.payment_gateway_status === 'SUCCESS-CANCELLED') {
      steps.push('Cancelled');
      active = 2;
    } else if (data.payment_gateway_status === 'SUCCESS-REFUND') {
      if (shippingDetails && shippingDetails.shipping) {
        steps.push('Shipped');
        active += 1;
      }
      if (shippingDetails && shippingDetails.delivered) {
        steps.push('Delivered');
        active += 1;
      }
      steps.push('Refunded');
      active += 1;
    } else if (data.fulfillment_status === 'SHIPPED') {
      steps.push('Shipped');
      steps.push('Delivered');
      active = 2;
    } else if (data.fulfillment_status === 'DELIVERED') {
      steps.push('Shipped');
      steps.push('Delivered');
      active = 3;
    } else {
      steps.push('Shipped');
      steps.push('Delivered');
    }
  };

  if (data && data.user_info) {
    shippingDetails = JSON.parse(data.shipping_delivery_details);
    checkSteps();
    count = steps.length;
  }

  return (
    <div className="row justify-content-center invoice-shipping-status">
      <div className="col-lg-8 col-md-7 col-sm-12 pl-5 pr-5">
        <div className="horizontal-steps mt-4 mb-4 pb-5">
          <div className="horizontal-steps-content">
            {steps.map((item, index) => (
              <div
                key={item}
                className={active === index ? 'step-item current' : 'step-item'}
              >
                <span>{item}</span>
              </div>
            ))}
          </div>
          <div
            className="process-line"
            style={{
              width: `${Math.ceil((100 / (count - 1 || count)) * active)}%`
            }}
          />
        </div>
      </div>
      <div className="col-lg-4 col-md-5 col-sm-12 d-flex flex-column justify-content-center align-items-lg-end align-items-md-end align-items-sm-start">
        {shippingDetails && shippingDetails.shipping ? (
          <div>
            <a
              href={shippingDetails.shipping.tracking_link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mb-3"
            >
              Track Order
            </a>
            <p className="text-muted font-11 pr-4">
              <strong>Shipping Date: </strong>{' '}
              {shippingDetails.shipping.shipped_at}
              <br />
              <strong>Shipping Carrier:</strong>{' '}
              {shippingDetails.shipping.shipping_carrier}
              <br />
              <strong>Carrier Ref:</strong>{' '}
              {shippingDetails.shipping.shipping_carrier_ref}
            </p>
          </div>
        ) : null}
        {shippingDetails && shippingDetails.delivered ? (
          <div>
            <p className="text-muted font-11 pr-4">
              <strong>Delivery Date: </strong>{' '}
              {shippingDetails.delivered.delivery_date}
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function renderAddress(address, type) {
  if (address) {
    return (
      <address className="font-13">
        {address.name}
        <p className="mb-1" />
        {address.street_name_number}
        <br />
        {address.address_line1}
        <br />
        {address.address_line2 ? (
          <>
            {address.address_line2}
            <br />
          </>
        ) : null}
        {address.postcode}
        <br />
        {address.city || address.city_details ? (
          <>
            {address.city_details ? address.city_details.name : address.city}
            <br />
          </>
        ) : null}
        {address.state ? address.state.name : ''}
        <br />
        {address.country.name || address.country}
        <br />
        {`Phone: ${address.mobile_number}`}
      </address>
    );
  }
  return <address className="font-13">N/A</address>;
}

function renderOrderItem(data) {
  if (data.package) {
    const currency = data.currency ? data.currency.currency_symbol : '';
    return (
      <tr>
        <td>
          <b>{data.package.title}</b>
        </td>
        <td>{data.package.ean}</td>
        <td>{data.package.bpoint}</td>
        <td className="text-center">1</td>
        <td className="text-right">{`${currency}${data.subtotal}`}</td>
      </tr>
    );
  }
  return null;
}

function renderTaxBreakdown(data) {
  if (data.order_taxes_shipping_details) {
    const currency = data.currency ? data.currency.currency_symbol : '';
    const taxes = filter(data.order_taxes_shipping_details, ['type', 1]);
    return taxes.map((item, index) => (
      <tr key={`tax_${index}`}>
        <td>
          <b>{`${item.name} (${item.amount}%):`}</b>
        </td>
        <td>{`${currency}${item.total}`}</td>
      </tr>
    ));
  }
  return [];
}

function renderShipping(data) {
  if (data.order_taxes_shipping_details) {
    const currency = data.currency ? data.currency.currency_symbol : '';
    const taxes = filter(data.order_taxes_shipping_details, ['type', 2]);
    return taxes.map((item, index) => {
      const desc = item.name.split('|');
      return (
        <tr key={`ship_${index}`}>
          <td>
            <b className="d-block">Shipping:</b>
            {!data.store_pickup ? (
              <>
                {desc.map(descItem => (
                  <small className="d-block">{descItem}</small>
                ))}
              </>
            ) : null}
          </td>
          {!data.store_pickup ? (
            <td>{`${currency}${item.amount}`}</td>
          ) : (
            <td className="text-success">Pick-up from Store</td>
          )}
        </tr>
      );
    });
  }
  return [];
}

class PackageInvoiceView extends React.Component {
  state = {
    showModal: false
  };

  componentDidMount() {
    const { match, getOrderById } = this.props;
    if (match.params && match.params.id) {
      getOrderById(match.params.id);
    }
  }

  handleSingleDownload = async () => {
    const { order, match } = this.props;
    const orderList = get(order, 'data', []);
    let data = null;
    if (match.params && match.params.id) {
      const dataObj = find(orderList, { id: parseInt(match.params.id, 10) });
      if (dataObj && dataObj.user_info) {
        data = dataObj;
      }
    }
    const url = `${process.env.REACT_APP_API_URL}/subscription/invoice`;
    const response = await axios({
      url,
      method: 'GET',
      params: { id: data.id },
      headers: { Accept: 'application/pdf' },
      responseType: 'blob'
    });
    downloadBlob(
      response,
      `${moment().format('DDMMYYYY')}-${data.invoice_number}.pdf`
    );
  };

  showPackingSlip = () => {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  };

  render() {
    const { order, match, isAdmin } = this.props;
    const { showModal } = this.state;
    const orderList = get(order, 'data', []);
    let data = null;
    let currency = '';
    let shippingAddress = {};
    let billingAddress = {};
    if (match.params && match.params.id) {
      const dataObj = find(orderList, { id: parseInt(match.params.id, 10) });
      if (dataObj && dataObj.user_info) {
        data = dataObj;
        currency = data.currency ? data.currency.currency_symbol : '';
        shippingAddress = find(data.order_address, { type: 'SHIPPING' });
        billingAddress = find(data.order_address, { type: 'BILLING' });
      }
    }

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.invoiceView.breadcrumb} />
              <h4 className="page-title">Invoice</h4>
            </div>
          </div>
        </div>
        <ShippingSteps data={data} />
        <div className="card">
          {data ? (
            <div className="card-body">
              {showModal && (
                <PackingSlip
                  showModal={showModal}
                  showPackingSlip={this.showPackingSlip}
                  data={data}
                  renderAddress={renderAddress}
                  shippingAddress={shippingAddress}
                  billingAddress={billingAddress}
                />
              )}
              <div className="row mb-3">
                <div className="col-sm-9">
                  <img
                    src={appConfig.logo}
                    alt="{appConfig.logoALT}"
                    height="40"
                    className="mb-2"
                  />
                  <StoreAddress country={data.country} />
                </div>
                <div className="col-sm-3">
                  <h3 className="mt-0">{`Invoice #${data.invoice_number}`}</h3>
                  <div className="font-13">
                    <strong>Order Date: </strong>{' '}
                    <span className="float-right">
                      {isAdmin
                        ? data.created_at_tz.replace(/\s.*/, '')
                        : data.order_local_date_tz.replace(/\s.*/, '')}
                    </span>
                  </div>
                  <div className="font-13">
                    <strong>Order Status: </strong>{' '}
                    <span
                      className={`${getOrderStatusClass(
                        data.payment_gateway_status
                      )} float-right`}
                    >
                      {data.payment_gateway_status}
                    </span>
                  </div>
                  <div className="font-13">
                    <strong>Order ID: </strong>{' '}
                    <span className="float-right">{`#${data.id}`}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <h6>
                    {`Hi ${
                      data.user_info
                        ? `${data.user_info.first_name} ${data.user_info.last_name},`
                        : ''
                    }`}
                  </h6>
                  <p className="text-muted font-11 pr-4">
                    {' '}
                    <strong>Associate ID:</strong> {data.user_info.uuid} <br />
                    <strong>Sponsor ID:</strong> {data.sponsor_id} <br />
                    <strong>Email:</strong> {data.user_info.email}
                  </p>
                  <p className="text-muted font-13 pr-4">
                    Thank you for joining as Associate.
                  </p>
                </div>
                <div className="col-sm-3">
                  <h6>Shipping Address</h6>
                  {renderAddress(shippingAddress)}
                </div>
                <div className="col-sm-3">
                  <h6>Billing Address</h6>
                  {renderAddress(billingAddress)}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table mt-4 table-nowrap">
                      <thead>
                        <tr>
                          <th width="50%">Starter Pack</th>
                          <th>EAN 13 number</th>
                          <th>B-Point</th>
                          <th className="text-center">Qty.</th>
                          <th className="text-right">Rate</th>
                        </tr>
                      </thead>
                      <tbody>{renderOrderItem(data)}</tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-sm-6">
                  {isAdmin && (
                    <Fragment>
                      <h6>
                        <small>
                          Product Weight:{' '}
                          <strong>
                            {data.package ? data.package.weight : '0.000'} kg
                          </strong>
                        </small>
                      </h6>
                      <h6>
                        <small>Total Weight: {`______________`}</small>
                      </h6>
                    </Fragment>
                  )}
                </div>
                <div className="col-sm-6">
                  <div className="float-right mt-3 mt-sm-0">
                    <table className="table table-sm text-right">
                      <tbody>
                        <tr>
                          <td>
                            <b>Sub-total:</b>
                          </td>
                          <td>{`${currency}${data.subtotal}`}</td>
                        </tr>
                        {renderShipping(data)}
                        {renderTaxBreakdown(data)}
                        <tr>
                          <td>
                            <b>Gross Total:</b>
                          </td>
                          <td>
                            <h3>{`${currency}${data.total}`}</h3>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="clearfix" />
                </div>
              </div>
              <div className="d-print-none mt-4">
                <div className="text-right">
                  <button
                    type="button"
                    onClick={this.showPackingSlip}
                    className="btn border border-dark font-weight-bold mr-2 view-packing-slip"
                  >
                    View Packing Slip
                  </button>
                  <button
                    type="button"
                    onClick={() => window.print()}
                    className="btn btn-primary mr-2"
                  >
                    <i className="mdi mdi-printer" /> Print
                  </button>
                  <button
                    type="button"
                    onClick={this.handleSingleDownload}
                    className="btn btn-success"
                  >
                    <i className="mdi mdi-download" /> Download
                  </button>
                </div>
              </div>
              <div className="clearfix pt-5 text-center">
                This is a computer generated invoice &amp; hence requires no
                signature.
              </div>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  order: state.order.allList,
  isAdmin: state.auth.isAdmin
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderById: orderActions.getSubscriptionOrderById
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageInvoiceView);
