import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TaxForm from './TaxForm';
import { tax as taxActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class TaxAdd extends React.Component {
  handleSubmit = values => {
    const { createTax } = this.props;
    createTax(values);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.taxAdd.breadcrumb} />
              <h4 className="page-title">{routePath.taxAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card tax_add">
              <div className="card-body">
                <TaxForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                  initialValues={{
                    tax_rate_shipping: false
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.tax.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createTax: taxActions.createTax }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxAdd);
