import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { submit, getFormValues } from 'redux-form';
import Button from 'react-bootstrap/Button';
import PackageCard from './PackageCard';
import InnerLoader from '../../components/InnerLoader';
import {
  app as appActions,
  auth as authActions,
  packages as packageActions
} from '../../actions';
import PaymentForm from './PaymentForm';
import RegisterAddressForm from './RegisterAddressForm';
import LayoutRegister from '../../components/LayoutRegister';
import ZottoPayModal from '../../components/ZottoPayModal';

class Package extends React.Component {
  constructor() {
    super();
    this.state = { step: 3 };
  }

  componentDidMount() {
    const {
      getUserPackages,
      getCountry,
      userInfo,
      getUserInfo,
      getCountryWiseCurrency,
      getPrefetchImageStyle
    } = this.props;
    if (!userInfo || (userInfo && !userInfo.associate_info)) {
      getUserInfo();
    }
    getCountry();
    getUserPackages();
    getCountryWiseCurrency();
    getPrefetchImageStyle();
  }

  changeStep = step => {
    this.setState({ step });
  };

  handleAddressSubmit = () => {
    const { dispatch } = this.props;
    dispatch(submit('registerAddressForm'));
  };

  goToPayment = () => {
    const { pack } = this.props;
    if (pack) {
      this.setState({ step: 4 });
    }
  };

  handleSubmit = values => {
    const { subscribe, formValues, pack } = this.props;
    if (pack) {
      const fieldValues = { ...formValues };
      if (
        fieldValues.city &&
        fieldValues.city === 'others' &&
        fieldValues.otherCity
      ) {
        fieldValues.city = fieldValues.otherCity;
      }
      if (
        fieldValues.shipping_city &&
        fieldValues.shipping_city === 'others' &&
        fieldValues.shipping_otherCity
      ) {
        fieldValues.shipping_city = fieldValues.shipping_otherCity;
      }
      if (fieldValues.mobile_number) {
        fieldValues.mobile_number = fieldValues.mobile_number.replace(
          /\D/g,
          ''
        );
      }
      if (fieldValues.shipping_mobile_number) {
        fieldValues.shipping_mobile_number = fieldValues.shipping_mobile_number.replace(
          /\D/g,
          ''
        );
      }
      subscribe({
        package_id: pack,
        ...fieldValues,
        payment_type: values.payment_type,
        shipping_mode: values.shipping_mode
        /* card_number: values.card_number,
        exp_year: values.exp_year,
        exp_month: values.exp_month,
        cvv: values.cvv */
      });
    }
  };

  render() {
    const { isLoading, packages, pack, formValues, userInfo } = this.props;
    const { step } = this.state;
    // const getPackageClass = () => {
    //   if (packages.length > 4) return 'col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3';
    //   return 'col-xl-6 col-lg-6 col-md-6 col-sm-12  mb-3';
    // };
    return (
      <LayoutRegister step={step}>
        <div className="card-body">
          {step === 3 && (
            <div className="row">
              <div className="col-md-6 border-right-dash">
                <div className="row row-eq-height mb-3">
                  {isLoading ? (
                    <InnerLoader type="table" />
                  ) : (
                    packages.map(item => (
                      <div
                        key={`package_${item.id}`}
                        // className={getPackageClass()}
                        className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3"
                      >
                        <PackageCard data={item} changeStep={this.changeStep} />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="col-md-6">
                {userInfo && userInfo.associate_info ? (
                  <RegisterAddressForm
                    submitForm={this.goToPayment}
                    initialValues={{
                      same_as_billing: true,
                      pickup_from_store: false,
                      country: userInfo.associate_info.country,
                      shipping_country: userInfo.associate_info.country
                    }}
                    destroyOnUnmount={false}
                  />
                ) : null}
              </div>
              <div className="col-12 mt-3">
                <div className="text-center pt-3 border-top">
                  <Button
                    disabled={!pack}
                    onClick={this.handleAddressSubmit}
                    className="btn btn-primary btn-lg pl-5 pr-5"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          )}
          {step === 4 && (
            <PaymentForm
              submitForm={this.handleSubmit}
              changeStep={this.changeStep}
              addressForm={formValues}
              pack={pack}
              selPackage={pack ? _.find(packages, { id: pack }) : false}
              destroyOnUnmount={false}
            />
          )}
        </div>
        <ZottoPayModal />
      </LayoutRegister>
    );
  }
}

const mapStateToProps = state => ({
  packages: state.packages.list,
  pack: state.auth.package,
  userInfo: state.auth.user,
  isLoading: state.packages.isLoading,
  formValues: getFormValues('registerAddressForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserInfo: authActions.getUserInfo,
      getCountry: appActions.getCountry,
      getCountryWiseCurrency: appActions.getCountryWiseCurrency,
      getUserPackages: packageActions.getUserPackages,
      setPackage: authActions.setPackage,
      subscribe: authActions.subscribe,
      getPrefetchImageStyle: appActions.getPrefetchImageStyle,
      dispatch: action => action
      // submitAddressForm: () => submit('registerAddressForm') //if submit required for address form
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Package);
