import { associate as associateActionTypes } from '../actions';
import initialState from '../constants/initialState';

function associate(state = initialState.associate, action) {
  switch (action.type) {
    case associateActionTypes.ASSOCIATE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case associateActionTypes.ASSOCIATE_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          list: action.data
        };
      }
      return state;
    case associateActionTypes.ASSOCIATE_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case associateActionTypes.ASSOCIATE_CREATE_SUCCESS:
    case associateActionTypes.ASSOCIATE_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    default:
      return state;
  }
}

export default associate;
