import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProductForm from './ProductForm';
import BreadCrumb from '../../components/BreadCrumb';
import { product as productActions } from '../../actions';
import routePath from '../../constants/routePath';

class ProductAdd extends React.Component {
  handleSubmit = values => {
    const { createProduct } = this.props;
    const newValues = JSON.parse(JSON.stringify(values));
    // Parse country array
    const countries = [];
    newValues.states = [];
    newValues.countries.forEach(item => {
      item.states.forEach(s => {
        if (!s.inherit) {
          newValues.states.push(s);
        }
      });
      delete item.states;

      if (!item.inherit) {
        countries.push(item);
      }
    });
    newValues.countries = countries;
    delete newValues.priceCountry;

    createProduct(newValues);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.productAdd.breadcrumb} />
              <h4 className="page-title">{routePath.productAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <ProductForm
              submitForm={this.handleSubmit}
              enableReinitialize
              keepDirtyOnReinitialize
              inProgress={createProgress}
              initialValues={{
                file_id: [],
                sold_out: false,
                category_id: [],
                status: false,
                sold_single: false,
                low_stock_alert: 0,
                manage_stock: true,
                tax_id: '',
                display_product: 0,
                member_price: 0,
                customer_price_vat: 0,
                member_price_vat: 0,
                countries: [],
                uncovered_countries: [],
                uncovered_states: []
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.category.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createProduct: productActions.createProduct }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductAdd);
