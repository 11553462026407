const paymentGateway = {
  PAYMENT_GATEWAY_LIST_REQUEST: 'PAYMENT_GATEWAY_LIST_REQUEST',
  PAYMENT_GATEWAY_LIST_SUCCESS: 'PAYMENT_GATEWAY_LIST_SUCCESS',
  PAYMENT_GATEWAY_ALL_LIST_REQUEST: 'PAYMENT_GATEWAY_ALL_LIST_REQUEST',
  PAYMENT_GATEWAY_ALL_LIST_SUCCESS: 'PAYMENT_GATEWAY_ALL_LIST_SUCCESS',
  PAYMENT_GATEWAY_CREATE_REQUEST: 'PAYMENT_GATEWAY_CREATE_REQUEST',
  PAYMENT_GATEWAY_CREATE_SUCCESS: 'PAYMENT_GATEWAY_CREATE_SUCCESS',
  PAYMENT_GATEWAY_CREATE_ERROR: 'PAYMENT_GATEWAY_CREATE_ERROR',
  PAYMENT_GATEWAY_UPDATE_REQUEST: 'PAYMENT_GATEWAY_UPDATE_REQUEST',
  PAYMENT_GATEWAY_UPDATE_SUCCESS: 'PAYMENT_GATEWAY_UPDATE_SUCCESS',
  PAYMENT_GATEWAY_UPDATE_ERROR: 'PAYMENT_GATEWAY_UPDATE_ERROR',
  PAYMENT_GATEWAY_REQUEST: 'PAYMENT_GATEWAY_REQUEST',
  PAYMENT_GATEWAY_SUCCESS: 'PAYMENT_GATEWAY_SUCCESS',
  getAllPaymentGateways: data => ({
    type: paymentGateway.PAYMENT_GATEWAY_ALL_LIST_REQUEST,
    data
  }),
  getPaymentGatewayById: id => ({
    type: paymentGateway.PAYMENT_GATEWAY_REQUEST,
    id
  }),
  createPaymentGateway: data => ({
    type: paymentGateway.PAYMENT_GATEWAY_CREATE_REQUEST,
    data
  }),
  updatePaymentGateway: data => ({
    type: paymentGateway.PAYMENT_GATEWAY_UPDATE_REQUEST,
    data
  })
};

export default paymentGateway;
