import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function PackageViewModal(props) {
  const { handleModal, data } = props;
  return (
    <Modal size="sm" show onHide={handleModal}>
      <Modal.Header closeButton>Preview</Modal.Header>
      <Modal.Body className="card card-pricing mb-0 text-center">
        <div className="card-body">
          <p className="card-pricing-plan-name font-weight-bold text-uppercase">
            {data.title}
          </p>
          <i
            className={`card-pricing-icon text-primary ${
              data.icon ? data.icon : 'fas fa-cube'
            }`}
          />
          {/* <h3 className="card-pricing-price m-0">
            €XXX.xx
          </h3>
          <span className="badge badge-light text-muted">incl. VAT</span> */}
          <div
            className="card-pricing-features"
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PackageViewModal;
