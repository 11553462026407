/* global appConfig */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Scrollbars } from 'react-custom-scrollbars';
import WithSubmenu from './WithSubmenu';
import menu from '../constants/menu';
import menuAssociate from '../constants/menuAssociate';
import routePath from '../constants/routePath';

class LeftSidebar extends React.Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // alert('You clicked outside of me!');
      document.body.classList.remove('sidebar-enable');
    }
  }

  render() {
    const { userInfo } = this.props;
    const permission = get(userInfo, 'permission.permission', {});
    const role = get(userInfo, 'permission.role', []);
    let menuOptions = [];
    if (role.includes('super-admin')) {
      menuOptions = menu;
    } else {
      menuOptions = menuAssociate;
    }
    return (
      <div className="left-side-menu sidebar-dark" ref={this.setWrapperRef}>
        <NavLink className="logo text-center" to={routePath.dashboard.path}>
          <span className="logo-lg">
            <img
              className="logo-img"
              src={appConfig.logoLight}
              alt="{appConfig.logoALT}"
              height="40"
            />
          </span>
        </NavLink>
        <Scrollbars
          autoHide
          autoHeight
          autoHeightMin="80vh"
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              className="metismenu-scroll"
              // style={{
              //   ...style,
              //   backgroundColor: '#8391A2'
              // }}
            />
          )}
        >
          <ul className="metismenu side-nav">
            {menuOptions.map(item => {
              const childItems = [];
              if (item.children) {
                item.children.forEach(subItem => {
                  if (
                    subItem.permission === undefined ||
                    (subItem.permission && permission[subItem.permission])
                  ) {
                    childItems.push(
                      <React.Fragment key={subItem.label}>
                        {subItem.children ? (
                          <WithSubmenu item={subItem} />
                        ) : (
                          <li className="side-nav-item">
                            {/* check if subItem has an external Link , if true return anchor tag with an href 
                            to the external link */
                            subItem.externalLink ? (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                className="side-nav-link"
                                href={subItem.externalLink}
                              >
                                <i className={subItem.icon} />
                                {subItem.label}
                              </a>
                            ) : (
                              <NavLink
                                className="side-nav-link"
                                to={subItem.to}
                                exact
                              >
                                <i className={subItem.icon} />
                                {subItem.label}
                              </NavLink>
                            )}
                          </li>
                        )}
                      </React.Fragment>
                    );
                  }
                });
              }
              if (childItems.length) {
                return (
                  <React.Fragment key={item.label}>
                    {item.section && (
                      <li className="side-nav-title side-nav-item">
                        {item.label}
                      </li>
                    )}
                    {childItems}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </ul>
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user
});

export default connect(
  mapStateToProps,
  {}
)(LeftSidebar);
