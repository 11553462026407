import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, find } from 'lodash';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import Button from 'react-bootstrap/Button';
import InnerLoader, { OverlayLoader } from '../../components/InnerLoader';
import StoreAddress from '../../components/StoreAddress';
import { auth as authActions, app as appActions } from '../../actions';
import ShippingOptions from './ShippingOptions';
import BillingDetails from './BillingDetails';
import ShippingDetails from './ShippingDetails';
import PaymentMode from '../Checkout/PaymentMode';

function PaymentForm({
  getPrefetchStoreAddress,
  getSubscriptionTotalDetails,
  pack,
  dispatch,
  addressForm,
  submitForm,
  paymentType,
  registrationProgress,
  changeStep,
  subscriptionCalculation,
  selPackage,
  appSetting,
  shippingMode,
  calculationProgress,
  userInfo,
  countryCurrencies,
  registerCountry
}) {
  useEffect(() => {
    if (addressForm.shipping_country) {
      getPrefetchStoreAddress(addressForm.shipping_country);
    }
  }, [addressForm.shipping_country]);

  useEffect(() => {
    getSubscriptionTotalDetails({
      package_id: pack,
      pickup_from_store: addressForm.pickup_from_store,
      billing_country: addressForm.country,
      shipping_country: addressForm.same_as_billing
        ? addressForm.country
        : addressForm.shipping_country,
      shipping_mode: shippingMode
    });
  }, [shippingMode]);

  // const handleCardExp = e => {
  //   const expDateValidation = validator.expirationDate(e.target.value);
  //   dispatch(change('registerPaymentForm', 'exp_date', e.target.value));
  //   if (expDateValidation.month) {
  //     dispatch(
  //       change('registerPaymentForm', 'exp_month', expDateValidation.month)
  //     );
  //   }
  //   if (expDateValidation.year) {
  //     dispatch(
  //       change('registerPaymentForm', 'exp_year', expDateValidation.year)
  //     );
  //   }
  // };

  const selectPaymentType = e => {
    dispatch(change('registerPaymentForm', 'payment_type', e.target.value));
  };

  const handleSubmitForm = () => {
    submitForm({
      payment_type: paymentType,
      shipping_mode: shippingMode
    });
  };

  let currency = '';
  if (userInfo && userInfo.associate_info) {
    currency = countryCurrencies[userInfo.associate_info.country]
      ? countryCurrencies[userInfo.associate_info.country].currency_symbol
      : '';
  }

  return (
    <div className="">
      {addressForm && (
        <div className="row row-eq-height">
          <div className="col-sm-6 mb-3">
            <div className="card border border-success h-100 mb-0">
              <div className="card-body p-2">
                <i className="mdi mdi-account-circle float-right font-18 text-success" />
                <h5 className="mt-0">Billing Address</h5>
                <BillingDetails addressForm={addressForm} />
              </div>
            </div>
          </div>
          <div className="col-sm-6 mb-3">
            <div className="card border border-warning h-100 mb-0">
              <div className="card-body p-2">
                {addressForm.pickup_from_store ? (
                  <>
                    <i className="mdi mdi-store float-right font-18 text-warning" />
                    <h5 className="mt-0">Pick-up Store Address</h5>
                    <div className="font-weight-normal font-13">
                      <StoreAddress country={addressForm.country} />
                    </div>
                  </>
                ) : (
                  <>
                    <i className="mdi mdi-truck float-right font-18 text-warning" />
                    <h5 className="mt-0">Shipping Address</h5>
                    <ShippingDetails addressForm={addressForm} />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="border p-2 p-sm-3 rounded">
        <h4 className="header-title mb-3">Order Summary</h4>
        <Field name="shipping_mode" component="input" type="hidden" />
        {!isEmpty(subscriptionCalculation) ? (
          <div className="table-responsive mb-4 position-relative">
            {calculationProgress ? <OverlayLoader /> : null}
            <table className="table table-summary mb-0">
              <tbody>
                <tr className="border-bottom font-weight-bold">
                  <td>Starter Pack:</td>
                  <td className="text-right">
                    {selPackage ? selPackage.title : '---'}
                  </td>
                </tr>
                <tr>
                  <td>Sub Total:</td>
                  <td className="text-right">
                    {`${currency}${subscriptionCalculation.subtotal}`}
                  </td>
                </tr>
                <ShippingOptions currency={currency} />
                {subscriptionCalculation.tax_details &&
                  subscriptionCalculation.tax_details.map(taxItem => (
                    <tr key={taxItem.name}>
                      <td>{`${taxItem.name} (${taxItem.tax_rate}%):`}</td>
                      <td className="text-right">
                        {`${currency}${taxItem.amount}`}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <th className="border-bottom pb-2">Gross Total: </th>
                  <th className="border-bottom text-right pb-2">
                    {`${currency}${subscriptionCalculation.total_amount}`}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <InnerLoader type="table" />
        )}
        <div className="row">
          <div className="col-sm-7">
            <h4 className="header-title mb-1">Payment Options</h4>
            <p className="pt-1">We support Master, Visa and Maestro cards.</p>
          </div>
        </div>
        <PaymentMode
          country={registerCountry}
          value={paymentType}
          onChange={selectPaymentType}
        />
        <div className="text-center mt-3">
          <Button
            variant="outline-primary"
            disabled={!pack}
            onClick={() => changeStep(3)}
            className="mr-2"
          >
            Back
          </Button>
          <Button
            type="button"
            variant="success"
            onClick={handleSubmitForm}
            disabled={registrationProgress || !paymentType}
            className="pl-3 pr-3 pr-sm-4 pl-sm-4"
          >
            {registrationProgress ? 'Please wait...' : ' Pay Now'}
          </Button>
        </div>
      </div>
    </div>
  );
}

const selector = formValueSelector('registerPaymentForm');
const selectorAddr = formValueSelector('registerAddressForm');

const mapStateToProps = state => ({
  registrationProgress: state.auth.registrationProgress,
  calculationProgress: state.auth.calculationProgress,
  appSetting: state.app.settings,
  subscriptionCalculation: state.auth.subscriptionCalculation,
  paymentType: selector(state, 'payment_type'),
  shippingMode: selector(state, 'shipping_mode'),
  registerCountry: selectorAddr(state, 'country'),
  userInfo: state.auth.user,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubscriptionTotalDetails: authActions.getSubscriptionTotalDetails,
      getPrefetchStoreAddress: appActions.getPrefetchStoreAddress,
      dispatch: action => action
    },
    dispatch
  );

const PaymentFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentForm);

export default reduxForm({
  form: 'registerPaymentForm'
})(PaymentFormConnected);
