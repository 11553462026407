import { dashboard as dashboardActionTypes } from '../actions';
import initialState from '../constants/initialState';

function dashboard(state = initialState.dashboard, action) {
  switch (action.type) {
    case dashboardActionTypes.BP_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case dashboardActionTypes.BP_SUCCESS:
      if (action.data) {
        return {
          ...state,
          bp: action.data.bp,
          isLoading: false
        };
      }
      return state;
    case dashboardActionTypes.ASSOCIATE_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case dashboardActionTypes.ASSOCIATE_DASHBOARD_SUCCESS:
      if (action.data) {
        return {
          ...state,
          dashboard: action.data,
          isLoading: false
        };
      }
      return state;
    case dashboardActionTypes.USER_SUMMARY_REQUEST:
      return {
        ...state,
        summary: {},
        isUserSummaryLoading: true
      };
    case dashboardActionTypes.USER_SUMMARY_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isUserSummaryLoading: false,
          userSummary: action.data
        };
      }
      return state;
    case dashboardActionTypes.USER_SUMMARY_ERROR:
      return {
        ...state,
        isUserSummaryLoading: false
      };
    default:
      return state;
  }
}

export default dashboard;
