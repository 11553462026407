import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { startCase, find } from 'lodash';
import LocationForm from './LocationForm';
import {
  location as locationActions,
  currency as currencyActions,
  singleLocation as singleLocationActions
} from '../../actions';
import LinkBack from './LinkBack';

const normaliseData = obj => {
  const { currency_id: currencyId, state_id: stateId } = obj;
  return {
    ...obj,
    currency_id: currencyId && parseInt(currencyId, 10),
    state_id: stateId && parseInt(stateId, 10)
  };
};
class LocationEdit extends React.Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: { type, id }
      }
    } = props;

    this.locationType = type;
    this.locationId = id;
    this.locationList = {};
  }

  componentDidMount() {
    const { getLocationById, getCurrency } = this.props;
    getLocationById({
      type: this.locationType,
      id: this.locationId
    });
    if (this.locationType === 'country') getCurrency();
    this.path = '/location/country';
  }

  handleSubmit = values => {
    const {
      updateLocation,
      match,
      location: {
        data: { state_id: stateId, country_id: countryId }
      },
      currency: { currencies }
    } = this.props;
    const curr = find(currencies, { id: values.currency_id });
    updateLocation({
      id: parseInt(match.params.id, 10),
      type: this.locationType,
      navId: stateId || countryId,
      data: {
        ...values,
        status: values.status ? 1 : 0,
        currency: curr && curr.currency_abbreviation,
        currency_id: curr && curr.id
      },
      redirect: true
    });
  };

  render() {
    const { location, currency } = this.props;
    const { data } = location;
    const { state_id: stateId, country_id: countryId } = data;
    const locationDetail = normaliseData(data);
    const redirectId = this.locationType === 'city' ? stateId : countryId;
    if (this.locationType === 'city' || this.locationType === 'state')
      this.path = `/location/${this.locationType}/${redirectId}`;

    if (data.id)
      return (
        <>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <LinkBack
                  linkItems={[
                    { link: '/location/country', title: 'Location' },
                    {
                      title: 'Edit'
                    }
                  ]}
                />
                <h4 className="page-title">
                  Edit {startCase(this.locationType)}
                </h4>
              </div>
            </div>
          </div>
          {data ? (
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="card location_add">
                  <div className="card-body">
                    <LocationForm
                      submitForm={this.handleSubmit}
                      initialValues={locationDetail}
                      inProgress={location.updateProgress}
                      locationType={this.locationType}
                      currency={currency}
                      path={this.path}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
    return null;
  }
}

const mapStateToProps = state => ({
  location: state.singleLocation,
  currency: state.currency
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateLocation: locationActions.updateLocation,
      getLocationById: singleLocationActions.getSingleLocation,
      getCurrency: currencyActions.getCurrency
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LocationEdit)
);
