import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PackageForm from './PackageForm';
import BreadCrumb from '../../components/BreadCrumb';
import { packages as packageActions } from '../../actions';
import routePath from '../../constants/routePath';

class AssociatePackageAdd extends React.Component {
  uploadedImage = null;

  handleSubmit = values => {
    const { createPackage } = this.props;
    // append image file is uploaded
    let imageParam = {};
    if (values.image_selected) {
      imageParam = { image: this.uploadedImage };
    }
    createPackage({ ...values, ...imageParam });
  };

  handleSelectImage = file => {
    this.uploadedImage = file;
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.associatePackageAdd.breadcrumb} />
              <h4 className="page-title">
                {routePath.associatePackageAdd.title}
              </h4>
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h2 className="pageheader-title">Packages</h2>
              <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link
                        to={routePath.dashboard}
                        className="breadcrumb-link"
                      >
                        <i className="action-icon fas fa-home" />
                      </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link
                        to={routePath.associatePackage}
                        className="breadcrumb-link"
                      >
                        Packages
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Create
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
    </div> */}
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <PackageForm
              submitForm={this.handleSubmit}
              inProgress={createProgress}
              initialValues={{ countries: [] }}
              onSelectImage={this.handleSelectImage}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.packages.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createPackage: packageActions.createPackage }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociatePackageAdd);
