import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get, find, findIndex } from 'lodash';
import TemplateForm from './TemplateForm';
import { template as templateActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';

class TemplateEdit extends React.Component {
  componentDidMount() {
    const { template, match, getTemplateById } = this.props;
    const templateList = get(template, 'data', []);
    if (
      match.params &&
      match.params.id &&
      parseInt(match.params.id, 10) &&
      findIndex(templateList, { id: parseInt(match.params.id, 10) }) < 0
    ) {
      // getTemplateById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateTemplate, match } = this.props;
    updateTemplate({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  render() {
    const { updateProgress, template, match } = this.props;
    const templateList = get(template, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      data = find(templateList, { id: parseInt(match.params.id, 10) });
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.templateEdit.breadcrumb} />
              <h4 className="page-title">{routePath.templateEdit.title}</h4>
            </div>
          </div>
        </div>
        {/* {data ? ( */}
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <TemplateForm
              submitForm={this.handleSubmit}
              initialValues={data}
              inProgress={updateProgress}
            />
          </div>
        </div>
        {/* ) : null} */}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.template.updateProgress,
  template: state.template.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTemplate: templateActions.updateTemplate,
      getTemplateById: templateActions.getTemplateById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TemplateEdit)
);
