import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import SelectedCountryItem from './SelectedCountryItem';

import { product as productActions } from '../../actions';

function CountryField({ fields, countries, convertCurrency }) {
  return (
    <>
      {fields.map((name, index) => {
        const fieldValue = countries[index];

        return (
          <SelectedCountryItem
            key={`country_item_${fieldValue.country}`}
            fieldValue={fieldValue}
            name={name}
          />
        );
      })}
    </>
  );
}

const selector = formValueSelector('productForm');
const mapStateToProps = state => ({
  countries: selector(state, 'countries')
});

export default connect(
  mapStateToProps,
  null
)(CountryField);
