import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { media as mediaActions } from '../../actions';
import { getTypeFromPath } from '../../utills/custom';

class GenerateThumbs extends React.Component {
  state = {
    generateProgress: 0,
    isProcessing: false
  };

  scanImage = () => {
    const { getAllMedia } = this.props;
    this.setState({ generateProgress: 0, isProcessing: false });
    getAllMedia();
  };

  generateThumbs = () => {
    this.setState({ isProcessing: true });
    this.iteratorThumb = this.thumbGenerator();
    this.iteratorThumb.next();
  };

  *thumbGenerator() {
    const { allFileList } = this.props;
    for (let i = 0; i < allFileList.length; i += 1) {
      yield axios({
        url: `${process.env.REACT_APP_API_URL}/tool/generate-thumbnail`,
        method: 'POST',
        data: {
          file_id: allFileList[i].id,
          overwrite: false,
          type: getTypeFromPath(allFileList[i].file_path)
        },
        hideMessage: true
      }).then(() => {
        this.setState(prevState => ({
          generateProgress: prevState.generateProgress + 1
        }));
        this.iteratorThumb.next();
      });
    }
  }

  render() {
    const { isScanning, allFileList } = this.props;
    const { generateProgress, isProcessing } = this.state;
    return (
      <div>
        {!isScanning && allFileList.length ? (
          <div className="row mb-3">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-10">
              <h4 className="header-title">{`Processing ${generateProgress} / ${allFileList.length}`}</h4>
              <div className="progress mb-2">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${100 / (allFileList.length / generateProgress)}%`
                  }}
                  aria-valuenow={generateProgress}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <p className="text-muted font-14 mb-3">
                {allFileList[generateProgress]
                  ? allFileList[generateProgress].file_path
                  : ''}
              </p>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-2">
              <button
                type="button"
                className="btn btn-primary float-right"
                disabled={isProcessing}
                onClick={this.generateThumbs}
              >
                Start
              </button>
            </div>
          </div>
        ) : (
          <h4 className="header-title">No file to generate thumbnail</h4>
        )}
        <button
          type="button"
          className="btn btn-primary float-right"
          disabled={isScanning}
          onClick={this.scanImage}
        >
          Scan Image
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isScanning: state.media.isScanning,
  allFileList: state.media.allFileList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllMedia: mediaActions.getAllMedia
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerateThumbs);
