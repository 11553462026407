import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import FsbView from '../../components/FsbView';
import InnerLoader from '../../components/InnerLoader';
import { fsb as fsbActions } from '../../actions';
import NoResult from '../../components/NoResult';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class FSBPerformanceDetails extends React.Component {
  componentDidMount() {
    const { getFsbPerformanceDetails, getFsb, match } = this.props;
    getFsb();
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getFsbPerformanceDetails(parseInt(match.params.id, 10));
    }
  }

  render() {
    const { isLoading, fsbPlans, performanceDetails, match } = this.props;
    const fsb = fsbPlans.data ? fsbPlans.data[0] : {};
    let data = null;
    if (match.params && match.params.id) {
      data = performanceDetails[match.params.id];
    }
    if (isLoading) {
      return <InnerLoader type="table" />;
    }
    if (fsb && fsb.phase && data && data.fsb_plan_logs) {
      const { user_info: userInfo } = data;
      return (
        <>
          <div className="rofsbPerformanceDetailsw">
            <div className="col-12">
              <div className="page-title-box">
                <BreadCrumb list={routePath.fsbPerformanceDetails.breadcrumb} />

                {userInfo ? (
                  <h4 className="page-title">
                    <span
                      className={`flag-icon flag-icon-${userInfo.country.toLowerCase()} mr-1`}
                    />
                    {`${userInfo.uuid} - ${userInfo.first_name} ${userInfo.last_name}`}
                  </h4>
                ) : null}
              </div>
            </div>
          </div>
          <FsbView
            fsb={fsb}
            associateFsb={data}
            hideFsbTracker
            showUserInfo
            admin
            editable
          />
        </>
      );
    }
    return (
      <NoResult
        title="Only for NEW ASSOCIATES who're signing up."
        body="You're not qualified for the FAST START BONUS plan."
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.fsb.isLoadingPerformanceDetails,
  performanceDetails: state.fsb.performanceDetails,
  fsbPlans: state.fsb.plan
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFsbPerformanceDetails: fsbActions.getFsbPerformanceDetails,
      getFsb: fsbActions.getFsb
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(FSBPerformanceDetails)
);
