import React from 'react';
import { Field, Form, reduxForm, change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { required, number } from '../../utills/validator';
import { inputField, selectField, switchField } from '../../components/Fields';
import { app as appActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';
import routePath from '../../constants/routePath';

class TaxForm extends React.Component {
  state = {
    disableState: false,
    disableCity: false
  };

  componentDidMount() {
    const {
      getCountry,
      getCityByState,
      getStateByCountry,
      initialValues
    } = this.props;
    // Fetch state and city list
    getCountry();
    if (
      initialValues.tax_rate_country &&
      initialValues.tax_rate_country !== '*'
    ) {
      getStateByCountry(initialValues.tax_rate_country);
    }
    if (initialValues.tax_rate_state && initialValues.tax_rate_state !== '*') {
      getCityByState(initialValues.tax_rate_state);
    }
  }

  countryChange = val => {
    const { dispatch, getStateByCountry } = this.props;
    if (val === '*') {
      dispatch(change('taxForm', 'tax_rate_city', '*'));
      dispatch(change('taxForm', 'tax_rate_state', '*'));
      this.setState({ disableState: true, disableCity: true });
    } else if (val) {
      dispatch(change('taxForm', 'tax_rate_city', ''));
      dispatch(change('taxForm', 'tax_rate_state', ''));
      this.setState({ disableState: false, disableCity: false });
      getStateByCountry(val);
    }
  };

  stateChange = val => {
    const { dispatch, getCityByState } = this.props;
    if (val === '*') {
      dispatch(change('taxForm', 'tax_rate_city', '*'));
      this.setState({ disableCity: true });
    } else if (val) {
      dispatch(change('taxForm', 'tax_rate_city', ''));
      this.setState({ disableCity: false });
      getCityByState(val);
    }
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      countryList,
      stateList,
      cityList
    } = this.props;
    const { disableState, disableCity } = this.state;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <Field
          name="tax_rate_name"
          component={inputField}
          type="text"
          className="form-control"
          label="Name"
          validate={[required]}
        />
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="tax_rate_country"
              id="tax_rate_country"
              component={selectField}
              label="Country"
              feedback={this.countryChange}
              options={[{ value: '*', label: '*' }, ...countryList]}
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field
              name="tax_rate_state"
              id="tax_rate_state"
              component={selectField}
              label="State"
              feedback={this.stateChange}
              options={[{ value: '*', label: '*' }, ...stateList]}
              disabled={disableState}
              validate={[required]}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="tax_rate_postcode"
              component={inputField}
              type="text"
              className="form-control"
              label="Postcode/ZIP"
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field
              name="tax_rate_city"
              id="tax_rate_city"
              component={selectField}
              label="City"
              options={[{ value: '*', label: '*' }, ...cityList]}
              disabled={disableCity}
              validate={[required]}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="tax_rate"
              component={inputField}
              type="text"
              className="form-control"
              label="Rate(%)"
              validate={[required, number]}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="tax_rate_shipping"
              component={switchField}
              label="Shipping"
              id="tax_rate_shipping"
              type="success"
            />
          </div>
        </div>
        <Button
          as={Link}
          to={routePath.tax.path}
          className="btn btn-light mr-2"
        >
          Cancel
        </Button>
        <button type="submit" disabled={inProgress} className="btn btn-primary">
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </button>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  cityList: state.app.cityList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState
    },
    dispatch
  );

const TaxFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxForm);

export default reduxForm({
  form: 'taxForm' // a unique identifier for this form
})(TaxFormWithRedux);
