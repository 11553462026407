import React from 'react';
import { Card } from 'react-bootstrap';
import { OverlayLoader } from './InnerLoader';
import getDuration from '../utills/getDuration';

function TurnoverBox({
  isLoading,
  title,
  prevVat,
  prevRevenue,
  vat,
  revenue,
  filter,
  start,
  end
}) {
  const { start: startDate, end: endDate } = getDuration(filter, start, end);
  const quantityFeedback =
    Number(vat.split(',').join('')) >= Number(prevVat.split(',').join(''))
      ? 'text-success'
      : 'text-danger';
  const revenueFeedback =
    Number(revenue.split(',').join('')) >=
    Number(prevRevenue.split(',').join(''))
      ? 'text-success'
      : 'text-danger';
  return (
    <Card className="mb-1 h-100 stat-card">
      {isLoading && <OverlayLoader type="spinner" />}
      <div className="stat-row pl-2 pr-2 pt-2">
        <div>
          <div className="stat-title font-weight-bold text-uppercase">
            {title}
          </div>
          <div className="stat-title text-uppercase">
            {startDate} TO {endDate}
          </div>
          <div className={`${revenueFeedback} stat-highlight`}>{revenue}</div>
        </div>
        <i className="mdi mdi-scale float-right widget widget-icon-circle" />
      </div>
      <div className="stat-row pl-2 pr-2 pb-2">
        <div>
          <div className="stat-filter font-weight-semibold text-uppercase">
            Revenue
          </div>
          <div className={`${revenueFeedback} stat-figure`}>{prevRevenue}</div>
        </div>
        <div>
          <div className="stat-filter font-weight-semibold text-uppercase">
            Items sold
          </div>
          <div className={`${quantityFeedback} stat-figure`}>
            {prevVat} <i className="mdi mdi-arrow-right-bold" />
            {vat}
          </div>
        </div>
      </div>
    </Card>
  );
}

export default TurnoverBox;
