import React from 'react';

export default function TreeNav(props) {
  const {
    limit,
    page,
    totalPages,
    referrals,
    parentId,
    refId,
    topId,
    nodePagination,
    gotoParentTree
  } = props;
  return (
    <div className="row mb-2">
      {referrals > limit ? (
        <div className="col">
          <button
            type="button"
            className={`btn btn-light btn-rounded m-auto ${
              page === 0 ? 'disabled' : ''
            }`}
            onClick={() => nodePagination('back')}
          >
            <i className="dripicons-chevron-left " />
            Prev
            {page !== 0 && ` (${limit})`}
          </button>
        </div>
      ) : null}
      {parentId && parentId !== refId && refId !== topId ? (
        <div className="col text-center">
          <button
            type="button"
            className="btn btn-link m-auto"
            onClick={() => gotoParentTree(parentId)}
          >
            <i className="dripicons-chevron-up " /> Go to previous level
          </button>
        </div>
      ) : null}
      {referrals > limit ? (
        <div className="col text-right">
          <button
            type="button"
            className={`btn btn-primary btn-rounded m-auto ${
              page === totalPages - 1 ? 'disabled' : ''
            }`}
            onClick={() => nodePagination('next')}
          >
            Next
            {page < totalPages - 2 && ` (${limit})`}
            {page === totalPages - 2 && ` (${referrals - limit * (page + 1)})`}
            <i className="dripicons-chevron-right" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
