import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get, find, findIndex } from 'lodash';
import RankForm from './RankForm';
import { rank as rankActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';

class RankEdit extends React.Component {
  componentDidMount() {
    const { rank, match, getRankById } = this.props;
    const rankList = get(rank, 'data', []);
    if (
      match.params &&
      match.params.id &&
      parseInt(match.params.id, 10) &&
      findIndex(rankList, { id: parseInt(match.params.id, 10) }) < 0
    ) {
      getRankById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateRank, match } = this.props;
    updateRank({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  render() {
    const { updateProgress, rank, match } = this.props;
    const rankList = get(rank, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      data = find(rankList, { id: parseInt(match.params.id, 10) });
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.rankEdit.breadcrumb} />
              <h4 className="page-title">{routePath.rankEdit.title}</h4>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card rank_add">
                <div className="card-body">
                  <RankForm
                    submitForm={this.handleSubmit}
                    initialValues={data}
                    inProgress={updateProgress}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.rank.updateProgress,
  rank: state.rank.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateRank: rankActions.updateRank,
      getRankById: rankActions.getRankById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RankEdit)
);
