import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GenerationForm from './GenerationForm';
import { generation as generationActions } from '../../actions';
import routePath from '../../constants/routePath';

class GenerationAdd extends React.Component {
  handleSubmit = values => {
    const { createGeneration } = this.props;
    createGeneration(values);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Generation</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link
                    to={routePath.dashboard.path}
                    className="breadcrumb-link"
                  >
                    <i className="action-icon fas fa-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={routePath.generation.path}
                    className="breadcrumb-link"
                  >
                    Generation
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card generation_add">
              <h5 className="card-header">Create Generation</h5>
              <div className="card-body">
                <GenerationForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.generation.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { createGeneration: generationActions.createGeneration },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenerationAdd);
