import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { customerTree as treeActions } from '../../actions';
import { getMonthOption } from '../../utills/custom';

function MenuItem({ user }) {
  return (
    <>
      <span
        className={`flag-icon flag-icon-${user.uuid
          .slice(0, 2)
          .toLowerCase()} mr-1`}
      />
      <span>{`${user.uuid} - ${user.first_name} ${user.last_name}`}</span>
    </>
  );
}

class TreeFilterForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.monthOption = getMonthOption();
    // Add till date option
    this.monthOption.splice(0, 0, { label: 'Lifetime', value: '' });
    this.state = {
      selectedMonth: {
        label: 'Lifetime',
        value: ''
      }
    };
  }

  formatCustomers = async (input, callback) => {
    if (input) {
      const { selectedMonth } = this.state;
      const [mm, yy] = selectedMonth.value.split(',');
      try {
        const result = await axios({
          url: `${process.env.REACT_APP_API_URL}/customer/search`,
          method: 'GET',
          params: { user: input, mm, yy }
        });
        if (result && result.customers) {
          const { customers } = result;
          callback(
            customers.map(user => {
              return {
                label: <MenuItem user={user} />,
                value: user.id
              };
            })
          );
        } else {
          callback([]);
        }
      } catch (error) {
        callback([]);
      }
    }
  };

  handleMonthChange = val => {
    const { submitForm } = this.props;
    this.setState({ selectedMonth: val });
    submitForm(undefined, val);
  };

  handleSelectSearch = input => {
    const { submitForm } = this.props;
    const { selectedMonth } = this.state;
    submitForm(input, selectedMonth);
  };

  render() {
    const { loadingCustomer, hideSearch } = this.props;
    const { selectedMonth } = this.state;
    return (
      <div className="row">
        <div className="col-12 col-md-4">
          <Select
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select Month"
            isClearable={false}
            isSearchable
            name="month_picker"
            value={selectedMonth}
            options={this.monthOption}
            onChange={this.handleMonthChange}
          />
        </div>
        {hideSearch ? null : (
          <div className="col-12 col-md-8">
            <AsyncSelect
              cacheOptions
              loadOptions={this.formatCustomers}
              defaultOptions
              isSearchable
              isClearable
              isLoading={loadingCustomer}
              onChange={this.handleSelectSearch}
              autofocus
              placeholder="Search by Customer Name or ID"
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('treeFilterForm')(state),
  customers: state.tree.customers,
  loadingCustomer: state.tree.loadingCustomer
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomerTree: treeActions.getCustomerTree
    },
    dispatch
  );

const TreeFilterFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TreeFilterForm);

export default reduxForm({
  form: 'treeFilterForm' // a unique identifier for this form
})(TreeFilterFormConnected);
