import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Image from '../../components/Image';
import { productImage } from '../../constants/imageSize';
import img from '../../assets/images/img-placeholder.jpg';
import { getAssocProductPrice } from '../../utills/custom';

function UnavailableTag({ status, soldout }) {
  if (!status) {
    return (
      <tr className="cart-item-sold-out">
        <td colSpan="6" className="pb-0 font-13 font-weight-bold">
          <i className="dripicons-wrong mr-2" />
          Item no longer available! Please remove from cart.
        </td>
      </tr>
    );
  }
  if (soldout) {
    return (
      <tr className="cart-item-sold-out">
        <td colSpan="6" className="pb-0 font-13 font-weight-bold">
          <i className="dripicons-warning mr-2" />
          Temporarily out of stock! Please remove from cart.
        </td>
      </tr>
    );
  }
  return null;
}

function CartItem({
  data,
  country,
  updateCart,
  deleteCart,
  countryCurrencies
}) {
  const [quantity, setQuantity] = useState(data.quantity);

  useEffect(() => {
    setQuantity(data.quantity);
  }, [data.quantity]);

  function handleQtyChange(e) {
    setQuantity(+e.target.value);
    updateCart(data.id, {
      cart_id: data.cart_id,
      quantity: +e.target.value
    });
  }

  function handleDelete() {
    deleteCart(data.id);
  }

  let pimg = get(data, 'product.product_images[0].file.file_path', null);
  if (pimg) {
    pimg = `${process.env.REACT_APP_IMAGE_URL}/${pimg}`;
  } else {
    pimg = img;
  }
  const currency = countryCurrencies[country]
    ? countryCurrencies[country].currency_symbol
    : '';
  return (
    <>
      <UnavailableTag
        soldout={data.product.sold_out}
        status={data.product.status}
      />
      <tr
        key={`cart_item_${data.id}`}
        className={
          data.product.sold_out || !data.product.status
            ? 'cart-item-sold-out mb-1'
            : ''
        }
        style={{
          borderBottom:
            data.product.sold_out || !data.product.status
              ? '2px solid #ffffff'
              : 'none'
        }}
      >
        <td>
          <span className="img_holder">
            <Image
              src={pimg}
              alt={data.product.title}
              className="rounded"
              size={productImage.productAdmin}
              height="64"
            />
          </span>
        </td>
        <td>
          <p className="align-middle">
            <Link
              to={`/shop/product/${data.product.slug}`}
              className="text-body font-weight-semibold"
            >
              {data.product.title}
            </Link>
            <br />
            {/* <small className="mr-2">
              <b>SKU:</b>
              <span>12345</span>
            </small> */}
          </p>
        </td>
        <td>{`${currency}${data.product.member_price_vat} `}</td>
        <td>
          <input
            type="number"
            min="1"
            value={quantity}
            onChange={handleQtyChange}
            className="form-control px-1 text-center"
            placeholder="Qty"
            disabled={data.product.sold_out || !data.product.status}
          />
        </td>
        <td>
          {`${currency}${(
            data.quantity * getAssocProductPrice(data.product)
          ).toFixed(2)}`}
        </td>
        <td>
          <button
            type="button"
            className="btn action-icon"
            onClick={handleDelete}
          >
            <i className="mdi mdi-close-circle" />
          </button>
        </td>
      </tr>
    </>
  );
}

const mapStateToProps = state => ({
  countryCurrencies: state.app.countryCurrencies
});

export default connect(
  mapStateToProps,
  null
)(CartItem);
