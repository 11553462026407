import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { setDefaultAddress } from '../../services/apiService';
import { address as addressActionTypes } from '../../actions';

function SetDefaultButton({ id, getAllAddresses }) {
  const [processing, setProcessing] = useState(false);
  const handleSetDefault = async () => {
    try {
      if (id) {
        setProcessing(true);
        await setDefaultAddress(id);
        getAllAddresses();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button
      type="button"
      className="btn btn-link p-0 card-link text-custom font-12"
      onClick={handleSetDefault}
      disabled={processing}
    >
      {processing ? 'Processing...' : 'Set default'}
    </button>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllAddresses: addressActionTypes.getAllAddresses
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(SetDefaultButton);
