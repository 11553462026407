const paymentOption = {
  PAYMENT_OPTION_ALL_CARD_LIST_REQUEST: 'PAYMENT_OPTION_ALL_CARD_LIST_REQUEST',
  PAYMENT_OPTION_ALL_CARD_LIST_SUCCESS: 'PAYMENT_OPTION_ALL_CARD_LIST_SUCCESS',
  PAYMENT_OPTION_ALL_CARD_LIST_ERROR: 'PAYMENT_OPTION_ALL_CARD_LIST_ERROR',
  PAYMENT_OPTION_ALL_BANK_LIST_REQUEST: 'PAYMENT_OPTION_ALL_BANK_LIST_REQUEST',
  PAYMENT_OPTION_ALL_BANK_LIST_SUCCESS: 'PAYMENT_OPTION_ALL_BANK_LIST_SUCCESS',
  PAYMENT_OPTION_ALL_BANK_LIST_ERROR: 'PAYMENT_OPTION_ALL_BANK_LIST_ERROR',

  PAYMENT_OPTION_CREATE_REQUEST: 'PAYMENT_OPTION_CREATE_REQUEST',
  PAYMENT_OPTION_CREATE_SUCCESS: 'PAYMENT_OPTION_CREATE_SUCCESS',
  PAYMENT_OPTION_CREATE_ERROR: 'PAYMENT_OPTION_CREATE_ERROR',
  PAYMENT_OPTION_UPDATE_REQUEST: 'PAYMENT_OPTION_UPDATE_REQUEST',
  PAYMENT_OPTION_UPDATE_SUCCESS: 'PAYMENT_OPTION_UPDATE_SUCCESS',
  PAYMENT_OPTION_UPDATE_ERROR: 'PAYMENT_OPTION_UPDATE_ERROR',
  PAYMENT_OPTION_SHOW_ALERT: 'PAYMENT_OPTION_SHOW_ALERT',
  PAYMENT_OPTION_HIDE_ALERT: 'PAYMENT_OPTION_HIDE_ALERT',
  PAYMENT_OPTION_DELETE_REQUEST: 'PAYMENT_OPTION_DELETE_REQUEST',
  PAYMENT_OPTION_DELETE_SUCCESS: 'PAYMENT_OPTION_DELETE_SUCCESS',
  PAYMENT_OPTION_DELETE_ERROR: 'PAYMENT_OPTION_DELETE_ERROR',
  PAYMENT_OPTION_REQUEST: 'PAYMENT_OPTION_REQUEST',
  PAYMENT_OPTION_SUCCESS: 'PAYMENT_OPTION_SUCCESS',
  PAYMENT_OPTION_ERROR: 'PAYMENT_OPTION_ERROR',
  PAYMENT_OPTION_SHOW_MODAL: 'PAYMENT_OPTION_SHOW_MODAL',
  PAYMENT_OPTION_HIDE_MODAL: 'PAYMENT_OPTION_HIDE_MODAL',
  getAllPaymentOption: type => ({
    type: paymentOption[`PAYMENT_OPTION_ALL_${type}_LIST_REQUEST`]
  }),
  createPaymentOption: data => ({
    type: paymentOption.PAYMENT_OPTION_CREATE_REQUEST,
    data
  }),
  updatePaymentOption: data => ({
    type: paymentOption.PAYMENT_OPTION_UPDATE_REQUEST,
    data
  }),
  getPaymentOptionById: id => ({
    type: paymentOption.PAYMENT_OPTION_REQUEST,
    id
  }),
  showModal: data => ({ type: paymentOption.PAYMENT_OPTION_SHOW_MODAL, data }),
  hideModal: data => ({ type: paymentOption.PAYMENT_OPTION_HIDE_MODAL, data }),
  showAlert: data => ({ type: paymentOption.PAYMENT_OPTION_SHOW_ALERT, data }),
  hideAlert: data => ({ type: paymentOption.PAYMENT_OPTION_HIDE_ALERT, data }),
  deletePaymentOption: data => ({
    type: paymentOption.PAYMENT_OPTION_DELETE_REQUEST,
    data
  })
};

export default paymentOption;
