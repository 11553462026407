import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CartPrice from './CartPrice';
import BreadCrumb from '../../components/BreadCrumb';
import CartItem from './CartItem';
import routePath from '../../constants/routePath';
import { cart as cartActions } from '../../actions';
import history from '../../services/historyService';

class Cart extends React.Component {
  checkDisabled = false;

  componentDidMount() {
    const { getCartDetails } = this.props;
    getCartDetails();
  }

  handleUpdateCart = (id, data) => {
    const { updateCart } = this.props;
    updateCart({ id, data });
  };

  handleDeleteCart = id => {
    const { deleteCart } = this.props;
    deleteCart(id);
  };

  goToCheckout = () => {
    history.push(routePath.checkout.path);
  };

  renderCartItems() {
    const { cartDetails } = this.props;
    let disabled = false;
    const cartItems = cartDetails.items.map(cartData => {
      if (cartData.product.sold_out || !cartData.product.status) {
        disabled = true;
      }
      return (
        <CartItem
          data={cartData}
          country={cartDetails.country}
          key={`cart_item_${cartData.id}`}
          deleteCart={this.handleDeleteCart}
          updateCart={this.handleUpdateCart}
        />
      );
    });
    this.checkDisabled = disabled;
    return cartItems;
  }

  render() {
    const { cartDetails } = this.props;
    return (
      <React.Fragment>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.cart.breadcrumb} />
              <h4 className="page-title">{routePath.cart.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="table-responsive mb-3">
                      {cartDetails && cartDetails.items ? (
                        <table className="table table-borderless table-centered mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th colSpan="2">Product</th>
                              <th>Price</th>
                              <th width="3%">Quantity</th>
                              <th>Total</th>
                              <th className="no-min-wd" />
                            </tr>
                          </thead>
                          <tbody>{this.renderCartItems()}</tbody>
                        </table>
                      ) : null}
                    </div>
                    <div className="row text-center text-xs-left text-sm-left">
                      <div className="col-xs-6 col-sm-6">
                        <Link
                          to={routePath.shop.path}
                          className="btn btn-light font-weight-semibold mb-3 mb-sm-0"
                        >
                          <i className="mdi mdi-arrow-left m-1" />
                          <span>Back to Shop</span>
                        </Link>
                      </div>
                      <div className="col-xs-6 col-sm-6">
                        <div className="text-xs-right text-sm-right">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.goToCheckout}
                            disabled={this.checkDisabled}
                          >
                            <i className="mdi mdi-cart-plus mr-1" />
                            <span>Checkout</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <CartPrice country={cartDetails.country} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  cartCalculation: state.cart.cartCalculation
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartDetails: cartActions.getCartDetails,
      updateCart: cartActions.updateCart,
      deleteCart: cartActions.deleteCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cart);
