import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { map } from 'lodash';
import { formValueSelector, submit } from 'redux-form';
import AdvanceTable from '../../components/AdvanceTable';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import InnerLoader from '../../components/InnerLoader';
import DownlineAssociateForm from './DownlineAssociateForm';
import { order as orderActions, tree as treeActions } from '../../actions';
import { getCurrencySymbol, getOrderStatusClass } from '../../utills/custom';

class DownlineAssociateOrder extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getDownlineAssociate } = this.props;
    getDownlineAssociate({
      dispatchDownlineOrder: true
    });
  }

  filterHandler = val => {
    const { dispatch } = this.props;
    this.setState({ selected: val }, () => {
      dispatch(submit('downlineAssociateForm'));
    });
  };

  submitHandler = values => {
    const { getAllOrders, associates } = this.props;
    const { page, selected } = this.state;
    this.setState({ page: 1 });
    getAllOrders({
      orderType: 'associate',
      ids: selected.length ? selected : [],
      page: 1
    });
  };

  handleTableChange = (type, { page }) => {
    const { getAllOrders, associates } = this.props;
    const { selected } = this.state;
    if (type === 'pagination') {
      getAllOrders({
        orderType: 'associate',
        ids: selected.length ? selected : [],
        page
      });
    }
    this.setState(prevState => ({ page }));
  };

  render() {
    const statusOptions = [
      { value: 'Status1', label: 'Status 1' },
      { value: 'Status2', label: 'Status 2' },
      { value: 'Status3', label: 'Status 3' },
      { value: 'Status4', label: 'Status 4' }
    ];
    const {
      orders,
      associates,
      isLoading,
      appSetting,
      isDownlineLoading,
      filterValues
    } = this.props;
    const { page, selected } = this.state;
    const columns = [
      {
        dataField: 'invoice_number',
        text: 'Inv. no.',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link
                to={`/order/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            ) : (
              <Link
                to={`/subscription/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            )}
          </>
        )
      },
      {
        dataField: 'created_at',
        text: 'Date',
        // headerClasses: 'text-center',
        // classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: col => (
          <span className="date">
            {moment(col).format(appSetting.site_date_format)}
            <br />
            <small>{moment(col).format('H:mm A')}</small>
          </span>
        )
      },
      {
        dataField: 'total',
        text: 'Total Price',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: col => (
          <span className="font-weight-semibold text-primary">
            {`${getCurrencySymbol(appSetting.def_currency)}${col}`}
          </span>
        )
      },
      {
        dataField: 'type',
        text: 'Order Type',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) =>
          !row.cart_id ? (
            <span className="badge badge-info">STARTER PACK</span>
          ) : null
      },
      {
        dataField: 'uuid',
        text: 'ID',
        formatter: (col, row) => row.user_info && row.user_info.uuid
      },
      {
        dataField: 'user_info',
        text: 'Full Name',
        formatter: col =>
          col && col.first_name && `${col.first_name} ${col.last_name}`
      },
      {
        dataField: 'method',
        // headerAttrs: { width: '15%' },
        text: 'Transaction ID',
        formatter: (col, row) => (
          <span>
            {row.order_transaction && row.order_transaction[0]
              ? row.order_transaction[0].transaction_id
              : '---'}
          </span>
        )
      },
      {
        dataField: 'payment_gateway_status',
        text: 'Payment Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '15%' },
        formatter: col => (
          <span className={getOrderStatusClass(col)}>{col}</span>
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '8%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link to={`/order/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" />
              </Link>
            ) : (
              <Link to={`/subscription/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" />
              </Link>
            )}
            {row.cart_id ? (
              <a
                href={`${process.env.REACT_APP_API_URL}/product/invoice?id=${row.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="action-icon mdi mdi-file-pdf" />
              </a>
            ) : (
              <a
                href={`${process.env.REACT_APP_API_URL}/subscription/invoice?id=${row.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="action-icon mdi mdi-file-pdf" />
              </a>
            )}
            {/* <Link to={`/order/${row.order_number}/delete`}><i className="action-icon mdi mdi-trash-can" /></Link> */}
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.downlineAssociateOrder.breadcrumb} />
              <h4 className="page-title">
                {routePath.downlineAssociateOrder.title}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isDownlineLoading ? (
                  <InnerLoader type="table" />
                ) : (
                  <>
                    <DownlineAssociateForm
                      associateList={associates}
                      submitForm={this.submitHandler}
                      filterHandler={this.filterHandler}
                      // initialValues={{ associate: downlineDropdownValues(associates) }}
                    />
                    <AdvanceTable
                      keyField="id"
                      data={orders.data}
                      columns={columns}
                      loading={isLoading}
                      remote={{ pagination: true }}
                      totalSize={orders.total}
                      sizePerPage={orders.per_page}
                      page={page}
                      onTableChange={this.handleTableChange}
                      sizePerPageList={filterValues}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  orders: state.order.allList,
  associates: state.tree.allList,
  appSetting: state.app.settings,
  isLoading: state.order.isLoading,
  isDownlineLoading: state.tree.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllOrders: orderActions.getAllOrders,
      getDownlineAssociate: treeActions.getDownlineAssociate,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownlineAssociateOrder);
