import React from 'react';
import {
  Field,
  FieldArray,
  Form as FormRedux,
  getFormValues,
  reduxForm,
  change
} from 'redux-form';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { number } from '../../utills/validator';
import {
  inputField,
  inputGroupField,
  textareaField,
  selectField
} from '../../components/Fields';
import CouponCode from './CouponCode';
import routePath from '../../constants/routePath';
import btnLoader from '../../assets/images/btn-loader.gif';

const MenuItem = ({ user }) => {
  return (
    <>
      <span
        className={`flag-icon flag-icon-${user.uuid
          .slice(0, 2)
          .toLowerCase()} mr-1`}
      />
      <span>{`${user.uuid} - ${user.name}`}</span>
    </>
  );
};

class CouponForm extends React.Component {
  state = {
    prefix: '',
    visibility: '',
    percentage: null,
    countryList: []
  };

  componentDidMount() {
    const { dispatch, storeCountries, formValues } = this.props;
    const options = [];
    if (window.location.pathname === '/coupons/add') {
      dispatch(change('couponForm', 'code', [{}]));
      dispatch(change('couponForm', 'user_id', []));
      dispatch(change('couponForm', 'visibility', 0));
      dispatch(change('couponForm', 'max_discount_allowed', '0.00'));
    } else if (formValues.type && formValues.type === 1) {
      this.setState({ percentage: false });
    }

    if (storeCountries !== undefined && Array.isArray(storeCountries)) {
      storeCountries.forEach(item => {
        options.push({
          label: item.store_country,
          value: item.store_country_iso2
        });
      });
    }

    this.setState({ countryList: options });
  }

  countryChange = val => {
    this.setState({ prefix: val });
  };

  getUsers = val => {
    if (val === 1) {
      this.setState({ visibility: 'ASSOCIATE' });
    } else if (val === 2) {
      this.setState({ visibility: 'CUSTOMER' });
    }
  };

  loadOptions = async (search, loadedOptions, { page }) => {
    const { visibility } = this.state;
    const users = [];
    let response;

    try {
      response = await axios({
        url: `${process.env.REACT_APP_API_URL}/users`,
        method: 'GET',
        params: { role: visibility, page },
        json: true
      });
      if (response) {
        response.data.forEach(item => {
          users.push({
            label: <MenuItem user={item} />,
            value: item.id,
            searchValue: item.uuid
          });
        });
      }
    } catch (err) {
      console.log(err);
    }

    let filteredUsers = [];
    if (!search) {
      filteredUsers = users;
    } else {
      const searchLower = search.toLowerCase();
      filteredUsers = users.filter(({ searchValue }) => {
        return searchValue.toLowerCase().includes(searchLower);
      });
    }

    return {
      options: filteredUsers,
      hasMore: page < response.last_page,
      additional: { page: page + 1 }
    };
  };

  getType = val => {
    const { dispatch } = this.props;
    const { percentage } = this.state;
    if (val === 2) {
      this.setState({ percentage: true });
    } else {
      dispatch(change('couponForm', 'max_discount_allowed', '0.00'));
      if (percentage === true || percentage === null) {
        this.setState({ percentage: false });
      }
    }
  };

  handleUserSelect = val => {
    const { dispatch } = this.props;
    const userId = val && val.map(item => item.value);
    dispatch(change('couponForm', 'user_id', userId));
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      formValues,
      countryCurrencies
    } = this.props;

    const { prefix, percentage, visibility, countryList } = this.state;
    const isDisabled = window.location.pathname !== '/coupons/add' && true;
    const index =
      window.location.pathname === '/coupons/add' ? prefix : formValues.country;

    const currency = countryCurrencies[index]
      ? countryCurrencies[index].currency_symbol
      : '€';

    return (
      <FormRedux
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="country"
              id="country"
              component={selectField}
              disabled={isDisabled}
              label="Country"
              feedback={this.countryChange}
              options={[...countryList]}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field
              name="title"
              component={inputField}
              type="text"
              className="form-control"
              label="Title"
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="description"
              component={inputField}
              type="text"
              className="form-control"
              label="Description"
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="start_at"
              component={inputGroupField}
              className="form-control"
              placeholder="25/01/2022 21:30"
              label="Start Date (DD/MM/YYYY HH:MM)"
            >
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-calendar" />
                </span>
              </div>
            </Field>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="end_at"
              component={inputGroupField}
              className="form-control"
              placeholder="31/01/2022 09:30"
              label="End Date (DD/MM/YYYY HH:MM)"
            >
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-calendar" />
                </span>
              </div>
            </Field>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Field
              name="method"
              id="method"
              component={selectField}
              label="Method"
              options={[
                { value: 'VOUCHER', label: 'Voucher' },
                { value: 'COUPON', label: 'Coupon' }
              ]}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Field
              name="type"
              id="type"
              component={selectField}
              feedback={this.getType}
              label="Type"
              options={[
                { value: 1, label: 'Flat' },
                { value: 2, label: 'Percentage' }
              ]}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Field
              name="discount"
              component={inputField}
              type="number"
              className="form-control"
              label={`Discount (${percentage === true ? '%' : currency})`}
              validate={[number]}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="max_discount_allowed"
              component={inputField}
              type="number"
              disabled={percentage === false && true}
              className="form-control"
              label="Max Discount Allowed"
              validate={[number]}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="min_cart_amount"
              component={inputField}
              type="number"
              className="form-control"
              label="Min Cart Amount"
              validate={[number]}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="max_usage"
              component={inputField}
              type="number"
              className="form-control"
              label="Max Usage"
              validate={[number]}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="max_usage_per_user"
              component={inputField}
              type="number"
              className="form-control"
              label="Max Usage(per User)"
              validate={[number]}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-xs-12 col-sm-6">
            <Field
              name="visibility"
              id="visibility"
              component={selectField}
              disabled={isDisabled}
              label="Visibility"
              onChange={this.getUsers}
              options={[
                { value: 0, label: 'All' },
                { value: 1, label: 'Associate' },
                { value: 2, label: 'Customer' }
              ]}
            />
          </div>
          {window.location.pathname === '/coupons/add' ? (
            <div className="col-xs-12 col-sm-6 mb-3">
              <label htmlFor="user_id">Assign to Users</label>
              <AsyncPaginate
                name="user_id"
                placeholder="Select ..."
                label="Select User"
                isMulti
                onChange={this.handleUserSelect}
                loadOptions={this.loadOptions}
                additional={{
                  page: 1
                }}
                onMenuOpen={this.onMenuOpen}
                cacheUniqs={[visibility]}
                className="react-select"
                classNamePrefix="react-select"
              />
            </div>
          ) : (
            <div className="col-xs-12 col-md-6">
              <Field
                name="users"
                component={textareaField}
                disabled
                type="text"
                className="form-control"
                label="Users"
              />
            </div>
          )}
        </div>
        <div className="row align-item-center">
          {window.location.pathname === '/coupons/add' ? (
            <div className="col-xs-12 col-md-6">
              <label>Code</label>
              <FieldArray name="code" prefix={prefix} component={CouponCode} />
            </div>
          ) : (
            <div className="col-xs-12 col-md-6">
              <Field
                name="code"
                component={textareaField}
                disabled
                type="text"
                className="form-control"
                label="Code"
              />
            </div>
          )}
        </div>
        <Button
          as={Link}
          to={routePath.coupons.path}
          className="btn btn-light mr-2"
        >
          Cancel
        </Button>
        <button type="submit" disabled={inProgress} className="btn btn-primary">
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </button>
      </FormRedux>
    );
  }
}

const mapStateToProps = state => ({
  storeCountries: state.app.storeCountries.data,
  countryCurrencies: state.app.countryCurrencies,
  formValues: getFormValues('couponForm')(state)
});
const CouponFormWithRedux = connect(mapStateToProps)(CouponForm);

export default reduxForm({
  form: 'couponForm' // a unique identifier for this form
})(CouponFormWithRedux);
