import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';

const BillingDetails = ({ addressForm, cityList, stateList, countryList }) => {
  return (
    <div className="font-weight-normal font-13">
      {addressForm.street_name_number}
      {addressForm.address_line1 && (
        <>
          <br />
          {addressForm.address_line1}
        </>
      )}
      <br />
      {addressForm.postcode}
      <br />
      {(addressForm.city && addressForm.city !== 'others'
        ? find(cityList, { value: addressForm.city }).label
        : '') || addressForm.otherCity}
      <br />
      {find(stateList, { value: addressForm.state }).label}
      <br />
      {find(countryList, { value: addressForm.country }).label}
      <br />
      {`Phone:  ${addressForm.mobile_number}`}
    </div>
  );
};

const mapStateToProps = state => ({
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  countryList: state.app.countryList
});

export default connect(
  mapStateToProps,
  null
)(BillingDetails);
