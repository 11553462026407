import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AdvanceTable from '../../components/AdvanceTable';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import StatusSwitch from '../../components/StatusSwitch';
import { media as mediaActions } from '../../actions';
import MediaSettingForm from './MediaSettingForm';
import GenerateThumbs from './GenerateThumbs';

class Media extends React.Component {
  componentDidMount() {
    const { getAllMedias } = this.props;
    getAllMedias();
  }

  updateMediaStatus = (id, val) => {
    const { updateMedia } = this.props;
    updateMedia({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  handleSubmit = values => {
    const { updateMediaSetting } = this.props;
    updateMediaSetting(values);
  };

  render() {
    const { medias, isLoading, filterValues } = this.props;

    const columns = [
      {
        dataField: 'name',
        text: 'Style name',
        headerAttrs: { width: '25%' }
      },
      {
        dataField: 'width',
        text: 'Width',
        headerClasses: 'text-center',
        classes: 'text-center'
      },
      {
        dataField: 'height',
        text: 'Height',
        headerClasses: 'text-center',
        classes: 'text-center'
      },
      {
        dataField: 'status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={parseInt(col, 10)}
            onChange={this.updateMediaStatus}
            id={row.id}
            idPrefix="media_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/setting/media/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.media.breadcrumb} />
              <h4 className="page-title">{routePath.media.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <MediaSettingForm submitForm={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Link
                      to={routePath.mediaAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      Add New
                    </Link>
                  </div>
                </div>

                <AdvanceTable
                  data={medias.data}
                  columns={columns}
                  loading={isLoading}
                  sizePerPage={medias.per_page}
                  page={medias.current_page}
                  remote={{ pagination: true }}
                  hideSizePerPage
                  totalSize={medias.total}
                  sizePerPageList={filterValues}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <GenerateThumbs />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  medias: state.media.allList,
  isLoading: state.media.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllMedias: mediaActions.getAllMedias,
      updateMediaSetting: mediaActions.updateMediaSetting,
      updateMedia: mediaActions.updateMedia
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Media);
