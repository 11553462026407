import React from 'react';

function FsbPhasePerformance({ fsbPlanLogs, showPayment }) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-sm no-width">
        <thead className="table-light">
          <tr>
            <th width="25%">Phases</th>
            <th className="text-center">Current BP</th>
            <th className="text-center">Missing BP</th>
            {showPayment ? (
              <th className="text-center">Payout (&euro;)</th>
            ) : null}
            <th className="text-center">Qualified</th>
          </tr>
        </thead>
        <tbody>
          {fsbPlanLogs.map((log, index) => (
            <tr key={`missing_${log.id}`}>
              <td>{log.fsb_phase.title}</td>
              <td className="text-center">{log.achieved_bp}</td>
              <td className="text-center">
                {log.end_date
                  ? '0.00'
                  : parseFloat(
                      log.fsb_phase.bp_target - log.achieved_bp
                    ).toFixed(2)}
              </td>
              {showPayment ? (
                <td className="text-center">
                  {log.fsb_phase && log.end_date
                    ? log.fsb_phase.payout
                    : '0.00'}
                </td>
              ) : null}
              <td className="text-center">
                <span
                  className={`badge badge-pill badge-${
                    log.end_date ? 'success' : 'danger'
                  }`}
                >
                  {log.end_date ? 'Yes' : 'No'}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FsbPhasePerformance;
