import React from 'react';

function FsbViewStaticForm({ value, onEdit, editable }) {
  return (
    <>
      <div className="row align-items-center mb-2">
        <div className="col-6 col-sm-4">
          <label>Active</label>
          <div>
            {value.active_status ? (
              <span className="badge badge-pill badge-success">Yes</span>
            ) : (
              <span className="badge badge-pill badge-danger">No</span>
            )}
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <label>Achieved</label>
          <div>
            {value.achieve_status ? (
              <span className="badge badge-pill badge-success">Yes</span>
            ) : (
              <span className="badge badge-pill badge-danger">No</span>
            )}
          </div>
        </div>
        {editable ? (
          <div className="col-12 col-sm-4 text-right">
            <button
              type="button"
              className="btn btn-sm btn-rounded btn-primary ml-2"
              onClick={onEdit}
            >
              <i className="mdi mdi-square-edit-outline mr-1" />
              <span>Edit</span>
            </button>
          </div>
        ) : null}
      </div>
      <div className="row align-items-center">
        <div className="col-6 col-sm-4">
          <div className="form-group">
            <label>Start Date </label>
            <div>
              <input
                className="form-control"
                readOnly
                type="text"
                value={value.start_date}
              />
            </div>
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <div className="form-group">
            <label>End Date </label>
            <div>
              <input
                className="form-control"
                readOnly
                type="text"
                value={value.end_date}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FsbViewStaticForm;
