import React from 'react';
import moment from 'moment';
import { Modal } from 'react-bootstrap';

const CouponModal = ({ coupon, showModal, closeModal }) => {
  const start = moment(coupon.start_at, 'YYYY-MM-DD HH:mm').format(
    'DD/MM/YYYY HH:mm'
  );

  const end = moment(coupon.end_at, 'YYYY-MM-DD HH:mm').format(
    'DD/MM/YYYY HH:mm'
  );

  const Visibility = ({ visibility }) => {
    if (visibility === 1) {
      return <span className="badge badge-dark">ASSOCIATE</span>;
    }
    if (visibility === 2) {
      return <span className="badge badge-warning">CUSTOMER</span>;
    }
    return <span className="badge badge-success">ALL</span>;
  };

  const Users = ({ users }) => {
    return (
      <>
        <span>Users:</span>
        <div className="ml-2 row">
          {users &&
            users.map(user => (
              <p className="col-xs-6 col-sm-4 col-md-3" key={user.uuid}>
                <span
                  className={`flag-icon flag-icon-${user.uuid
                    .slice(0, 2)
                    .toLowerCase()} mr-1`}
                />
                {user.uuid}
                <span style={{ display: 'block' }}>{user.name}</span>
              </p>
            ))}
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        centered
      >
        <Modal.Header className="coupon-modal-header" closeButton>
          {coupon.country && <div>{coupon.country.name}</div>}
          <span className="mx-2">|</span>
          <div className="font-weight-semibold">{coupon.method}</div>
          <span className="mx-2">|</span>
          <div className="font-weight-semibold">{coupon.type}</div>
          <span className="mx-2">|</span>
          <div>{coupon.title}</div>
        </Modal.Header>
        <Modal.Body className="my-auto">
          <p>
            {'Code: '}
            <span className="text-primary">
              {coupon.code && coupon.code.join(', ')}
            </span>
          </p>
          <p>
            {'Description: '}
            {coupon.description}
          </p>
          <p>
            {'Discount: '}
            <span className="text-primary">{coupon.discount}%</span>
          </p>
          <p>
            {'Maximum Discount Allowed: '}
            <span className="text-primary">{coupon.max_discount_allowed}</span>
          </p>
          <p>
            {'Minimum Cart Amount: '}
            <span className="text-primary">{coupon.min_cart_amount}</span>
          </p>
          <p>
            {'Maximum Usage: '}
            <span className="text-primary">{coupon.max_usage}</span>
          </p>
          <p>
            {'Maximum Usage per User: '}
            <span className="text-primary">{coupon.max_usage_per_user}</span>
          </p>
          <p>
            {'Start Date & Time: '}
            {start}
          </p>
          <p>
            {'End Date & Time: '}
            {end}
          </p>
          <p>
            {'Visibility: '}
            <Visibility visibility={coupon.visibility} />
          </p>
          {coupon.users !== null ? (
            <Users users={coupon.users} />
          ) : (
            <p>Assigned Users: No Users</p>
          )}
          <p>
            {'Status: '}
            {coupon.status === 1 ? (
              <span className="badge badge-success">Active</span>
            ) : (
              <span className="badge badge-danger">Not Active</span>
            )}
          </p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CouponModal;
