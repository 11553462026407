import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkout as checkoutActions } from '../actions';

function ZottoPayModal(props) {
  const { zottopayModal, zoottopayModalClose } = props;
  const handleModal = () => {
    zoottopayModalClose();
  };

  return (
    <Modal size="lg" show={zottopayModal} onHide={handleModal} backdrop={false}>
      <Modal.Body className="card card-pricing mb-0 text-center">
        <div id="zottopay_html" />
      </Modal.Body>
    </Modal>
  );
}

const mapStateToProps = state => ({
  zottopayModal: state.checkout.zottopayModal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      zoottopayModalClose: checkoutActions.zoottopayModalClose,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZottoPayModal);
