import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Button } from 'react-bootstrap';

import { imageField } from '../../components/Fields';
import { category as categoryActions } from '../../actions';
import avatar from '../../assets/images/avatar.svg';
import { profileImage } from '../../constants/imageSize';
import DeleteAlert from '../../components/ConfirmAlert';
import ImageField from './ImageField';

class CategoryImage extends React.Component {
  state = {
    uploadPic: false,
    alert: false,
    pImage: undefined
  };

  openUploadPicForm = () => {
    const { uploadPic } = this.state;
    this.setState({ uploadPic: !uploadPic });
  };

  handleAlert = () => {
    const { alert } = this.state;
    this.setState({ alert: !alert });
  };

  deleteProfilePic = async () => {
    const { deleteCategoryImageFile } = this.props;
    deleteCategoryImageFile();
    this.setState({
      alert: !alert,
      pImage: undefined
    });
  };

  handlePicture = () => {
    const { uploadPic } = this.state;
    this.setState({
      uploadPic: !uploadPic
    });
  };

  handleChange = e => {
    const { categoryImageFile } = this.props;
    const file = e.target.files[0];
    categoryImageFile(file);
    const reader = new FileReader();
    reader.onload = event => {
      this.setState({
        pImage: event.target.result
      });
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  reset = e => {
    this.setState({ pImage: undefined });
  };

  render() {
    const { uploadPic, alert, pImage } = this.state;
    const { defaultImage } = this.props;
    return (
      <Fragment>
        {!uploadPic ? (
          <div className="row mb-2">
            <div className="col-12">
              <img
                className="img-thumbnail w-100 edit-pic"
                src={pImage || defaultImage || avatar}
                alt={`${pImage && pImage.name}`}
                size={profileImage.profileThumbnail}
              />
            </div>
            <div className="col-12 text-left text-md-center">
              <button
                type="button"
                onClick={this.openUploadPicForm}
                className="btn btn-sm btn-link mt-2 mr-lg-1"
              >
                <i className="mdi mdi-square-edit-outline mr-1" />
                <span>Edit Image</span>
              </button>
              {pImage && (
                <>
                  <button
                    type="button"
                    onClick={() => this.handleAlert()}
                    className="btn btn-sm btn-link mt-2"
                  >
                    <i className="mdi mdi-close-circle mr-1" />
                    <span>Remove</span>
                  </button>
                  <DeleteAlert
                    show={!!alert}
                    onConfirm={this.deleteProfilePic}
                    onCancel={this.handleAlert}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="form-group text-center">
            <label htmlFor="profilePic" className="d-none">
              Image
            </label>
            <div className="upload_img text-center align-items-center border border-light bg-light-lighten p-3">
              <ImageField
                component={imageField}
                id="profilePic"
                btnLabel="Browse Image"
                removeLabel="Remove Image"
                containerClassName="m-0"
                handleChange={this.handleChange}
                image={pImage}
                reset={this.reset}
              />
              <p className="font-10 text-center text-muted mt-1">
                Only jpeg, jpg and png is allowed.
                <br />
                Minimum dimension: 150 x 150 px
                <br />
                Maximum file size: 500 KB
              </p>
            </div>
            <div className="mt-2">
              <button
                type="button"
                onClick={this.openUploadPicForm}
                className="btn btn-sm btn-danger mr-2"
              >
                Cancel
              </button>
              <Button onClick={this.handlePicture} variant="primary" size="sm">
                Upload
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      categoryImageFile: categoryActions.categoryImageFile,
      deleteCategoryImageFile: categoryActions.deleteCategoryImageFile
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CategoryImage);
