import Login from './containers/Login';
import Register from './containers/Register';
import Redirect from './components/Redirect';
import ThankYou from './containers/ThankYou';
import Cancel from './containers/Cancel';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import Dashboard from './containers/Dashboard';
import Package from './containers/Package';
import Role, { UserListByRole, PermissionByRole } from './containers/Role';
import Associate, { AssociateAdd } from './containers/Associate';
import Customer, { CustomerTree } from './containers/Customer';
import AssociatePackage, {
  AssociatePackageAdd,
  AssociatePackageEdit
} from './containers/AssociatePackage';
import {
  Payout,
  PayoutAssociate,
  GeneratePayout,
  PayoutView,
  PayoutStatement
} from './containers/Payout';

import Tree from './containers/Tree';
import CustomerDownlines from './containers/CustomerTree';
import MainContent from './containers/Main';
import Category, { CategoryAdd, CategoryEdit } from './containers/Category';
import Product, {
  ProductView,
  ProductAdd,
  ProductEdit
} from './containers/Product';
import Generation, {
  GenerationAdd,
  GenerationEdit
} from './containers/Generation';
import Rank, { RankAdd, RankEdit } from './containers/Rank';
import FsbConfig, {
  FsbConfigView,
  FsbConfigEdit
} from './containers/FsbConfig';
import Order, {
  OrderView,
  DownlineAssociateOrder,
  OwnCustomerOrder,
  InvoiceView,
  ConfirmationZottoPay,
  PackageInvoiceView
} from './containers/Order';
import Shop, { ShopCategoryProducts, ProductDetails } from './containers/Shop';
import Checkout from './containers/Checkout';
import Cart from './containers/Cart';
import Account, {
  Profile,
  Addresses,
  PaymentOptions,
  FsbTracker
} from './containers/Account';
import Template, { TemplateAdd, TemplateEdit } from './containers/Template';
import routePath from './constants/routePath';
import TaxConfig, { TaxAdd, TaxEdit } from './containers/TaxConfig';
import Coupons, {
  CouponAdd,
  CouponEdit,
  CouponHistory,
  CouponHistoryView
} from './containers/Coupons';
import ShippingConfig, {
  ShippingAdd,
  ShippingEdit
} from './containers/ShippingConfig';
import General, {
  Setting,
  SettingAdd,
  Store,
  LoginHistory
} from './containers/Setting';
import Media, { MediaAdd, MediaEdit } from './containers/Media';
import Country, {
  State,
  City,
  LocationAdd,
  LocationEdit
} from './containers/Location';
import Downline from './containers/Downline';
import FsbList, { FSBPerformanceDetails } from './containers/FsbTracker';
import StoreAddressEdit from './containers/Setting/StoreAddressEdit';
import StoreAddressAdd from './containers/Setting/StoreAddressAdd';
import PaymentGatewayAdd from './containers/Setting/PaymentGatewayAdd';
import PaymentGatewayEdit from './containers/Setting/PaymentGatewayEdit';

const routes = [
  {
    path: routePath.login.path,
    component: Login,
    isPublic: true,
    name: routePath.login.title // Name is important for key and must be unique
  },
  {
    path: routePath.register.path,
    component: Register,
    isPublic: true,
    name: routePath.register.title // Name is important for key and must be unique
  },
  {
    path: routePath.ref.path,
    component: Redirect,
    isPublic: true,
    name: routePath.ref.title // Name is important for key and must be unique
  },
  {
    path: routePath.package.path,
    exact: true,
    component: Package,
    name: routePath.package.title
  },
  {
    path: routePath.thankyou.path,
    component: ThankYou,
    isPublic: true,
    name: routePath.thankyou.title // Name is important for key and must be unique
  },
  {
    path: routePath.cancel.path,
    component: Cancel,
    isPublic: true,
    name: routePath.cancel.title // Name is important for key and must be unique
  },
  {
    path: routePath.forgot.path,
    component: ForgotPassword,
    isPublic: true,
    name: routePath.forgot.title // Name is important for key and must be unique
  },
  {
    path: routePath.reset.path,
    component: ResetPassword,
    isPublic: true,
    name: routePath.reset.title // Name is important for key and must be unique
  },
  {
    path: '/',
    component: MainContent,
    name: 'root',
    routes: [
      {
        path: routePath.dashboard.path,
        exact: true,
        component: Dashboard,
        name: routePath.dashboard.title
      },
      {
        path: routePath.associate.path,
        exact: true,
        component: Associate,
        name: routePath.associate.title,
        permission: routePath.associate.permission
      },
      {
        path: routePath.associateAdd.path,
        exact: true,
        component: AssociateAdd,
        name: routePath.associateAdd.title,
        permission: routePath.associateAdd.permission
      },
      {
        path: routePath.tree.path,
        exact: true,
        component: Tree,
        name: routePath.tree.title,
        permission: routePath.tree.permission
      },
      // customer downlines sidebar
      {
        path: routePath.treeCustomer.path,
        exact: true,
        component: CustomerDownlines,
        name: routePath.treeCustomer.title,
        permission: routePath.treeCustomer.permission
      },
      {
        path: routePath.downline.path,
        exact: true,
        component: Downline,
        name: routePath.downline.title,
        permission: routePath.downline.permission
      },
      {
        path: routePath.customer.path,
        exact: true,
        component: Customer,
        name: routePath.customer.title,
        permission: routePath.customer.permission
      },
      {
        path: routePath.customerTree.path,
        exact: true,
        component: CustomerTree,
        name: routePath.customerTree.title,
        permission: routePath.customerTree.permission
      },
      {
        path: routePath.payout.path,
        exact: true,
        component: PayoutAssociate,
        name: routePath.payout.title,
        permission: routePath.payout.permission
      },
      {
        path: routePath.payoutList.path,
        exact: true,
        component: Payout,
        name: routePath.payoutList.title,
        permission: routePath.payoutList.permission
      },
      {
        path: routePath.payoutView.path,
        exact: true,
        component: PayoutView,
        name: routePath.payoutView.title,
        permission: routePath.payoutView.permission
      },
      {
        path: routePath.payoutGenerate.path,
        exact: true,
        component: GeneratePayout,
        name: routePath.payoutGenerate.title,
        permission: routePath.payoutGenerate.permission
      },
      {
        path: routePath.payoutStatement.path,
        exact: true,
        component: PayoutStatement,
        name: routePath.payoutStatement.title,
        permission: routePath.payoutStatement.permission
      },
      {
        path: routePath.payout.path,
        exact: true,
        component: GeneratePayout,
        name: routePath.payout.title,
        permission: routePath.payout.permission
      },
      {
        path: routePath.associatePackage.path,
        exact: true,
        component: AssociatePackage,
        name: routePath.associatePackage.title,
        permission: routePath.associatePackage.permission
      },
      {
        path: routePath.associatePackageAdd.path,
        exact: true,
        component: AssociatePackageAdd,
        name: routePath.associatePackageAdd.title,
        permission: routePath.associatePackageAdd.permission
      },
      {
        path: routePath.associatePackageEdit.path,
        exact: true,
        component: AssociatePackageEdit,
        name: routePath.associatePackageEdit.title,
        permission: routePath.associatePackageEdit.permission
      },

      {
        path: routePath.role.path,
        exact: true,
        component: Role,
        name: routePath.role.title,
        permission: routePath.role.permission
      },
      {
        path: routePath.roleUserList.path,
        exact: true,
        component: UserListByRole,
        name: routePath.roleUserList.title,
        permission: routePath.roleUserList.permission
      },
      {
        path: routePath.rolePermissionList.path,
        exact: true,
        component: PermissionByRole,
        name: routePath.rolePermissionList.title,
        permission: routePath.rolePermissionList.permission
      },
      {
        path: routePath.category.path,
        exact: true,
        component: Category,
        name: routePath.category.title,
        permission: routePath.category.permission
      },
      {
        path: routePath.categoryAdd.path,
        exact: true,
        component: CategoryAdd,
        name: routePath.categoryAdd.title,
        permission: routePath.categoryAdd.permission
      },
      {
        path: routePath.categoryEdit.path,
        exact: true,
        component: CategoryEdit,
        name: routePath.categoryEdit.title,
        permission: routePath.categoryEdit.permission
      },
      {
        path: routePath.categoryProduct.path,
        exact: true,
        component: Product,
        name: routePath.categoryProduct.title,
        permission: routePath.categoryProduct.permission
      },
      {
        path: routePath.product.path,
        exact: true,
        component: Product,
        name: routePath.product.title,
        permission: routePath.product.permission
      },
      {
        path: routePath.productView.path,
        exact: true,
        component: ProductView,
        name: routePath.productView.title,
        permission: routePath.productView.permission
      },
      {
        path: routePath.productAdd.path,
        exact: true,
        component: ProductAdd,
        name: routePath.productAdd.title,
        permission: routePath.productAdd.permission
      },
      {
        path: routePath.productEdit.path,
        exact: true,
        component: ProductEdit,
        name: routePath.productEdit.title,
        permission: routePath.productEdit.permission
      },
      {
        path: routePath.order.path,
        exact: true,
        component: Order,
        name: routePath.order.title
      },
      {
        path: routePath.myOrder.path,
        exact: true,
        component: Order,
        name: routePath.myOrder.title
      },
      {
        path: routePath.downlineAssociateOrder.path,
        exact: true,
        component: DownlineAssociateOrder,
        name: routePath.downlineAssociateOrder.title
      },
      {
        path: routePath.ownCustomerOrder.path,
        exact: true,
        component: OwnCustomerOrder,
        name: routePath.ownCustomerOrder.title
      },
      {
        path: routePath.orderView.path,
        exact: true,
        component: OrderView,
        name: routePath.orderView.title
      },
      {
        path: routePath.invoiceView.path,
        exact: true,
        component: InvoiceView,
        name: routePath.invoiceView.title
      },
      {
        path: routePath.confirmationZotto.path,
        exact: true,
        component: ConfirmationZottoPay,
        name: routePath.confirmationZotto.title
      },
      {
        path: routePath.confirmation.path,
        exact: true,
        component: InvoiceView,
        name: routePath.confirmation.title
      },
      {
        path: routePath.packageInvoiceView.path,
        exact: true,
        component: PackageInvoiceView,
        name: routePath.packageInvoiceView.title
      },
      {
        path: routePath.generation.path,
        exact: true,
        component: Generation,
        name: routePath.generation.title,
        permission: routePath.generation.permission
      },
      {
        path: routePath.generationAdd.path,
        exact: true,
        component: GenerationAdd,
        name: routePath.generationAdd.title,
        permission: routePath.generationAdd.permission
      },
      {
        path: routePath.generationEdit.path,
        exact: true,
        component: GenerationEdit,
        name: routePath.generationEdit.title,
        permission: routePath.generationEdit.permission
      },
      {
        path: routePath.rank.path,
        exact: true,
        component: Rank,
        name: routePath.rank.title,
        permission: routePath.rank.permission
      },
      {
        path: routePath.rankAdd.path,
        exact: true,
        component: RankAdd,
        name: routePath.rankAdd.title,
        permission: routePath.rankAdd.permission
      },
      {
        path: routePath.rankEdit.path,
        exact: true,
        component: RankEdit,
        name: routePath.rankEdit.title,
        permission: routePath.rankEdit.permission
      },
      {
        path: routePath.shopProduct.path,
        exact: true,
        component: ProductDetails,
        name: routePath.shopProduct.title
      },
      {
        path: routePath.shop.path,
        exact: true,
        component: Shop,
        name: routePath.shop.title
      },
      {
        path: routePath.shopCategoryProducts.path,
        exact: true,
        component: ShopCategoryProducts,
        name: routePath.shop.title
      },
      {
        path: routePath.shopPagination.path,
        exact: true,
        component: ShopCategoryProducts,
        name: routePath.shopPagination.title
      },
      {
        path: routePath.cart.path,
        exact: true,
        component: Cart,
        name: routePath.cart.title
      },
      {
        path: routePath.checkout.path,
        exact: true,
        component: Checkout,
        name: routePath.checkout.title
      },
      {
        path: routePath.fsb.path,
        exact: true,
        component: FsbConfig,
        name: routePath.fsb.title,
        permission: routePath.fsb.permission
      },
      {
        path: routePath.fsbView.path,
        exact: true,
        component: FsbConfigView,
        name: routePath.fsbView.title,
        permission: routePath.fsbView.permission
      },
      {
        path: routePath.fsbEdit.path,
        exact: true,
        component: FsbConfigEdit,
        name: routePath.fsbEdit.title,
        permission: routePath.fsbEdit.permission
      },
      {
        path: routePath.account.path,
        exact: true,
        component: Account,
        name: routePath.account.title
      },
      {
        path: routePath.profile.path,
        exact: true,
        component: Profile,
        name: routePath.profile.title
      },
      {
        path: routePath.addresses.path,
        exact: true,
        component: Addresses,
        name: routePath.addresses.title
      },
      {
        path: routePath.payOptions.path,
        exact: true,
        component: PaymentOptions,
        name: routePath.payOptions.title
      },
      {
        path: routePath.fsbTracker.path,
        exact: true,
        component: FsbTracker,
        name: routePath.fsbTracker.title
      },
      {
        path: routePath.fsbPerformanceList.path,
        exact: true,
        component: FsbList,
        name: routePath.fsbPerformanceList.title
      },
      {
        path: routePath.fsbPerformanceDetails.path,
        exact: true,
        component: FSBPerformanceDetails,
        name: routePath.fsbPerformanceDetails.title
      },
      {
        path: routePath.tax.path,
        exact: true,
        component: TaxConfig,
        name: routePath.tax.title,
        permission: routePath.tax.permission
      },
      {
        path: routePath.taxAdd.path,
        exact: true,
        component: TaxAdd,
        name: routePath.taxAdd.title,
        permission: routePath.taxAdd.permission
      },
      {
        path: routePath.taxEdit.path,
        exact: true,
        component: TaxEdit,
        name: routePath.taxEdit.title,
        permission: routePath.taxEdit.permission
      },
      {
        path: routePath.shipping.path,
        exact: true,
        component: ShippingConfig,
        name: routePath.shipping.title,
        permission: routePath.shipping.permission
      },
      {
        path: routePath.shippingAdd.path,
        exact: true,
        component: ShippingAdd,
        name: routePath.shippingAdd.title,
        permission: routePath.shippingAdd.permission
      },
      {
        path: routePath.shippingEdit.path,
        exact: true,
        component: ShippingEdit,
        name: routePath.shippingEdit.title,
        permission: routePath.shippingEdit.permission
      },
      {
        path: routePath.template.path,
        exact: true,
        component: Template,
        name: routePath.rank.title,
        permission: routePath.template.permission
      },
      {
        path: routePath.templateAdd.path,
        exact: true,
        component: TemplateAdd,
        name: routePath.templateAdd.title,
        permission: routePath.templateAdd.permission
      },
      {
        path: routePath.templateEdit.path,
        exact: true,
        component: TemplateEdit,
        name: routePath.templateEdit.title,
        permission: routePath.templateEdit.permission
      },
      {
        path: routePath.setting.path,
        exact: true,
        component: Setting,
        name: routePath.setting.title,
        permission: routePath.setting.permission
      },
      {
        path: routePath.settingAdd.path,
        exact: true,
        component: SettingAdd,
        name: routePath.settingAdd.title,
        permission: routePath.settingAdd.permission
      },
      {
        path: routePath.generalSetting.path,
        exact: true,
        component: General,
        name: routePath.generalSetting.title,
        permission: routePath.generalSetting.permission
      },
      {
        path: routePath.storeSetting.path,
        exact: true,
        component: Store,
        name: routePath.storeSetting.title,
        permission: routePath.storeSetting.permission
      },
      // {
      //   path: routePath.addressSetting.path,
      //   exact: true,
      //   component: Address,
      //   name: routePath.addressSetting.title,
      //   permission: routePath.addressSetting.permission
      // },
      {
        path: routePath.addressSettingAdd.path,
        exact: true,
        component: StoreAddressAdd,
        name: routePath.addressSettingAdd.title,
        permission: routePath.addressSettingAdd.permission
      },
      {
        path: routePath.addressSettingEdit.path,
        exact: true,
        component: StoreAddressEdit,
        name: routePath.addressSettingEdit.title,
        permission: routePath.addressSettingEdit.permission
      },
      {
        path: routePath.paymentGatewayAdd.path,
        exact: true,
        component: PaymentGatewayAdd,
        name: routePath.paymentGatewayAdd.title,
        permission: routePath.paymentGatewayAdd.permission
      },
      {
        path: routePath.paymentGatewayCopy.path,
        exact: true,
        component: PaymentGatewayAdd,
        name: routePath.paymentGatewayCopy.title,
        permission: routePath.paymentGatewayCopy.permission
      },
      {
        path: routePath.paymentGatewayEdit.path,
        exact: true,
        component: PaymentGatewayEdit,
        name: routePath.paymentGatewayEdit.title,
        permission: routePath.paymentGatewayEdit.permission
      },
      {
        path: routePath.loginHistory.path,
        exact: true,
        component: LoginHistory,
        name: routePath.loginHistory.title,
        permission: routePath.loginHistory.permission
      },
      {
        path: routePath.media.path,
        exact: true,
        component: Media,
        name: routePath.media.title,
        permission: routePath.media.permission
      },
      {
        path: routePath.mediaAdd.path,
        exact: true,
        component: MediaAdd,
        name: routePath.mediaAdd.title,
        permission: routePath.mediaAdd.permission
      },
      {
        path: routePath.mediaEdit.path,
        exact: true,
        component: MediaEdit,
        name: routePath.mediaEdit.title,
        permission: routePath.mediaEdit.permission
      },
      {
        path: routePath.coupons.path,
        exact: true,
        component: Coupons,
        name: routePath.coupons.title
        // permission: routePath.coupons.permission
      },
      {
        path: routePath.couponAdd.path,
        exact: true,
        component: CouponAdd,
        name: routePath.couponAdd.title
        // permission: routePath.couponAdd.permission
      },
      {
        path: routePath.couponEdit.path,
        exact: true,
        component: CouponEdit,
        name: routePath.couponEdit.title
        // permission: routePath.couponEdit.permission
      },
      {
        path: routePath.consumptionHistory.path,
        exact: true,
        component: CouponHistory,
        name: routePath.consumptionHistory.title
        // permission: routePath.consumptionHistory.permission
      },
      {
        path: routePath.consumptionHistoryView.path,
        exact: true,
        component: CouponHistoryView,
        name: routePath.consumptionHistoryView.title
        // permission: routePath.consumptionHistoryView.permission
      },
      {
        path: routePath.country.path,
        exact: true,
        component: Country,
        name: routePath.country.title,
        permission: routePath.country.permission
      },
      {
        path: routePath.state.path,
        exact: true,
        component: State,
        name: routePath.state.title,
        permission: routePath.state.permission
      },
      {
        path: routePath.city.path,
        exact: true,
        component: City,
        name: routePath.city.title,
        permission: routePath.city.permission
      },
      {
        path: routePath.locationAdd.path,
        exact: false,
        component: LocationAdd,
        name: routePath.locationAdd.title,
        permission: routePath.locationAdd.permission
      },
      {
        path: routePath.locationEdit.path,
        exact: true,
        component: LocationEdit,
        name: routePath.locationEdit.title,
        permission: routePath.locationEdit.permission
      }
    ]
  }
];

export default routes;
