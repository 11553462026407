import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import avatar from '../assets/images/avatar.svg';
import { getImageUrl } from '../utills/custom';
import { profileImage } from '../constants/imageSize';

function ProfileImg({ data, imageStyle }) {
  let pImage = get(data, 'profile_picture', null);
  let imageUrl = avatar;
  if (pImage) {
    pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
    if (
      !/placeholder/gi.test(pImage) &&
      !/avatar/gi.test(pImage) &&
      imageStyle[profileImage.profileThumbnail]
    ) {
      imageUrl = getImageUrl(pImage, imageStyle[profileImage.profileThumbnail]);
    }
  }
  return (
    <img src={imageUrl} alt="" className="rounded-circle avatar-sm mr-1" />
  );
}

const mapStateToProps = state => ({
  imageStyle: state.app.imageStyle
});

export default connect(
  mapStateToProps,
  null
)(ProfileImg);
