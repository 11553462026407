import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { rank as rankActionTypes, app as appActionTypes } from '../actions';
import routePath from '../constants/routePath';

function* getAllRank(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/rank`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: rankActionTypes.RANK_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getRank(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/rank/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: rankActionTypes.RANK_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createRank(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/rank`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Rank was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: rankActionTypes.RANK_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.rank.path
      });
    }
  } catch (error) {
    yield put({ type: rankActionTypes.RANK_CREATE_ERROR, data: error });
  }
}

function* updateRank(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/rank/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Rank updated successfully'
    });
    if (responseJson) {
      yield put({
        type: rankActionTypes.RANK_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.rank.path
        });
      }
    }
  } catch (error) {
    yield put({ type: rankActionTypes.RANK_UPDATE_ERROR, data: error });
  }
}

function* watchPackageListAll() {
  yield takeLatest(rankActionTypes.RANK_ALL_LIST_REQUEST, getAllRank);
}

function* watchGetRank() {
  yield takeLatest(rankActionTypes.RANK_REQUEST, getRank);
}

function* watchRankCreate() {
  yield takeLatest(rankActionTypes.RANK_CREATE_REQUEST, createRank);
}

function* watchRankUpdate() {
  yield takeLatest(rankActionTypes.RANK_UPDATE_REQUEST, updateRank);
}

export default [
  fork(watchPackageListAll),
  fork(watchRankCreate),
  fork(watchRankUpdate),
  fork(watchGetRank)
];
