import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

function SearchInput({ onSubmit }) {
  const [value, setValue] = useState('');
  const debounceSubmit = useCallback(debounce(onSubmit, 750), [onSubmit]);

  const handleChange = e => {
    setValue(e.target.value);
    debounceSubmit(e.target.value);
  };

  const handleClear = () => {
    setValue('');
    debounceSubmit('');
  };

  return (
    <div className="app-search input-group input-group-sm search-bar">
      <input
        value={value}
        type="text"
        onChange={handleChange}
        className="form-control"
        placeholder="Search By Associate Name or UUID"
      />
      <span className="mdi mdi-magnify" />
      {value ? (
        <span
          className="mdi mdi-close"
          role="presentation"
          onClick={handleClear}
        />
      ) : null}
    </div>
  );
}

SearchInput.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SearchInput;
