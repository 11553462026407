const dashboard = {
  BP_REQUEST: 'BP_REQUEST',
  BP_SUCCESS: 'BP_SUCCESS',
  BP_ERROR: 'BP_ERROR',
  USER_SUMMARY_REQUEST: 'USER_SUMMARY_REQUEST',
  USER_SUMMARY_SUCCESS: 'USER_SUMMARY_SUCCESS',
  USER_SUMMARY_ERROR: 'USER_SUMMARY_ERROR',
  ASSOCIATE_DASHBOARD_REQUEST: 'ASSOCIATE_DASHBOARD_REQUEST',
  ASSOCIATE_DASHBOARD_SUCCESS: 'ASSOCIATE_DASHBOARD_SUCCESS',
  ASSOCIATE_DASHBOARD_ERROR: 'ASSOCIATE_DASHBOARD_ERROR',
  getBpoints: id => ({ type: dashboard.BP_REQUEST, id }),
  getUserSummary: data => ({ type: dashboard.USER_SUMMARY_REQUEST, data }),
  getDashboard: params => ({
    type: dashboard.ASSOCIATE_DASHBOARD_REQUEST,
    params
  })
};

export default dashboard;
