import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  storePaymentGateway as storePaymentGatewayTypes,
  app as appActionTypes
} from '../actions';

const postRequestHeaders = { 'content-type': 'multipart/form-data' };

function* getStorePaymentGatewayList() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/getPaymentGatewayList`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_LIST_ERROR,
      data: error
    });
  }
}

function* getStorePaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/getPaymentGateway/${actions.data.iso2}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_ERROR,
      data: error
    });
  }
}

function* createStorePaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/setPaymentGateway`,
      method: 'POST',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: storePaymentGatewayTypes.STORE_CREATE_PAYMENT_GATEWAY_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: '/setting/store'
      });
    }
  } catch (error) {
    yield put({
      type: storePaymentGatewayTypes.STORE_CREATE_PAYMENT_GATEWAY_ERROR,
      data: error
    });
  }
}

function* updateStorePaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/updatePaymentGateway`,
      method: 'POST',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: storePaymentGatewayTypes.STORE_UPDATE_PAYMENT_GATEWAY_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: '/setting/store'
      });
    }
  } catch (error) {
    yield put({
      type: storePaymentGatewayTypes.STORE_UPDATE_PAYMENT_GATEWAY_ERROR,
      data: error
    });
  }
}

function* deleteStorePaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/removePaymentGateway/${actions.data}`,
      method: 'POST'
    });
    if (responseJson) {
      yield put({
        type: storePaymentGatewayTypes.STORE_DELETE_PAYMENT_GATEWAY_SUCCESS,
        data: actions.data
      });
    }
  } catch (error) {
    yield put({
      type: storePaymentGatewayTypes.STORE_DELETE_PAYMENT_GATEWAY_ERROR,
      data: error
    });
  }
}

function* watchGetStorePaymentGatewayList() {
  yield takeLatest(
    storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_LIST_REQUEST,
    getStorePaymentGatewayList
  );
}

function* watchGetStorePaymentGateway() {
  yield takeLatest(
    storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_REQUEST,
    getStorePaymentGateway
  );
}

function* watchStorePaymentGatewayCreate() {
  yield takeLatest(
    storePaymentGatewayTypes.STORE_CREATE_PAYMENT_GATEWAY_REQUEST,
    createStorePaymentGateway
  );
}
function* watchStorePaymentGatewayUpdate() {
  yield takeLatest(
    storePaymentGatewayTypes.STORE_UPDATE_PAYMENT_GATEWAY_REQUEST,
    updateStorePaymentGateway
  );
}

function* watchStorePaymentGatewayDelete() {
  yield takeLatest(
    storePaymentGatewayTypes.STORE_DELETE_PAYMENT_GATEWAY_REQUEST,
    deleteStorePaymentGateway
  );
}

export default [
  fork(watchStorePaymentGatewayCreate),
  fork(watchStorePaymentGatewayUpdate),
  fork(watchGetStorePaymentGateway),
  fork(watchStorePaymentGatewayDelete),
  fork(watchGetStorePaymentGatewayList)
];
