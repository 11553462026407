import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import AdvanceTable from '../../components/AdvanceTable';
import StatusSwitch from '../../components/StatusSwitch';
import { location as locationActions } from '../../actions';
import LinkBack from './LinkBack';

class City extends React.Component {
  state = {
    page: 1,
    selected: [],
    updatedStatus: {}
  };

  componentDidMount() {
    const {
      getAllLocations,
      match: {
        params: { id }
      }
    } = this.props;

    getAllLocations({ type: `state/city/${id}` });
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const {
      getAllLocations,
      match: {
        params: { id }
      }
    } = this.props;

    if (type === 'pagination') {
      getAllLocations({ type: `state/city/${id}`, page, per_page: perPage });
    }
    this.setState({ page });
  };

  updateLocationStatus = (id, val) => {
    const { updateLocation } = this.props;
    updateLocation({
      id,
      type: 'city',
      data: { updateType: 'status' }
    });
    const { updatedStatus } = this.state;
    setTimeout(() => {
      this.setState({
        updatedStatus: {
          ...updatedStatus,
          [id]: val
        }
      });
    }, 500);
  };

  getStatus = (stateValue, fetchedValue) => {
    if (typeof stateValue === 'boolean') return stateValue;
    return fetchedValue;
  };

  render() {
    const { locations, isLoading, filterValues } = this.props;
    const { page, selected } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID'
      },
      {
        dataField: 'name',
        text: 'City'
      },
      {
        dataField: 'status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => {
          return (
            <StatusSwitch
              checked={this.getStatus(
                // eslint-disable-next-line react/destructuring-assignment
                this.state.updatedStatus[row.id],
                parseInt(col, 10)
              )}
              onChange={this.updateLocationStatus}
              id={row.id}
              idPrefix="location_sts_"
            />
          );
        }
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <>
            <Link to={`/location/city/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];
    const { country, state, city } = locations.data;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <LinkBack
                linkItems={[
                  { link: '/location/country', title: 'Location' },
                  {
                    link: `/location/state/${country && country.country_id}`,
                    title: 'State'
                  },
                  { title: 'Cities' }
                ]}
              />
              <h4 className="page-title">
                {country && country.country_name}
                <span> / </span>
                {state && state.state_name}
              </h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-6">
                    <Link
                      to={`/location/state/${country && country.country_id}`}
                      className="btn btn-info btn-sm mr-sm-2 mb-1 mb-sm-0"
                    >
                      Back
                    </Link>
                  </div>
                  <div className="col-6">
                    <div className="btn-group btn-group-sm ml-2 float-right">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          <Dropdown.Item
                            as={Link}
                            to={`/location/add/city?id=${state &&
                              state.state_id}`}
                          >
                            <i className="mdi mdi-plus-circle-outline" /> Add
                            city
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-import" /> Import
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-export" /> Export
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <AdvanceTable
                  data={city}
                  columns={columns}
                  loading={isLoading}
                  sizePerPageList={filterValues}
                  sizePerPage={city ? city.length : 0}
                  page={page}
                  remote={{ pagination: true }}
                  hideSizePerPage
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.location.allList,
  isLoading: state.location.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllLocations: locationActions.getAllLocations,
      updateLocation: locationActions.updateLocation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(City);
