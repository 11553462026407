import { findIndex } from 'lodash';
import actions from 'redux-form/lib/actions';
import { payout as payoutActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { updateObjectInArray } from '../utills/custom';

function payout(state = initialState.payout, action) {
  switch (action.type) {
    case payoutActionTypes.ADMIN_PAYOUT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case payoutActionTypes.ADMIN_PAYOUT_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          adminList: action.data
        };
      }
      return state;
    case payoutActionTypes.ASSOCIATE_PAYOUT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case payoutActionTypes.ASSOCIATE_PAYOUT_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          assocMonthlist: action.data
        };
      }
      return state;
    case payoutActionTypes.PAYOUT_GENERATE_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case payoutActionTypes.PAYOUT_GENERATE_LIST_ERROR:
    case payoutActionTypes.PAYOUT_GENERATE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case payoutActionTypes.PAYOUT_APPROVE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case payoutActionTypes.PAYOUT_APPROVE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case payoutActionTypes.PAYOUT_APPROVE_SUCCESS:
      if (action.data) {
        const newList = state.adminPayoutItem.data.map(item => {
          if (action.data.rows.includes(item.id)) {
            return {
              ...item,
              approve_status: action.status
            };
          }
          return item;
        });
        return {
          ...state,
          adminPayoutItem: {
            ...state.adminPayoutItem,
            data: newList
          },
          createProgress: false
        };
      }
      return state;
    case payoutActionTypes.PAYOUT_PAID_REQUEST:
      return {
        ...state
      };
    case payoutActionTypes.PAYOUT_PAID_SUCCESS:
      if (action.data && action.data.data && action.data.data.id) {
        const index = findIndex(state.adminList.data, {
          id: action.data.data.id
        });
        if (index > -1) {
          const newList = updateObjectInArray(state.adminList.data, {
            index,
            item: action.data.data
          });
          return {
            ...state,
            adminList: {
              ...state.adminList,
              data: newList
            }
          };
        }
      }
      return {
        ...state
      };
    case payoutActionTypes.CLEAR_PAYOUT_ITEM:
      return {
        ...state,
        adminPayoutItem: initialState.payout.adminPayoutItem
      };
    case payoutActionTypes.ADMIN_PAYOUT_ITEM_REQUEST:
      return {
        ...state,
        isLoading: true,
        adminPayoutItem: initialState.payout.adminPayoutItem
      };
    case payoutActionTypes.ADMIN_PAYOUT_ITEM_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          adminPayoutItem: action.data
        };
      }
      return state;
    case payoutActionTypes.PAYOUT_STATEMENT_SUCCESS:
      if (action.data) {
        return {
          ...state,
          details: { ...state.details, [action.data.id]: action.data }
        };
      }
      return state;
    case payoutActionTypes.PAYOUT_SEND_PAYMENT_REQUEST:
      return {
        ...state,
        paymentProcessing: true
      };
    case payoutActionTypes.PAYOUT_SEND_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentProcessing: false,
        payoutModal: false
      };
    case payoutActionTypes.PAYOUT_SEND_PAYMENT_ERROR:
      return {
        ...state,
        paymentProcessing: false
      };
    case payoutActionTypes.SHOW_HIDE_PAYOUT_MODAL:
      return {
        ...state,
        payoutModal: action.data
      };
    default:
      return state;
  }
}

export default payout;
