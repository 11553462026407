import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CategoryForm from './CategoryForm';
import BreadCrumb from '../../components/BreadCrumb';
import { category as categoryActions } from '../../actions';
import routePath from '../../constants/routePath';

class CategoryAdd extends React.Component {
  handleSubmit = values => {
    const { createCategory, imageFile } = this.props;
    const fieldValues = {
      ...values,
      status: +values.status,
      parent_id: values.parent_id || null,
      image: imageFile
    };
    createCategory(fieldValues);
  };

  render() {
    const { createProgress } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.categoryAdd.breadcrumb} />
              <h4 className="page-title">{routePath.categoryAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card category_add">
              <div className="card-body">
                <CategoryForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                  initialValues={{
                    parent_id: '',
                    status: true,
                    country_iso2: [],
                    states: []
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.category.createProgress,
  imageFile: state.category.imageFile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createCategory: categoryActions.createCategory
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryAdd);
