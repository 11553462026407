// @ts-check
const storeAddress = {
  STORE_ADDRESS_LIST_REQUEST: 'STORE_ADDRESS_LIST_REQUEST',
  STORE_ADDRESS_LIST_SUCCESS: 'STORE_ADDRESS_LIST_SUCCESS',
  STORE_ADDRESS_LIST_ERROR: 'STORE_ADDRESS_LIST_ERROR',
  STORE_ADDRESS_REQUEST: 'STORE_ADDRESS_REQUEST',
  STORE_ADDRESS_SUCCESS: 'STORE_ADDRESS_SUCCESS',
  STORE_ADDRESS_ERROR: 'STORE_ADDRESS_ERROR',
  STORE_CREATE_ADDRESS_REQUEST: 'STORE_CREATE_ADDRESS_REQUEST',
  STORE_CREATE_ADDRESS_SUCCESS: 'STORE_CREATE_ADDRESS_SUCCESS',
  STORE_CREATE_ADDRESS_ERROR: 'STORE_CREATE_ADDRESS_ERROR',
  STORE_UPDATE_ADDRESS_REQUEST: 'STORE_UPDATE_ADDRESS_REQUEST',
  STORE_UPDATE_ADDRESS_SUCCESS: 'STORE_UPDATE_ADDRESS_SUCCESS',
  STORE_UPDATE_ADDRESS_ERROR: 'STORE_UPDATE_ADDRESS_ERROR',
  STORE_PICKUP_UPDATE_REQUEST: 'STORE_PICKUP_UPDATE_REQUEST',
  STORE_PICKUP_UPDATE_SUCCESS: 'STORE_PICKUP_UPDATE_SUCCESS',
  STORE_PICKUP_UPDATE_ERROR: 'STORE_PICKUP_UPDATE_ERROR',
  COUPON_VISIBILITY_UPDATE_REQUEST: 'COUPON_VISIBILITY_UPDATE_REQUEST',
  COUPON_VISIBILITY_UPDATE_SUCCESS: 'COUPON_VISIBILITY_UPDATE_SUCCESS',
  COUPON_VISIBILITY_UPDATE_ERROR: 'COUPON_VISIBILITY_UPDATE_ERROR',

  getStoreAddressList: () => ({
    type: storeAddress.STORE_ADDRESS_LIST_REQUEST
  }),
  getStoreAddress: data => ({
    type: storeAddress.STORE_ADDRESS_REQUEST,
    data
  }),
  createStoreAddress: data => ({
    type: storeAddress.STORE_CREATE_ADDRESS_REQUEST,
    data
  }),
  updateStoreAddress: data => ({
    type: storeAddress.STORE_UPDATE_ADDRESS_REQUEST,
    data
  }),
  updateStorePickup: data => ({
    type: storeAddress.STORE_PICKUP_UPDATE_REQUEST,
    data
  }),
  updateCouponVisibility: data => ({
    type: storeAddress.COUPON_VISIBILITY_UPDATE_REQUEST,
    data
  })
};

export default storeAddress;
