import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import { payout as payoutActions } from '../../actions';
import GeneratePayoutForm from './PayoutForm';

class GeneratePayout extends React.Component {
  handleSubmit = values => {
    const { generatePayout } = this.props;
    generatePayout(values);
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.payoutGenerate.breadcrumb} />
              <h4 className="page-title">{routePath.payoutGenerate.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <GeneratePayoutForm
                  submitForm={this.handleSubmit}
                  initialValues={{ type: 'monthly' }}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      generatePayout: payoutActions.generatePayout,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(GeneratePayout);
