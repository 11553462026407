import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import overlayFactory from 'react-bootstrap-table2-overlay';
import NoResult from './NoResult';

function selectRow(props) {
  return {
    mode: 'checkbox',
    classes: 'table-light',
    selectionHeaderRenderer: ({ mode, checked, indeterminate }) => (
      <div className="custom-control custom-checkbox">
        <input
          type={mode}
          checked={!indeterminate && checked}
          onChange={() => {}}
          className="custom-control-input"
        />
        <label className="custom-control-label" />
      </div>
    ),
    selectionRenderer: ({ mode, checked, disabled }) => (
      <div className="custom-control custom-checkbox">
        <input
          type={mode}
          checked={checked}
          disabled={disabled}
          onChange={() => {}}
          className="custom-control-input"
        />
        <label className="custom-control-label" />
      </div>
    ),
    ...props
  };
}

const tableLoading = loading => {
  if (!loading) return <NoResult />;
  return <div className="py-5" />;
};

const pageButtonRenderer = ({ page, active, onPageChange }) => {
  const handleClick = e => {
    e.preventDefault();
    onPageChange(page);
  };

  let pageText = page;
  if (page === 'Next') pageText = '&#8250;';
  if (page === 'Back') pageText = '&#8249;';
  if (page === 'First') pageText = '&#171;';
  if (page === 'Last') pageText = '&#187;';

  return (
    <li key={`page_${page}`} className={`page-item ${active && 'active'}`}>
      <button
        type="button"
        className="page-link"
        onClick={handleClick}
        dangerouslySetInnerHTML={{ __html: pageText }}
      />
    </li>
  );
};

const paginationOptions = ({
  totalSize,
  sizePerPage,
  hideSizePerPage,
  page = 1,
  sizePerPageList
}) => {
  let sizeList = [];
  sizeList = sizePerPageList.map(limit => ({
    text: limit,
    value: limit
  }));

  return {
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    hideSizePerPage: hideSizePerPage || totalSize <= sizePerPage,
    sizePerPageList: sizeList,
    hidePageListOnlyOnePage: true,
    showTotal: true,
    totalSize,
    sizePerPage,
    page,
    paginationTotalRenderer: (from, to) => {
      if (totalSize) {
        return (
          <div className="react-bootstrap-table-pagination-total text-muted align-middle text-center text-sm-left ml-0 mb-3 mb-sm-0 ml-sm-2 mt-1 mt-sm-0 d-sm-inline-block">
            {`Showing ${from} to ${to} of ${totalSize} Results`}
          </div>
        );
      }
      return '';
    },
    pageButtonRenderer
  };
};

function AdvanceTable(props) {
  const {
    columns,
    data = [],
    keyField,
    fixed,
    loading,
    remote,
    totalSize,
    sizePerPage,
    sizePerPageList,
    hideSizePerPage = true,
    page,
    onTableChange,
    disableSelect,
    onSelect,
    onSelectAll,
    selected = [],
    className = ''
  } = props;
  return (
    <BootstrapTable
      keyField={keyField || 'id'}
      bootstrap4
      columns={columns}
      data={data}
      remote={remote}
      onTableChange={onTableChange}
      pagination={paginationFactory(
        paginationOptions({
          totalSize: totalSize || data.length,
          page,
          sizePerPage,
          hideSizePerPage,
          sizePerPageList
        })
      )}
      loading={loading}
      bordered={false}
      wrapperClasses={`table-responsive ${className} ${
        fixed ? 'fixed-header' : ''
      }`}
      headerClasses="thead-light"
      {...(disableSelect
        ? {}
        : {
            selectRow: selectRow({
              onSelect,
              onSelectAll,
              selected
            })
          })}
      noDataIndication={() => tableLoading(loading)}
      overlay={overlayFactory({
        spinner: true,
        styles: {
          overlay: base => ({
            ...base,
            text: 'Loading...',
            background: 'rgba(241, 243, 250, 0.9)'
          })
        }
      })}
    />
  );
}

export {
  AdvanceTable as default,
  paginationFactory,
  selectRow,
  tableLoading,
  paginationOptions,
  BootstrapTable
};
