import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import {
  auth as authActions,
  cart as cartActions,
  app as appActions,
  dashboard as dashboardActions
} from '../actions';
import RouteWithSubRoutes from '../components/RouteWithSubRoutes';
import ScrollToTop from '../components/ScrollToTop';
import Navbar from '../components/Navbar';
import LeftSidebar from '../components/LeftSidebar';
import Footer from '../components/FooterDash';
import PageLoader from '../components/PageLoader';
import NoMatch from './NoMatch';

export class Main extends React.Component {
  componentDidMount() {
    document.body.classList.remove('authentication-bg');
    const {
      getUserInfo,
      getCart,
      getPrefetchImageStyle,
      getPrefetchSetting,
      getBpoints,
      getPrefetchStoreCountries,
      getCountryWiseCurrency
    } = this.props;
    getUserInfo();
    getPrefetchSetting();
    getCart();
    getPrefetchImageStyle();
    getBpoints();
    getPrefetchStoreCountries();
    getCountryWiseCurrency();
    // window.addEventListener('resize', this.resize);
    // this.resize();
  }

  resize = () => {
    const currentCondenseNav =
      window.innerWidth > 767.98 && window.innerWidth <= 1028;
    if (currentCondenseNav) {
      document.body.setAttribute('data-leftbar-compact-mode', 'condensed');
    } else {
      document.body.removeAttribute('data-leftbar-compact-mode');
    }
  };

  render() {
    const { routes, userInfoProgress, userInfo } = this.props;
    const subscription = get(userInfo, 'subscription');
    const permission = get(userInfo, 'permission.permission', {});
    const role = get(userInfo, 'permission.role', []);
    let redirectToPackage = true;
    if (role.includes('super-admin') || (subscription && subscription.status)) {
      redirectToPackage = false;
    }
    return (
      <ScrollToTop>
        <div className="dashboard">
          <div className="wrapper">
            {userInfoProgress || isEmpty(userInfo) ? (
              <PageLoader />
            ) : (
              <>
                {!redirectToPackage ? <LeftSidebar /> : null}
                <div className={!redirectToPackage ? 'content-page' : ''}>
                  <div className="content mb-3">
                    {!redirectToPackage ? <Navbar /> : null}
                    <div
                      className={
                        !redirectToPackage ? 'container-fluid' : 'container'
                      }
                    >
                      <Switch>
                        {routes.map(route => {
                          if (
                            route.permission === undefined ||
                            (route.permission && permission[route.permission])
                          ) {
                            return (
                              <RouteWithSubRoutes
                                key={route.name}
                                {...route}
                                redirectToPackage={redirectToPackage}
                              />
                            );
                          }
                          return null;
                        })}
                        <Route component={NoMatch} />
                      </Switch>
                    </div>
                  </div>
                  {!redirectToPackage ? <Footer /> : null}
                </div>
              </>
            )}
          </div>
        </div>
      </ScrollToTop>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user,
  userInfoProgress: state.auth.userInfoProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUserInfo: authActions.getUserInfo,
      getPrefetchStoreCountries: appActions.getPrefetchStoreCountries,
      getPrefetchSetting: appActions.getPrefetchSetting,
      getCountryWiseCurrency: appActions.getCountryWiseCurrency,
      getCart: cartActions.getCart,
      getPrefetchImageStyle: appActions.getPrefetchImageStyle,
      getBpoints: dashboardActions.getBpoints
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
