import { permission as permissionActionTypes } from '../actions';
import initialState from '../constants/initialState';

function permission(state = initialState.permission, action) {
  switch (action.type) {
    case permissionActionTypes.ROLE_LIST_REQUEST:
      return {
        ...state,
        isLoadingRoles: true
      };
    case permissionActionTypes.ROLE_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoadingRoles: false,
          roles: action.data
        };
      }
      return state;
    case permissionActionTypes.USER_LIST_REQUEST:
      return {
        ...state,
        isLoadingUsers: true,
        users: []
      };
    case permissionActionTypes.USER_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoadingUsers: false,
          users: action.data
        };
      }
      return state;
    case permissionActionTypes.USER_LIST_ERROR:
      return {
        ...state,
        isLoadingUsers: false
      };
    case permissionActionTypes.PERMISSION_LIST_REQUEST:
      return {
        ...state,
        isLoadingPermissions: true,
        allPermissions: []
      };
    case permissionActionTypes.PERMISSION_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoadingPermissions: false,
          allPermissions: action.data
        };
      }
      return state;
    case permissionActionTypes.PERMISSION_LIST_ERROR:
      return {
        ...state,
        isLoadingPermissions: false
      };
    case permissionActionTypes.ROLE_PERMISSION_REQUEST:
      return {
        ...state,
        isLoadingPermissions: true,
        rolePermission: {}
      };
    case permissionActionTypes.ROLE_PERMISSION_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoadingPermissions: false,
          rolePermission: action.data
        };
      }
      return state;
    case permissionActionTypes.ROLE_PERMISSION_ERROR:
      return {
        ...state,
        isLoadingPermissions: false
      };
    case permissionActionTypes.UPDATE_ROLE_PERMISSION_REQUEST:
      return {
        ...state,
        isProcessingPermissions: true
      };
    case permissionActionTypes.UPDATE_ROLE_PERMISSION_SUCCESS:
    case permissionActionTypes.UPDATE_ROLE_PERMISSION_ERROR:
      return {
        ...state,
        isProcessingPermissions: false
      };
    default:
      return state;
  }
}

export default permission;
