import React from 'react';
import { change, Field, Form, getFormValues, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {
  selectField,
  inputField,
  inputGroupField
} from '../../components/Fields';
import { app as appActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

const statusOptions = [
  { value: 'PROCESSING', label: 'Processing' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'SUCCESS', label: 'Success' },
  { value: 'FAILED', label: 'Failed' }
];

class OrderFilterForm extends React.PureComponent {
  componentDidMount() {
    const { handleSubmit, getCountry } = this.props;
    getCountry();
    setTimeout(() => handleSubmit(), 0);
  }

  handleFilterOption = val => {
    const { dispatch, formValues } = this.props;
    dispatch(change('orderFilterForm', 'filter_option', val));
    dispatch(change('orderFilterForm', 'filter', ''));
    // if (formValues.filter) this.handleChangeFilter();
  };

  handleChangeFilter = event => {
    const { handleSubmit } = this.props;
    setTimeout(() => handleSubmit(), 0);
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      initialValues,
      formValues,
      isLoading,
      isAdmin,
      reset,
      countryList,
      userInfo
    } = this.props;

    const isAssociate = get(userInfo, 'permission.role', []).includes(
      'associate'
    );

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          {!isAssociate ? (
            <div className="col-12 col-lg-3 col-md-6">
              <Field
                name="shipping_country"
                component={selectField}
                placeholder="All Country"
                options={[...countryList]}
                isClearable
              />
            </div>
          ) : null}
          <div className="col-12 col-lg-3 col-md-6">
            <Field
              name="payment_status"
              component={selectField}
              // multiple
              notSearchable
              isClearable
              // label="Payment Status"
              placeholder="All Status"
              options={statusOptions}
              // onChange={this.handleChangeFilter}
            />
          </div>
          <div className="col-12 col-lg-3 col-md-6">
            <Field name="start" component="input" type="hidden" />
            <Field name="end" component="input" type="hidden" />
            <Field name="filter_option" component="input" type="hidden" />
            <Field name="date_filter" component="input" type="hidden" />
            {isAdmin ? (
              <Field
                name="filter"
                component={inputGroupField}
                className="form-control"
                // label="Search"
                placeholder={
                  formValues.filter_option === 'INVOICE_NUMBER'
                    ? 'Inv. no.'
                    : 'ID or Name'
                }
                // onChange={this.handleChangeFilter}
              >
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    drop="right"
                    id="filterOptionDropdown"
                  >
                    {formValues.filter_option === 'INVOICE_NUMBER'
                      ? 'Invoice'
                      : 'Associate or Customer'}
                  </Dropdown.Toggle>
                  <Dropdown.Menu alignRight>
                    <Dropdown.Item
                      onClick={() => this.handleFilterOption('INVOICE_NUMBER')}
                    >
                      Invoice
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => this.handleFilterOption('USERS')}
                    >
                      Associate or Customer
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Field>
            ) : (
              <Field
                name="filter"
                component={inputField}
                className="form-control"
                // label="Search"
                placeholder="Inv. no."

                // onChange={this.handleChangeFilter}
              />
            )}
          </div>
          {isAssociate ? <div className="col-12 col-lg-3 col-md-6" /> : null}
          <div className="col-12 col-lg-3 col-md-6">
            <button
              type="submit"
              disabled={isLoading}
              className="btn btn-primary btn-block"
            >
              {isLoading ? <img src={btnLoader} alt="" /> : 'Filter'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  countryList: state.app.countryList,
  userInfo: state.auth.user,
  formValues: getFormValues('orderFilterForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry
    },
    dispatch
  );

const OrderFilterFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderFilterForm);

export default reduxForm({
  form: 'orderFilterForm' // a unique identifier for this form
})(OrderFilterFormConnected);
