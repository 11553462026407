import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';
import ProfileForm from './ProfileForm';
import ChangePasswordForm from './ChangePasswordForm';
import { profile as profileActions } from '../../actions';
import InnerLoader from '../../components/InnerLoader';

class Profile extends React.Component {
  componentDidMount() {
    const { getProfile } = this.props;
    getProfile();
  }

  render() {
    const { profile, isLoading, updateProgress } = this.props;
    let profileData = null;
    if (profile && profile.associate_info) {
      let city = '';
      let otherCity = '';
      if (!Number.isNaN(Number(profile.associate_info.city))) {
        city = parseInt(profile.associate_info.city, 10);
      } else {
        city = 'others';
        otherCity = profile.associate_info.city;
      }
      profileData = {
        id: profile.id,
        uuid: profile.uuid,
        first_name: profile.first_name,
        last_name: profile.last_name,
        email: profile.email,
        gender: profile.associate_info.gender
          ? profile.associate_info.gender
          : undefined,
        dob: profile.associate_info.dob
          ? moment(profile.associate_info.dob).format('DD/MM/YYYY')
          : undefined,
        country: profile.associate_info.country
          ? profile.associate_info.country
          : undefined,
        state: profile.associate_info.state
          ? parseInt(profile.associate_info.state, 10)
          : undefined,
        city: profile.associate_info.city ? city : undefined,
        otherCity,
        company_name: profile.associate_info.company_name
          ? profile.associate_info.company_name
          : undefined,
        tax_number: profile.associate_info.tax_number
          ? profile.associate_info.tax_number
          : undefined,
        phone_number: profile.phone_number
          ? `+${profile.phone_number}`
          : undefined,
        referral_link: profile.referral_url,
        associate_referral_url: profile.associate_referral_url,
        customer_referral_url: profile.customer_referral_url
      };
    }

    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.profile.breadcrumb} />
              <h4 className="page-title">{routePath.profile.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0">Edit your profile</h4>
                <p className="text-muted font-13 m-b-30">
                  Update your profile details here.
                </p>
                {!isLoading && profileData ? (
                  <ProfileForm
                    initialValues={profileData}
                    inProgress={updateProgress}
                  />
                ) : (
                  <InnerLoader type="table" />
                )}
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <h4 className="mt-0">Reset Password</h4>
                <p className="text-muted font-13 m-b-30">
                  Change your account password using the form below.
                </p>
                <ChangePasswordForm />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.profile.isLoading,
  updateProgress: state.profile.updateProgress,
  profile: state.profile.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProfile: profileActions.getProfileById
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
