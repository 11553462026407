import routePath from './routePath';

const menu = [
  {
    label: 'Dashboard',
    section: true,
    children: [
      {
        label: routePath.dashboard.title,
        to: routePath.dashboard.path,
        icon: 'mdi mdi-home'
      }
    ]
  },
  {
    label: 'Shop',
    section: true,
    children: [
      {
        label: routePath.product.title,
        to: routePath.product.path,
        icon: 'fas fa-boxes',
        permission: routePath.product.permission
      },
      {
        label: routePath.category.title,
        to: routePath.category.path,
        icon: 'far fa-list-alt',
        permission: routePath.category.permission
      },

      {
        label: routePath.order.title,
        to: routePath.order.path,
        icon: 'fas fa-shopping-cart'
      }
    ]
  },
  // {
  //   label: 'Inventory',
  //   section: true,
  //   children: [
  //     {
  //       label: 'Stock',
  //       to: '#',
  //       permission: routePath.productAdd.permission,
  //       icon: 'fas fa-box-open'
  //     }
  //   ]
  // },
  {
    label: 'Users',
    section: true,
    children: [
      {
        label: routePath.associate.title,
        to: routePath.associate.path,
        permission: routePath.associate.permission,
        icon: 'fas fa-user',
        children: [
          {
            label: 'Show all',
            to: routePath.associate.path,
            permission: routePath.associate.permission,
            icon: 'fas fa-user'
          },
          {
            label: routePath.tree.title,
            to: routePath.tree.path,
            permission: routePath.tree.permission,
            icon: 'fas fa-sitemap'
          }
          // {
          //   label: 'Downline Details',
          //   to: '#',
          //   permission: routePath.associate.permission,
          //   icon: 'fas fa-user'
          // },
          // {
          //   label: 'Move Associate',
          //   to: '#',
          //   permission: routePath.associate.permission,
          //   icon: 'fas fa-user'
          // }
        ]
      },
      // ,
      // {
      //   label: 'Admin & Staffs',
      //   to: '#',
      //   permission: routePath.associate.permission,
      //   icon: 'fas fa-user'
      // }
      {
        label: routePath.customer.title,
        to: routePath.customer.path,
        permission: routePath.customer.permission,
        icon: 'fas fa-user',
        children: [
          {
            label: 'Show all',
            to: routePath.customer.path,
            permission: routePath.customer.permission,
            icon: 'fas fa-user'
          },
          // add customer downlines to sidebar
          {
            label: routePath.treeCustomer.title,
            to: routePath.treeCustomer.path,
            permission: routePath.treeCustomer.permission,
            icon: 'fas fa-sitemap'
          }
        ]
      }
    ]
  },
  {
    label: 'Payout',
    section: true,
    children: [
      {
        label: 'Payout Statement',
        to: routePath.payoutList.path,
        permission: routePath.payoutList.permission,
        icon: 'mdi mdi-cash'
      },
      {
        label: 'Wallet Login',
        to: routePath.walletLogin.path,
        permission: routePath.walletLogin.permission,
        icon: 'mdi mdi-wallet',
        externalLink: process.env.REACT_APP_WALLET_ADMIN // property to define an  external link
      }
    ]
  },
  // {
  //   label: 'Bonus Plans',
  //   section: true,
  //   children: [
  //     {
  //       label: 'FSB',
  //       to: '#',
  //       permission: routePath.associatePackage.permission,
  //       icon: 'fas fa-box-open'
  //     }
  //     // {
  //     //   label: 'CB',
  //     //   to: '#',
  //     //   permission: routePath.associatePackage.permission,
  //     //   icon: 'fas fa-box-open'
  //     // }
  //   ]
  // },
  {
    label: 'Admin',
    section: true,
    children: [
      {
        label: routePath.role.title,
        to: routePath.role.path,
        permission: routePath.role.permission,
        icon: 'fas fa-shield-alt'
      },
      {
        label: routePath.generalSetting.title,
        to: routePath.generalSetting.path,
        permission: routePath.generalSetting.permission,
        icon: 'mdi mdi-wrench'
      },
      {
        label: routePath.storeSetting.title,
        to: routePath.storeSetting.path,
        permission: routePath.storeSetting.permission,
        icon: 'mdi mdi-store'
      },
      // {
      //   label: 'Address Settings',
      //   to: routePath.addressSetting.path,
      //   permission: routePath.addressSetting.permission,
      //   icon: 'mdi mdi-map'
      // },
      {
        label: routePath.tax.title,
        to: routePath.tax.path,
        permission: routePath.tax.permission,
        icon: 'mdi mdi-percent'
      },
      {
        label: routePath.shipping.title,
        to: routePath.shipping.path,
        permission: routePath.shipping.permission,
        icon: 'mdi mdi-truck-fast'
      },
      {
        label: routePath.media.title,
        to: routePath.media.path,
        permission: routePath.media.permission,
        icon: 'mdi mdi-image'
      },
      // {
      //   label: routePath.paymentGateway.title,
      //   to: routePath.paymentGateway.path,
      //   permission: routePath.paymentGateway.permission,
      //   icon: 'mdi mdi-cash-multiple'
      // },
      {
        label: 'Locations',
        to: routePath.country.path,
        permission: routePath.country.permission,
        icon: 'mdi mdi-map-marker'
        // children: [
        // {
        //   label: routePath.country.title,
        //   to: routePath.country.path,
        //   permission: routePath.country.permission,
        //   icon: 'mdi mdi-map-marker'
        // }
        // {
        //   label: routePath.state.title,
        //   to: routePath.state.path,
        //   permission: routePath.state.permission,
        //   icon: 'mdi mdi-map-marker'
        // },
        // {
        //   label: routePath.city.title,
        //   to: routePath.city.path,
        //   permission: routePath.city.permission,
        //   icon: 'mdi mdi-map-marker'
        // }
        // ]
      },
      {
        label: routePath.coupons.title,
        to: routePath.coupons.path,
        // permission: routePath.coupons.permission,
        icon: 'mdi mdi-ticket-percent'
      }
      // {
      //   label: routePath.template.title,
      //   to: routePath.template.path,
      //   permission: routePath.template.permission,
      //   icon: 'mdi mdi-email-plus'
      // }
      // {
      //   label: 'Notifications',
      //   to: '#',
      //   permission: routePath.template.permission,
      //   icon: 'mdi mdi-bell'
      // },
      // {
      //   label: routePath.loginHistory.title,
      //   to: routePath.loginHistory.path,
      //   permission: routePath.template.permission,
      //   icon: 'mdi mdi-history'
      // },
      // {
      //   label: 'Backup',
      //   to: '#',
      //   permission: routePath.template.permission,
      //   icon: 'mdi mdi-content-save'
      // }
    ]
  },
  {
    label: 'Associates',
    section: true,
    children: [
      {
        label: routePath.associatePackage.title,
        to: routePath.associatePackage.path,
        permission: routePath.associatePackage.permission,
        icon: 'fas fa-box-open'
      },
      {
        label: routePath.rank.title,
        to: routePath.rank.path,
        permission: routePath.rank.permission,
        icon: 'fas fa-ribbon'
      },
      {
        label: routePath.generation.title,
        to: routePath.generation.path,
        permission: routePath.generation.permission,
        icon: 'fas fa-code-branch'
      },
      {
        label: routePath.fsb.title,
        to: routePath.fsb.path,
        permission: routePath.fsb.permission,
        icon: 'fas fa-ribbon'
      }
      // {
      //   label: 'CB',
      //   to: '#',
      //   permission: routePath.fsb.permission,
      //   icon: 'fas fa-ribbon'
      // }
    ]
  }
];

export default menu;
