import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, Form, reduxForm, submit } from 'redux-form';
import { components } from 'react-select';
import { selectField } from '../../components/Fields';

class DownlineAssociateForm extends React.PureComponent {
  componentDidMount() {
    const { dispatch } = this.props;
    // dispatch(submit('downlineAssociateForm'));
  }

  render() {
    const {
      handleSubmit,
      submitForm,
      associateList,
      filterHandler
    } = this.props;

    return (
      <Form noValidate onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-12">
            <Field
              name="associate"
              component={selectField}
              multiple
              // checkbox
              // groupClassName="react-select-checkbox"
              placeholder="Choose..."
              label="Choose Downline Associates"
              options={associateList}
              feedback={filterHandler}
              formatOptionLabel={({ value, label, levels }) => (
                <div style={{ display: 'flex' }}>
                  {levels ? (
                    <div className="text-primary mr-1">
                      <i
                        style={{
                          marginLeft: levels > 1 ? `${levels * 10}px` : 0
                        }}
                        className="mdi mdi-subdirectory-arrow-right"
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <div className="font-weight-semibold">{label}</div>
                </div>
              )}
              components={{
                MultiValueLabel: ({ props, data }) => (
                  <components.MultiValueLabel {...props}>
                    <div className="mx-1">{data.label}</div>
                  </components.MultiValueLabel>
                )
              }}
              styles={{
                option: (styles, { data }) => {
                  let bg = {};
                  if (data.levels === 0 && data.parent) {
                    bg = {
                      backgroundColor: '#0E4D92',
                      color: '#fff!important'
                    };
                  }
                  return {
                    ...styles,
                    ...bg
                  };
                }
              }}
            />
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatch: action => action
    },
    dispatch
  );

const DownlineAssociateFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownlineAssociateForm);

export default reduxForm({
  form: 'downlineAssociateForm' // a unique identifier for this form
})(DownlineAssociateFormConnected);
