import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';

import AdvanceTable from '../../components/AdvanceTable';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import StatusSwitch from '../../components/StatusSwitch';
import TemplateViewModal from './TemplateViewModal';
import TemplateSettingForm from './TemplateSettingForm';

class Template extends React.Component {
  state = {
    modal: false
  };

  columns = [
    {
      dataField: 'title',
      text: 'Email Template',
      formatter: (cell, row) => (
        <Link to="/template/1/edit" className="dashed-underline">
          {cell}
        </Link>
      )
    },
    {
      dataField: 'assigned_to',
      text: 'Assigned to',
      headerClasses: 'text-center',
      headerAttrs: { width: '13%' },
      classes: 'text-center'
    },
    {
      dataField: 'type',
      text: 'Content Type',
      headerClasses: 'text-center',
      classes: 'text-center',
      headerAttrs: { width: '13%' }
    },
    {
      dataField: 'recipients',
      text: 'Recipient(s)',
      headerClasses: 'text-center',
      headerAttrs: { width: '12%' },
      classes: 'text-center'
    },
    {
      dataField: 'status',
      text: 'Active?',
      headerClasses: 'text-center',
      classes: 'text-center',
      headerAttrs: { width: '9%' },
      formatter: (cell, row) => (
        <StatusSwitch name="switch1" checked={cell} data-switch="success" />
      )
    },
    {
      dataField: 'date',
      text: 'Date',
      sort: true,
      headerClasses: 'text-center',
      headerAttrs: { width: '15%' },
      classes: 'text-center'
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      headerClasses: 'text-center',
      headerAttrs: { width: '15%' },
      classes: 'text-center',
      formatter: (cell, row) => (
        <>
          <button
            type="button"
            className="action-icon btn btn-link"
            onClick={this.handleModal}
          >
            <i className="action-icon mdi mdi-eye" />
          </button>
          <Link
            className="action-icon btn btn-link"
            to={`/template/${row.id}/edit`}
          >
            <i className="action-icon mdi mdi-square-edit-outline" />
          </Link>
          <Link
            className="action-icon btn btn-link"
            to={routePath.template.path}
          >
            <i className="action-icon mdi mdi-delete" />
          </Link>
        </>
      )
    }
  ];

  loadData = rows => {
    const data = [];
    for (let i = 1; i < rows; i += 1) {
      data.push({
        id: i,
        title: 'New account created',
        assigned_to: 'Testing',
        type: 'HTML',
        recipients: 'RETAIL',
        status: true,
        date: 'August 05 2018'
      });
    }
    return data;
  };

  titleFormatter = (cell, row) => (
    <Link to="/template/1/edit" className="dashed-underline">
      {cell}
    </Link>
  );

  handleModal = () => {
    this.setState(a => ({ modal: !a.modal }));
  };

  handleSubmit = values => {
    const { createTemplate } = this.props;
    // createTemplate(values);
  };

  render() {
    const { modal } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.template.breadcrumb} />
              <h4 className="page-title">{routePath.template.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <TemplateSettingForm submitForm={this.handleSubmit} />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-sm-12 col-lg-7" />
                  <div className="col-sm-12 col-lg-5 text-lg-right">
                    <div className="btn-group btn-group-sm">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          <Dropdown.Item href={routePath.templateAdd.path}>
                            <i className="mdi mdi-plus-circle-outline" /> Add
                            new
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-content-copy" /> Copy
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            <i className="mdi mdi-trash-can" /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>

                <AdvanceTable data={this.loadData(16)} columns={this.columns} />
              </div>
            </div>
          </div>
        </div>
        {modal && <TemplateViewModal handleModal={this.handleModal} />}
      </>
    );
  }
}

export default Template;
