const coupons = {
  COUPON_ALL_LIST_REQUEST: 'COUPON_ALL_LIST_REQUEST',
  COUPON_ALL_LIST_SUCCESS: 'COUPON_ALL_LIST_SUCCESS',
  COUPON_ALL_LIST_ERROR: 'COUPON_ALL_LIST_ERROR',
  COUPON_CREATE_REQUEST: 'COUPON_CREATE_REQUEST',
  COUPON_CREATE_SUCCESS: 'COUPON_CREATE_SUCCESS',
  COUPON_CREATE_ERROR: 'COUPON_CREATE_ERROR',
  COUPON_UPDATE_REQUEST: 'COUPON_UPDATE_REQUEST',
  COUPON_UPDATE_SUCCESS: 'COUPON_UPDATE_SUCCESS',
  COUPON_UPDATE_ERROR: 'COUPON_UPDATE_ERROR',
  COUPON_REQUEST: 'COUPON_REQUEST',
  COUPON_SUCCESS: 'COUPON_SUCCESS',
  COUPON_ERROR: 'COUPON_ERROR',
  COUPON_STATUS_UPDATE_REQUEST: 'COUPON_STATUS_UPDATE_REQUEST',
  COUPON_STATUS_UPDATE_SUCCESS: 'COUPON_STATUS_UPDATE_SUCCESS',
  COUPON_STATUS_UPDATE_ERROR: 'COUPON_STATUS_UPDATE_ERROR',
  COUPON_SHOW_MODAL: 'COUPON_SHOW_MODAL',
  COUPON_HIDE_MODAL: 'COUPON_HIDE_MODAL',
  COUPON_SHOW_ALERT: 'COUPON_SHOW_ALERT',
  COUPON_HIDE_ALERT: 'COUPON_HIDE_ALERT',
  COUPON_DELETE_REQUEST: 'COUPON_DELETE_REQUEST',
  COUPON_DELETE_SUCCESS: 'COUPON_DELETE_SUCCESS',
  COUPON_DELETE_ERROR: 'COUPON_DELETE_ERROR',
  COUPON_ALL_ORDER_REQUEST: 'COUPON_ALL_ORDER_REQUEST',
  COUPON_ALL_ORDER_SUCCESS: 'COUPON_ALL_ORDER_SUCCESS',
  COUPON_ALL_ORDER_ERROR: 'COUPON_ALL_ORDER_ERROR',
  COUPON_ORDER_REQUEST: 'COUPON_ORDER_REQUEST',
  COUPON_ORDER_SUCCESS: 'COUPON_ORDER_SUCCESS',
  COUPON_ORDER_ERROR: 'COUPON_ORDER_ERROR',
  getAllCoupons: data => ({
    type: coupons.COUPON_ALL_LIST_REQUEST,
    data
  }),
  createCoupon: data => ({ type: coupons.COUPON_CREATE_REQUEST, data }),
  updateCoupon: data => ({ type: coupons.COUPON_UPDATE_REQUEST, data }),
  updateCouponStatus: data => ({
    type: coupons.COUPON_STATUS_UPDATE_REQUEST,
    data
  }),
  getCoupon: id => ({ type: coupons.COUPON_REQUEST, id }),
  showModal: data => ({ type: coupons.COUPON_SHOW_MODAL, data }),
  hideModal: data => ({ type: coupons.COUPON_HIDE_MODAL, data }),
  showAlert: data => ({ type: coupons.COUPON_SHOW_ALERT, data }),
  hideAlert: data => ({ type: coupons.COUPON_HIDE_ALERT, data }),
  deleteCoupon: id => ({ type: coupons.COUPON_DELETE_REQUEST, id }),
  getAllOrderCoupons: data => ({
    type: coupons.COUPON_ALL_ORDER_REQUEST,
    data
  }),
  getOrderCoupon: data => ({ type: coupons.COUPON_ORDER_REQUEST, data })
};

export default coupons;
