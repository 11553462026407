import React from 'react';
import { Link } from 'react-router-dom';
import routePath from '../constants/routePath';

function BreadCrumb({ list }) {
  return (
    <div className="page-title-right">
      <ol className="breadcrumb m-0">
        <li className="breadcrumb-item">
          <Link to={routePath.dashboard.path} className="breadcrumb-link">
            <i className="action-icon fas fa-home" />
          </Link>
        </li>
        {list.map((item, index) => {
          let value = {};
          if (typeof item === 'object') {
            value = item;
          } else {
            value = routePath[item];
          }
          if (index === list.length - 1) {
            return (
              <li
                key={`${index}_menuitem`}
                className="breadcrumb-item active"
                aria-current="page"
              >
                {value.title}
              </li>
            );
          }
          return (
            <li
              key={`${index}_menuitem`}
              className="breadcrumb-item"
              aria-current="page"
            >
              <Link to={value.path} className="breadcrumb-link">
                {value.title}
              </Link>
            </li>
          );
        })}
      </ol>
    </div>
  );
}

export default BreadCrumb;
