/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InnerLoader from '../../components/InnerLoader';
import { storeAddress as storeAddressActions } from '../../actions';
import AdvanceTable from '../../components/AdvanceTable';
import StatusSwitch from '../../components/StatusSwitch';
import CouponToggle from './CouponToggle';
import Icon from '../../components/Icon';

class StoreAddress extends React.Component {
  state = {
    updatedStatus: {},
    page: 1
  };

  componentDidMount() {
    const { getStoreAddressList } = this.props;
    getStoreAddressList();
  }

  updatePickupStatus = (data, row) => {
    const { getStoreAddressList } = this.props;
    const { updateStorePickup } = this.props;
    updateStorePickup(data);

    setTimeout(() => {
      getStoreAddressList();
    }, 300);
  };

  getStatus = (stateValue, fetchedValue) => {
    if (typeof stateValue === 'boolean') return stateValue;
    return fetchedValue;
  };

  // This function will update table
  handleTableChange = (type, { page, sizePerPage }) => {
    const { getStoreAddressList } = this.props;
    if (type === 'pagination') {
      getStoreAddressList({ page, per_page: sizePerPage });
    }
    this.setState({ page });
  };

  render() {
    const { storeAddressList, filterValues } = this.props;
    const { page } = this.state;
    const {
      isLoading,
      data: {
        country_settings: { data: storeList, per_page: perPage, total }
      }
    } = storeAddressList;

    const columns = [
      {
        dataField: 'store_country',
        text: 'Store Country',
        headerAttrs: { width: '250px' }
      },
      {
        dataField: 'company_phone',
        text: 'Phone'
      },
      {
        dataField: 'store_pickup',
        text: 'Store pickup',
        formatter: (col, row) => {
          // console.log({ col, row });
          const { store_address_id: sti } = row;
          return (
            <StatusSwitch
              checked={this.getStatus(
                // eslint-disable-next-line react/destructuring-assignment
                this.state.updatedStatus[sti],
                Boolean(col)
              )}
              onChange={() =>
                this.updatePickupStatus(
                  { store_country_iso2: row.store_country_iso2 },
                  row
                )
              }
              id={row.id}
            />
          );
        }
      },
      {
        dataField: 'coupon_status',
        text: 'Coupon Visibility',
        formatter: (col, row) => {
          return <CouponToggle status={col} country={row.store_country_iso2} />;
        }
      },
      {
        dataField: 'country',
        text: 'Action',
        headerAttrs: { width: '15%' },
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => (
          <Link
            className="btn btn-link"
            to={`/setting/address/edit/${row.store_country_iso2}`}
          >
            <Icon
              title="Edit"
              iconClassName="mdi-square-edit-outline"
              isAction
            />
          </Link>
        )
      }
    ];
    if (isLoading || !Array.isArray(storeList)) return null;
    return (
      <>
        {isLoading && <InnerLoader type="table" />}
        <div className="table-wrapper">
          {!isLoading && (
            <AdvanceTable
              data={storeList}
              columns={columns}
              loading={isLoading}
              remote={{ pagination: true }}
              totalSize={total}
              sizePerPage={perPage}
              sizePerPageList={filterValues}
              page={page}
              hideSizePerPage={false}
              onTableChange={this.handleTableChange}
              // onSelect={this.handleOnSelect}
              // onSelectAll={this.handleOnSelectAll}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  storeAddressList: state.storeAddress,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStoreAddressList: storeAddressActions.getStoreAddressList,
      updateStorePickup: storeAddressActions.updateStorePickup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoreAddress);
