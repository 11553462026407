import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import Tooltip from './ProductModalFormDisabledTooltip';

const ProductModalFormDisabled = ({
  data,
  formValues,
  taxOptions,
  currency
}) => {
  const fieldValues = data || formValues;

  const taxStatusOption = [
    { value: true, label: 'Taxable' },
    { value: false, label: 'Not taxable' }
  ];

  const getValuetaxable = () => {
    return taxStatusOption.filter(val => val.value === fieldValues.taxable);
  };

  const getValueTaxId = () => {
    return taxOptions.filter(val => val.value === fieldValues.tax_id);
  };

  return (
    <div>
      <div className="border-bottom mt-2">
        <div className="form-group  row">
          <label className="col-5 col-form-label">
            Regular Price <Tooltip position="right" data="Regular Price" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <div className="input-group-text">{currency}</div>
              </div>
              <input
                className="form-control form-control-sm"
                type="number"
                value={fieldValues.regular_price}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="form-group  row ">
          <label className="col-5 col-form-label">
            Sale Price <Tooltip position="right" data="Sale Price" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <div className="input-group-text">{currency}</div>
              </div>
              <input
                className="form-control form-control-sm"
                type="number"
                value={fieldValues.sale_price}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="form-group  row ">
          <label className="col-5 col-form-label">
            B-Points <Tooltip position="right" data="B-Points" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend" />
              <input
                className="form-control form-control-sm"
                type="number"
                value={fieldValues.bpoint}
                readOnly
              />
            </div>
          </div>
        </div>
        <div className="form-group  row ">
          <label className="col-5 col-form-label">
            C-Points <Tooltip position="right" data="Customer Points" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend" />
              <input
                className="form-control form-control-sm"
                type="number"
                value={fieldValues.cpoint}
                readOnly
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-bottom pt-2">
        <div className="form-group  row">
          <label className="col-5 col-form-label">
            Tax Status <Tooltip position="right" data="Tax Status" />
          </label>
          <div className="col-7">
            <Select
              value={getValuetaxable()}
              isDisabled
              options={taxStatusOption}
            />
          </div>
        </div>
        <div className="form-group  row">
          <label className="col-5 col-form-label">
            Tax Rate <Tooltip position="right" data="Tax Rate" />
          </label>
          <div className="col-7">
            <Select value={getValueTaxId()} isDisabled options={taxOptions} />
          </div>
        </div>
      </div>
      <div className="pt-2">
        <div className="form-group  row ">
          <label className="col-5 col-form-label">
            Member Price <Tooltip position="right" data="Member Price" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <div className="input-group-text">{currency}</div>
              </div>
              <input
                className="form-control form-control-sm"
                readOnly
                type="number"
                value={fieldValues.member_price}
              />
            </div>
          </div>
        </div>
        <div className="form-group  row ">
          <label className="col-5 col-form-label">
            Member Price (VAT){' '}
            <Tooltip position="right" data="Member Price (VAT)" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <div className="input-group-text">{currency}</div>
              </div>
              <input
                className="form-control form-control-sm"
                readOnly
                value={fieldValues.member_price_vat}
              />
            </div>
          </div>
        </div>
        <div className="form-group  row ">
          <label className="col-5 col-form-label">
            Customer Price (VAT){' '}
            <Tooltip position="right" data="Customer Price (VAT)" />
          </label>
          <div className="col-7">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <div className="input-group-text">{currency}</div>
              </div>
              <input
                className="form-control form-control-sm"
                readOnly
                value={fieldValues.customer_price_vat}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  formValues: getFormValues('productForm')(state)
});

export default connect(
  mapStateToProps,
  null
)(ProductModalFormDisabled);
