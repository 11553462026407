const profile = {
  PROFILE_NAME_AVAILABILITY_REQUEST: 'PROFILE_NAME_AVAILABILITY_REQUEST',
  PROFILE_NAME_AVAILABILITY_SUCCESS: 'PROFILE_NAME_AVAILABILITY_SUCCESS',
  PROFILE_NAME_AVAILABILITY_ERROR: 'PROFILE_NAME_AVAILABILITY_ERROR',
  PROFILE_UPDATE_REQUEST: 'PROFILE_UPDATE_REQUEST',
  PROFILE_UPDATE_SUCCESS: 'PROFILE_UPDATE_SUCCESS',
  PROFILE_UPDATE_ERROR: 'PROFILE_UPDATE_ERROR',
  PROFILE_IMAGE_REQUEST: 'PROFILE_IMAGE_REQUEST',
  PROFILE_IMAGE_SUCCESS: 'PROFILE_IMAGE_SUCCESS',
  PROFILE_IMAGE_ERROR: 'PROFILE_IMAGE_ERROR',
  PROFILE_REQUEST: 'PROFILE_REQUEST',
  PROFILE_SUCCESS: 'PROFILE_SUCCESS',
  PROFILE_ERROR: 'PROFILE_ERROR',
  SET_REFERRAL_PROFILE: 'SET_REFERRAL_PROFILE',
  checkProfileAvailability: name => ({
    type: profile.PROFILE_NAME_AVAILABILITY_REQUEST,
    name
  }),
  updateProfile: data => ({ type: profile.PROFILE_UPDATE_REQUEST, data }),
  updateProfileImage: data => ({ type: profile.PROFILE_IMAGE_REQUEST, data }),
  getProfileById: id => ({ type: profile.PROFILE_REQUEST, id }),
  setReferralProfile: status => ({ type: profile.SET_REFERRAL_PROFILE, status })
};

export default profile;
