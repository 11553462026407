import React from 'react';
import BreadCrumb from 'react-bootstrap/Breadcrumb';
import { Link } from 'react-router-dom';
import routePath from '../../constants/routePath';

export default function LinkBack({ linkItems }) {
  return (
    <BreadCrumb className="page-title-right">
      <BreadCrumb.Item>
        <Link to={routePath.dashboard.path}>
          <i className="action-icon fas fa-home" />
        </Link>
      </BreadCrumb.Item>
      {linkItems.map(({ title, link }, idx) => (
        <BreadCrumb.Item
          key={idx}
          href={link}
          active={idx === linkItems.length - 1}
        >
          {title}
        </BreadCrumb.Item>
      ))}
    </BreadCrumb>
  );
}
