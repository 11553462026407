import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';
import TreeFilterForm from '../Tree/TreeFilterForm';
import { customer as customerActionTypes } from '../../actions';

class Customer extends React.Component {
  searchedInput = null;

  selectedMonth = null;

  state = {
    page: 1
  };

  componentDidMount() {
    const { getAllCustomer } = this.props;
    getAllCustomer();
  }

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAllCustomer, getCustomerByAssocId } = this.props;
    if (type === 'pagination') {
      const options = { params: { page, per_page: perPage } };
      if (this.selectedMonth && this.selectedMonth.value) {
        const mmyy = this.selectedMonth.value.split(',');
        const [mm, yy] = mmyy;
        options.params.mm = mm;
        options.params.yy = yy;
      }
      if (this.searchedInput) {
        options.id = this.searchedInput.value;
        getCustomerByAssocId(options);
      } else {
        getAllCustomer(options.params);
      }
    }
    this.setState({ page });
  };

  handleOnChange = (input, selectedMonth) => {
    const { getCustomerByAssocId, getAllCustomer } = this.props;
    this.setState({ page: 1 });
    const options = {};
    this.selectedMonth = selectedMonth;
    if (selectedMonth && selectedMonth.value) {
      const mmyy = selectedMonth.value.split(',');
      options.params = {
        mm: mmyy[0],
        yy: mmyy[1]
      };
    }
    if (input) {
      this.searchedInput = input;
      options.id = input.value;
      getCustomerByAssocId(options);
    } else if (input === undefined && this.searchedInput) {
      options.id = this.searchedInput.value;
      getCustomerByAssocId(options);
    } else {
      this.searchedInput = null;
      getAllCustomer(options.params);
    }
  };

  render() {
    const { allCustomer, isLoading, filterValues } = this.props;
    const { page } = this.state;

    const columns = [
      {
        dataField: 'name',
        text: 'ID',
        // headerAttrs: { width: '12%' },
        formatter: (col, row) => (
          <>
            {row && row.country && (
              <span
                className={`flag-icon flag-icon-${row.country.toLowerCase()} mr-1`}
              />
            )}
            <span>{row.user.uuid}</span>
          </>
        )
      },
      {
        dataField: 'name',
        text: 'Name',
        formatter: (col, row) => (
          <span>{`${row.user.first_name} ${row.user.last_name}`}</span>
        )
      },
      {
        dataField: 'email',
        text: 'Email',
        formatter: (col, row) => <span>{row.user.email}</span>
      },
      {
        dataField: 'phone',
        text: 'Phone',
        formatter: (col, row) => <span>{row.user.phone_number}</span>
      },
      {
        dataField: 'order_count',
        text: 'Order Count',
        formatter: (col, row) => (
          <span>{row.success_order ? row.success_order.length : 0}</span>
        )
      },
      {
        dataField: 'customer_bp',
        text: 'Total BP',
        formatter: (col, row) => (
          <span>
            {row.success_order && row.success_order.length
              ? row.success_order.reduce((accumulator, currentValue) => {
                  if (currentValue.bp_transaction) {
                    return (
                      accumulator + currentValue.bp_transaction[0].earned_points
                    );
                  }
                  return accumulator;
                }, 0)
              : '0.00'}
          </span>
        )
      }
    ];

    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.customer.breadcrumb} />
              <h4 className="page-title">{routePath.customer.title}</h4>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <TreeFilterForm submitForm={this.handleOnChange} />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                {/* <h4 className="header-title">Bordered table</h4>
                <p className="text-muted font-14 mb-4">
                  This list showing your own customers
                </p> */}
                <div className="table-responsive-sm">
                  <AdvanceTable
                    data={allCustomer.data}
                    columns={columns}
                    loading={isLoading}
                    remote={{ pagination: true }}
                    totalSize={allCustomer.total}
                    sizePerPage={allCustomer.per_page}
                    sizePerPageList={filterValues}
                    page={page}
                    hideSizePerPage={false}
                    onTableChange={this.handleTableChange}
                    disableSelect
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  allCustomer: state.customer.allCustomer,
  isLoading: state.customer.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllCustomer: customerActionTypes.getAllCustomer,
      getCustomerByAssocId: customerActionTypes.getCustomerByAssocId
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Customer);
