/* global appConfig */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { get } from 'lodash';

import { auth as authActions, app as appActions } from '../actions';
import avatar from '../assets/images/avatar.svg';
import routePath from '../constants/routePath';
import CartDropdown from '../containers/Cart/CartDropdown';
import { profileImage } from '../constants/imageSize';
import Image from './Image';

function Navbar({ doLogout, userInfo, bpoint }) {
  const userRoles = userInfo.permission.role;
  const toggleMenu = () => {
    document.body.classList.add('sidebar-enable');
  };
  const isAdmin = userRoles.indexOf('super-admin') > -1;
  let pImage = get(userInfo, 'profile_picture_file.file_path', null);
  if (pImage) {
    pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
  } else {
    pImage = avatar;
  }

  return (
    <nav className="navbar-custom">
      <button
        type="button"
        onClick={() => toggleMenu()}
        className="button-menu-mobile open-left disable-btn"
      >
        <i className="mdi mdi-menu" />
      </button>
      <NavLink
        className="py-3 d-lg-none d-inline-block"
        to={routePath.dashboard.path}
      >
        <img
          className=""
          src={appConfig.logo}
          alt="{appConfig.logoALT}"
          height="24"
        />
        {/* <img src={logoSm} alt="logo" height="16" /> */}
      </NavLink>
      <ul className="list-unstyled topbar-right-menu float-right mb-0">
        {/* <li className="topbar-dropdown d-none d-lg-block">
          <div className="nav-link">
            <span className="btn btn-sm btn-rounded font-12 btn-outline-dark">

              Current BP
              {' '}
              <span className="badge badge-pill badge-dark">{bpoint}</span>
            </span>
          </div>
        </li> */}
        {/* {isAdmin && (
          <li className="topbar-dropdown d-none d-lg-block">
            <div className="nav-link">
              <Link
                to={routePath.order.path}
                className="btn btn-sm btn-rounded font-12 btn-outline-success"
              >
                New orders{' '}
                <span className="badge badge-pill badge-success">3</span>
              </Link>
            </div>
          </li>
        )} */}
        {/* <li className="topbar-dropdown d-none d-lg-block">
          <div className="nav-link">
            <span className="btn btn-sm btn-rounded font-12 btn-outline-danger">
              New Associates
              {' '}
              <span className="badge badge-pill badge-danger">3</span>
            </span>
          </div>
        </li> */}
        <li className="notification-list cart-dropdown topbar-dropdown d-lg-block">
          <CartDropdown />
        </li>
        {/* <li className="dropdown notification-list">
               <Dropdown>
                <Dropdown.Toggle size="sm" variant="link" id="notiDropdown" className="nav-link dropdown-toggle arrow-none">
                  <i className="dripicons-bell noti-icon" />
                  <span className="custom-icon-badge">2</span>
                </Dropdown.Toggle>
                <Dropdown.Menu alignRight className="notification-header-dropdown dropdown-lg">
                  <Dropdown.Header className="dropdown-header noti-title">
                    <h5 className="m-0">
                      <span className="float-right">
                        <Link to={routePath.account.path} className="text-dark">
                          <small>Clear All</small>
                        </Link>
                      </span>
                      Notification
                    </h5>
                  </Dropdown.Header>
                  <Dropdown.Item as={Link} to={routePath.account.path} className="notify-item">
                    <div className="notify-icon bg-primary">
                      <i className="mdi mdi-comment-account-outline" />
                    </div>
                    <p className="notify-details">Karen Robinson</p>
                    <p className="text-muted mb-0 user-msg">
                      <small>Wow ! this admin looks good and awesome design</small>
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={routePath.account.path} className="notify-item">
                    <div className="notify-icon bg-warning">
                      <i className="mdi mdi-comment-account-outline" />
                    </div>
                    <p className="notify-details">Karen Robinson</p>
                    <p className="text-muted mb-0 user-msg">
                      <small>Wow ! this admin looks good and awesome design</small>
                    </p>
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={routePath.account.path} className="text-center text-primary notify-item notify-all">
                    View all
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li> */}
        <li className="dropdown notification-list">
          <Dropdown>
            <Dropdown.Toggle
              size="sm"
              variant="link"
              id="userDropdown"
              className="nav-link dropdown-toggle nav-user arrow-none mr-0"
            >
              <span className="account-user-avatar d-none d-sm-inline-block">
                <Image
                  className="rounded-circle"
                  src={pImage}
                  alt={`${userInfo.first_name} ${userInfo.last_name}`}
                  size={profileImage.profileSmall}
                />
              </span>
              <span>
                <span className="account-user-name">
                  <span className="d-none d-sm-inline-block">Welcome,</span>{' '}
                  {userInfo.first_name}!
                </span>
                <span
                  className={`d-none1 d-sm-inline-block1 flag-icon flag-icon-${
                    userInfo.associate_info && userInfo.associate_info.country
                      ? userInfo.associate_info.country.toLowerCase()
                      : ''
                  } mr-1`}
                />
                <span className="d-none1 d-sm-inline-block1 account-position u-id">{`${userInfo.uuid}`}</span>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.Item as={Link} to={routePath.account.path}>
                <i className="mdi mdi-account-circle mr-1" />
                <span>My Account</span>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to={routePath.profile.path}>
                <i className="mdi mdi-account-edit mr-1" />
                <span>Profile</span>
              </Dropdown.Item>
              <Dropdown.Item onClick={doLogout} to="/">
                <i className="mdi mdi-logout mr-1" />
                <span>Logout</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
      {/* <div className="app-search">
        <form>
          <div className="input-group" id="custom-search">
            <input type="text" className="form-control" placeholder="Search..." />
            <span className="mdi mdi-magnify" />
            <div className="input-group-append">
              <button className="btn btn-primary" type="submit">Search</button>
            </div>
          </div>
        </form> *!/
        <p className="text-muted text_ip">
          <i className="mdi mdi-ethernet" />
          {' '}
          103.226.203.104
          <i className="mdi mdi-calendar ml-2" />
          {' '}
          13 Sep, 2019
        </p>
      </div> */}
    </nav>
  );
}

const mapStateToProps = state => ({
  userInfo: state.auth.user,
  bpoint: state.dashboard.bp
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogout: authActions.doLogout
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navbar);
