import { findIndex } from 'lodash';
import { rank as rankActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { insertItem, updateObjectInArray } from '../utills/custom';

function rank(state = initialState.rank, action) {
  switch (action.type) {
    case rankActionTypes.RANK_ALL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case rankActionTypes.RANK_ALL_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allList: action.data
        };
      }
      return state;
    case rankActionTypes.RANK_ALL_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case rankActionTypes.RANK_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case rankActionTypes.RANK_CREATE_SUCCESS:
    case rankActionTypes.RANK_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case rankActionTypes.RANK_SUCCESS:
      if (action.data) {
        const newList = insertItem(state.allList.data, {
          index: 0,
          item: action.data
        });
        return {
          ...state,
          allList: { ...state.allList, data: newList }
        };
      }
      return state;
    case rankActionTypes.RANK_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case rankActionTypes.RANK_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        if (index > -1) {
          const newList = updateObjectInArray(state.allList.data, {
            index,
            item: action.data
          });
          const allList = { ...state.allList, data: newList };
          return {
            ...state,
            allList,
            updateProgress: false
          };
        }
      }
      return {
        ...state,
        updateProgress: false
      };
    case rankActionTypes.RANK_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    default:
      return state;
  }
}

export default rank;
