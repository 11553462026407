import React from 'react';
import { connect } from 'react-redux';
import { getImageUrl } from '../utills/custom';

function Image({
  src = '',
  className = '',
  alt = '',
  title = '',
  size = null,
  imageStyle
}) {
  let imageUrl = src;
  if (
    !/placeholder/gi.test(src) &&
    !/avatar/gi.test(src) &&
    size &&
    imageStyle[size]
  ) {
    imageUrl = getImageUrl(src, imageStyle[size]);
  }
  const imgAttr = [];
  return (
    <img
      className={className}
      src={imageUrl}
      {...(title && { title })}
      alt={alt}
    />
  );
}
const mapStateToProps = state => ({
  imageStyle: state.app.imageStyle
});

export default connect(
  mapStateToProps,
  null
)(Image);
