import React from 'react';
import { Field, FieldArray, Form, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import { required, number } from '../../utills/validator';
import { inputField } from '../../components/Fields';
import FsbItemField from './FsbItemField';
import btnLoader from '../../assets/images/btn-loader.gif';
import routePath from '../../constants/routePath';

class FsbConfigForm extends React.Component {
  componentDidMount() {
    // const { getAllGenerations } = this.props;
    // getAllGenerations();
  }

  render() {
    const { handleSubmit, submitForm, inProgress } = this.props;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row align-items-center">
          <div className="col-xs-12 col-md-6">
            <Field
              name="title"
              component={inputField}
              type="text"
              className="form-control"
              label="Title"
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field
              name="total_duration"
              component={inputField}
              type="text"
              className="form-control"
              label="Total Duration (Days)"
              validate={[required, number]}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <FieldArray
                name="phase"
                component={FsbItemField}
                onDelete={this.handleDelete}
              />
            </div>
            <div className="form-group">
              <Link to={routePath.fsb.path} className="btn btn-light mr-2">
                Cancel
              </Link>
              <button
                type="submit"
                disabled={inProgress}
                className="btn btn-primary"
              >
                {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'fsbConfigForm' // a unique identifier for this form
})(FsbConfigForm);
