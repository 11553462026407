import React, { createRef, Fragment } from 'react';
import {
  Field,
  FieldArray,
  Form,
  reduxForm,
  change,
  getFormValues,
  getFormInitialValues
} from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { required } from '../../utills/validator';
import {
  inputField,
  selectField,
  switchField,
  checkboxField
} from '../../components/Fields';
import { app as appActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';
import routePath from '../../constants/routePath';
import ShippingFlatRate from './ShippingFlatRate';
import ShippingRateByWeight from './ShippingRateByWeight';

class ShippingForm extends React.Component {
  state = {
    disableState: false,
    disableCity: false,
    countElemsInRow: null
  };

  countElem = createRef(null);

  componentDidMount() {
    const {
      getCountry,
      getCityByState,
      getStateByCountry,
      initialValues,
      dispatch
    } = this.props;
    const { childNodes: childDivs } = this.countElem.current;
    const divCount = Object.keys(childDivs).reduce((acc, div) => {
      // eslint-disable-next-line no-param-reassign
      if (childDivs[div] instanceof HTMLDivElement) acc += 1;
      this.setState({
        countElemsInRow: acc
      });
      return acc;
    }, 0);
    getCountry();
    if (initialValues.country && initialValues.country !== '*') {
      getStateByCountry(initialValues.country);
    }
    if (initialValues.state && initialValues.state !== '*') {
      getCityByState(initialValues.state);
    }
    if (initialValues.rate_type && initialValues.rate_type === 1) {
      dispatch(change('shippingForm', 'delivery', this.getFlatRate));
    }
    if (initialValues.rate_type && initialValues.rate_type === 2) {
      dispatch(change('shippingForm', 'delivery', this.getRateByWeight()));
    }
  }

  getFlatRate = () => {
    const { initialValues } = this.props;
    const delivery = [];
    initialValues.delivery.map(item =>
      delivery.push({
        id: item.id,
        delivery_name: item.delivery_name,
        delivery_time: item.delivery_time,
        rate: item.rate
      })
    );
    return delivery;
  };

  getRates = item => {
    const rates = [];
    item.shipping_rates.map(rate =>
      rates.push({
        id: rate.id,
        max_weight: rate.max_weight,
        min_weight: rate.min_weight,
        rate: rate.rate
      })
    );
    return rates;
  };

  getRateByWeight = () => {
    const { initialValues } = this.props;
    const delivery = [];
    initialValues.delivery.map(item =>
      delivery.push({
        id: item.id,
        delivery_name: item.delivery_name,
        delivery_time: item.delivery_time,
        rates: this.getRates(item)
      })
    );
    return delivery;
  };

  countryChange = val => {
    const { dispatch, getStateByCountry } = this.props;
    if (val === '*') {
      dispatch(change('shippingForm', 'city', '*'));
      dispatch(change('shippingForm', 'state', '*'));
      this.setState({ disableState: true, disableCity: true });
    } else if (val) {
      dispatch(change('shippingForm', 'city', ''));
      dispatch(change('shippingForm', 'state', ''));
      this.setState({ disableState: false, disableCity: false });
      getStateByCountry(val);
    }
  };

  stateChange = val => {
    const { dispatch, getCityByState } = this.props;
    if (val === '*') {
      dispatch(change('shippingForm', 'city', '*'));
      this.setState({ disableCity: true });
    } else if (val) {
      dispatch(change('shippingForm', 'city', ''));
      this.setState({ disableCity: false });
      getCityByState(val);
    }
  };

  rateChange = val => {
    const { dispatch } = this.props;
    if (val === 1) {
      dispatch(change('shippingForm', 'delivery', [{}]));
    }
    if (val === 2) {
      dispatch(change('shippingForm', 'delivery', [{ rates: [{}] }]));
    }
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      countryList,
      stateList,
      cityList,
      formValues,
      countryCurrencies,
      isAdmin
    } = this.props;
    const { disableState, disableCity, countElemsInRow } = this.state;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card shipping_add">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-4">
                    <Field
                      name="title"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Name"
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      name="country"
                      id="country"
                      component={selectField}
                      label="Country"
                      feedback={this.countryChange}
                      options={[{ value: '*', label: '*' }, ...countryList]}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-md-4">
                    <Field
                      name="state"
                      id="state"
                      component={selectField}
                      label="State"
                      feedback={this.stateChange}
                      options={[{ value: '*', label: '*' }, ...stateList]}
                      disabled={disableState}
                      validate={[required]}
                    />
                  </div>
                </div>

                <div className="row align-items-center">
                  <div className="col-xs-12 col-md-6">
                    <Field
                      name="city"
                      id="city"
                      component={selectField}
                      label="City"
                      options={[{ value: '*', label: '*' }, ...cityList]}
                      disabled={disableCity}
                      validate={[required]}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <Field
                      name="postcode"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Postcode/ZIP"
                      validate={[required]}
                    />
                  </div>
                </div>
                <Fragment>
                  <div className="row">
                    <div className="shipping-calculation col-md-12">
                      <div className="row shipping-calculation-card">
                        <div className="col-md-6">
                          <Field
                            name="rate_type"
                            id="rate_type"
                            label="Shipping Calculation"
                            component={selectField}
                            notSearchable
                            feedback={this.rateChange}
                            options={[
                              {
                                value: '',
                                label: (
                                  <>
                                    <p className="mb-0 p-2">Select....</p>
                                  </>
                                )
                              },
                              {
                                value: 1,
                                label: (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <span className="flatrate-icon d-flex align-items-center justify-content-center rounded-circle">
                                        <i className="mdi mdi-currency-eur text-secondary" />
                                      </span>
                                      <div>
                                        <p className="ml-2 mb-0">Flat Rate</p>
                                        <small className="ml-2">
                                          Apply the same rate for an entire
                                          order
                                        </small>
                                      </div>
                                    </div>
                                  </>
                                )
                              },
                              {
                                value: 2,
                                label: (
                                  <>
                                    <div className="d-flex align-items-center ">
                                      <span className="flatrate-icon d-flex align-items-center justify-content-center rounded-circle">
                                        <i className="mdi mdi-weight-kilogram text-secondary" />
                                      </span>
                                      <div>
                                        <p className="ml-2 mb-0">
                                          Rate by Weight
                                        </p>
                                        <small className="ml-2 d-col-none text-wrap">
                                          Calculate rate based on the total
                                          weight of an entire order
                                        </small>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                            ]}
                            className="shipping-calculation-input"
                            validate={[required]}
                            // components={{ Option: IconOption }}
                          />
                        </div>
                      </div>
                      {formValues.rate_type && formValues.rate_type === 1 && (
                        <div>
                          <FieldArray
                            name="delivery"
                            country={formValues.country}
                            currency={
                              formValues.country &&
                              countryCurrencies[formValues.country]
                                ? countryCurrencies[formValues.country]
                                    .currency_symbol
                                : ''
                            }
                            component={ShippingFlatRate}
                          />
                        </div>
                      )}
                      {formValues.rate_type && formValues.rate_type === 2 && (
                        <div>
                          <FieldArray
                            name="delivery"
                            country={formValues.country}
                            currency={
                              formValues.country &&
                              countryCurrencies[formValues.country]
                                ? countryCurrencies[formValues.country]
                                    .currency_symbol
                                : ''
                            }
                            component={ShippingRateByWeight}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row" ref={this.countElem}>
                    <div className="col-md-4">
                      <Field
                        name="store_pickup"
                        component={checkboxField}
                        id="store_pickup"
                        className="form-control form-control-sm "
                        inline={['col-4', 'col-8 pt-2']}
                        options={[
                          {
                            value: 'yes',
                            label: 'Allow Store Pickup'
                          }
                        ]}
                      />
                    </div>
                    <div
                      className={
                        countElemsInRow === 2
                          ? 'col-md-4 offset-md-4 col-xs-12'
                          : 'col-md-4 col-xs-12'
                      }
                    >
                      <Field
                        name="status"
                        component={switchField}
                        groupClassName="form-group d-flex justify-content-center mt-1 rate-type-status"
                        label="Status"
                        id="status"
                        type="success"
                      />
                    </div>

                    <div className="col-md-4 text-right">
                      <Button
                        as={Link}
                        to={routePath.shipping.path}
                        className="btn btn-light mr-2"
                      >
                        Cancel
                      </Button>
                      <button
                        type="submit"
                        disabled={inProgress}
                        className="btn btn-primary"
                      >
                        {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                      </button>
                    </div>
                  </div>
                </Fragment>
              </div>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}
const mapStateToProps = state => ({
  countryList: state.app.countryList,
  countryCurrencies: state.app.countryCurrencies,
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  formValues: getFormValues('shippingForm')(state),
  isAdmin: state.auth.isAdmin
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState
    },
    dispatch
  );

const ShippingFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingForm);

export default reduxForm({
  form: 'shippingForm' // a unique identifier for this form
})(ShippingFormWithRedux);
