export const paymentParamsKeys = {
  QUICKPAY_API_KEY: 'Quickpay API Key',
  QUICKPAY_MERCHANT_ID: 'Quickpay Merchant ID',
  QUICKPAY_PRIVATE_KEY: 'Quickpay Private Key',
  ZOTTOPAY_MERCHANT_ID: 'Zottopay Merchant ID',
  ZOTTOPAY_SECRET_KEY: 'Zottopay Secret Key'
};

export const initialPaymentState = {
  QUICKPAY_API_KEY: '',
  QUICKPAY_MERCHANT_ID: '',
  QUICKPAY_PRIVATE_KEY: '',
  ZOTTOPAY_MERCHANT_ID: '',
  ZOTTOPAY_SECRET_KEY: ''
};

export const gatewayOptions = [
  { value: 'ZP', label: 'ZottoPay' },
  { value: 'QP', label: 'QuickPay' }
];
