import React from 'react';
import pageloader from '../assets/images/pageloader.gif';
import { DotLoader } from './PageLoader';
import pageloaderblack from '../assets/images/pageloaderblack.gif';

export default function InnerLoader({ type }) {
  const getImage = () => {
    switch (type) {
      case 'table':
        return <DotLoader />; // return <img src={tableloader} alt="loader" />;
      case 'bigBlack':
        return <img src={pageloaderblack} alt="loader" />;
      default:
        return <img src={pageloader} alt="loader" />;
    }
  };
  return <div className="inner-loader">{getImage()}</div>;
}

export function OverlayLoader({ type, className = 'preloader-inner' }) {
  const getImage = () => {
    switch (type) {
      case 'table':
        return <DotLoader />; // return <img src={tableloader} alt="loader" />;
      case 'bigBlack':
        return <img src={pageloaderblack} alt="loader" />;
      case 'spinner':
        return (
          <div className="text-center">
            <SpinnerLoader className="spinner-border-md text-success align-middle" />
          </div>
        );
      default:
        return <img src={pageloader} alt="loader" />;
    }
  };
  return (
    <div className={className}>
      <div className="overlay-inner">{getImage()}</div>
    </div>
  );
}

export function SpinnerLoader({ type, className = 'text-primary' }) {
  switch (type) {
    case 'spinner-md':
      return <div className={`spinner-border ${className}`} role="status" />;
    case 'grow':
      return (
        <span
          className={`spinner-grow ${className}`}
          role="status"
          aria-hidden="true"
        />
      );
    default:
      return (
        <span
          className={`spinner-border ${className}`}
          role="status"
          aria-hidden="true"
        />
      );
  }
}
