import React, { useState } from 'react';
import { map, sumBy } from 'lodash';
import FsbTriangle from './FsbTriangle';
import FsbViewForm from './FsbViewForm';
import FsbViewStaticForm from './FsbViewStaticForm';
import FsbPhasePerformance from './FsbPhasePerformance';
import FsbPhase from './FsbPhase';
import FsbUserInfo from './FsbUserInfo';

function FsbView({
  fsb,
  associateFsb,
  hideFsbTracker,
  showUserInfo,
  admin,
  editable
}) {
  const [editMode, setEditMode] = useState(false);
  const phases = map(associateFsb.fsb_plan_logs, 'fsb_phase');
  const { user_info: userInfo, sponsor_info: sponsorInfo } = associateFsb;
  const handleEdit = () => {
    setEditMode(true);
  };
  const handleCancel = () => {
    setEditMode(false);
  };
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="p-2">
                <FsbTriangle data={associateFsb} />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              {showUserInfo && userInfo && sponsorInfo ? (
                <>
                  <FsbUserInfo title="Associate ID: " userInfo={userInfo} />
                  <FsbUserInfo title="Sponsor ID: " userInfo={sponsorInfo} />
                </>
              ) : null}
              {hideFsbTracker ? null : (
                <>
                  <h4 className="mt-0 mb-3">
                    <div className="float-right badge badge-danger">{`${fsb.total_duration} Days`}</div>
                    FSB Tracker
                  </h4>
                  <p className="text-muted font-13 d-none">
                    View the tracker plan details below.
                  </p>
                  <FsbPhase phases={phases} />
                </>
              )}
              <div className="form-horizontal">
                {editMode ? (
                  <FsbViewForm
                    initialValues={{
                      start_date: associateFsb.start_date,
                      end_date: associateFsb.end_date,
                      achieve_status: associateFsb.achieve_status,
                      active_status: associateFsb.active_status
                    }}
                    id={associateFsb.user_id}
                    onCancel={handleCancel}
                  />
                ) : (
                  <FsbViewStaticForm
                    value={{
                      start_date: associateFsb.start_date,
                      end_date: associateFsb.end_date,
                      achieve_status: associateFsb.achieve_status,
                      active_status: associateFsb.active_status
                    }}
                    onEdit={handleEdit}
                    editable={editable}
                  />
                )}
              </div>
              <FsbPhasePerformance
                showPayment={admin}
                fsbPlanLogs={associateFsb.fsb_plan_logs}
              />
              <div className="mt-2">
                <h5>
                  <span>TOTAL QUALIFIED BP FOR 90 DAYS</span>
                  <span className="badge badge-primary font-18 ml-1">
                    {sumBy(associateFsb.fsb_plan_logs, obj =>
                      parseFloat(obj.achieved_bp)
                    ).toFixed(2)}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default FsbView;
