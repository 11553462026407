import React from 'react';
import { NavLink } from 'react-router-dom';

export default class WithSubmenu extends React.Component {
  state = {
    openSubmenu: false
  };

  handleMenuOpen = e => {
    e.preventDefault();
    this.setState(a => ({ openSubmenu: !a.openSubmenu }));
  };

  render() {
    const { item } = this.props;
    const { openSubmenu } = this.state;
    return (
      <li
        key={item.label}
        className={openSubmenu ? 'side-nav-item mm-active' : 'side-nav-item'}
      >
        <NavLink
          className={openSubmenu ? 'side-nav-link collapsed' : 'side-nav-link'}
          to={item.to}
          data-toggle="collapse"
          aria-expanded={openSubmenu}
          onClick={this.handleMenuOpen}
        >
          <i className={item.icon} />
          {item.label}
          <span className="menu-arrow" />
        </NavLink>
        <div
          className={openSubmenu ? 'submenu collapse show' : 'submenu collapse'}
        >
          <ul className="side-nav-second-level">
            {item.children.map((subitem, index) => (
              <li key={index}>
                <NavLink to={subitem.to}>
                  <i className={subitem.icon} />
                  {subitem.label}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </li>
    );
  }
}
