import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get, find, findIndex } from 'lodash';
import GenerationForm from './GenerationForm';
import { generation as generationActions } from '../../actions';
import routePath from '../../constants/routePath';

class GenerationEdit extends React.Component {
  componentDidMount() {
    const { generation, match, getGenerationById } = this.props;
    const generationList = get(generation, 'data', []);
    if (
      match.params &&
      match.params.id &&
      parseInt(match.params.id, 10) &&
      findIndex(generationList, { id: parseInt(match.params.id, 10) }) < 0
    ) {
      getGenerationById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateGeneration, match } = this.props;
    console.log(values);
    updateGeneration({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  render() {
    const { updateProgress, generation, match } = this.props;
    const generationList = get(generation, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      data = find(generationList, { id: parseInt(match.params.id, 10) });
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <h4 className="page-title">Generation</h4>
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link
                    to={routePath.dashboard.path}
                    className="breadcrumb-link"
                  >
                    <i className="action-icon fas fa-home" />
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={routePath.generation.path}
                    className="breadcrumb-link"
                  >
                    Generation
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit
                </li>
              </ol>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card generation_add">
                <h5 className="card-header">Update Generation</h5>
                <div className="card-body">
                  <GenerationForm
                    submitForm={this.handleSubmit}
                    initialValues={data}
                    inProgress={updateProgress}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.generation.updateProgress,
  generation: state.generation.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateGeneration: generationActions.updateGeneration,
      getGenerationById: generationActions.getGenerationById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(GenerationEdit)
);
