import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AdvanceTable from '../../components/AdvanceTable';
import {
  permission as permissionActions,
  setting as settingActions
} from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import LoginHistoryForm from './LoginHistoryForm';

class LoginHistory extends React.Component {
  columns = [
    {
      dataField: 'uuid',
      text: 'UUID',
      headerAttrs: { width: '10%' }
    },
    {
      dataField: 'username',
      text: 'Username'
    },
    {
      dataField: 'agent',
      text: 'Browser'
    },
    {
      dataField: 'ip_address',
      text: 'IP',
      headerAttrs: { width: '13%' }
    },
    {
      dataField: 'in_time',
      text: 'IN Time',
      formatter: (col, row) => (
        <span className="date">
          Sep 20, 2019
          <br />
          <small>11:02am</small>
        </span>
      )
    },
    {
      dataField: 'out_time',
      text: 'OUT Time',
      formatter: (col, row) => (
        <span className="date">
          Sep 20, 2019
          <br />
          <small>11:02am</small>
        </span>
      )
    },
    {
      dataField: 'user_time',
      text: 'User Time',
      formatter: (col, row) => <span className="date">7h 25m 44s</span>
      // headerAttrs: { width: '13%' }
    }
  ];

  componentDidMount() {
    const { getAllLoginHistories, getRoles } = this.props;
    getAllLoginHistories();
    getRoles();
  }

  handleSubmit = values => {};

  render() {
    const { loginHistories, isLoading } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.loginHistory.breadcrumb} />
              <h4 className="page-title">{routePath.loginHistory.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-8">
                    <LoginHistoryForm submitForm={this.handleSubmit} />
                  </div>
                  <div className="col-md-4">
                    <button
                      type="button"
                      className="btn btn-danger btn-sm float-right"
                    >
                      <i className="mdi mdi-delete" /> Delete
                    </button>
                  </div>
                </div>
                <AdvanceTable
                  data={loginHistories.data}
                  columns={this.columns}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  loginHistories: state.setting.allList,
  roles: state.permission.roles,
  isLoading: state.setting.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRoles: permissionActions.getRoles,
      getAllLoginHistories: settingActions.getAllLoginHistories
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginHistory);
