import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get, find } from 'lodash';
import ProductForm from './ProductForm';
import Tab from '../../components/Tab';
import BreadCrumb from '../../components/BreadCrumb';
import { product as productActions } from '../../actions';
import routePath from '../../constants/routePath';
import InnerLoader from '../../components/InnerLoader';

class ProductEdit extends React.Component {
  componentDidMount() {
    const { match, getProductById } = this.props;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getProductById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateProduct, match } = this.props;
    const newValues = JSON.parse(JSON.stringify(values));
    // Parse country array
    const countries = [];
    newValues.states = [];
    newValues.countries.forEach(item => {
      item.states.forEach(s => {
        if (!s.inherit) {
          newValues.states.push(s);
        }
      });
      delete item.states;

      if (!item.inherit) {
        countries.push(item);
      }
    });
    newValues.countries = countries;
    delete newValues.priceCountry;

    updateProduct({
      id: parseInt(match.params.id, 10),
      data: newValues,
      redirect: true
    });
  };

  render() {
    const { updateProgress, product, match } = this.props;
    const productList = get(product, 'data', []);
    let data = null;
    let dataObj = null;
    const productImages = [];
    let defaultProductImage = '';
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      dataObj = find(productList, { id: parseInt(match.params.id, 10) });
      let categoryId = [];
      const fileId = [];
      let defaultFileId = '';
      if (dataObj && dataObj.product_category) {
        if (Array.isArray(dataObj.product_category)) {
          categoryId = dataObj.product_category.map(item => item.id);
        }

        if (Array.isArray(dataObj.product_images)) {
          dataObj.product_images.forEach(item => {
            if (item.file && Number(item.is_default) === 1) {
              defaultFileId = item.file_id;
              defaultProductImage = `${process.env.REACT_APP_IMAGE_URL}/${item.file.file_path}`;
            } else {
              fileId.push(item.file_id);
              productImages.push(
                `${process.env.REACT_APP_IMAGE_URL}/${item.file.file_path}`
              );
            }
          });
        }

        data = {
          id: dataObj.id,
          sku: dataObj.sku,
          sort_order: dataObj.sort_order,
          ean: dataObj.ean,
          publish_date: '',
          title: dataObj.title,
          slug: dataObj.slug,
          description: dataObj.description,
          excerpt: dataObj.excerpt,
          currency_id: dataObj.currency_id,
          regular_price: dataObj.regular_price,
          sale_price: dataObj.sale_price,
          discount_percent: dataObj.discount_percent,

          taxable: dataObj.taxable,
          tax_id: dataObj.tax_id,
          member_price: dataObj.member_price || 0,
          member_price_vat: dataObj.member_price_vat || 0,
          customer_price_vat: dataObj.customer_price_vat || 0,
          bpoint: dataObj.bpoint,
          cpoint: dataObj.cpoint,

          manage_stock: !!dataObj.manage_stock,
          allow_backorders: dataObj.allow_backorders,
          sold_out: dataObj.sold_out,
          low_stock_alert: dataObj.low_stock_alert,
          stock_qty: dataObj.stock_qty,
          sold_single: !!dataObj.sold_single,
          status: !!dataObj.status,
          display_product: dataObj.display_product,
          category_id: categoryId,
          file_id: fileId,
          default_file_id: defaultFileId,
          priceCountry: dataObj.countries,
          states: dataObj.states,
          uncovered_countries: dataObj.uncovered_countries,
          uncovered_states: dataObj.uncovered_states
        };

        if (dataObj.shipping) {
          data.shipping_id = dataObj.shipping.shipping_id;
          data.width = dataObj.shipping.width;
          data.height = dataObj.shipping.height;
          data.length = dataObj.shipping.length;
          data.weight = dataObj.shipping.weight;
        }
      }
    }
    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.productEdit.breadcrumb} />
              <h4 className="page-title">{routePath.productEdit.title}</h4>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <ProductForm
                submitForm={this.handleSubmit}
                initialValues={data}
                enableReinitialize
                keepDirtyOnReinitialize
                inProgress={updateProgress}
                defaultImage={defaultProductImage}
                imageGallery={productImages}
                id={parseInt(match.params.id, 10)}
              />
            </div>
          </div>
        ) : (
          <InnerLoader type="table" />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.product.updateProgress,
  product: state.product.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProduct: productActions.updateProduct,
      getProductById: productActions.getProductById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductEdit)
);
