import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { paymentParamsKeys, gatewayOptions } from '../utills/storePaymentState';
import LinkBack from '../containers/Location/LinkBack';

const PaymentGatewayDetailsForm = ({
  paymentState,
  countryList,
  country,
  activeMode,
  isEdit,
  handleCountryChange,
  handleActiveModeChange,
  handleChangePaymentData,
  handleSubmit,
  title
}) => {
  const getValue = () => {
    return Array.isArray(activeMode)
      ? gatewayOptions.filter(val => activeMode.includes(val.value))
      : [];
  };
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <LinkBack
              linkItems={[
                { link: '/setting/store', title: 'Settings' },
                {
                  title: 'Payment Gateway'
                }
              ]}
            />
            <h4 className="page-title">{title}</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xs-12 col-md-4">
                  <Form>
                    <Form.Label>Store Country</Form.Label>
                    <Select
                      placeholder="Select..."
                      name="Store Country"
                      label="Store Country"
                      options={[...countryList]}
                      isDisabled={isEdit}
                      isSearchable
                      isClearable
                      onChange={e => handleCountryChange(e ? e.value : '')}
                      value={[
                        { label: '*', value: '*', id: '*' },
                        ...countryList
                      ].filter(option => option.value === country)}
                    />
                  </Form>
                </div>
              </div>

              {/* map through the payments types */}
              <div className="row mb-3">
                {Object.keys(paymentState).map(paymentKey => {
                  if (paymentKey === 'QUICKPAY_MERCHANT_ID') {
                    return (
                      <div key={paymentKey} className="col-xs-12 col-md-4">
                        <Form.Group>
                          <Form.Label>
                            {paymentParamsKeys[paymentKey]}
                          </Form.Label>
                          <Form.Control
                            required
                            value={paymentState[paymentKey]}
                            onChange={({ target: { value } }) =>
                              handleChangePaymentData(paymentKey, value)
                            }
                          />
                        </Form.Group>
                      </div>
                    );
                  }
                  return null;
                })}
                {/* map through each payment's type fields  */}
                {Object.keys(paymentState).map(paymentKey => {
                  if (paymentKey !== 'QUICKPAY_MERCHANT_ID') {
                    return (
                      <div key={paymentKey} className="col-xs-12 col-md-4">
                        <Form.Group>
                          <Form.Label>
                            {paymentParamsKeys[paymentKey]}
                          </Form.Label>
                          <Form.Control
                            required
                            value={paymentState[paymentKey]}
                            onChange={({ target: { value } }) =>
                              handleChangePaymentData(paymentKey, value)
                            }
                          />
                        </Form.Group>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

              <div className="row mb-3">
                <div className="col-xs-12 col-md-4">
                  <Form.Group>
                    <Form.Label>Active Payment Mode</Form.Label>
                    <Select
                      value={getValue()}
                      isMulti
                      onChange={handleActiveModeChange}
                      isClearable
                      onBlur={event => event.preventDefault()}
                      options={gatewayOptions}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  </Form.Group>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Button
                    type="submit"
                    // disabled={createProgress}
                    className="btn btn-light mr-2"
                    as={Link}
                    to="/setting/store"
                  >
                    Cancel
                  </Button>
                  <Button
                    // disabled={createProgress}
                    className="btn btn-primary mr-2"
                    onClick={handleSubmit}
                  >
                    {/* {createProgress ? <img src={btnLoader} alt="" /> : 'Save'} */}
                    Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentGatewayDetailsForm;
