const customer = {
  ASSOCIATE_CUSTOMER_REQUEST: 'ASSOCIATE_CUSTOMER_REQUEST',
  ASSOCIATE_CUSTOMER_SUCCESS: 'ASSOCIATE_CUSTOMER_SUCCESS',
  ASSOCIATE_CUSTOMER_ERROR: 'ASSOCIATE_CUSTOMER_ERROR',
  ALL_CUSTOMER_REQUEST: 'ALL_CUSTOMER_REQUEST',
  ALL_CUSTOMER_SUCCESS: 'ALL_CUSTOMER_SUCCESS',
  ALL_CUSTOMER_ERROR: 'ALL_CUSTOMER_ERROR',
  CUSTOMER_BY_ASSOC_REQUEST: 'CUSTOMER_BY_ASSOC_REQUEST',
  CUSTOMER_BY_ASSOC_SUCCESS: 'CUSTOMER_BY_ASSOC_SUCCESS',
  CUSTOMER_BY_ASSOC_ERROR: 'CUSTOMER_BY_ASSOC_ERROR',
  getAssociatesCustomer: data => ({
    type: customer.ASSOCIATE_CUSTOMER_REQUEST,
    data
  }),
  getAllCustomer: params => ({
    type: customer.ALL_CUSTOMER_REQUEST,
    params
  }),
  getCustomerByAssocId: data => ({
    type: customer.CUSTOMER_BY_ASSOC_REQUEST,
    id: data.id,
    params: data.params
  })
};

export default customer;
