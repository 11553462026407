import React from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import BillingDetails from './BillingDetails';

const ShippingDetails = ({
  addressForm,
  shippingCityList,
  shippingStateList,
  countryList
}) => {
  if (addressForm.same_as_billing) {
    return <BillingDetails addressForm={addressForm} />;
  }
  return (
    <div className="font-weight-normal font-13">
      {addressForm.shipping_street_name_number}
      {addressForm.shipping_address_line1 && (
        <>
          <br />
          {addressForm.shipping_address_line1}
        </>
      )}
      <br />
      {addressForm.shipping_postcode}
      <br />
      {(addressForm.shipping_city && addressForm.shipping_city !== 'others'
        ? find(shippingCityList, { value: addressForm.shipping_city }).label
        : '') || addressForm.shipping_otherCity}
      <br />
      {find(shippingStateList, { value: addressForm.shipping_state }).label}
      <br />
      {find(countryList, { value: addressForm.shipping_country }).label}
      <br />
      {`Phone:  ${addressForm.shipping_mobile_number}`}
    </div>
  );
};

const mapStateToProps = state => ({
  shippingStateList: state.auth.shippingStateList,
  shippingCityList: state.auth.shippingCityList,
  countryList: state.app.countryList
});

export default connect(
  mapStateToProps,
  null
)(ShippingDetails);
