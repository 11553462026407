import routePath from './routePath';

const menu = [
  {
    label: 'Dashboard',
    section: true,
    children: [
      {
        label: routePath.dashboard.title,
        to: routePath.dashboard.path,
        icon: 'mdi mdi-home'
      }
    ]
  },
  {
    label: 'E-Commerce',
    section: true,
    children: [
      {
        label: routePath.shop.title,
        to: routePath.shop.path,
        icon: 'mdi mdi-shopping'
      },
      {
        label: routePath.order.title,
        to: routePath.order.path,
        icon: 'fas fa-shopping-cart',
        children: [
          {
            label: `My ${routePath.myOrder.title}`,
            to: routePath.myOrder.path,
            icon: 'fas fa-shopping-cart'
          },
          // {
          //   label: routePath.downlineAssociateOrder.title,
          //   to: routePath.downlineAssociateOrder.path,
          //   icon: 'mdi mdi-file-tree'
          // },
          {
            label: routePath.ownCustomerOrder.title,
            to: routePath.ownCustomerOrder.path,
            icon: 'mdi mdi-account-multiple-outline'
          }
        ]
      }
    ]
  },
  {
    label: 'Payout',
    section: true,
    children: [
      {
        label: 'Payout Statement',
        to: routePath.payout.path,
        permission: routePath.payout.permission,
        icon: 'mdi mdi-cash'
      },
      {
        label: 'Wallet Login',
        to: routePath.walletLogin.path,
        permission: routePath.walletLogin.permission,
        icon: 'mdi mdi-wallet',
        externalLink: process.env.REACT_APP_WALLET_ASSOCIATE // property to define an  external link
      }
    ]
  },
  {
    label: 'My Account',
    section: true,
    children: [
      {
        label: routePath.profile.title,
        to: routePath.profile.path,
        icon: 'mdi mdi-face'
        // permission: routePath.profile.permission
      },
      {
        label: routePath.addresses.title,
        to: routePath.addresses.path,
        icon: 'mdi mdi-map-marker-multiple'
      },
      {
        label: 'Shareables',
        to: routePath.shareablesLink.path,
        permission: routePath.shareablesLink.permission,
        icon: 'mdi mdi-link-variant',
        externalLink: process.env.REACT_APP_SHAREABLES_ASSOCIATE // property to define an  external link
      },
      {
        label: routePath.payOptions.title,
        to: routePath.payOptions.path,
        icon: 'mdi mdi-wallet'
      }
    ]
  },
  {
    label: 'Bonus Plans',
    section: true,
    children: [
      {
        label: routePath.fsbTracker.title,
        to: routePath.fsbTracker.path,
        icon: 'mdi mdi-eye'
      }
    ]
  },
  {
    label: 'Downline Tree',
    section: true,
    children: [
      {
        label: 'Associate Tree',
        to: routePath.tree.path,
        icon: 'fas fa-sitemap'
      },
      // add customer downlines to sidebar
      {
        label: 'Customer Tree',
        to: routePath.treeCustomer.path,
        permission: routePath.treeCustomer.permission,
        icon: 'fas fa-sitemap'
      },
      {
        label: 'Personal customers',
        to: routePath.customerTree.path,
        icon: 'fas fa-user'
      }
    ]
  }
];

export default menu;
