/* global location */
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { OverlayLoader } from '../../components/InnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import NoResult from '../../components/NoResult';
import routePath from '../../constants/routePath';
import { shop as shopActions, app as appActions } from '../../actions';
import CategoryItem from './CategoryItem';
import ShopFilterForm from './ShopFilterForm';
import ShopRestriction from '../../components/ShopRestriction';

function ShopComponent({
  shopCountry,
  shopState,
  getShopCategory,
  categoryList,
  isLoading
}) {
  const [layoutStyle, setLayoutStyle] = useState('grid');

  const handleLayout = layout => {
    setLayoutStyle(layout);
  };

  useEffect(() => {
    if (shopCountry) {
      getShopCategory({ country: shopCountry, state: shopState });
    }
  }, [shopCountry, shopState]);

  return (
    <div className="shop-page">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <BreadCrumb list={routePath.shop.breadcrumb} />
            <h4 className="page-title">{routePath.shop.title}</h4>
          </div>
        </div>
      </div>

      <ShopFilterForm
        hideGridLayout
        hideCategory
        handleLayout={handleLayout}
        layoutStyle={layoutStyle}
      />

      <div className="row categories-grid">
        {isLoading && <OverlayLoader type="spinner" />}
        {categoryList &&
          categoryList.map(category => (
            <CategoryItem key={`shop_cat_${category.id}`} category={category} />
          ))}
        {!isLoading && categoryList.length === 0 && (
          <NoResult icon="mdi mdi-map-marker-multiple" />
        )}
      </div>
      <ShopRestriction />
    </div>
  );
}

const mapStateToProps = state => ({
  categoryList: state.shop.categoryList,
  shopCountry: state.app.shopCountry,
  shopState: state.app.shopState,
  isLoading: state.shop.categoryLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getShopCategory: shopActions.getShopCategory,
      navigate: appActions.navigate
    },
    dispatch
  );

const ShopComponentWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopComponent);

// function Shop(props) {
//   const { match } = props;
//   return <ShopComponentWithRedux key={match.params.page} {...props} />;
// }

export default ShopComponentWithRedux;
