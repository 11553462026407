/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { storePaymentGateway as storePaymentGatewayActions } from '../../actions';

import PaymentGatewayDetailsForm from './PaymentGatewayForm';

const PaymentGatewayDetailsAdd = ({
  createStorePaymentGateway,
  getStorePaymentGateway,
  storePaymentGateway,
  match: { params }
}) => {
  useEffect(() => {
    if (params && params.id) getStorePaymentGateway({ iso2: params.id });
  }, [params, getStorePaymentGateway]);

  return (
    <PaymentGatewayDetailsForm
      title="Add Gateway"
      paymentGatewayList={
        storePaymentGateway.data && storePaymentGateway.data.country_settings
          ? storePaymentGateway.data.country_settings.payment_gateway
          : null
      }
      onSubmit={createStorePaymentGateway}
    />
  );
};

const MSTP = state => ({ storePaymentGateway: state.storePaymentGateway });

const MDSTP = {
  getStorePaymentGateway: storePaymentGatewayActions.getStorePaymentGateway,
  createStorePaymentGateway:
    storePaymentGatewayActions.createStorePaymentGateway
};

export default connect(
  MSTP,
  MDSTP
)(PaymentGatewayDetailsAdd);
