import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { Link } from 'react-router-dom';
import {
  required,
  number,
  email,
  passwordsMatch
} from '../../utills/validator';
import { inputField, editorField, selectField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';
import routePath from '../../constants/routePath';

const AssociateForm = props => {
  const { handleSubmit, submitForm, inProgress, roles } = props;
  return (
    <Form
      className="needs-validation"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="row">
        <div className="col-md-4">
          <Field
            name="first_name"
            className="form-control"
            type="text"
            component={inputField}
            placeholder="First name"
            id="first-name"
            label="First name"
            validate={[required]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="last_name"
            className="form-control"
            type="text"
            component={inputField}
            placeholder="Last name"
            id="last-name"
            label="last name"
            validate={[required]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <Field
            name="email"
            component={inputField}
            type="text"
            className="form-control"
            label="Email"
            placeholder="e.g. hello@example.com"
            validate={[required, email]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="newPassword"
            component={inputField}
            type="password"
            className="form-control"
            label="Password"
            validate={[required]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="password"
            component={inputField}
            type="password"
            className="form-control"
            label="Confirm Password"
            validate={[required, passwordsMatch]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="role_id"
            component={selectField}
            type="password"
            notSearchable
            label="Role"
            options={roles}
            validate={[required]}
          />
        </div>
      </div>
      <Link to={routePath.associate.path} className="btn btn-light mr-2">
        Cancel
      </Link>
      <button
        type="submit"
        disabled={inProgress}
        className="btn btn-primary mr-2"
      >
        {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
      </button>
    </Form>
  );
};

export default reduxForm({
  form: 'AssociateForm' // a unique identifier for this form
})(AssociateForm);
