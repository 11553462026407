const address = {
  ADDRESS_ALL_LIST_REQUEST: 'ADDRESS_ALL_LIST_REQUEST',
  ADDRESS_ALL_LIST_SUCCESS: 'ADDRESS_ALL_LIST_SUCCESS',
  ADDRESS_ALL_LIST_ERROR: 'ADDRESS_ALL_LIST_ERROR',
  ADDRESS_SHOW_MODAL: 'ADDRESS_SHOW_MODAL',
  ADDRESS_HIDE_MODAL: 'ADDRESS_HIDE_MODAL',
  ADDRESS_CREATE_REQUEST: 'ADDRESS_CREATE_REQUEST',
  ADDRESS_CREATE_SUCCESS: 'ADDRESS_CREATE_SUCCESS',
  ADDRESS_CREATE_ERROR: 'ADDRESS_CREATE_ERROR',
  ADDRESS_UPDATE_REQUEST: 'ADDRESS_UPDATE_REQUEST',
  ADDRESS_UPDATE_SUCCESS: 'ADDRESS_UPDATE_SUCCESS',
  ADDRESS_UPDATE_ERROR: 'ADDRESS_UPDATE_ERROR',
  ADDRESS_SHOW_ALERT: 'ADDRESS_SHOW_ALERT',
  ADDRESS_HIDE_ALERT: 'ADDRESS_HIDE_ALERT',
  ADDRESS_DELETE_REQUEST: 'ADDRESS_DELETE_REQUEST',
  ADDRESS_DELETE_SUCCESS: 'ADDRESS_DELETE_SUCCESS',
  ADDRESS_DELETE_ERROR: 'ADDRESS_DELETE_ERROR',
  ADDRESS_REQUEST: 'ADDRESS_REQUEST',
  ADDRESS_SUCCESS: 'ADDRESS_SUCCESS',
  getAllAddresses: data => ({
    type: address.ADDRESS_ALL_LIST_REQUEST,
    data
  }),
  showModal: data => ({ type: address.ADDRESS_SHOW_MODAL, data }),
  hideModal: data => ({ type: address.ADDRESS_HIDE_MODAL, data }),
  createAddress: data => ({ type: address.ADDRESS_CREATE_REQUEST, data }),
  updateAddress: data => ({ type: address.ADDRESS_UPDATE_REQUEST, data }),
  getAddressById: id => ({ type: address.ADDRESS_REQUEST, id }),
  showAlert: data => ({ type: address.ADDRESS_SHOW_ALERT, data }),
  hideAlert: data => ({ type: address.ADDRESS_HIDE_ALERT, data }),
  deleteAddressById: id => ({ type: address.ADDRESS_DELETE_REQUEST, id })
};

export default address;
