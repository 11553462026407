import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import { payout as payoutActions } from '../../actions';
import routePath from '../../constants/routePath';
import PayoutDownloadButton from './PayoutDownloadButton';
import SendMoneyPopup from './SendMoneyPopup';

const getStatusClass = status => {
  switch (status) {
    case 'PROCESSING':
      return 'badge badge-warning badge-pill';
    case 'COMPLETED':
      return 'badge badge-success badge-pill';
    default:
      return 'badge badge-light badge-pill';
  }
};

class PayoutView extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getAdminPayoutItem, match } = this.props;
    if (match.params && match.params.id) {
      getAdminPayoutItem({ id: match.params.id });
    }
  }

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getAdminPayoutItem, match } = this.props;
    if (match.params && match.params.id) {
      getAdminPayoutItem({ id: match.params.id, page, per_page: perPage });
    }
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(prevState => ({
        selected: [...prevState.selected, ...ids]
      }));
    } else {
      this.setState(prevState => ({
        selected: prevState.selected.filter(x => !ids.includes(x))
      }));
    }
  };

  resetSelected = () => {
    this.setState({ selected: [] });
  };

  handlePayoutApprove = () => {
    const { approvePayout } = this.props;
    const { selected } = this.state;
    approvePayout({ selected, callback: this.resetSelected });
  };

  handlePayoutRefuse = () => {
    const { refusePayout } = this.props;
    const { selected } = this.state;
    refusePayout({ selected, callback: this.resetSelected });
  };

  handleSendMoney = () => {
    const { showHidePayoutModal } = this.props;
    showHidePayoutModal(true);
  };

  closePayoutModal = () => {
    const { showHidePayoutModal } = this.props;
    showHidePayoutModal(false);
  };

  render() {
    const {
      payout,
      isLoading,
      createProgress,
      payoutModal,
      match,
      filterValues
    } = this.props;
    const { page, selected } = this.state;
    const columns = [
      {
        dataField: 'user_id',
        text: 'Associate ID',
        headerAttrs: { width: '15%' },
        formatter: (col, row) => {
          if (row.user) {
            return row.user.uuid;
          }
          return null;
        }
      },
      {
        dataField: 'user',
        text: 'Name',
        headerAttrs: { width: '35%' },
        formatter: col => {
          if (col) {
            return `${col.first_name} ${col.last_name}`;
          }
          return '---';
        }
      },
      {
        dataField: 'email',
        text: 'Email',
        formatter: (col, row) => {
          if (row.user) {
            return row.user.email;
          }
          return '---';
        }
      },
      {
        dataField: 'phone',
        text: 'Phone',
        formatter: (col, row) => {
          if (row.user) {
            return row.user.phone_number;
          }
          return '---';
        }
      },
      {
        dataField: 'amount',
        text: 'Amount',
        formatter: (col, row) => (
          <span>
            {row.euro_value === 1
              ? `€${parseFloat(col).toFixed(2)}`
              : `${
                  row.currency ? row.currency.currency_symbol : ''
                }${parseFloat(row.currency_total_payout).toFixed(2)}`}
          </span>
        )
      },
      {
        dataField: 'approve_status',
        text: 'Approved',
        classes: 'text-center',
        formatter: col => {
          if (col) {
            return <span className="badge badge-success badge-pill">YES</span>;
          }
          return <span className="badge badge-warning badge-pill">NO</span>;
        }
      },
      {
        dataField: 'payment_status',
        text: 'Paid',
        classes: 'text-center',
        formatter: col => <span className={getStatusClass(col)}>{col}</span>
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/payout/${row.id}/statement`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
            <PayoutDownloadButton id={row.id} />
          </>
        )
      }
    ];
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.payoutView.breadcrumb} />
              <h4 className="page-title">{routePath.payoutView.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-8">
                    <h5 className="text-primary">{payout.period}</h5>
                  </div>
                  <div className="col-4 text-sm-right">
                    <Dropdown>
                      <Dropdown.Toggle
                        size="sm"
                        variant="primary"
                        id="actionDropdown"
                      >
                        Actions
                      </Dropdown.Toggle>
                      <Dropdown.Menu alignRight>
                        <Dropdown.Item
                          onClick={this.handlePayoutApprove}
                          disabled={createProgress || !selected.length}
                        >
                          <i className="mdi mdi-checkbox-marked" /> Approve
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={this.handlePayoutRefuse}
                          disabled={createProgress || !selected.length}
                        >
                          <i className="mdi mdi-close-box text-danger" /> Refuse
                        </Dropdown.Item>
                        <Dropdown.Item
                          disabled={!selected.length}
                          onClick={this.handleSendMoney}
                        >
                          <i className="mdi mdi-bank" /> Send Money
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <AdvanceTable
                  data={payout.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  sizePerPageList={filterValues}
                  totalSize={payout.total}
                  sizePerPage={payout.per_page}
                  hideSizePerPage={false}
                  page={page}
                  onTableChange={this.handleTableChange}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                  selected={selected}
                />
              </div>
            </div>
          </div>
        </div>
        {payoutModal ? (
          <SendMoneyPopup
            pagePayload={{ id: match.params.id, page }}
            callback={this.resetSelected}
            selected={selected}
            onClose={this.closePayoutModal}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  payout: state.payout.adminPayoutItem,
  isLoading: state.payout.isLoading,
  createProgress: state.payout.createProgress,
  payoutModal: state.payout.payoutModal,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAdminPayoutItem: payoutActions.getAdminPayoutItem,
      approvePayout: payoutActions.approvePayout,
      refusePayout: payoutActions.refusePayout,
      showHidePayoutModal: payoutActions.showHidePayoutModal
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutView);
