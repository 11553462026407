import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector, change } from 'redux-form';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import routePath from '../../constants/routePath';
import AddressItem from './AddressItem';
import {
  app as appActions,
  address as addressActionTypes
} from '../../actions';
import InnerLoader from '../../components/InnerLoader';
import NoResult from '../../components/NoResult';
import StoreAddress from '../../components/StoreAddress';
import WithShippingPrompt from './WithShippingPrompt';

const AddressItemWithPrompt = WithShippingPrompt(AddressItem);

function ShippingInfo({
  addresses,
  isLoading,
  shippingAddress,
  shippingMode,
  storeAddress,
  handleTabChange,
  cartDetails,
  showModal,
  dispatch,
  storePickup = false,
  getAllAddresses,
  getPrefetchStoreAddress
}) {
  useEffect(() => {
    getAllAddresses();
  }, []);

  useEffect(() => {
    getPrefetchStoreAddress(cartDetails.country);
  }, [cartDetails.country]);

  const handleAddressAdd = () => {
    showModal();
  };

  const handleStoreAddressSelect = e => {
    if (e.target.checked) {
      dispatch(change('checkoutForm', 'shipping_address_id', ''));
      dispatch(change('checkoutForm', 'pickup_from_store', 1));
    } else {
      dispatch(change('checkoutForm', 'shipping_address_id', ''));
      dispatch(change('checkoutForm', 'pickup_from_store', 0));
    }
  };

  const handleAddressSelect = val => {
    dispatch(change('checkoutForm', 'shipping_address_id', val));
    dispatch(change('checkoutForm', 'pickup_from_store', 0));
  };

  const shippings = addresses.filter(
    address => address.type === 'SHIPPING' || address.type === 'BOTH'
  );
  return (
    <>
      {storeAddress && storeAddress.store_pickup ? (
        <>
          <div className="row mb-2">
            <div className="col-sm-12">
              <label className="custom-control custom-checkbox mb-0">
                <input
                  type="checkbox"
                  name="SHIPPING"
                  className="custom-control-input"
                  value={storeAddress.id}
                  checked={storePickup}
                  onChange={handleStoreAddressSelect}
                />
                <div className="custom-control-label font-weight-bold">
                  <h5 className="align-text-bottom">Pickup from Store</h5>
                  <StoreAddress country={storeAddress.country} />
                </div>
              </label>
            </div>
          </div>
          {!storePickup ? (
            <div className="row">
              <div className="col">
                <hr />
              </div>
              <div className="col-auto mt-1">OR</div>
              <div className="col">
                <hr />
              </div>
            </div>
          ) : null}
        </>
      ) : null}
      {!storePickup && (
        <>
          <div className="row mb-2">
            <div className="col-sm-12">
              <h5 className="mt-2 mb-3">Choose Shipping Address</h5>
            </div>
            {cartDetails && cartDetails.country ? (
              <div className="col-sm-12">
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  <strong>
                    <i className="mdi mdi mdi-information-outline text-primary font-18" />
                  </strong>{' '}
                  You have added products for country
                  <strong>{` "${cartDetails.country_name}"`}</strong>
                </div>
              </div>
            ) : null}
            <div className="col-sm-12">
              <button
                type="button"
                className="btn btn-light btn-sm"
                onClick={handleAddressAdd}
              >
                <i className="mdi mdi-plus-circle" />
                <span>&nbsp; Add New Address</span>
              </button>
            </div>
          </div>
          <div className="row row-eq-height">
            {!isLoading ? (
              <>
                {shippings.length ? (
                  shippings.map(address => (
                    <AddressItemWithPrompt
                      address={address}
                      grid={3}
                      key={address.id}
                      isSelected={parseInt(shippingAddress, 10)}
                      onSelect={handleAddressSelect}
                    />
                  ))
                ) : (
                  <NoResult />
                )}
              </>
            ) : (
              <InnerLoader type="table" />
            )}
          </div>
        </>
      )}
      <div className="row text-center text-xs-left text-sm-left">
        <div className="col-12">
          <hr />
        </div>
        <div className="col-xs-6 col-sm-6">
          <Link
            to={routePath.shop.path}
            className="btn d-sm-inline-block btn-light font-weight-semibold mb-3 mb-sm-0"
          >
            <i className="mdi mdi-arrow-left mr-1" />
            <span>Back to Shop</span>
          </Link>
        </div>
        <div className="col-xs-6 col-sm-6">
          <div className="text-xs-right text-sm-right">
            <Button
              variant="primary"
              className="font-weight-semibold"
              disabled={!storePickup && !shippingAddress && !shippingMode}
              onClick={() => handleTabChange('billing-information')}
            >
              <span>Continue</span>
              <i className="mdi mdi-arrow-right ml-1" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

const selector = formValueSelector('checkoutForm');

const mapStateToProps = state => ({
  addresses: state.address.list,
  isLoading: state.address.isLoading,
  shippingAddress: selector(state, 'shipping_address_id'),
  shippingMode: selector(state, 'shipping_mode'),
  storePickup: selector(state, 'pickup_from_store'),
  cartDetails: state.cart.cartDetails,
  storeAddress: state.app.storeAddress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllAddresses: addressActionTypes.getAllAddresses,
      getPrefetchStoreAddress: appActions.getPrefetchStoreAddress,
      showModal: addressActionTypes.showModal,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingInfo);
