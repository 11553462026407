import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { fsb as fsbActionTypes } from '../actions';

function* getFsb() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb/config`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.FSB_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateFsb(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb/config`,
      method: 'PUT',
      data: actions.data,
      successMessage: 'Fsb updated successfully'
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.FSB_UPDATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: fsbActionTypes.FSB_UPDATE_ERROR, data: error });
  }
}

function* adminUpdateFsb(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb-track/performance/${actions.id}`,
      method: 'PUT',
      data: actions.data,
      successMessage: 'Fsb updated successfully'
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.ADMIN_FSB_UPDATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: fsbActionTypes.FSB_PERFORMANCE_DETAILS_REQUEST,
        id: actions.id
      });
    }
  } catch (error) {
    yield put({ type: fsbActionTypes.ADMIN_FSB_UPDATE_ERROR, data: error });
  }
}

function* getFsbPerformanceList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb-track/performance`,
      method: 'GET',
      params: actions.params
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.FSB_PERFORMANCE_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getFsbPerformanceDetails(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/fsb-track/performance/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: fsbActionTypes.FSB_PERFORMANCE_DETAILS_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetFsb() {
  yield takeLatest(fsbActionTypes.FSB_REQUEST, getFsb);
}

function* watchFsbUpdate() {
  yield takeLatest(fsbActionTypes.FSB_UPDATE_REQUEST, updateFsb);
}

function* watchGetFsbPerformanceList() {
  yield takeLatest(
    fsbActionTypes.FSB_PERFORMANCE_LIST_REQUEST,
    getFsbPerformanceList
  );
}

function* watchGetFsbPerformanceDetails() {
  yield takeLatest(
    fsbActionTypes.FSB_PERFORMANCE_DETAILS_REQUEST,
    getFsbPerformanceDetails
  );
}

function* watchAdminFsbUpdate() {
  yield takeLatest(fsbActionTypes.ADMIN_FSB_UPDATE_REQUEST, adminUpdateFsb);
}

export default [
  fork(watchFsbUpdate),
  fork(watchGetFsb),
  fork(watchGetFsbPerformanceList),
  fork(watchGetFsbPerformanceDetails),
  fork(watchAdminFsbUpdate)
];
