import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function TemplateViewModal(props) {
  const { handleModal } = props;
  return (
    <Modal size="xl" show onHide={handleModal}>
      <Modal.Header closeButton />
      <Modal.Body className="iframe-modal">
        <iframe
          title="email template"
          src="/template.html"
          frameBorder="0"
          className="full-iframe"
        />
      </Modal.Body>
    </Modal>
  );
}

export default TemplateViewModal;
