import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { get, find, findIndex } from 'lodash';
import MediaForm from './MediaForm';
import { media as mediaActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';

class MediaEdit extends React.Component {
  componentDidMount() {
    const { media, match, getMediaById } = this.props;
    const mediaList = get(media, 'data', []);
    if (
      match.params &&
      match.params.id &&
      parseInt(match.params.id, 10) &&
      findIndex(mediaList, { id: parseInt(match.params.id, 10) }) < 0
    ) {
      getMediaById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateMedia, match } = this.props;
    updateMedia({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  render() {
    const { updateProgress, media, match } = this.props;
    const mediaList = get(media, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      data = find(mediaList, { id: parseInt(match.params.id, 10) });
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.mediaEdit.breadcrumb} />
              <h4 className="page-title">{routePath.mediaEdit.title}</h4>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card media_add">
                <div className="card-body">
                  <MediaForm
                    submitForm={this.handleSubmit}
                    initialValues={data}
                    inProgress={updateProgress}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.media.updateProgress,
  media: state.media.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateMedia: mediaActions.updateMedia,
      getMediaById: mediaActions.getMediaById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MediaEdit)
);
