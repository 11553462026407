const checkout = {
  CHECKOUT_REQUEST: 'CHECKOUT_REQUEST',
  CHECKOUT_SUCCESS: 'CHECKOUT_SUCCESS',
  CHECKOUT_ERROR: 'CHECKOUT_ERROR',
  ZOTTOPAY_MODAL_OPEN: 'ZOTTOPAY_MODAL_OPEN',
  ZOTTOPAY_MODAL_CLOSE: 'ZOTTOPAY_MODAL_CLOSE',
  doCheckout: data => ({ type: checkout.CHECKOUT_REQUEST, data }),
  zoottopayModalOpen: () => ({ type: checkout.ZOTTOPAY_MODAL_OPEN }),
  zoottopayModalClose: () => ({ type: checkout.ZOTTOPAY_MODAL_CLOSE })
};

export default checkout;
