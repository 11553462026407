/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';

import { storeAddress as storeAddressTypes, app } from '../../actions';
import { initialPaymentState } from '../../utills/storePaymentState';

import PaymentGatewayDetailsForm from '../../components/PaymentGatewayForm';

const paymentReducer = (state, { type, payload }) => {
  if (Object.keys(state).includes(type)) {
    return { ...state, [type]: payload };
  }
  if (type === 'STATE_FROM_PROPS') {
    return payload;
  }

  return state;
};

const PaymentGatewayDetailsFormContainer = ({
  getCountry,
  storeAddressData,
  onSubmit,
  paymentGatewayList,
  countryProp,
  activeModeProp,
  isEdit,
  title
}) => {
  useEffect(() => {
    getCountry();
  }, []);

  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    if (storeAddressData.country_settings.data)
      setCountryList(
        storeAddressData.country_settings.data.map(item => ({
          id: item.id,
          label: item.store_country,
          value: item.store_country_iso2
        }))
      );
  }, [storeAddressData]);
  const [country, setCountry] = useState('');
  const [activeMode, setActiveMode] = useState([]);

  useEffect(() => {
    if (activeModeProp) setActiveMode(activeModeProp);
  }, [activeModeProp]);

  useEffect(() => {
    if (countryProp) setCountry(countryProp);
  }, [countryProp]);

  const [paymentState, dispatchPayment] = useReducer(
    paymentReducer,
    initialPaymentState
  );

  // if the list of paymentGateway recieved from parent component => update the state
  useEffect(() => {
    if (paymentGatewayList) {
      dispatchPayment({
        type: 'STATE_FROM_PROPS',
        payload: paymentGatewayList
      });
    }
  }, [paymentGatewayList]);

  const handleChangePaymentData = (type, payload) => {
    dispatchPayment({ type, payload });
  };

  const handleActiveModeChange = values => {
    setActiveMode(map(values, 'value'));
  };

  const handleSubmit = () => {
    onSubmit({ country, active_mode: activeMode, ...paymentState });
  };

  return (
    <PaymentGatewayDetailsForm
      paymentState={paymentState}
      countryList={countryList}
      country={country}
      activeMode={activeMode}
      handleCountryChange={setCountry}
      handleActiveModeChange={handleActiveModeChange}
      handleChangePaymentData={handleChangePaymentData}
      handleSubmit={handleSubmit}
      isEdit={isEdit}
      title={title}
    />
  );
};

const MSTP = state => ({
  storeAddressData: state.storeAddress.data
});

const MDSTP = {
  getCountry: storeAddressTypes.getStoreAddressList
};

export default connect(
  MSTP,
  MDSTP
)(PaymentGatewayDetailsFormContainer);
