import _, { findIndex } from 'lodash';
import { setting as settingActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { insertItem, updateObjectInArray } from '../utills/custom';

function setting(state = initialState.setting, action) {
  switch (action.type) {
    case settingActionTypes.SETTING_ALL_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case settingActionTypes.SETTING_ALL_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allList: action.data
        };
      }
      return state;
    case settingActionTypes.SETTING_ALL_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case settingActionTypes.SETTING_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case settingActionTypes.SETTING_CREATE_SUCCESS:
    case settingActionTypes.SETTING_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case settingActionTypes.SETTING_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case settingActionTypes.SETTING_SUCCESS:
      if (action.data) {
        const settingList = {};
        _.forEach(action.data.data, set => {
          settingList[set.key] = set.value;
        });
        return {
          ...state,
          data: settingList,
          isLoading: false
        };
      }
      return state;
    case settingActionTypes.SETTING_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case settingActionTypes.SETTING_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case settingActionTypes.SETTING_UPDATE_SUCCESS:
    case settingActionTypes.SETTING_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };

    case settingActionTypes.LOGIN_HISTORY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case settingActionTypes.LOGIN_HISTORY_SUCCESS:
      if (action.data) {
        return {
          ...state,
          allList: action.data,
          isLoading: false
        };
      }
      return state;

    default:
      return state;
  }
}

export default setting;
