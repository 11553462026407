import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { getFormValues, change, arraySplice, arrayPush } from 'redux-form';
import { findIndex, find } from 'lodash';
import CountryContext from './countryContext';
import ProductCountryModal from './ProductCountryModal';
import useCountryPrice from './hooks/useCountryPrice';

function SelectedCountryItem(props) {
  const { name, fieldValue, formValues, dispatch } = props;
  const [showModal, setShowModal] = useState(false);
  const { stateList } = useContext(CountryContext);
  const [countryName, setCountryName] = useState('');
  const { getConvertedPrice } = useCountryPrice(
    fieldValue.country,
    name,
    fieldValue,
    dispatch
  );

  const {
    countries: selectedCountries,
    uncovered_states: uncoveredStates,
    states
  } = formValues;

  useEffect(() => {
    const geStateList = async () => {
      try {
        // Set all states as selected as default
        // Check if state is already present (Condition for edit mode)
        const countryIndex = findIndex(selectedCountries, {
          country: fieldValue.country
        });
        if (
          stateList[fieldValue.country] &&
          stateList[fieldValue.country].states &&
          countryIndex > -1
        ) {
          setCountryName(stateList[fieldValue.country].country);
          const stateIds = [];
          stateList[fieldValue.country].states.forEach(item => {
            if (!uncoveredStates.includes(item.id)) {
              // Find state prices if present
              const stateObj = find(states, { state: item.id });
              if (stateObj) {
                stateIds.push({
                  state: item.id,
                  regular_price: stateObj.regular_price,
                  sale_price: stateObj.sale_price,
                  member_price: stateObj.member_price,
                  member_price_vat: stateObj.member_price_vat,
                  customer_price_vat: stateObj.customer_price_vat,
                  bpoint: stateObj.bpoint,
                  cpoint: stateObj.cpoint,
                  taxable: stateObj.taxable,
                  tax_id: stateObj.tax_id,
                  inherit: false
                });
              } else {
                stateIds.push({
                  state: item.id,
                  regular_price: fieldValue.regular_price,
                  sale_price: fieldValue.sale_price,
                  member_price: fieldValue.member_price,
                  member_price_vat: fieldValue.member_price_vat,
                  customer_price_vat: fieldValue.customer_price_vat,
                  bpoint: fieldValue.bpoint,
                  cpoint: fieldValue.cpoint,
                  taxable: fieldValue.taxable,
                  tax_id: fieldValue.taxId,
                  inherit: true
                });
              }
            }
          });
          const newCountries = [...selectedCountries];
          newCountries[countryIndex].states = stateIds;
          dispatch(change('productForm', 'countries', newCountries));
        }
      } catch (error) {
        console.log(error);
      }
    };
    geStateList();
  }, [fieldValue.country, stateList]);

  /**
   * Convert general price and set to country price
   *
   * If general price if changed and check_currency_value is checked
   * we convert the general price and replace with country price
   */
  useEffect(() => {
    if (fieldValue.check_currency_value) {
      getConvertedPrice({
        country: fieldValue.country,
        regular_price: formValues.regular_price,
        sale_price: formValues.sale_price
      });
    }
  }, [
    formValues.regular_price,
    formValues.sale_price,
    fieldValue.check_currency_value
  ]);

  const deleteCountry = () => {
    const cIndx = findIndex(selectedCountries, { country: fieldValue.country });
    if (cIndx > -1) {
      dispatch(arraySplice('productForm', 'countries', cIndx, 1));
      dispatch(
        arrayPush('productForm', 'uncovered_countries', fieldValue.country)
      );
    }
  };

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className="category-selected-country mb-1 ml-1 p-2 rounded"
        style={{ width: 'fit-content' }}
      >
        {countryName}
        {` (${fieldValue && fieldValue.states ? fieldValue.states.length : 0})`}
        <button
          type="button"
          className="text-primary ml-2 btn p-0"
          onClick={handleShowModal}
        >
          Edit
          <i className="mdi mdi-square-edit-outline ml-1" />
        </button>
        <button type="button" onClick={deleteCountry} className="btn ml-2 p-0">
          <i className="mdi mdi-close" />
        </button>
      </div>
      {showModal && (
        <ProductCountryModal
          countryName={countryName}
          countyIso2={fieldValue.country}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          statesInModal={stateList[fieldValue.country].states}
          name={name}
          fieldValue={fieldValue}
          uncoveredStates={uncoveredStates}
          inProgress={false}
        />
      )}
    </>
  );
}
// const selector = formValueSelector('productForm');
const mapStateToProps = state => ({
  formValues: getFormValues('productForm')(state)
  // selectedCountries: selector(state, 'countries')
});

export default connect(
  mapStateToProps,
  null
)(SelectedCountryItem);
