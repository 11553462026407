import React from 'react';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  inputField,
  checkboxField,
  selectField
} from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';

function TaxSettingForm({
  handleSubmit,
  submitForm,
  inProgress,
  pristine,
  submitting
}) {
  return (
    <Form
      className="needs-validation"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="row">
        <div className="col-md-4">
          <Field
            name="price_tax"
            component={selectField}
            id="price_tax"
            label="Prices entered with tax"
            tooltip="Prices entered with tax"
            notSearchable
            options={[
              {
                value: 'yes',
                label: 'Yes, i will enter prices inclusive of tax'
              },
              { value: 'no', label: 'No, i will enter prices exclusive of tax' }
            ]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="calc_tax"
            component={selectField}
            label="Calculate tax based on"
            notSearchable
            tooltip="Calculate tax based on"
            options={[
              { value: 'cust_shipping', label: 'Customer shipping address' },
              { value: 'cust_billing', label: 'Customer billing address' }
            ]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="shipping_tax_class"
            component={selectField}
            label="Shipping tax class"
            notSearchable
            tooltip="Calculate shipping based on"
            options={[
              {
                value: 'shipping_tax_cart_item',
                label: 'Shipping tax class based on cart items'
              },
              {
                value: 'shipping_tax_total_item',
                label: 'Shipping tax class based on total items'
              }
            ]}
          />
        </div>
        <div className="col-md-12">
          <Field
            name="round_tax"
            component={checkboxField}
            id="round_tax"
            label="Rounding"
            options={[
              {
                value: 'yes',
                label:
                  'Round tax at subtotal level, instead of rounding per line'
              }
            ]}
          />
        </div>
        {/* <div className="col-md-12">
          <Field
            name="additional_tax"
            component={checkboxField}
            id="additional_tax"
            label="Additional tax classes"
            tooltip="Additional tax classes"
            options={[
              { value: 'reduced', label: 'Reduced rate' },
              { value: 'zero', label: 'Zero rate' }
            ]}
          />
        </div> */}
        <div className="col-md-3">
          <Field
            name="display_price_shop"
            component={selectField}
            label="Display prices in the shop"
            notSearchable
            options={[
              { value: 'incl_vat', label: 'Inclusive tax' },
              { value: 'excl_vat', label: 'Exclusive tax' }
            ]}
          />
        </div>
        <div className="col-md-4">
          <Field
            name="display_price_cart_checkout"
            component={selectField}
            label="Display prices during cart and checkout"
            notSearchable
            options={[
              { value: 'incl_vat', label: 'Inclusive tax' },
              { value: 'excl_vat', label: 'Exclusive tax' }
            ]}
          />
        </div>
        <div className="col-md-3">
          <Field
            name="price_display_suffix"
            component={inputField}
            type="text"
            className="form-control"
            label="Price display suffix"
            tooltip="Price display suffix"
            placeholder="N/A"
          />
        </div>
        <div className="col-md-2">
          <Field
            name="display_tax_total"
            component={selectField}
            label="Display tax totals"
            notSearchable
            options={[
              { value: 'itemized', label: 'Itemized' },
              { value: 'tax_total_cart', label: 'Tax on Total Cart Items' }
            ]}
          />
        </div>
      </div>
      <button type="button" className="btn btn-light mr-2">
        Cancel
      </button>
      <button
        type="submit"
        disabled={inProgress || pristine || submitting}
        className="btn btn-primary"
      >
        {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
      </button>
    </Form>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const TaxSettingReduxForm = reduxForm({
  form: 'taxSettingForm' // a unique identifier for this form
})(TaxSettingForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxSettingReduxForm);
