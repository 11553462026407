import React from 'react';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Image from '../../components/Image';
import { productImage } from '../../constants/imageSize';
import img from '../../assets/images/img-placeholder.jpg';
import { getAssocProductPrice } from '../../utills/custom';

function UnavailableTag({ status, soldout }) {
  if (!status) {
    return (
      <div className="cart-item-sold-out col-12 font-weight-bold font-12 mb-2">
        <i className="dripicons-wrong mr-2" />
        Item no longer available! Please remove.
      </div>
    );
  }
  if (soldout) {
    return (
      <div className="cart-item-sold-out col-12 font-weight-bold font-12 mb-2">
        <i className="dripicons-warning mr-2" />
        Temporarily out of stock! Please remove.
      </div>
    );
  }
  return '';
}

function CartItem({ data, currency }) {
  let pimg = get(data, 'product.product_images[0].file.file_path', null);
  if (pimg) {
    pimg = `${process.env.REACT_APP_IMAGE_URL}/${pimg}`;
  } else {
    pimg = img;
  }
  return (
    <>
      <div
        className={
          data.product.sold_out || !data.product.status
            ? 'row align-middle no-gutters1 pt-2 pb-2 border-top cart-item-sold-out mb-1'
            : 'row align-middle no-gutters1 pt-2 pb-2 border-top'
        }
      >
        <UnavailableTag
          soldout={data.product.sold_out}
          status={data.product.status}
        />
        <div className="col-2 pr-0">
          <Image
            src={pimg}
            alt={data.product.title}
            size={productImage.productAdmin}
            className="rounded w-100"
          />
        </div>
        <div className="col-6 pr-0">
          <Link
            to={`/shop/product/${data.product.slug}`}
            className="text-body font-weight-semibold "
          >
            {data.product.title}
          </Link>
          <br />
          <small>{`${data.quantity} x ${currency}${data.product.member_price}`}</small>
        </div>
        <div className="col-4 text-right font-weight-bold">
          {`${currency}${(
            data.quantity * getAssocProductPrice(data.product, true)
          ).toFixed(2)}`}
        </div>
      </div>
    </>
  );
}

export default CartItem;
