import React from 'react';

const ImageField = ({
  id,
  containerClassName = 'form-group',
  label,
  inline,
  btnLabel,
  removeLabel,
  handleChange,
  image,
  reset
}) => {
  const newContainerClassName = `${containerClassName} ${inline ? ' row' : ''}`;
  return (
    <div className={newContainerClassName}>
      <div className={inline && inline[1]}>
        <div className="file-cloud">
          <input
            type="file"
            id={id}
            className="d-none"
            onChange={handleChange}
          />
          <div className={image && 'mb-2'}>
            {image && (
              <img
                src={image}
                style={{
                  maxWidth: '100%',
                  width: 'inherit',
                  height: 'inherit'
                }}
                alt={label}
              />
            )}
          </div>
          {image ? (
            <button
              type="button"
              className="btn btn-light btn-sm mb-1"
              onClick={() => reset()}
            >
              {removeLabel || 'Remove'}
            </button>
          ) : (
            <label htmlFor={id} className="btn btn-light btn-sm cursor-pointer">
              {btnLabel || 'Browse'}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageField;
