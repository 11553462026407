import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AdvanceTable from '../../components/AdvanceTable';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import { setting as settingActions } from '../../actions';

class Setting extends React.Component {
  componentDidMount() {
    const { getAllSettings } = this.props;
    getAllSettings();
  }

  render() {
    const { settings, isLoading, filterValues } = this.props;

    const columns = [
      {
        dataField: 'key',
        text: 'Key'
      },
      {
        dataField: 'value',
        text: 'Value'
      },
      {
        dataField: 'tag',
        text: 'Tag'
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.setting.breadcrumb} />
              <h4 className="page-title">{routePath.setting.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Link
                      to={routePath.settingAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      Add New
                    </Link>
                  </div>
                </div>

                <AdvanceTable
                  data={settings.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: false }}
                  totalSize={settings.data.length}
                  sizePerPage={200}
                  sizePerPageList={filterValues}
                  hideSizePerPage
                  page={1}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  settings: state.setting.allList,
  isLoading: state.setting.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllSettings: settingActions.getAllSettings
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Setting);
