import React from 'react';
import { change, Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import routePath from '../../constants/routePath';
import { required } from '../../utills/validator';
import {
  editorField,
  inputField,
  inputFieldControlled,
  radioField,
  selectField
} from '../../components/Fields';
import { template as templateActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';
import { getSlug } from '../../utills/custom';
import TemplateViewModal from './TemplateViewModal';

class TemplateForm extends React.Component {
  state = { modal: false };

  componentDidMount() {
    const { getAllTemplates } = this.props;
    // getAllTemplates();
  }

  handleModal = () => {
    this.setState(a => ({ modal: !a.modal }));
  };

  handleSlug = val => {
    const { formValues, dispatch } = this.props;
    if (formValues && formValues.title) {
      dispatch(change('templateForm', 'slug', getSlug(val)));
    }
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      templates,
      sendType
    } = this.props;
    const { modal } = this.state;
    const templateOption = [];
    if (templates && templates.data) {
      templates.data.forEach(item => {
        templateOption.push({ value: item.id, label: item.title });
      });
    }

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="card">
              <div className="card-body">
                <Field
                  name="title"
                  component={inputFieldControlled}
                  type="text"
                  className="form-control"
                  label="Title"
                  validate={[required]}
                />
                <Field
                  name="slug"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="Slug"
                  feedback={this.handleSlug}
                  validate={[required]}
                />
                <Field
                  name="body"
                  component={editorField}
                  className="form-control"
                  label="Email body"
                  validate={[required]}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="row mb-3">
              <div className="col">
                <Button
                  type="button"
                  className="btn btn-dark btn-block btn-sm mb-xs-2"
                  onClick={this.handleModal}
                >
                  Preview
                </Button>
              </div>
              <div className="col">
                <Button
                  as={Link}
                  to={routePath.template.path}
                  type="button"
                  className="btn btn-light btn-block btn-sm mb-xs-2"
                >
                  Cancel
                </Button>
              </div>
              <div className="col">
                <button
                  type="submit"
                  disabled={inProgress}
                  className="btn btn-primary btn-block btn-sm mb-xs-2"
                >
                  {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                </button>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Field
                  name="status"
                  component={radioField}
                  className="custom-control-input"
                  options={[
                    { label: 'Published', value: 'Published' },
                    { label: 'Draft', value: 'Draft' }
                  ]}
                  label="Status"
                  validate={[required]}
                />
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Field
                  name="userType"
                  notSearchable="1"
                  component={selectField}
                  options={[
                    { label: 'Sample', value: 'Sample' },
                    { label: 'Sample 1', value: 'Sample 1' }
                  ]}
                  validate={[required]}
                  label="Assigned to"
                />
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <Field
                  name="sendType"
                  component={radioField}
                  className="custom-control-input"
                  options={[
                    { label: 'Immediately', value: 'Immediately' },
                    { label: 'Queued', value: 'Queued' }
                  ]}
                  label="Trigger Email"
                  validate={[required]}
                />
                {sendType === 'Queued' && (
                  <Field
                    name="timing"
                    component={selectField}
                    validate={[required]}
                    notSearchable="1"
                    label="Time Interval"
                    className="form-control"
                    options={[
                      { label: '5', value: '5 mins' },
                      { label: '10', value: '10 mins' },
                      { label: '15', value: '15 mins' },
                      { label: '20', value: '20 mins' }
                    ]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        {modal && <TemplateViewModal handleModal={this.handleModal} />}
      </Form>
    );
  }
}

const selector = formValueSelector('templateForm');

const mapStateToProps = state => ({
  templates: state.template.allList,
  isLoading: state.template.isLoading,
  sendType: selector(state, 'sendType')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllTemplates: templateActions.getAllTemplates
    },
    dispatch
  );

const TemplateReduxForm = reduxForm({
  form: 'templateForm' // a unique identifier for this form
})(TemplateForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateReduxForm);
