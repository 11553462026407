import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { singleLocation as singleLocationActionTypes } from '../actions';

function* getSingleLocation(actions) {
  const {
    data: { type, id }
  } = actions;
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${type}/${id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: singleLocationActionTypes.SINGLE_LOCATION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetSingleLocation() {
  yield takeLatest(
    singleLocationActionTypes.SINGLE_LOCATION_REQUEST,
    getSingleLocation
  );
}

export default [fork(watchGetSingleLocation)];
