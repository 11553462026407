import React from 'react';
import { Link } from 'react-router-dom';
import routePath from '../constants/routePath';

export default function NoMatch() {
  return (
    <div className="container text-center">
      <div className="row">
        <div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 col-md-12 col-sm-12 col-12">
          <div className="error-section">
            <div className="error-section-content">
              <h1 className="display-3">
                <i
                  className="fa fa-exclamation-triangle text-danger mb-4"
                  style={{ fontSize: '90px' }}
                />
                <br />
                404
              </h1>
              <p>The requested page is not found.</p>
              <Link
                to={routePath.dashboard.path}
                className="btn btn-secondary btn-lg"
              >
                Back to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
