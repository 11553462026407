import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import {
  app as appActionTypes,
  auth as authActionTypes,
  checkout as checkoutTypes
} from '../actions';
import routePath from '../constants/routePath';
import { createForm } from '../utills/ajax';

function* login(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/login`,
      method: 'POST',
      data: actions.data,
      hideMessage: true
    });
    if (responseJson) {
      yield put({ type: authActionTypes.LOGIN_SUCCESS, data: responseJson });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.dashboard.path
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.LOGIN_ERROR, data: error });
  }
}

function* logout() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/logout`,
      method: 'POST',
      hideMessage: true
    });
    if (responseJson) {
      yield put({ type: authActionTypes.LOGOUT_SUCCESS, data: responseJson });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.login.path
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* register(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/register`,
      method: 'POST',
      data: actions.data,
      successMessage: 'You are successfully registered'
    });
    if (responseJson) {
      yield put({ type: authActionTypes.REGISTER_SUCCESS, data: responseJson });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.package.path
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.REGISTER_ERROR, data: error });
  }
}

function* getStateList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/states/${actions.data}`,
      method: 'GET'
    });
    if (responseJson) {
      const options = [];
      responseJson.forEach(item => {
        options.push({ label: item.name, value: item.id });
      });
      yield put({
        type: authActionTypes.SHIPPING_STATE_LIST_SUCCESS,
        data: options
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCityList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cities/${actions.data}`,
      method: 'GET'
    });
    if (responseJson) {
      const options = [];
      responseJson.forEach(item => {
        options.push({ label: item.name, value: item.id });
      });
      yield put({
        type: authActionTypes.SHIPPING_CITY_LIST_SUCCESS,
        data: options
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUser() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/user-info`,
      method: 'GET'
    });
    if (responseJson) {
      if (
        responseJson.permission &&
        responseJson.permission.role &&
        responseJson.permission.role.includes('customer')
      ) {
        NotificationManager.error('This Website is not for Customer');
        yield put({
          type: authActionTypes.LOGOUT_REQUEST
        });
      } else {
        yield put({
          type: authActionTypes.USER_INFO_SUCCESS,
          data: responseJson
        });
      }
    }
  } catch (error) {
    yield put({ type: authActionTypes.USER_INFO_ERROR, data: error });
  }
}

function* setPackage(actions) {
  try {
    yield put({
      type: authActionTypes.PACKAGE_SELECT_SUCCESS,
      data: actions.id
    });
  } catch (error) {
    yield put({ type: authActionTypes.SUBSCRIPTION_ERROR, data: error });
  }
}

function* getSubscriptionCalculation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/subscription/calculation`,
      method: 'POST',
      data: actions.data,
      hideMessage: true
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.SUBSCRIPTION_CALCULATION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* subscribe(actions) {
  try {
    let successMsg = 'Redirecting to QuickPay';
    if (actions.data.payment_type === 'zotto_pay') {
      successMsg = 'Redirecting to Zottopay';
    }
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/subscribe`,
      method: 'POST',
      data: actions.data,
      successMessage: successMsg
    });
    if (responseJson) {
      if (actions.data.payment_type === 'zotto_pay') {
        // yield put({ type: authActionTypes.SUBSCRIPTION_SUCCESS });
        // yield put({ type: checkoutTypes.ZOTTOPAY_MODAL_OPEN });
        // document.getElementById('zottopay_html').innerHTML = responseJson;
        // if (document.getElementById('userForm')) {
        //   document.getElementById('userForm').submit();
        // }
        createForm(responseJson);
      } else {
        window.location = responseJson.url;
      }
      /* ------------ DO NOT DELETE BELOW ----------------- */
      /* yield put({
        type: authActionTypes.SUBSCRIPTION_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.thankyou.path
      }); */
    }
  } catch (error) {
    yield put({ type: authActionTypes.SUBSCRIPTION_ERROR, data: error });
  }
}

function* subscriptionStatus() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/subscription-status`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.SUBSCRIPTION_STATUS_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.SUBSCRIPTION_STATUS_ERROR, data: error });
  }
}

function* forgotPassword(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/password/forgot`,
      method: 'POST',
      data: actions.data,
      successMessage:
        'Password reset instructions has been sent to your registered email'
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.FORGOT_PASSWORD_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.FORGOT_PASSWORD_ERROR, data: error });
  }
}

function* resetPassword(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/password/reset`,
      method: 'POST',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.RESET_PASSWORD_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.login.path
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.RESET_PASSWORD_ERROR, data: error });
  }
}

function* changePassword(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/user/change-password`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Your account password changed successfully.'
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.CHANGE_PASSWORD_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.profile.path
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.CHANGE_PASSWORD_ERROR, data: error });
  }
}

function* getRefUserId(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/check-referral-url`,
      method: 'POST',
      data: {
        ref_url: actions.data.refUserId
      },
      successMessage: 'Referral URL valid.'
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.REF_USER_ID_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({ type: authActionTypes.REF_USER_ID_ERROR, data: error });
  }
}

function* getSubscriptionShipping(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/subscription/get-shipping`,
      method: 'GET',
      params: actions.params
    });
    if (responseJson) {
      yield put({
        type: authActionTypes.SUBSCRIPTION_SHIPPING_OPTION_SUCCESS,
        data: responseJson
      });

      // Callback
      if (actions.callback) {
        actions.callback(responseJson);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetSubscriptionShipping() {
  yield takeLatest(
    authActionTypes.SUBSCRIPTION_SHIPPING_OPTION_REQUEST,
    getSubscriptionShipping
  );
}

function* refUserId() {
  yield takeLatest(authActionTypes.REF_USER_ID_REQUEST, getRefUserId);
}
function* watchLogin() {
  yield takeLatest(authActionTypes.LOGIN_REQUEST, login);
}

function* watchLogout() {
  yield takeLatest(authActionTypes.LOGOUT_REQUEST, logout);
}

function* watchRegister() {
  yield takeLatest(authActionTypes.REGISTER_REQUEST, register);
}

function* watchGetUser() {
  yield takeLatest(authActionTypes.USER_INFO_REQUEST, getUser);
}

function* watchSetPackage() {
  yield takeLatest(authActionTypes.PACKAGE_SELECT_REQUEST, setPackage);
}

function* watchSubscriptionCalculation() {
  yield takeLatest(
    authActionTypes.SUBSCRIPTION_CALCULATION_REQUEST,
    getSubscriptionCalculation
  );
}

function* watchSubscription() {
  yield takeLatest(authActionTypes.SUBSCRIPTION_REQUEST, subscribe);
}

function* watchSubscriptionStatus() {
  yield takeLatest(
    authActionTypes.SUBSCRIPTION_STATUS_REQUEST,
    subscriptionStatus
  );
}

function* watchForgotPassword() {
  yield takeLatest(authActionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword);
}

function* watchResetPassword() {
  yield takeLatest(authActionTypes.RESET_PASSWORD_REQUEST, resetPassword);
}

function* watchChangePassword() {
  yield takeLatest(authActionTypes.CHANGE_PASSWORD_REQUEST, changePassword);
}

function* watchStateList() {
  yield takeLatest(authActionTypes.SHIPPING_STATE_LIST_REQUEST, getStateList);
}
function* watchCityList() {
  yield takeLatest(authActionTypes.SHIPPING_CITY_LIST_REQUEST, getCityList);
}

export default [
  fork(watchLogin),
  fork(watchLogout),
  fork(refUserId),
  fork(watchRegister),
  fork(watchGetUser),
  fork(watchSetPackage),
  fork(watchSubscriptionCalculation),
  fork(watchSubscription),
  fork(watchSubscriptionStatus),
  fork(watchForgotPassword),
  fork(watchResetPassword),
  fork(watchChangePassword),
  fork(watchStateList),
  fork(watchCityList),
  fork(watchGetSubscriptionShipping)
];
