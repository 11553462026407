import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import AdvanceTable from '../../components/AdvanceTable';
import routePath from '../../constants/routePath';
import StatusSwitch from '../../components/StatusSwitch';
import { location as locationActions } from '../../actions';
import LinkTooltip from '../../components/LinkTooltip';
import LinkBack from './LinkBack';

class Country extends React.Component {
  state = {
    page: 1,
    selected: [],
    updatedStatus: {}
  };

  componentDidMount() {
    const { getAllLocations } = this.props;
    getAllLocations({ type: 'country' });
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows, list) => {
    const ids = rows.map(r => r.id);
    const fullIds = list.map(r => r.id);
    if (isSelect) {
      this.setState({
        selected: ids
      });
    } else if (ids.length < fullIds.length) {
      this.setState({
        selected: fullIds
      });
    } else if (ids.length === fullIds.length) {
      this.setState({
        selected: []
      });
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAllLocations } = this.props;
    if (type === 'pagination') {
      getAllLocations({ type: 'country', page, per_page: perPage });
    }
    this.setState({ page });
  };

  updateLocationStatus = (id, val) => {
    const { updateLocation } = this.props;

    updateLocation({
      id,
      type: 'country',
      data: { updateType: 'status' }
    });
    const { updatedStatus } = this.state;
    setTimeout(() => {
      this.setState({
        updatedStatus: {
          ...updatedStatus,
          [id]: val
        }
      });
    }, 500);
  };

  getStatus = (stateValue, fetchedValue) => {
    if (typeof stateValue === 'boolean') return stateValue;
    return fetchedValue;
  };

  render() {
    const { locations, isLoading, filterValues } = this.props;
    const { page, selected } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID'
      },
      {
        dataField: 'name',
        text: 'Country',
        formatter: (col, row) => (
          <LinkTooltip
            href={`/location/state/${row.id}`}
            position="top"
            message="List of states"
          >
            {col}
          </LinkTooltip>
        )
      },
      {
        dataField: 'currency',
        text: 'Currency',
        formatter: (col, row) =>
          row.currency &&
          `${row.currency.currency_abbreviation} (${row.currency.currency_symbol})`
      },
      {
        dataField: 'iso2',
        text: 'ISO-2',
        headerClasses: 'text-center',
        classes: 'text-center'
      },
      {
        dataField: 'iso3',
        text: 'ISO-3',
        headerClasses: 'text-center',
        classes: 'text-center'
      },
      {
        dataField: 'phonecode',
        text: 'Phone Code',
        headerClasses: 'text-center',
        classes: 'text-center'
      },
      {
        dataField: 'stateCount',
        text: 'No. of states',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '15%' },
        formatter: (col, row) =>
          col ? (
            <LinkTooltip
              href={`/location/state/${row.id}`}
              position="top"
              message="List of states"
            >
              {col}
            </LinkTooltip>
          ) : (
            <span className="text-danger">{col}</span>
          )
      },
      {
        dataField: 'status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={this.getStatus(
              // eslint-disable-next-line react/destructuring-assignment
              this.state.updatedStatus[row.id],
              parseInt(col, 10)
            )}
            onChange={this.updateLocationStatus}
            id={row.id}
            idPrefix="location_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/location/country/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];
    const countryList = Array.isArray(locations.data) ? locations.data : [];
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <LinkBack
                linkItems={[{ link: '/location/country', title: 'Location' }]}
              />
              <h4 className="page-title">{routePath.country.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-6" />
                  <div className="col-6">
                    <div className="btn-group btn-group-sm ml-2 float-right">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          <Dropdown.Item as={Link} to="/location/add/country">
                            <i className="mdi mdi-plus-circle-outline" /> Add
                            country
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-import" /> Import
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-export" /> Export
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <AdvanceTable
                  data={countryList}
                  columns={columns}
                  loading={isLoading}
                  sizePerPageList={filterValues}
                  totalSize={locations.total}
                  sizePerPage={locations.per_page}
                  page={page}
                  remote={{ pagination: true }}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  // onSelectAll={(...props) => this.handleOnSelectAll(...props)}
                  onSelectAll={(isSelect, rows) =>
                    this.handleOnSelectAll(isSelect, rows, countryList)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.location.allList,
  isLoading: state.location.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllLocations: locationActions.getAllLocations,
      updateLocation: locationActions.updateLocation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Country);
