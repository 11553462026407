import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from 'react-select';
import { get, find, map } from 'lodash';
import AdvanceTable from '../../components/AdvanceTable';
import StatusSwitch from '../../components/StatusSwitch';
import BreadCrumb from '../../components/BreadCrumb';
import Image from '../../components/Image';
import { app as appActions, packages as packageActions } from '../../actions';
import { packageImage } from '../../constants/imageSize';
import routePath from '../../constants/routePath';
import PackageViewModal from './PackageViewModal';
import img from '../../assets/images/img-placeholder.jpg';

class AssociatePackage extends React.Component {
  constructor(props) {
    super(props);
    this.updatePackageStatus = this.updatePackageStatus.bind(this);
    this.handleModal = this.handleModal.bind(this);

    this.columns = [
      {
        dataField: 'package_image_default',
        text: 'Image',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => {
          let pImage = get(row, 'file.file_path', null);
          if (pImage) {
            pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
          } else {
            pImage = img;
          }
          return (
            <span className="img_holder">
              <Image src={pImage} alt="" size={packageImage.packageAdmin} />
            </span>
          );
        }
      },
      {
        dataField: 'title',
        text: 'Title'
      },
      {
        dataField: 'ean',
        text: 'EAN 13 Number',
        // headerClasses: 'text-center',
        // classes: 'text-center',
        headerAttrs: { width: '18%' },
        formatter: (col, row) => (
          <span className={row.order_type_class_name}>{col}</span>
        )
      },
      {
        dataField: 'status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '9%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={parseInt(col, 10)}
            onChange={this.updatePackageStatus}
            id={row.id}
            idPrefix="assos_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '13%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <button
              type="button"
              className="action-icon btn btn-link"
              onClick={() => this.handleModal(row.id)}
            >
              <i className="action-icon mdi mdi-eye" />
            </button>
            <Link
              className="action-icon btn btn-link"
              to={`/associate-package/${row.id}/edit`}
            >
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];

    this.state = {
      page: 1,
      selected: [],
      modal: false
    };
  }

  componentDidMount() {
    const { getAllPackages, getCountry } = this.props;
    getAllPackages();
    getCountry();
  }

  updatePackageStatus = (id, val) => {
    const { updatePackage } = this.props;
    updatePackage({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  handleModal = id => {
    const { packages } = this.props;
    const { modal } = this.state;
    const data = find(packages, { id });
    if (!modal && data) {
      return this.setState({ modal: data });
    }
    return this.setState({ modal: false });
  };

  handleFilter = val => {
    const { getAllPackages } = this.props;
    const countries = map(val, 'value');
    getAllPackages({ country: countries });
  };

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getAllPackages, match } = this.props;
    const data = { page, per_page: perPage };
    if (match.params && match.params.id) {
      data.id = match.params.id;
    }
    getAllPackages(data);
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  render() {
    const { countryList, packages, isLoading, filterValues } = this.props;
    const { page, selected, modal } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.associatePackage.breadcrumb} />
              <h4 className="page-title">{routePath.associatePackage.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-6">
                    <div className="form-group form-group-sm">
                      <Select
                        name="country"
                        placeholder="Choose Country..."
                        id="country"
                        isMulti
                        className="react-select"
                        classNamePrefix="react-select"
                        options={countryList}
                        onChange={this.handleFilter}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Link
                      to={routePath.associatePackageAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      <span>Add New</span>
                    </Link>
                  </div>
                </div>
                <AdvanceTable
                  keyField="id"
                  data={packages.data}
                  columns={this.columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={packages.total}
                  sizePerPage={packages.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
                {modal && (
                  <PackageViewModal
                    data={modal}
                    handleModal={this.handleModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  packages: state.packages.allList,
  isLoading: state.packages.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllPackages: packageActions.getAllPackages,
      getCountry: appActions.getCountry,
      updatePackage: packageActions.updatePackage
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociatePackage);
