import { all } from 'redux-saga/effects';
import app from './app';
import auth from './auth';
import associate from './associate';
import customer from './customer';
import tree from './tree';
import customerTree from './customerTree';
import packages from './package';
import payout from './payout';
import paymentGateway from './paymentGateway';
import permission from './permission';
import category from './category';
import product from './product';
import generation from './generation';
import rank from './rank';
import fsb from './fsb';
import tax from './tax';
import address from './address';
import profile from './profile';
import paymentOption from './paymentOption';
import template from './template';
import shop from './shop';
import cart from './cart';
import shipping from './shipping';
import setting from './setting';
import media from './media';
import location from './location';
import coupons from './coupons';
import associateFsb from './associateFsb';
import checkout from './checkout';
import order from './order';
import dashboard from './dashboard';
import currency from './currency';
import singleLocation from './singleLocation';
import storeAddress from './storeAddress';
import storePaymentGateway from './storePaymentGateway';

export default function* root() {
  yield all([
    ...app,
    ...auth,
    ...associate,
    ...customer,
    ...tree,
    ...packages,
    ...payout,
    ...paymentGateway,
    ...permission,
    ...category,
    ...product,
    ...generation,
    ...rank,
    ...fsb,
    ...tax,
    ...address,
    ...profile,
    ...paymentOption,
    ...template,
    ...shop,
    ...cart,
    ...shipping,
    ...setting,
    ...associateFsb,
    ...checkout,
    ...media,
    ...location,
    ...coupons,
    ...order,
    ...dashboard,
    ...customerTree,
    ...currency,
    ...singleLocation,
    ...storeAddress,
    ...storePaymentGateway
  ]);
}
