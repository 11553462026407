const location = {
  LOCATION_ALL_LIST_REQUEST: 'LOCATION_ALL_LIST_REQUEST',
  LOCATION_ALL_LIST_SUCCESS: 'LOCATION_ALL_LIST_SUCCESS',
  LOCATION_ALL_LIST_ERROR: 'LOCATION_ALL_LIST_ERROR',
  LOCATION_CREATE_REQUEST: 'LOCATION_CREATE_REQUEST',
  LOCATION_CREATE_SUCCESS: 'LOCATION_CREATE_SUCCESS',
  LOCATION_CREATE_ERROR: 'LOCATION_CREATE_ERROR',
  LOCATION_UPDATE_REQUEST: 'LOCATION_UPDATE_REQUEST',
  LOCATION_UPDATE_SUCCESS: 'LOCATION_UPDATE_SUCCESS',
  LOCATION_UPDATE_ERROR: 'LOCATION_UPDATE_ERROR',
  LOCATION_REQUEST: 'LOCATION_REQUEST',
  LOCATION_SUCCESS: 'LOCATION_SUCCESS',
  getAllLocations: data => ({
    type: location.LOCATION_ALL_LIST_REQUEST,
    data
  }),
  createLocation: data => ({ type: location.LOCATION_CREATE_REQUEST, data }),
  updateLocation: data => ({ type: location.LOCATION_UPDATE_REQUEST, data }),
  getLocationById: data => ({ type: location.LOCATION_REQUEST, data })
};

export default location;
