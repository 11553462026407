const generation = {
  GENERATION_ALL_LIST_REQUEST: 'GENERATION_ALL_LIST_REQUEST',
  GENERATION_ALL_LIST_SUCCESS: 'GENERATION_ALL_LIST_SUCCESS',
  GENERATION_ALL_LIST_ERROR: 'GENERATION_ALL_LIST_ERROR',
  GENERATION_CREATE_REQUEST: 'GENERATION_CREATE_REQUEST',
  GENERATION_CREATE_SUCCESS: 'GENERATION_CREATE_SUCCESS',
  GENERATION_CREATE_ERROR: 'GENERATION_CREATE_ERROR',
  GENERATION_UPDATE_REQUEST: 'GENERATION_UPDATE_REQUEST',
  GENERATION_UPDATE_SUCCESS: 'GENERATION_UPDATE_SUCCESS',
  GENERATION_UPDATE_ERROR: 'GENERATION_UPDATE_ERROR',
  GENERATION_REQUEST: 'GENERATION_REQUEST',
  GENERATION_SUCCESS: 'GENERATION_SUCCESS',
  getAllGenerations: data => ({
    type: generation.GENERATION_ALL_LIST_REQUEST,
    data
  }),
  createGeneration: data => ({
    type: generation.GENERATION_CREATE_REQUEST,
    data
  }),
  updateGeneration: data => ({
    type: generation.GENERATION_UPDATE_REQUEST,
    data
  }),
  getGenerationById: id => ({ type: generation.GENERATION_REQUEST, id })
};

export default generation;
