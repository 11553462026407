import { findIndex } from 'lodash';
import { product as productActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { insertItem, updateObjectInArray } from '../utills/custom';

function product(state = initialState.product, action) {
  switch (action.type) {
    case productActionTypes.PRODUCT_ALL_LIST_REQUEST:
    case productActionTypes.PRODUCT_ALL_LIST_CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case productActionTypes.PRODUCT_ALL_LIST_SUCCESS:
    case productActionTypes.PRODUCT_ALL_LIST_CATEGORY_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allList: action.data
        };
      }
      return state;
    case productActionTypes.PRODUCT_ALL_LIST_ERROR:
    case productActionTypes.PRODUCT_ALL_LIST_CATEGORY_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case productActionTypes.PRODUCT_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case productActionTypes.PRODUCT_CREATE_SUCCESS:
    case productActionTypes.PRODUCT_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case productActionTypes.PRODUCT_CLONE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case productActionTypes.PRODUCT_CLONE_SUCCESS:
    case productActionTypes.PRODUCT_CLONE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case productActionTypes.PRODUCT_REQUEST:
      return {
        ...state,
        isLoadingSingle: true
      };
    case productActionTypes.PRODUCT_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        const newList = insertItem(state.allList.data, {
          index: index > -1 ? index : 0,
          item: action.data
        });
        return {
          ...state,
          allList: { ...state.allList, data: newList },
          isLoadingSingle: false
        };
      }
      return state;
    case productActionTypes.PRODUCT_ERROR:
      return {
        ...state,
        isLoadingSingle: false
      };
    case productActionTypes.PRODUCT_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case productActionTypes.PRODUCT_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        if (index > -1) {
          const newList = updateObjectInArray(state.allList.data, {
            index,
            item: action.data
          });
          const allList = { ...state.allList, data: newList };
          return {
            ...state,
            allList,
            updateProgress: false
          };
        }
      }
      return {
        ...state,
        updateProgress: false
      };
    case productActionTypes.PRODUCT_STATUS_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, {
          id: action.data[0].product_id
        });
        if (index > -1) {
          const newList = updateObjectInArray(state.allList.data, {
            index,
            item: {
              ...state.allList.data[index],
              status: action.data[0].status
            }
          });
          const allList = { ...state.allList, data: newList };
          return {
            ...state,
            allList,
            updateProgress: false
          };
        }
      }
      return {
        ...state,
        updateProgress: false
      };
    case productActionTypes.PRODUCT_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case productActionTypes.PRODUCT_PRICE_SUCCESS:
      if (action.data) {
        if (action.isTaxList) {
          return {
            ...state,
            taxes: action.data
          };
        }
      }
      return state;
    case productActionTypes.PRODUCT_CONVERT_PRICE_SUCCESS:
      if (action.data) {
        if (state.allList.data[0].countries.length) {
          const { data } = state.allList;
          const currentProduct = data[0];
          const filteredCountries = currentProduct.countries.map(c =>
            /* if the country in product countryList equals the conversion country,
            then update the countryList's country with converted prices */
            c.country === action.data.country
              ? {
                  ...c,
                  regular_price: action.data.reg_price,
                  sale_price: action.data.sale_price
                }
              : c
          );
          currentProduct.countries = filteredCountries;
          return {
            ...state,
            allList: { data: [currentProduct] }
          };
        }
      }
      return state;
    default:
      return state;
  }
}

export default product;
