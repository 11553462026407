import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { productImage } from '../../constants/imageSize';
import { cart as cartActions } from '../../actions';
import img from '../../assets/images/img-placeholder.jpg';
import Image from '../../components/Image';
import AddToCartButton from './AddToCartButton';

function gridClass(col) {
  if (col === '3') {
    return 'col-xs-6 col-sm-4 col-md-3 col-lg-4 mb-3';
  }
  if (col === '4') {
    return 'col-xs-6 col-sm-4 col-md-3 col-lg-3 mb-3';
  }
  return 'col-xs-6 col-sm-4 col-md-3 col-lg-3 mb-3';
}

class ProductItem extends React.Component {
  getCategoryLinks() {
    const { product } = this.props;
    if (product.category && product.category.length) {
      return (
        <ul className="list-inline mb-1 font-10 font-weight-semibold text-uppercase">
          {product.category.map(item => (
            <li key={item.id} className="list-inline-item">
              <Link
                className="text-muted"
                to="/"
                title={get(item, 'category[0].title', '')}
              >
                {get(item, 'category[0].title', '')}
              </Link>
            </li>
          ))}
        </ul>
      );
    }
    return null;
  }

  handleAddToCart = () => {
    const { cartId, product, addToCart, shopCountry, shopState } = this.props;
    if (cartId && shopCountry) {
      addToCart({
        cart_id: cartId,
        product_id: product.id,
        quantity: 1,
        country: shopCountry,
        state: shopState
      });
    }
  };

  render() {
    const { product, col, type, shopCountry, countryCurrencies } = this.props;
    let pImage = get(product, 'product_image_default[0].file.file_path', null);
    if (pImage) {
      pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
    } else {
      pImage = img;
    }
    const currency = countryCurrencies[shopCountry]
      ? countryCurrencies[shopCountry].currency_symbol
      : '';
    if (type === 'grid') {
      return (
        <div className={gridClass(col)}>
          <div className="card product-item h-100">
            <div className="item-cover">
              <Link to={`/shop/product/${product.slug}`} title={product.title}>
                <Image
                  className="card-img-top product-img"
                  src={pImage}
                  alt={product.title}
                  size={productImage.productGridMedium}
                />
              </Link>
              <div className="item-actions">
                <AddToCartButton
                  className="btn btn-primary btn-sm btn-block rounded-0"
                  onClick={this.handleAddToCart}
                />
              </div>
            </div>
            <div className="px-2 pt-2 pb-0 d-lg-none">
              <button
                type="button"
                onClick={this.handleAddToCart}
                className="btn btn-primary btn-sm btn-block rounded-0"
              >
                <i className="mdi mdi-cart" /> Add to cart
              </button>
            </div>
            <div className="mt-2">
              <Link to={`/shop/product/${product.slug}`} title={product.title}>
                <button
                  type="button"
                  className="btn btn-dark btn-sm btn-block rounded-0"
                >
                  <i className="mdi mdi-eye-outline" /> View Details
                </button>
              </Link>
            </div>

            <div className="card-body pb-0">
              {this.getCategoryLinks()}
              <h5 className="card-title font-weight-semibold">
                <Link
                  to={`/shop/product/${product.slug}`}
                  title={product.title}
                >
                  {product.title}
                </Link>
              </h5>
              <h5 className="card-title font-16">
                {`${currency}${
                  product.taxable
                    ? product.member_price_vat
                    : product.member_price
                } `}
                {product.taxable && (
                  <span className="badge badge-light text-muted font-9">
                    incl. VAT
                  </span>
                )}
                <br />
                <div className="badge badge-light text-success font-9">
                  {' '}
                  B-POINT: {product.bpoint ? product.bpoint : 0}
                </div>
              </h5>
              <p
                className="card-text font-12"
                dangerouslySetInnerHTML={{ __html: product.excerpt }}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="col-12">
        <div className="card product-item">
          <div className="row">
            <div className="col-sm-4 col-md-3">
              <div className="product-img-container h-100">
                <Link
                  to={`/shop/product/${product.slug}`}
                  title={product.title}
                >
                  <Image
                    className="product-img"
                    src={pImage}
                    alt={product.title}
                    size={productImage.productGridMedium}
                  />
                </Link>
              </div>
            </div>
            <div className="col-sm-8 col-md-9">
              <div className="card-body">
                {this.getCategoryLinks()}
                <h3 className="card-title font-weight-normal">
                  <Link
                    to={`/shop/product/${product.slug}`}
                    title={product.title}
                  >
                    {product.title}
                  </Link>
                </h3>
                <h4 className="card-title">
                  {`${currency}${
                    product.taxable
                      ? product.member_price_vat
                      : product.member_price
                  } `}
                  {product.taxable && (
                    <span className="badge badge-light text-muted font-9">
                      incl. VAT
                    </span>
                  )}
                  <br />
                  <div className="badge badge-light text-success font-9">
                    {' '}
                    B-POINT: {product.bpoint ? product.bpoint : '0'}
                  </div>
                </h4>
                <p
                  className="card-text font-12"
                  dangerouslySetInnerHTML={{ __html: product.excerpt }}
                />
                <div className="mt-2">
                  <button
                    type="button"
                    onClick={this.handleAddToCart}
                    className="btn btn-primary mr-2"
                  >
                    <i className="mdi mdi-cart" /> Add to cart
                  </button>
                  <Link
                    to={`/shop/product/${product.slug}`}
                    title={product.title}
                  >
                    <button type="button" className="btn btn-dark mr-2">
                      <i className="mdi mdi-eye-outline" /> View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cartId: state.cart.cartId,
  shopCountry: state.app.shopCountry,
  shopState: state.app.shopState,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addToCart: cartActions.addToCart
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductItem)
);
