import React from 'react';

function FsbUserInfo({ title, userInfo }) {
  return (
    <div className="text-left mb-3">
      <h5 className="mb-1">
        {title}
        &nbsp;
        <span
          className={`flag-icon flag-icon-${userInfo.country.toLowerCase()} mr-1`}
        />
        {userInfo.uuid}
      </h5>
      <p className="text-muted mb-0">
        <strong>Name: </strong>
        <span>{`${userInfo.first_name} ${userInfo.last_name}`}</span>
      </p>
      <p className="text-muted mb-0">
        <strong>Email: </strong>
        <span>{userInfo.email}</span>
      </p>
      <p className="text-muted mb-0">
        <strong>Phone: </strong>
        <span>{userInfo.phone_number}</span>
      </p>
    </div>
  );
}

export default FsbUserInfo;
