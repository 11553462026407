import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { storeAddress as storeAddressActions } from '../../actions';
import StatusSwitch from '../../components/StatusSwitch';

function CouponToggle({ country, status, updateCouponVisibility }) {
  const handleChange = () => {
    // Call the API
    updateCouponVisibility({ store_country_iso2: country });
  };

  return (
    <StatusSwitch
      checked={status}
      onChange={handleChange}
      id={country}
      idPrefix="coupon_status"
    />
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCouponVisibility: storeAddressActions.updateCouponVisibility
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(CouponToggle);
