const tax = {
  TAX_ALL_LIST_REQUEST: 'TAX_ALL_LIST_REQUEST',
  TAX_ALL_LIST_SUCCESS: 'TAX_ALL_LIST_SUCCESS',
  TAX_ALL_LIST_ERROR: 'TAX_ALL_LIST_ERROR',
  TAX_CREATE_REQUEST: 'TAX_CREATE_REQUEST',
  TAX_CREATE_SUCCESS: 'TAX_CREATE_SUCCESS',
  TAX_CREATE_ERROR: 'TAX_CREATE_ERROR',
  TAX_UPDATE_REQUEST: 'TAX_UPDATE_REQUEST',
  TAX_UPDATE_SUCCESS: 'TAX_UPDATE_SUCCESS',
  TAX_UPDATE_ERROR: 'TAX_UPDATE_ERROR',
  TAX_REQUEST: 'TAX_REQUEST',
  TAX_SUCCESS: 'TAX_SUCCESS',
  TAX_STATUS_UPDATE_REQUEST: 'TAX_STATUS_UPDATE_REQUEST',
  TAX_STATUS_UPDATE_SUCCESS: 'TAX_STATUS_UPDATE_SUCCESS',
  TAX_STATUS_UPDATE_ERROR: 'TAX_STATUS_UPDATE_ERROR',
  getAllTaxes: data => ({
    type: tax.TAX_ALL_LIST_REQUEST,
    data
  }),
  updateTaxStatus: data => ({
    type: tax.TAX_STATUS_UPDATE_REQUEST,
    data
  }),
  createTax: data => ({ type: tax.TAX_CREATE_REQUEST, data }),
  updateTax: data => ({ type: tax.TAX_UPDATE_REQUEST, data }),
  getTaxById: id => ({ type: tax.TAX_REQUEST, id })
};

export default tax;
