import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import AssociateForm from './AssociateForm';
import BreadCrumb from '../../components/BreadCrumb';
import {
  packages as packageActions,
  permission as permissionActions
} from '../../actions';
import routePath from '../../constants/routePath';
import { readableTransform } from '../../utills/custom';

class AssociateAdd extends React.Component {
  componentDidMount() {
    const { getRoles } = this.props;
    getRoles();
  }

  handleSubmit = values => {
    const { createPackage } = this.props;
    createPackage(values);
  };

  roleOptions = roles => {
    const options = [];
    roles.map(role =>
      options.push({ label: readableTransform(role.name), value: role.id })
    );
    return options;
  };

  render() {
    const { createProgress, roles } = this.props;
    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.associateAdd.breadcrumb} />
              <h4 className="page-title">{routePath.associateAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <AssociateForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                  roles={this.roleOptions(roles.data)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.associate.createProgress,
  roles: state.permission.roles
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createPackage: packageActions.createPackage,
      getRoles: permissionActions.getRoles
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociateAdd);
