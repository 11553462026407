import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import FsbViewForm from '../../components/FsbViewForm';
import FsbConfigTriangle from './FsbConfigTriangle';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';
import { fsb as fsbActions } from '../../actions';
import InnerLoader from '../../components/InnerLoader';

const phases2 = [
  {
    id: 1,
    title: 'Phase-01',
    bp: 500,
    missing_bp: 0,
    qualified: true
  },
  {
    id: 2,
    title: 'Phase-02',
    bp: 800,
    missing_bp: 450,
    qualified: false
  },
  {
    id: 3,
    title: 'Phase-03',
    bp: 1200,
    missing_bp: 0,
    qualified: true
  }
];

class FsbConfigView extends React.Component {
  componentDidMount() {
    const { getFsb } = this.props;
    getFsb();
  }

  render() {
    const { fsbPlans, isLoading } = this.props;
    const fsb = fsbPlans.data ? fsbPlans.data[0] : {};
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.fsbTracker.breadcrumb} />
              <h4 className="page-title">{routePath.fsbTracker.title}</h4>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            {isLoading ? (
              <InnerLoader type="table" />
            ) : (
              <div className="row">
                <div className="col-lg-6 col-md-12">
                  <div className="p-sm-2">
                    <FsbConfigTriangle data={fsb} />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <h4 className="mt-0 mb-3">
                    <div className="float-right badge badge-danger">
                      {`${fsb.total_duration} Days`}
                    </div>
                    FSB Tracker
                  </h4>
                  <p className="text-muted font-13 d-none">
                    View the tracker plan details below.
                  </p>

                  <div className="table-responsive">
                    <table className="table table-bordered table-centered table-sm no-width">
                      <thead className="table-light">
                        <tr>
                          <th width="40%">Phases</th>
                          <th className="text-center">BP</th>
                          <th className="text-center">Payout</th>
                        </tr>
                      </thead>
                      <tbody>
                        {fsb.phase &&
                          fsb.phase.map(phase => (
                            <tr key={phase.id}>
                              <td>{phase.title}</td>
                              <td className="text-center">{phase.bp_target}</td>
                              <td className="text-center">{phase.payout}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  {/* <div className="form-horizontal">
                    <FsbViewForm
                      initialValues={{
                        start_date: '2019-08-01',
                        end_date: '2019-08-30'
                      }}
                    />
                  </div> */}
                  <div className="table-responsive">
                    <table className="table table-bordered table-centered table-sm no-width">
                      <thead className="table-light">
                        <tr>
                          <th width="40%">Phases</th>
                          <th>Current BP</th>
                          <th>Missing BP</th>
                          <th>Qualified</th>
                        </tr>
                      </thead>
                      <tbody>
                        {phases2.map(phase => (
                          <tr key={`missing_${phase.id}`}>
                            <td>{phase.title}</td>
                            <td className="text-center">{phase.bp}</td>
                            <td className="text-center">{phase.missing_bp}</td>
                            <td className="text-center">
                              <span
                                className={`badge badge-pill badge-${
                                  phase.qualified ? 'success' : 'danger'
                                }`}
                              >
                                {phase.qualified ? 'Yes' : 'No'}
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div className="mt-2">
                    <h5>
                      <span>TOTAL QUALIFIED BP FOR 90 DAYS</span>{' '}
                      <span className="badge badge-primary font-18 mt-2 mt-sm-0">
                        2500
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.fsb.isLoading,
  fsbPlans: state.fsb.plan
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFsb: fsbActions.getFsb
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FsbConfigView);
