import React from 'react';

function DisplayAppliedCoupon({ couponDetails, coupon, onDelete }) {
  const { success_message: successMessage, error_message: errorMessage } =
    couponDetails || {};
  return (
    <tr>
      <td colSpan="2">
        <div className="applied-coupon-container">
          <div className="d-flex justify-content-center flex-column">
            <div className="d-flex align-items-center">
              <i
                className={`${
                  errorMessage
                    ? 'text-danger mdi mdi-close-circle'
                    : 'text-success mdi-check-decagram'
                } coupon-icon mdi lead`}
              />
              <span className="coupon-code">{coupon}</span>
            </div>
            <div className="">
              {errorMessage ? (
                <small className="text-danger">{errorMessage}</small>
              ) : (
                <small className="text-success">{successMessage || ''}</small>
              )}
            </div>
          </div>
          <button
            type="button"
            className="btn btn-link dashed-underline p-0"
            onClick={onDelete}
          >
            <small>Remove</small>
          </button>
        </div>
      </td>
    </tr>
  );
}

export default DisplayAppliedCoupon;
