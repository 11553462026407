import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SettingForm from './SettingForm';
import { setting as settingActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';

class SettingAdd extends React.Component {
  handleSubmit = values => {
    const { createSetting } = this.props;
    const formValues = {
      settings: [
        {
          key: values.key,
          value: values.value,
          tag: values.tag
        }
      ]
    };
    createSetting(formValues);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.settingAdd.breadcrumb} />
              <h4 className="page-title">{routePath.settingAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <SettingForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.setting.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createSetting: settingActions.createSetting }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingAdd);
