const tree = {
  ASSOCIATE_TREE_REQUEST: 'ASSOCIATE_TREE_REQUEST',
  ASSOCIATE_TREE_SUCCESS: 'ASSOCIATE_TREE_SUCCESS',
  DOWNLINE_ASSOCIATE_REQUEST: 'ASSOCIATE_DOWNLINE_REQUEST',
  DOWNLINE_ASSOCIATE_SUCCESS: 'ASSOCIATE_DOWNLINE_SUCCESS',
  getAssociateTree: data => ({ type: tree.ASSOCIATE_TREE_REQUEST, data }),
  getDownlineAssociate: data => ({
    type: tree.DOWNLINE_ASSOCIATE_REQUEST,
    data
  })
};

export default tree;
