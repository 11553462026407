import React, { useState } from 'react';
import DeleteAlert from '../../components/ConfirmAlert';

function ProductImageUpload({
  containerClassName,
  label,
  id,
  onChange,
  deleteFile,
  multiple,
  preview = []
}) {
  const [image, setImage] = useState(preview);
  const [alert, setAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const handleChange = e => {
    if (e.target.files && e.target.files.length) {
      if (onChange) {
        try {
          onChange(e.target.files[0]);
        } catch (error) {
          console.log(error);
        }
      }
      const reader = new FileReader();
      reader.onload = event => {
        if (multiple) {
          setImage([...image, event.target.result]);
        } else {
          setImage([event.target.result]);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleDelete = async () => {
    if (deleteFile && alert !== false) {
      const imgIndex = alert;
      try {
        setDeleting(true);
        await deleteFile(imgIndex);
        setDeleting(false);
        setAlert(false);
        if (multiple) {
          const images = [...image];
          images.splice(imgIndex, 1);
          setImage([...images]);
        } else {
          setImage([]);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <div className={containerClassName || ''}>
      <div className="upload-zone">
        <input type="file" id={id} className="d-none" onChange={handleChange} />
        {multiple || !image.length ? (
          <label htmlFor={id} className="btn btn-light">
            <i className="text-success dripicons-cloud-upload mr-1" /> Select
            image
            {multiple && 's'}
          </label>
        ) : null}
        <div className={`row ${multiple ? 'mt-3' : ''}`}>
          {image.map((item, index) => (
            <div key={`${id}_${index}`} className="col-4 mb-1">
              <div className="position-relative">
                <div className="img-actions">
                  <button
                    type="button"
                    className="btn btn-sm btn-danger"
                    onClick={() => setAlert(index)}
                  >
                    <i className="mdi mdi-delete" />
                  </button>
                </div>
                <img className="rounded w-100" src={item} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <DeleteAlert
        show={alert !== false}
        inProgress={deleting}
        onConfirm={handleDelete}
        onCancel={() => setAlert(false)}
      />
    </div>
  );
}

export default ProductImageUpload;
