import { storePaymentGateway as storePaymentGatewayTypes } from '../actions';
import initialState from '../constants/initialState';

function storePaymentGateway(state = initialState.storePaymentGateway, action) {
  switch (action.type) {
    case storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_LIST_SUCCESS:
      return {
        isLoading: false,
        data: {
          ...action.data
        }
      };
    case storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_SUCCESS:
      return {
        data: {
          ...action.data
        },
        isLoading: false
      };

    case storePaymentGatewayTypes.STORE_PAYMENT_GATEWAY_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case storePaymentGatewayTypes.STORE_DELETE_PAYMENT_GATEWAY_SUCCESS:
      return {
        isLoading: false,
        data: {
          ...state.data,
          data: state.data.data.filter(
            item => item.country_iso2 !== action.data
          )
        }
      };
    default:
      return state;
  }
}

export default storePaymentGateway;
