import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Button, Form } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { get } from 'lodash';
import axios from 'axios';

import { fileField, imageField } from '../../components/Fields';
import { profile as profileActions } from '../../actions';
import avatar from '../../assets/images/avatar.svg';
import btnLoader from '../../assets/images/btn-loader.gif';
import { profileImage } from '../../constants/imageSize';
import Image from '../../components/Image';
import DeleteAlert from '../../components/ConfirmAlert';

class ProfileImageForm extends React.Component {
  state = {
    uploadPic: false,
    alert: false
  };

  openUploadPicForm = () => {
    const { uploadPic } = this.state;
    this.setState({ uploadPic: !uploadPic });
  };

  handleAlert = () => {
    const { alert } = this.state;
    this.setState({ alert: !alert });
  };

  deleteProfilePic = async () => {
    const { updateProfileImage } = this.props;
    updateProfileImage({ deletePic: true });
  };

  submitForm = values => {
    const { updateProfileImage } = this.props;
    const fd = new FormData();
    fd.append('image', values.file);
    updateProfileImage(fd);
  };

  render() {
    const { uploadPic, alert } = this.state;
    const {
      handleSubmit,
      pristine,
      submitting,
      updateProgress,
      initialValues,
      profile
    } = this.props;
    let pImage = get(profile, 'profile_picture_file.file_path', null);
    if (pImage) {
      pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
    } else {
      pImage = avatar;
    }
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(this.submitForm)}
      >
        {!uploadPic ? (
          <div className="row mb-2">
            <div className="col-12">
              <Image
                className="img-thumbnail w-100 edit-pic"
                src={pImage}
                alt={`${profile.first_name} ${profile.last_name}`}
                size={profileImage.profileThumbnail}
              />
            </div>
            <div className="col-12 text-left text-md-center">
              <button
                type="button"
                onClick={this.openUploadPicForm}
                className="btn btn-sm btn-link mt-2 mr-lg-1"
              >
                <i className="mdi mdi-square-edit-outline mr-1" />
                <span>Edit picture</span>
              </button>
              {!/avatar/gi.test(pImage) && (
                <>
                  <button
                    type="button"
                    onClick={() => this.handleAlert()}
                    className="btn btn-sm btn-link mt-2"
                  >
                    <i className="mdi mdi-close-circle mr-1" />
                    <span>Remove</span>
                  </button>
                  <DeleteAlert
                    show={!!alert}
                    inProgress={updateProgress}
                    onConfirm={this.deleteProfilePic}
                    onCancel={this.handleAlert}
                  />
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="form-group text-center">
            <label htmlFor="profilePic" className="d-none">
              Profile picture
            </label>
            <div className="upload_img text-center align-items-center border border-light bg-light-lighten p-2">
              <Field
                name="file"
                component={imageField}
                id="profilePic"
                btnLabel="Browse Profile Photo"
                removeLabel="Remove Photo"
                containerClassName="m-0"
                // label="Upload picture"
              />
              <p className="font-10 text-left text-muted">
                Only jpeg, jpg and png is allowed.
                <br />
                Minimum dimension: 150 x 150 px
                <br />
                Maximum file size: 500 KB
              </p>
            </div>
            <div className="mt-2">
              <button
                type="button"
                onClick={this.openUploadPicForm}
                className="btn btn-sm btn-danger mr-2"
              >
                Cancel
              </button>
              <Button
                type="submit"
                disabled={pristine || submitting}
                variant="primary"
                size="sm"
              >
                {updateProgress ? <img src={btnLoader} alt="" /> : 'Upload'}
              </Button>
            </div>
          </div>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.profile.updateImageProgress,
  profile: state.profile.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProfileImage: profileActions.updateProfileImage
    },
    dispatch
  );

const EditProfileImageFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileImageForm);

export default reduxForm({
  form: 'editProfileImageForm' // a unique identifier for this form
})(EditProfileImageFormWithRedux);
