import React from 'react';
import {
  Field,
  Form,
  formValueSelector,
  getFormValues,
  reduxForm
} from 'redux-form';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { required } from '../../utills/validator';
import routePath from '../../constants/routePath';
import { inputField, selectField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';

function PayoutForm({ handleSubmit, submitForm, isLoading, payoutType }) {
  const years = () => {
    const currentYear = new Date().getFullYear();
    const yearsList = [];
    for (let i = currentYear; i >= 2019; i -= 1) {
      yearsList.push({ label: i, value: i });
    }
    return yearsList;
  };
  const getMonthOption = () => {
    const returnObj = [];
    for (let i = 0; i < 5; i += 1) {
      const dateObj = moment().subtract(i, 'month');
      returnObj.push({
        label: `${dateObj.format('MMMM')} - ${dateObj.format('YYYY')}`,
        value: `${dateObj.month() + 1},${dateObj.year()}`
      });
    }
    return returnObj;
  };

  return (
    <Form
      className="needs-validation mb-2"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="row">
        <div className="col-xs-12 col-md-3 d-none ">
          <Field
            name="type"
            id="payout_type"
            component={selectField}
            label="Payout Type"
            notSearchable
            options={[
              { label: 'Monthly', value: 'monthly' },
              { label: 'Yearly', value: 'yearly' }
            ]}
            validate={[required]}
          />
        </div>
        <div className="col-xs-12 col-md-3">
          <Field
            name="year"
            id="payout_year"
            component={selectField}
            label="Year"
            options={years()}
            validate={[required]}
          />
        </div>
        {payoutType === 'monthly' && (
          <div className="col-xs-12 col-md-3">
            <Field
              name="month"
              id="payout_month"
              component={selectField}
              label="Month"
              // options={getMonthOption()}
              options={[
                { label: 'January', value: 1 },
                { label: 'February', value: 2 },
                { label: 'March', value: 3 },
                { label: 'April', value: 4 },
                { label: 'May', value: 5 },
                { label: 'June', value: 6 },
                { label: 'July', value: 7 },
                { label: 'August', value: 8 },
                { label: 'September', value: 9 },
                { label: 'October', value: 10 },
                { label: 'November', value: 11 },
                { label: 'December', value: 12 }
              ]}
              validate={[required]}
            />
          </div>
        )}
        <div className="col-xs-12 col-md-3">
          <Field
            name="payout_no"
            component={inputField}
            type="text"
            className="form-control"
            label="Payout no."
            validate={[required]}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-12 mb-2">
          <Link to={routePath.payoutList.path} className="btn btn-light mr-2">
            Cancel
          </Link>
          <button type="submit" disabled={isLoading} className="btn btn-dark">
            {isLoading ? <img src={btnLoader} alt="" /> : 'Generate Payout'}
          </button>
        </div>
      </div>
    </Form>
  );
}

const selector = formValueSelector('generatePayoutForm');

const mapStateToProps = state => ({
  isLoading: state.payout.isLoading,
  payoutType: selector(state, 'type')
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const GeneratePayoutFormConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(PayoutForm);

export default reduxForm({
  form: 'generatePayoutForm' // a unique identifier for this form
})(GeneratePayoutFormConnected);
