import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import storageService from '../services/storageService';
import routePath from '../constants/routePath';

class RouteWithSubRoutes extends React.Component {
  renderRoute = routeProps => {
    const { path, isPublic, routes, redirectToPackage } = this.props;

    if (isPublic) {
      if (path === routePath.login.path && storageService.getToken()) {
        return <Redirect to={{ pathname: routePath.dashboard.path }} />;
      }
      return <this.props.component {...routeProps} routes={routes} />;
    }
    if (storageService.getToken()) {
      if (redirectToPackage && path !== routePath.package.path) {
        return <Redirect to={{ pathname: routePath.package.path }} />;
      }
      return <this.props.component {...routeProps} routes={routes} />;
    }
    return <Redirect to={{ pathname: routePath.login.path }} />;
  };

  render() {
    const { path, exact } = this.props;
    return <Route path={path} exact={exact} render={this.renderRoute} />;
  }
}

export default RouteWithSubRoutes;
