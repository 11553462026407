import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import routePath from '../../constants/routePath';
import { auth as authActions } from '../../actions';
import InnerLoader from '../../components/InnerLoader';
import LayoutRegister from '../../components/LayoutRegister';

class ThankYou extends React.Component {
  timeout = null;

  componentDidMount() {
    this.reloadStatus();

    this.timeout = setInterval(() => {
      this.reloadStatus();
    }, 10000);
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  reloadStatus = () => {
    const { getSubscriptionStatus, subscriptionStatus } = this.props;
    if (!subscriptionStatus || subscriptionStatus.status !== 'COMPLETED') {
      getSubscriptionStatus();
    } else if (this.timeout) {
      clearInterval(this.timeout);
    }
  };

  render() {
    const { isLoading, subscriptionStatus } = this.props;
    return (
      <LayoutRegister>
        <div className="card-body ph-5 pt-4 pb-5">
          {isEmpty(subscriptionStatus) && isLoading ? (
            <div className="text-center">
              <InnerLoader type="table" />
            </div>
          ) : (
            <div className="text-center">
              {isEmpty(subscriptionStatus) ||
              (subscriptionStatus && subscriptionStatus.status === 'FAILED') ? (
                <>
                  <h2 className="mt-0">
                    <i className="mdi mdi-close-circle text-danger font-60" />
                  </h2>
                  <h3 className="mt-0">Failed!</h3>
                  <p className="w-75 mb-3 mx-auto">
                    Your payment was not success.
                  </p>
                  <Link to={routePath.package.path} className="btn btn-primary">
                    Go to Subscription
                  </Link>
                </>
              ) : (
                <>
                  {subscriptionStatus.status === 'COMPLETED' ? (
                    <>
                      <h2 className="mt-0">
                        <i className="mdi mdi-check-circle text-success font-60" />
                      </h2>
                      <h3 className="mt-0">Welcome!</h3>
                      <p className="w-75 mb-3 mx-auto">
                        You have partnered with a company with a passion to help
                        people change their lives.
                      </p>
                      <Link
                        to={routePath.dashboard.path}
                        className="btn btn-primary"
                      >
                        Go to Dashboard
                      </Link>
                    </>
                  ) : (
                    <>
                      <h2 className="mt-0">
                        <i className="mdi mdi-progress-check text-success font-60" />
                      </h2>
                      <h3 className="mt-0">Processing!</h3>
                      <p className="w-75 mb-3 mx-auto">
                        Your payment is being processed.
                      </p>
                      {/* <button
                        type="button"
                        onClick={this.reloadStatus}
                        className="btn btn-dark"
                      >
                        Check Payment Status
                      </button> */}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </LayoutRegister>
    );
  }
}

const mapStateToProps = state => ({
  subscriptionStatus: state.auth.subscriptionStatus,
  isLoading: state.auth.subscriptionStatusProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubscriptionStatus: authActions.getSubscriptionStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankYou);
