import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { checkout as checkoutTypes, app as appActionTypes } from '../actions';
import { createForm } from '../utills/ajax';
import storageService from '../services/storageService';

function* checkout(actions) {
  try {
    let successMsg = 'Redirecting to QuickPay';
    if (actions.data.payment_type === 'zotto_pay') {
      successMsg = 'Redirecting to Zottopay';
    }
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/checkout`,
      method: 'POST',
      data: actions.data,
      successMessage: successMsg
    });
    if (responseJson) {
      // Remove COUPON DATA from storage
      storageService.setAppliedCoupon('');
      if (actions.data.payment_type === 'zotto_pay') {
        createForm(responseJson);
      } else {
        window.location = responseJson.url;
      }
    }
  } catch (error) {
    yield put({ type: checkoutTypes.CHECKOUT_ERROR, data: error });
  }
}

function* watchCheckout() {
  yield takeLatest(checkoutTypes.CHECKOUT_REQUEST, checkout);
}

export default [fork(watchCheckout)];
