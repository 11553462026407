import React, { useState, useEffect, useCallback } from 'react';
import { debounce, map } from 'lodash';
import { Modal } from 'react-bootstrap';
import btnLoader from '../../assets/images/btn-loader.gif';

/**
 * statesInModal = {
 *  name: String
 *  id: Integer
 * }
 */

const CategoryModalRegions = ({
  showModal,
  statesInModal,
  handleCloseModal,
  inProgress,
  handleCheckedStates,
  selectedStateIds,
  countryLabel
}) => {
  const [allStates, setAllStates] = useState(true);
  const [search, setSearch] = useState('');
  const [searchMode, setSearchMode] = useState(statesInModal);
  const [selectedState, setSelectedState] = useState([]);

  useEffect(() => {
    setSelectedState([...selectedStateIds]);
  }, [selectedStateIds]);

  // checked single State
  const handleOnSelect = e => {
    const index = selectedState.indexOf(parseInt(e.target.value, 10));
    if (index > -1) {
      selectedState.splice(index, 1);
      setSelectedState([...selectedState]);

      // uncheck 'all states checkbox'
      setAllStates(false);
    } else {
      setSelectedState([...selectedState, parseInt(e.target.value, 10)]);
    }
  };

  // checked/unchecked  all states
  const handleOnSelectAll = e => {
    setAllStates(prev => !prev);
    if (e.target.checked) {
      setSelectedState(statesInModal.map(item => item.id));
    } else {
      setSelectedState([]);
    }
  };

  const applySearch = useCallback(
    debounce(input => {
      if (input) {
        setSearchMode(
          statesInModal.filter(item =>
            item.name.toLowerCase().startsWith(input.toLowerCase())
          )
        );
      } else {
        setSearchMode([...statesInModal]);
      }
    }, 750),
    [statesInModal]
  );

  // search coutries
  const onSearchChange = e => {
    setSearch(e.target.value);
    applySearch(e.target.value);
  };

  const handleSubmit = () => {
    handleCheckedStates(selectedState);
    handleCloseModal();
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="w-100 h-100 my-0 mx-auto p-0"
        className="mx-30"
        centered
      >
        <Modal.Header closeButton>
          <h4 className="d-inline m-0">{countryLabel} : Choose States</h4>
        </Modal.Header>

        <Modal.Body className="my-auto vh-60">
          <div className="d-flex justify-content-center flex-wrap h-100">
            <div
              className="app-search input-group input-group-sm search-bar mb-1 mt-1 rounded"
              id="custom-search"
            >
              <span className="mdi mdi-magnify ml-2" />
              <input
                type="text"
                className="form-control"
                placeholder="Search by Name ..."
                onChange={onSearchChange}
                value={search}
              />
            </div>

            <div
              style={{ height: '60vh' }}
              className="mt-3 overflow-auto category-modal-states w-100"
            >
              <div className="h-100">
                {search.length === 0 && (
                  <div
                    className="pl-2 mr-2 border-bottom d-flex align-items-center cursor-pointer"
                    aria-hidden="true"
                  >
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        id="all_state"
                        checked={allStates}
                        onChange={handleOnSelectAll}
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="all_state"
                        className="custom-control-label"
                      />
                    </div>
                    <label
                      htmlFor="all_state"
                      className="h4 h4-state ml-2 cursor-pointer"
                    >
                      All States
                    </label>
                  </div>
                )}
                <div className="pl-2 mr-2">
                  {searchMode.map(state => (
                    <div
                      key={`state_item_${state.id}`}
                      className="d-flex align-items-center mt-1"
                    >
                      <div className="custom-control custom-checkbox cursor-pointer">
                        <input
                          type="checkbox"
                          checked={selectedState.includes(state.id)}
                          id={`state_item_${state.id}`}
                          onChange={handleOnSelect}
                          value={state.id}
                          className="custom-control-input cursor-pointer"
                        />
                        <label
                          htmlFor={`state_item_${state.id}`}
                          className="custom-control-label"
                        />
                      </div>
                      <label
                        htmlFor={`state_item_${state.id}`}
                        className="h5 h5-state ml-2 cursor-pointer"
                      >
                        {state.name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3 text-right">
            <button
              type="button"
              className="btn btn-light mr-2"
              onClick={handleCloseModal}
            >
              Close
            </button>
            <button
              type="submit"
              disabled={inProgress}
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CategoryModalRegions;
