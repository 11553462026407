import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { startCase, find } from 'lodash';
import LocationForm from './LocationForm';
import {
  location as locationActions,
  currency as currencyActions,
  singleLocation as singleLocationActions
} from '../../actions';
import LinkBack from './LinkBack';

const getLocation = search => (search ? search.split('=')[1] : '');
class LocationAdd extends React.Component {
  constructor(props) {
    super(props);
    this.locationType = props.match.params && props.match.params.type;

    this.locationId = getLocation(props.location && props.location.search);
    if (this.locationType === 'country') this.path = `/location/country`;
    if (this.locationType === 'state')
      this.path = `/location/state/${this.locationId}`;
    if (this.locationType === 'city')
      this.path = `/location/city/${this.locationId}`;
  }

  componentDidMount() {
    const { getLocationById, getCurrency } = this.props;
    if (this.locationType === 'country') getCurrency();
    if (this.locationType === 'city')
      getLocationById({
        type: 'state',
        id: this.locationId
      });
  }

  handleSubmit = values => {
    const {
      createLocation,
      match,
      currency: { currencies }
    } = this.props;

    const curr = find(currencies, { id: values.currency_id });
    createLocation({
      id: parseInt(match.params.id, 10),
      type: this.locationType,
      data: {
        ...values,
        status: values.status ? 1 : 0,
        currency: curr && curr.currency_abbreviation,
        currency_id: curr && curr.id
      },
      redirect: true
    });
  };

  render() {
    const { createProgress, currency, singleLocation } = this.props;
    if (this.locationType === 'country')
      this.locationDetail = {
        status: 1,
        formType: 'add'
      };
    else if (this.locationType === 'state')
      this.locationDetail = {
        country_id: Number(this.locationId),
        status: 1,
        formType: 'add'
      };
    else
      this.locationDetail = {
        country_id: singleLocation.data.country_id,
        state_id: Number(this.locationId),
        status: 1,
        formType: 'add'
      };

    if (singleLocation.isLoading) return null;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <LinkBack
                linkItems={[
                  { link: '/location/country', title: 'Location' },
                  {
                    title: 'Add'
                  }
                ]}
              />
              <h4 className="page-title">Add {startCase(this.locationType)}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <LocationForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                  locationType={this.locationType}
                  currency={currency}
                  path={this.path}
                  initialValues={this.locationDetail}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.location.createProgress,
  currency: state.currency,
  singleLocation: state.singleLocation
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      createLocation: locationActions.createLocation,
      getCurrency: currencyActions.getCurrency,
      getLocationById: singleLocationActions.getSingleLocation
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationAdd);
