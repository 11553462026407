import React, { useRef, useState } from 'react';
import { compose, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { app as appActions, cart as cartActions } from '../../actions';
import { OverlayLoader } from '../../components/InnerLoader';
import { WarningAlert } from '../../components/ConfirmAlert';
import StorageService from '../../services/storageService';

function WithShippingPrompt(Component) {
  return props => {
    const {
      cartDetails,
      dispatch,
      reviseCart,
      loadingreviseCart,
      setShopCountry,
      setShopState,
      ...rest
    } = props;
    const { address, onSelect } = rest;
    const [showPrompt, setShowPrompt] = useState(false);
    const addressValue = useRef('');

    const resetShopCountryState = () => {
      if (address.country) {
        setShopCountry(address.country.iso2);
        StorageService.setShopCountry(address.country.iso2);
      }
      if (address.state) {
        setShopState(address.state.id);
        StorageService.setShopState(address.state.id);
      }
    };
    const callback = () => {
      // Set shop country & state same as shipping
      resetShopCountryState();

      onSelect(addressValue.current);
      setShowPrompt(false);
    };
    const handleConfirm = () => {
      // Revise cart
      reviseCart(
        {
          cart_id: cartDetails.id,
          country: address.country.iso2,
          state: address.state.id
        },
        callback
      );
    };
    const handleCalcel = () => {
      setShowPrompt(false);
    };
    const matchShippingAddressWithCartCountryState = addressId => {
      addressValue.current = addressId;
      if (cartDetails && cartDetails.country !== address.country.iso2) {
        // Display prmpt
        setShowPrompt(true);
      }
      // else if (
      //   cartDetails &&
      //   cartDetails.state &&
      //   cartDetails.state !== address.state.id
      // ) {
      //   // Silently revise the cart is state is different
      //   handleConfirm();
      // }
      else {
        handleConfirm();
      }
      return false;
    };
    return (
      <>
        <Component
          {...rest}
          beforeSelect={matchShippingAddressWithCartCountryState}
        />

        <WarningAlert
          show={showPrompt}
          onConfirm={handleConfirm}
          onCancel={handleCalcel}
          inProgress={loadingreviseCart}
          title={loadingreviseCart ? '' : 'Are you sure?'}
        >
          {loadingreviseCart
            ? 'Reloading your cart...'
            : 'You are adding product for different country/state, you have added before. Your existing cart items might not be available or price may vary. Confirm to continue'}
        </WarningAlert>
        {loadingreviseCart ? (
          <OverlayLoader className="preloader-inner fixed" type="spinner" />
        ) : null}
      </>
    );
  };
}

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  loadingreviseCart: state.cart.loadingreviseCart
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      reviseCart: cartActions.reviseCart,
      setShopCountry: appActions.setShopCountry,
      setShopState: appActions.setShopState
    },
    dispatch
  );

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  WithShippingPrompt
);
