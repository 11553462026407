import { findIndex } from 'lodash';
import { order as orderActionTypes } from '../actions';
import initialState from '../constants/initialState';
import { insertItem, updateObjectInArray } from '../utills/custom';

function order(state = initialState.order, action) {
  switch (action.type) {
    case orderActionTypes.ORDER_ALL_LIST_REQUEST:
      return {
        ...state,
        allList: [],
        isLoading: true
      };
    case orderActionTypes.ORDER_ALL_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allList: action.data
        };
      }
      return state;
    case orderActionTypes.ORDER_ALL_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case orderActionTypes.ORDER_SUMMARY_REQUEST:
      return {
        ...state,
        summary: {},
        isSummaryLoading: true
      };
    case orderActionTypes.ORDER_SUMMARY_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isSummaryLoading: false,
          summary: action.data
        };
      }
      return state;
    case orderActionTypes.ORDER_SUMMARY_ERROR:
      return {
        ...state,
        isSummaryLoading: false
      };
    case orderActionTypes.ORDER_REQUEST:
    case orderActionTypes.ORDER_TNX_REQUEST:
      return {
        ...state,
        isLoadingSingle: true
      };
    case orderActionTypes.ORDER_SUCCESS:
    case orderActionTypes.SUBSCRIPTION_ORDER_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        let newList = [];
        if (index > -1) {
          newList = updateObjectInArray(state.allList.data, {
            index: index > -1 ? index : 0,
            item: action.data
          });
        } else {
          newList = insertItem(state.allList.data, {
            index: 0,
            item: action.data
          });
        }
        return {
          ...state,
          allList: { ...state.allList, data: newList },
          isLoadingSingle: false
        };
      }
      return state;
    case orderActionTypes.ORDER_TNX_SUCCESS:
      if (action.data) {
        return {
          ...state,
          tnxDetails: action.data,
          isLoadingSingle: false
        };
      }
      return state;
    case orderActionTypes.ORDER_ERROR:
    case orderActionTypes.SUBSCRIPTION_ORDER_ERROR:
      return {
        ...state,
        isLoadingSingle: false
      };
    default:
      return state;
  }
}

export default order;
