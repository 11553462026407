import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { media as mediaActionTypes, app as appActionTypes } from '../actions';
import routePath from '../constants/routePath';

function* getAllMedia() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/config-image`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: mediaActionTypes.MEDIA_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getMedia(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/config-image/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: mediaActionTypes.MEDIA_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createMedia(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/config-image`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Media was added successfully'
    });
    if (responseJson) {
      yield put({
        type: mediaActionTypes.MEDIA_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.media.path
      });
    }
  } catch (error) {
    yield put({ type: mediaActionTypes.MEDIA_CREATE_ERROR, data: error });
  }
}

function* updateMedia(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/config-image/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Media updated successfully'
    });
    if (responseJson) {
      yield put({
        type: mediaActionTypes.MEDIA_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.media.path
        });
      }
    }
  } catch (error) {
    yield put({ type: mediaActionTypes.MEDIA_UPDATE_ERROR, data: error });
  }
}

function* updateMediaSetting(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/config-image/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Media settings updated successfully'
    });
    if (responseJson) {
      yield put({
        type: mediaActionTypes.MEDIA_SETTING_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.media.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: mediaActionTypes.MEDIA_SETTING_UPDATE_ERROR,
      data: error
    });
  }
}

function* getAllFiles() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/tool/all-files`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: mediaActionTypes.ALL_FILE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchMediaListAll() {
  yield takeLatest(mediaActionTypes.MEDIA_ALL_LIST_REQUEST, getAllMedia);
}

function* watchGetMedia() {
  yield takeLatest(mediaActionTypes.MEDIA_REQUEST, getMedia);
}

function* watchMediaCreate() {
  yield takeLatest(mediaActionTypes.MEDIA_CREATE_REQUEST, createMedia);
}

function* watchMediaSettingUpdate() {
  yield takeLatest(
    mediaActionTypes.MEDIA_SETTING_UPDATE_REQUEST,
    updateMediaSetting
  );
}

function* watchMediaUpdate() {
  yield takeLatest(mediaActionTypes.MEDIA_UPDATE_REQUEST, updateMedia);
}

function* watchFileListAll() {
  yield takeLatest(mediaActionTypes.ALL_FILE_REQUEST, getAllFiles);
}

export default [
  fork(watchMediaListAll),
  fork(watchMediaCreate),
  fork(watchMediaUpdate),
  fork(watchMediaSettingUpdate),
  fork(watchGetMedia),
  fork(watchFileListAll)
];
