class StorageService {
  constructor() {
    this.storage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
    this.token_key = 'token';
    this.refresh_token_key = 'refresh_token';
    this.add_to_cart = 'add_to_cart';
    this.shop_country = 'shop_country';
    this.shop_state = 'shop_state';
    this.order_filter = 'order_filter';
    this.applied_coupon = 'applied_coupon';
  }

  getToken() {
    return this.storage.getItem(this.token_key) || false;
  }

  setToken(token) {
    try {
      this.storage.setItem(this.token_key, token);
    } catch (error) {
      window.alert(
        'Your web browser does not support storing settings locally. The most common cause of this is using "Private Browsing Mode". Please open in normal mode.'
      );
    }
  }

  getRefreshToken() {
    return this.storage.getItem(this.refresh_token_key) || false;
  }

  setRefreshToken(token) {
    this.storage.setItem(this.refresh_token_key, token);
  }

  setAddToCart(id) {
    this.storage.setItem(this.add_to_cart, id);
  }

  getAddToCart() {
    return this.storage.getItem(this.add_to_cart) || false;
  }

  unsetAddToCart() {
    this.storage.removeItem(this.add_to_cart);
  }

  getShopCountry() {
    return this.sessionStorage.getItem(this.shop_country) || null;
  }

  setShopCountry(country) {
    this.sessionStorage.setItem(this.shop_country, country);
  }

  getShopState() {
    return this.sessionStorage.getItem(this.shop_state) || null;
  }

  setShopState(state) {
    this.sessionStorage.setItem(this.shop_state, state);
  }

  getOrderFilter() {
    const val = this.sessionStorage.getItem(this.order_filter);
    return val ? JSON.parse(val) : null;
  }

  setOrderFilter(value) {
    this.sessionStorage.setItem(this.order_filter, JSON.stringify(value));
  }

  getAppliedCoupon() {
    return this.sessionStorage.getItem(this.applied_coupon);
  }

  setAppliedCoupon(value) {
    this.sessionStorage.setItem(this.applied_coupon, value);
  }

  clearStorage() {
    this.storage.clear();
    this.sessionStorage.clear();
  }
}

export default new StorageService();
