import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { Overlay, Tooltip } from 'react-bootstrap';
import { required } from '../../utills/validator';
import { inputField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';
import ShowHidePasswordField from '../../components/ShowHidePasswordField';

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordStrengthClass: '',
      passwordStrengthPercent: 0,
      tooltipOpen: false,
      tooltipTarget: React.createRef()
    };
    this.strengthPassed = [
      <li className="text-left">
        <span>Use at least one uppercase character</span>
      </li>,
      <li className="text-left">
        <span>Use at least one lowercase character</span>
      </li>,
      <li className="text-left">
        <span>Use at least one digit</span>
      </li>,
      <li className="text-left">
        <span>Use at least one special character ($@$!%*#?&)</span>
      </li>,
      <li className="text-left">
        <span>Must be at least 8 character long</span>
      </li>
    ];
  }

  toggleTooltip = () => {
    this.setState(a => ({
      tooltipOpen: !a.tooltipOpen
    }));
  };

  checkPasswordStrength = val => {
    const matchedCase = [
      {
        pattern: '[A-Z]', // Uppercase Alpabates
        text: 'Use at least one uppercase character'
      },
      {
        pattern: '[a-z]', // Lowercase Alphabates
        text: 'Use at least one lowercase character'
      },
      {
        pattern: '[0-9]', // Numbers
        text: 'Use at least one digit'
      },
      {
        pattern: '[$@$!%*#?&]', // Special Charector
        text: 'Use at least one special character ($@$!%*#?&)'
      },
      {
        pattern: '^(?=.{8,})', // Length check
        text: 'Must be at least 8 character long'
      }
    ];
    const passed = [];
    let ctr = 0;
    matchedCase.forEach((item, key) => {
      if (new RegExp(item.pattern).test(val)) {
        ctr += 1;
        passed.push(
          <li key={key} className="text-left">
            <del>{item.text}</del>
          </li>
        );
      } else {
        passed.push(
          <li key={key} className="text-left">
            <span>{item.text}</span>
          </li>
        );
      }
    });
    this.strengthPassed = passed;
    switch (ctr) {
      case 5:
        this.setState({
          passwordStrengthClass: 'bg-success',
          passwordStrengthPercent: 100,
          tooltipOpen: false
        });
        break;
      case 4:
        this.setState({
          passwordStrengthClass: 'bg-warning',
          passwordStrengthPercent: 80,
          tooltipOpen: true
        });
        break;
      case 3:
        this.setState({
          passwordStrengthClass: 'bg-warning',
          passwordStrengthPercent: 50,
          tooltipOpen: true
        });
        break;
      case 2:
        this.setState({
          passwordStrengthClass: 'bg-info',
          passwordStrengthPercent: 30,
          tooltipOpen: true
        });
        break;
      case 1:
        this.setState({
          passwordStrengthClass: 'bg-info',
          passwordStrengthPercent: 10,
          tooltipOpen: true
        });
        break;
      default:
        this.setState({
          passwordStrengthClass: '',
          passwordStrengthPercent: 0,
          tooltipOpen: true
        });
    }
    if (!val) {
      this.setState({ tooltipOpen: false });
    }
  };

  render() {
    const { handleSubmit, submitLogin, inProgress } = this.props;
    const {
      passwordStrengthClass,
      passwordStrengthPercent,
      tooltipOpen,
      tooltipTarget
    } = this.state;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitLogin)}
      >
        <ShowHidePasswordField
          name="password"
          placeholder="Enter your new password"
          label="Password"
          groupClassName="mb-1"
          feedback={this.checkPasswordStrength}
          validate={[required]}
        />
        <div className="row">
          <div className="col-12 col-md-10">
            <div className="progress" style={{ height: 7 }}>
              <div
                className={`bar progress-bar progress-bar-striped ${passwordStrengthClass}`}
                style={{ width: `${passwordStrengthPercent}%` }}
              />
            </div>
            <div className="d-md-none mt-2 mt-md-0">
              <span className="font-weight-semibold">Password Guide</span>
              <ul className="mt-1 pass-guide-info pl-3">
                {this.strengthPassed}
              </ul>
            </div>
          </div>
          <div className="col-md-2 text-right d-none d-md-inline-block">
            <button
              type="button"
              className="btn btn-icon btn-sm pass-info p-0"
              onClick={() => this.toggleTooltip()}
              id="TooltipExample"
              ref={tooltipTarget}
            >
              <i className="mdi mdi-information-outline" />
            </button>
            <Overlay
              target={tooltipTarget.current}
              show={tooltipOpen}
              placement="right"
            >
              {props => (
                <Tooltip
                  id="overlay-example"
                  className="d-none d-md-block"
                  {...props}
                >
                  <div>
                    <strong>Password Guide</strong>
                    <ul className="mt-1 pass-guide-info pl-3">
                      {this.strengthPassed}
                    </ul>
                  </div>
                </Tooltip>
              )}
            </Overlay>
          </div>
        </div>
        <ShowHidePasswordField
          name="confirm_password"
          placeholder="Confirm password"
          label="Confirm Password"
          validate={[required]}
        />
        <div className="text-center">
          <button
            type="submit"
            disabled={inProgress}
            className="btn btn-primary"
          >
            {inProgress ? <img src={btnLoader} alt="" /> : 'Submit'}
          </button>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'resetPasswordForm' // a unique identifier for this form
})(ResetPasswordForm);
