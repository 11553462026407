import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import _ from 'lodash';
import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import { switchField } from '../../components/Fields';
import {
  setting as settingActions,
  shipping as shippingActions
} from '../../actions';
import routePath from '../../constants/routePath';
import ShippingSettingForm from './ShippingSettingForm';
import InnerLoader from '../../components/InnerLoader';
import StatusSwitch from '../../components/StatusSwitch';

class ShippingConfig extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  columns = [
    {
      dataField: 'title',
      text: 'Shipping Name',
      headerAttrs: { width: '15%' }
    },
    {
      dataField: 'country',
      text: 'Country',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'state',
      text: 'State',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: col => (col ? col.name : '*')
    },
    {
      dataField: 'city',
      text: 'City',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: col => (col ? col.name : '*')
    },
    {
      dataField: 'postcode',
      text: 'Postcode/ZIP',
      headerClasses: 'text-center',
      classes: 'text-center'
    },
    {
      dataField: 'rate_type',
      text: 'Shipping Mode',
      headerClasses: 'text-center',
      classes: 'text-center',
      headerAttrs: { width: '15%' },
      formatter: col => (col === 1 ? 'Flat Rate' : 'Rate by Weight')
    },
    {
      dataField: 'status',
      text: 'Active?',
      headerClasses: 'text-center',
      classes: 'text-center',
      headerAttrs: { width: '10%' },
      formatter: (col, row) => (
        <StatusSwitch
          checked={parseInt(col, 10)}
          onChange={this.updateShippingStatus}
          id={row.id}
          idPrefix="assos_sts_"
        />
      )
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      headerClasses: 'text-center',
      headerAttrs: { width: '10%' },
      classes: 'text-center',
      formatter: (col, row) => (
        <>
          <Link to={`/shipping/${row.id}/edit`}>
            <i className="action-icon mdi mdi-square-edit-outline" />
          </Link>
        </>
      )
    }
  ];

  componentDidMount() {
    const { getSetting, getAllShipping } = this.props;
    getSetting('shipping');
    getAllShipping();
  }

  updateShippingStatus = (id, val) => {
    const { updateShippingStatus } = this.props;
    updateShippingStatus({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getAllShipping, match } = this.props;
    const data = { page, per_page: perPage };
    if (match.params && match.params.id) {
      data.id = match.params.id;
    }
    getAllShipping(data);
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleSubmit = values => {
    const { updateSetting } = this.props;
    const formFields = [
      'calc_shipping_cart',
      'hide_shipping_cost_until_add_enter',
      'default_shipping_location'
    ];
    const fieldValues = [];
    _.forEach(values, (val, field) => {
      if (formFields.indexOf(field) > -1) {
        fieldValues.push({
          key: field,
          value: val,
          options: 'shipping'
        });
      }
    });
    updateSetting({ settings: fieldValues });
  };

  render() {
    const {
      shipping,
      settings,
      isLoading,
      updateProgress,
      filterValues
    } = this.props;
    const { page, selected } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.shipping.breadcrumb} />
              <h4 className="page-title">{routePath.shipping.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading && <InnerLoader type="table" />}
                {!isLoading && (
                  <ShippingSettingForm
                    submitForm={this.handleSubmit}
                    inProgress={updateProgress}
                    initialValues={settings}
                    enableReinitialize="true"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <Link
                      to={routePath.shippingAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      <span>Add New</span>
                    </Link>
                  </div>
                </div>
                <AdvanceTable
                  keyField="id"
                  data={shipping.data}
                  columns={this.columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={shipping.total}
                  sizePerPage={shipping.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  shipping: state.shipping.allList,
  isLoading: state.shipping.isLoading,
  updateProgress: state.setting.updateProgress,
  settings: state.setting.data,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllShipping: shippingActions.getAllShipping,
      updateSetting: settingActions.updateSetting,
      getSetting: settingActions.getSetting,
      updateShippingStatus: shippingActions.updateShippingStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingConfig);
