/* global appConfig */
import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import routePath from '../../constants/routePath';
import { required, number } from '../../utills/validator';
import { inputField, imageField } from '../../components/Fields';
import { media as mediaActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class MediaSettingForm extends React.PureComponent {
  render() {
    const { handleSubmit, submitForm, inProgress, reset } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <h4 className="header-title mb-3 text-primary">Logo &amp; Favicon</h4>
        <div className="row">
          <div className="col-xs-12 col-md-3">
            <Field
              name="dashboard_logo"
              component={imageField}
              id="dashLogo"
              className="form-control"
              preview={{ url: appConfig.logo, width: '70%' }}
              label="Dashboard"
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <Field
              name="login_logo"
              component={imageField}
              id="loginLogo"
              preview={{ url: appConfig.logo, width: '70%' }}
              className="form-control"
              label="Login"
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <Field
              name="invoice_logo"
              component={imageField}
              id="invoiceLogo"
              preview={{ url: appConfig.logo, width: '70%' }}
              className="form-control"
              label="Invoice"
            />
          </div>
          <div className="col-xs-12 col-md-3">
            <Field
              name="favicon"
              component={imageField}
              id="fav"
              preview={{ url: appConfig.logo, width: '70%' }}
              className="form-control"
              label="Favicon"
            />
          </div>
          <div className="col-xs-12 col-md-12 mt-2">
            <button
              type="button"
              onClick={() => reset()}
              className="btn btn-light mr-2"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={inProgress}
              className="btn btn-primary"
            >
              {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.media.updateProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      // getAllMedias: mediaActions.getAllMedias
    },
    dispatch
  );

const MediaFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaSettingForm);

export default reduxForm({
  form: 'MediaSettingForm' // a unique identifier for this form
})(MediaFormWithRedux);
