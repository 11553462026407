import { storeAddress as storeAddressActionTypes } from '../actions';
import initialState from '../constants/initialState';

function storeAddress(state = initialState.storeAddress, action) {
  switch (action.type) {
    case storeAddressActionTypes.STORE_ADDRESS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case storeAddressActionTypes.STORE_ADDRESS_LIST_SUCCESS:
      if (action.data) {
        const countryList = [];
        action.data.country_settings.data.forEach(item => {
          countryList.push({
            id: item.id,
            label: item.store_country,
            value: item.store_country_iso2
          });
        });

        return {
          ...state,
          isLoading: false,
          data: {
            ...state.data,
            ...action.data
          },
          countries: countryList
        };
      }
      return state;
    case storeAddressActionTypes.STORE_ADDRESS_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case storeAddressActionTypes.STORE_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case storeAddressActionTypes.STORE_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          ...action.data
        }
      };
    case storeAddressActionTypes.STORE_ADDRESS_ERROR:
      return {
        ...state,
        isLoading: false
      };

    case storeAddressActionTypes.STORE_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case storeAddressActionTypes.STORE_CREATE_SUCCESS:
    case storeAddressActionTypes.STORE_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case storeAddressActionTypes.STORE_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case storeAddressActionTypes.STORE_UPDATE_SUCCESS:
    case storeAddressActionTypes.STORE_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case storeAddressActionTypes.STORE_PICKUP_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case storeAddressActionTypes.STORE_PICKUP_UPDATE_SUCCESS:
    case storeAddressActionTypes.STORE_PICKUP_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    default:
      return state;
  }
}

export default storeAddress;
