/* global appConfig */
import React, { useEffect } from 'react';
import Footer from './Footer';

export default function LayoutRegister({ children, step }) {
  useEffect(() => {
    document.body.classList.add('authentication-bg');
  }, []);
  return (
    <>
      <div className="account-pages">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className={`col-sm-12 col-md-12 ${
                step === 3 ? ' col-lg-12 col-xl-12' : 'col-lg-8 col-xl-7'
              }`}
            >
              <div className="card">
                <div className="card-header text-center border-bottom-dash">
                  <img
                    className="logo-img"
                    src={appConfig.logo}
                    alt="{appConfig.logoALT}"
                    height="54"
                  />
                </div>
                {step && (
                  <>
                    <div className="text-center w-75 m-auto">
                      <h4 className="text-dark-50 text-center mt-3 font-weight-bold mb-2">
                        Register as an Associate
                      </h4>
                    </div>
                    <div className="package_nav mt-3">
                      <ul className="nav nav-pills bg-nav-pills nav-justified form-wizard-header">
                        <li className="nav-item">
                          <span
                            className={`nav-link rounded-0 pt-2 pb-2 h-100 ${
                              step === 1 ? 'active' : ''
                            }`}
                          >
                            <i className="far fa-handshake mr-1" />
                            <span className="d-none d-md-inline">Member</span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link rounded-0 pt-2 pb-2 h-100 ${
                              step === 2 ? 'active' : ''
                            }`}
                          >
                            <i className="far fa-user mr-1" />
                            <span className="d-none d-md-inline">
                              Personal Info
                            </span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link rounded-0 pt-2 pb-2 h-100 ${
                              step === 3 ? 'active' : ''
                            }`}
                          >
                            <i className="fas fa-cube mr-1" />
                            <span className="d-none d-md-inline">Package</span>
                          </span>
                        </li>
                        <li className="nav-item">
                          <span
                            className={`nav-link rounded-0 pt-2 pb-2 h-100 ${
                              step === 4 ? 'active' : ''
                            }`}
                          >
                            <i className="far fa-check-circle mr-1" />
                            <span className="d-none d-md-inline">Payment</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </>
                )}
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
