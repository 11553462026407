import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import CouponForm from './CouponForm';
import { coupons as couponActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class CouponAdd extends React.Component {
  handleSubmit = values => {
    const { createCoupon } = this.props;
    const newValues = { ...values };
    newValues.code = newValues.code.map(item => item.code);

    newValues.start_at = moment(newValues.start_at, 'DD/MM/YYYY HH:mm').format(
      'YYYY-MM-DD HH:mm'
    );
    newValues.end_at = moment(newValues.end_at, 'DD/MM/YYYY HH:mm').format(
      'YYYY-MM-DD HH:mm'
    );
    createCoupon(newValues);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.couponAdd.breadcrumb} />
              <h4 className="page-title">{routePath.couponAdd.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card coupon_add">
              <div className="card-body">
                <CouponForm
                  submitForm={this.handleSubmit}
                  inProgress={createProgress}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.coupons.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createCoupon: couponActions.createCoupon }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponAdd);
