import React from 'react';
import { change, Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { dateField, selectField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';
import { permission as permissionActions } from '../../actions';
import { readableTransform } from '../../utills/custom';

class LoginHistoryForm extends React.PureComponent {
  componentDidMount() {
    const { getRoles } = this.props;
    getRoles();
  }

  roleOptions = roles => {
    const options = [];
    roles.map(role =>
      options.push({ label: readableTransform(role.name), value: role.id })
    );
    return options;
  };

  render() {
    const { handleSubmit, submitForm, inProgress, roles } = this.props;
    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-sm-4">
            {roles && roles.data && (
              <Field
                name="role_id"
                component={selectField}
                options={this.roleOptions(roles)}
                placeholder="Choose role..."
              />
            )}
          </div>
          <div className="col-sm-4">
            <Field
              name="date_from"
              component={dateField}
              type="text"
              selectsStart
              className="form-control"
              placeholder="From date"
              inputGroupClass="input-group input-group-sm"
            />
          </div>
          <div className="col-sm-4">
            <Field
              name="date_to"
              component={dateField}
              type="text"
              selectsEnd
              className="form-control"
              placeholder="To date"
              inputGroupClass="input-group input-group-sm"
            />
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.permission.roles
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRoles: permissionActions.getRoles
    },
    dispatch
  );

const LoginHistoryReduxForm = reduxForm({
  form: 'loginHistoryForm' // a unique identifier for this form
})(LoginHistoryForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginHistoryReduxForm);
