import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { customer as customerActionTypes } from '../actions';

function* getAssociatesCustomer(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/associate/customer`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: customerActionTypes.ASSOCIATE_CUSTOMER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAllCustomer(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/all-customer`,
      method: 'GET',
      params: actions.params
    });
    if (responseJson) {
      yield put({
        type: customerActionTypes.ALL_CUSTOMER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCustomerByAssoc(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/all-customer/${actions.id}`,
      method: 'GET',
      params: actions.params
    });
    if (responseJson) {
      yield put({
        type: customerActionTypes.CUSTOMER_BY_ASSOC_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetAssociatesCustomer() {
  yield takeLatest(
    customerActionTypes.ASSOCIATE_CUSTOMER_REQUEST,
    getAssociatesCustomer
  );
}

function* watchGetCustomerByAssoc() {
  yield takeLatest(
    customerActionTypes.CUSTOMER_BY_ASSOC_REQUEST,
    getCustomerByAssoc
  );
}

function* watchGetAllCustomer() {
  yield takeLatest(customerActionTypes.ALL_CUSTOMER_REQUEST, getAllCustomer);
}

export default [
  fork(watchGetAssociatesCustomer),
  fork(watchGetAllCustomer),
  fork(watchGetCustomerByAssoc)
];
