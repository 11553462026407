import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import InnerLoader from '../../components/InnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import { permission as permissionActions } from '../../actions';
import routePath from '../../constants/routePath';
import RolePermissionForm from './RolePermissionForm';

class PermissionByRole extends React.Component {
  componentDidMount() {
    const { getPermissionByRole, match } = this.props;
    if (match.params && match.params.id) {
      getPermissionByRole({ id: match.params.id });
    }
  }

  handleSubmit = values => {
    const { updateRolePermission } = this.props;
    const { match } = this.props;
    updateRolePermission({ id: match.params.id, data: { permission: values } });
  };

  render() {
    const { permissions, isLoading, isProcessing } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="page-header">
              <h2 className="pageheader-title">
                {routePath.rolePermissionList.title}
              </h2>
              <div className="page-breadcrumb">
                <nav aria-label="breadcrumb">
                  <BreadCrumb list={routePath.rolePermissionList.breadcrumb} />
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                {isLoading ? (
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">Module</th>
                        <th scope="col">Read</th>
                        <th scope="col">Write</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan="3" className="text-center">
                          <InnerLoader type="table" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <RolePermissionForm
                    submitForm={this.handleSubmit}
                    inProgress={isProcessing}
                    permissions={permissions}
                    initialValues={permissions.permission}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  permissions: state.permission.rolePermission,
  isLoading: state.permission.isLoadingPermissions,
  isProcessing: state.permission.isProcessingPermissions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPermissionByRole: permissionActions.getPermissionByRole,
      updateRolePermission: permissionActions.updateRolePermission
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PermissionByRole)
);
