import React, { useRef, useState } from 'react';
import { Overlay, Tooltip as BootstrapTooltip, Button } from 'react-bootstrap';

const Tooltip = ({ position, data }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <i
        ref={target}
        className="mdi mdi-help-circle text-primary font-12"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
      <Overlay target={target.current} show={show} placement={position}>
        {props => <BootstrapTooltip {...props}>{data}</BootstrapTooltip>}
      </Overlay>
    </>
  );
};

export default Tooltip;
