import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, find, findIndex } from 'lodash';
import TaxForm from './TaxForm';
import { tax as taxActions, app as appActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class TaxEdit extends React.Component {
  componentDidMount() {
    const { tax, match, getTaxById } = this.props;
    const taxList = get(tax, 'data', []);
    if (
      match.params &&
      match.params.id &&
      parseInt(match.params.id, 10) &&
      findIndex(taxList, { id: parseInt(match.params.id, 10) }) < 0
    ) {
      getTaxById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateTax, match } = this.props;
    updateTax({
      id: parseInt(match.params.id, 10),
      data: values,
      redirect: true
    });
  };

  render() {
    const { updateProgress, tax, match } = this.props;
    const taxList = get(tax, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      const dataObj = find(taxList, { id: parseInt(match.params.id, 10) });
      if (dataObj) {
        data = {
          tax_rate_country: dataObj.tax_rate_country,
          tax_rate_state:
            dataObj.tax_rate_state === '*'
              ? dataObj.tax_rate_state
              : parseInt(dataObj.tax_rate_state, 10),
          tax_rate: dataObj.tax_rate,
          tax_rate_name: dataObj.tax_rate_name,
          tax_rate_shipping: dataObj.tax_rate_shipping,
          tax_rate_postcode: dataObj.tax_rate_postcode,
          tax_rate_city:
            dataObj.tax_rate_city === '*'
              ? dataObj.tax_rate_city
              : parseInt(dataObj.tax_rate_city, 10)
        };
      }
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.taxEdit.breadcrumb} />
              <h4 className="page-title">{routePath.taxEdit.title}</h4>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card tax_add">
                <div className="card-body">
                  <TaxForm
                    submitForm={this.handleSubmit}
                    inProgress={updateProgress}
                    initialValues={data}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.tax.updateProgress,
  tax: state.tax.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateTax: taxActions.updateTax,
      getTaxById: taxActions.getTaxById,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaxEdit);
