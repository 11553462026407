import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

function ShopRestriction({ userInfo }) {
  const { associate_info: associateInfo = {} } = userInfo;
  const { shop_restriction_ends: shopRestrictionEnds } = associateInfo || {};
  if (shopRestrictionEnds) {
    const currentDate = moment();
    const shopDate = moment(shopRestrictionEnds);
    const diff = shopDate.diff(currentDate, 'days');
    if (diff > -1) {
      return (
        <div className="alert alert-warning" role="alert">
          <strong>Alert!</strong> You can start purchasing from{' '}
          {shopDate.format('DD/MM/YYYY')}
        </div>
      );
    }
  }
  return null;
}

const mapStateToProps = state => ({ userInfo: state.auth.user });

export default connect(
  mapStateToProps,
  null
)(ShopRestriction);
