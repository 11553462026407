import { currency as currencyActionTypes } from '../actions';
import initialState from '../constants/initialState';

function currency(state = initialState.currency, action) {
  switch (action.type) {
    case currencyActionTypes.CURRENCY_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case currencyActionTypes.CURRENCY_ERROR:
    case currencyActionTypes.CURRENCY_SUCCESS:
      return {
        ...state,
        ...action.data,
        isLoading: false
      };

    default:
      return state;
  }
}

export default currency;
