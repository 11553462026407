import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { find, get } from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { change, getFormValues } from 'redux-form';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Dropdown from 'react-bootstrap/Dropdown';
import AdvanceTable from '../../components/AdvanceTable';
import OrderFilterForm from './OrderFilterForm';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import SingleDownloadButton from './SingleDownloadButton';
import { app as appActionTypes, order as orderActions } from '../../actions';
import { dateRanges, dateRangesValues } from '../../utills/custom';
import { downloadBlob } from '../../utills/ajax';
import StatBox from '../../components/StatBox';
import TurnoverBox from '../../components/TurnoverBox';
import GrowthBox from '../../components/GrowthBox';
import OrderStatusDropdown from './OrderStatusDropdown';
import OrderFulfillmentDropdown from './OrderFulfillmentDropdown';
import StorageService from '../../services/storageService';

const getPaymentMethods = orderTransaction => {
  const dict = {
    quickpay: 'QP',
    zotto_pay: 'ZOTTO'
  };
  if (orderTransaction && orderTransaction.length) {
    const [a, b] = orderTransaction;
    if (b) return `${dict[a.payment_method]} & ${dict[b.payment_method]}`;
    return dict[a.payment_method];
  }
  return '---';
};

const filterColumn = (isAdmin, columns) => {
  return isAdmin
    ? columns
    : columns.filter(item => item.dataField !== 'payment_method');
};

class Order extends React.Component {
  constructor(props) {
    super(props);
    const startDate = moment()
      .startOf('year')
      .format('DD/MM/YYYY');
    const endDate = moment()
      .endOf('year')
      .format('DD/MM/YYYY');
    this.state = {
      page: 1,
      selected: [],
      startDate,
      endDate,
      dateRangeValue: `${startDate} to ${endDate}`,
      ranges: dateRanges
    };

    // Set default form values
    const fval = StorageService.getOrderFilter();
    this.initialValues = fval || {};

    this.handleFilter = values => {
      const { formValues, getAllOrders } = this.props;
      const filters = { ...formValues, ...values };
      // Keep filter options in session storage
      // StorageService.setOrderFilter(filters);
      getAllOrders(filters);
    };
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleInvoiceDownload = async () => {
    const { selected } = this.state;
    if (selected) {
      await axios({
        url: `${
          process.env.REACT_APP_API_URL
        }/orders/download-merged-invoice?id=${selected.join(',')}`,
        method: 'GET',
        headers: { Accept: 'application/pdf' },
        responseType: 'blob'
      }).then(response => {
        downloadBlob(response, `${moment().format('DDMMYYYY')}-INV-Merged.pdf`);
      });
    }
  };

  handlePackingSlipDownload = async () => {
    const { selected } = this.state;
    if (selected) {
      await axios({
        url: `${
          process.env.REACT_APP_API_URL
        }/orders/download-merged-packingslip?id=${selected.join(',')}`,
        method: 'GET',
        headers: { Accept: 'application/pdf' },
        responseType: 'blob'
      }).then(response => {
        downloadBlob(
          response,
          `${moment().format('DDMMYYYY')}-PACKINGSLIP-Merged.pdf`
        );
      });
    }
  };

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    if (type === 'pagination') this.handleFilter({ page, per_page: perPage });
    this.setState({ page });
  };

  handleDateRange = (event, picker) => {
    const { changeStartDate, changeEndDate, changeDateFilter } = this.props;
    const startDate = moment(picker.startDate).format('DD/MM/YYYY');
    const start = moment(picker.startDate).format('DDMMYYYY');
    const endDate = moment(picker.endDate).format('DD/MM/YYYY');
    const end = moment(picker.endDate).format('DDMMYYYY');
    const filter = dateRangesValues[picker.chosenLabel];
    this.setState({ dateRangeValue: `${startDate} to ${endDate}`, filter });

    if (filter) {
      changeDateFilter(filter);
      changeStartDate('');
      changeEndDate('');
    } else {
      changeDateFilter('custom');
      changeStartDate(start);
      changeEndDate(end);
    }

    this.handleFilter();
  };

  render() {
    const { orders, isAdmin, isLoading, userInfo, filterValues } = this.props;
    const {
      page,
      selected,
      startDate,
      endDate,
      dateRangeValue,
      ranges,
      filter
    } = this.state;

    const [start, end] = dateRangeValue.split('to').map(i => i.trim());
    const orderSummary = find(orders.order_summary, { name: 'Orders' });
    const saleSummary = find(orders.order_summary, { name: 'Sales' });
    const turnoverSummary = find(orders.order_summary, { name: 'Turnover' });
    const growthSummary = find(orders.order_summary, { name: 'Growth' });

    const userRoles = get(userInfo, 'permission.role', []);
    const columns = [
      {
        dataField: 'invoice_number',
        text: 'Inv. no.',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link
                to={`/order/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            ) : (
              <Link
                to={`/subscription/${row.id}/invoice`}
                className="dashed-underline"
              >
                {col}
              </Link>
            )}
          </>
        )
      },
      {
        dataField: 'created_at_tz',
        text: 'Date',
        // headerClasses: 'text-center',
        // classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: (col, row) => {
          let dateObj = [];
          if (userRoles.includes('associate')) {
            dateObj = row.order_local_date_tz.split(' ');
          } else {
            dateObj = col.split(' ');
          }
          return (
            <span className="date">
              {dateObj[0]}
              <br />
              <small>{dateObj[1]}</small>
            </span>
          );
        }
      },
      {
        dataField: 'original_total',
        text: 'Total Price',
        headerClasses: 'text-center',
        classes: 'text-center',
        // headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <span className="font-weight-semibold text-primary">
            {`${row.currency ? row.currency.currency_symbol : ''}${col}`}
          </span>
        )
      },
      {
        dataField: 'order_type',
        text: 'Order Type',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <span className={row.order_type_class_name}>{col}</span>
        )
      },
      {
        dataField: 'id',
        text: 'Order ID'
      },
      {
        dataField: 'method',
        // headerAttrs: { width: '15%' },
        text: 'Transaction ID',
        formatter: (col, row) => (
          <span>
            {row.order_transaction && row.order_transaction[0]
              ? row.order_transaction[0].transaction_id
              : '---'}
          </span>
        )
      },
      {
        dataField: 'payment_method',
        // headerAttrs: { width: '15%' },
        // bookmark
        text: 'Payment Mode',
        formatter: (col, row) => (
          <span>{getPaymentMethods(row.order_transaction)}</span>
        )
      },
      {
        dataField: 'payment_gateway_status',
        text: 'Payment Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => (
          <OrderStatusDropdown
            value={col}
            orderId={row.id}
            reload={this.handleFilter}
            clickable={!userRoles.includes('associate')}
          />
        )
      },
      {
        dataField: 'fulfillment_status',
        text: 'Fulfillment Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => (
          <OrderFulfillmentDropdown
            value={col}
            additionalData={row.shipping_delivery_details}
            orderId={row.id}
            reload={this.handleFilter}
            clickable={
              !userRoles.includes('associate') &&
              row.payment_gateway_status === 'SUCCESS'
            }
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            {row.cart_id ? (
              <Link to={`/order/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" title="View" />
              </Link>
            ) : (
              <Link to={`/subscription/${row.id}/invoice`}>
                <i className="action-icon mdi mdi-eye" title="View" />
              </Link>
            )}
            <SingleDownloadButton
              id={row.id}
              invoice={row.invoice_number}
              title="Download PDF"
              icon="mdi mdi-file-pdf"
              type={row.cart_id ? 'order-invoice' : 'subscription-invoice'}
            />
            {userRoles.includes('super-admin') ? (
              <SingleDownloadButton
                id={row.id}
                invoice={row.invoice_number}
                icon="mdi mdi-file-document-outline"
                title="Packing Slip"
                type={
                  row.cart_id ? 'order-packingslip' : 'subscription-packingslip'
                }
              />
            ) : null}
            {/* <Link to={`/order/${row.order_number}/delete`}><i className="action-icon mdi mdi-trash-can" /></Link> */}
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              {isAdmin ? (
                <>
                  <BreadCrumb list={routePath.order.breadcrumb} />
                  <h4 className="page-title">{routePath.order.title}</h4>
                </>
              ) : (
                <>
                  <BreadCrumb list={routePath.myOrder.breadcrumb} />
                  <h4 className="page-title">My {routePath.myOrder.title}</h4>
                </>
              )}
            </div>
          </div>
          {isAdmin && (
            <>
              <div className="col-12">
                <div className="row mb-2">
                  <div className="col-sm-4">
                    <DateRangePicker
                      startDate={startDate}
                      endDate={endDate}
                      ranges={ranges}
                      onApply={this.handleDateRange}
                    >
                      <div className="form-group has-icon">
                        <i className="mdi mdi-calendar" />
                        <input
                          type="text"
                          value={dateRangeValue}
                          className="form-control input-medium cursor-pointer bg-white"
                          placeholder="Select date range"
                          readOnly
                        />
                      </div>
                    </DateRangePicker>
                  </div>
                  <div className="col-sm-8 text-sm-right">
                    {/* <div className="img_checkbox">
                  <label className="custom-control custom-checkbox">
                    <input className="custom-control-input" type="checkbox" />
                    <span className="custom-control-label">Show Images</span>
                  </label>
                </div>
                <button className="btn btn-sm btn-success btn-refresh ml-2" type="submit">
                  <i className="fas fa-sync" />
                </button> */}
                    <div className="btn-group btn-group-sm ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          {isAdmin && (
                            <Dropdown.Item
                              onClick={() => this.handleInvoiceDownload()}
                            >
                              <i className="mdi mdi-file-pdf" /> Download &amp;
                              Merge PDF
                            </Dropdown.Item>
                          )}
                          {isAdmin && (
                            <Dropdown.Item
                              onClick={() => this.handlePackingSlipDownload()}
                            >
                              <i className="mdi mdi-file-pdf" /> Download &amp;
                              Merge Packing slip
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item href="#/action-2">
                            <i className="mdi mdi-trash-can" /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row row-eq-height">
                  <div className="col-sm-6 col-lg-3 mb-3">
                    <StatBox
                      icon="mdi-cart"
                      title={(orderSummary && orderSummary.name) || 'Orders'}
                      isLoading={isLoading}
                      growth={
                        (orderSummary && orderSummary.growth_percentage) ||
                        '0.00%'
                      }
                      previousValue={
                        (orderSummary && orderSummary.previous_total) || '0'
                      }
                      currentValue={(orderSummary && orderSummary.total) || '0'}
                      filter={filter}
                      start={start}
                      end={end}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-3">
                    <StatBox
                      icon="mdi-cart"
                      title={(saleSummary && saleSummary.name) || 'Orders'}
                      isLoading={isLoading}
                      growth={
                        (saleSummary && saleSummary.growth_percentage) ||
                        '0.00%'
                      }
                      previousValue={
                        (saleSummary && saleSummary.previous_total) || '0'
                      }
                      currentValue={(saleSummary && saleSummary.total) || '0'}
                      filter={filter}
                      start={start}
                      end={end}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-3">
                    <TurnoverBox
                      title={
                        (turnoverSummary && turnoverSummary.name) || 'Turnover'
                      }
                      isLoading={isLoading}
                      prevVat={
                        (turnoverSummary &&
                          turnoverSummary.previous_period
                            .previous_total_items_quantities) ||
                        '0.00'
                      }
                      prevRevenue={
                        (turnoverSummary &&
                          turnoverSummary.previous_period
                            .previous_total_revenue) ||
                        '0.00'
                      }
                      vat={
                        (turnoverSummary &&
                          turnoverSummary.current_period
                            .total_items_quantities) ||
                        '0.00'
                      }
                      revenue={
                        (turnoverSummary &&
                          turnoverSummary.current_period.total_revenue) ||
                        '0.00'
                      }
                      filter={filter}
                      start={start}
                      end={end}
                    />
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-3">
                    <GrowthBox
                      title={(growthSummary && growthSummary.name) || 'Growth'}
                      isLoading={isLoading}
                      quantity={
                        (growthSummary &&
                          growthSummary.items_quantities_sold_growth_percentage) ||
                        '0.00%'
                      }
                      revenue={
                        (growthSummary &&
                          growthSummary.total_revenue_growth_percentage) ||
                        '0.00%'
                      }
                      filter={filter}
                      start={start}
                      end={end}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="row justify-content-end mb-3 mb-md-0">
                  <div className="col-lg-12">
                    <OrderFilterForm
                      initialValues={
                        isAdmin
                          ? {
                              filter_option: 'INVOICE_NUMBER',
                              date_filter: 'yearly',
                              ...this.initialValues
                            }
                          : this.initialValues
                      }
                      submitForm={this.handleFilter}
                      inProgress={isLoading}
                    />
                  </div>
                </div>
                <AdvanceTable
                  keyField="id"
                  data={orders.data}
                  columns={filterColumn(isAdmin, columns)}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={orders.total}
                  sizePerPage={orders.per_page}
                  sizePerPageList={filterValues}
                  hideSizePerPage={false}
                  page={page}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isAdmin: state.auth.isAdmin,
  orders: state.order.allList,
  summary: state.order.summary,
  isLoading: state.order.isLoading,
  isSummaryLoading: state.order.isSummaryLoading,
  userInfo: state.auth.user,
  filterValues: state.app.settings.filter_values,
  formValues: getFormValues('orderFilterForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllOrders: orderActions.getAllOrders,
      navigate: appActionTypes.navigate,
      getSummary: orderActions.getSummary,
      changeStartDate: start => change('orderFilterForm', 'start', start),
      changeEndDate: end => change('orderFilterForm', 'end', end),
      changeDateFilter: option =>
        change('orderFilterForm', 'date_filter', option)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Order);
