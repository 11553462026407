import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { find, findIndex, map } from 'lodash';
import CategoryModalregions from './CategoryModalRegions';
import { stateListAPI } from '../../services/apiService';

function SelectedCountryItem({
  data,
  dispatch,
  selectedCountries,
  allSelectedStates,
  countryList
}) {
  const [showModal, setShowModal] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [countryDetails, setCountryDetails] = useState({});

  useEffect(() => {
    const geStateList = async () => {
      try {
        const res = await stateListAPI(data);
        setStateList(res);

        // Set all states as selected as default
        // Check if state is already present (Condition for edit mode)
        const stateIndex = findIndex(allSelectedStates, { country: data });
        if (stateIndex < 0) {
          const stateIds = map(res, 'id');
          allSelectedStates.push({
            country: data,
            state: stateIds
          });
          dispatch(change('categoryForm', 'states', [...allSelectedStates]));
        }
      } catch (error) {
        console.log(error);
      }
    };

    geStateList();
  }, [data]);

  useEffect(() => {
    // Find the country label
    const details = find(countryList, { value: data });
    if (details) {
      setCountryDetails(details);
    } else {
      setCountryDetails({});
    }
  }, [data, countryList]);

  const deleteCountry = () => {
    // Delete country
    const newCountries = selectedCountries.filter(country => country !== data);
    dispatch(change('categoryForm', 'country_iso2', newCountries));

    // Delete State
    const newStates = allSelectedStates.filter(item => item.country !== data);
    dispatch(change('categoryForm', 'states', newStates));
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCheckedStates = states => {
    // Update selected states list in the form for the specific country
    const index = findIndex(allSelectedStates, { country: data });
    if (index > -1) {
      allSelectedStates[index].state = states;
    } else {
      allSelectedStates.push({
        country: data,
        state: states
      });
    }
    dispatch(change('categoryForm', 'states', allSelectedStates));
  };

  const index = findIndex(allSelectedStates, { country: data });

  return (
    <>
      <div
        className="category-selected-country mb-1 ml-1 p-2 rounded"
        style={{ width: 'fit-content' }}
      >
        <span>
          {countryDetails.label}
          {` (${index > -1 ? allSelectedStates[index].state.length : 0})`}
        </span>
        <button
          type="button"
          className="text-primary ml-2 btn p-0"
          onClick={handleShowModal}
        >
          Edit
          <i className="mdi mdi-square-edit-outline ml-1" />
        </button>
        <button type="button" onClick={deleteCountry} className="btn ml-2 p-0">
          <i className="mdi mdi-close" />
        </button>
      </div>
      {showModal && (
        <CategoryModalregions
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          statesInModal={stateList}
          selectedStateIds={index > -1 ? allSelectedStates[index].state : []}
          inProgress={false}
          handleCheckedStates={handleCheckedStates}
          countryLabel={countryDetails.label}
        />
      )}
    </>
  );
}

const selector = formValueSelector('categoryForm');
const mapStateToProps = state => ({
  selectedCountries: selector(state, 'country_iso2'),
  allSelectedStates: selector(state, 'states'),
  countryList: state.app.countryList
});

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       getStateByCountry: appActionTypes.getStateByCountry
//     },
//     dispatch
//   );

export default connect(
  mapStateToProps,
  null
)(SelectedCountryItem);
