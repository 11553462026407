import React from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import routePath from '../../constants/routePath';
import { cart as cartActions } from '../../actions';

import CartDropdownItem from './CartDropdownItem';

class CartDropdown extends React.Component {
  componentDidMount() {
    const { getCartDetails } = this.props;
    getCartDetails();
  }

  /* handleUpdateCart = (id, data) => {
    const { updateCart } = this.props;
    updateCart({ id, data });
  };

  handleDeleteCart = (id) => {
    const { deleteCart } = this.props;
    deleteCart(id);
  }; */

  render() {
    const { cartDetails, countryCurrencies } = this.props;
    const notEmpty =
      cartDetails && cartDetails.items && cartDetails.items.length > 0;
    const currency = countryCurrencies[cartDetails.country]
      ? countryCurrencies[cartDetails.country].currency_symbol
      : '';
    return (
      <React.Fragment>
        <Dropdown>
          <Dropdown.Toggle
            size="sm"
            variant="link"
            id="cartDropdown"
            className="nav-link dropdown-toggle arrow-none"
          >
            <i
              className={`dripicons-cart noti-icon ${
                notEmpty ? 'text-success' : ''
              }`}
            />
            <span className="custom-icon-badge">
              {notEmpty && cartDetails.items.length}
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            alignRight
            className="notification-header-dropdown dropdown-lg pb-0"
          >
            <Dropdown.Header className="dropdown-header noti-title">
              <h5 className="m-0">
                <span className="float-right d-none">
                  <Link to={routePath.account.path} className="text-dark">
                    <small>Clear All</small>
                  </Link>
                </span>
                Your Cart
              </h5>
            </Dropdown.Header>

            {notEmpty ? (
              <React.Fragment>
                {cartDetails.items.map(cartData => (
                  <CartDropdownItem
                    data={cartData}
                    key={`cart_drop_item_${cartData.id}`}
                    currency={currency}
                    // deleteCart={this.handleDeleteCart}
                    // updateCart={this.handleUpdateCart}
                  />
                ))}
                <Dropdown.Item className="p-0">
                  <div className="row no-gutters mt-2 border-top cart-buttons">
                    <div className="col border-right">
                      <Link
                        to={routePath.cart.path}
                        className="btn btn-link btn-block rounded-0"
                      >
                        View cart
                      </Link>
                    </div>
                    <div className="col">
                      <Link
                        to={routePath.checkout.path}
                        className="btn btn-link btn-block rounded-0"
                      >
                        Checkout
                      </Link>
                    </div>
                  </div>
                </Dropdown.Item>
              </React.Fragment>
            ) : (
              <div className="text-center p-3 text-muted">
                <i className="mdi mdi-cart-outline font-60" />
                <p>Cart is currently empty!</p>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  cartDetails: state.cart.cartDetails,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCartDetails: cartActions.getCartDetails
      // updateCart: cartActions.updateCart,
      // deleteCart: cartActions.deleteCart
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CartDropdown);
