import React, { useCallback } from 'react';
import { arrayRemove, arrayPush, change } from 'redux-form';
import { findIndex, find, debounce } from 'lodash';
import {
  calculateProductConvertPrice,
  calculateProductPrice
} from '../../../services/apiService';

function useCountryPrice(countyIso2, name, fieldValue, dispatch) {
  const getPriceCalculation = useCallback(
    debounce(async data => {
      try {
        const res = await calculateProductPrice(data);
        dispatch(
          change('productForm', `${name}.member_price`, res.member_price)
        );
        dispatch(
          change(
            'productForm',
            `${name}.member_price_vat`,
            res.member_price_vat
          )
        );
        dispatch(
          change(
            'productForm',
            `${name}.customer_price_vat`,
            res.customer_price_vat
          )
        );
      } catch (error) {
        console.error(error);
      }
    }, 750),
    [name]
  );

  const handleChangePrice = (field, value) => {
    const data = {};
    if (fieldValue[field] === value) return;
    const fields = [
      'taxable',
      'regular_price',
      'sale_price',
      'bpoint',
      'cpoint'
    ];
    fields.forEach(fname => {
      if (fname === field) {
        data[fname] = value;
      } else {
        data[fname] = fieldValue[fname] || 0;
      }
    });

    if (fieldValue.taxable) {
      if (field === 'tax_id') {
        data.tax_rate = value;
      } else {
        data.tax_rate = fieldValue.tax_id;
      }
    }

    data.country = countyIso2;
    getPriceCalculation(data);
  };

  const getConvertedPrice = async data => {
    try {
      const res = await calculateProductConvertPrice(data);

      dispatch(change('productForm', `${name}.regular_price`, res.reg_price));
      dispatch(change('productForm', `${name}.sale_price`, res.sale_price));
      handleChangePrice('regular_price', res.reg_price);
      handleChangePrice('sale_price', res.sale_price);
    } catch (error) {
      console.error(error);
    }
  };

  return {
    handleChangePrice,
    getPriceCalculation,
    getConvertedPrice
  };
}

export default useCountryPrice;
