import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { currency as currencyActionTypes } from '../actions';

function* getCurrency(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/prefetch/currencies`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: currencyActionTypes.CURRENCY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: currencyActionTypes.CURRENCY_ERROR
    });
  }
}

function* watchGetCurrency() {
  yield takeLatest(currencyActionTypes.CURRENCY_REQUEST, getCurrency);
}

export default [fork(watchGetCurrency)];
