const template = {
  TEMPLATE_ALL_LIST_REQUEST: 'TEMPLATE_ALL_LIST_REQUEST',
  TEMPLATE_ALL_LIST_SUCCESS: 'TEMPLATE_ALL_LIST_SUCCESS',
  TEMPLATE_ALL_LIST_ERROR: 'TEMPLATE_ALL_LIST_ERROR',
  TEMPLATE_CREATE_REQUEST: 'TEMPLATE_CREATE_REQUEST',
  TEMPLATE_CREATE_SUCCESS: 'TEMPLATE_CREATE_SUCCESS',
  TEMPLATE_CREATE_ERROR: 'TEMPLATE_CREATE_ERROR',
  TEMPLATE_UPDATE_REQUEST: 'TEMPLATE_UPDATE_REQUEST',
  TEMPLATE_UPDATE_SUCCESS: 'TEMPLATE_UPDATE_SUCCESS',
  TEMPLATE_UPDATE_ERROR: 'TEMPLATE_UPDATE_ERROR',
  TEMPLATE_REQUEST: 'TEMPLATE_REQUEST',
  TEMPLATE_SUCCESS: 'TEMPLATE_SUCCESS',
  getAllTemplates: data => ({
    type: template.TEMPLATE_ALL_LIST_REQUEST,
    data
  }),
  createTemplate: data => ({ type: template.TEMPLATE_CREATE_REQUEST, data }),
  updateTemplate: data => ({ type: template.TEMPLATE_UPDATE_REQUEST, data }),
  getTemplateById: id => ({ type: template.TEMPLATE_REQUEST, id })
};

export default template;
