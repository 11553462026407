const associate = {
  ROLE_LIST_REQUEST: 'ROLE_LIST_REQUEST',
  ROLE_LIST_SUCCESS: 'ROLE_LIST_SUCCESS',
  ROLE_LIST_ERROR: 'ROLE_LIST_ERROR',
  USER_LIST_REQUEST: 'USER_LIST_REQUEST',
  USER_LIST_SUCCESS: 'USER_LIST_SUCCESS',
  USER_LIST_ERROR: 'USER_LIST_ERROR',
  PERMISSION_LIST_REQUEST: 'PERMISSION_LIST_REQUEST',
  PERMISSION_LIST_SUCCESS: 'PERMISSION_LIST_SUCCESS',
  PERMISSION_LIST_ERROR: 'PERMISSION_LIST_ERROR',
  ROLE_PERMISSION_REQUEST: 'ROLE_PERMISSION_REQUEST',
  ROLE_PERMISSION_SUCCESS: 'ROLE_PERMISSION_SUCCESS',
  ROLE_PERMISSION_ERROR: 'ROLE_PERMISSION_ERROR',
  UPDATE_ROLE_PERMISSION_REQUEST: 'UPDATE_ROLE_PERMISSION_REQUEST',
  UPDATE_ROLE_PERMISSION_SUCCESS: 'UPDATE_ROLE_PERMISSION_SUCCESS',
  UPDATE_ROLE_PERMISSION_ERROR: 'UPDATE_ROLE_PERMISSION_ERROR',
  getRoles: data => ({ type: associate.ROLE_LIST_REQUEST, data }),
  getUsersByRole: data => ({ type: associate.USER_LIST_REQUEST, data }),
  getPermissions: data => ({ type: associate.PERMISSION_LIST_REQUEST, data }),
  getPermissionByRole: data => ({
    type: associate.ROLE_PERMISSION_REQUEST,
    data
  }),
  updateRolePermission: data => ({
    type: associate.UPDATE_ROLE_PERMISSION_REQUEST,
    data
  })
};

export default associate;
