import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  paymentGateway as paymentGatewayActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getPaymentGateways() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/paymentGateway`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: paymentGatewayActionTypes.PAYMENT_GATEWAY_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getAllPaymentGateways() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/all-paymentGateway`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: paymentGatewayActionTypes.PAYMENT_GATEWAY_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* getPaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/paymentGateway/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: paymentGatewayActionTypes.PAYMENT_GATEWAY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    // console.log(error);
  }
}

function* createPaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/paymentGateway/add`,
      method: 'POST',
      data: actions.data,
      successMessage: 'PaymentGateway was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: paymentGatewayActionTypes.PAYMENT_GATEWAY_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.associatePaymentGateway.path
      });
    }
  } catch (error) {
    yield put({
      type: paymentGatewayActionTypes.PAYMENT_GATEWAY_CREATE_ERROR,
      data: error
    });
  }
}

function* updatePaymentGateway(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/paymentGateway/${actions.data.id}/edit`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'PaymentGateway updated successfully'
    });
    if (responseJson) {
      yield put({
        type: paymentGatewayActionTypes.PAYMENT_GATEWAY_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.associatePaymentGateway.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: paymentGatewayActionTypes.PAYMENT_GATEWAY_UPDATE_ERROR,
      data: error
    });
  }
}

function* watchPaymentGatewayList() {
  yield takeLatest(
    paymentGatewayActionTypes.PAYMENT_GATEWAY_LIST_REQUEST,
    getPaymentGateways
  );
}

function* watchPaymentGatewayListAll() {
  yield takeLatest(
    paymentGatewayActionTypes.PAYMENT_GATEWAY_ALL_LIST_REQUEST,
    getAllPaymentGateways
  );
}

function* watchGetPaymentGateway() {
  yield takeLatest(
    paymentGatewayActionTypes.PAYMENT_GATEWAY_REQUEST,
    getPaymentGateway
  );
}

function* watchPaymentGatewayCreate() {
  yield takeLatest(
    paymentGatewayActionTypes.PAYMENT_GATEWAY_CREATE_REQUEST,
    createPaymentGateway
  );
}

function* watchPaymentGatewayUpdate() {
  yield takeLatest(
    paymentGatewayActionTypes.PAYMENT_GATEWAY_UPDATE_REQUEST,
    updatePaymentGateway
  );
}

export default [
  fork(watchPaymentGatewayList),
  fork(watchPaymentGatewayListAll),
  fork(watchGetPaymentGateway),
  fork(watchPaymentGatewayCreate),
  fork(watchPaymentGatewayUpdate)
];
