import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, Form, change } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import validator from 'card-validator';
import { required, number, ccNumber } from '../../utills/validator';
import {
  inputField,
  radioField,
  selectField,
  inputMaskField
} from '../../components/Fields';
import { paymentOption as paymentOptionActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class PaymentCardForm extends React.Component {
  handleClose = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  getMonths = () => {
    const months = [];
    for (let i = 1; i <= 12; i += 1) {
      const val = i > 9 ? i : `0${i}`;
      months.push({ value: val, label: val });
    }
    return months;
  };

  getYears = () => {
    const date = new Date();
    const years = [];
    const thisYear = date.getFullYear();
    for (let i = 0; i <= 20; i += 1) {
      const val = thisYear + i;
      years.push({ value: val, label: val });
    }
    return years;
  };

  getCardTypes = () => [
    { value: 'visa', label: 'Visa' },
    { value: 'mastercard', label: 'Master' },
    { value: 'amex', label: 'Amex' }
  ];

  setCardType = value => {
    const { dispatch } = this.props;
    const numberValidation = validator.number(value);
    if (
      numberValidation &&
      numberValidation.isPotentiallyValid &&
      numberValidation.card
    ) {
      dispatch(
        change('paymentCardForm', 'card_type', numberValidation.card.type)
      );
    }
  };

  submitForm = values => {
    const {
      createPaymentOption,
      updatePaymentOption,
      initialValues
    } = this.props;
    const fieldValues = {
      ...values,
      card_number: values.card_number.replace(/\s/g, '')
    };
    if (initialValues.id) {
      updatePaymentOption({
        optionType: 'card',
        id: initialValues.id,
        data: fieldValues
      });
    } else createPaymentOption({ data: fieldValues, optionType: 'card' });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      inProgress,
      initialValues
    } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(this.submitForm)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {initialValues.id ? 'Update card' : 'Add new card'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <Field
                name="card_holder_name"
                component={inputField}
                type="text"
                className="form-control"
                label="Name on card"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-8">
              <Field
                name="card_number"
                mask="9999 9999 9999 9999"
                component={inputMaskField}
                className="form-control"
                label="Card number"
                feedback={this.setCardType}
                validate={[required, ccNumber]}
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Field
                name="card_type"
                component={selectField}
                notSearchable="1"
                label="Card Type"
                options={this.getCardTypes()}
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="exp_month"
                component={selectField}
                notSearchable="1"
                label="Month"
                options={this.getMonths()}
                validate={[required, number]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="exp_year"
                component={selectField}
                notSearchable="1"
                label="Year"
                options={this.getYears()}
                validate={[required, number]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="is_default"
                options={[
                  { value: 0, label: 'No' },
                  { value: 1, label: 'Yes' }
                ]}
                id="isDefault"
                component={radioField}
                label="Default"
                validate={[required]}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={inProgress || pristine || submitting}
            variant="primary"
          >
            {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  inProgress: state.paymentOption.inProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      hideModal: paymentOptionActions.hideModal,
      createPaymentOption: paymentOptionActions.createPaymentOption,
      getPaymentOptionById: paymentOptionActions.getPaymentOptionById,
      updatePaymentOption: paymentOptionActions.updatePaymentOption,
      dispatch: action => action
    },
    dispatch
  );

const PaymentCardReduxForm = reduxForm({
  form: 'paymentCardForm' // a unique identifier for this form
})(PaymentCardForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentCardReduxForm);
