import { customer as customerActionTypes } from '../actions';
import initialState from '../constants/initialState';

function customer(state = initialState.customer, action) {
  switch (action.type) {
    case customerActionTypes.ASSOCIATE_CUSTOMER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case customerActionTypes.ASSOCIATE_CUSTOMER_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          associatesCustomer: action.data
        };
      }
      return state;
    case customerActionTypes.ALL_CUSTOMER_REQUEST:
    case customerActionTypes.CUSTOMER_BY_ASSOC_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case customerActionTypes.ALL_CUSTOMER_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allCustomer: action.data
        };
      }
      return state;
    case customerActionTypes.CUSTOMER_BY_ASSOC_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allCustomer: action.data
        };
      }
      return state;
    default:
      return state;
  }
}

export default customer;
