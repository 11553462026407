import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  location as locationActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllLocation(actions) {
  const params = { ...actions.data };
  if (params && params.type) {
    delete params.type;
  }
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}`,
      method: 'GET',
      params
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLocation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}/${actions.data.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createLocation(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${actions.data.type}/add`,
      method: 'POST',
      data: actions.data.data,
      successMessage: 'Location was added successfully'
    });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath[actions.data.type].path
      });
    }
  } catch (error) {
    yield put({ type: locationActionTypes.LOCATION_CREATE_ERROR, data: error });
  }
}

function* updateLocation(actions) {
  const {
    data: { updateType, id },
    type,
    navId
  } = actions.data;
  let path = '/location/country';
  if (type === 'state') path = `/location/state/${navId}`;
  if (type === 'city') path = `/location/city/${navId}`;
  try {
    let responseJson;
    if (updateType === 'status')
      responseJson = yield axios({
        url: `${process.env.REACT_APP_API_URL}/${actions.data.type}/toggleActivation/${actions.data.id}`,
        method: 'PUT',
        successMessage: 'Location updated successfully'
      });
    else
      responseJson = yield axios({
        url: `${process.env.REACT_APP_API_URL}/${actions.data.type}/edit/${actions.data.id}`,
        method: 'PUT',
        data: actions.data.data,
        successMessage: 'Location updated successfully'
      });
    if (responseJson) {
      yield put({
        type: locationActionTypes.LOCATION_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path
        });
      }
    }
  } catch (error) {
    yield put({
      type: locationActionTypes.LOCATION_UPDATE_ERROR,
      data: error
    });
  }
}

function* watchPackageListAll() {
  yield takeLatest(
    locationActionTypes.LOCATION_ALL_LIST_REQUEST,
    getAllLocation
  );
}

function* watchGetLocation() {
  yield takeLatest(locationActionTypes.LOCATION_REQUEST, getLocation);
}

function* watchLocationCreate() {
  yield takeLatest(locationActionTypes.LOCATION_CREATE_REQUEST, createLocation);
}

function* watchLocationUpdate() {
  yield takeLatest(locationActionTypes.LOCATION_UPDATE_REQUEST, updateLocation);
}

export default [
  fork(watchPackageListAll),
  fork(watchLocationCreate),
  fork(watchLocationUpdate),
  fork(watchGetLocation)
];
