import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _, { find } from 'lodash';
import moment from 'moment';
import CouponModal from './CouponModal';
import DeleteAlert from '../../components/ConfirmAlert';
import BreadCrumb from '../../components/BreadCrumb';
import { coupons as couponActions } from '../../actions';
import routePath from '../../constants/routePath';
import AdvanceTable from '../../components/AdvanceTable';
import StatusSwitch from '../../components/StatusSwitch';

class Coupons extends React.Component {
  state = {
    page: 1,
    selected: [],
    showModal: false,
    couponData: {}
  };

  columns = [
    {
      dataField: 'id',
      text: 'ID',
      headerClasses: 'text-center',
      headerAttrs: { width: '3%' },
      classes: 'text-center'
    },
    {
      dataField: 'country.iso2',
      text: 'Country',
      headerClasses: 'text-center',
      headerAttrs: { width: '3%' },
      classes: 'text-center'
    },
    {
      dataField: 'title',
      text: 'Title',
      headerClasses: 'text-center',
      headerAttrs: { width: '25%' },
      classes: 'text-center',
      formatter: (col, row) => (
        <>
          <button
            type="button"
            className="btn btn-link dashed-underline p-0"
            onClick={() => this.openModal(row)}
          >
            {col}
          </button>
        </>
      )
    },
    {
      dataField: 'method',
      text: 'Method',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (col, row) => {
        if (col === 'COUPON') {
          return <span className="badge badge-primary">{col}</span>;
        }
        return <span className="badge badge-success">{col}</span>;
      }
    },
    {
      dataField: 'type',
      text: 'Type',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (col, row) => {
        if (col === 'FLAT') {
          return <span className="badge badge-secondary">{col}</span>;
        }
        return <span className="badge badge-info">{col}</span>;
      }
    },
    {
      dataField: 'discount',
      text: 'Discount',
      headerClasses: 'text-center',
      classes: 'text-center',
      headerAttrs: { width: '5%' },
      formatter: (col, row) => {
        const { countryCurrencies } = this.props;
        const index = row.country.iso2;
        const currency = countryCurrencies[index]
          ? countryCurrencies[index].currency_symbol
          : '€';
        if (row.type === 'FLAT') {
          return (
            <div className="font-weight-semibold">
              <p className="text-primary">
                {currency}
                {parseInt(col, 10)}
              </p>
            </div>
          );
        }
        return (
          <div className="font-weight-semibold">
            <p className="text-primary">{parseInt(col, 10)}%</p>
          </div>
        );
      }
    },
    {
      dataField: 'start_at',
      text: 'Start & End Date',
      headerClasses: 'text-center',
      headerAttrs: { width: '20%' },
      classes: 'text-center',
      formatter: (col, row) => {
        const { appSetting } = this.props;
        const start = moment(col).format(appSetting.site_date_format);
        const end = moment(row.end_at).format(appSetting.site_date_format);
        return (
          <span>
            {start} to {end}
          </span>
        );
      }
    },
    {
      dataField: 'visibility',
      text: 'Visibility',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (col, row) => {
        if (row.visibility === 1) {
          return <span className="badge badge-dark">ASSOCIATE</span>;
        }
        if (row.visibility === 2) {
          return <span className="badge badge-warning">CUSTOMER</span>;
        }
        return <span className="badge badge-success">ALL</span>;
      }
    },
    {
      dataField: 'status',
      text: 'Active?',
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (col, row) => (
        <StatusSwitch
          checked={parseInt(col, 10)}
          onChange={this.updateCouponStatus}
          id={row.id}
          idPrefix="coupon_sts_"
        />
      )
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      headerClasses: 'text-center',
      headerAttrs: { width: '12%' },
      classes: 'text-center',
      formatter: (col, row) => {
        const { showAlert } = this.props;
        return (
          <>
            <Link to={`/coupons/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
            <button
              type="button"
              className="btn btn-icon btn-sm action-icon"
              onClick={() => this.openModal(row)}
            >
              <i className="action-icon mdi mdi-eye" />
            </button>
            <button
              type="button"
              className="btn btn-icon btn-sm action-icon"
              onClick={() => showAlert(row)}
            >
              <i className="mdi mdi-delete" />
            </button>
          </>
        );
      }
    }
  ];

  componentDidMount() {
    const { getAllCoupons } = this.props;
    getAllCoupons();
  }

  handleDelete = row => {
    const { deleteCoupon, alert } = this.props;
    deleteCoupon(alert.id);
  };

  updateCouponStatus = (id, val) => {
    const { updateCouponStatus } = this.props;
    updateCouponStatus({
      id,
      status: val ? 1 : 0
    });
  };

  openModal = row => {
    this.setState({ showModal: true, couponData: row });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  handleTableChange = (type, options) => {
    const { sizePerPage: perPage, page } = options;
    const { getAllCoupons, match } = this.props;
    const data = { page, per_page: perPage };
    if (match.params && match.params.id) {
      data.id = match.params.id;
    }
    getAllCoupons(data);
    this.setState({ page });
  };

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  render() {
    const {
      coupons,
      isLoading,
      hideAlert,
      alert,
      filterValues,
      updateProgress
    } = this.props;
    const { page, selected, showModal, couponData } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.coupons.breadcrumb} />
              <h4 className="page-title">{routePath.coupons.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                    <Link
                      to={routePath.couponAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      Add New
                    </Link>
                    <Link
                      to={routePath.consumptionHistory.path}
                      className="btn btn-primary float-right mr-2"
                    >
                      <i className="mdi mdi mdi-history mr-2" />
                      History
                    </Link>
                  </div>
                </div>
                <AdvanceTable
                  keyField="id"
                  data={coupons.data}
                  columns={this.columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={coupons.total}
                  sizePerPage={coupons.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
                <DeleteAlert
                  show={!!alert}
                  onConfirm={this.handleDelete}
                  inProgress={updateProgress}
                  onCancel={hideAlert}
                />
                <CouponModal
                  showModal={showModal}
                  coupon={couponData}
                  closeModal={this.closeModal}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  coupons: state.coupons.allList,
  appSetting: state.app.settings,
  alert: state.coupons.alert,
  isLoading: state.coupons.isLoading,
  updateProgress: state.coupons.updateProgress,
  countryCurrencies: state.app.countryCurrencies,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllCoupons: couponActions.getAllCoupons,
      showAlert: couponActions.showAlert,
      hideAlert: couponActions.hideAlert,
      deleteCoupon: couponActions.deleteCoupon,
      updateCouponStatus: couponActions.updateCouponStatus
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Coupons);
