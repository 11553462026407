import React from 'react';

export default class StatusSwitch extends React.Component {
  getClass() {
    const { type } = this.props;
    switch (type) {
      case 'danger':
        return 'switch-button switch-button-danger';
      case 'warning':
        return 'switch-button switch-button-warning';
      case 'yesno':
        return 'switch-button switch-button-yesno';
      case 'success':
      default:
        return 'switch-button switch-button-success';
    }
  }

  handleChange = e => {
    const { onChange, id } = this.props;
    if (typeof onChange === 'function') {
      onChange(id, e.target.checked);
    }
  };

  render() {
    const {
      checked,
      id,
      idPrefix,
      className,
      dataLabel = { on: 'Yes', off: 'No' }
    } = this.props;
    return (
      <div className={`${className} ${this.getClass()}`}>
        <input
          checked={checked}
          type="checkbox"
          id={`${idPrefix}${id}`}
          data-switch="success"
          onChange={this.handleChange}
          className={this.getClass()}
        />
        <label
          htmlFor={`${idPrefix}${id}`}
          data-on-label={dataLabel.on}
          data-off-label={dataLabel.off}
        />
      </div>
    );
  }
}

StatusSwitch.defaultProps = {
  checked: false,
  idPrefix: 'switch_'
};
