const singleLocation = {
  SINGLE_LOCATION_REQUEST: 'SINGLE_LOCATION_REQUEST',
  SINGLE_LOCATION_SUCCESS: 'SINGLE_LOCATION_SUCCESS',
  getSingleLocation: data => ({
    type: singleLocation.SINGLE_LOCATION_REQUEST,
    data
  })
};

export default singleLocation;
