import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Field,
  Form,
  getFormValues,
  reduxForm,
  FieldArray,
  arrayPush,
  change
} from 'redux-form';
import { find } from 'lodash';
import Select from 'react-select';
import { app as appActionTypes } from '../../actions';
import { required } from '../../utills/validator';
import { inputField, editorField, switchField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';
import routePath from '../../constants/routePath';
import TaxItemField from './TaxItemField';
import PackageImageUpload from './PackageImageUpload';

class PackageForm extends React.Component {
  componentDidMount() {
    const { getPrefetchTax, getCountry, getPrefetchShipping } = this.props;
    getPrefetchTax();
    getPrefetchShipping();
    getCountry();
  }

  handleCountryAdd = value => {
    const { formValues, dispatch } = this.props;
    const found = find(formValues.countries, { country: value.value });
    if (!found) {
      dispatch(
        arrayPush('packageForm', 'countries', {
          country: value.value,
          bp_restriction: '',
          bpoint: '',
          package_bonus_amount: '',
          price: '',
          shipping: '',
          shipping_vat: '',
          shop_restriction: '',
          tax: '',
          weight: ''
        })
      );
    }
  };

  handleImageSelect = file => {
    const { dispatch, onSelectImage } = this.props;
    onSelectImage(file);
    dispatch(change('packageForm', 'image_selected', true));
  };

  handleImageDelete = () => {
    const { dispatch } = this.props;
    dispatch(change('packageForm', 'image_selected', false));
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      formValues,
      countryList,
      defaultImage
    } = this.props;

    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Form
                className="needs-validation"
                noValidate
                onSubmit={handleSubmit(submitForm)}
              >
                <div className="row">
                  <div className="col">
                    <Field
                      name="title"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Title"
                      validate={[required]}
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="ean"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="EAN 13 Number"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <Field
                      name="icon"
                      component={inputField}
                      type="text"
                      className="form-control"
                      label="Icon class"
                    />
                  </div>
                </div>
                {/* <Field
                  name="type"
                  id="durationType"
                  component={radioField}
                  isInline
                  label="Duration"
                  options={[
                    { value: 'month', label: 'Monthly' },
                    { value: 'year', label: 'Annually' }
                  ]}
                  validate={[required]}
                /> */}
                <Field
                  name="description"
                  component={editorField}
                  type="text"
                  className="form-control"
                  label="Description"
                  validate={[required]}
                />
                <div className="card">
                  <h5 className="card-header">Package Image</h5>
                  <div className="card-body">
                    <div className="upload_img text-center align-items-center">
                      <Field name="file_id" component="input" type="hidden" />
                      <PackageImageUpload
                        preview={defaultImage ? [defaultImage] : []}
                        id="cat_img"
                        onChange={this.handleImageSelect}
                        deleteFile={this.handleImageDelete}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <Select
                        placeholder="Select Country to add price details"
                        options={countryList}
                        onChange={this.handleCountryAdd}
                      />
                    </div>
                  </div>
                </div>
                <FieldArray name="countries" component={TaxItemField} />
                <div className="row">
                  <div className="col">
                    <Field
                      name="status"
                      component={switchField}
                      label="Status"
                      id="packageStatus"
                      type="success"
                    />
                  </div>
                </div>
                <Link
                  to={routePath.associatePackage.path}
                  className="btn btn-light mr-2"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  disabled={inProgress}
                  className="btn btn-primary"
                >
                  {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                </button>
              </Form>
            </div>
          </div>
        </div>
        {/* <div className="col-3">
          {formValues ? (
            <div className="card card-pricing text-center">
              <div className="card-body">
                <p className="badge badge-danger rounded">Preview</p>
                <p className="card-pricing-plan-name font-weight-bold text-uppercase">
                  {formValues.title}
                </p>
                <i
                  className={`card-pricing-icon text-primary ${
                    formValues.icon ? formValues.icon : 'fas fa-cube'
                  }`}
                />
                <h3 className="card-pricing-price m-0">
                  {formValues.countries.length && formValues.countries[0].price
                    ? `€${parseFloat(formValues.countries[0].price).toFixed(2)}`
                    : '€0.00'}
                </h3>
                <span className="badge badge-light text-muted">incl. VAT</span>
                <div
                  className="card-pricing-features"
                  dangerouslySetInnerHTML={{ __html: formValues.description }}
                />
              </div>
            </div>
          ) : (
            <div className="text-center p-3">
              <h4>
                <i className="mdi mdi-eye font-25" />
                <br />
                Preview
              </h4>
              <p>Enter details to see how it will look</p>
            </div>
          )}
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('packageForm')(state),
  countryList: state.app.countryList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPrefetchTax: appActionTypes.getPrefetchTax,
      getPrefetchShipping: appActionTypes.getPrefetchShipping,
      getCountry: appActionTypes.getCountry
    },
    dispatch
  );

const PackageFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageForm);

export default reduxForm({
  form: 'packageForm' // a unique identifier for this form
})(PackageFormWithRedux);
