import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { bindActionCreators } from 'redux';
import { auth as authActions } from '../../actions';
import Image from '../../components/Image';
import { packageImage } from '../../constants/imageSize';
import img from '../../assets/images/img-placeholder.jpg';

function PackageCard({
  data,
  pack,
  setPackage,
  countryCurrencies,
  userInfo,
  dispatch
}) {
  const handleSetPackage = val => {
    setPackage(val);
    // Re-set shipping id if select different package
    if (pack !== val) {
      dispatch(change('registerPaymentForm', 'shipping_mode', ''));
    }
    // changeStep(4);
  };
  const isSelected = pack === data.id;
  let currency = '';
  if (userInfo && userInfo.associate_info) {
    currency = countryCurrencies[userInfo.associate_info.country]
      ? countryCurrencies[userInfo.associate_info.country].currency_symbol
      : '';
  }

  let pImage = data.file && data.file.file_path ? data.file.file_path : null;
  if (pImage) {
    pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
  } else {
    pImage = img;
  }

  return (
    <div className="card card-pricing mb-0 h-100">
      <Image
        src={pImage}
        className="card-img-top"
        alt=""
        size={packageImage.packageListMedium}
      />
      <div className="card-body text-center">
        <p className="card-pricing-plan-name font-weight-bold text-uppercase mb-0">
          {data.title}
        </p>
        <span className={isSelected ? 'text-success' : ''}>
          <i className="card-pricing-icon fas fa-cube" />
          {/* <img className="w-75" src="" /> */}
        </span>
        <h3 className="card-pricing-price m-0">
          {/* {data.price > '0' ? `${parseFloat(data.price).toFixed(2)}` : 'FREE'} */}
          {`${currency}${parseFloat(data.package_country[0].price).toFixed(2)}`}
        </h3>
        {data.tax_id ? (
          <span className="badge badge-light text-muted">incl. VAT</span>
        ) : null}
        <div
          className="card-pricing-features"
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
        <button
          type="button"
          className={`btn mt-3 mb-2 pl-3 pr-3 ${
            isSelected ? 'btn-success' : 'btn-primary '
          }`}
          onClick={() => handleSetPackage(data.id)}
        >
          {isSelected ? (
            <>
              <i className="mdi mdi-check-circle mr-1" /> Selected
            </>
          ) : (
            'Select'
          )}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  pack: state.auth.package,
  userInfo: state.auth.user,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setPackage: authActions.setPackage,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageCard);
