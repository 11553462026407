import React, { useCallback } from 'react';
import axios from 'axios';
import moment from 'moment';
import { downloadBlob } from '../../utills/ajax';

function SingleDownloadButton({
  id,
  invoice,
  type,
  title,
  icon = 'mdi mdi-file-pdf'
}) {
  const handleSingleDownload = useCallback(async () => {
    let url = '';
    switch (type) {
      case 'order-invoice':
        url = `${process.env.REACT_APP_API_URL}/product/invoice`;
        break;
      case 'subscription-invoice':
        url = `${process.env.REACT_APP_API_URL}/subscription/invoice`;
        break;
      case 'order-packingslip':
        url = `${process.env.REACT_APP_API_URL}/order/packingslip`;
        break;
      case 'subscription-packingslip':
        url = `${process.env.REACT_APP_API_URL}/subscription/packingslip`;
        break;
      default:
    }
    const response = await axios({
      url,
      method: 'GET',
      params: { id },
      headers: { Accept: 'application/pdf' },
      responseType: 'blob'
    });
    downloadBlob(response, `${moment().format('DDMMYYYY')}-${invoice}.pdf`);
  }, [id, type]);

  return (
    <button
      type="button"
      onClick={handleSingleDownload}
      className="btn btn-icon btn-sm"
    >
      <i className={`action-icon ${icon}`} title={`${title}`} />
    </button>
  );
}

export default SingleDownloadButton;
