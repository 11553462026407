import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AdvanceTable from '../../components/AdvanceTable';
import InnerLoader from '../../components/InnerLoader';
import { generation as generationActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import StatusSwitch from '../../components/StatusSwitch';

class Generation extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getAllGenerations } = this.props;
    getAllGenerations();
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAllGenerations } = this.props;
    if (type === 'pagination') getAllGenerations({ page, per_page: perPage });
    this.setState({ page });
  };

  render() {
    const { generations, isLoading, filterValues } = this.props;
    const { page, selected } = this.state;
    const columns = [
      {
        dataField: 'title',
        text: 'Title',
        headerAttrs: { width: '30%' }
      },
      {
        dataField: 'bp_percent',
        text: 'B-Point Percentage',
        headerClasses: 'text-center',
        classes: 'text-center',
        formatter: (col, row) => `${col}%`
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '7%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/generation/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.generation.breadcrumb} />
              <h4 className="page-title">{routePath.generation.title}</h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <Link
                      to={routePath.generationAdd.path}
                      className="btn btn-primary float-right"
                    >
                      <i className="mdi mdi-plus-circle mr-2" />
                      Add New
                    </Link>
                  </div>
                </div>
                <AdvanceTable
                  data={generations.data}
                  columns={columns}
                  loading={isLoading}
                  sizePerPageList={filterValues}
                  totalSize={generations.total}
                  sizePerPage={generations.per_page}
                  page={page}
                  remote={{ pagination: true }}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  generations: state.generation.allList,
  isLoading: state.generation.isLoading,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllGenerations: generationActions.getAllGenerations
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Generation);
