import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { payout as payoutActionTypes, app as appActionTypes } from '../actions';
import { downloadBlob } from '../utills/ajax';

function* getAssociatePayout(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/associate/month`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.ASSOCIATE_PAYOUT_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAdminPayoutList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.ADMIN_PAYOUT_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPayoutGenerateList(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/generate`,
      method: 'POST',
      data: actions.data,
      hideMessage: true
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.PAYOUT_GENERATE_LIST_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: `/payout/${responseJson.id}/view`
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: payoutActionTypes.PAYOUT_GENERATE_LIST_ERROR,
      data: error
    });
  }
}

function* approvePayout(actions) {
  try {
    let apiUrl = `${process.env.REACT_APP_API_URL}/payout/approve`;
    if (!actions.status) {
      apiUrl = `${process.env.REACT_APP_API_URL}/payout/refuse`;
    }
    const responseJson = yield axios({
      url: apiUrl,
      method: 'PUT',
      data: {
        selected: actions.data.selected
      }
    });
    if (responseJson) {
      if (actions.data.callback) {
        yield actions.data.callback();
      }
      yield put({
        type: payoutActionTypes.PAYOUT_APPROVE_SUCCESS,
        data: responseJson,
        status: actions.status
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* paidPayout(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/paid/${actions.data.id}`,
      method: 'PUT',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.PAYOUT_PAID_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getAdminPayoutItem(actions) {
  try {
    const { page = 1, sizePerPage = 12 } = actions.data;
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/${actions.data.id}`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.ADMIN_PAYOUT_ITEM_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPayoutStatement(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/associate/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.PAYOUT_STATEMENT_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* handleInvoiceDownload(actions) {
  try {
    const response = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/invoice/${actions.id}`,
      method: 'GET',
      headers: { Accept: 'application/pdf' },
      responseType: 'blob'
    });
    yield downloadBlob(response, 'INV-Maxx4-Payout.pdf');
  } catch (error) {
    console.log(error);
  }
}

function* ProcessPaoutPayment(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/payout/process`,
      method: 'POST',
      data: actions.data.payload,
      successMessage: 'Payout processed'
    });
    if (responseJson) {
      yield put({
        type: payoutActionTypes.PAYOUT_SEND_PAYMENT_SUCCESS,
        data: responseJson
      });
      yield put({
        type: payoutActionTypes.ADMIN_PAYOUT_ITEM_REQUEST,
        data: actions.data.pagePayload
      });
      yield put({
        type: payoutActionTypes.SHOW_HIDE_PAYOUT_MODAL,
        data: false
      });
      if (actions.data.callback) {
        yield actions.data.callback();
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: payoutActionTypes.PAYOUT_SEND_PAYMENT_ERROR,
      data: error
    });
  }
}

function* watchAssociatePayoutList() {
  yield takeLatest(
    payoutActionTypes.ASSOCIATE_PAYOUT_LIST_REQUEST,
    getAssociatePayout
  );
}

function* watchPayoutList() {
  yield takeLatest(
    payoutActionTypes.ADMIN_PAYOUT_ITEM_REQUEST,
    getAdminPayoutItem
  );
}

function* watchPayoutGenerateList() {
  yield takeLatest(
    payoutActionTypes.PAYOUT_GENERATE_LIST_REQUEST,
    getPayoutGenerateList
  );
}

function* watchApprovePayout() {
  yield takeLatest(payoutActionTypes.PAYOUT_APPROVE_REQUEST, approvePayout);
}

function* watchPaidPayout() {
  yield takeLatest(payoutActionTypes.PAYOUT_PAID_REQUEST, paidPayout);
}

function* watchPayoutListAll() {
  yield takeLatest(
    payoutActionTypes.ADMIN_PAYOUT_LIST_REQUEST,
    getAdminPayoutList
  );
}

function* watchGetPayoutStatement() {
  yield takeLatest(
    payoutActionTypes.PAYOUT_STATEMENT_REQUEST,
    getPayoutStatement
  );
}

function* watchDownloadPayout() {
  yield takeLatest(
    payoutActionTypes.PAYOUT_DOWNLOAD_REQUEST,
    handleInvoiceDownload
  );
}

function* watchProcessPayoutPayment() {
  yield takeLatest(
    payoutActionTypes.PAYOUT_SEND_PAYMENT_REQUEST,
    ProcessPaoutPayment
  );
}

export default [
  fork(watchAssociatePayoutList),
  fork(watchPayoutGenerateList),
  fork(watchApprovePayout),
  fork(watchPaidPayout),
  fork(watchPayoutList),
  fork(watchPayoutListAll),
  fork(watchGetPayoutStatement),
  fork(watchDownloadPayout),
  fork(watchProcessPayoutPayment)
];
