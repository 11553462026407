import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FsbView from '../../components/FsbView';
import InnerLoader from '../../components/InnerLoader';
import {
  associateFsb as associateFsbActions,
  fsb as fsbActions
} from '../../actions';
import NoResult from '../../components/NoResult';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class FsbTracker extends React.Component {
  componentDidMount() {
    const { getAssociateFsb, getFsb } = this.props;
    getAssociateFsb();
    getFsb();
  }

  render() {
    const { isLoading, fsbPlans, associateFsb } = this.props;
    if (isLoading) {
      return <InnerLoader type="table" />;
    }
    const fsb = fsbPlans.data ? fsbPlans.data[0] : {};

    if (fsb && fsb.phase && associateFsb && associateFsb.fsb_plan_logs) {
      return (
        <>
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <BreadCrumb list={routePath.fsbTracker.breadcrumb} />
                <h4 className="page-title">{routePath.fsbTracker.title}</h4>
              </div>
            </div>
          </div>
          <FsbView fsb={fsb} associateFsb={associateFsb} />
        </>
      );
    }
    return (
      <NoResult
        title="Only for NEW ASSOCIATES who're signing up."
        body="You're not qualified for the FAST START BONUS plan."
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.associateFsb.isLoading,
  associateFsb: state.associateFsb.plan,
  fsbPlans: state.fsb.plan
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAssociateFsb: associateFsbActions.getAssociateFsb,
      getFsb: fsbActions.getFsb
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FsbTracker);
