import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ShippingForm from './ShippingForm';
import { shipping as shippingActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class ShippingAdd extends React.Component {
  handleSubmit = values => {
    const { createShipping } = this.props;
    console.log(values);
    createShipping(values);
  };

  render() {
    const { createProgress } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.shippingAdd.breadcrumb} />
              <h4 className="page-title">{routePath.shippingAdd.title}</h4>
            </div>
          </div>
        </div>
        <ShippingForm
          submitForm={this.handleSubmit}
          inProgress={createProgress}
          initialValues={{ status: false }}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.shipping.createProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { createShipping: shippingActions.createShipping },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingAdd);
