import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import Select from 'react-select';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
import { dashboard as dashboardActions } from '../../actions';
import StatisticsWidget from '../../components/StatisticsWidget';
import { getMonthOption } from '../../utills/custom';
// import InnerLoader from '../../components/InnerLoader';

const generationArray = [
  { label: 'Generation 1 - 20%', commission: '(BP x 20%) x 1.43' },
  { label: 'Generation 2 - 10%', commission: '(BP x 10%) x 1.43' },
  { label: 'Generation 3 - 9%', commission: '(BP x 9%) x 1.43' },
  { label: 'Generation 4 - 9%', commission: '(BP x 9%) x 1.43' },
  { label: 'Generation 5 - 7%', commission: '(BP x 7%) x 1.43' },
  { label: 'Generation 6 - 7%', commission: '(BP x 7%) x 1.43' },
  { label: 'Generation 7 - 5%', commission: '(BP x 5%) x 1.43' },
  { label: 'Generation 8 - 4%', commission: '(BP x 4%) x 1.43' },
  { label: 'BP outside 8 Generations', commission: 'N/A' }
];

class AssociateDash extends React.Component {
  constructor(props) {
    super(props);
    this.monthOption = getMonthOption();
    const dateObj = moment();
    this.state = {
      dateRangeValue: '11/01/2019 to 12/02/2019',
      selectedMonth: {
        label: `${dateObj.format('MMMM')} - ${dateObj.format('YYYY')}`,
        value: `${dateObj.month() + 1},${dateObj.year()}`
      },
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment()
            .subtract(1, 'month')
            .startOf('month'),
          moment()
            .subtract(1, 'month')
            .endOf('month')
        ]
      }
    };
  }

  componentDidMount() {
    const { getDashboard } = this.props;
    const { selectedMonth } = this.state;
    getDashboard({ mmyy: selectedMonth.value });
  }

  handleDateRange = (event, picker) => {
    const startDate = moment(picker.startDate).format('DD/MM/YYYY');
    const endDate = moment(picker.endDate).format('DD/MM/YYYY');
    this.setState({ dateRangeValue: `${startDate} to ${endDate}` });
  };

  handleMonthChange = val => {
    const { getDashboard } = this.props;
    this.setState({ selectedMonth: val });
    getDashboard({ mmyy: val.value });
  };

  render() {
    const { dateRangeValue, ranges, selectedMonth } = this.state;
    const { userInfo, data, isLoading } = this.props;
    const {
      current_currency: currentCurrency = '',
      previous_currency: previousCurrency = '',
      requested_currency: requestedCurrency = ''
    } = data;
    return (
      <div className="position-relative">
        {isLoading && (
          <div className="preloader-inner">
            <div className="status-inner">
              <div className="bouncing-loader">
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
        )}
        <div className="row row-eq-height">
          <div className="col-md-4 mb-3">
            <StatisticsWidget
              bgclassName="cta-box bg-primary text-white mb-0 h-100"
              icon="mdi mdi-crown bg-light-lighten rounded-circle text-white"
              // bgclassName="cta-box bg-white text-white1"
              // icon="mdi mdi-summit bg-light rounded-circle"
              description="Present Rank"
              title={data.highest_rank ? data.highest_rank.title : '---'}
            >
              <p className="mb-0 text-uppercase font-12">
                <span className="badge badge-success font-11 font-weight-semibold">
                  Joining date: {userInfo.joining_date}
                </span>
              </p>
              {/* <p className="mb-0 text-uppercase font-12">&nbsp;</p> */}
            </StatisticsWidget>
          </div>
          <div className="col-md-4 mb-3">
            <StatisticsWidget
              // bgclassName="cta-box bg-white text-white1"
              // icon="mdi mdi-summit bg-light rounded-circle"
              bgclassName="cta-box bg-success text-white mb-0 h-100"
              icon="mdi mdi-summit bg-light-lighten rounded-circle text-white"
              description="Next Rank"
              title={data.next_rank ? data.next_rank.title : '---'}
            >
              <p className="mb-0 text-uppercase font-12">
                <span className="badge badge-danger font-11 font-weight-semibold">
                  {data.next_rank
                    ? `${parseFloat(
                        data.next_rank.require_next_rank_bp
                      ).toFixed(2)} B-Point to Qualify`
                    : '---'}
                </span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-md-4 mb-3">
            {!data.bp_restriction_starts ||
            (data.current_bpoints &&
              parseFloat(data.current_bpoints.own_purchase) +
                parseFloat(data.current_bpoints.customer_purchase) >=
                30) ? (
              <StatisticsWidget
                bgclassName="cta-box bg-success-lighten mb-0 h-100"
                icon="mdi mdi-check bg-success rounded-circle text-white"
                description="Qualified"
                title="YES"
              >
                <p className="mb-0 text-uppercase font-12">
                  <span className="badge badge-dark font-11 font-weight-semibold">
                    For 30 B-Point
                  </span>
                </p>
              </StatisticsWidget>
            ) : (
              <StatisticsWidget
                bgclassName="cta-box bg-danger-lighten mb-0 h-100"
                icon="mdi mdi-close bg-danger rounded-circle text-white"
                description="Qualified"
                title="NO"
              >
                <p className="mb-0 text-uppercase font-12">
                  <span className="badge badge-dark font-11 font-weight-semibold">
                    For 30 B-Point
                  </span>
                </p>
              </StatisticsWidget>
            )}
          </div>
        </div>
        <div className="row row-eq-height">
          <div className="col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName="mb-0 h-100"
              icon="mdi mdi-cash bg-light rounded-circle text-success widget-icon ml-2"
              description="Current Month Commission"
              title={`${currentCurrency}${data.currency_current_month_commission ||
                '0.00'}`}
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">{data.current_month}</span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName="mb-0 h-100"
              icon="mdi mdi-cash bg-light rounded-circle text-success widget-icon ml-2"
              description="Last Month Commission"
              title={`${previousCurrency}${data.currency_previous_month_commission ||
                '0.00'}`}
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">{data.previous_month}</span>
              </p>
            </StatisticsWidget>
          </div>
          {/* <div className="col-sm-4">
            <StatisticsWidget
              icon="mdi mdi-cash bg-light rounded-circle text-success widget-icon ml-2"
              description="Total Commission Earned"
              title="€12,763"
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">Upto now</span>
              </p>
            </StatisticsWidget>
          </div> */}
        </div>
        <div className="row mb-2">
          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
            <Select
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select Month"
              isClearable={false}
              isSearchable
              name="month_picker"
              value={selectedMonth}
              options={this.monthOption}
              onChange={this.handleMonthChange}
            />
          </div>
        </div>
        {/* <div className="row mb-2">
          <div className="col-xl-12 col-lg-12 col-md-8 col-sm-12 col-12">
            <DateRangePicker
              startDate="1/11/2019"
              endDate="11/30/2019"
              ranges={ranges}
              onApply={this.handleDateRange}
            >
              <div className="form-group has-icon">
                <i className="mdi mdi-calendar" />
                <input type="text" value={dateRangeValue} className="form-control input-medium cursor-pointer" placeholder="Select date range" readOnly />
              </div>
            </DateRangePicker>
          </div>
        </div> */}
        <div className="row row-eq-height">
          <div className="col-sm-6 col-lg-3 mb-3">
            <StatisticsWidget
              icon="mdi mdi-account"
              bgclassName="card-icon mb-0 h-100"
              cardClass="p-2"
              description="Personal"
              title={
                data.bpoints ? (
                  <span
                    className={
                      parseFloat(data.bpoints.own_purchase) +
                        parseFloat(data.bpoints.customer_purchase) >=
                      30
                        ? ''
                        : 'text-danger'
                    }
                  >
                    {`${(
                      parseFloat(data.bpoints.own_purchase) +
                      parseFloat(data.bpoints.customer_purchase)
                    ).toFixed(2)}`}
                  </span>
                ) : (
                  <span className="text-danger">0.00</span>
                )
              }
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">{`Total BP - ${data.requested_month}`}</span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-sm-6 col-lg-3 mb-3">
            <StatisticsWidget
              icon="fas fa-sitemap"
              bgclassName="card-icon mb-0 h-100"
              cardClass="p-2"
              description="Downline"
              title={
                data.bpoints
                  ? `${(
                      parseFloat(data.bpoints.downline_associate_own_purchase) +
                      parseFloat(data.bpoints.downline_associate_signup) +
                      parseFloat(data.bpoints.downline_customer_purchase)
                    ).toFixed(2)}`
                  : '0.00'
              }
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">{`Total BP - ${data.requested_month}`}</span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-sm-6 col-lg-3 mb-3">
            <StatisticsWidget
              icon="mdi mdi-account-star"
              bgclassName="card-icon mb-0 h-100"
              cardClass="p-2"
              description="Rank Qualification"
              title={data.bpoints ? data.bpoints.earned_points : '0.00'}
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">{`Total BP - ${data.requested_month}`}</span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-sm-6 col-lg-3 mb-3">
            <StatisticsWidget
              icon="mdi mdi-account-multiple-check"
              bgclassName="card-icon mb-0 h-100"
              cardClass="p-2"
              description="Qualified Generations"
              title={
                data.qualified_bp > 0
                  ? parseFloat(data.qualified_bp).toFixed(2)
                  : '0.00'
              }
            >
              <p className="mb-0 text-muted font-13">
                <span className="text-nowrap">{`Total BP - ${data.requested_month}`}</span>
              </p>
            </StatisticsWidget>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="mt-0 mb-3">B POINT Monthly Report</h5>
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th>Activity</th>
                        <th>Description</th>
                        <th className="text-center">BPOINT</th>
                        <th className="text-center">Commission</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Personal Associates Sign-Up BP</td>
                        <td>
                          Commissions Paid
                          <br />
                          <small>(BP x 20%) x 1.43</small>
                        </td>
                        <td className="text-center">
                          {data.bpoints
                            ? data.bpoints.associate_signup
                            : '0.00'}
                        </td>
                        <td className="text-center">
                          {data.bpoints
                            ? `${requestedCurrency}${data.bpoints.currency_associate_signup_amount}`
                            : `${requestedCurrency}0.00`}
                        </td>
                      </tr>
                      <tr>
                        <td>Own Purchase BP</td>
                        <td>No Commissions Paid</td>
                        <td className="text-center">
                          {data.bpoints ? data.bpoints.own_purchase : '0.00'}
                        </td>
                        <td className="text-center">N/A</td>
                      </tr>
                      {/* <tr>
                        <td>Customer Sign-Up BP</td>
                        <td>No Commissions Paid</td>
                        <td className="text-center">80</td>
                      </tr> */}
                      <tr>
                        <td>Customer Purchase BP</td>
                        <td>
                          Commissions Paid
                          <br />
                          <small>(BP x 65%) x 1.43</small>
                        </td>
                        <td className="text-center">
                          {data.bpoints
                            ? data.bpoints.customer_purchase
                            : '0.00'}
                        </td>
                        <td className="text-center">
                          {data.bpoints
                            ? `${requestedCurrency}${data.bpoints.currency_customer_purchase_amount}`
                            : `${requestedCurrency}0.00`}
                        </td>
                      </tr>
                      <tr>
                        <td>Referred Customer BP</td>
                        <td>No Commissions Paid</td>
                        <td className="text-center">
                          {data.bpoints
                            ? data.bpoints.ref_customer_purchase
                            : '0.00'}
                        </td>
                        <td className="text-center">N/A</td>
                      </tr>
                      <tr>
                        <td>Downline BP</td>
                        <td />
                        <td className="text-center">
                          {data.bpoints
                            ? (
                                parseFloat(
                                  data.bpoints.downline_associate_own_purchase
                                ) +
                                parseFloat(
                                  data.bpoints.downline_associate_signup
                                ) +
                                parseFloat(
                                  data.bpoints.downline_customer_purchase
                                )
                              ).toFixed(2)
                            : '0.00'}
                        </td>
                        <td className="text-center">N/A</td>
                      </tr>
                      <tr>
                        <td>Total BP</td>
                        <td />
                        <td className="text-center">
                          {data.bpoints ? data.bpoints.earned_points : '0.00'}
                        </td>
                        <td className="text-center">N/A</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="mt-0 mb-3">
                  Generations Report
                  {!data.bpoints ||
                  (data.bpoints &&
                    parseFloat(data.bpoints.own_purchase) +
                      parseFloat(data.bpoints.customer_purchase) <
                      30) ? (
                    <span className="ml-1 badge badge-danger">
                      NOT QUALIFIED
                    </span>
                  ) : null}
                </h5>
                <div className="table-responsive">
                  <table className="table table-sm table-bordered text-center table-nowrap">
                    <thead className="thead-light">
                      <tr>
                        <th>Generations</th>
                        <th>Qualified</th>
                        <th>BP</th>
                        <th>Commission</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generationArray.map((item, index) => {
                        if (data.generation_bp && data.generation_bp[index]) {
                          return (
                            <tr
                              className={
                                data.generation_bp[index].payout_amount > 0 &&
                                parseFloat(data.bpoints.own_purchase) +
                                  parseFloat(data.bpoints.customer_purchase) >=
                                  30
                                  ? 'text-success'
                                  : 'text-danger'
                              }
                            >
                              <td>{item.label}</td>
                              <td>
                                {data.generation_bp[index].payout_amount > 0
                                  ? 'Yes'
                                  : 'No'}
                              </td>
                              <td width="30%">
                                {data.generation_bp[index].bpoint}
                                <br />
                                <small>{item.commission}</small>
                              </td>
                              <td>
                                {`${requestedCurrency}${data.generation_bp[index].currency_amount}`}
                              </td>
                            </tr>
                          );
                        }
                        return (
                          <tr className="text-danger">
                            <td>{item.label}</td>
                            <td>No</td>
                            <td width="30%">
                              0.00
                              <br />
                              <small>{item.commission}</small>
                            </td>
                            <td>{`${requestedCurrency}0.00`}</td>
                          </tr>
                        );
                      })}
                      <tr
                        className={
                          data.marketing_packet_sale > 0
                            ? 'text-success'
                            : 'text-danger'
                        }
                      >
                        <td>Marketing Packet Sale</td>
                        <td>{data.marketing_packet_sale > 0 ? 'Yes' : 'No'}</td>
                        <td width="30%">{data.marketing_packet_sale_qty}</td>
                        <td>
                          {`${requestedCurrency}${parseFloat(
                            data.marketing_packet_sale
                          ).toFixed(2)}`}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-eq-height">
          <div className="col-md-6 col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName={
                data.three_active_sponsers
                  ? 'cta-box bg-success-lighten mb-0 h-100'
                  : 'cta-box bg-danger-lighten mb-0 h-100'
              }
              icon={
                data.three_active_sponsers
                  ? 'mdi mdi-check bg-success rounded-circle text-white'
                  : 'mdi mdi-close bg-danger rounded-circle text-white'
              }
              description="Qualified With 3 Personally Sponsored Active Associates"
              title={data.three_active_sponsers ? 'YES' : 'NO'}
            >
              <p className="mb-0 text-uppercase font-12">
                <span className="badge badge-dark font-11 font-weight-semibold">
                  From 10,000 BP
                </span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-md-6 col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName={
                data.six_active_sponsers
                  ? 'cta-box bg-success-lighten mb-0 h-100'
                  : 'cta-box bg-danger-lighten mb-0 h-100'
              }
              icon={
                data.six_active_sponsers
                  ? 'mdi mdi-check bg-success rounded-circle text-white'
                  : 'mdi mdi-close bg-danger rounded-circle text-white'
              }
              description="Qualified With 6 Personally Sponsored Active Associates"
              title={data.six_active_sponsers ? 'YES' : 'NO'}
            >
              <p className="mb-0 text-uppercase font-12">
                <span className="badge badge-dark font-11 font-weight-semibold">
                  From 100,000 BP
                </span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-md-3 col-sm-6 mb-3">
            <StatisticsWidget
              // icon="mdi mdi-coins"
              bgclassName="mb-0 h-100"
              description="Fast Start Bonus"
              title={
                data.bonus
                  ? `${
                      Number.isNaN(Number(data.bonus.fsb))
                        ? data.bonus.fsb
                        : `${requestedCurrency}${data.bonus.fsb}`
                    }`
                  : '---'
              }
            />
          </div>
          <div className="col-md-3 col-sm-6 mb-3">
            <StatisticsWidget
              // icon="mdi mdi-cash"
              bgclassName="mb-0 h-100"
              description="Car Bonus"
              title={`${requestedCurrency}${
                data.bonus ? parseFloat(data.bonus.car).toFixed(2) : '---'
              }`}
            />
          </div>
          <div className="col-md-3 col-sm-6 mb-3">
            <StatisticsWidget bgclassName="mb-0 h-100">
              <>
                <h5 className="font-weight-semibold font-13 mt-0 mb-1 text-uppercase">
                  Cash Bonus
                </h5>
                <p className="mb-0 font-14 text-uppercase font-12">
                  <span className="badge badge-info">50% of Car Bonus</span>
                </p>
                <h4 className="mt-2 mb-0">
                  {`${requestedCurrency}${
                    data.bonus ? parseFloat(data.bonus.cash).toFixed(2) : '---'
                  }`}
                </h4>
              </>
            </StatisticsWidget>
          </div>
          <div className="col-md-3 col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName="bg-warning-lighten mb-0 h-100"
              // icon="mdi mdi-cash"
              description="Total Commission"
              title={`${requestedCurrency}${
                data.bonus
                  ? parseFloat(data.bonus.currency_total).toFixed(2)
                  : '---'
              }`}
            />
          </div>
        </div>
        <div className="row row-eq-height">
          <div className="col-md-12">
            <h5 className="mt-0 mb-3">
              Qualification for Applicable Incentives
            </h5>
          </div>
          <div className="col-md-6 col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName="cta-box mb-0 h-100"
              icon="mdi mdi mdi-subdirectory-arrow-left rounded-circle"
              description="Total Qualified BP In Largest Leg"
            >
              <p className="mb-0 mt-3 text-uppercase font-12">
                <span className="badge badge-primary-lighten font-11 font-weight-semibold">
                  60% Required - 700
                </span>
              </p>
            </StatisticsWidget>
          </div>
          <div className="col-md-6 col-sm-6 mb-3">
            <StatisticsWidget
              bgclassName="cta-box mb-0 h-100"
              icon="mdi mdi-subdirectory-arrow-right rounded-circle"
              description="Total Qualified BP Outside Largest Leg"
            >
              <p className="mb-0 mt-3 text-uppercase font-12">
                <span className="badge badge- badge-primary-lighten font-11 font-weight-semibold">
                  40% Required - 700
                </span>
              </p>
            </StatisticsWidget>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user,
  data: state.dashboard.dashboard,
  isLoading: state.dashboard.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDashboard: dashboardActions.getDashboard
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociateDash);
