const payout = {
  ASSOCIATE_PAYOUT_LIST_REQUEST: 'ASSOCIATE_PAYOUT_LIST_REQUEST',
  ASSOCIATE_PAYOUT_LIST_SUCCESS: 'ASSOCIATE_PAYOUT_LIST_SUCCESS',
  PAYOUT_GENERATE_LIST_REQUEST: 'PAYOUT_GENERATE_LIST_REQUEST',
  PAYOUT_GENERATE_LIST_SUCCESS: 'PAYOUT_GENERATE_LIST_SUCCESS',
  PAYOUT_GENERATE_LIST_ERROR: 'PAYOUT_GENERATE_LIST_ERROR',
  PAYOUT_APPROVE_REQUEST: 'PAYOUT_APPROVE_REQUEST',
  PAYOUT_APPROVE_SUCCESS: 'PAYOUT_APPROVE_SUCCESS',
  PAYOUT_APPROVE_ERROR: 'PAYOUT_APPROVE_ERROR',
  PAYOUT_PAID_REQUEST: 'PAYOUT_PAID_REQUEST',
  PAYOUT_PAID_SUCCESS: 'PAYOUT_PAID_SUCCESS',
  PAYOUT_PAID_ERROR: 'PAYOUT_PAID_ERROR',
  ADMIN_PAYOUT_ITEM_REQUEST: 'ADMIN_PAYOUT_ITEM_REQUEST',
  ADMIN_PAYOUT_ITEM_SUCCESS: 'ADMIN_PAYOUT_ITEM_SUCCESS',
  ADMIN_PAYOUT_LIST_REQUEST: 'ADMIN_PAYOUT_LIST_REQUEST',
  ADMIN_PAYOUT_LIST_SUCCESS: 'ADMIN_PAYOUT_LIST_SUCCESS',
  PAYOUT_STATEMENT_REQUEST: 'PAYOUT_STATEMENT_REQUEST',
  PAYOUT_STATEMENT_SUCCESS: 'PAYOUT_STATEMENT_SUCCESS',
  CLEAR_PAYOUT_ITEM: 'CLEAR_PAYOUT_ITEM',
  PAYOUT_DOWNLOAD_REQUEST: 'PAYOUT_DOWNLOAD_REQUEST',
  PAYOUT_SEND_PAYMENT_REQUEST: 'PAYOUT_SEND_PAYMENT_REQUEST',
  PAYOUT_SEND_PAYMENT_SUCCESS: 'PAYOUT_SEND_PAYMENT_SUCCESS',
  PAYOUT_SEND_PAYMENT_ERROR: 'PAYOUT_SEND_PAYMENT_ERROR',
  SHOW_HIDE_PAYOUT_MODAL: 'SHOW_HIDE_PAYOUT_MODAL',

  getAdminPayoutList: data => ({
    type: payout.ADMIN_PAYOUT_LIST_REQUEST,
    data
  }),
  getAdminPayoutItem: data => ({
    type: payout.ADMIN_PAYOUT_ITEM_REQUEST,
    data
  }),
  setPayoutPaid: data => ({ type: payout.PAYOUT_PAID_REQUEST, data }),
  getAssociatePayout: data => ({
    type: payout.ASSOCIATE_PAYOUT_LIST_REQUEST,
    data
  }),
  generatePayout: data => ({ type: payout.PAYOUT_GENERATE_LIST_REQUEST, data }),
  approvePayout: data => ({
    type: payout.PAYOUT_APPROVE_REQUEST,
    data,
    status: 1
  }),
  refusePayout: data => ({
    type: payout.PAYOUT_APPROVE_REQUEST,
    data,
    status: 0
  }),
  getPayoutById: id => ({ type: payout.PAYOUT_STATEMENT_REQUEST, id }),
  downloadPayoutInvoice: id => ({ type: payout.PAYOUT_DOWNLOAD_REQUEST, id }),
  showHidePayoutModal: data => ({ type: payout.SHOW_HIDE_PAYOUT_MODAL, data }),
  makePayoutPayment: data => ({
    type: payout.PAYOUT_SEND_PAYMENT_REQUEST,
    data
  })
};

export default payout;
