import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { storePaymentGateway as paymentGatewayActions } from '../../actions';
import AdvanceTable from '../../components/AdvanceTable';
import Icon from '../../components/Icon';
import DeleteAlert from '../../components/ConfirmAlert';

function PaymentGateway({
  paymentGatewayList,
  getStorePaymentGatewayList,
  deleteStorePaymentGateway,
  filterValues
}) {
  useEffect(() => {
    getStorePaymentGatewayList();
  }, [getStorePaymentGatewayList]);

  const [isShowModal, setIsShowModal] = useState(false);

  const [pickedPayment, setPickedPayment] = useState(null);

  const [page, setPage] = useState(1);

  const handleTableChange = (type, { currentPage, sizePerPage }) => {
    if (type === 'pagination') {
      getStorePaymentGatewayList({ currentPage, per_page: sizePerPage });
    }
    setPage(currentPage);
  };

  const handleDeletePaymentGateway = iso => {
    setIsShowModal(false);
    setPickedPayment(null);
    deleteStorePaymentGateway(iso);
  };

  const columns = [
    {
      dataField: 'country',
      text: 'Country',
      headerAttrs: { width: '250px' }
    },
    {
      dataField: 'active_mode',
      text: 'Gateway Name',
      formatter: col => {
        let fieldsArr = '';
        if (col && Array.isArray(col)) {
          col.forEach(item => {
            let gfullName = '';
            switch (item) {
              case 'ZP':
                gfullName = 'Zottopay';
                break;
              case 'QP':
                gfullName = 'Quickpay';
                break;
              default:
            }
            if (gfullName) {
              if (!fieldsArr) {
                fieldsArr = gfullName;
              } else {
                fieldsArr += `, ${gfullName}`;
              }
            }
          });
        }

        return <span>{fieldsArr}</span>;
      }
    },
    {
      dataField: 'action',
      isDummyField: true,
      text: 'Action',
      headerClasses: 'text-center',
      headerAttrs: { width: '15%' },
      classes: 'text-center',
      formatter: (_, row) => (
        <>
          <Link
            className="btn-link"
            to={`/setting/payment/edit/${row.country_iso2}`}
          >
            <Icon
              title="Edit"
              iconClassName=" mdi-square-edit-outline"
              isAction
              cursor="pointer"
            />
          </Link>
          <Link
            className="btn-link"
            to={`/setting/payment/add/${row.country_iso2}`}
          >
            <Icon
              title="Copy"
              iconClassName="mdi-content-copy"
              isAction
              cursor="pointer"
            />
          </Link>
          {/* <button
            type="button"
            className="btn btn-link p-0 pb-1"
            onClick={() => {
              setPickedPayment(row.country_iso2);
              setIsShowModal(true);
            }}
          >
            <Icon
              title="Delete"
              iconClassName="mdi-delete"
              isAction
              cursor="pointer"
            />
          </button> */}
        </>
      )
    }
  ];

  return (
    <>
      <div className="table-wrapper">
        <AdvanceTable
          data={paymentGatewayList.data || []}
          columns={columns}
          loading={false}
          sizePerPageList={filterValues}
          totalSize={paymentGatewayList.total}
          sizePerPage={paymentGatewayList.per_page}
          page={page}
          remote={{ pagination: true }}
          hideSizePerPage={false}
          onTableChange={handleTableChange}
        />
      </div>
      <DeleteAlert
        show={isShowModal}
        onConfirm={() => handleDeletePaymentGateway(pickedPayment)}
        onCancel={() => setIsShowModal(false)}
      />
    </>
  );
}

const MSTP = store => ({
  paymentGatewayList: store.storePaymentGateway.data,
  filterValues: store.app.settings.filter_values
});
const MDSTP = {
  getStorePaymentGatewayList: paymentGatewayActions.getStorePaymentGatewayList,
  deleteStorePaymentGateway: paymentGatewayActions.deleteStorePaymentGateway
};

export default connect(
  MSTP,
  MDSTP
)(PaymentGateway);
