import StorageService from '../services/storageService';

const initialState = {
  app: {
    countryList: [],
    stateList: [],
    cityList: [],
    categoryList: [],
    shippingList: [],
    taxList: [],
    imageStyle: {},
    settings: {
      site_title: 'MX4',
      def_currency: 'EUR',
      site_date_format: 'DD/MM/YYYY',
      site_def_lang: 'en',
      office_shipping_id: 0,
      filter_values: []
    },
    storeAddress: {},
    storeAddressCountryWise: {},
    storeCountries: {},
    companyAddress: {},
    shopCountry: StorageService.getShopCountry() || undefined,
    shopState: StorageService.getShopState() || undefined,
    countryCurrencies: {}
    // shopCountry: undefined,
    // shopState: undefined
  },
  dashboard: {
    bp: 0,
    dashboard: {},
    isLoading: false,
    isUserSummaryLoading: false,
    userSummary: {}
  },
  auth: {
    user: {},
    refUserId: '',
    refUserChecked: false,
    isAdmin: false,
    loginProgress: false,
    package: false,
    registrationProgress: false,
    userInfoProgress: true,
    forgotPasswordSuccess: false,
    forgotPasswordProgress: false,
    resetPasswordProgress: false,
    changePasswordProgress: false,
    subscriptionStatus: null,
    subscriptionCalculation: {},
    subscriptionShippingOptions: {},
    subscriptionStatusProgress: true,
    shippingStateList: [],
    shippingCityList: [],
    calculationProgress: false
  },
  associate: {
    isLoading: false,
    createProgress: false,
    list: {
      data: []
    }
  },
  customer: {
    isLoading: false,
    associatesCustomer: {
      data: []
    },
    allCustomer: {
      data: []
    }
  },
  tree: {
    isLoading: false,
    list: [],
    allList: [],
    associateUsers: [],
    loadingAssociate: false
  },
  // for loading customer downline
  customerTree: {
    isLoading: false,
    list: [],
    allList: [],
    customers: [],
    loadingCustomer: false
  },
  packages: {
    isLoading: false,
    createProgress: false,
    list: [],
    allList: []
  },
  payout: {
    isLoading: false,
    createProgress: false,
    assocMonthlist: {
      data: []
    },
    details: {},
    adminPayoutItem: {
      data: []
    },
    adminList: {
      data: []
    },
    paymentProcessing: false,
    payoutModal: false
  },
  paymentGateway: {
    isLoading: false,
    createProgress: false,
    list: [],
    allList: [
      {
        id: 1,
        title: 'PayPal',
        mode: 1,
        status: 1
      },
      {
        id: 2,
        title: 'RazorPay',
        mode: 0,
        status: 0
      }
    ]
  },
  category: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    categoryDetails: {},
    imageFile: null,
    updateProgress: false
  },
  product: {
    isLoading: false,
    isLoadingSingle: false,
    createProgress: false,
    isLoadingTax: false,
    taxes: [],
    allList: {
      data: []
    },
    updateProgress: false
  },
  permission: {
    roles: {
      data: []
    },
    isLoadingRoles: false,
    users: {
      data: []
    },
    isLoadingUsers: false,
    allPermissions: [],
    isProcessingPermissions: false,
    rolePermission: {
      module: [],
      permission: {}
    },
    isLoadingPermissions: false
  },
  generation: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  rank: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  fsb: {
    isLoading: false,
    plan: {},
    updateProgress: false,
    isLoadingPerformance: false,
    performance: {},
    isLoadingPerformanceDetails: false,
    performanceDetails: []
  },
  associateFsb: {
    isLoading: false,
    plan: {}
  },
  tax: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  notification: [],
  address: {
    isLoading: false,
    createProgress: false,
    list: [],
    editAddress: {},
    updateProgress: false,
    modal: false,
    alert: false
  },
  paymentOption: {
    isLoading: false,
    isCardLoading: false,
    isBankLoading: false,
    inProgress: false,
    cardList: {
      data: []
    },
    bankList: {
      data: []
    },
    editPaymentOption: {},
    modal: false,
    alert: false
  },
  profile: {
    isLoading: false,
    data: [],
    updateProgress: false,
    updateImageProgress: false,
    isReferralProfileValid: false
  },
  template: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  shop: {
    isLoadingList: false,
    isLoadingDetails: false,
    productDetails: {},
    list: {
      data: []
    },
    categoryList: [],
    categoryLoading: false
  },
  cart: {
    cartId: null,
    cartDetails: {},
    cartCalculation: {},
    calculationProgress: false,
    cartShippingOptions: {},
    loadingreviseCart: false
  },
  shipping: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  setting: {
    isLoading: false,
    allList: {
      data: []
    },
    data: {},
    createProgress: false,
    updateProgress: false
  },
  media: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false,
    isScanning: false,
    allFileList: []
  },
  coupons: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    allCouponOrder: {
      data: []
    },
    allOrder: {
      data: []
    },
    updateProgress: false,
    modal: false,
    alert: false
  },
  location: {
    isLoading: false,
    createProgress: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  singleLocation: {
    isLoading: false,
    data: {}
  },
  loginHistory: {
    isLoading: false,
    allList: {
      data: []
    },
    updateProgress: false
  },
  order: {
    isLoading: false,
    isSummaryLoading: false,
    isLoadingSingle: false,
    summary: {},
    allList: {
      data: [],
      order_summary: {}
    },
    tnxDetails: {}
  },
  checkout: {
    progress: false,
    zottopayModal: false
  },
  currency: {
    isLoading: false,
    currencies: []
  },
  storeAddress: {
    data: {
      country_settings: {},
      country_store: {}
    },
    countries: [],
    isLoading: false
  },
  storePaymentGateway: {
    data: {
      country_settings: {
        country: '',
        country_iso2: '',
        id: 17,
        payment_gateway: []
      }
    },
    isLoading: false
  }
};

export default initialState;
