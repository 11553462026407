import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';

import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';
import GeneralForm from './GeneralForm';
import { setting as settingActions } from '../../actions';
import InnerLoader from '../../components/InnerLoader';

class General extends React.Component {
  componentDidMount() {
    const { getSetting } = this.props;
    getSetting('general');
  }

  handleSubmit = values => {
    const { updateSetting } = this.props;
    const formFields = [
      'site_title',
      'site_desc',
      'site_url',
      'site_email',
      'site_def_lang',
      'site_date_format',
      'site_timezone',
      'display_admin_cat',
      'display_admin_products',
      'display_admin_orders',
      'show_cat',
      'show_products',
      'show_orders',
      'invoice_details'
    ];
    const fieldValues = [];
    _.forEach(values, (val, field) => {
      if (formFields.indexOf(field) > -1) {
        fieldValues.push({
          key: field,
          value: val,
          options: 'general'
        });
      }
    });
    updateSetting({ settings: fieldValues });
  };

  render() {
    const { settings, isLoading, updateProgress } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.generalSetting.breadcrumb} />
              <h4 className="page-title">{routePath.generalSetting.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                {isLoading && <InnerLoader type="table" />}
                {!isLoading && (
                  <GeneralForm
                    submitForm={this.handleSubmit}
                    inProgress={updateProgress}
                    initialValues={settings}
                    enableReinitialize="true"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.setting.isLoading,
  updateProgress: state.setting.updateProgress,
  settings: state.setting.data
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateSetting: settingActions.updateSetting,
      getSetting: settingActions.getSetting
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(General);
