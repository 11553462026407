import React from 'react';

function FsbPhase({ phases }) {
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-centered table-sm no-width">
        <thead className="table-light">
          <tr>
            <th width="40%">Phases</th>
            <th className="text-center">BP</th>
            <th className="text-center">Payout</th>
          </tr>
        </thead>
        <tbody>
          {phases.map(phase => (
            <tr key={phase.id}>
              <td>{phase.title}</td>
              <td className="text-center">{phase.bp_target}</td>
              <td className="text-center">{phase.payout}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default FsbPhase;
