const fsb = {
  FSB_UPDATE_REQUEST: 'FSB_UPDATE_REQUEST',
  FSB_UPDATE_SUCCESS: 'FSB_UPDATE_SUCCESS',
  FSB_UPDATE_ERROR: 'FSB_UPDATE_ERROR',
  FSB_REQUEST: 'FSB_REQUEST',
  FSB_SUCCESS: 'FSB_SUCCESS',
  FSB_ERROR: 'FSB_ERROR',
  FSB_PERFORMANCE_LIST_REQUEST: 'FSB_PERFORMANCE_LIST_REQUEST',
  FSB_PERFORMANCE_LIST_SUCCESS: 'FSB_PERFORMANCE_LIST_SUCCESS',
  FSB_PERFORMANCE_LIST_ERROR: 'FSB_PERFORMANCE_LIST_ERROR',
  FSB_PERFORMANCE_DETAILS_REQUEST: 'FSB_PERFORMANCE_DETAILS_REQUEST',
  FSB_PERFORMANCE_DETAILS_SUCCESS: 'FSB_PERFORMANCE_DETAILS_SUCCESS',
  FSB_PERFORMANCE_DETAILS_ERROR: 'FSB_PERFORMANCE_DETAILS_ERROR',
  ADMIN_FSB_UPDATE_REQUEST: 'ADMIN_FSB_UPDATE_REQUEST',
  ADMIN_FSB_UPDATE_SUCCESS: 'ADMIN_FSB_UPDATE_SUCCESS',
  ADMIN_FSB_UPDATE_ERROR: 'ADMIN_FSB_UPDATE_ERROR',
  updateFsb: data => ({ type: fsb.FSB_UPDATE_REQUEST, data }),
  getFsb: id => ({ type: fsb.FSB_REQUEST, id }),
  getFsbPerformanceList: params => ({
    type: fsb.FSB_PERFORMANCE_LIST_REQUEST,
    params
  }),
  getFsbPerformanceDetails: id => ({
    type: fsb.FSB_PERFORMANCE_DETAILS_REQUEST,
    id
  }),
  adminUpdateFsb: data => ({
    type: fsb.ADMIN_FSB_UPDATE_REQUEST,
    id: data.id,
    data: data.data
  })
};

export default fsb;
