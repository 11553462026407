import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ImageGallery from 'react-image-gallery';
import { Tab, Nav } from 'react-bootstrap';
import BreadCrumb from '../../components/BreadCrumb';
import InnerLoader from '../../components/InnerLoader';
import { shop as shopActions, cart as cartActions } from '../../actions';
import routePath from '../../constants/routePath';
import { productImage } from '../../constants/imageSize';
import { getImageUrl, insertItem } from '../../utills/custom';
import NoResult from '../../components/NoResult';
import AddToCartButton from './AddToCartButton';

class ProductDetails extends React.Component {
  state = {
    cartQty: 1
  };

  componentDidMount() {
    const { match, shopCountry, shopState, getProductDetails } = this.props;
    if (match.params && match.params.slug) {
      getProductDetails({
        slug: match.params.slug,
        country: shopCountry,
        state: shopState
      });
    }
  }

  handleCartQty = e => {
    if (e.target.value >= 1) {
      this.setState({ cartQty: e.target.value });
    }
  };

  handleChangeQty = type => {
    const { cartQty } = this.state;
    let currQty = +cartQty;
    if (type === '-' && currQty > 1) currQty -= 1;
    if (type === '+') currQty += 1;
    this.setState({ cartQty: currQty });
  };

  handleAddToCart = () => {
    const {
      match,
      productDetails,
      cartId,
      addToCart,
      shopCountry,
      shopState
    } = this.props;
    const { cartQty } = this.state;
    if (cartId && cartQty && shopCountry) {
      const product = productDetails[match.params.slug];
      addToCart({
        cart_id: cartId,
        product_id: product.id,
        quantity: cartQty,
        country: shopCountry,
        state: shopState
      });
    }
  };

  processImages = (images, title) => {
    const { imageStyle } = this.props;
    return images.map(image => ({
      original: `${process.env.REACT_APP_IMAGE_URL}/${getImageUrl(
        image.file.file_path,
        imageStyle[productImage.productGridMedium]
      )}`,
      thumbnail: `${process.env.REACT_APP_IMAGE_URL}/${getImageUrl(
        image.file.file_path,
        imageStyle[productImage.productGridThumb]
      )}`,
      originalAlt: title,
      thumbnailAlt: title
    }));
  };

  render() {
    const {
      productDetails,
      match,
      loading,
      shopCountry,
      countryCurrencies
    } = this.props;
    const { cartQty } = this.state;
    let data = null;
    if (match.params && match.params.slug) {
      data = productDetails[match.params.slug];
    }
    const currency = countryCurrencies[shopCountry]
      ? countryCurrencies[shopCountry].currency_symbol
      : '';

    // BreadCrumb
    let breadCrumbList = routePath.shopProduct.breadcrumb;
    if (data && data.category && data.category.length) {
      breadCrumbList = insertItem(routePath.shopProduct.breadcrumb, {
        index: 1,
        item: {
          path: `/shop/${data.category[0].slug}`,
          title: data.category[0].title
        }
      });
    }

    return (
      <React.Fragment>
        {loading ? (
          <div className="mt-5">
            <InnerLoader type="table" />
          </div>
        ) : (
          <>
            <div className="row mb-2">
              <div className="col-12">
                <div className="page-title-box">
                  <BreadCrumb list={breadCrumbList} />
                  <h4 className="page-title">{data && data.title}</h4>
                </div>
              </div>
            </div>
            {data ? (
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-5 mb-3">
                          {data.product_images.length ? (
                            <ImageGallery
                              items={this.processImages(
                                data.product_images,
                                data.title
                              )}
                              showPlayButton={false}
                              useBrowserFullscreen={false}
                              showThumbnails={
                                data.product_images &&
                                data.product_images.length > 1
                              }
                            />
                          ) : (
                            <NoResult
                              icon="mdi mdi-image-broken-variant"
                              title="No images"
                              body="This product currently has no images."
                            />
                          )}
                        </div>
                        <div className="col-lg-7">
                          <form className="pl-lg-4">
                            <h3 className="mt-0">{data.title}</h3>
                            <h6 className="font-14">{`SKU: ${data.sku}`}</h6>
                            <span className="badge badge-light text-success font-11">
                              B-POINT: {data.bpoint}
                            </span>
                            {/* <span className="badge badge-dark font-11">
                              B-POINT:
                              {' '}
                              {data.bpoint}
                            </span> */}
                            <p className="font-16 d-none">
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                              <span className="text-warning mdi mdi-star" />
                            </p>
                            <div className="mt-3">
                              {/* <h5 className="text-strikeout font-weight-normal">{`${data.regular_price}`}</h5> */}
                              <h3 className="text-primary">
                                {`${currency}${
                                  data.taxable
                                    ? data.member_price_vat
                                    : data.member_price
                                } `}
                                {data.taxable && (
                                  <span className="badge badge-light text-muted font-10">
                                    incl. VAT
                                  </span>
                                )}
                              </h3>
                            </div>
                            <div className="mt-4">
                              <h6 className="font-14">Quantity</h6>
                              <div className="row">
                                <div className="col-12 mb-3 mb-sm-0 col-sm-3 col-md-3">
                                  <div className="input-group input-qty">
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={() =>
                                          this.handleChangeQty('-')
                                        }
                                      >
                                        -
                                      </button>
                                    </div>
                                    <input
                                      type="number"
                                      min="1"
                                      value={cartQty}
                                      onChange={this.handleCartQty}
                                      className="form-control no-spinner p-0 text-center"
                                      placeholder="Qty"
                                    />
                                    <div className="input-group-append">
                                      <button
                                        type="button"
                                        className="btn btn-light"
                                        onClick={() =>
                                          this.handleChangeQty('+')
                                        }
                                      >
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-4 col-md-5">
                                  <AddToCartButton
                                    className="btn btn-danger"
                                    onClick={this.handleAddToCart}
                                    disabled={!cartQty}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-4">
                              {/* <h6 className="font-14">Description:</h6> */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.excerpt
                                }}
                              />
                            </div>
                            {/* <div className="mt-4">
                              <div className="row">
                                <div className="col-md-4">
                                  <h6 className="font-14">Available Stock:</h6>
                                  <p className="text-sm lh-150">{parseInt(data.stock_qty, 10)}</p>
                                </div>
                              </div>
                            </div> */}
                          </form>
                        </div>
                      </div>

                      <div className="mt-4">
                        <Tab.Container
                          id="details-tabs"
                          defaultActiveKey="description"
                        >
                          <Nav className="nav nav-tabs nav-bordered mb-2 no-rounded">
                            <Nav.Item>
                              <Nav.Link eventKey="description">
                                Description
                              </Nav.Link>
                            </Nav.Item>
                            {/* <Nav.Item>
                              <Nav.Link eventKey="specifications">Specifications</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="reviews">Reviews</Nav.Link>
                            </Nav.Item> */}
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="description" className="p-2">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: data.description
                                }}
                              />
                            </Tab.Pane>
                            {/* <Tab.Pane eventKey="specifications" className="p-2" />
                            <Tab.Pane eventKey="reviews" className="p-2" /> */}
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <p>404 Page</p>
            )}
          </>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  productDetails: state.shop.productDetails,
  loading: state.shop.isLoadingDetails,
  cartId: state.cart.cartId,
  imageStyle: state.app.imageStyle,
  shopCountry: state.app.shopCountry,
  shopState: state.app.shopState,
  countryCurrencies: state.app.countryCurrencies
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getProductDetails: shopActions.getProductDetails,
      addToCart: cartActions.addToCart
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ProductDetails)
);
