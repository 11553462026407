const media = {
  MEDIA_ALL_LIST_REQUEST: 'MEDIA_ALL_LIST_REQUEST',
  MEDIA_ALL_LIST_SUCCESS: 'MEDIA_ALL_LIST_SUCCESS',
  MEDIA_ALL_LIST_ERROR: 'MEDIA_ALL_LIST_ERROR',
  MEDIA_CREATE_REQUEST: 'MEDIA_CREATE_REQUEST',
  MEDIA_CREATE_SUCCESS: 'MEDIA_CREATE_SUCCESS',
  MEDIA_CREATE_ERROR: 'MEDIA_CREATE_ERROR',
  MEDIA_SETTING_UPDATE_REQUEST: 'MEDIA_SETTING_UPDATE_REQUEST',
  MEDIA_SETTING_UPDATE_SUCCESS: 'MEDIA_SETTING_UPDATE_SUCCESS',
  MEDIA_SETTING_UPDATE_ERROR: 'MEDIA_SETTING_UPDATE_ERROR',
  MEDIA_UPDATE_REQUEST: 'MEDIA_UPDATE_REQUEST',
  MEDIA_UPDATE_SUCCESS: 'MEDIA_UPDATE_SUCCESS',
  MEDIA_UPDATE_ERROR: 'MEDIA_UPDATE_ERROR',
  MEDIA_REQUEST: 'MEDIA_REQUEST',
  MEDIA_SUCCESS: 'MEDIA_SUCCESS',
  ALL_FILE_REQUEST: 'ALL_FILE_REQUEST',
  ALL_FILE_SUCCESS: 'ALL_FILE_SUCCESS',
  getAllMedias: data => ({
    type: media.MEDIA_ALL_LIST_REQUEST,
    data
  }),
  createMedia: data => ({ type: media.MEDIA_CREATE_REQUEST, data }),
  updateMediaSetting: data => ({
    type: media.MEDIA_SETTING_UPDATE_REQUEST,
    data
  }),
  updateMedia: data => ({ type: media.MEDIA_UPDATE_REQUEST, data }),
  getMediaById: id => ({ type: media.MEDIA_REQUEST, id }),
  getAllMedia: params => ({ type: media.ALL_FILE_REQUEST, params })
};

export default media;
