import React, { useRef, useState } from 'react';
import { Overlay, Tooltip as BootstrapTooltip, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Tooltip = ({ position, data }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <div className="ml-1">
      <Link
        to="/"
        className="dashed-underline"
        ref={target}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        View All
      </Link>
      <Overlay target={target.current} show={show} placement={position}>
        {props => <BootstrapTooltip {...props}>{data}</BootstrapTooltip>}
      </Overlay>
    </div>
  );
};

export default Tooltip;
