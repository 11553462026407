const storePaymentGateway = {
  STORE_PAYMENT_GATEWAY_LIST_REQUEST: 'STORE_PAYMENT_GATEWAY_LIST_REQUEST',
  STORE_PAYMENT_GATEWAY_LIST_SUCCESS: 'STORE_PAYMENT_GATEWAY_LIST_SUCCESS',
  STORE_PAYMENT_GATEWAY_LIST_ERROR: 'STORE_PAYMENT_GATEWAY_LIST_ERROR',
  STORE_PAYMENT_GATEWAY_REQUEST: 'STORE_PAYMENT_GATEWAY_REQUEST',
  STORE_PAYMENT_GATEWAY_SUCCESS: 'STORE_PAYMENT_GATEWAY_SUCCESS',
  STORE_PAYMENT_GATEWAY_ERROR: 'STORE_PAYMENT_GATEWAY_ERROR',
  STORE_CREATE_PAYMENT_GATEWAY_REQUEST: 'STORE_CREATE_PAYMENT_GATEWAY_REQUEST',
  STORE_CREATE_PAYMENT_GATEWAY_SUCCESS: 'STORE_CREATE_PAYMENT_GATEWAY_SUCCESS',
  STORE_CREATE_PAYMENT_GATEWAY_ERROR: 'STORE_CREATE_PAYMENT_GATEWAY_ERROR',
  STORE_UPDATE_PAYMENT_GATEWAY_REQUEST: 'STORE_UPDATE_PAYMENT_GATEWAY_REQUEST',
  STORE_UPDATE_PAYMENT_GATEWAY_SUCCESS: 'STORE_UPDATE_PAYMENT_GATEWAY_SUCCESS',
  STORE_UPDATE_PAYMENT_GATEWAY_ERROR: 'STORE_UPDATE_PAYMENT_GATEWAY_ERROR',
  STORE_DELETE_PAYMENT_GATEWAY_REQUEST: 'STORE_DELETE_PAYMENT_GATEWAY_REQUEST',
  STORE_DELETE_PAYMENT_GATEWAY_SUCCESS: 'STORE_DELETE_PAYMENT_GATEWAY_SUCCESS',
  STORE_DELETE_PAYMENT_GATEWAY_ERROR: 'STORE_DELETE_PAYMENT_GATEWAY_ERROR',

  getStorePaymentGatewayList: () => ({
    type: storePaymentGateway.STORE_PAYMENT_GATEWAY_LIST_REQUEST
  }),
  getStorePaymentGateway: data => ({
    type: storePaymentGateway.STORE_PAYMENT_GATEWAY_REQUEST,
    data
  }),
  createStorePaymentGateway: data => ({
    type: storePaymentGateway.STORE_CREATE_PAYMENT_GATEWAY_REQUEST,
    data
  }),
  updateStorePaymentGateway: data => ({
    type: storePaymentGateway.STORE_UPDATE_PAYMENT_GATEWAY_REQUEST,
    data
  }),
  deleteStorePaymentGateway: data => ({
    type: storePaymentGateway.STORE_DELETE_PAYMENT_GATEWAY_REQUEST,
    data
  })
};

export default storePaymentGateway;
