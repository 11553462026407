import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import jsonToFormData from 'json-form-data';
import {
  category as categoryActionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllCategory(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category`,
      method: 'GET',
      params: actions.data
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCategory(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createCategory(actions) {
  try {
    const fd = jsonToFormData(actions.data, {
      initialFormData: new FormData()
    });
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category/add`,
      method: 'POST',
      headers: { 'content-type': 'multipart/form-data' },
      data: fd,
      successMessage: 'Category was addedd successfully'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: routePath.category.path
      });
    }
  } catch (error) {
    yield put({ type: categoryActionTypes.CATEGORY_CREATE_ERROR, data: error });
  }
}

function* updateCategory(actions) {
  try {
    const fd = jsonToFormData(actions.data.data, {
      initialFormData: new FormData()
    });
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/category/update/${actions.data.id}`,
      method: 'POST',
      data: fd,
      successMessage: 'Category updated successfully'
    });
    if (responseJson) {
      yield put({
        type: categoryActionTypes.CATEGORY_UPDATE_SUCCESS,
        data: responseJson
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.category.path
        });
      }
    }
  } catch (error) {
    yield put({ type: categoryActionTypes.CATEGORY_UPDATE_ERROR, data: error });
  }
}

function* watchPackageListAll() {
  yield takeLatest(
    categoryActionTypes.CATEGORY_ALL_LIST_REQUEST,
    getAllCategory
  );
}

function* watchGetCategory() {
  yield takeLatest(categoryActionTypes.CATEGORY_REQUEST, getCategory);
}

function* watchCategoryCreate() {
  yield takeLatest(categoryActionTypes.CATEGORY_CREATE_REQUEST, createCategory);
}

function* watchCategoryUpdate() {
  yield takeLatest(categoryActionTypes.CATEGORY_UPDATE_REQUEST, updateCategory);
}

export default [
  fork(watchPackageListAll),
  fork(watchCategoryCreate),
  fork(watchCategoryUpdate),
  fork(watchGetCategory)
];
