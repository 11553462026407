import React from 'react';
import moment from 'moment';
import { Field, Form, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectField, dateField } from './Fields';
import { required } from '../utills/validator';
import { fsb as fsbActions } from '../actions';

function FsbViewForm({ handleSubmit, adminUpdateFsb, id, onCancel }) {
  const submitForm = values => {
    const newValues = { ...values };
    // Change the date format
    newValues.start_date = moment(newValues.start_date, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    newValues.end_date = moment(newValues.end_date, 'DD/MM/YYYY').format(
      'YYYY-MM-DD'
    );
    adminUpdateFsb({
      id,
      data: newValues
    });
  };

  return (
    <Form
      className="needs-validation "
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <div className="row align-items-center">
        <div className="col-6 col-sm-4">
          <Field
            name="active_status"
            id="active_status"
            component={selectField}
            label="Active"
            options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]}
            validate={[required]}
          />
        </div>
        <div className="col-6 col-sm-4">
          <Field
            name="achieve_status"
            id="achieve_status"
            component={selectField}
            label="Achieved"
            options={[{ label: 'Yes', value: 1 }, { label: 'No', value: 0 }]}
            validate={[required]}
          />
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-6 col-sm-4">
          <Field
            name="start_date"
            component={dateField}
            type="text"
            className="form-control"
            label="Start Date"
          />
        </div>
        <div className="col-6 col-sm-4">
          <Field
            name="end_date"
            component={dateField}
            type="text"
            className="form-control"
            label="End Date"
          />
        </div>
        <div className="col-12 col-sm-4 text-right mt-2">
          <button
            type="button"
            className="btn btn-outline-primary btn-sm mr-1"
            onClick={onCancel}
          >
            <span>Cancel</span>
          </button>
          <button type="submit" className="btn btn-primary btn-sm">
            Save
          </button>
        </div>
      </div>
    </Form>
  );
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      adminUpdateFsb: fsbActions.adminUpdateFsb
    },
    dispatch
  );

const FsbViewFormWithForm = reduxForm({
  form: 'fsbViewForm' // a unique identifier for this form
})(FsbViewForm);

export default connect(
  null,
  mapDispatchToProps
)(FsbViewFormWithForm);
