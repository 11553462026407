import React, { useEffect } from 'react';

const Redirect = props => {
  useEffect(() => {
    const { match } = props;
    window.localStorage.setItem('refUUId', match.params.refId);
    window.location.href = process.env.REACT_APP_MAIN_URL;
  }, []);

  return null;
};

export default Redirect;
