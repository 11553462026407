import React from 'react';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { required, email } from '../../utills/validator';
import {
  inputField,
  inputFieldControlled,
  textareaField,
  colorField
} from '../../components/Fields';
import { template as templateActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class TemplateSettingForm extends React.Component {
  componentDidMount() {
    const { getAllTemplates } = this.props;
    // getAllTemplates();
  }

  handleColorChange = color => {
    const { change } = this.props;
    change('color', color);
  };

  render() {
    const { handleSubmit, submitForm, inProgress } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <Field
              name="title"
              component={inputFieldControlled}
              type="text"
              className="form-control"
              label="From name"
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <Field
              name="slug"
              component={inputField}
              type="email"
              className="form-control"
              label="From address"
              validate={[required, email]}
            />
          </div>
        </div>
        <h4>Email Template</h4>
        <p className="text-muted font-12">
          This section lets you customize the email templates properties
        </p>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Field
              name="header_image"
              component={inputField}
              type="text"
              className="form-control"
              label="Header image"
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-12">
            <Field
              name="header_footer"
              component={textareaField}
              label="Footer text"
              className="form-control"
              /* validate={[required]} */
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="base_color"
              component={colorField}
              type="color"
              className="form-control"
              label="Base color"
              /* validate={[required]} */
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="back_color"
              component={colorField}
              type="color"
              className="form-control"
              label="Background color"
              /* validate={[required]} */
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="body_color"
              component={colorField}
              type="color"
              className="form-control"
              label="Body background color"
              /* validate={[required]} */
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-3">
            <Field
              name="text_color"
              component={colorField}
              type="color"
              className="form-control"
              label="Body text color"
              /* validate={[required]} */
            />
          </div>
          <div className="col-xs-12 col-md-12 mt-2">
            <button type="button" className="btn btn-light mr-2">
              Cancel
            </button>
            <button
              type="submit"
              disabled={inProgress}
              className="btn btn-primary"
            >
              {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
            </button>
          </div>
        </div>
      </Form>
    );
  }
}

const selector = formValueSelector('templateSettingForm');

const mapStateToProps = state => ({
  templates: state.template.allList,
  isLoading: state.template.isLoading
  // sendType: selector(state, 'sendType')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllTemplates: templateActions.getAllTemplates
    },
    dispatch
  );

const TemplateSettingReduxForm = reduxForm({
  form: 'templateSettingForm' // a unique identifier for this form
})(TemplateSettingForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateSettingReduxForm);
