import React from 'react';
import { Treant } from 'ngx-treantjs';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import InnerLoader from '../../components/InnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import TreeNav from './TreeNav';
import NodeViewModal from './NodeViewModal';
import { customerTree as treeActions } from '../../actions';
import avatar from '../../assets/images/avatar.svg';
import routePath from '../../constants/routePath';
import { getImageUrl } from '../../utills/custom';
import { profileImage } from '../../constants/imageSize';
import TreeFilterForm from './TreeFilterForm';

const chart = {
  container: '#assoc_tree',
  connectors: {
    type: 'step',
    style: {
      stroke: '#727cf5'
    }
  },
  node: {
    HTMLclass: 'nodeExample1'
  }
};

class Tree extends React.Component {
  selectedMonth = null;

  selectedAssoc = null;

  state = {
    page: 0,
    limit: 4,
    search: '',
    totalPages: 0,
    referrals: 0,
    parentId: false,
    refId: false,
    modal: false,
    details: false
  };

  listById = {};

  componentDidMount() {
    const { getCustomerTree, userInfo } = this.props;
    getCustomerTree();
    this.setState({ refId: userInfo.id });
    document.addEventListener('click', this.handleNodeActions, true);
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoading } = this.props;
    const { page, search } = this.state;
    if (
      (prevProps.isLoading !== isLoading && !isLoading) ||
      (!isLoading && prevState.page !== page) ||
      (!isLoading && prevState.search !== search)
    ) {
      const { list } = this.props;
      if (list.sponsor.data.length > 0) {
        const nodeStructure = this.drawTree(list.sponsor.data[0]);
        // eslint-disable-next-line no-new
        new Treant({
          chart,
          nodeStructure
        });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleNodeActions, true);
  }

  drawTree = item => {
    const { page, limit } = this.state;
    const { imageStyle } = this.props;

    let children = [];
    if (item.customers && item.customers.data && item.customers.data.length) {
      let referralsChunk = item.customers.data;
      if (item.requested_node) {
        referralsChunk = item.customers.data.slice(
          page * limit,
          page * limit + limit
        );
        this.initTreePagination(item); // only for level 1
      }
      children = referralsChunk.map(refItem => {
        this.listById[refItem.id] = refItem;
        return this.drawTree(refItem);
      });
    }

    const isDisabled =
      item.requested_node || (item.referrals && item.referrals_count === 0);
    let actions = '';

    if (!item.sponsor_node) {
      actions = `<div class="col-5">
      <button type="button" class="btn btn-sm btn-link btn-block btn-light border-right rounded-0 node-details" data-id="${
        item.id
      }"><i class="mdi mdi-plus-circle pointer"></i> Details</button></div>
      <div class="col-7"><button type="button" class="btn btn-sm btn-link btn-block btn-light rounded-0 ${
        isDisabled ? 'disabled text-dark' : 'expand-tree'
      }" data-id="${item.id}">${
        !item.requested_node
          ? `<i class="mdi mdi-menu-down pointer"></i> Expand (${item.referrals_count})`
          : `<i class="mdi mdi-menu-up pointer"></i> Collapse (${item.referrals_count})`
      }</button></div>`;
    } else if (item.requested_node) {
      actions = `<div class="col-5">
      <button disabled type="button" class="btn btn-sm btn-link btn-block btn-light border-right rounded-0 node-details" >
        <i class="mdi mdi-plus-circle pointer"></i> Details
      </button>
      </div>
      <div class="col-7"><button type="button" class="btn btn-sm btn-link btn-block btn-light rounded-0 disabled text-dark">
        <i class="mdi mdi-menu-up pointer"></i> Collapse (${item.customers_count})
      </button>
    </div>`;
    }

    const op = item.own_purchase || 0;
    const cp = item.customer_purchase || 0;

    let pImage = get(item, 'profile_picture', null);
    let imageUrl = avatar;
    if (pImage) {
      pImage = `${process.env.REACT_APP_IMAGE_URL}/${pImage}`;
      if (
        !/placeholder/gi.test(pImage) &&
        !/avatar/gi.test(pImage) &&
        imageStyle[profileImage.profileThumbnail]
      ) {
        imageUrl = getImageUrl(
          pImage,
          imageStyle[profileImage.profileThumbnail]
        );
      }
    }

    let headerHtml = '';
    const getBg = customerType => {
      if (customerType) {
        if (customerType === 'sponsor_customer') return 'bg-warning';
        if (customerType === 'direct_customer') return 'bg-dark';
        // referred_customer
        return 'bg-info';
      }
      // not a customer
      return 'bg-success';
    };
    const totalCP = item.total_cpoints || '0.00';
    if (item.sponsor_node) {
      headerHtml = `<div class="tree-item-header text-center text-uppercase text-light bg-success font-11">Sponsor</div>`;
    } else {
      headerHtml = `<div class="tree-item-header text-right ${getBg(
        item.type
      )} text-light font-11">TOTAL CP: ${totalCP}</div>`;
    }

    return {
      innerHTML: `${headerHtml}<div class="tree-item-body"><div class="row no-gutters"><div class="col-4"><img src=${imageUrl} class="rounded-circle" /></div><div class="col-8"><p class="text-right mb-0 float-right"><span class="flag-icon flag-icon-${item.customer_info &&
        item.customer_info.country &&
        item.customer_info.country.toLowerCase()}"></span></p><p class="node-name mb-0 mt-2 text-uppercase font-11">${
        item.first_name
      } ${item.last_name}</p><p class="node-id mb-1 font-11">ID: ${
        item.uuid
      }</p></div></div></div><div class="tree-item-footer"><div class="row no-gutters tree-item-footer">${actions}</div></div>`,
      image: avatar,
      children
    };
  };

  initTreePagination = item => {
    const { limit } = this.state;
    const dPages = Math.floor(item.customers.data.length / limit);
    this.setState({
      totalPages:
        dPages < item.customers.data.length / limit ? dPages + 1 : dPages,
      referrals: item.customers.data.length,
      refId: item.id,
      parentId: item.referrer_cust_id ? item.referrer_cust_id : item.referrer_id
    });
  };

  nodePagination = action => {
    const { page, totalPages } = this.state;
    const currPage = page;
    if (action === 'next' && page < totalPages - 1) {
      this.setState({ page: page + 1 });
    } else if (action === 'back' && page > 0) {
      this.setState({ page: page - 1 });
    }
  };

  handleNodeActions = event => {
    const e = event || window.event;
    const element = e.target || e.srcElement;

    if (element.matches('.expand-tree')) {
      // console.log('I am called!!');
      const id = element.getAttribute('data-id');
      this.selectedAssoc = id;
      this.makeAssocCall(this.selectedAssoc, this.selectedMonth);
    }
    if (element.matches('.node-details')) {
      // console.log('I am called!!');
      const id = element.getAttribute('data-id');
      this.setState({ details: this.listById[id], modal: true });
    }
  };

  handleModal = () => {
    this.setState(a => ({ modal: !a.modal, details: false }));
  };

  makeAssocCall = (input, selectedMonth) => {
    const { getCustomerTree, userInfo } = this.props;
    const options = {};

    if (input) {
      options.user_id = input;
    }

    if (selectedMonth && selectedMonth.value) {
      const [mm, yy] = selectedMonth.value.split(',');
      options.mm = mm;
      options.yy = yy;
    }

    this.setState({ page: 0 });
    getCustomerTree(options);
  };

  handleFilter = (input, selectedMonth) => {
    const { userInfo } = this.props;

    if (input) {
      this.selectedAssoc = input.value;
    } else {
      this.selectedAssoc = '';
    }

    this.selectedMonth = selectedMonth;

    this.makeAssocCall(this.selectedAssoc, this.selectedMonth);
  };

  gotoParentTree = id => {
    if (id) {
      this.selectedAssoc = id;
      this.makeAssocCall(this.selectedAssoc, this.selectedMonth);
    }
  };

  render() {
    const { isLoading, userInfo } = this.props;
    const {
      totalPages,
      page,
      limit,
      parentId,
      refId,
      referrals,
      modal,
      details
    } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.customerTree.breadcrumb} />
              <h4 className="page-title">{routePath.customerTree.title}</h4>
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-8">
            <TreeFilterForm submitForm={this.handleFilter} />
          </div>
        </div>
        {isLoading && <InnerLoader type="table" />}
        {!isLoading && (
          <>
            <TreeNav
              nodePagination={this.nodePagination}
              gotoParentTree={this.gotoParentTree}
              page={page}
              limit={limit}
              parentId={parentId}
              refId={refId}
              topId={userInfo.id}
              totalPages={totalPages}
              referrals={referrals}
            />

            <div id="assoc_tree" />

            <TreeNav
              nodePagination={this.nodePagination}
              gotoParentTree={this.gotoParentTree}
              page={page}
              limit={limit}
              parentId={parentId}
              refId={refId}
              topId={userInfo.id}
              totalPages={totalPages}
              referrals={referrals}
            />
          </>
        )}
        {modal && (
          <NodeViewModal handleModal={this.handleModal} details={details} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.customerTree.isLoading,
  imageStyle: state.app.imageStyle,
  list: state.customerTree.list,
  userInfo: state.auth.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getCustomerTree: treeActions.getCustomerTree },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tree);
