import { findIndex } from 'lodash';
import { category as categoryActionTypes } from '../actions';
import initialState from '../constants/initialState';
import {
  categoryHierarchyWIthIndex,
  updateObjectInArray
} from '../utills/custom';

function category(state = initialState.category, action) {
  switch (action.type) {
    case categoryActionTypes.CATEGORY_ALL_LIST_REQUEST:
      return {
        ...state,
        categoryDetails: {},
        isLoading: true
      };
    case categoryActionTypes.CATEGORY_ALL_LIST_SUCCESS:
      if (action.data) {
        return {
          ...state,
          isLoading: false,
          allList: {
            ...action.data,
            data: categoryHierarchyWIthIndex(action.data.data)
          }
        };
      }
      return state;
    case categoryActionTypes.CATEGORY_ALL_LIST_ERROR:
      return {
        ...state,
        isLoading: false
      };
    case categoryActionTypes.CATEGORY_CREATE_REQUEST:
      return {
        ...state,
        createProgress: true
      };
    case categoryActionTypes.CATEGORY_CREATE_SUCCESS:
    case categoryActionTypes.CATEGORY_CREATE_ERROR:
      return {
        ...state,
        createProgress: false
      };
    case categoryActionTypes.CATEGORY_SUCCESS:
      if (action.data) {
        return {
          ...state,
          categoryDetails: { [action.data.id]: action.data }
        };
      }
      return state;
    case categoryActionTypes.CATEGORY_UPDATE_REQUEST:
      return {
        ...state,
        updateProgress: true
      };
    case categoryActionTypes.CATEGORY_UPDATE_SUCCESS:
      if (action.data) {
        const index = findIndex(state.allList.data, { id: action.data.id });
        if (index > -1) {
          const newList = updateObjectInArray(state.allList.data, {
            index,
            item: action.data
          });
          const allList = { ...state.allList, data: newList };
          return {
            ...state,
            allList,
            updateProgress: false
          };
        }
      }
      return {
        ...state,
        updateProgress: false
      };
    case categoryActionTypes.CATEGORY_UPDATE_ERROR:
      return {
        ...state,
        updateProgress: false
      };
    case categoryActionTypes.ADD_CATEGORY_IMAGE_FILE:
      return {
        ...state,
        imageFile: action.data
      };
    case categoryActionTypes.DELETE_CATEGORY_IMAGE_FILE:
      return {
        ...state,
        imageFile: null
      };
    default:
      return state;
  }
}

export default category;
