import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { cart as cartActionTypes } from '../actions';

const { CancelToken } = axios;
let CalculationToken = null;

function* getCart() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cart/get`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.CART_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCartDetails() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cart/assos`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.CART_DETAILS_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getCartCalculation(actions) {
  try {
    if (CalculationToken) {
      CalculationToken.cancel();
    }
    CalculationToken = CancelToken.source();
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cartitem/calculation`,
      method: 'POST',
      data: actions.data,
      hideMessage: true,
      hideErrorMessage: true,
      cancelToken: CalculationToken.token
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.CART_CALCULATION_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* addTOCart(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cartitem`,
      method: 'POST',
      data: actions.data,
      successMessage: 'Added to cart'
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.ADD_TO_CART_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* updateCart(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cartitem/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Cart updated successfully'
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.UPDATE_CART_SUCCESS,
        data: responseJson
      });
      // Update Cart Price brek down calculation
      yield put({
        type: cartActionTypes.CART_CALCULATION_REQUEST,
        data: { cart_id: responseJson.id }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* deleteCart(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/cartitem/${actions.id}`,
      method: 'DELETE',
      successMessage: 'Cart updated successfully'
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.DELETE_CART_SUCCESS,
        data: responseJson
      });
      // Update Cart Price brek down calculation
      yield put({
        type: cartActionTypes.CART_CALCULATION_REQUEST,
        data: { cart_id: responseJson.id }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* reviseCartDetails(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/reviseCartCalculation`,
      method: 'POST',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: cartActionTypes.REVISE_CART_SUCCESS,
        data: responseJson.cart
      });

      // Callback
      if (actions.callback) {
        actions.callback(responseJson);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetCart() {
  yield takeLatest(cartActionTypes.CART_REQUEST, getCart);
}

function* watchAddToCart() {
  yield takeLatest(cartActionTypes.ADD_TO_CART_REQUEST, addTOCart);
}

function* watchGetCartDetails() {
  yield takeLatest(cartActionTypes.CART_DETAILS_REQUEST, getCartDetails);
}

function* watchGetCartCalculation() {
  yield takeLatest(
    cartActionTypes.CART_CALCULATION_REQUEST,
    getCartCalculation
  );
}

function* watchUpdateCart() {
  yield takeLatest(cartActionTypes.UPDATE_CART_REQUEST, updateCart);
}

function* watchDeleteCart() {
  yield takeLatest(cartActionTypes.DELETE_CART_REQUEST, deleteCart);
}

function* watchReviseCart() {
  yield takeLatest(cartActionTypes.REVISE_CART_REQUEST, reviseCartDetails);
}

export default [
  fork(watchGetCart),
  fork(watchAddToCart),
  fork(watchGetCartDetails),
  fork(watchGetCartCalculation),
  fork(watchUpdateCart),
  fork(watchDeleteCart),
  fork(watchReviseCart)
];
