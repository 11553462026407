import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { keyBy, isEmpty } from 'lodash';
import InnerLoader from '../../components/InnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import { tree as treeActions } from '../../actions';
import routePath from '../../constants/routePath';
import MyDownline from './MyDownline';

const chart = {
  container: '#assoc_tree',
  connectors: {
    type: 'step',
    style: {
      stroke: '#727cf5'
    }
  },
  node: {
    HTMLclass: 'nodeExample1'
  }
};

class Downline extends React.Component {
  componentDidMount() {
    const { getAssociateTree } = this.props;
    getAssociateTree();
  }

  render() {
    const { isLoading, list, listObj } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.downline.breadcrumb} />
              <h4 className="page-title">{routePath.downline.title}</h4>
            </div>
          </div>
        </div>
        {isLoading || isEmpty(list) ? (
          <InnerLoader type="table" />
        ) : (
          <MyDownline item={list[0]} itemObj={listObj} />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.tree.isLoading,
  list: state.tree.list,
  listObj: keyBy(state.tree.list, 'id')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { getAssociateTree: treeActions.getAssociateTree },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Downline);
