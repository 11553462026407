import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import { order as orderActionTypes } from '../actions';

function* getAllOrder(actions) {
  // const data = actions.data || {};
  let url = `${process.env.REACT_APP_API_URL}/order`;
  if (actions.data) {
    const { orderType } = actions.data;
    if (orderType === 'customer') {
      url = `${process.env.REACT_APP_API_URL}/order/direct-customer`;
    } else if (orderType === 'associate') {
      url = `${process.env.REACT_APP_API_URL}/associate/downline/order`;
    }
  }

  try {
    const responseJson = yield axios({
      url,
      method: 'GET',
      params: actions.data,
      paramsSerializer: params =>
        qs.stringify(params, { skipNulls: false, strictNullHandling: false })
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_ALL_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getSummary(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/order/summary`,
      method: 'GET',
      params: actions.data,
      paramsSerializer: params =>
        qs.stringify(params, { skipNulls: false, strictNullHandling: false })
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_SUMMARY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getOrder(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/orderdetail/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: orderActionTypes.ORDER_ERROR,
      data: error
    });
  }
}

function* getTnxOrder(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/ordertnxdetail/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.ORDER_TNX_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: orderActionTypes.ORDER_ERROR,
      data: error
    });
  }
}

function* getSubscriptionOrder(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/order/associate-package/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: orderActionTypes.SUBSCRIPTION_ORDER_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: orderActionTypes.SUBSCRIPTION_ORDER_ERROR,
      data: error
    });
  }
}

function* watchOrderListAll() {
  yield takeLatest(orderActionTypes.ORDER_ALL_LIST_REQUEST, getAllOrder);
}

function* watchGetSummary() {
  yield takeLatest(orderActionTypes.ORDER_SUMMARY_REQUEST, getSummary);
}

function* watchGetOrder() {
  yield takeLatest(orderActionTypes.ORDER_REQUEST, getOrder);
}

function* watchGetTnxOrder() {
  yield takeLatest(orderActionTypes.ORDER_TNX_REQUEST, getTnxOrder);
}

function* watchGetSubscriptionOrder() {
  yield takeLatest(
    orderActionTypes.SUBSCRIPTION_ORDER_REQUEST,
    getSubscriptionOrder
  );
}

export default [
  fork(watchOrderListAll),
  fork(watchGetSummary),
  fork(watchGetOrder),
  fork(watchGetTnxOrder),
  fork(watchGetSubscriptionOrder)
];
