import React, { useCallback, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import { auth as authActions } from '../../actions';

function ShippingOptions({
  getSubscriptionShippingOption,
  shippingCountry,
  packageId,
  subscriptionShippingOptions,
  pickupFromStore,
  dispatch,
  shippingMode,
  currency
}) {
  const callback = useCallback(
    data => {
      if (data && data.shipping_modes) {
        // Check if the selected shipping is present in API response
        const foundId = findIndex(data.shipping_modes, o => {
          return o.id === parseInt(shippingMode, 10);
        });
        // If not found set to the first shipping method
        if (foundId < 0) {
          dispatch(
            change(
              'registerPaymentForm',
              'shipping_mode',
              data.shipping_modes[0].id
            )
          );
        }
      } else {
        dispatch(change('registerPaymentForm', 'shipping_mode', ''));
      }
    },
    [shippingMode]
  );

  useEffect(() => {
    if (shippingCountry) {
      getSubscriptionShippingOption(
        {
          package_id: packageId,
          shipping_country: shippingCountry,
          pickup_from_store: +pickupFromStore
        },
        callback
      );
    }
  }, [shippingCountry, packageId, pickupFromStore]);

  const handleShippingSelect = e => {
    dispatch(change('registerPaymentForm', 'shipping_mode', e.target.value));
  };

  if (
    subscriptionShippingOptions &&
    subscriptionShippingOptions.shipping_modes
  ) {
    return (
      <>
        <tr>
          <td colSpan="2">Shipping ({subscriptionShippingOptions.country})</td>
        </tr>
        {subscriptionShippingOptions.shipping_modes.map(item => (
          <tr className="font-13" key={`ship_id_${item.id}`}>
            <td className="pt-0">
              <label>
                <input
                  name="shipping_mode"
                  value={item.id}
                  checked={parseInt(shippingMode, 10) === item.id}
                  type="radio"
                  onChange={handleShippingSelect}
                  className="mr-1"
                />
                {item.delivery_name}:
              </label>
              {item.delivery_time ? (
                <small className="d-block">({item.delivery_time})</small>
              ) : null}
            </td>
            <td className="pt-0 text-right">{`${currency}${item.rate}`}</td>
          </tr>
        ))}
      </>
    );
  }

  return null;
}

const selector = formValueSelector('registerAddressForm');
const selectorPayment = formValueSelector('registerPaymentForm');

const mapStateToProps = state => ({
  packageId: state.auth.package,
  subscriptionShippingOptions: state.auth.subscriptionShippingOptions,
  pickupFromStore: selector(state, 'pickup_from_store'),
  shippingCountry: selector(state, 'shipping_country'),
  shippingMode: selectorPayment(state, 'shipping_mode')
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSubscriptionShippingOption: authActions.getSubscriptionShippingOption,
      dispatch: action => action
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingOptions);
