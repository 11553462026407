import React, { Fragment, useEffect } from 'react';
import { Field, FieldArray } from 'redux-form';
import { required, number } from '../../utills/validator';
import { inputField } from '../../components/Fields';

const RenderRange = ({ fields, currency }) => {
  return (
    <Fragment>
      <div className="mywrapper">
        <div className="row">
          <div className="col-12 col-md-12 col-sm-6">
            {fields.map((field, index) => (
              <Fragment key={`weight_${index}`}>
                {index === 0 && (
                  <div className="row d-flex flex-nowrap">
                    <label className="col-md-6 col-12 col-sm-12">
                      Weight Range
                    </label>
                    <label className="col-md-6 col-12 col-sm-12">
                      {`Rate (${currency})`}
                    </label>
                  </div>
                )}
                <div className="row d-flex flex-nowrap">
                  <div className="col-md-6 col-12 col-sm-12">
                    <div className="row">
                      <div className="col-md-6 col-6 col-sm-6 pr-0">
                        <div className="d-flex range-input-group">
                          <Field
                            name={`${field}min_weight`}
                            component={inputField}
                            type="text"
                            className="form-control shipping-range-input"
                            groupClassName=""
                            placeholder="0.000"
                            validate={[required, number]}
                          />
                          <span className="input-group-text">kg</span>
                        </div>
                      </div>
                      <div className="col-md-6 col-6 col-sm-6 pl-0">
                        <div className="d-flex range-input-group">
                          <Field
                            name={`${field}max_weight`}
                            component={inputField}
                            type="text"
                            className="form-control shipping-range-input"
                            groupClassName=""
                            placeholder="1.000"
                            validate={[required, number]}
                          />
                          <span className="input-group-text">kg</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12 col-sm-12">
                    <div className="row d-flex flex-nowrap">
                      <div className="col-md-6 col-6 col-sm-6">
                        <Field
                          name={`${field}rate`}
                          component={inputField}
                          type="text"
                          className="form-control"
                          placeholder="0.00"
                          validate={[required, number]}
                        />
                      </div>
                      <div className="col-md-6">
                        {index === fields.length - 1 && (
                          <button
                            type="button"
                            onClick={() => fields.push({})}
                            className="btn btn-sm btn-success mr-1"
                          >
                            <i className="mdi mdi-plus" />
                          </button>
                        )}

                        {index !== 0 && (
                          <button
                            type="button"
                            onClick={() => fields.remove(index)}
                            className="btn btn-sm btn-danger"
                          >
                            <i className="mdi mdi-trash-can-outline" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
const ShippingRateByWeight = ({ fields, currency }) => {
  return (
    <Fragment>
      {fields.map((field, index) => (
        <div
          className="position-relative border-bottom"
          id={index}
          key={`weight_${index}`}
        >
          <div className="row mt-4">
            <div className="col-md-4">
              <Field
                name={`${field}delivery_name`}
                component={inputField}
                type="text"
                className="form-control"
                label="Delivery Option Name"
                validate={[required]}
                placeholder="e.g., Standard Shipping"
              />
            </div>
            <div className="col-md-4">
              <Field
                name={`${field}delivery_time`}
                component={inputField}
                type="text"
                className="form-control"
                label="Est. Delivery Time (optional)"
                placeholder="e.g., 3 - 5 business days"
              />
            </div>
          </div>
          {index !== 0 && (
            <button
              type="button"
              className="btn btn-sm btn-danger calculation-remove-btn"
              onClick={() => fields.remove(index)}
            >
              <i className="mdi mdi-close mr-1" />
              Remove
            </button>
          )}
          <div className="row">
            <div className="col-md-8">
              <FieldArray
                name={`${field}rates`}
                currency={currency}
                component={RenderRange}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="col-md-12 mt-2 pl-0">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => fields.push({ rates: [{}] })}
        >
          {' '}
          <i className="mdi mdi-plus" />
          Add Delivery Option
        </button>
      </div>
    </Fragment>
  );
};

export default ShippingRateByWeight;
