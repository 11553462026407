import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  paymentOption as paymentOptionTypes,
  app as appActionTypes
} from '../actions';
import routePath from '../constants/routePath';

function* getAllCard() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/user-cards`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}
function* getAllBank() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/associate-banks`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getPayment(actions) {
  try {
    const optionType =
      actions.optionType === 'card' ? 'user-cards' : 'associate-banks';
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${optionType}/${actions.id}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_SUCCESS,
        data: responseJson,
        optionType: actions.optionType
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createPayment(actions) {
  try {
    const paymentType =
      actions.data.optionType === 'card' ? 'user-cards' : 'associate-banks';
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${paymentType}`,
      method: 'POST',
      data: actions.data.data,
      successMessage: 'Payment option was added successfully'
    });
    if (responseJson) {
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_CREATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_HIDE_MODAL
      });
      if (actions.data.optionType === 'card') {
        yield put({
          type: paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_REQUEST
        });
      }
      if (actions.data.optionType === 'bank') {
        yield put({
          type: paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_REQUEST
        });
      }
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.payOptions.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: paymentOptionTypes.PAYMENT_OPTION_CREATE_ERROR,
      data: error
    });
  }
}

function* updatePayment(actions) {
  try {
    const optionType =
      actions.data.optionType === 'card' ? 'user-cards' : 'associate-banks';
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${optionType}/${actions.data.id}`,
      method: 'PUT',
      data: actions.data.data,
      successMessage: 'Payment option updated successfully'
    });
    if (responseJson) {
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_UPDATE_SUCCESS,
        data: responseJson
      });
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_HIDE_MODAL,
        data: responseJson
      });
      if (actions.data.optionType === 'card') {
        yield put({
          type: paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_REQUEST
        });
      }
      if (actions.data.optionType === 'bank') {
        yield put({
          type: paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_REQUEST
        });
      }
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.payOptions.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: paymentOptionTypes.PAYMENT_OPTION_UPDATE_ERROR,
      data: error
    });
  }
}

function* deletePayment(actions) {
  try {
    const optionType =
      actions.data.optionType === 'card' ? 'user-cards' : 'associate-banks';
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/${optionType}/${actions.data.id}`,
      method: 'DELETE',
      successMessage: 'Payment option deleted successfully'
    });
    if (responseJson) {
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_DELETE_SUCCESS,
        data: actions.data
      });
      yield put({
        type: paymentOptionTypes.PAYMENT_OPTION_HIDE_ALERT,
        data: actions.data
      });
      if (actions.data.redirect) {
        yield put({
          type: appActionTypes.NAVIGATE_REQUEST,
          path: routePath.payOptions.path
        });
      }
    }
  } catch (error) {
    yield put({
      type: paymentOptionTypes.PAYMENT_OPTION_DELETE_ERROR,
      data: error
    });
  }
}

function* watchPaymentCardListAll() {
  yield takeLatest(
    paymentOptionTypes.PAYMENT_OPTION_ALL_CARD_LIST_REQUEST,
    getAllCard
  );
}

function* watchPaymentBankListAll() {
  yield takeLatest(
    paymentOptionTypes.PAYMENT_OPTION_ALL_BANK_LIST_REQUEST,
    getAllBank
  );
}

function* watchGetPayment() {
  yield takeLatest(paymentOptionTypes.PAYMENT_OPTION_REQUEST, getPayment);
}

function* watchPaymentCreate() {
  yield takeLatest(
    paymentOptionTypes.PAYMENT_OPTION_CREATE_REQUEST,
    createPayment
  );
}

function* watchPaymentUpdate() {
  yield takeLatest(
    paymentOptionTypes.PAYMENT_OPTION_UPDATE_REQUEST,
    updatePayment
  );
}

function* watchPaymentDelete() {
  yield takeLatest(
    paymentOptionTypes.PAYMENT_OPTION_DELETE_REQUEST,
    deletePayment
  );
}

export default [
  fork(watchPaymentCardListAll),
  fork(watchPaymentBankListAll),
  fork(watchPaymentCreate),
  fork(watchPaymentUpdate),
  fork(watchGetPayment),
  fork(watchPaymentDelete)
];
