import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import _, { find, get, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { order as orderActions } from '../../actions';
import routePath from '../../constants/routePath';
import BreadCrumb from '../../components/BreadCrumb';
import NoResult from '../../components/NoResult';
import InnerLoader from '../../components/InnerLoader';

class OrderView extends React.Component {
  componentDidMount() {
    const { order, match, getOrderById } = this.props;
    const orderList = get(order, 'data', []);
    if (match.params && match.params.id) {
      getOrderById(match.params.id);
    }
  }

  productItems = (items, currency) => {
    if (items) {
      const trItems = [];
      items.forEach(item => {
        trItems.push(
          <tr key={item.id}>
            <td>
              <Link
                className="dashed-underline"
                to={`/product/${item.product_id}/view`}
              >
                {item.product.title}
              </Link>
            </td>
            <td>{item.quantity}</td>
            <td>{`${currency}${item.price}`}</td>
            <td>{`${currency}${(item.price * item.quantity).toFixed(2)}`}</td>
          </tr>
        );
      });
      return trItems;
    }
    return '<tr><td colspan="4">No items</td></tr>';
  };

  render() {
    const { order, match, isLoading } = this.props;
    const orderList = get(order, 'data', []);
    let data = null;
    let shippingSummary = null;
    let taxSummary = null;
    let shippingAddress = null;
    let billingAddress = null;
    let currency = '';
    if (match.params && match.params.id) {
      const tmpData = find(orderList, { id: parseInt(match.params.id, 10) });
      if (tmpData) {
        data = tmpData;
        currency = data.currency ? data.currency.currency_symbol : '';
        if (data.order_taxes_shipping_details) {
          shippingSummary = _.filter(data.order_taxes_shipping_details, [
            'type',
            2
          ]);
          taxSummary = _.filter(data.order_taxes_shipping_details, ['type', 1]);
        }
        if (data.order_address) {
          shippingAddress = _.find(data.order_address, ['type', 'SHIPPING']);
          billingAddress = _.find(data.order_address, ['type', 'BILLING']);
        }
      }
    }

    return (
      <React.Fragment>
        {isLoading && <InnerLoader type="table" />}
        {data ? (
          <>
            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  <BreadCrumb list={routePath.orderView.breadcrumb} />
                  <h4 className="page-title">{`Order ID: #${data.id}`}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table mb-0 table-nowrap">
                        <thead className="thead-light">
                          <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.order_item &&
                            this.productItems(data.order_item, currency)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h4 className="header-title mb-3">Order Summary</h4>
                    <div className="table-responsive">
                      <table className="table table-summary mb-0">
                        <tbody>
                          <tr>
                            <td>Sub Total:</td>
                            <td className="text-right">
                              {`${currency}${parseFloat(data.subtotal).toFixed(
                                2
                              )}`}
                            </td>
                          </tr>
                          {shippingSummary && (
                            <>
                              <tr>
                                <td colSpan="2">Shipping:</td>
                              </tr>
                              {shippingSummary.map(shipping => (
                                <tr
                                  key={`shipping_${shipping.id}`}
                                  className="font-13"
                                >
                                  <td className="pt-0">{shipping.name}</td>
                                  <td className="pt-0 text-right">
                                    {shipping.total > 0
                                      ? `${currency}${parseFloat(
                                          shipping.total
                                        ).toFixed(2)}${
                                          shipping.type === 1 ? '%' : ''
                                        }`
                                      : 'FREE'}
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                          {taxSummary &&
                            taxSummary.map(tax => (
                              <tr key={`tax${tax.type}`} className="font-13">
                                <td className="pt-0">
                                  {`${tax.name} (${parseFloat(
                                    tax.amount
                                  ).toFixed(1)}${tax.type === 1 ? '%' : ''})`}
                                </td>
                                <td className="pt-0 text-right">
                                  {tax.total > 0
                                    ? `${currency}${parseFloat(
                                        tax.total
                                      ).toFixed(2)}`
                                    : '0'}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <th>Gross Total: </th>
                            <th className="text-right">
                              {`${currency}${parseFloat(data.total).toFixed(
                                2
                              )}`}
                            </th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row row-eq-height">
              {billingAddress && (
                <div className="col-md-3 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h4 className="header-title mb-2">Billing</h4>
                      <address className="mb-0 address-lg">
                        {billingAddress.street_name_number}
                        <br />
                        {billingAddress.billingAddress_line1 ? (
                          <>
                            {billingAddress.billingAddress_line1}
                            <br />
                          </>
                        ) : null}
                        {billingAddress.billingAddress_line2 ? (
                          <>
                            {billingAddress.billingAddress_line2}
                            <br />
                          </>
                        ) : null}
                        {billingAddress.postcode}
                        <br />
                        {billingAddress.city_details
                          ? billingAddress.city_details.name
                          : billingAddress.city && billingAddress.city}
                        <br />
                        {billingAddress.state ? billingAddress.state.name : ''}
                        <br />
                        {billingAddress.country.name || billingAddress.country}
                        <br />
                        <abbr title="Mobile">M:</abbr>{' '}
                        <span>{billingAddress.mobile_number}</span>
                      </address>
                    </div>
                  </div>
                </div>
              )}
              {shippingAddress && (
                <div className="col-md-3 mb-3">
                  <div className="card h-100">
                    <div className="card-body">
                      <h4 className="header-title mb-2">Shipping</h4>
                      <address className="mb-0 address-lg">
                        {shippingAddress.street_name_number}
                        <br />
                        {shippingAddress.shippingAddress_line1 ? (
                          <>
                            {shippingAddress.shippingAddress_line1}
                            <br />
                          </>
                        ) : null}
                        {shippingAddress.shippingAddress_line2 ? (
                          <>
                            {shippingAddress.shippingAddress_line2}
                            <br />
                          </>
                        ) : null}
                        {shippingAddress.postcode}
                        <br />
                        {shippingAddress.city_details
                          ? shippingAddress.city_details.name
                          : shippingAddress.city && shippingAddress.city}
                        <br />
                        {shippingAddress.state
                          ? shippingAddress.state.name
                          : ''}
                        <br />
                        {shippingAddress.country.name ||
                          shippingAddress.country}
                        <br />
                        <abbr title="Mobile">M:</abbr>{' '}
                        <span>{shippingAddress.mobile_number}</span>
                      </address>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-3 mb-3" />
              <div className="col-md-3 mb-3" />
            </div>
          </>
        ) : (
          <div className="mt-3">{!isLoading && <NoResult />}</div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.order.isLoadingSingle,
  order: state.order.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getOrderById: orderActions.getOrderById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OrderView)
);
