import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import _ from 'lodash';

import AdvanceTable from '../../components/AdvanceTable';
import StatusSwitch from '../../components/StatusSwitch';
import BreadCrumb from '../../components/BreadCrumb';
import { category as categoryActions } from '../../actions';
import routePath from '../../constants/routePath';
import { categoryImage } from '../../constants/imageSize';
import { getImageUrl } from '../../utills/custom';
import img from '../../assets/images/img-placeholder.png';
import Tooltip from './CategoryCountryTooltip';

function hierarchyHTMLDash(level) {
  switch (level) {
    case 1:
      return (
        <>
          <i className="mdi mdi-minus" />
        </>
      );
    case 2:
      return (
        <>
          <i className="mdi mdi-minus" />
          <i className="mdi mdi-minus" />
        </>
      );
    case 3:
      return (
        <>
          <i className="mdi mdi-minus" />
          <i className="mdi mdi-minus" />
          <i className="mdi mdi-minus" />
        </>
      );
    case 0:
    default:
      return null;
  }
}

class Category extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getAllCategories } = this.props;
    getAllCategories();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAllCategories } = this.props;
    if (type === 'pagination') getAllCategories({ page, per_page: perPage });
    this.setState({ page });
  };

  updateCategoryStatus = (id, val) => {
    const { updateCategory } = this.props;
    updateCategory({
      id,
      data: { status: val ? 1 : 0 }
    });
  };

  render() {
    const { categories, isLoading, imageStyle, filterValues } = this.props;
    const { page, selected } = this.state;
    const columns = [
      {
        dataField: 'file',
        text: 'Image',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <span className="img_holder">
            <img
              src={
                col
                  ? `${process.env.REACT_APP_IMAGE_URL}/${getImageUrl(
                      col.file_path,
                      imageStyle[categoryImage.categoryAdmin]
                    )}`
                  : img
              }
              alt={row.title}
            />
          </span>
        )
      },
      {
        dataField: 'title',
        text: 'Title',
        headerAttrs: { width: '25%' },
        formatter: (col, row) => (
          <>
            <Link to={`/category/${row.id}/view`} className="dashed-underline">
              {hierarchyHTMLDash(row.hierarchyLevel)} {col}
            </Link>
          </>
        )
      },
      {
        dataField: 'slug',
        text: 'Slug'
      },
      {
        dataField: 'Country',
        text: 'Country',
        formatter: (col, row) => (
          <Tooltip position="right" data="All Countries Here" />
        )
      },

      {
        dataField: 'short_description',
        text: 'Description',
        formatter: (col, row) => (
          <span
            dangerouslySetInnerHTML={{
              __html: col
            }}
          />
        )
      },

      {
        dataField: 'status',
        text: 'Active?',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '10%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={parseInt(col, 10)}
            onChange={this.updateCategoryStatus}
            id={row.id}
            idPrefix="assos_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/category/${row.id}/product`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
            <Link to={`/category/${row.id}/edit`}>
              <i className="action-icon mdi mdi-square-edit-outline" />
            </Link>
          </>
        )
      }
    ];
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.category.breadcrumb} />
              <h4 className="page-title">{routePath.category.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body">
                <div className="row mb-3">
                  <div className="col-sm-7 col-lg-6">
                    <div className="app-search category_search">
                      <form className="form-inline">
                        <div
                          className="input-group input-group-sm search-bar"
                          id="custom-search"
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search By Category Name"
                          />
                          <span className="mdi mdi-magnify" />
                          <div className="input-group-append d-none d-lg-block">
                            <button className="btn btn-light" type="submit">
                              Search
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-sm-5 col-lg-6 text-right">
                    <div className="btn-group btn-group-sm ml-2">
                      <Dropdown>
                        <Dropdown.Toggle
                          size="sm"
                          variant="primary"
                          id="actionDropdown"
                        >
                          Actions
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight>
                          <Dropdown.Item
                            as={Link}
                            to={routePath.categoryAdd.path}
                          >
                            <i className="mdi mdi-plus-circle-outline" /> Add
                            new
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2">
                            <i className="mdi mdi-trash-can" /> Delete
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-3">
                            <i className="mdi mdi-export" /> Export
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <AdvanceTable
                  data={categories.data}
                  columns={columns}
                  loading={isLoading}
                  remote={{ pagination: true }}
                  totalSize={categories.total}
                  sizePerPage={categories.per_page}
                  sizePerPageList={filterValues}
                  page={page}
                  hideSizePerPage={false}
                  onTableChange={this.handleTableChange}
                  selected={selected}
                  onSelect={this.handleOnSelect}
                  onSelectAll={this.handleOnSelectAll}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.category.allList,
  isLoading: state.category.isLoading,
  imageStyle: state.app.imageStyle,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllCategories: categoryActions.getAllCategories,
      updateCategory: categoryActions.updateCategory
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Category);
