/* global appConfig */
import React from 'react';

export default function Footer() {
  return (
    <footer className="footer">
      <span>&copy; {appConfig.footerYear}, </span>
      <a href={appConfig.footerURL} className="text-muted">
        <b> {appConfig.companyName}</b>
      </a>
      <span>. {appConfig.footerText}.</span>
    </footer>
  );
}
