import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { find, findIndex, map } from 'lodash';
import PackageForm from './PackageForm';
import BreadCrumb from '../../components/BreadCrumb';
import { packages as packageActions } from '../../actions';
import routePath from '../../constants/routePath';

function formatData(data) {
  const countries = data.package_country
    ? data.package_country.map(item => ({
        bp_restriction: item.bp_restriction,
        bpoint: parseFloat(item.bpoint).toFixed(2),
        country: item.country,
        id: item.id,
        package_bonus_amount: parseFloat(item.package_bonus_amount).toFixed(2),
        price: parseFloat(item.price).toFixed(2),
        shipping: item.shipping,
        shipping_vat: item.shipping_vat,
        shop_restriction: item.shop_restriction,
        status: item.status,
        tax: item.tax,
        weight: item.weight
      }))
    : [];

  return {
    description: data.description,
    ean: data.ean,
    status: data.status,
    title: data.title,
    image: '',
    countries
  };
}

class AssociatePackageEdit extends React.Component {
  uploadedImage = null;

  componentDidMount() {
    const { packages, match, getPackageById } = this.props;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getPackageById(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updatePackage, match } = this.props;
    // append image file is uploaded
    let imageParam = {};
    if (values.image_selected) {
      imageParam = { image: this.uploadedImage };
    }
    updatePackage({
      id: parseInt(match.params.id, 10),
      data: { ...values, ...imageParam },
      redirect: true
    });
  };

  handleSelectImage = file => {
    this.uploadedImage = file;
  };

  render() {
    const { updateProgress, packages, match } = this.props;
    let data = null;
    let defaultPackageImage = '';
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      const found = find(packages, { id: parseInt(match.params.id, 10) });
      if (found && found.package_country) {
        data = formatData(found);
        if (found.file && found.file.file_path) {
          defaultPackageImage = `${process.env.REACT_APP_IMAGE_URL}/${found.file.file_path}`;
        }
      }
    }
    return (
      <>
        <div className="row mb-2">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.associatePackageEdit.breadcrumb} />
              <h4 className="page-title">
                {routePath.associatePackageEdit.title}
              </h4>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <PackageForm
                submitForm={this.handleSubmit}
                initialValues={data}
                // initialValues={{ ...data, country_id: map(data.country_id, 'id') }}
                inProgress={updateProgress}
                defaultImage={defaultPackageImage}
                onSelectImage={this.handleSelectImage}
              />
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.packages.updateProgress,
  packages: state.packages.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updatePackage: packageActions.updatePackage,
      getPackageById: packageActions.getPackageById
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssociatePackageEdit)
);
