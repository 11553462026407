import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import img from '../../assets/images/img-placeholder.jpg';
import Image from '../../components/Image';
import { categoryImage } from '../../constants/imageSize';
import { getImageUrl } from '../../utills/custom';

function CategoryItem({ category, imageStyle }) {
  return (
    <div className="col-sm-3 col-md-3">
      <Link to={`/shop/${category.slug}`} title={category.title}>
        <div className="card category-item">
          <Image
            className="category-img"
            src={
              category.file
                ? `${process.env.REACT_APP_IMAGE_URL}/${getImageUrl(
                    category.file,
                    imageStyle[categoryImage.shopCategoryMedium]
                  )}`
                : img
            }
            alt={category.title}
          />
          <div className="card-img-overlay text-white d-flex flex-column justify-content-center text-center">
            <h3 className="card-title mb-0">{category.title}</h3>
            {/* <h6 className="card-subtitle mb-2">Emilia-Romagna Region, Italy</h6> */}
          </div>
        </div>
      </Link>
    </div>
  );
}

const mapStateToProps = state => ({
  imageStyle: state.app.imageStyle
});

export default connect(
  mapStateToProps,
  null
)(CategoryItem);
