const setting = {
  SETTING_ALL_LIST_REQUEST: 'SETTING_ALL_LIST_REQUEST',
  SETTING_ALL_LIST_SUCCESS: 'SETTING_ALL_LIST_SUCCESS',
  SETTING_ALL_LIST_ERROR: 'SETTING_ALL_LIST_ERROR',
  SETTING_CREATE_REQUEST: 'SETTING_CREATE_REQUEST',
  SETTING_CREATE_SUCCESS: 'SETTING_CREATE_SUCCESS',
  SETTING_CREATE_ERROR: 'SETTING_CREATE_ERROR',
  SETTING_UPDATE_REQUEST: 'SETTING_UPDATE_REQUEST',
  SETTING_UPDATE_SUCCESS: 'SETTING_UPDATE_SUCCESS',
  SETTING_UPDATE_ERROR: 'SETTING_UPDATE_ERROR',
  SETTING_REQUEST: 'SETTING_REQUEST',
  SETTING_SUCCESS: 'SETTING_SUCCESS',
  SETTING_ERROR: 'SETTING_ERROR',
  getAllSettings: data => ({
    type: setting.SETTING_ALL_LIST_REQUEST,
    data
  }),
  createSetting: data => ({ type: setting.SETTING_CREATE_REQUEST, data }),
  updateSetting: data => ({ type: setting.SETTING_UPDATE_REQUEST, data }),
  getSetting: tag => ({ type: setting.SETTING_REQUEST, tag }),

  GENERAL_UPDATE_REQUEST: 'GENERAL_UPDATE_REQUEST',
  GENERAL_UPDATE_SUCCESS: 'GENERAL_UPDATE_SUCCESS',
  GENERAL_UPDATE_ERROR: 'GENERAL_UPDATE_ERROR',
  GENERAL_REQUEST: 'GENERAL_REQUEST',
  GENERAL_SUCCESS: 'GENERAL_SUCCESS',
  GENERAL_ERROR: 'GENERAL_ERROR',
  updateGeneral: data => ({ type: setting.GENERAL_UPDATE_REQUEST, data }),
  getGeneral: () => ({ type: setting.GENERAL_REQUEST }),

  STORE_UPDATE_REQUEST: 'STORE_UPDATE_REQUEST',
  STORE_UPDATE_SUCCESS: 'STORE_UPDATE_SUCCESS',
  STORE_UPDATE_ERROR: 'STORE_UPDATE_ERROR',
  STORE_REQUEST: 'STORE_REQUEST',
  STORE_SUCCESS: 'STORE_SUCCESS',
  STORE_ERROR: 'STORE_ERROR',
  updateStore: data => ({ type: setting.STORE_UPDATE_REQUEST, data }),
  getStore: () => ({ type: setting.STORE_REQUEST }),

  LOGIN_HISTORY_REQUEST: 'LOGIN_HISTORY_REQUEST',
  LOGIN_HISTORY_SUCCESS: 'LOGIN_HISTORY_SUCCESS',
  LOGIN_HISTORY_ERROR: 'LOGIN_HISTORY_ERROR',
  getAllLoginHistories: () => ({ type: setting.LOGIN_HISTORY_REQUEST })
};

export default setting;
