import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import btnLoader from '../../assets/images/btn-loader.gif';
import { readableTransform } from '../../utills/custom';

const RolePermissionForm = props => {
  const { handleSubmit, submitForm, inProgress, permissions } = props;
  return (
    <React.Fragment>
      {permissions.module.length ? (
        <Form
          className="needs-validation"
          noValidate
          onSubmit={handleSubmit(submitForm)}
        >
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col">Module</th>
                <th scope="col">Read</th>
                <th scope="col">Write</th>
              </tr>
            </thead>
            <tbody>
              {permissions.module.map(pKey => (
                <tr key={`permission_${pKey}`}>
                  <td>{readableTransform(pKey)}</td>
                  <td>
                    <label className="custom-control custom-checkbox custom-control-inline">
                      <Field
                        name={`${pKey}_read`}
                        type="checkbox"
                        component="input"
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">
                        {permissions.permission[`${pKey}_read`]
                          ? 'Enable'
                          : 'Disable'}
                      </span>
                    </label>
                  </td>
                  <td>
                    <label className="custom-control custom-checkbox custom-control-inline">
                      <Field
                        name={`${pKey}_write`}
                        type="checkbox"
                        component="input"
                        className="custom-control-input"
                      />
                      <span className="custom-control-label">
                        {permissions.permission[`${pKey}_write`]
                          ? 'Enable'
                          : 'Disable'}
                      </span>
                    </label>
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="3" className="text-center">
                  <button
                    type="submit"
                    disabled={inProgress}
                    className="btn btn-primary btn-lg btn-block"
                  >
                    {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Form>
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Module</th>
              <th scope="col">Read</th>
              <th scope="col">Write</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="3" className="text-center">
                No result found
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </React.Fragment>
  );
};

export default reduxForm({
  form: 'rolePermissionForm' // a unique identifier for this form
})(RolePermissionForm);
