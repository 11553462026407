import React from 'react';
import moment from 'moment';

const phaseStylew = ['tri-info1 font-we', 'tri-info2', 'tri-info3'];

export default function FsbTriangle({ data }) {
  const endDate = moment(data.end_date);
  const currentDate = moment();
  const DaysLeft = endDate.diff(currentDate, 'days');

  const phaseStyle = data.fsb_plan_logs.map((item, index) => ({
    id: item.id,
    progress: (100 * item.achieved_bp) / item.fsb_phase.bp_target,
    styles: {
      style: index === 1 ? 'width' : 'height',
      container: `triangle-line${index + 1}`,
      el: `progressbar${index + 1}`
    }
  }));

  return (
    <div className="triangle-cont">
      {data.fsb_plan_logs &&
        data.fsb_plan_logs.map((phase, index) => (
          <div
            key={`triangle_label_${phase.id}`}
            className={`${phaseStylew[index]} text-uppercase`}
          >
            <span className="font-weight-bold">{phase.fsb_phase.title}</span>
            <br />
            {/* <p className="text-muted font-11 d-none">01/06/2019 - 31/12/2019</p> */}
            <p className="badge badge-pill badge-dark">
              {`${phase.achieved_bp} BP`}
            </p>
          </div>
        ))}
      <div className="tri-out">
        <div className="tri-desc">
          <h5 className="mb-0">DAYS LEFT</h5>
          <h1 className="text-danger font-60 m-0">
            {DaysLeft > 0 ? DaysLeft : '0'}
          </h1>
          <small className="font-11 text-muted">
            {`PLAN END DATE: ${data.end_date}`}
          </small>
        </div>
        {phaseStyle.map(phase => (
          <div key={`triangle_${phase.id}`} className={phase.styles.container}>
            <div
              className={phase.styles.el}
              style={{ [phase.styles.style]: `${phase.progress}%` }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
