import React from 'react';
import { change, Field, Form, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { required } from '../../utills/validator';
import { inputField, selectField, switchField } from '../../components/Fields';
import { app as appActions, location as locationActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';
import { fetchStatesByCountryId } from '../../services/apiService';

class LocationForm extends React.Component {
  state = {
    stateList: []
  };

  componentDidMount() {
    const { getCountry, initialValues, locationType } = this.props;
    getCountry({ show: 'all' });
    if (locationType === 'city' && initialValues && initialValues.country_id) {
      this.getStatesInCountry(initialValues.country_id);
    }
  }

  getStatesInCountry = async countryId => {
    const statesData = await fetchStatesByCountryId(countryId);
    this.setState({
      stateList: statesData && statesData.data.state
    });
  };

  countryChange = val => {
    const { locationType } = this.props;
    if (val && locationType === 'city') {
      this.getStatesInCountry(val);
    }
  };

  getCountryIdDropdown = countries => {
    const countryList = [];
    countries.map(country =>
      countryList.push({
        value: country.id,
        label: country.label
      })
    );
    return countryList;
  };

  getStateIdDropdown = states => {
    return states.map(item => {
      return {
        value: item.state_id,
        label: item.state_name
      };
    });
  };

  render() {
    const {
      handleSubmit,
      submitForm,
      inProgress,
      locationType,
      countryList,
      currency,
      path,
      initialValues: { formType }
    } = this.props;
    const { stateList } = this.state;
    this.countryList = this.getCountryIdDropdown(countryList);
    this.stateList = this.getStateIdDropdown(stateList);

    const currencyList = currency.currencies.map(item => {
      const {
        currency_symbol: symbol,
        currency_abbreviation: abbrev,
        id
      } = item;
      return {
        label: `${abbrev} (${symbol})`,
        value: parseInt(id, 10)
      };
    });

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        {locationType === 'country' && (
          <div className="row">
            <div className="col-md-6">
              <Field
                name="name"
                component={inputField}
                type="text"
                className="form-control"
                label="Name"
                validate={[required]}
              />
            </div>
            <div className="col-md-6">
              <Field
                name="capital"
                component={inputField}
                type="text"
                className="form-control"
                label="Capital"
                validate={[required]}
              />
            </div>
            <div className="col-md-3">
              <Field
                name="iso2"
                component={inputField}
                type="text"
                className="form-control"
                label="ISO-2"
                validate={[required]}
              />
            </div>
            <div className="col-md-3">
              <Field
                name="iso3"
                component={inputField}
                type="text"
                className="form-control"
                label="ISO-3"
              />
            </div>
            <div className="col-md-3">
              <Field
                name="phonecode"
                component={inputField}
                type="text"
                className="form-control"
                label="Phone Code"
              />
            </div>
            <div className="col-md-3">
              <Field
                name="currency_id"
                component={selectField}
                label="Currency"
                validate={[required]}
                options={currencyList}
              />
            </div>
          </div>
        )}
        <div className="row">
          {(locationType === 'state' || locationType === 'city') && (
            <div
              className={`${
                locationType === 'state' ? 'col-md-6' : 'col-md-4'
              }`}
            >
              <Field
                name="country_id"
                id="country"
                component={selectField}
                label="Country"
                feedback={this.countryChange}
                options={this.countryList}
                validate={[required]}
                disabled={formType === 'add'}
              />
            </div>
          )}
          {locationType === 'city' && (
            <div className="col-md-4">
              <Field
                name="state_id"
                id="state"
                component={selectField}
                label="State"
                options={this.stateList}
                validate={[required]}
                disabled={formType === 'add'}
              />
            </div>
          )}
          {(locationType === 'state' || locationType === 'city') && (
            <div
              className={`${
                locationType === 'state' ? 'col-md-6' : 'col-md-4'
              }`}
            >
              <Field
                name="name"
                component={inputField}
                type="text"
                className="form-control"
                label="Name"
                validate={[required]}
              />
            </div>
          )}
        </div>
        <Field
          name="status"
          component={switchField}
          label="Status"
          id="locationStatus"
          type="success"
        />
        <Button as={Link} to={path} className="btn btn-light mr-2">
          Cancel
        </Button>
        <button type="submit" disabled={inProgress} className="btn btn-primary">
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </button>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  countryList: state.app.countryList,
  locations: state.location.allList,
  isLoading: state.location.isLoading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAllLocations: locationActions.getAllLocations,
      getCountry: appActions.getCountry
    },
    dispatch
  );

const RankFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationForm);

export default reduxForm({
  form: 'locationForm' // a unique identifier for this form
})(RankFormWithRedux);
