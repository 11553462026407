import React, { Fragment } from 'react';
import { Field } from 'redux-form';
import { required, number } from '../../utills/validator';
import { inputField } from '../../components/Fields';

const ShippingFlatRate = ({ fields, currency }) => {
  return (
    <Fragment>
      {fields.map((field, index) => (
        <div
          className="position-relative border-bottom"
          key={`flat_${index}`}
          id={index}
        >
          <div className="row mt-4">
            <div className="col-md-4 col-sm-4">
              <Field
                name={`${field}delivery_name`}
                component={inputField}
                type="text"
                className="form-control"
                label="Delivery Option Name"
                validate={[required]}
                placeholder="e.g., Standard Shipping"
              />
            </div>
            {index !== 0 && (
              <button
                type="button"
                className="btn btn-sm btn-danger calculation-remove-btn"
                onClick={() => fields.remove(index)}
              >
                <i className="mdi mdi-close mr-1" />
                Remove
              </button>
            )}
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-4">
              <Field
                name={`${field}delivery_time`}
                component={inputField}
                type="text"
                className="form-control"
                label="Est. Delivery Time (optional)"
                placeholder="e.g., 3 - 5 business days"
              />
            </div>
            <div className="col-md-2 col-sm-2 ml-sm-2 ml-xs-0">
              <Field
                name={`${field}rate`}
                component={inputField}
                type="text"
                className="form-control"
                label={`Rate (${currency})`}
                placeholder="0.00"
                validate={[required, number]}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="col-md-12 col-sm-12 mt-2 pl-0">
        <button
          className="btn btn-primary"
          type="button"
          onClick={() => fields.push({})}
        >
          {' '}
          <i className="mdi mdi-plus" />
          Add Delivery Option
        </button>
      </div>
    </Fragment>
  );
};

export default ShippingFlatRate;
