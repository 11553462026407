import React from 'react';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { checkboxField, radioField } from '../../components/Fields';
import btnLoader from '../../assets/images/btn-loader.gif';

function ShippingSettingForm({
  handleSubmit,
  submitForm,
  inProgress,
  pristine,
  submitting
}) {
  return (
    <Form
      className="needs-validation form-horizontal"
      noValidate
      onSubmit={handleSubmit(submitForm)}
    >
      <Field
        name="calc_shipping_cart"
        component={checkboxField}
        id="calc_shipping_cart"
        groupClassName="form-group mb-0"
        label="Calculations"
        // inline={['col-3', 'col-9 pt-2']}
        options={[
          {
            value: 'yes',
            label: 'Enable this shipping calculator on cart page'
          }
        ]}
      />
      <Field
        name="hide_shipping_cost_until_add_enter"
        component={checkboxField}
        id="hide_shipping_cost_until_add_enter"
        options={[
          {
            value: 'yes',
            label: 'Hide shipping cost untill an address is entered'
          }
        ]}
      />
      <Field
        name="default_shipping_location"
        component={radioField}
        id="default_shipping_location"
        label="Default Shipping Location"
        tooltip="Default Shipping Location"
        options={[
          { value: 'shipping', label: 'Default to customer shipping address' },
          { value: 'billing', label: 'Default to customer billing address' }
        ]}
      />
      <button type="button" className="btn btn-light mr-2">
        Cancel
      </button>
      <button
        type="submit"
        disabled={inProgress || pristine || submitting}
        className="btn btn-primary"
      >
        {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
      </button>
    </Form>
  );
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const ShippingSettingReduxForm = reduxForm({
  form: 'shippingSettingForm' // a unique identifier for this form
})(ShippingSettingForm);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShippingSettingReduxForm);
