/* global appConfig */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import moment from 'moment';
import axios from 'axios';
import { getOrderStatusClass } from '../../utills/custom';
import StoreAddress from '../../components/StoreAddress';
import { downloadBlob } from '../../utills/ajax';

function renderOrderItem(data) {
  if (data.order_item) {
    return data.order_item.map((item, index) => (
      <tr key={item.product_id}>
        <td>
          <b>{item.product.title}</b>
        </td>
        <td>{item.product.ean}</td>
        <td>{item.product.sku}</td>
        <td className="text-center">{item.quantity}</td>
      </tr>
    ));
  }
  if (data.package) {
    return (
      <tr>
        <td>
          <b>{data.package.title}</b>
        </td>
        <td>{data.package.ean}</td>
        <td className="text-center">1</td>
      </tr>
    );
  }
  return null;
}

const PackingSlip = ({
  showModal,
  showPackingSlip,
  data,
  billingAddress,
  shippingAddress,
  renderAddress
}) => {
  const [productWeight, setProductWeight] = useState('0.00');
  const [isPrintModal, setIsPrintModal] = useState(false);

  const handleSingleDownload = async () => {
    let url;
    if (data.order_item) {
      url = `${process.env.REACT_APP_API_URL}/order/packingslip`;
    } else {
      url = `${process.env.REACT_APP_API_URL}/subscription/packingslip`;
    }

    const response = await axios({
      url,
      method: 'GET',
      params: { id: data.id },
      headers: { Accept: 'application/pdf' },
      responseType: 'blob'
    });
    downloadBlob(
      response,
      `${moment().format('DDMMYYYY')}-${data.invoice_number}.pdf`
    );
  };

  useEffect(() => {
    console.log(data);
    if (data.order_item) {
      setProductWeight(data.total_weight);
    }
    if (data.package) {
      setProductWeight(data.package.weight);
    }
  }, []);
  useEffect(() => {
    if (isPrintModal) {
      window.print();
    }
    if (window.print) {
      setIsPrintModal(false);
    }
  }, [isPrintModal]);

  return (
    <>
      <Modal
        className={isPrintModal && 'PrintModal'}
        size="xl"
        show={showModal}
        onHide={showPackingSlip}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="row mb-4">
            <div className="col-sm-9">
              <h2 className="font-weight-bold dark text-uppercase">
                Packing Slip
              </h2>
            </div>
            <div className="col-sm-3">
              <h3 className="mt-0">{`Invoice #${data.invoice_number}`}</h3>
              <div className="font-13">
                <strong>Order Date: </strong>{' '}
                <span className="float-right">
                  {data.created_at_tz.replace(/\s.*/, '')}
                </span>
              </div>
              <div className="font-13">
                <strong>Order Status: </strong>{' '}
                <span
                  className={`${getOrderStatusClass(
                    data.payment_gateway_status
                  )} float-right`}
                >
                  {data.payment_gateway_status}
                </span>
              </div>
              <div className="font-13">
                <strong>Order ID: </strong>{' '}
                <span className="float-right">{`#${data.id}`}</span>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-6">
              <img
                src={appConfig.logo}
                alt="{appConfig.logoALT}"
                height="40"
                className="mb-2"
              />
              <address className="font-13">
                <StoreAddress country={data.country} />
              </address>
            </div>
            <div className="col-sm-3">
              <h6>Shipping Address</h6>
              {renderAddress(shippingAddress)}
            </div>
            <div className="col-sm-3">
              <h6>Billing Address</h6>
              {renderAddress(billingAddress)}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table mt-4 table-nowrap">
                  <thead>
                    <tr>
                      <th width="50%">
                        {data.package || data.package === null
                          ? 'Starter Pack'
                          : 'Product(s)'}
                      </th>
                      <th>EAN 13 Number</th>
                      {data.order_item && <th>SKU</th>}

                      <th className="text-center">Qty.</th>

                      {/* <th className="text-right">Total B-Point</th> */}
                      {/* <th className="text-right">Total</th> */}
                    </tr>
                  </thead>
                  <tbody>{renderOrderItem(data)}</tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-12">
              <h6>
                <small>
                  Product Weight: <strong>{productWeight} kg</strong>
                </small>
              </h6>
            </div>
            <div className="col-12">
              <h6>
                <small>Total Weight: {`______________`}</small>
              </h6>
            </div>
          </div>
          <div className="d-print-none mt-4">
            <div className="text-right">
              <button
                type="button"
                onClick={() => setIsPrintModal(true)}
                className="btn btn-primary mr-2"
              >
                <i className="mdi mdi-printer" /> Print
              </button>
              <button
                type="button"
                onClick={handleSingleDownload}
                className="btn btn-success mr-2 "
              >
                <i className="mdi mdi-download" /> Download
              </button>
            </div>
          </div>
          <div className="clearfix pt-5 text-center">
            This is a computer generated packing slip &amp; hence requires no
            signature.
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PackingSlip;
