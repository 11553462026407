import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, Form, change, getFormValues } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import {
  required,
  number,
  minValue,
  phoneNumber
} from '../../utills/validator';
import { inputField, radioField, selectField } from '../../components/Fields';
import {
  app as appActions,
  auth as authActions,
  paymentOption as paymentOptionActions
} from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class PaymentBankForm extends React.Component {
  componentDidMount() {
    const {
      getCountry,
      getCityByState,
      getStateByCountry,
      initialValues,
      userInfo,
      dispatch
    } = this.props;
    // Fetch state and city list
    getCountry();

    if (initialValues.country) {
      getStateByCountry(initialValues.country);
    }
    if (initialValues.state) {
      getCityByState(initialValues.state);
    }

    if (userInfo && userInfo.associate_info) {
      dispatch(
        change('paymentBankForm', 'tax', userInfo.associate_info.tax_number)
      );
    }
  }

  handleClose = () => {
    const { hideModal } = this.props;
    hideModal();
  };

  countryChange = val => {
    const { dispatch, getStateByCountry } = this.props;
    dispatch(change('paymentBankForm', 'city', ''));
    dispatch(change('paymentBankForm', 'state', ''));
    if (val) {
      getStateByCountry(val);
    }
  };

  stateChange = val => {
    const { dispatch, getCityByState } = this.props;
    dispatch(change('paymentBankForm', 'city', ''));
    if (val) {
      getCityByState(val);
    }
  };

  submitForm = values => {
    const {
      createPaymentOption,
      updatePaymentOption,
      initialValues
    } = this.props;
    const fieldValues = { ...values };
    if (
      fieldValues.city &&
      fieldValues.city === 'others' &&
      fieldValues.otherCity
    ) {
      fieldValues.city = fieldValues.otherCity;
    }
    if (initialValues.id) {
      updatePaymentOption({
        optionType: 'bank',
        id: initialValues.id,
        data: fieldValues
      });
    } else createPaymentOption({ data: fieldValues, optionType: 'bank' });
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      inProgress,
      countryList,
      stateList,
      cityList,
      initialValues,
      formValues
    } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(this.submitForm)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {initialValues.id ? 'Update bank' : 'Add new bank'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <Field
                name="account_name"
                component={inputField}
                type="text"
                className="form-control"
                label="Account Holder's name"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-8">
              <Field
                name="tax"
                readOnly
                component={inputField}
                type="text"
                className="form-control"
                label="Tax Number"
              />
            </div>
            <div className="col-xs-12 col-md-4">
              <Field
                name="bic_code"
                component={inputField}
                type="text"
                className="form-control"
                label="BIC/SWIFT Code"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-12">
              <Field
                name="iban"
                component={inputField}
                type="text"
                className="form-control"
                label="IBAN"
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-12">
              <Field
                name="country"
                id="country"
                component={selectField}
                label="Country/Region"
                feedback={this.countryChange}
                options={countryList}
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="state"
                id="state"
                component={selectField}
                label="State"
                feedback={this.stateChange}
                options={stateList}
                validate={[required]}
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <Field
                name="city"
                id="city"
                component={selectField}
                label="City"
                validate={[required]}
                options={[{ label: 'Others', value: 'others' }, ...cityList]}
              />
            </div>
            {formValues.city === 'others' && (
              <div className="col-xs-12 col-md-12">
                <Field
                  name="otherCity"
                  id="otherCity"
                  component={inputField}
                  type="text"
                  className="form-control"
                  label="City name"
                  validate={[required]}
                />
              </div>
            )}
            <div className="col-xs-12 col-md-6">
              <Field
                name="is_default"
                options={[
                  { value: 0, label: 'No' },
                  { value: 1, label: 'Yes' }
                ]}
                id="isDefault"
                component={radioField}
                label="Default"
                validate={[required]}
              />
            </div>
            {/* <div className="col-xs-12 col-md-12">
              <Field
                name="locationType"
                options={[
                  { value: 'Current', label: 'Current' },
                  { value: 'Saving', label: 'Saving' }
                ]}
                id="locationType"
                component={radioField}
                label="Account Type"
                validate={[required]}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={inProgress || pristine || submitting}
            variant="primary"
          >
            {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
          </Button>
        </Modal.Footer>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.user,
  countryList: state.app.countryList,
  stateList: state.app.stateList,
  cityList: state.app.cityList,
  inProgress: state.paymentOption.inProgress,
  formValues: getFormValues('paymentBankForm')(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCountry: appActions.getCountry,
      getStateByCountry: appActions.getStateByCountry,
      getCityByState: appActions.getCityByState,
      hideModal: paymentOptionActions.hideModal,
      createPaymentOption: paymentOptionActions.createPaymentOption,
      getPaymentOptionById: paymentOptionActions.getPaymentOptionById,
      updatePaymentOption: paymentOptionActions.updatePaymentOption
    },
    dispatch
  );

const PaymentBankRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentBankForm);

export default reduxForm({
  form: 'paymentBankForm' // a unique identifier for this form
})(PaymentBankRedux);
