import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import routePath from '../../constants/routePath';
import { required, number } from '../../utills/validator';
import {
  inputField,
  inputFieldControlled,
  selectField
} from '../../components/Fields';
import { media as mediaActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';

class MediaForm extends React.Component {
  render() {
    const { handleSubmit, submitForm, inProgress } = this.props;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Field
              name="name"
              component={inputFieldControlled}
              type="text"
              className="form-control"
              label="Style name"
              feedback={this.handleSlug}
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <Field
              name="width"
              component={inputField}
              type="text"
              className="form-control"
              label="Width"
              validate={[required, number]}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <Field
              name="height"
              component={inputField}
              type="text"
              className="form-control"
              label="Height"
              validate={[required, number]}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <Field
              name="align_type"
              id="align_type"
              component={selectField}
              notSearchable
              label="Align type"
              options={[
                { label: 'Top Left', value: 'top-left' },
                { label: 'Top', value: 'Top' },
                { label: 'Top Right', value: 'top-right' },
                { label: 'Left', value: 'left' },
                { label: 'Center', value: 'center' },
                { label: 'Right', value: 'right' },
                { label: 'Bottom Left', value: 'bottom-left' },
                { label: 'Bottom', value: 'bottom' },
                { label: 'Bottom Right', value: 'bottom-right' }
              ]}
              validate={[required]}
            />
          </div>
          <div className="col-xs-12 col-md-4">
            <Field
              name="status"
              id="mediaStatus"
              component={selectField}
              notSearchable
              label="Status"
              options={[
                { label: 'Enable', value: 1 },
                { label: 'Disable', value: 0 }
              ]}
              validate={[required]}
            />
          </div>
        </div>
        <button
          type="submit"
          disabled={inProgress}
          className="btn btn-primary mr-2"
        >
          {inProgress ? <img src={btnLoader} alt="" /> : 'Save'}
        </button>
        <Button as={Link} to={routePath.media.path} className="btn btn-light">
          Cancel
        </Button>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  updateProgress: state.media.updateProgress
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const RankFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(MediaForm);

export default reduxForm({
  form: 'mediaForm' // a unique identifier for this form
})(RankFormWithRedux);
