import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { payout as payoutActions } from '../../actions';

function SendMoneyPopup({
  onClose,
  selected,
  pagePayload,
  callback,
  paymentProcessing,
  makePayoutPayment
}) {
  const [payoutNo, setPayoutNo] = useState('');

  const handleSubmit = () => {
    makePayoutPayment({
      payload: {
        payout_no: payoutNo,
        payout_ids: selected
      },
      pagePayload,
      callback
    });
  };

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Payout</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <p>
              <strong>{`You are processing payment of ${selected.length} associate(s)`}</strong>
            </p>
            <div className="form-group">
              <label className="m-0">Payout No. </label>
              <p className="text-muted font-13">
                The payout number you had put during generating the pauout
              </p>
              <div>
                <input
                  name="payout_no"
                  className="form-control"
                  value={payoutNo}
                  onChange={e => setPayoutNo(e.target.value)}
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={paymentProcessing}
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          disabled={paymentProcessing}
          type="button"
          onClick={handleSubmit}
          variant="primary"
        >
          Process
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = state => ({
  paymentProcessing: state.payout.paymentProcessing
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      makePayoutPayment: payoutActions.makePayoutPayment
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendMoneyPopup);
