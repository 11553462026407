import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import { dashboard as dashboardActionTypes } from '../actions';

function* getBpoint() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/assos/current-bp`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: dashboardActionTypes.BP_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getDashboard(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/associate-dashboard`,
      method: 'GET',
      params: actions.params
    });
    if (responseJson) {
      yield put({
        type: dashboardActionTypes.ASSOCIATE_DASHBOARD_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getUserSummary(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/user/summary`,
      method: 'GET',
      params: actions.data,
      paramsSerializer: params =>
        qs.stringify(params, { skipNulls: false, strictNullHandling: false })
    });
    if (responseJson) {
      yield put({
        type: dashboardActionTypes.USER_SUMMARY_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* watchGetBpoint() {
  yield takeLatest(dashboardActionTypes.BP_REQUEST, getBpoint);
}

function* watchGetDashboard() {
  yield takeLatest(
    dashboardActionTypes.ASSOCIATE_DASHBOARD_REQUEST,
    getDashboard
  );
}

function* watchGetUserSummary() {
  yield takeLatest(dashboardActionTypes.USER_SUMMARY_REQUEST, getUserSummary);
}

export default [
  fork(watchGetBpoint),
  fork(watchGetUserSummary),
  fork(watchGetDashboard)
];
