import React from 'react';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { get, find } from 'lodash';
import { connect } from 'react-redux';
import CouponForm from './CouponForm';
import { coupons as couponActions } from '../../actions';
import BreadCrumb from '../../components/BreadCrumb';
import routePath from '../../constants/routePath';

class CouponEdit extends React.Component {
  componentDidMount() {
    const { match, getCoupon } = this.props;

    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      getCoupon(parseInt(match.params.id, 10));
    }
  }

  handleSubmit = values => {
    const { updateCoupon, match } = this.props;
    const { code, users, ...updateValues } = values;
    updateValues.start_at = moment(
      updateValues.start_at,
      'DD/MM/YYYY HH:mm'
    ).format('YYYY-MM-DD HH:mm');

    updateValues.end_at = moment(
      updateValues.end_at,
      'DD/MM/YYYY HH:mm'
    ).format('YYYY-MM-DD HH:mm');

    updateCoupon({
      id: parseInt(match.params.id, 10),
      data: updateValues,
      redirect: true
    });
  };

  render() {
    const { coupons, match, createProgress } = this.props;
    const couponList = get(coupons, 'data', []);
    let data = null;
    if (match.params && match.params.id && parseInt(match.params.id, 10)) {
      const dataObj = find(couponList, { id: parseInt(match.params.id, 10) });
      if (dataObj) {
        dataObj.start_at = moment(dataObj.start_at, 'YYYY-MM-DD').format(
          'DD/MM/YYYY HH:mm'
        );
        dataObj.end_at = moment(dataObj.end_at, 'YYYY-MM-DD HH:mm').format(
          'DD/MM/YYYY HH:mm'
        );

        const assignedUsers = [];
        if (dataObj.users) {
          dataObj.users.forEach(item => {
            assignedUsers.push(`${item.uuid} - ${item.name}`);
          });
        }

        data = {
          country: dataObj.country === null ? '*' : dataObj.country.iso2,
          code: dataObj.code.join(', '),
          title: dataObj.title,
          description: dataObj.description,
          method: dataObj.method,
          type:
            dataObj.type === 'PERCENTAGE'
              ? (dataObj.type = 2)
              : (dataObj.type = 1),
          discount: dataObj.discount,
          start_at: dataObj.start_at,
          end_at: dataObj.end_at,
          visibility: dataObj.visibility,
          max_discount_allowed: dataObj.max_discount_allowed,
          min_cart_amount: dataObj.min_cart_amount,
          max_usage: dataObj.max_usage,
          max_usage_per_user: dataObj.max_usage_per_user,
          users: assignedUsers.join(', ')
        };
      }
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.couponEdit.breadcrumb} />
              <h4 className="page-title">{routePath.couponEdit.title}</h4>
            </div>
          </div>
        </div>
        {data ? (
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="card coupon_edit">
                <div className="card-body">
                  <CouponForm
                    submitForm={this.handleSubmit}
                    inProgress={createProgress}
                    initialValues={data}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = state => ({
  createProgress: state.coupons.createProgress,
  coupons: state.coupons.allList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateCoupon: couponActions.updateCoupon,
      getCoupon: couponActions.getCoupon
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponEdit);
