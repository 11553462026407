import React, { useState } from 'react';
import { NotificationManager } from 'react-notifications';

function CouponForm({ onApply, errorMsg }) {
  const [input, setInput] = useState('');
  const [processing, setProcessing] = useState(false);
  const handleApply = async e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (input) {
      try {
        setProcessing(true);
        await onApply(input);
      } catch (error) {
        setProcessing(false);
        NotificationManager.error(error);
      }
    }
  };
  return (
    <tr>
      <td colSpan="2">
        <form action="" onSubmit={handleApply}>
          <div className="form-group">
            <label className="mb-1">Apply coupon</label>
            <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
              <input
                type="text"
                className="form-control"
                placeholder="Coupon Code"
                value={input}
                onChange={e => setInput(e.target.value)}
              />
              <button
                className="input-group-addon bootstrap-touchspin-postfix input-group-append btn btn-light"
                type="submit"
                disabled={processing}
                // onClick={handleApply}
              >
                <span className="input-group-text">Apply</span>
              </button>
              <div className={`invalid-feedback ${errorMsg ? 'd-block' : ''}`}>
                {errorMsg}
              </div>
            </div>
          </div>
        </form>
      </td>
    </tr>
  );
}

export default CouponForm;
