/* global location */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import PaginationNormal from '../../components/PaginationNormal';
import { OverlayLoader } from '../../components/InnerLoader';
import BreadCrumb from '../../components/BreadCrumb';
import NoResult from '../../components/NoResult';
import routePath from '../../constants/routePath';
import ProductItem from './ProductItem';
import { shop as shopActions } from '../../actions';
import ShopFilterForm from './ShopFilterForm';
import Search from './Search';

class ShopCategoryProducts extends React.Component {
  state = {
    layoutStyle: 'grid',
    // currentPage: 1,
    searchTerm: '',
    selectedCategory: '',
    perPage: null,
    page: 1
  };

  componentDidMount() {
    const { match, shopCountry, getShopCategory, shopState } = this.props;
    if (match.params) {
      if (match.params.category) {
        this.setState({ selectedCategory: match.params.category });
      }
      // if (match.params.page && parseInt(match.params.page, 10)) {
      //   this.setState({ currentPage: match.params.page });
      // }
    }

    getShopCategory({ country: shopCountry, state: shopState });

    // Only load if session set for country
    // if (shopCountry) {
    //   this.handleGetProducts();
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    const { shopCountry, shopState, getShopCategory } = this.props;
    const { searchTerm, selectedCategory } = this.state;
    if (
      prevProps.shopCountry !== shopCountry ||
      prevProps.shopState !== shopState ||
      prevState.searchTerm !== searchTerm ||
      prevState.selectedCategory !== selectedCategory
    ) {
      this.handleGetProducts();
    }

    if (
      prevProps.shopCountry !== shopCountry ||
      prevProps.shopState !== shopState
    ) {
      getShopCategory({ country: shopCountry, state: shopState });
    }
  }

  setSearchTerm = searchTerm => {
    this.setState({ searchTerm }, () => {
      // this.handleGetProducts();
    });
  };

  setSearchCategory = selectedCategory => {
    this.setState({ selectedCategory }, () => {
      // this.handleGetProducts();
    });
  };

  handleLayout = layout => {
    this.setState({ layoutStyle: layout });
  };

  handleSizeChange = size => {
    const {
      products: { total }
    } = this.props;
    const { page } = this.state;
    const lastPage = Math.ceil(total / size);
    const findPage = lastPage < page ? lastPage : page;
    this.setState({ page: findPage, perPage: size }, () => {
      this.handleGetProducts();
    });
    // const { page } = this.state;
    // history.push(`/shop/${page}/?per_page=${size}`);
  };

  handlePageChange = page => {
    this.setState({ page }, () => {
      this.handleGetProducts();
    });
  };

  handleGetProducts = values => {
    const { searchItem, shopCountry, shopState } = this.props;
    const { page, perPage, searchTerm, selectedCategory } = this.state;
    if (shopCountry) {
      const orderList = {
        date_asc: {
          sort: 'date',
          order: 'asc'
        },
        date_desc: {
          sort: 'date',
          order: 'desc'
        },
        price_asc: {
          sort: 'price',
          order: 'asc'
        },
        price_desc: {
          sort: 'price',
          order: 'desc'
        }
      };

      const params = {
        page, // reset the page
        per_page: perPage,
        country: shopCountry,
        state: shopState,
        category: selectedCategory
        // sort: orderList[values.order_by].sort,
        // order: orderList[values.order_by].order
      };
      if (searchTerm) {
        params.title = searchTerm;
      }
      searchItem(params);
    }
  };

  render() {
    const { products, isLoading } = this.props;
    const { layoutStyle, selectedCategory } = this.state;
    return (
      <div className="shop-page">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.shopCategoryProducts.breadcrumb} />
              <h4 className="page-title">
                {routePath.shop.title}
                {products.category ? ` / ${products.category.title}` : ''}
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3">
            <Search getSearchTerm={this.setSearchTerm} />
          </div>
          <div className="col-md-9">
            <ShopFilterForm
              submitForm={this.handleGetProducts}
              handleLayout={this.handleLayout}
              layoutStyle={layoutStyle}
              initialValues={{ order_by: 'date_desc' }}
              selectedCategory={selectedCategory}
              onCategoryChange={this.setSearchCategory}
            />
          </div>
        </div>

        {isLoading && (
          <OverlayLoader className="preloader-inner fixed" type="spinner" />
        )}
        <div
          className={`row row-eq-height ${
            layoutStyle === 'grid' ? 'products-grid' : 'products-list'
          }`}
        >
          {products.data && products.data.length ? (
            products.data.map(product => (
              <React.Fragment key={product.id}>
                {layoutStyle === 'grid' ? (
                  <ProductItem product={product} type="grid" col="4" />
                ) : (
                  <ProductItem product={product} type="list" />
                )}
              </React.Fragment>
            ))
          ) : (
            <NoResult />
          )}
        </div>

        {!isLoading && products.data && products.data.length ? (
          <div className="row mt-0 mt-sm-4">
            <div className="col-sm-6 mb-2 mb-sm-0">
              <div className="btn-group mr-2">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="light"
                    className="text-dark"
                    id="pageDropDown"
                  >
                    {products.per_page}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {products.filter_values &&
                      products.filter_values.map(size => (
                        <Dropdown.Item
                          key={`size_${size}`}
                          onClick={() => this.handleSizeChange(size)}
                        >
                          {size}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="text-muted mt-1 mb-md-3 text-center text-sm-left d-inline">
                {products.from
                  ? `Showing ${products.from}-${products.to} of ${products.total} results`
                  : null}
              </div>
            </div>
            <div className="col-sm-6">
              <PaginationNormal
                perPage={products.per_page}
                handleOnClick={this.handlePageChange}
                lastPage={products.last_page || 1}
                currentPage={products.current_page}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.shop.list,
  isLoading: state.shop.isSearching,
  shopCountry: state.app.shopCountry,
  shopState: state.app.shopState
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchItem: shopActions.searchItem,
      getShopCategory: shopActions.getShopCategory
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopCategoryProducts);
