import React from 'react';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { Nav, Tab } from 'react-bootstrap';
import AdvanceTable from '../../components/AdvanceTable';
import BreadCrumb from '../../components/BreadCrumb';
import { payout as payoutActions } from '../../actions';
import routePath from '../../constants/routePath';
import StatusSwitch from '../../components/StatusSwitch';
import { exportPayoutInvite } from '../../services/apiService';

class Payout extends React.Component {
  state = {
    page: 1,
    selected: []
  };

  componentDidMount() {
    const { getAdminPayoutList } = this.props;
    getAdminPayoutList();
  }

  handleOnSelect = (row, isSelect) => {
    const { selected } = this.state;
    if (isSelect) {
      this.setState(() => ({
        selected: [...selected, row.id]
      }));
    } else {
      this.setState(() => ({
        selected: selected.filter(x => x !== row.id)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.id);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  };

  handleTableChange = (type, { page, sizePerPage: perPage }) => {
    const { getAdminPayoutList } = this.props;
    if (type === 'pagination') getAdminPayoutList({ page, per_page: perPage });
    this.setState({ page });
  };

  updatePayoutStatus = (id, val) => {
    const { setPayoutPaid } = this.props;
    setPayoutPaid({
      paid: +val,
      id
    });
  };

  handleInvite = () => {
    try {
      exportPayoutInvite();
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {
      payouts,
      isLoading,
      appSetting,
      userInfo,
      filterValues
    } = this.props;
    const { page, selected } = this.state;
    const userRoles = userInfo.permission.role;
    const isAdmin = userRoles.indexOf('super-admin') > -1;
    const columnsYearly = [
      {
        dataField: 'payout_no',
        text: 'Company Payout no.'
        // headerAttrs: { width: '12%' }
      },
      {
        dataField: 'type',
        text: 'Year'
      },
      {
        dataField: 'created_at',
        text: 'Date & Time',
        headerAttrs: { width: '12%' },
        formatter: col => (
          <span className="date">
            {moment(col).format(appSetting.site_date_format)}
            <br />
            <small>{moment(col).format('H:mm A')}</small>
          </span>
        )
      },
      {
        dataField: 'status',
        text: 'Status',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '9%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={parseInt(col, 10)}
            onChange={this.updatePayoutStatus}
            dataLabel={{ on: 'Paid', off: 'Pending' }}
            id={row.id}
            className="mode-switch"
            idPrefix="payout_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/payout/${row.id}/view`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
          </>
        )
      }
    ];

    const columnsMonthly = [
      {
        dataField: 'payout_no',
        text: 'Company Payout no.'
        // headerAttrs: { width: '12%' }
      },
      {
        dataField: 'month',
        text: 'Month',
        formatter: (col, row) => (
          <span>
            {`${moment(row.month, 'MM').format('MMMM')} - ${row.year}`}
          </span>
        )
      },
      {
        dataField: 'created_at',
        text: 'Date & Time',
        headerAttrs: { width: '12%' },
        formatter: col => (
          <span className="date">
            {moment(col).format(appSetting.site_date_format)}
            <br />
            <small>{moment(col).format('H:mm A')}</small>
          </span>
        )
      },
      {
        dataField: 'paid_status',
        text: 'Paid',
        headerClasses: 'text-center',
        classes: 'text-center',
        headerAttrs: { width: '15%' },
        formatter: (col, row) => (
          <StatusSwitch
            checked={parseInt(col, 10)}
            onChange={this.updatePayoutStatus}
            dataLabel={{ on: 'Paid', off: 'Pending' }}
            className="mode-switch"
            id={row.id}
            idPrefix="payout_sts_"
          />
        )
      },
      {
        dataField: 'action',
        isDummyField: true,
        text: 'Action',
        headerClasses: 'text-center',
        headerAttrs: { width: '10%' },
        classes: 'text-center',
        formatter: (col, row) => (
          <>
            <Link to={`/payout/${row.id}/view`}>
              <i className="action-icon mdi mdi-eye" />
            </Link>
          </>
        )
      }
    ];
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.payout.breadcrumb} />
              <h4 className="page-title">{routePath.payout.title}</h4>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Tab.Container id="payouts-tabs" defaultActiveKey="monthly">
              <Nav className="nav nav-tabs nav-bordered">
                <Nav.Item>
                  <Nav.Link eventKey="monthly">Monthly</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="yearly">Yearly</Nav.Link>
                </Nav.Item> */}
              </Nav>

              <div className="card">
                <div className="card-body">
                  {isAdmin && (
                    <div className="row mb-3">
                      <div className="col-12">
                        <Link
                          to={routePath.payoutGenerate.path}
                          className="btn btn-primary float-right"
                        >
                          <i className="mdi mdi-plus-circle mr-2" />
                          Generate Payout
                        </Link>
                        <button
                          type="button"
                          className="btn btn-primary float-right mr-2"
                          onClick={this.handleInvite}
                        >
                          <i className="mdi mdi-file-download mr-2" />
                          Export Invite List
                        </button>
                      </div>
                    </div>
                  )}
                  <Tab.Content>
                    {/* <Tab.Pane eventKey="yearly">
                      <AdvanceTable
                        data={[
                          {
                            id: 1,
                            payout_no: 20,
                            type: 2019,
                            created_at: '2019-12-09 11:45:55',
                            status: 1
                          }, {
                            id: 2,
                            payout_no: 234,
                            type: 2019,
                            created_at: '2019-12-09 11:45:55',
                            status: 0
                          }
                        ]}
                        columns={columnsYearly}
                        loading={isLoading}
                      />
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="monthly">
                      <AdvanceTable
                        data={payouts.data}
                        columns={columnsMonthly}
                        loading={isLoading}
                        sizePerPageList={filterValues}
                        totalSize={payouts.total}
                        sizePerPage={payouts.per_page}
                        page={page}
                        remote={{ pagination: true }}
                        hideSizePerPage={false}
                        onTableChange={this.handleTableChange}
                        selected={selected}
                        onSelect={this.handleOnSelect}
                        onSelectAll={this.handleOnSelectAll}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  payouts: state.payout.adminList,
  appSetting: state.app.settings,
  isLoading: state.payout.isLoading,
  userInfo: state.auth.user,
  filterValues: state.app.settings.filter_values
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAdminPayoutList: payoutActions.getAdminPayoutList,
      setPayoutPaid: payoutActions.setPayoutPaid
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Payout);
