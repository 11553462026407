import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import {
  storeAddress as storeAddressActionTypes,
  app as appActionTypes
} from '../actions';

function* getStoreAddressList() {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/getCountryStoreList`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: storeAddressActionTypes.STORE_ADDRESS_LIST_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* getStoreAddress(actions) {
  console.log({ actions });
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/getCountryStore/${actions.data.iso2}`,
      method: 'GET'
    });
    if (responseJson) {
      yield put({
        type: storeAddressActionTypes.STORE_ADDRESS_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* createStoreAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/createStoreAddress`,
      method: 'POST',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: storeAddressActionTypes.STORE_CREATE_ADDRESS_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: '/setting/store'
      });
    }
  } catch (error) {
    yield put({
      type: storeAddressActionTypes.STORE_CREATE_ADDRESS_ERROR,
      data: error
    });
  }
}

function* updateStoreAddress(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/updateStoreAddress`,
      method: 'POST',
      data: actions.data
    });
    if (responseJson) {
      yield put({
        type: storeAddressActionTypes.STORE_UPDATE_ADDRESS_SUCCESS,
        data: responseJson
      });
      yield put({
        type: appActionTypes.NAVIGATE_REQUEST,
        path: '/setting/store'
      });
    }
  } catch (error) {
    yield put({
      type: storeAddressActionTypes.STORE_UPDATE_ADDRESS_ERROR,
      data: error
    });
  }
}

function* updateStorePickup(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/country/toggleStorePickupActivation/${actions.data.store_country_iso2}`,
      method: 'PUT'
    });
    if (responseJson) {
      yield put({
        type: storeAddressActionTypes.STORE_PICKUP_UPDATE_SUCCESS,
        data: responseJson
      });
    }
  } catch (error) {
    yield put({
      type: storeAddressActionTypes.STORE_PICKUP_UPDATE_ERROR,
      data: error
    });
  }
}

function* updateCouponVisibility(actions) {
  try {
    const responseJson = yield axios({
      url: `${process.env.REACT_APP_API_URL}/coupons/toggle-coupon-status/${actions.data.store_country_iso2}`,
      method: 'PUT'
    });
    if (responseJson) {
      yield put({
        type: storeAddressActionTypes.STORE_ADDRESS_LIST_REQUEST
      });
    }
  } catch (error) {
    yield put({
      type: storeAddressActionTypes.COUPON_VISIBILITY_UPDATE_ERROR,
      data: error
    });
  }
}

function* watchStoreAddressListAll() {
  yield takeLatest(
    storeAddressActionTypes.STORE_ADDRESS_LIST_REQUEST,
    getStoreAddressList
  );
}

function* watchGetStoreAddress() {
  yield takeLatest(
    storeAddressActionTypes.STORE_ADDRESS_REQUEST,
    getStoreAddress
  );
}

function* watchStoreAddressCreate() {
  yield takeLatest(
    storeAddressActionTypes.STORE_CREATE_ADDRESS_REQUEST,
    createStoreAddress
  );
}

function* watchStoreAddressUpdate() {
  yield takeLatest(
    storeAddressActionTypes.STORE_UPDATE_ADDRESS_REQUEST,
    updateStoreAddress
  );
}

function* watchStorePickupUpdate() {
  yield takeLatest(
    storeAddressActionTypes.STORE_PICKUP_UPDATE_REQUEST,
    updateStorePickup
  );
}

function* watchUpdateCouponVisibility() {
  yield takeLatest(
    storeAddressActionTypes.COUPON_VISIBILITY_UPDATE_REQUEST,
    updateCouponVisibility
  );
}

export default [
  fork(watchStoreAddressListAll),
  fork(watchStoreAddressCreate),
  fork(watchStoreAddressUpdate),
  fork(watchGetStoreAddress),
  fork(watchStorePickupUpdate),
  fork(watchUpdateCouponVisibility)
];
