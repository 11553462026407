import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import FsbConfigForm from './FsbConfigForm';
import BreadCrumb from '../../components/BreadCrumb';
import InnerLoader from '../../components/InnerLoader';
import routePath from '../../constants/routePath';
import { fsb as fsbActions } from '../../actions';
import FsbConfigTriangle from './FsbConfigTriangle';

class FsbConfigEdit extends React.Component {
  componentDidMount() {
    const { getFsb } = this.props;
    getFsb();
  }

  handleSubmit = values => {
    const { updateFsb } = this.props;
    updateFsb(values);
  };

  render() {
    const { updateProgress, fsbPlans, isLoading } = this.props;
    const fsb = fsbPlans.data ? fsbPlans.data[0] : {};
    let data = null;
    if (!isEmpty(fsb)) {
      data = {
        title: fsb.title,
        total_duration: fsb.total_duration,
        phase: [],
        is_deleted: []
      };
      fsb.phase.forEach(item => {
        data.phase.push({
          id: item.id,
          title: item.title,
          bp_target: item.bp_target,
          payout: item.payout
        });
      });
    }
    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="page-title-box">
              <BreadCrumb list={routePath.fsb.breadcrumb} />
              <h4 className="page-title">{routePath.fsb.title}</h4>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            {!data ? (
              <InnerLoader type="table" />
            ) : (
              <div className="row">
                <div className="col-md-6">
                  <div className="p-2">
                    <FsbConfigTriangle data={data} />
                  </div>
                </div>
                <div className="col-md-6">
                  <h5 className="mt-0">Edit FSB Tracker</h5>
                  <p className="text-muted font-13 m-b-30">
                    Edit the tracker plan details below.
                  </p>

                  {!data ? (
                    <InnerLoader type="table" />
                  ) : (
                    <FsbConfigForm
                      submitForm={this.handleSubmit}
                      inProgress={updateProgress}
                      initialValues={data}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.fsb.isLoading,
  updateProgress: state.fsb.updateProgress,
  fsbPlans: state.fsb.plan
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateFsb: fsbActions.updateFsb,
      getFsb: fsbActions.getFsb
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FsbConfigEdit);
