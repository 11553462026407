import React from 'react';
import { bindActionCreators } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { Button, Form, Overlay, Tooltip } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import { inputField, inputGroupField } from '../../components/Fields';
import {
  required,
  passwordsMatch,
  passwordRules
} from '../../utills/validator';
import { auth as authActions } from '../../actions';
import btnLoader from '../../assets/images/btn-loader.gif';
import ShowHidePasswordField from '../../components/ShowHidePasswordField';

class ChangePasswordForm extends React.Component {
  state = {
    strengthPassed: [
      <li className="text-left">
        <span>Use at least one uppercase character</span>
      </li>,
      <li className="text-left">
        <span>Use at least one lowercase character</span>
      </li>,
      <li className="text-left">
        <span>Use at least one digit</span>
      </li>,
      <li className="text-left">
        <span>Use at least one special character ($@$!%*#?&)</span>
      </li>,
      <li className="text-left">
        <span>Must be at least 8 character long</span>
      </li>
    ],
    passwordStrengthClass: '',
    passwordStrengthPercent: 0,
    tooltipOpen: false,
    tooltipTarget: React.createRef(),
    showEye: true
  };

  showPassword = () => {
    const { showEye } = this.state;
    this.setState({ showEye: !showEye });
  };

  toggleTooltip = () => {
    this.setState(a => ({
      tooltipOpen: !a.tooltipOpen
    }));
  };

  checkPasswordStrength = val => {
    const matchedCase = passwordRules;
    const passed = [];
    let ctr = 0;
    matchedCase.forEach((item, key) => {
      if (new RegExp(item.pattern).test(val)) {
        ctr += 1;
        passed.push(
          <li key={key} className="text-left">
            <del>{item.text}</del>
          </li>
        );
      } else {
        passed.push(
          <li key={key} className="text-left">
            <span>{item.text}</span>
          </li>
        );
      }
    });
    this.setState({ strengthPassed: passed });
    switch (ctr) {
      case 5:
        this.setState({
          passwordStrengthClass: 'bg-success',
          passwordStrengthPercent: 100,
          tooltipOpen: false
        });
        break;
      case 4:
        this.setState({
          passwordStrengthClass: 'bg-warning',
          passwordStrengthPercent: 80,
          tooltipOpen: true
        });
        break;
      case 3:
        this.setState({
          passwordStrengthClass: 'bg-warning',
          passwordStrengthPercent: 50,
          tooltipOpen: true
        });
        break;
      case 2:
        this.setState({
          passwordStrengthClass: 'bg-info',
          passwordStrengthPercent: 30,
          tooltipOpen: true
        });
        break;
      case 1:
        this.setState({
          passwordStrengthClass: 'bg-info',
          passwordStrengthPercent: 10,
          tooltipOpen: true
        });
        break;
      default:
        this.setState({
          passwordStrengthClass: '',
          passwordStrengthPercent: 0,
          tooltipOpen: true
        });
    }
    if (!val) {
      this.setState({ tooltipOpen: false });
    }
  };

  submitForm = values => {
    const { changePassword } = this.props;
    const fieldValues = { ...values };
    changePassword(fieldValues);
  };

  render() {
    const {
      handleSubmit,
      pristine,
      submitting,
      changePasswordProgress
    } = this.props;
    const {
      strengthPassed,
      passwordStrengthClass,
      passwordStrengthPercent,
      tooltipOpen,
      tooltipTarget,
      showEye
    } = this.state;

    return (
      <Form
        className="needs-validation"
        noValidate
        onSubmit={handleSubmit(this.submitForm)}
      >
        <div className="row">
          <div className="col-md-4">
            <ShowHidePasswordField
              name="oldPassword"
              label="Current Password"
              validate={[required]}
            />
          </div>
          <div className="col-md-4">
            <ShowHidePasswordField
              name="newPassword"
              label="New Password"
              validate={[required]}
              feedback={this.checkPasswordStrength}
            />

            <div className="row">
              <div className="col-12 col-md-10">
                <div className="progress" style={{ height: 7 }}>
                  <div
                    className={`bar progress-bar progress-bar-striped ${passwordStrengthClass}`}
                    style={{ width: `${passwordStrengthPercent}%` }}
                  />
                </div>
                <div className="d-md-none mt-2 mt-md-0">
                  <span className="font-weight-semibold">Password Guide</span>
                  <ul className="mt-1 pass-guide-info pl-3">
                    {strengthPassed}
                  </ul>
                </div>
              </div>
              <div className="col-md-2 text-right d-none d-md-inline-block">
                <button
                  type="button"
                  className="btn btn-icon btn-sm pass-info p-0"
                  onClick={() => this.toggleTooltip()}
                  id="TooltipExample"
                  ref={tooltipTarget}
                >
                  <i className="mdi mdi-information-outline" />
                </button>
                <Overlay
                  target={tooltipTarget.current}
                  show={tooltipOpen}
                  placement="right"
                >
                  {props => (
                    <Tooltip
                      id="overlay-example"
                      className="d-none d-md-block"
                      {...props}
                    >
                      <div>
                        <strong>Password Guide</strong>
                        <ul className="mt-1 pass-guide-info pl-3">
                          {strengthPassed}
                        </ul>
                      </div>
                    </Tooltip>
                  )}
                </Overlay>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <ShowHidePasswordField
              name="confirmPassword"
              label="Confirm Password"
              validate={[required, passwordsMatch]}
            />
          </div>
          <div className="col-md-12 mt-2">
            <div className="form-group">
              <Button
                type="submit"
                disabled={pristine || submitting}
                variant="primary"
              >
                {changePasswordProgress ? (
                  <img src={btnLoader} alt="" />
                ) : (
                  'Update Password'
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  changePasswordProgress: state.auth.changePasswordProgress
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changePassword: authActions.changePassword
    },
    dispatch
  );

const ChangePasswordFormWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordForm);

export default reduxForm({
  form: 'profileChangePasswordForm' // a unique identifier for this form
})(ChangePasswordFormWithRedux);
