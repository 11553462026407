import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import sagaMonitor from '@redux-saga/simple-saga-monitor';
import rootReducer from '../reducers';

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware({ sagaMonitor });
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const { logger } = require('redux-logger');
    return {
      ...createStore(
        combineReducers({ ...rootReducer }),
        applyMiddleware(sagaMiddleware, logger)
      ),
      runSaga: sagaMiddleware.run
    };
  }
  return {
    ...createStore(
      combineReducers({ ...rootReducer }),
      applyMiddleware(sagaMiddleware)
    ),
    runSaga: sagaMiddleware.run
  };
}
