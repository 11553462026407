import React, { Component, useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { shop as shopAction } from '../../actions';

class SearchComponent extends Component {
  searchProductList = debounce(searchTerm => {
    const { getSearchTerm } = this.props;

    this.setState({ text: searchTerm });
    getSearchTerm(searchTerm);
  }, 1000);

  constructor(props) {
    super(props);
    this.state = {
      searchTerm: props.searchTerm,
      text: ''
    };
  }

  setSearchTerm = searchTerm => {
    this.setState({ searchTerm, text: '' });
    this.searchProductList(searchTerm);
  };

  render() {
    const { searchTerm, text } = this.state;
    const { isSearching, products } = this.props;
    return (
      <div>
        <div>
          <input
            placeholder="Search Products..."
            className="form-control"
            onChange={e => {
              this.setSearchTerm(e.target.value);
            }}
          />
        </div>
        {text && !isSearching && (
          <div className="block my-2">
            <small>
              Search results for &#34;{searchTerm}&#34; -{' '}
              <b>{products.total}&nbsp;</b>
              record(s) found!
            </small>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  products: state.shop.list,
  isSearching: state.shop.isSearching
});

// const mapDistpatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       searchItem: shopAction.searchItem
//     },
//     dispatch
//   );

const SearchComponentWithredux = connect(
  mapStateToProps,
  null
)(SearchComponent);

function Search(props) {
  return <SearchComponentWithredux {...props} />;
}

export default Search;
