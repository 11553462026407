import '@babel/polyfill';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { unregister } from './serviceWorker';

// Themes
// import './assets/scss/DefaultTheme.scss';

// For Dark import DarkTheme.scss
// import './assets/scss/DarkTheme.scss';

// For green - import GreenTheme.scss
// import './assets/scss/GreenTheme.scss';

// For royal - import RoyalTheme.scss
// import './assets/scss/RoyalTheme.scss';

// For blue - import RoyalTheme.scss
import './assets/scss/YaleTheme.scss';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
